export const setoresCensitarios = {
  "type": "FeatureCollection",
  "name": "setores_censitarios",
  "crs": {
    "type": "name",
    "properties": { "name": "urn:ogc:def:crs:EPSG::4674" }
  },
  "features": [
    {
      "type": "Feature",
      "properties": {
        "fid": 8,
        "id": 36919,
        "CD_SETOR": "315210505060001P",
        "AREA_KM2": 0.140599,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 330
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.905393812999897, -20.414323919],
            [-42.905658992, -20.414696709999902],
            [-42.905690568999901, -20.414741101999901],
            [-42.905837057, -20.4149008539999],
            [-42.906102614, -20.415196426],
            [-42.906516155, -20.41549313],
            [-42.906202277, -20.41579037],
            [-42.906228179, -20.415964283999902],
            [-42.906246497, -20.416087275999899],
            [-42.906275795999903, -20.416284001],
            [-42.906332710999898, -20.416332234],
            [-42.906364310999898, -20.416348071],
            [-42.906419147, -20.416344731],
            [-42.9069527199999, -20.4162871139999],
            [-42.907098287999901, -20.416271395],
            [-42.907171241999897, -20.416268267],
            [-42.907249021, -20.416270360999899],
            [-42.907610793999901, -20.416410648],
            [-42.908125888, -20.415898108],
            [-42.908520403, -20.416184318],
            [-42.908381995999903, -20.416321325],
            [-42.908877253, -20.417005854],
            [-42.908923623, -20.417107449],
            [-42.909114716, -20.417321995999899],
            [-42.909440662, -20.417719297],
            [-42.909462846999901, -20.417749967],
            [-42.909502464, -20.417823381999899],
            [-42.909516739, -20.41795983],
            [-42.909563599, -20.418407757999901],
            [-42.909648795, -20.418574276999902],
            [-42.909771077, -20.418773901],
            [-42.909846494, -20.418927573],
            [-42.909896918999898, -20.419079433],
            [-42.909927825999901, -20.419228543],
            [-42.909973756999896, -20.419419957999899],
            [-42.910333798999901, -20.419471971999901],
            [-42.910356457, -20.419473335],
            [-42.910575567, -20.419496309],
            [-42.910821472, -20.418652071999901],
            [-42.910823536, -20.418643137999901],
            [-42.910833295, -20.418569182999899],
            [-42.910936880999898, -20.417784228999899],
            [-42.910976897999902, -20.41718894],
            [-42.911000374, -20.416705124],
            [-42.910944856, -20.416278285],
            [-42.910823825, -20.416123097],
            [-42.910761904, -20.416039134999899],
            [-42.910328179, -20.415468233999899],
            [-42.910061842999902, -20.415245558],
            [-42.909754953999901, -20.4151216929999],
            [-42.909521240999901, -20.4150750109999],
            [-42.909115496, -20.414992759],
            [-42.908436880999901, -20.414827859],
            [-42.908026146999902, -20.414678196999901],
            [-42.907655709, -20.414482397],
            [-42.907214841, -20.414239237999901],
            [-42.906846843, -20.4140495],
            [-42.906536183999897, -20.413999272],
            [-42.906158741, -20.414030405999899],
            [-42.9058723159999, -20.414109956999901],
            [-42.905393812999897, -20.414323919]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 9,
        "id": 36920,
        "CD_SETOR": "315210505060002P",
        "AREA_KM2": 0.096568,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 344
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.9027837679999, -20.4161603159999],
            [-42.903091526, -20.41673041],
            [-42.903409752999899, -20.416792668],
            [-42.903842671999897, -20.4168136239999],
            [-42.904677186999898, -20.416718865999901],
            [-42.904817835, -20.41669467],
            [-42.905171062, -20.416673729],
            [-42.905401258, -20.416717245],
            [-42.906025811999903, -20.4168211809999],
            [-42.906270068, -20.4168431209999],
            [-42.906361091, -20.416932027],
            [-42.90630873, -20.417102618999898],
            [-42.906233653999898, -20.417260196],
            [-42.906025233, -20.417594542],
            [-42.905970461, -20.417866631],
            [-42.905981563, -20.417923858999899],
            [-42.906001696, -20.418027629999902],
            [-42.905998858, -20.418158128999899],
            [-42.905967929, -20.418246839999899],
            [-42.905892261, -20.418244993],
            [-42.905829895999901, -20.4181955029999],
            [-42.905753434, -20.418148164999899],
            [-42.905708045, -20.418172362999901],
            [-42.905695767999902, -20.418307463],
            [-42.905672126999903, -20.41842086],
            [-42.905657429, -20.418491356999901],
            [-42.905637049999903, -20.418650063],
            [-42.905623106, -20.418758652],
            [-42.905606689, -20.418884418999902],
            [-42.905593203, -20.418991516999899],
            [-42.905530379, -20.419480762],
            [-42.90551856, -20.419579661999901],
            [-42.905505089, -20.419677712],
            [-42.906276803, -20.419805279999899],
            [-42.906234491, -20.4200909109999],
            [-42.906127361, -20.420312376],
            [-42.906081437, -20.420414889],
            [-42.906045943, -20.420494122],
            [-42.906084939, -20.420459433],
            [-42.9061258569999, -20.42042169],
            [-42.906199783, -20.420353501],
            [-42.90624779, -20.42030922],
            [-42.906339403999901, -20.420224716],
            [-42.906502368, -20.420030010999898],
            [-42.906683224999902, -20.4198055969999],
            [-42.906946326, -20.419350934],
            [-42.907058142, -20.419152595999901],
            [-42.907148631, -20.418992088],
            [-42.907181776999899, -20.41894357],
            [-42.907218766, -20.418897996999899],
            [-42.90729268, -20.418806926999899],
            [-42.907326917, -20.418717145],
            [-42.907696611999903, -20.417667676999901],
            [-42.907761697999902, -20.417536846],
            [-42.907798872999898, -20.417449164999901],
            [-42.907909147999902, -20.4171890709999],
            [-42.9081183979999, -20.416741083],
            [-42.908203091, -20.416561564999899],
            [-42.908263658, -20.416441099999901],
            [-42.908381995999903, -20.416321325],
            [-42.908520403, -20.416184318],
            [-42.908125888, -20.415898108],
            [-42.907610793999901, -20.416410648],
            [-42.907249021, -20.416270360999899],
            [-42.907171241999897, -20.416268267],
            [-42.907098287999901, -20.416271395],
            [-42.9069527199999, -20.4162871139999],
            [-42.906419147, -20.416344731],
            [-42.906364310999898, -20.416348071],
            [-42.906332710999898, -20.416332234],
            [-42.906275795999903, -20.416284001],
            [-42.906246497, -20.416087275999899],
            [-42.906228179, -20.415964283999902],
            [-42.906202277, -20.41579037],
            [-42.90613496, -20.4157241519999],
            [-42.905960353999902, -20.415681709],
            [-42.905845857, -20.415698764],
            [-42.905777777, -20.415700714],
            [-42.905724989, -20.415709747999902],
            [-42.905522545999901, -20.415781102],
            [-42.905274482, -20.416005324],
            [-42.904940408999899, -20.416072992],
            [-42.904753113, -20.41611548],
            [-42.904555441999896, -20.416156432],
            [-42.904289579, -20.416194936999901],
            [-42.904036081, -20.416209879],
            [-42.903792672999899, -20.416230379999899],
            [-42.903516189, -20.416227689],
            [-42.9027837679999, -20.4161603159999]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 10,
        "id": 36921,
        "CD_SETOR": "315210505060003P",
        "AREA_KM2": 0.201419,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 195
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.907761697999902, -20.417536846],
            [-42.907696611999903, -20.417667676999901],
            [-42.907326917, -20.418717145],
            [-42.90729268, -20.418806926999899],
            [-42.907266947, -20.418905067],
            [-42.9072476649999, -20.418959702999899],
            [-42.907346773, -20.418993579999899],
            [-42.907319634, -20.419271954],
            [-42.907322668999903, -20.419490638],
            [-42.907346801, -20.419699689],
            [-42.907361204, -20.419895029],
            [-42.907358208999902, -20.420191868],
            [-42.907458247, -20.420335169999898],
            [-42.9075594, -20.420547672999898],
            [-42.907689821, -20.420663084],
            [-42.907859827, -20.420791392],
            [-42.907972946, -20.420869875999902],
            [-42.908014396999903, -20.420975811999899],
            [-42.907959574, -20.42113334],
            [-42.907933236, -20.421443301999901],
            [-42.907951738, -20.421793043],
            [-42.907952876, -20.42230122],
            [-42.907923434999901, -20.422780240999899],
            [-42.907929219, -20.422819503],
            [-42.907925232, -20.422896502],
            [-42.907933783, -20.422938799999901],
            [-42.9079242399999, -20.422967406999899],
            [-42.907913176, -20.422993887],
            [-42.907812890999899, -20.423042752999901],
            [-42.907768866, -20.423065274],
            [-42.907688952999898, -20.423084060999901],
            [-42.907613379999901, -20.423060924],
            [-42.907342569, -20.422972651],
            [-42.907269712999899, -20.4229133169999],
            [-42.907213585, -20.422746025],
            [-42.907164247, -20.422643434],
            [-42.907099792999901, -20.422556474],
            [-42.906997084999901, -20.422452846999899],
            [-42.90689401, -20.422374486],
            [-42.906749786, -20.4223002059999],
            [-42.906610438, -20.4222564519999],
            [-42.906500869, -20.422260346],
            [-42.906415806, -20.422318462],
            [-42.906353221, -20.422395924999901],
            [-42.906263878999901, -20.422503982],
            [-42.90619457, -20.422628921999902],
            [-42.906059169999899, -20.422843761],
            [-42.9059728, -20.4229622669999],
            [-42.905879289, -20.423085414],
            [-42.905834359, -20.423151026],
            [-42.905779901, -20.423291497],
            [-42.905779621, -20.423469435],
            [-42.905813875, -20.423617438999901],
            [-42.905887404999902, -20.423753417999901],
            [-42.9061408049999, -20.42422428],
            [-42.906516659, -20.424922594999899],
            [-42.906623979, -20.425183037],
            [-42.906682902999897, -20.425287376],
            [-42.906838736999902, -20.425571927],
            [-42.906877897999898, -20.425699536],
            [-42.906879981, -20.425797396],
            [-42.906838679, -20.4258853649999],
            [-42.906723923, -20.426015118999899],
            [-42.906335871, -20.42646625],
            [-42.905700227999901, -20.42706197],
            [-42.904689698, -20.427860132],
            [-42.904538370999902, -20.427870116999902],
            [-42.904208185, -20.428210265],
            [-42.9046755849999, -20.428521248],
            [-42.906766235, -20.428321098],
            [-42.907000525999898, -20.428297003999901],
            [-42.907100920999902, -20.428221807999901],
            [-42.907196363, -20.428125802999901],
            [-42.907171832, -20.427911408999901],
            [-42.907174843, -20.427744622],
            [-42.907318382, -20.427313560999899],
            [-42.907353337, -20.427226049],
            [-42.907421747999898, -20.427054773],
            [-42.907467987, -20.42693901],
            [-42.90760481, -20.426751964999902],
            [-42.907718578, -20.42645605],
            [-42.907759197, -20.426194254999899],
            [-42.907717182999903, -20.425994913999901],
            [-42.907508499999899, -20.425627779],
            [-42.907425450999902, -20.4254318],
            [-42.907373042, -20.425306615999901],
            [-42.907343227, -20.425024871999899],
            [-42.907334359999901, -20.424683586],
            [-42.907529982, -20.424322929],
            [-42.907755473999899, -20.4241132239999],
            [-42.908057289999903, -20.424032714],
            [-42.908300343999898, -20.423861431],
            [-42.908526633, -20.423641744999902],
            [-42.9088594, -20.423245809],
            [-42.909056407, -20.422842822],
            [-42.909131649, -20.422316077999898],
            [-42.909163774, -20.4213383919999],
            [-42.909248931, -20.420944705999901],
            [-42.909132445, -20.4206158],
            [-42.908990113999899, -20.420326401],
            [-42.908965754999898, -20.420107717],
            [-42.908860438, -20.419747001],
            [-42.908836696, -20.419294094],
            [-42.908929069, -20.418896242999899],
            [-42.909040169, -20.418656099],
            [-42.908989548, -20.418252089],
            [-42.908953482, -20.418120341],
            [-42.908868223999903, -20.417980047],
            [-42.908713897, -20.417863452999899],
            [-42.908425489, -20.417662004],
            [-42.908214223999899, -20.417507124999901],
            [-42.908063042, -20.417431778],
            [-42.907926696999901, -20.4174205479999],
            [-42.907856645, -20.417484615],
            [-42.90779667, -20.4175214589999],
            [-42.907761697999902, -20.417536846]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 11,
        "id": 36922,
        "CD_SETOR": "315210505060004P",
        "AREA_KM2": 0.381566,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 68
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.90799455, -20.428795776],
            [-42.908070253999902, -20.428878261],
            [-42.908279928999903, -20.428977819999901],
            [-42.9087754399999, -20.429190465],
            [-42.909138938, -20.429396380999901],
            [-42.909342207999899, -20.429697303999902],
            [-42.909553936999899, -20.429914005999901],
            [-42.909892095, -20.430160391999902],
            [-42.910003979, -20.430078878],
            [-42.910867907999901, -20.429236623999898],
            [-42.911424306999898, -20.428731517],
            [-42.911951203999898, -20.428498577],
            [-42.912340223999898, -20.428436834],
            [-42.912837049, -20.428443396],
            [-42.913342020999899, -20.4285490299999],
            [-42.913688056, -20.428743557],
            [-42.91402297, -20.428795195],
            [-42.914217551, -20.42879254],
            [-42.914459909999898, -20.4287116419999],
            [-42.914620918, -20.428605185],
            [-42.914764221, -20.428517814],
            [-42.914849971, -20.428421906],
            [-42.9149317, -20.428334569999901],
            [-42.914969301, -20.428255055],
            [-42.914981092999902, -20.428168453999898],
            [-42.91502758, -20.428119470999899],
            [-42.915144551, -20.428106773],
            [-42.915268174999902, -20.428075622999899],
            [-42.915375018, -20.428044579],
            [-42.915524733, -20.427987662],
            [-42.915614827999903, -20.428000559999901],
            [-42.915752123999901, -20.427898295],
            [-42.915779611999902, -20.427815984999899],
            [-42.915762667, -20.427747648],
            [-42.915900799, -20.427583585],
            [-42.916012894999902, -20.427437573],
            [-42.916066348, -20.427266066],
            [-42.916083767, -20.427207182],
            [-42.916042445999899, -20.427088743],
            [-42.915900635999897, -20.426926849],
            [-42.915891427, -20.426701084999898],
            [-42.915949522999902, -20.4263496569999],
            [-42.9159244209999, -20.426078208],
            [-42.915858414999903, -20.425867321999899],
            [-42.915840607, -20.425566169],
            [-42.915854050999897, -20.425484933999901],
            [-42.915805623, -20.425457133],
            [-42.915758390999898, -20.425376528999902],
            [-42.915706719, -20.42529862],
            [-42.915700624, -20.425188295999899],
            [-42.915743442999897, -20.425137360999901],
            [-42.915814135999902, -20.425031928],
            [-42.915858601, -20.424951909],
            [-42.915878095, -20.4248689349999],
            [-42.915876255999898, -20.4247392199999],
            [-42.915863941999902, -20.4246740899999],
            [-42.9157855509999, -20.424499335999901],
            [-42.9158261709999, -20.424399197],
            [-42.915845917999903, -20.4243349229999],
            [-42.915840799, -20.42423471],
            [-42.915832135, -20.424156341],
            [-42.915849699, -20.424065108],
            [-42.91587928, -20.423995234],
            [-42.915928381999898, -20.4239768969999],
            [-42.915973775999902, -20.4238587799999],
            [-42.915978639999899, -20.423795335],
            [-42.915983181999898, -20.423694097999899],
            [-42.916057910999903, -20.423546555],
            [-42.916080627, -20.423483100999899],
            [-42.916110049999901, -20.423411393],
            [-42.916150337, -20.423342545],
            [-42.9161627, -20.423275869],
            [-42.916252296, -20.423180756],
            [-42.916302424, -20.423098013999901],
            [-42.916354032, -20.4229802259999],
            [-42.916417617, -20.422928839999901],
            [-42.916402739, -20.422834464999902],
            [-42.916333236999897, -20.422830392],
            [-42.916278670999901, -20.422755627999901],
            [-42.916298442, -20.42268917],
            [-42.916394382, -20.422602088],
            [-42.9164725259999, -20.422549558999901],
            [-42.916467559, -20.422521779],
            [-42.916427551, -20.42235026],
            [-42.916447526, -20.422265357999901],
            [-42.916484211, -20.422214428],
            [-42.916539249, -20.4221781319999],
            [-42.916587008, -20.422100545],
            [-42.916632757999899, -20.422036601999899],
            [-42.916638256999903, -20.421973468],
            [-42.916670842, -20.421931050999898],
            [-42.916660600999897, -20.421808847],
            [-42.916682208999902, -20.421753817],
            [-42.916481274, -20.421636063999902],
            [-42.916247572, -20.421409586],
            [-42.916085505, -20.421253686999901],
            [-42.91595768, -20.421134965],
            [-42.915891802, -20.421102355999899],
            [-42.915677219, -20.421016199999901],
            [-42.915589167999897, -20.421052835],
            [-42.915395017, -20.421133616],
            [-42.914935363, -20.420985752],
            [-42.914855741999901, -20.420960138999899],
            [-42.9146116759999, -20.420912954999899],
            [-42.914482106, -20.420912173],
            [-42.91434158, -20.420926506999901],
            [-42.914166308999903, -20.420944383999899],
            [-42.914082127, -20.420993113],
            [-42.914043862, -20.421015263],
            [-42.913986265999903, -20.421093442],
            [-42.914011565, -20.421230722],
            [-42.914024559, -20.421751902],
            [-42.914176943, -20.422536206],
            [-42.913999996, -20.422725048],
            [-42.914103247999897, -20.422996896],
            [-42.913904514, -20.423186911999899],
            [-42.913569542999902, -20.423818728],
            [-42.913270485999902, -20.423891388],
            [-42.912837725, -20.423889383],
            [-42.912778364, -20.423954866],
            [-42.912668601999897, -20.424099560999899],
            [-42.912391935999899, -20.424385347999898],
            [-42.9121818099999, -20.424577160999899],
            [-42.911896344, -20.425040994],
            [-42.911572433999901, -20.425591596999901],
            [-42.9112819489999, -20.426012952999901],
            [-42.910918275, -20.426519097],
            [-42.910758253, -20.426635734999898],
            [-42.910559894, -20.426718074],
            [-42.909707608, -20.427106449],
            [-42.909598402, -20.427207779],
            [-42.909582348, -20.427368892999901],
            [-42.909419895, -20.427565174999899],
            [-42.909082666, -20.427852131],
            [-42.908987189, -20.427959186],
            [-42.908416463, -20.428441973],
            [-42.90799455, -20.428795776]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 12,
        "id": 36923,
        "CD_SETOR": "315210505060005P",
        "AREA_KM2": 0.097183,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 325
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.912754887999903, -20.417464142],
            [-42.912747171, -20.417569883999899],
            [-42.912735912, -20.417724172],
            [-42.912730337, -20.417800563999901],
            [-42.91268187, -20.417994737],
            [-42.912669888, -20.4180424739999],
            [-42.912632335, -20.418108252],
            [-42.912612924, -20.418149491],
            [-42.912568228999902, -20.41823346],
            [-42.912488822999897, -20.41838397],
            [-42.912423287, -20.41850576],
            [-42.912500457, -20.418572116],
            [-42.912639392, -20.418667823],
            [-42.912726633, -20.4187211],
            [-42.912792672, -20.418765388999901],
            [-42.912850279999901, -20.418803490999899],
            [-42.912869368, -20.418816116],
            [-42.912953708, -20.418882873],
            [-42.912995021, -20.4188212339999],
            [-42.913027638, -20.418756021],
            [-42.913205703, -20.418400006999899],
            [-42.913494, -20.41787291],
            [-42.913608613999898, -20.417634962],
            [-42.913656606, -20.417292173],
            [-42.913684960999902, -20.417120064],
            [-42.9137282149999, -20.416987318999901],
            [-42.913846534, -20.416844373999901],
            [-42.914085969, -20.416657605999902],
            [-42.914217876999899, -20.4165843759999],
            [-42.914422821999899, -20.416506259999899],
            [-42.914495077, -20.416489946999899],
            [-42.914602691, -20.416494871999902],
            [-42.914660571999903, -20.41652412],
            [-42.914779414, -20.416635898],
            [-42.914907148999902, -20.416834783999899],
            [-42.915425238999902, -20.4166199759999],
            [-42.915950772, -20.416379891],
            [-42.916332297999901, -20.417114953999899],
            [-42.916552625, -20.416750491999899],
            [-42.916651290999901, -20.4167605979999],
            [-42.916721763999902, -20.416759471],
            [-42.916782105, -20.416581178999898],
            [-42.916870372999902, -20.416308904999902],
            [-42.916901459, -20.415953176999899],
            [-42.916781597, -20.415578641],
            [-42.916587622, -20.415179274],
            [-42.916391643999901, -20.414855963],
            [-42.915986797, -20.414839175999901],
            [-42.915561355, -20.41486685],
            [-42.915211999999897, -20.414883679],
            [-42.914804726999897, -20.414894816],
            [-42.914406353999901, -20.414836899],
            [-42.914032802999898, -20.414860094999899],
            [-42.913597584999899, -20.41493229],
            [-42.913264275, -20.41508418],
            [-42.912978591, -20.415269754999901],
            [-42.912863756, -20.415430171],
            [-42.912859609, -20.415707144],
            [-42.912879174999901, -20.416440026],
            [-42.912902057999901, -20.416863063999902],
            [-42.912897164999897, -20.417216981],
            [-42.912894931, -20.417285561],
            [-42.912824907999898, -20.417436008],
            [-42.912754887999903, -20.417464142]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 13,
        "id": 36924,
        "CD_SETOR": "315210505060006P",
        "AREA_KM2": 0.531578,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 192
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.919361701, -20.41801241],
            [-42.91936284, -20.418014416999899],
            [-42.919327848, -20.418028826],
            [-42.919263811999897, -20.418060829],
            [-42.919199616, -20.418092913],
            [-42.919165348, -20.418110038999899],
            [-42.919085824, -20.418195388999901],
            [-42.919046082, -20.418238044],
            [-42.918982921, -20.418440387],
            [-42.918972774999901, -20.4184974799999],
            [-42.918908739, -20.418855043],
            [-42.9188592369999, -20.419105090999899],
            [-42.91873372, -20.4193839829999],
            [-42.918669195999897, -20.419538836999902],
            [-42.918668173999897, -20.419635392],
            [-42.918674779, -20.419711279999898],
            [-42.918719565999901, -20.419790174],
            [-42.918807992, -20.419868884],
            [-42.918856049999903, -20.419883644],
            [-42.918929466, -20.4198986769999],
            [-42.918931761, -20.4200337259999],
            [-42.918867366, -20.4202683419999],
            [-42.918820061, -20.420403066999899],
            [-42.918651778, -20.420643804999902],
            [-42.918558172, -20.420728220999901],
            [-42.918512968, -20.420826853999898],
            [-42.918425899999903, -20.420967095],
            [-42.918317398999903, -20.421092293],
            [-42.918234807, -20.421141575],
            [-42.918074921, -20.421171785999899],
            [-42.917756074, -20.421197283],
            [-42.917702552, -20.421235479],
            [-42.91770014, -20.421336773],
            [-42.917892762999898, -20.42163999],
            [-42.918242109999902, -20.421952185],
            [-42.918641293, -20.422144218],
            [-42.919422217, -20.422196009999901],
            [-42.91974027, -20.422081247999898],
            [-42.919990379999902, -20.422159096],
            [-42.9201202, -20.422419939],
            [-42.920163814, -20.422655442],
            [-42.920158349, -20.423087236999901],
            [-42.920133537, -20.423787214],
            [-42.920139895, -20.42436728],
            [-42.920185913999902, -20.425081651],
            [-42.920058835, -20.42596091],
            [-42.919967615, -20.426696316999902],
            [-42.920038709, -20.427243028],
            [-42.920218626, -20.427612021],
            [-42.920438073999897, -20.427852798999901],
            [-42.920835269, -20.42803344],
            [-42.921314003, -20.428038182],
            [-42.921800931999897, -20.427917829],
            [-42.922263380999901, -20.427641257999898],
            [-42.92250848, -20.427280263999901],
            [-42.923172843, -20.426313184],
            [-42.923350958999897, -20.42581797],
            [-42.924451476999899, -20.425868434999899],
            [-42.9249777489999, -20.426041637],
            [-42.925636789999899, -20.426080059999901],
            [-42.926225816999903, -20.424520293999901],
            [-42.926036675, -20.424466145999901],
            [-42.925779866, -20.424406037],
            [-42.925636473999901, -20.424371171],
            [-42.925348745999898, -20.424194932],
            [-42.925120426, -20.4239558629999],
            [-42.924902309999901, -20.423609785999901],
            [-42.92468337, -20.4233543409999],
            [-42.924373424, -20.423128248999902],
            [-42.924345925999901, -20.422871282],
            [-42.924371779999902, -20.4226143499999],
            [-42.924260118999896, -20.4223889209999],
            [-42.924117623, -20.422242552999901],
            [-42.924140436, -20.421857494999902],
            [-42.924131835, -20.421561788],
            [-42.924133977999901, -20.4212449629999],
            [-42.924067674999897, -20.4209236529999],
            [-42.9255219779999, -20.420775342999899],
            [-42.9269884079999, -20.420806959999901],
            [-42.928149775999898, -20.420792295999899],
            [-42.929380377, -20.4208228809999],
            [-42.930136025, -20.420703932],
            [-42.930245219, -20.4208630319999],
            [-42.931305419999902, -20.420711302],
            [-42.931245428, -20.420423398],
            [-42.931003825999902, -20.420167666999902],
            [-42.930874458999902, -20.420229998],
            [-42.930726740999901, -20.4203314179999],
            [-42.930671237, -20.420466887],
            [-42.930272011999897, -20.4205646999999],
            [-42.930215474, -20.420343839999902],
            [-42.930077807, -20.420375830999902],
            [-42.929964278999897, -20.420403931],
            [-42.929515217999899, -20.420471859],
            [-42.929259412, -20.420474758999902],
            [-42.9287966529999, -20.420480007],
            [-42.92847133, -20.420483696],
            [-42.927242212, -20.4204882189999],
            [-42.925527579999901, -20.420523848999899],
            [-42.925201301, -20.420532041999898],
            [-42.924215143, -20.420583509],
            [-42.924106083, -20.420574249],
            [-42.924032580999899, -20.420545504],
            [-42.923446949, -20.420153071999898],
            [-42.922854962, -20.419785783999899],
            [-42.922511864999898, -20.419567222],
            [-42.922389157999902, -20.419437409],
            [-42.922222723999901, -20.419266888],
            [-42.921983963999899, -20.419022261],
            [-42.921884523, -20.418935469],
            [-42.921762491, -20.418828957999899],
            [-42.921650562, -20.418774377],
            [-42.921452292, -20.4186857199999],
            [-42.921315144999902, -20.418658643],
            [-42.921573162, -20.418602615999902],
            [-42.921657154999899, -20.418572175],
            [-42.921685716, -20.418529880999898],
            [-42.921707268, -20.4184686749999],
            [-42.921719622, -20.4183846459999],
            [-42.921683545999898, -20.418263477],
            [-42.921395576, -20.418103991999899],
            [-42.921102941, -20.4178489],
            [-42.920824452, -20.417600095],
            [-42.920554298999903, -20.417326924999902],
            [-42.920444792, -20.417200266999899],
            [-42.920404194999897, -20.417237535],
            [-42.920309045, -20.417324884],
            [-42.920227777, -20.417386168],
            [-42.920137994999898, -20.417431957],
            [-42.919906168999901, -20.4175895999999],
            [-42.919840737999898, -20.41765964],
            [-42.919801004, -20.417763543],
            [-42.919723073999897, -20.417858973],
            [-42.919634596, -20.4179197029999],
            [-42.919497102, -20.417963571],
            [-42.919361701, -20.41801241]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 14,
        "id": 36925,
        "CD_SETOR": "315210505060007P",
        "AREA_KM2": 0.181374,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 168
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.920444792, -20.417200266999899],
            [-42.920554298999903, -20.417326924999902],
            [-42.920824452, -20.417600095],
            [-42.921102941, -20.4178489],
            [-42.921395576, -20.418103991999899],
            [-42.921683545999898, -20.418263477],
            [-42.921719622, -20.4183846459999],
            [-42.921707268, -20.4184686749999],
            [-42.921685716, -20.418529880999898],
            [-42.921657154999899, -20.418572175],
            [-42.921573162, -20.418602615999902],
            [-42.921315144999902, -20.418658643],
            [-42.921452292, -20.4186857199999],
            [-42.921650562, -20.418774377],
            [-42.921762491, -20.418828957999899],
            [-42.921884523, -20.418935469],
            [-42.921983963999899, -20.419022261],
            [-42.922222723999901, -20.419266888],
            [-42.922389157999902, -20.419437409],
            [-42.922511864999898, -20.419567222],
            [-42.922854962, -20.419785783999899],
            [-42.923446949, -20.420153071999898],
            [-42.924032580999899, -20.420545504],
            [-42.924106083, -20.420574249],
            [-42.924215143, -20.420583509],
            [-42.925201301, -20.420532041999898],
            [-42.925527579999901, -20.420523848999899],
            [-42.927242212, -20.4204882189999],
            [-42.92847133, -20.420483696],
            [-42.9287966529999, -20.420480007],
            [-42.929259412, -20.420474758999902],
            [-42.929515217999899, -20.420471859],
            [-42.929964278999897, -20.420403931],
            [-42.930077807, -20.420375830999902],
            [-42.930215474, -20.420343839999902],
            [-42.930272011999897, -20.4205646999999],
            [-42.930671237, -20.420466887],
            [-42.930726740999901, -20.4203314179999],
            [-42.930874458999902, -20.420229998],
            [-42.931003825999902, -20.420167666999902],
            [-42.931768177, -20.417930301999899],
            [-42.931604235999899, -20.417874153],
            [-42.9314564599999, -20.417795683999898],
            [-42.931263633, -20.417712072],
            [-42.931098554, -20.417663339999901],
            [-42.930955227, -20.4176210279999],
            [-42.930723478999901, -20.417600792],
            [-42.930459115, -20.417611058],
            [-42.93020826, -20.417671373],
            [-42.930010523, -20.417804121],
            [-42.929829839, -20.417993760999899],
            [-42.92958386, -20.418226617999899],
            [-42.929290535999897, -20.4185247],
            [-42.928836164, -20.418962555999901],
            [-42.928659129, -20.419097517],
            [-42.928520854999903, -20.419175002],
            [-42.928425018, -20.419211343],
            [-42.928200911, -20.419276394999901],
            [-42.927967974999902, -20.419286142],
            [-42.927384725, -20.419274704],
            [-42.926762821, -20.419254587999902],
            [-42.925993644, -20.419230942],
            [-42.9256236149999, -20.419227276999901],
            [-42.9246262569999, -20.419201191],
            [-42.924201487999902, -20.419174616],
            [-42.924093997999897, -20.4191498],
            [-42.9234219269999, -20.418956170999898],
            [-42.923015146, -20.418752675],
            [-42.922211784, -20.418159854999899],
            [-42.921111432, -20.4173320089999],
            [-42.921001921, -20.417243097],
            [-42.92059396, -20.416901825],
            [-42.920592041, -20.4169060249999],
            [-42.920520468999896, -20.417071407],
            [-42.920444792, -20.417200266999899]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 15,
        "id": 36926,
        "CD_SETOR": "315210505060009P",
        "AREA_KM2": 0.057605,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 237
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.920489652999898, -20.409380081999899],
            [-42.920522941999899, -20.409898445],
            [-42.920596007, -20.411023179],
            [-42.92062162, -20.411417179999901],
            [-42.921197021, -20.411263624999901],
            [-42.92181951, -20.4111132959999],
            [-42.9224206539999, -20.410961307],
            [-42.9223756069999, -20.409928024],
            [-42.922311602, -20.408406226],
            [-42.922282256, -20.408126912999901],
            [-42.9222203429999, -20.407593613],
            [-42.921642574, -20.408192970999899],
            [-42.921097373999899, -20.408815382999901],
            [-42.920489652999898, -20.409380081999899]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 16,
        "id": 36927,
        "CD_SETOR": "315210505060010P",
        "AREA_KM2": 0.066411,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 226
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.9188871489999, -20.4139600469999],
            [-42.919039514, -20.414104739],
            [-42.919150237999901, -20.414233550999899],
            [-42.919295027, -20.414443682],
            [-42.9195641479999, -20.414980779],
            [-42.920050889, -20.414828918999898],
            [-42.920845921, -20.414609504999898],
            [-42.920751607999897, -20.413086705999898],
            [-42.9207080259999, -20.41288373],
            [-42.920659284, -20.412073079],
            [-42.92062162, -20.411417179999901],
            [-42.920596007, -20.411023179],
            [-42.920522941999899, -20.409898445],
            [-42.920489652999898, -20.409380081999899],
            [-42.920434276, -20.409506732999901],
            [-42.92033809, -20.409698122999899],
            [-42.920181735, -20.410155196999899],
            [-42.919946549, -20.410926763],
            [-42.919628695, -20.411830375],
            [-42.9188871489999, -20.4139600469999]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 17,
        "id": 36928,
        "CD_SETOR": "315210505060011P",
        "AREA_KM2": 0.190875,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 60
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.915609001999897, -20.410326158],
            [-42.915675409999899, -20.410384598999901],
            [-42.915784206999902, -20.410450092],
            [-42.916005737, -20.410529446999899],
            [-42.916141044, -20.4105507199999],
            [-42.916255313, -20.410550579999899],
            [-42.916363063999903, -20.410508137],
            [-42.916459863999897, -20.410448787],
            [-42.916564419999901, -20.410345748999902],
            [-42.91668634, -20.41019477],
            [-42.916821130999899, -20.409989757999899],
            [-42.917068918, -20.409586497],
            [-42.917199557999901, -20.409371913],
            [-42.917463827999903, -20.409021792],
            [-42.917593452999903, -20.408935648999901],
            [-42.917729252, -20.408878495],
            [-42.917982491, -20.408787854],
            [-42.918213614, -20.4087341809999],
            [-42.918389519999899, -20.408701166999901],
            [-42.918516928, -20.408717635999899],
            [-42.918826642999903, -20.408774010999899],
            [-42.918976267999902, -20.408848699],
            [-42.919191879, -20.408976942],
            [-42.91933276, -20.409147601],
            [-42.919421891999903, -20.409309783],
            [-42.919559664, -20.409586039],
            [-42.919602915, -20.409778968999898],
            [-42.919619669, -20.409912698],
            [-42.919640618, -20.410053474999899],
            [-42.919659232, -20.4101355019999],
            [-42.919410284, -20.41075833],
            [-42.919164529999897, -20.410901968],
            [-42.918928315999899, -20.411087473],
            [-42.918728815999899, -20.411291352],
            [-42.918528138999903, -20.411528119],
            [-42.918300062, -20.4118525539999],
            [-42.918144923999897, -20.412173313],
            [-42.917963538, -20.412592178],
            [-42.917815243999897, -20.412880002999898],
            [-42.917672406, -20.413102260999899],
            [-42.917460564999899, -20.413373367],
            [-42.917331823, -20.413525397999901],
            [-42.917481388999903, -20.413527288999902],
            [-42.917686621, -20.413520177999899],
            [-42.918028432, -20.4135483539999],
            [-42.918306022, -20.413620520999899],
            [-42.918437109, -20.413674252],
            [-42.918665204, -20.413814511999899],
            [-42.9188871489999, -20.4139600469999],
            [-42.919628695, -20.411830375],
            [-42.919946549, -20.410926763],
            [-42.920181735, -20.410155196999899],
            [-42.92033809, -20.409698122999899],
            [-42.920434276, -20.409506732999901],
            [-42.920489652999898, -20.409380081999899],
            [-42.921097373999899, -20.408815382999901],
            [-42.921642574, -20.408192970999899],
            [-42.9222203429999, -20.407593613],
            [-42.922205752, -20.407495728999901],
            [-42.922202915999897, -20.407407804999899],
            [-42.922210506999903, -20.407196661999901],
            [-42.922171067, -20.4069682149999],
            [-42.92219391, -20.40677148],
            [-42.92222528, -20.4066512479999],
            [-42.922286015999902, -20.406548981],
            [-42.922387934, -20.40647182],
            [-42.922454022999901, -20.406409138999901],
            [-42.922536421, -20.406281077],
            [-42.922333249, -20.4061504169999],
            [-42.922186193, -20.406016823],
            [-42.921927672, -20.405773326],
            [-42.921832438, -20.405808822],
            [-42.921446064999898, -20.406141684],
            [-42.921271829, -20.406306819999902],
            [-42.92098305, -20.406470246],
            [-42.920823565, -20.406570250999899],
            [-42.920502477, -20.406859588],
            [-42.919930184, -20.407251996],
            [-42.919352133, -20.407239015999899],
            [-42.918906901999897, -20.407168795],
            [-42.918457722, -20.407039062],
            [-42.918354958999899, -20.407067454],
            [-42.918044689999903, -20.407207535999898],
            [-42.917685252999902, -20.407412921999899],
            [-42.917257244, -20.407700509999898],
            [-42.916857289999903, -20.4081471289999],
            [-42.91659157, -20.4085267849999],
            [-42.916417361, -20.408861822999899],
            [-42.916279768, -20.409515851],
            [-42.91604269, -20.410018697999899],
            [-42.915757852, -20.410214206],
            [-42.915641471, -20.410298254999901],
            [-42.915609001999897, -20.410326158]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 18,
        "id": 36929,
        "CD_SETOR": "315210505060012P",
        "AREA_KM2": 1.234002,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 246
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.908711787, -20.407561632],
            [-42.90871034, -20.407585845],
            [-42.908707533, -20.407632828],
            [-42.908672996999897, -20.407710827],
            [-42.908637121, -20.4077927609999],
            [-42.908560006, -20.407943969],
            [-42.908481485, -20.408097659],
            [-42.908235966999897, -20.40849514],
            [-42.907714964, -20.409279602],
            [-42.907308738, -20.409863414],
            [-42.907026067999901, -20.410269657],
            [-42.906920070999902, -20.410396378],
            [-42.907001451, -20.410652146],
            [-42.906926164, -20.410821449],
            [-42.906972179, -20.411000762],
            [-42.907173122, -20.411348775],
            [-42.907287021999899, -20.411516876999901],
            [-42.907795758, -20.411747735999899],
            [-42.908196646, -20.411925363],
            [-42.908541367999902, -20.412080839999899],
            [-42.908906029, -20.412408522],
            [-42.909478803, -20.413022198],
            [-42.909648335, -20.413438202],
            [-42.910102327999901, -20.414321774],
            [-42.910385114, -20.414662861],
            [-42.910468683, -20.414842597],
            [-42.910518345, -20.414867202],
            [-42.910840155, -20.415074481],
            [-42.910915062999898, -20.414641498],
            [-42.910975123, -20.414466906],
            [-42.911084190999901, -20.414221305],
            [-42.911207112999897, -20.414053228999901],
            [-42.911526513, -20.413895086],
            [-42.911628306999901, -20.413875542],
            [-42.911908065, -20.4139207119999],
            [-42.912109831, -20.413961647],
            [-42.912473238, -20.414038795],
            [-42.9126048689999, -20.414046164],
            [-42.912705499, -20.414049591],
            [-42.912959412, -20.414018132],
            [-42.913135498, -20.414002434999901],
            [-42.913320702, -20.414026669],
            [-42.913461115, -20.414032998],
            [-42.913562466, -20.4140336409999],
            [-42.913657314, -20.414019197],
            [-42.913808342, -20.413968229],
            [-42.9139496929999, -20.413956599999899],
            [-42.914285629999902, -20.413981657],
            [-42.914497401999903, -20.414033693],
            [-42.914622977, -20.414035409999901],
            [-42.914958438, -20.4140587969999],
            [-42.914988824, -20.414070701999901],
            [-42.915049193, -20.414092173],
            [-42.914938728, -20.414011435],
            [-42.914888323, -20.413865578],
            [-42.914809665999897, -20.4137685809999],
            [-42.914700153999902, -20.413634073],
            [-42.914574876, -20.413366944],
            [-42.913874629, -20.413216447],
            [-42.913988111, -20.412623738],
            [-42.914005480999897, -20.412258127],
            [-42.9137847, -20.412209364],
            [-42.913470963, -20.41196077],
            [-42.913408215999901, -20.411931366],
            [-42.911762525, -20.411714848],
            [-42.911702628999898, -20.41169079],
            [-42.911309974999902, -20.411451930999899],
            [-42.910720136, -20.411113331],
            [-42.91061596, -20.411086676],
            [-42.910549407, -20.410766453999901],
            [-42.910462095, -20.4104966109999],
            [-42.910517023999901, -20.410445141],
            [-42.910622015999898, -20.4104155869999],
            [-42.910773107999901, -20.410404647999901],
            [-42.910922501, -20.41040888],
            [-42.91102362, -20.410436385],
            [-42.911136354, -20.410475108999901],
            [-42.911322470999899, -20.41055055],
            [-42.911471538999898, -20.4106249509999],
            [-42.912026258, -20.41090589],
            [-42.912161176, -20.410932482],
            [-42.912344757, -20.41094407],
            [-42.912558972999904, -20.410915404],
            [-42.912707374, -20.410890757999901],
            [-42.912876347999898, -20.4108393019999],
            [-42.913004015, -20.410800424999898],
            [-42.913169739, -20.4107268079999],
            [-42.913354317, -20.410633844],
            [-42.913501165, -20.410549278],
            [-42.913626205, -20.410457649],
            [-42.913717434, -20.410373321],
            [-42.913913089999902, -20.410105652],
            [-42.914092734, -20.409884351999899],
            [-42.9142058589999, -20.409737153],
            [-42.914349011999903, -20.409552421999901],
            [-42.914407097, -20.409497454],
            [-42.914461648, -20.409440237],
            [-42.914555838999902, -20.409373834],
            [-42.914577956, -20.4092779499999],
            [-42.914600569999898, -20.409179459],
            [-42.91464395, -20.409013033999901],
            [-42.91471544, -20.408875035999898],
            [-42.914789991, -20.408722476],
            [-42.914805742999903, -20.408592623999901],
            [-42.914801864, -20.408448491999899],
            [-42.914782647, -20.408312918],
            [-42.914764694, -20.408154383],
            [-42.914754084, -20.408031562],
            [-42.914769971999903, -20.407821612],
            [-42.914863289, -20.407892728],
            [-42.915011459, -20.408138338],
            [-42.915219635, -20.408460657],
            [-42.915298355999902, -20.40862255],
            [-42.915307842999901, -20.408728675],
            [-42.91531223, -20.408929692999902],
            [-42.915330229999903, -20.409090642],
            [-42.915353018, -20.409302282999899],
            [-42.915389522999902, -20.409543184],
            [-42.915404716999902, -20.409653245],
            [-42.91541233, -20.409909064],
            [-42.915424684999898, -20.409980128],
            [-42.915432413, -20.410062497],
            [-42.915460157, -20.410129971999901],
            [-42.915511772999899, -20.4102111239999],
            [-42.91553757, -20.410243086],
            [-42.915554108, -20.410263507],
            [-42.915576090999899, -20.410290707999899],
            [-42.915594246999902, -20.410313173],
            [-42.915609001999897, -20.410326158],
            [-42.915641471, -20.410298254999901],
            [-42.915757852, -20.410214206],
            [-42.91604269, -20.410018697999899],
            [-42.916279768, -20.409515851],
            [-42.916417361, -20.408861822999899],
            [-42.91659157, -20.4085267849999],
            [-42.916857289999903, -20.4081471289999],
            [-42.917257244, -20.407700509999898],
            [-42.917685252999902, -20.407412921999899],
            [-42.918044689999903, -20.407207535999898],
            [-42.918354958999899, -20.407067454],
            [-42.918457722, -20.407039062],
            [-42.918906901999897, -20.407168795],
            [-42.919352133, -20.407239015999899],
            [-42.919930184, -20.407251996],
            [-42.920502477, -20.406859588],
            [-42.920823565, -20.406570250999899],
            [-42.92098305, -20.406470246],
            [-42.921271829, -20.406306819999902],
            [-42.921446064999898, -20.406141684],
            [-42.921832438, -20.405808822],
            [-42.921927672, -20.405773326],
            [-42.92183024, -20.405677981999901],
            [-42.920165066, -20.404063216999901],
            [-42.917860316, -20.401857766],
            [-42.914023094, -20.398184918],
            [-42.913725272, -20.3978972859999],
            [-42.912467929999899, -20.398872056999899],
            [-42.910890115, -20.400406932],
            [-42.912610480999902, -20.401992298],
            [-42.912660921999901, -20.4020237239999],
            [-42.912716691999897, -20.402080340999898],
            [-42.912753379, -20.402553769999901],
            [-42.912732994, -20.402712337],
            [-42.911717962, -20.404062795],
            [-42.910789414, -20.405214941999901],
            [-42.910603034, -20.405442884],
            [-42.910339576999903, -20.405671954999899],
            [-42.910206288, -20.4057443959999],
            [-42.910056731999902, -20.405825679],
            [-42.909842929, -20.405931917],
            [-42.90968011, -20.405992985999902],
            [-42.909426531, -20.406079256999899],
            [-42.909077260999901, -20.406194189],
            [-42.909010329, -20.406218532],
            [-42.9088885359999, -20.406299271],
            [-42.908784507, -20.406385214],
            [-42.908723078, -20.406485206],
            [-42.908703199999898, -20.4065601309999],
            [-42.908674690999902, -20.406667591],
            [-42.908695764, -20.4069014099999],
            [-42.90872912, -20.407271528],
            [-42.908711787, -20.407561632]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 19,
        "id": 36930,
        "CD_SETOR": "315210505060013P",
        "AREA_KM2": 0.490052,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 319
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.901197040999897, -20.405639224],
            [-42.901235646, -20.405715432],
            [-42.901222479, -20.406259593],
            [-42.901199409999897, -20.4068046199999],
            [-42.901186825, -20.406920202],
            [-42.901115556, -20.406952209999901],
            [-42.901089641999903, -20.4070272129999],
            [-42.901097845, -20.407237762],
            [-42.901068209, -20.407545956],
            [-42.901012835, -20.407886859],
            [-42.900987143, -20.4079907039999],
            [-42.900968712, -20.408097284],
            [-42.90091647, -20.408255138],
            [-42.900737224, -20.40853548],
            [-42.900670765, -20.408641503999899],
            [-42.9006356209999, -20.4087536229999],
            [-42.900556032999901, -20.408976304],
            [-42.900537772, -20.409171634],
            [-42.90055589, -20.40921893],
            [-42.900651352999901, -20.409313563],
            [-42.9005696689999, -20.409344892],
            [-42.900531144, -20.409480301],
            [-42.900495309, -20.4096139399999],
            [-42.900507314, -20.409714645],
            [-42.900598430999899, -20.409954171],
            [-42.900669939999901, -20.410115658999899],
            [-42.900749993, -20.410403323],
            [-42.900754563, -20.410451902],
            [-42.900773885, -20.410535165999899],
            [-42.90080598, -20.4106195049999],
            [-42.900815023999897, -20.410806081],
            [-42.900815499, -20.410961712],
            [-42.900827266, -20.411212985],
            [-42.900819758999901, -20.411622526],
            [-42.900831223, -20.4120557379999],
            [-42.900828796, -20.412143226],
            [-42.900824395, -20.412301831],
            [-42.900817657999902, -20.412504027999901],
            [-42.9008118369999, -20.4126565669999],
            [-42.90085791, -20.412861284],
            [-42.900909219, -20.413105202999901],
            [-42.901064391, -20.413316501999901],
            [-42.901309894999898, -20.413495312],
            [-42.901446291999903, -20.413582264999899],
            [-42.901594654, -20.413685288],
            [-42.901866378, -20.413735991],
            [-42.902061063, -20.413816440999899],
            [-42.902317063999902, -20.413987425],
            [-42.902492238, -20.414121311],
            [-42.902759365999898, -20.414305037],
            [-42.903077449, -20.41441342],
            [-42.903449125999899, -20.414508745],
            [-42.903744998, -20.414491941999898],
            [-42.904024314, -20.414417528999898],
            [-42.904600301999899, -20.414164791],
            [-42.904971955999898, -20.413907879],
            [-42.905167478, -20.4137682859999],
            [-42.905259848999897, -20.413692690999898],
            [-42.905324793, -20.413634413999901],
            [-42.905279799, -20.413604486999901],
            [-42.905289361, -20.413563369],
            [-42.905290532, -20.412438864],
            [-42.905387081999898, -20.412040498],
            [-42.905482702, -20.411746471999901],
            [-42.905642235999899, -20.411448681],
            [-42.905852108, -20.411231476999902],
            [-42.906300518, -20.411111841],
            [-42.906655726999901, -20.410983734],
            [-42.906926164, -20.410821449],
            [-42.907001451, -20.410652146],
            [-42.906920070999902, -20.410396378],
            [-42.907026067999901, -20.410269657],
            [-42.907308738, -20.409863414],
            [-42.907714964, -20.409279602],
            [-42.908235966999897, -20.40849514],
            [-42.908481485, -20.408097659],
            [-42.908560006, -20.407943969],
            [-42.908637121, -20.4077927609999],
            [-42.908672996999897, -20.407710827],
            [-42.908707533, -20.407632828],
            [-42.90871034, -20.407585845],
            [-42.90837199, -20.407781002],
            [-42.908224273, -20.407913747999899],
            [-42.9079637, -20.408044918],
            [-42.907817688, -20.408073158],
            [-42.907316697999903, -20.408019764999899],
            [-42.90681983, -20.407884583999898],
            [-42.906683883, -20.407796937999901],
            [-42.906628756, -20.407741562999899],
            [-42.906555038, -20.407734677],
            [-42.906429870999901, -20.407753171],
            [-42.906321125, -20.4075333],
            [-42.906180364, -20.407473587],
            [-42.9060384799999, -20.40737861],
            [-42.905981380999897, -20.40732442],
            [-42.905849104, -20.407341964999901],
            [-42.905706521, -20.407261461],
            [-42.905550307999903, -20.407257698],
            [-42.905464216, -20.407294529999898],
            [-42.905298560999903, -20.407241986],
            [-42.905157666, -20.407225439999898],
            [-42.905041159999897, -20.407165855],
            [-42.904894479999903, -20.407225965],
            [-42.904767675, -20.407186969999898],
            [-42.904690865, -20.407173515],
            [-42.904543466999897, -20.407214097999901],
            [-42.904458742, -20.407212849],
            [-42.904311762, -20.407184924],
            [-42.904260609999902, -20.407166194],
            [-42.904109255, -20.407104649],
            [-42.904004011, -20.407084204999901],
            [-42.90389884, -20.407082499999898],
            [-42.90380935, -20.407071243],
            [-42.903736791, -20.407043055],
            [-42.9036914389999, -20.4069925709999],
            [-42.90361552, -20.406917751],
            [-42.903600833, -20.406880422],
            [-42.903622240999901, -20.406823979],
            [-42.903658704, -20.406764323999901],
            [-42.903671973, -20.40667661],
            [-42.903592300999897, -20.406605602],
            [-42.903469684, -20.406449065999901],
            [-42.90339432, -20.406421321],
            [-42.903253036, -20.406400200999901],
            [-42.90317985, -20.4063084269999],
            [-42.90310342, -20.4062671],
            [-42.90293997, -20.406200206999898],
            [-42.902833275, -20.406199382999901],
            [-42.902648401, -20.406122415],
            [-42.902505812, -20.4060436359999],
            [-42.902466016999902, -20.405953176],
            [-42.90244132, -20.405897035999899],
            [-42.902325388, -20.405784147],
            [-42.902223949, -20.405735681],
            [-42.902163562999903, -20.405638708],
            [-42.901922205999902, -20.405616805],
            [-42.901844849, -20.405550155999901],
            [-42.901762829999903, -20.405455031],
            [-42.901688973, -20.405009630999899],
            [-42.901631801, -20.404944196],
            [-42.901503697, -20.4048869289999],
            [-42.9013945949999, -20.404824661],
            [-42.901323619, -20.405003868999898],
            [-42.9012488179999, -20.405185026],
            [-42.901197040999897, -20.405639224]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 20,
        "id": 36931,
        "CD_SETOR": "315210505060015P",
        "AREA_KM2": 0.040896,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 342
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.903633311, -20.419467025],
            [-42.903890492999899, -20.419586968],
            [-42.904222567, -20.419842199999898],
            [-42.904648237999901, -20.420217991999898],
            [-42.904819736, -20.4203214529999],
            [-42.904854918999902, -20.420346123999899],
            [-42.9049803199999, -20.420420051],
            [-42.905018199, -20.420337891],
            [-42.90505502, -20.420251484999898],
            [-42.905053063, -20.420153359],
            [-42.90505917, -20.4201220899999],
            [-42.905084906999903, -20.420095672999899],
            [-42.905161907, -20.42010235],
            [-42.905193588, -20.420098557],
            [-42.905255919999902, -20.420075214999901],
            [-42.905282499, -20.4200766129999],
            [-42.905297537, -20.420088854],
            [-42.9052835479999, -20.420129071],
            [-42.905199965, -20.4202096819999],
            [-42.9056553409999, -20.420527153],
            [-42.9058535039999, -20.420665305999901],
            [-42.906045943, -20.420494122],
            [-42.906081437, -20.420414889],
            [-42.906127361, -20.420312376],
            [-42.906234491, -20.4200909109999],
            [-42.906276803, -20.419805279999899],
            [-42.905505089, -20.419677712],
            [-42.905496688, -20.419754304],
            [-42.905326246999898, -20.41988852],
            [-42.905093571999899, -20.420071741],
            [-42.905065637999897, -20.420031645999899],
            [-42.90502779, -20.420006106],
            [-42.904997006, -20.4199948209999],
            [-42.905010354, -20.419831455999901],
            [-42.905036203999899, -20.4195699059999],
            [-42.905205768, -20.418081512999901],
            [-42.903926199, -20.41785334],
            [-42.903667104, -20.418473395],
            [-42.903608084, -20.419217106],
            [-42.903633311, -20.419467025]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 21,
        "id": 36932,
        "CD_SETOR": "315210505060016P",
        "AREA_KM2": 0.177967,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 23
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.906045943, -20.420494122],
            [-42.9058535039999, -20.420665305999901],
            [-42.90568166, -20.420852747],
            [-42.904947885999903, -20.42165002],
            [-42.904573453999902, -20.422020118],
            [-42.904378873, -20.422256881],
            [-42.90412823, -20.4225716609999],
            [-42.903916334, -20.422860891],
            [-42.903872427, -20.422922098],
            [-42.903827256999897, -20.422964377],
            [-42.903692908, -20.422916802],
            [-42.903494222999903, -20.422865338],
            [-42.903327899, -20.422826429999901],
            [-42.903201753, -20.422816398999899],
            [-42.903126175, -20.422833257],
            [-42.903087019999901, -20.422841992],
            [-42.902985765, -20.422897286],
            [-42.902750746, -20.423030163],
            [-42.9025533719999, -20.42316132],
            [-42.902361332999902, -20.423314774999898],
            [-42.902169355999902, -20.4234727209999],
            [-42.902051601, -20.423507027],
            [-42.901947983999897, -20.423537215],
            [-42.901586178999899, -20.423662685999901],
            [-42.901432083, -20.423712021],
            [-42.901218389999897, -20.423792675],
            [-42.901037279, -20.423841020999902],
            [-42.900935418, -20.4238510439999],
            [-42.900791862999903, -20.423856977],
            [-42.900690106, -20.423839051],
            [-42.900582712, -20.423804048],
            [-42.900470222, -20.42375792],
            [-42.900299343, -20.423595142999901],
            [-42.900137678, -20.423442329],
            [-42.900029263, -20.423399779],
            [-42.899940619999903, -20.423396093],
            [-42.899861013, -20.423392783999901],
            [-42.89978258, -20.423396883999899],
            [-42.899474218, -20.423429846],
            [-42.899304609, -20.423489532],
            [-42.899208442, -20.423549415],
            [-42.899144141, -20.423589455999899],
            [-42.899023739, -20.423658438],
            [-42.898902882999899, -20.423713964999902],
            [-42.898792532999899, -20.4237307409999],
            [-42.898718006, -20.423719884],
            [-42.898605736, -20.423689549999899],
            [-42.898468118, -20.423621888],
            [-42.898325229, -20.4235757669999],
            [-42.898227104999897, -20.42358181],
            [-42.898105530999899, -20.423593357],
            [-42.8980132249999, -20.423645853],
            [-42.897953507, -20.423695193],
            [-42.897818781, -20.423826307999899],
            [-42.897769339, -20.423879992],
            [-42.897698983999902, -20.423964602999899],
            [-42.897625843, -20.423997186999902],
            [-42.897544820999897, -20.4240155859999],
            [-42.897478530999898, -20.424007891],
            [-42.897499958, -20.424035029],
            [-42.897581714999902, -20.424086261],
            [-42.89767862, -20.424084227],
            [-42.897853578, -20.424098512999901],
            [-42.898012243, -20.424137116999901],
            [-42.898249716, -20.424200857999899],
            [-42.898897507999898, -20.424452324],
            [-42.899475507, -20.424705494],
            [-42.899994739, -20.424918622],
            [-42.900726291999902, -20.425452401],
            [-42.901604822, -20.425721054999901],
            [-42.901948042999898, -20.425807162],
            [-42.902602445999896, -20.425818179],
            [-42.902799478, -20.425792163],
            [-42.90299806, -20.4258665139999],
            [-42.903155673999898, -20.426031967],
            [-42.903159103999897, -20.4260355719999],
            [-42.903160817999897, -20.426037374],
            [-42.9031625329999, -20.426039177],
            [-42.903165293, -20.426044089],
            [-42.903267905, -20.425778178],
            [-42.903426103999898, -20.425667064],
            [-42.903603609, -20.425496186],
            [-42.903616179, -20.4249439939999],
            [-42.903682814, -20.424288472999901],
            [-42.904034549, -20.424119183],
            [-42.90427227, -20.424001405],
            [-42.90455066, -20.423885264],
            [-42.904635797, -20.423836444],
            [-42.9047646389999, -20.4237928709999],
            [-42.904882441, -20.4237908009999],
            [-42.904928906, -20.423739887],
            [-42.904953637999903, -20.423674094],
            [-42.904978822, -20.423335259999899],
            [-42.904962048, -20.423232762999898],
            [-42.904924977, -20.423063091],
            [-42.904901966999901, -20.422977187],
            [-42.904901257999903, -20.422918555],
            [-42.9049238979999, -20.422864182],
            [-42.904969047999899, -20.422791834],
            [-42.90516489, -20.4225681849999],
            [-42.905321838, -20.422414659],
            [-42.905422549, -20.422341783],
            [-42.905470271, -20.422312611999899],
            [-42.905557085999902, -20.422254581999901],
            [-42.905674948999902, -20.422191679],
            [-42.905723113, -20.422158613],
            [-42.905760793999903, -20.422115697],
            [-42.905820725, -20.4220144],
            [-42.905892259, -20.421962193],
            [-42.906518682999902, -20.421577288],
            [-42.906893582999899, -20.421324883],
            [-42.906952836999899, -20.421284480999901],
            [-42.907001774, -20.4212205279999],
            [-42.907111031999897, -20.421004586],
            [-42.907252437, -20.420665421],
            [-42.907275062999901, -20.420618944999902],
            [-42.907208258, -20.420510255999901],
            [-42.9071538809999, -20.420366916],
            [-42.907132940999901, -20.420277719999898],
            [-42.907117016, -20.420059622999901],
            [-42.90712273, -20.419798574],
            [-42.907151294999899, -20.419474767999901],
            [-42.907177964, -20.41920226],
            [-42.907190893999903, -20.419120569],
            [-42.9072476649999, -20.418959702999899],
            [-42.907266947, -20.418905067],
            [-42.90729268, -20.418806926999899],
            [-42.907218766, -20.418897996999899],
            [-42.907181776999899, -20.41894357],
            [-42.907148631, -20.418992088],
            [-42.907058142, -20.419152595999901],
            [-42.906946326, -20.419350934],
            [-42.906683224999902, -20.4198055969999],
            [-42.906502368, -20.420030010999898],
            [-42.906339403999901, -20.420224716],
            [-42.90624779, -20.42030922],
            [-42.906199783, -20.420353501],
            [-42.9061258569999, -20.42042169],
            [-42.906084939, -20.420459433],
            [-42.906045943, -20.420494122]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 22,
        "id": 36933,
        "CD_SETOR": "315210505060017P",
        "AREA_KM2": 0.080848,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 22
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.897902006, -20.406749299],
            [-42.897763013, -20.406799244],
            [-42.897550760999899, -20.406875218],
            [-42.897466751, -20.406901780999899],
            [-42.897407705, -20.407083351],
            [-42.89738434, -20.407228975],
            [-42.897366442, -20.407361341999898],
            [-42.897346705, -20.40749628],
            [-42.897362632999901, -20.407600429],
            [-42.897368722, -20.407640242],
            [-42.897359853999902, -20.407811170999899],
            [-42.8973570369999, -20.4078850719999],
            [-42.897328062, -20.407911245],
            [-42.897211678, -20.407957027],
            [-42.897138395999903, -20.408018921999901],
            [-42.896795951999898, -20.408230866999901],
            [-42.897055795, -20.408406037],
            [-42.897413757, -20.408616300999899],
            [-42.897676576, -20.408762953999901],
            [-42.898009413999901, -20.4088635959999],
            [-42.898595451999903, -20.409036582999899],
            [-42.898796384, -20.409175783],
            [-42.899016236, -20.409433163],
            [-42.89918784, -20.40971214],
            [-42.899316443, -20.409946749],
            [-42.899386708999899, -20.410089763],
            [-42.899873944, -20.410277397],
            [-42.899920404999897, -20.409905680999898],
            [-42.899937891, -20.409472616999899],
            [-42.900034898999898, -20.409168795],
            [-42.900107453, -20.40904051],
            [-42.9001322, -20.408989059],
            [-42.900444533, -20.408488492999901],
            [-42.900953709, -20.4077268889999],
            [-42.901046556, -20.407378698999899],
            [-42.901042646999898, -20.407271189],
            [-42.900820818999897, -20.407588357999899],
            [-42.900706410999902, -20.407682822],
            [-42.900631231, -20.407694892999899],
            [-42.900549248999901, -20.407632673],
            [-42.900350359999898, -20.407700942],
            [-42.900140957, -20.407772818999899],
            [-42.89989987, -20.407867811],
            [-42.899831092999896, -20.407857726],
            [-42.899781876, -20.4078505099999],
            [-42.899694392999898, -20.407824515],
            [-42.899654342, -20.407780471],
            [-42.899379175, -20.407201852],
            [-42.899209932, -20.406845968],
            [-42.898746969999898, -20.407033753],
            [-42.898583849, -20.406583377999901],
            [-42.89830896, -20.4066576459999],
            [-42.897902006, -20.406749299]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 23,
        "id": 36934,
        "CD_SETOR": "315210505060018P",
        "AREA_KM2": 0.063686,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 224
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.896344648, -20.40763097],
            [-42.896347622999897, -20.407655507],
            [-42.89659267, -20.408028793],
            [-42.896795951999898, -20.408230866999901],
            [-42.897138395999903, -20.408018921999901],
            [-42.897211678, -20.407957027],
            [-42.897328062, -20.407911245],
            [-42.8973570369999, -20.4078850719999],
            [-42.897359853999902, -20.407811170999899],
            [-42.897368722, -20.407640242],
            [-42.897362632999901, -20.407600429],
            [-42.897346705, -20.40749628],
            [-42.897366442, -20.407361341999898],
            [-42.89738434, -20.407228975],
            [-42.897407705, -20.407083351],
            [-42.897466751, -20.406901780999899],
            [-42.897550760999899, -20.406875218],
            [-42.897763013, -20.406799244],
            [-42.897902006, -20.406749299],
            [-42.898001289, -20.406473178999899],
            [-42.8981254569999, -20.4061335019999],
            [-42.898154675999898, -20.406019226],
            [-42.898199729999902, -20.405847439],
            [-42.89820693, -20.405716282],
            [-42.89820449, -20.405650556],
            [-42.898026151, -20.4052144519999],
            [-42.897997264, -20.405159173999898],
            [-42.897845839999903, -20.404829111],
            [-42.897791001, -20.404708191999902],
            [-42.897748869999901, -20.404641397],
            [-42.897720669, -20.4046128539999],
            [-42.897650519, -20.404576344],
            [-42.897570118, -20.404541529],
            [-42.897742676, -20.404357115],
            [-42.897916801999898, -20.404176761],
            [-42.898017388, -20.404117855],
            [-42.898297850999903, -20.403904831],
            [-42.898431189999897, -20.403830899],
            [-42.898326669, -20.4036168],
            [-42.898052307999897, -20.402953307],
            [-42.897710947999897, -20.4032770059999],
            [-42.897374587999899, -20.403647407999902],
            [-42.897112802, -20.404074353999899],
            [-42.896938642, -20.404515275999898],
            [-42.896873494, -20.405055182],
            [-42.896830038, -20.405317098],
            [-42.896720635, -20.405600612999901],
            [-42.896582697, -20.4059181269999],
            [-42.896477712, -20.406121810999899],
            [-42.896405737999899, -20.406482814999901],
            [-42.896310166, -20.407130416],
            [-42.896326412999898, -20.407449976999899],
            [-42.896344648, -20.40763097]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 24,
        "id": 36935,
        "CD_SETOR": "315210505060019P",
        "AREA_KM2": 0.027707,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 380
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.898871301, -20.404908185],
            [-42.898904073, -20.404985054],
            [-42.898967457, -20.405122212],
            [-42.899041053, -20.405294327],
            [-42.899230452, -20.405736520999898],
            [-42.899452342, -20.406254987],
            [-42.899624183999897, -20.406687389999899],
            [-42.900800542999903, -20.406206015],
            [-42.900798968999901, -20.406088082999901],
            [-42.900766959999899, -20.406024492],
            [-42.900627178, -20.40583355],
            [-42.900541752, -20.405562072],
            [-42.900458809, -20.405350783],
            [-42.900371122, -20.405273785],
            [-42.900301059, -20.4051790349999],
            [-42.900010296999902, -20.404463274],
            [-42.899883293999899, -20.404511591],
            [-42.899352620999899, -20.404713480999899],
            [-42.898871301, -20.404908185]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 25,
        "id": 36936,
        "CD_SETOR": "315210505060021P",
        "AREA_KM2": 0.03811,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 9
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.90183888, -20.4044325529999],
            [-42.902488552, -20.405277166],
            [-42.902628259, -20.405461819],
            [-42.902714580999898, -20.405555235999898],
            [-42.902800463999903, -20.405562648999901],
            [-42.903478725999904, -20.405682757],
            [-42.903668869999898, -20.405728376],
            [-42.903760426, -20.405753102999899],
            [-42.903809654, -20.405766398],
            [-42.903896257, -20.405770988],
            [-42.903936094, -20.405763559],
            [-42.903999021999901, -20.405728896],
            [-42.904069826999901, -20.405612113],
            [-42.904278819999902, -20.404955038],
            [-42.904458167, -20.404372647999899],
            [-42.904476369999898, -20.404313537999901],
            [-42.9039754309999, -20.404170094999898],
            [-42.903685521999897, -20.40408708],
            [-42.903618080999898, -20.404067768999901],
            [-42.9035374279999, -20.404041117],
            [-42.903446377, -20.404015186],
            [-42.903345459, -20.403982565],
            [-42.903234853999898, -20.403951429],
            [-42.902912245, -20.403858367999899],
            [-42.902816244999897, -20.4038412109999],
            [-42.902755498, -20.403841982],
            [-42.902682532, -20.403857812999899],
            [-42.902292529, -20.404136272999899],
            [-42.901972177999902, -20.404345496],
            [-42.901927993999898, -20.404374351999898],
            [-42.90183888, -20.4044325529999]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 26,
        "id": 36937,
        "CD_SETOR": "315210505060022P",
        "AREA_KM2": 0.149662,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 115
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.90183888, -20.4044325529999],
            [-42.901674781999901, -20.404452574999901],
            [-42.901548094, -20.40460094],
            [-42.901482433, -20.4046776879999],
            [-42.9013945949999, -20.404824661],
            [-42.901503697, -20.4048869289999],
            [-42.901631801, -20.404944196],
            [-42.901688973, -20.405009630999899],
            [-42.901762829999903, -20.405455031],
            [-42.901844849, -20.405550155999901],
            [-42.901922205999902, -20.405616805],
            [-42.902163562999903, -20.405638708],
            [-42.902223949, -20.405735681],
            [-42.902325388, -20.405784147],
            [-42.90244132, -20.405897035999899],
            [-42.902466016999902, -20.405953176],
            [-42.902505812, -20.4060436359999],
            [-42.902648401, -20.406122415],
            [-42.902833275, -20.406199382999901],
            [-42.90293997, -20.406200206999898],
            [-42.90310342, -20.4062671],
            [-42.90317985, -20.4063084269999],
            [-42.903253036, -20.406400200999901],
            [-42.90339432, -20.406421321],
            [-42.903469684, -20.406449065999901],
            [-42.903592300999897, -20.406605602],
            [-42.903671973, -20.40667661],
            [-42.903658704, -20.406764323999901],
            [-42.903622240999901, -20.406823979],
            [-42.903600833, -20.406880422],
            [-42.90361552, -20.406917751],
            [-42.9036914389999, -20.4069925709999],
            [-42.903736791, -20.407043055],
            [-42.90380935, -20.407071243],
            [-42.90389884, -20.407082499999898],
            [-42.904004011, -20.407084204999901],
            [-42.904109255, -20.407104649],
            [-42.904260609999902, -20.407166194],
            [-42.904311762, -20.407184924],
            [-42.904458742, -20.407212849],
            [-42.904543466999897, -20.407214097999901],
            [-42.904690865, -20.407173515],
            [-42.904767675, -20.407186969999898],
            [-42.904894479999903, -20.407225965],
            [-42.905041159999897, -20.407165855],
            [-42.905157666, -20.407225439999898],
            [-42.905298560999903, -20.407241986],
            [-42.905464216, -20.407294529999898],
            [-42.905550307999903, -20.407257698],
            [-42.905706521, -20.407261461],
            [-42.905849104, -20.407341964999901],
            [-42.905981380999897, -20.40732442],
            [-42.9060384799999, -20.40737861],
            [-42.906180364, -20.407473587],
            [-42.906321125, -20.4075333],
            [-42.906429870999901, -20.407753171],
            [-42.906555038, -20.407734677],
            [-42.906628756, -20.407741562999899],
            [-42.906683883, -20.407796937999901],
            [-42.90681983, -20.407884583999898],
            [-42.907316697999903, -20.408019764999899],
            [-42.907817688, -20.408073158],
            [-42.9079637, -20.408044918],
            [-42.908224273, -20.407913747999899],
            [-42.90837199, -20.407781002],
            [-42.90871034, -20.407585845],
            [-42.908711787, -20.407561632],
            [-42.908368542999902, -20.407021529],
            [-42.907987766, -20.406388408999899],
            [-42.907832148999901, -20.406133145],
            [-42.907762612999903, -20.406019863],
            [-42.907705959999902, -20.405930591],
            [-42.90765228, -20.405847591999901],
            [-42.907582168, -20.405776607],
            [-42.90740879, -20.405640177],
            [-42.907293518, -20.405554117],
            [-42.907157685, -20.405461465],
            [-42.90704471, -20.405388547],
            [-42.906929971, -20.4053085359999],
            [-42.906827977, -20.4051523269999],
            [-42.906633978999899, -20.404768905],
            [-42.906624649, -20.404752613],
            [-42.906585774, -20.404750083],
            [-42.906327403, -20.4047042609999],
            [-42.9061707769999, -20.404668946999902],
            [-42.905758545, -20.404576],
            [-42.905765322, -20.404469563],
            [-42.905674081, -20.404456814],
            [-42.905642166999897, -20.40445168],
            [-42.905558520999897, -20.4044298009999],
            [-42.905509408999897, -20.404416954999899],
            [-42.905441872, -20.404401605],
            [-42.905411163999901, -20.4045401569999],
            [-42.905215978999898, -20.404485528],
            [-42.9051043649999, -20.404456411999899],
            [-42.905066022, -20.404447465],
            [-42.905032262, -20.404471282],
            [-42.904476369999898, -20.404313537999901],
            [-42.904458167, -20.404372647999899],
            [-42.904278819999902, -20.404955038],
            [-42.904069826999901, -20.405612113],
            [-42.903999021999901, -20.405728896],
            [-42.903936094, -20.405763559],
            [-42.903896257, -20.405770988],
            [-42.903809654, -20.405766398],
            [-42.903760426, -20.405753102999899],
            [-42.903668869999898, -20.405728376],
            [-42.903478725999904, -20.405682757],
            [-42.902800463999903, -20.405562648999901],
            [-42.902714580999898, -20.405555235999898],
            [-42.902628259, -20.405461819],
            [-42.902488552, -20.405277166],
            [-42.90183888, -20.4044325529999]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 27,
        "id": 36938,
        "CD_SETOR": "315210505060024P",
        "AREA_KM2": 24.72161,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 17
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.90799455, -20.428795776],
            [-42.908022205999899, -20.428267456],
            [-42.907875766, -20.428188145],
            [-42.907584329, -20.428070766],
            [-42.907473399, -20.428027942],
            [-42.907362057999897, -20.428076511],
            [-42.907196363, -20.428125802999901],
            [-42.907100920999902, -20.428221807999901],
            [-42.907000525999898, -20.428297003999901],
            [-42.906766235, -20.428321098],
            [-42.9046755849999, -20.428521248],
            [-42.904208185, -20.428210265],
            [-42.904056532, -20.427664536],
            [-42.904005670999901, -20.427327998],
            [-42.903821746999903, -20.427081399],
            [-42.903628168, -20.426818905999902],
            [-42.903369831, -20.426467671999902],
            [-42.903285449, -20.4263266],
            [-42.903289792, -20.426267639999899],
            [-42.903281944, -20.426253596],
            [-42.903165293, -20.426044089],
            [-42.9031625329999, -20.426039177],
            [-42.903160817999897, -20.426037374],
            [-42.903159103999897, -20.4260355719999],
            [-42.903155673999898, -20.426031967],
            [-42.90299806, -20.4258665139999],
            [-42.902799478, -20.425792163],
            [-42.902602445999896, -20.425818179],
            [-42.901948042999898, -20.425807162],
            [-42.901604822, -20.425721054999901],
            [-42.900726291999902, -20.425452401],
            [-42.899994739, -20.424918622],
            [-42.899475507, -20.424705494],
            [-42.898897507999898, -20.424452324],
            [-42.898249716, -20.424200857999899],
            [-42.898012243, -20.424137116999901],
            [-42.897853578, -20.424098512999901],
            [-42.89767862, -20.424084227],
            [-42.897581714999902, -20.424086261],
            [-42.897624702, -20.424113197999901],
            [-42.897704734999898, -20.424195535],
            [-42.897727115999899, -20.424357804],
            [-42.897855271, -20.424470202999899],
            [-42.897758471, -20.424625662],
            [-42.897702291, -20.424815894999899],
            [-42.89767741, -20.424978544999899],
            [-42.897683209, -20.425070731999899],
            [-42.897742179, -20.4252659059999],
            [-42.897729429, -20.425461873],
            [-42.897813927, -20.425560176],
            [-42.897836722, -20.425687506],
            [-42.897888356, -20.4258922769999],
            [-42.897722810999902, -20.425930422],
            [-42.897576054999902, -20.425969811],
            [-42.8972997669999, -20.42605399],
            [-42.897008633, -20.426072207999901],
            [-42.896791502, -20.426105607],
            [-42.896473796, -20.426094093],
            [-42.895969390999902, -20.426138925],
            [-42.8957647679999, -20.426274511],
            [-42.895588003999897, -20.426369284],
            [-42.895439591, -20.426505875],
            [-42.89523192, -20.426718555],
            [-42.895062099, -20.426886227999901],
            [-42.894888583, -20.427057544],
            [-42.894743843999898, -20.427195146],
            [-42.894548196999899, -20.427324778],
            [-42.894348593, -20.427447786999899],
            [-42.894307947999899, -20.427487884],
            [-42.894241018999899, -20.427878689],
            [-42.894176439, -20.428189811],
            [-42.894075315999899, -20.428563523],
            [-42.894027590999897, -20.428913893],
            [-42.894017609999899, -20.42904465],
            [-42.893974280999899, -20.429227766999901],
            [-42.893760932, -20.429504762],
            [-42.893519929, -20.429680587999901],
            [-42.893279123999903, -20.429800503],
            [-42.89303552, -20.430038836999898],
            [-42.89279736, -20.430270381],
            [-42.892660619, -20.430376327],
            [-42.892454082, -20.430504755999898],
            [-42.892311988, -20.4304952849999],
            [-42.892307128, -20.430495424999901],
            [-42.892302553, -20.430495556],
            [-42.892298008, -20.430495687],
            [-42.892293192, -20.430495825],
            [-42.892278843, -20.4304962379999],
            [-42.892253075, -20.430473493],
            [-42.892233571, -20.430456276],
            [-42.892187052999901, -20.430425661999902],
            [-42.892172017999897, -20.430401447999898],
            [-42.892163476999897, -20.430391988999901],
            [-42.892154935999898, -20.43038253],
            [-42.892154295999902, -20.430381269],
            [-42.89215259, -20.430378457],
            [-42.8921144299999, -20.43036782],
            [-42.8920830389999, -20.430289315],
            [-42.89209403, -20.430257487999899],
            [-42.892205723, -20.43022604],
            [-42.89222805, -20.430144357],
            [-42.892163919, -20.430081087],
            [-42.892110708, -20.430001029],
            [-42.89209403, -20.42992874],
            [-42.892069889, -20.429885831],
            [-42.891988763, -20.429855701],
            [-42.89199868, -20.429792651],
            [-42.892000337, -20.429735731999902],
            [-42.892007006, -20.429657209],
            [-42.892044534, -20.429593575999899],
            [-42.892121263, -20.429537314],
            [-42.892134085, -20.429489498999899],
            [-42.892106908, -20.429401933999898],
            [-42.892107707, -20.429260805],
            [-42.892031984, -20.429136228999901],
            [-42.892004095999901, -20.429127461],
            [-42.891971178999903, -20.429128789999901],
            [-42.891911764, -20.429111851],
            [-42.891805989, -20.428997411999902],
            [-42.891684641, -20.428840081],
            [-42.891570969, -20.428715994999902],
            [-42.891464721, -20.428576318],
            [-42.891424486999902, -20.4285238689999],
            [-42.891390359, -20.428498437],
            [-42.891334314999902, -20.428489961],
            [-42.891146402999901, -20.428455712999899],
            [-42.891085711999899, -20.428394222],
            [-42.891016662, -20.4283668909999],
            [-42.890950657, -20.4284015869999],
            [-42.890908732999897, -20.428487858],
            [-42.890912332, -20.42862998],
            [-42.89092999, -20.428894632],
            [-42.890908899, -20.429090356],
            [-42.890937849, -20.42935255],
            [-42.890848885, -20.429472955999898],
            [-42.890868047, -20.429748171],
            [-42.890759882999902, -20.429764801],
            [-42.890642022, -20.429721685999901],
            [-42.890469792, -20.429569337],
            [-42.890214434, -20.429557025],
            [-42.889936282999898, -20.429415017999901],
            [-42.889868092, -20.429286109],
            [-42.889789387999897, -20.4292114429999],
            [-42.889683249, -20.4292281429999],
            [-42.889520788, -20.429303059999899],
            [-42.888942035999897, -20.429641515999901],
            [-42.888700261999901, -20.4298218939999],
            [-42.888334146, -20.430067860999898],
            [-42.887933317, -20.430276384],
            [-42.887862566, -20.430341191],
            [-42.887681406, -20.430704551],
            [-42.887645703, -20.430796087],
            [-42.887669629999898, -20.43088792],
            [-42.887826364, -20.430941787999899],
            [-42.888103156999897, -20.431278138],
            [-42.888204002, -20.431385502],
            [-42.888202147999898, -20.431395543999901],
            [-42.888191954, -20.431450766],
            [-42.888143086999897, -20.431510665],
            [-42.888011686, -20.431651728],
            [-42.888025248, -20.431746544],
            [-42.888007495999901, -20.431884694],
            [-42.888017848999901, -20.432033535999899],
            [-42.888069387, -20.432102921],
            [-42.888387287999898, -20.432208418999899],
            [-42.888513681999903, -20.432272075999901],
            [-42.888594855, -20.432386291999901],
            [-42.888643251, -20.432515378999899],
            [-42.888877178, -20.43265723],
            [-42.889086886, -20.432821794999899],
            [-42.889175152999897, -20.432846945999898],
            [-42.889269883999901, -20.432880226],
            [-42.889343561, -20.432917666],
            [-42.889435762999902, -20.433001528],
            [-42.889473689999903, -20.433075324999901],
            [-42.889539565999897, -20.433203508],
            [-42.88958638, -20.433230275],
            [-42.889685573, -20.433210505],
            [-42.889730482999902, -20.4331847129999],
            [-42.889799321999902, -20.433099574],
            [-42.890062739, -20.432764844],
            [-42.890263312999899, -20.4325044509999],
            [-42.890433324999897, -20.432394201],
            [-42.890630643, -20.432340494],
            [-42.890905954999901, -20.432361767],
            [-42.891111983, -20.4323086009999],
            [-42.891268328, -20.4323035789999],
            [-42.891619859999899, -20.432432752],
            [-42.89188372, -20.432551429999901],
            [-42.892299690999899, -20.43285868],
            [-42.892500406, -20.432972945],
            [-42.892539828999901, -20.4330720049999],
            [-42.892498321999902, -20.4331704169999],
            [-42.892322310999901, -20.433274775],
            [-42.892236012999902, -20.4333793119999],
            [-42.892070794999903, -20.4339084819999],
            [-42.891773013, -20.434358597],
            [-42.891560893, -20.434853991999901],
            [-42.891467148999901, -20.435046953],
            [-42.891413574999902, -20.435358325],
            [-42.891380279, -20.435460899],
            [-42.891218006, -20.435640986],
            [-42.891129694, -20.435718223],
            [-42.890987259, -20.435822588],
            [-42.890886556999902, -20.435903198999899],
            [-42.890864131999898, -20.436025906999902],
            [-42.890832266, -20.436149053],
            [-42.890864489, -20.436252363999898],
            [-42.890912526, -20.436284074],
            [-42.891034094, -20.4362870329999],
            [-42.891113764, -20.436319812],
            [-42.891194147, -20.4363678879999],
            [-42.891233228, -20.436562712],
            [-42.891138826, -20.436751253],
            [-42.891196188, -20.436892611],
            [-42.891416693999901, -20.437001892],
            [-42.891575401, -20.4369580759999],
            [-42.891675280999898, -20.436963357],
            [-42.891780727, -20.437013975],
            [-42.891833770999902, -20.437171388],
            [-42.891886577, -20.437270628],
            [-42.892100147, -20.437274261],
            [-42.892195048, -20.437119768999899],
            [-42.892317261, -20.437016119],
            [-42.892345914, -20.43691918],
            [-42.892461012, -20.4368296919999],
            [-42.892719938, -20.436805222],
            [-42.892890957, -20.436675367],
            [-42.892988446999901, -20.436597235],
            [-42.893077149, -20.436598197],
            [-42.893182447, -20.436714337999899],
            [-42.893221207, -20.436892643],
            [-42.893176716, -20.4369817699999],
            [-42.893149827999899, -20.437112043],
            [-42.893193595999897, -20.4372384],
            [-42.893343644, -20.437332409],
            [-42.893363389999898, -20.437465738999901],
            [-42.893350407999897, -20.437679311],
            [-42.893242027, -20.4377645829999],
            [-42.893254617, -20.438007717999898],
            [-42.893224436, -20.438047276],
            [-42.893125301999902, -20.4380731799999],
            [-42.893033058999897, -20.438055574],
            [-42.892969525, -20.437989178999899],
            [-42.892970311999903, -20.437847785],
            [-42.892966701, -20.437768239],
            [-42.8929223, -20.437680344999901],
            [-42.892829208, -20.437689527],
            [-42.892772878999899, -20.437728179],
            [-42.892722752999902, -20.437862469],
            [-42.892653955, -20.437924193999901],
            [-42.892495872, -20.438119232],
            [-42.892435546999899, -20.438221945],
            [-42.892252180999897, -20.438345311],
            [-42.891919562999902, -20.438733786999901],
            [-42.891509801, -20.439102099],
            [-42.891477773, -20.439172831999901],
            [-42.891468797999899, -20.439234519],
            [-42.891544766, -20.439377147],
            [-42.891509712999898, -20.439748095],
            [-42.891494533, -20.439934085999901],
            [-42.891444032, -20.439985985],
            [-42.891228966, -20.440109207],
            [-42.891106787999902, -20.440284887],
            [-42.891261683, -20.440412713999901],
            [-42.891334107, -20.440501052999899],
            [-42.891361181, -20.440595256999899],
            [-42.891341686, -20.440679917],
            [-42.890929437, -20.440994729],
            [-42.890802795, -20.441093634],
            [-42.890716247999897, -20.441237514],
            [-42.890701513, -20.441371795],
            [-42.890624984999903, -20.441613339],
            [-42.890514029, -20.441809957],
            [-42.890471213999902, -20.441892767],
            [-42.890485082999902, -20.442019458999901],
            [-42.890418290999897, -20.442408651999902],
            [-42.890331558, -20.44267123],
            [-42.89034069, -20.442762267],
            [-42.890390582999899, -20.442850609999901],
            [-42.890525222999898, -20.443017421],
            [-42.890754047, -20.4431100309999],
            [-42.890798009, -20.443163049],
            [-42.890812195, -20.44327759],
            [-42.890778859, -20.443354063999902],
            [-42.890456762, -20.443579126],
            [-42.890353505, -20.44365771],
            [-42.890258198, -20.443837863999899],
            [-42.890201112999897, -20.443994707999899],
            [-42.890198461, -20.444208478999901],
            [-42.890187066, -20.444399849],
            [-42.890128724, -20.4445381479999],
            [-42.890042531999903, -20.4449222719999],
            [-42.890155184, -20.445196963999901],
            [-42.890337957, -20.445787507],
            [-42.890351864, -20.4464160439999],
            [-42.890320522, -20.446514755],
            [-42.890251679, -20.446593698],
            [-42.890222713999897, -20.446666759],
            [-42.8902583019999, -20.44677002],
            [-42.890224382, -20.446867179],
            [-42.89015341, -20.446992977],
            [-42.890157428999899, -20.447072209999899],
            [-42.890220037999903, -20.447180585999899],
            [-42.890337128999903, -20.447251885999901],
            [-42.890404740999898, -20.447297874],
            [-42.89047819, -20.44741177],
            [-42.890487556, -20.4476156489999],
            [-42.890457517, -20.447734427999901],
            [-42.8902151469999, -20.44790016],
            [-42.889972023, -20.448144387],
            [-42.889877542, -20.448424619],
            [-42.889704653, -20.448643354],
            [-42.889620615999902, -20.448766237],
            [-42.889614836, -20.448921051],
            [-42.889607568, -20.449162553999901],
            [-42.889548432, -20.449281336],
            [-42.889526287, -20.4493844039999],
            [-42.889548509, -20.449494322],
            [-42.889531561, -20.449653767],
            [-42.889484433999897, -20.449806376],
            [-42.889364203999897, -20.44996771],
            [-42.889288625999903, -20.450014799],
            [-42.889178327, -20.4500021439999],
            [-42.889070220999898, -20.449957997],
            [-42.889018442999898, -20.449870616999899],
            [-42.888964793999897, -20.449821777],
            [-42.88883135, -20.449825599999901],
            [-42.888701242, -20.449896478],
            [-42.888552827, -20.450032007999901],
            [-42.888515369999901, -20.450138131],
            [-42.888556152, -20.450207453],
            [-42.888872743, -20.450296170999898],
            [-42.889082866, -20.450471029],
            [-42.889239561999901, -20.4507094639999],
            [-42.889324971, -20.4511348529999],
            [-42.88932111, -20.451374207999901],
            [-42.889265307, -20.451509513],
            [-42.88920694, -20.451576158],
            [-42.888304615, -20.451926876],
            [-42.888071719, -20.452073533],
            [-42.887857329, -20.452283805],
            [-42.887775801, -20.452568583],
            [-42.887760930999903, -20.453114619],
            [-42.887666324, -20.453340001999901],
            [-42.887654551, -20.45341561],
            [-42.8876611909999, -20.4535066],
            [-42.887764881, -20.453646971],
            [-42.887868722, -20.4536406829999],
            [-42.888096647999902, -20.453676307],
            [-42.888125726999903, -20.453721576],
            [-42.8880940029999, -20.4537791999999],
            [-42.887930305999902, -20.453837274],
            [-42.887837493999903, -20.454002795],
            [-42.887889183, -20.454156075],
            [-42.887935491, -20.454253309],
            [-42.887958603999898, -20.454426754999901],
            [-42.887910075, -20.454532065],
            [-42.887796978, -20.454716966],
            [-42.887746477999897, -20.45494831],
            [-42.887799023999897, -20.455184201999899],
            [-42.887770569999901, -20.455425767],
            [-42.887829462999903, -20.455588121999899],
            [-42.887959666, -20.455727641],
            [-42.888328937, -20.456166637],
            [-42.888378541, -20.456368785999899],
            [-42.888362634, -20.456627145],
            [-42.888217203999901, -20.457342937],
            [-42.888178938, -20.457457088999899],
            [-42.888116994, -20.457545249],
            [-42.887992439999898, -20.457713144],
            [-42.887916710999903, -20.457955806],
            [-42.8878874, -20.458133573999898],
            [-42.887927077, -20.4583081819999],
            [-42.888019884999899, -20.458430666],
            [-42.888211354, -20.458631431],
            [-42.888241897999897, -20.4587280759999],
            [-42.888198960999901, -20.45883638],
            [-42.8880496089999, -20.459017566999901],
            [-42.887809919999903, -20.459199464999902],
            [-42.887738659, -20.459330854],
            [-42.887750817, -20.459644633],
            [-42.887729525999902, -20.459806849],
            [-42.887633593, -20.459916887],
            [-42.887549603, -20.460059778999899],
            [-42.88750957, -20.460284123],
            [-42.887570619, -20.460535245],
            [-42.887638001, -20.460709296999902],
            [-42.887706674, -20.460751544999901],
            [-42.887853361999902, -20.460760045],
            [-42.888101537, -20.460877938],
            [-42.888313842999899, -20.461207074999901],
            [-42.888474365, -20.461725251],
            [-42.888875404, -20.462801855999899],
            [-42.888988955999899, -20.462860367],
            [-42.889391732, -20.462913749999899],
            [-42.889526577, -20.463038691999898],
            [-42.889614067, -20.46304155],
            [-42.889641776, -20.463012842999898],
            [-42.889644168, -20.462902778999901],
            [-42.889678021, -20.462631127],
            [-42.889872693, -20.462455145],
            [-42.889924112, -20.462469929],
            [-42.890419048999902, -20.462947749],
            [-42.890486762, -20.4629895109999],
            [-42.890574963999903, -20.462940881],
            [-42.890741566, -20.462500699],
            [-42.890829293, -20.462386556],
            [-42.890930581, -20.462314431],
            [-42.891456342, -20.4623269749999],
            [-42.891648381, -20.462308037],
            [-42.8919126179999, -20.46234901],
            [-42.892134143, -20.462495378],
            [-42.892202258999902, -20.462580914999901],
            [-42.892221664, -20.462652748],
            [-42.892170321, -20.462736408999898],
            [-42.891907316999898, -20.462936844],
            [-42.891541607, -20.4632297009999],
            [-42.891010974, -20.4635726459999],
            [-42.890738373, -20.463741900999899],
            [-42.890654884999897, -20.463858554],
            [-42.890116102, -20.464986113999899],
            [-42.890112873, -20.465057773999899],
            [-42.890060578, -20.465218959],
            [-42.890065917, -20.465327667999901],
            [-42.890097872999903, -20.465390663999901],
            [-42.890164672, -20.465426581],
            [-42.890449369999899, -20.465390676],
            [-42.890509467999898, -20.465427030999901],
            [-42.890587380999897, -20.465544021],
            [-42.890650767999901, -20.465827175],
            [-42.890609895, -20.465929623],
            [-42.890386105999902, -20.466141137],
            [-42.890183259, -20.466278189],
            [-42.88997749, -20.466368838999902],
            [-42.889893048, -20.466434],
            [-42.8898472939999, -20.466541347],
            [-42.889851275, -20.4665509169999],
            [-42.889886615, -20.466635868],
            [-42.889988591, -20.466790604],
            [-42.889997391999898, -20.467003335],
            [-42.890031775, -20.467099603999898],
            [-42.890088643999903, -20.467134385],
            [-42.890364374999898, -20.4671000129999],
            [-42.890447992, -20.467094555],
            [-42.890517825, -20.467162519999899],
            [-42.890567212999898, -20.46722763],
            [-42.890603328999902, -20.46733639],
            [-42.890606181, -20.467429912999901],
            [-42.8905787959999, -20.4674872259999],
            [-42.890624838, -20.467563199],
            [-42.890795699, -20.467599672999899],
            [-42.89096963, -20.467567190999901],
            [-42.8910276249999, -20.4675287969999],
            [-42.891088195, -20.467488698999901],
            [-42.891130976999897, -20.467491370999898],
            [-42.891196673, -20.4675488039999],
            [-42.891299253999897, -20.467619632],
            [-42.891353444999901, -20.467673073999901],
            [-42.891410488, -20.467758580999899],
            [-42.891461826, -20.467878825],
            [-42.891581617, -20.467945626999899],
            [-42.891684456, -20.467945539],
            [-42.891764153999901, -20.467929593999902],
            [-42.89179838, -20.467940283],
            [-42.891799534, -20.467941542999899],
            [-42.891836171, -20.467932267],
            [-42.891898919, -20.46792425],
            [-42.891953109, -20.467892185],
            [-42.892024412999902, -20.467734532],
            [-42.892141351, -20.4675314529999],
            [-42.892204099, -20.467461978],
            [-42.892326741, -20.4674031919999],
            [-42.892580583, -20.4673684549999],
            [-42.8927659719999, -20.4674031919999],
            [-42.89301411, -20.467456634],
            [-42.893527497, -20.467587567],
            [-42.893729999999898, -20.467675745999902],
            [-42.893938207, -20.467793318],
            [-42.894106483, -20.467905545999901],
            [-42.894212013, -20.4680525109999],
            [-42.89427476, -20.468258261],
            [-42.894343212, -20.468445307],
            [-42.894468706999902, -20.468667089],
            [-42.894488672, -20.4687579399999],
            [-42.894403107, -20.468928952999899],
            [-42.894243387, -20.4693591559999],
            [-42.894197752, -20.469690490999898],
            [-42.894137857, -20.470075267],
            [-42.894123607, -20.470110721],
            [-42.894163952, -20.470174041],
            [-42.894605073, -20.470323826],
            [-42.894769443, -20.470308483999901],
            [-42.895005335999898, -20.469893225],
            [-42.895198287, -20.469854986],
            [-42.895316337999901, -20.469745114],
            [-42.895673689, -20.4697991479999],
            [-42.895615432999897, -20.470117844],
            [-42.895598966, -20.470357554],
            [-42.895357912999899, -20.4707878409999],
            [-42.895257651, -20.471327163999899],
            [-42.895204129, -20.471501015999898],
            [-42.895236908, -20.471720517999898],
            [-42.895560409, -20.471790284],
            [-42.895708439, -20.472055144],
            [-42.895661509, -20.472367756],
            [-42.895469821999903, -20.472583880999899],
            [-42.8951622989999, -20.472613237],
            [-42.895099688, -20.472722362],
            [-42.895275336999902, -20.472902583],
            [-42.89566804, -20.473037024],
            [-42.896031308, -20.473120552999902],
            [-42.896042396, -20.473293457],
            [-42.896170944999902, -20.473372502],
            [-42.896671524999903, -20.4735168899999],
            [-42.896956599, -20.473570568],
            [-42.897134716, -20.473771703999901],
            [-42.897254678, -20.474181389],
            [-42.897522592, -20.474280840999899],
            [-42.897838093, -20.474497345],
            [-42.898194818, -20.474764644999901],
            [-42.898341042, -20.474891146999902],
            [-42.898461149, -20.474889812],
            [-42.898576128999899, -20.474774775999901],
            [-42.8986360379999, -20.4746720979999],
            [-42.898809846999903, -20.474629494999899],
            [-42.898911423999898, -20.474657894],
            [-42.898854839, -20.474875338999901],
            [-42.898870963, -20.47516307],
            [-42.898949955, -20.475697609999902],
            [-42.899139858, -20.475875284],
            [-42.899237038, -20.476000225],
            [-42.899297439, -20.476134729],
            [-42.899319728, -20.4762990739999],
            [-42.899207007, -20.476387794999901],
            [-42.899055539, -20.476776155],
            [-42.898970689, -20.476868988],
            [-42.898908389, -20.476937149],
            [-42.898969971, -20.477153792],
            [-42.898974016999901, -20.477293727],
            [-42.898898711999898, -20.477628044],
            [-42.899034862999898, -20.477802782],
            [-42.899281884999901, -20.478200162],
            [-42.899513961, -20.478414754],
            [-42.899606635999902, -20.478459657],
            [-42.899665778, -20.4784071199999],
            [-42.899695739, -20.478317403999899],
            [-42.899774973, -20.478235541999901],
            [-42.899840383, -20.478234686999901],
            [-42.8998802, -20.478281448],
            [-42.89998997, -20.4784699759999],
            [-42.900039451999902, -20.478639996],
            [-42.899976153999901, -20.478717036],
            [-42.899962601, -20.478790843999899],
            [-42.900032874, -20.47880472],
            [-42.900274275999898, -20.478731755],
            [-42.900364376, -20.478630618999901],
            [-42.90034459, -20.478556747],
            [-42.900376638, -20.478499755],
            [-42.900461385, -20.478491935],
            [-42.900644515, -20.478554745999901],
            [-42.900853755, -20.4785958749999],
            [-42.901026253999902, -20.478636903999899],
            [-42.901367691, -20.478650817],
            [-42.90154616, -20.478528937],
            [-42.901685495, -20.478493768],
            [-42.901935168, -20.478505346],
            [-42.902012582999902, -20.478415723],
            [-42.9019163, -20.478287179999899],
            [-42.901948708, -20.4780752039999],
            [-42.902156197999901, -20.477935602999899],
            [-42.902312213999899, -20.477962963],
            [-42.902400805999903, -20.477961967999899],
            [-42.902553861999898, -20.477963433],
            [-42.902856845, -20.477903254],
            [-42.902946893, -20.477858812],
            [-42.903083636, -20.477757245],
            [-42.903292025, -20.477710343],
            [-42.904400172, -20.476664376],
            [-42.904671589, -20.476265261],
            [-42.90531864, -20.475529584],
            [-42.905934574999897, -20.475005666999898],
            [-42.9063605219999, -20.474608068],
            [-42.906541747999903, -20.474455819],
            [-42.906783973, -20.474336059999899],
            [-42.906930409, -20.47430578],
            [-42.907152182999901, -20.474298812],
            [-42.907345, -20.474373995999901],
            [-42.907516, -20.4745349959999],
            [-42.907758999999899, -20.474672996],
            [-42.907991, -20.474741996],
            [-42.908234999999898, -20.474811996],
            [-42.908466, -20.474926996],
            [-42.90871, -20.475030995999902],
            [-42.90899, -20.475145996],
            [-42.909283, -20.475249995999899],
            [-42.910000844, -20.475593531],
            [-42.911291069999898, -20.4761093289999],
            [-42.911687495, -20.476107392],
            [-42.912168736999902, -20.475991608999902],
            [-42.912764065, -20.475664007],
            [-42.913836730999897, -20.475031651999899],
            [-42.914118323, -20.474781957999902],
            [-42.914937661, -20.47409076],
            [-42.915362758, -20.473725696],
            [-42.915752037999901, -20.473345600999899],
            [-42.916058785, -20.4730863099999],
            [-42.918293505, -20.471789784999899],
            [-42.919680677, -20.471182149999901],
            [-42.920780914, -20.470656495],
            [-42.92163928, -20.470046114],
            [-42.922531, -20.469314996],
            [-42.923291365999901, -20.468837080999901],
            [-42.923936441, -20.468425743],
            [-42.925282629, -20.467534987],
            [-42.926151763, -20.4671371069999],
            [-42.92640428, -20.467073200999899],
            [-42.926593231, -20.4670974379999],
            [-42.92675285, -20.467190275999901],
            [-42.926982075, -20.467522291],
            [-42.927348203999898, -20.468256430999901],
            [-42.927854758, -20.469315559999899],
            [-42.928420333, -20.470197633999899],
            [-42.928748027, -20.470828469],
            [-42.928933984, -20.4711105979999],
            [-42.929348521999898, -20.471925357],
            [-42.929639596999898, -20.472116378],
            [-42.930007343999897, -20.472301795],
            [-42.930507473, -20.472344708],
            [-42.930895855, -20.4722877089999],
            [-42.93132121, -20.472081548999899],
            [-42.931747747, -20.4716651869999],
            [-42.931952973, -20.471403962],
            [-42.932096042999902, -20.4708402219999],
            [-42.932332, -20.470635995999899],
            [-42.932582887, -20.470536244],
            [-42.932832, -20.470510996],
            [-42.933112, -20.470510996],
            [-42.933368, -20.4705229959999],
            [-42.933636, -20.470534996],
            [-42.93388, -20.470557996],
            [-42.934431801, -20.470639201],
            [-42.934797316999898, -20.4708035469999],
            [-42.935256129, -20.471100346999901],
            [-42.935699778999897, -20.4714686149999],
            [-42.936227403, -20.471783602],
            [-42.936652785, -20.471990707],
            [-42.936982940999897, -20.47198846],
            [-42.937492704, -20.471803513999902],
            [-42.938432629, -20.471400463],
            [-42.938937844, -20.471299514],
            [-42.939075773999903, -20.471224178999901],
            [-42.939183, -20.471069996],
            [-42.939366, -20.470804996],
            [-42.939537, -20.470540996],
            [-42.939684, -20.470334996],
            [-42.939806, -20.470104996],
            [-42.939904, -20.469874995999898],
            [-42.940014, -20.469645995999901],
            [-42.940136, -20.469404996],
            [-42.940258, -20.469185996],
            [-42.940393, -20.468956996],
            [-42.940539, -20.468772995999899],
            [-42.940857, -20.468405995999898],
            [-42.941076, -20.468187995999902],
            [-42.941345, -20.468003995999901],
            [-42.941882, -20.467636996],
            [-42.942089, -20.467476996],
            [-42.942309, -20.4673499959999],
            [-42.942540999999899, -20.467212995999901],
            [-42.94276, -20.4670979959999],
            [-42.943004, -20.4669489959999],
            [-42.943199, -20.466822996],
            [-42.943468, -20.466673995999901],
            [-42.944609887, -20.466296471],
            [-42.945127, -20.466158995999901],
            [-42.945956, -20.465952995999899],
            [-42.946249, -20.465837995999902],
            [-42.946517, -20.465746996],
            [-42.946736, -20.465665995999899],
            [-42.946979999999897, -20.465585996],
            [-42.947273, -20.465505995999902],
            [-42.947505, -20.465402996],
            [-42.947809999999897, -20.465287996],
            [-42.948029, -20.465184995999898],
            [-42.948261, -20.465104996],
            [-42.948554, -20.465035995999902],
            [-42.948895, -20.464921995999902],
            [-42.949115, -20.464749996],
            [-42.949297999999899, -20.464565996],
            [-42.949506, -20.464370996],
            [-42.949664, -20.464186996],
            [-42.949835, -20.463968995999899],
            [-42.949982, -20.4637279959999],
            [-42.950092, -20.463486996],
            [-42.950176999999897, -20.463268996],
            [-42.950287, -20.463061996],
            [-42.950421999999897, -20.462854996],
            [-42.95058, -20.462648995999899],
            [-42.950715, -20.462418995999901],
            [-42.950848999999899, -20.462165996],
            [-42.950903484, -20.462076672],
            [-42.950201825, -20.461700798],
            [-42.948928458, -20.461204977],
            [-42.947938436, -20.4604562],
            [-42.947317327999897, -20.459747061],
            [-42.946926576, -20.459463405],
            [-42.945865419, -20.458986747],
            [-42.945038895, -20.458598563],
            [-42.944341466, -20.458551702],
            [-42.9437814469999, -20.458483455],
            [-42.9431042739999, -20.458123563],
            [-42.942819439999901, -20.457585542999901],
            [-42.942757883, -20.457292663],
            [-42.942871275999899, -20.4568188699999],
            [-42.943140114, -20.4562184649999],
            [-42.943172984999897, -20.455818103],
            [-42.943033738999901, -20.455312111999898],
            [-42.94267518, -20.454625098],
            [-42.942448548, -20.454461810999899],
            [-42.942053793, -20.454431364],
            [-42.9401225, -20.454664866],
            [-42.938931274, -20.454248499],
            [-42.937823597, -20.4533874749999],
            [-42.936573076999899, -20.452111003],
            [-42.936135125, -20.451213983999899],
            [-42.936174433, -20.449819287],
            [-42.936357425, -20.449503057],
            [-42.936664860999898, -20.4492742579999],
            [-42.938807868, -20.448668486],
            [-42.939478874, -20.448417392],
            [-42.939854384999897, -20.4482422469999],
            [-42.939979031999897, -20.4480549109999],
            [-42.940040522999901, -20.447890040999901],
            [-42.940085487, -20.447549277999901],
            [-42.940078716, -20.447210208999898],
            [-42.940105128, -20.446872923999901],
            [-42.940049465999898, -20.446581508999898],
            [-42.939756648999897, -20.446254435999901],
            [-42.939423401999903, -20.446017167],
            [-42.938329316, -20.445600425999899],
            [-42.937729037999901, -20.44532944],
            [-42.937012301, -20.444915794999901],
            [-42.936586645, -20.444457142],
            [-42.935890739999898, -20.443393611],
            [-42.935618017, -20.443154052],
            [-42.935081816, -20.443088911],
            [-42.934155141, -20.443028953999899],
            [-42.933312580999903, -20.442971219999901],
            [-42.932604200999897, -20.442710937],
            [-42.932186385, -20.442419931],
            [-42.93166718, -20.441810427],
            [-42.931416231999897, -20.441362463],
            [-42.931486122, -20.441016332999901],
            [-42.93217222, -20.440243247],
            [-42.932540585, -20.439716008999898],
            [-42.93355925, -20.437790864],
            [-42.933673663, -20.4369406389999],
            [-42.933577925, -20.436599746999899],
            [-42.932746719, -20.436221476],
            [-42.930521649999903, -20.436178587],
            [-42.929823399, -20.436042300999901],
            [-42.9282533579999, -20.435262244999901],
            [-42.927211906, -20.434704108999899],
            [-42.925639155999903, -20.433704862],
            [-42.925132689999899, -20.433239683999901],
            [-42.924897302, -20.43292159],
            [-42.924023857999899, -20.431780046],
            [-42.923915157, -20.431273181999899],
            [-42.923837578, -20.430602461],
            [-42.923782882999902, -20.429497457],
            [-42.923551898, -20.4288090279999],
            [-42.92320555, -20.428160413999901],
            [-42.922909846, -20.427778197999899],
            [-42.92250848, -20.427280263999901],
            [-42.922263380999901, -20.427641257999898],
            [-42.921800931999897, -20.427917829],
            [-42.921314003, -20.428038182],
            [-42.920835269, -20.42803344],
            [-42.920438073999897, -20.427852798999901],
            [-42.920218626, -20.427612021],
            [-42.920038709, -20.427243028],
            [-42.919967615, -20.426696316999902],
            [-42.920058835, -20.42596091],
            [-42.920185913999902, -20.425081651],
            [-42.9200134989999, -20.425119777],
            [-42.918507028, -20.42549609],
            [-42.918410465, -20.425220397],
            [-42.918382225999899, -20.424963106],
            [-42.918389939999898, -20.424772865],
            [-42.918400246, -20.424646322999902],
            [-42.918419984, -20.424519654999902],
            [-42.918449893999899, -20.424388913],
            [-42.918499401, -20.424269769],
            [-42.918566294, -20.424218534],
            [-42.918747862, -20.4240932129999],
            [-42.918865191, -20.424018507],
            [-42.918955063999903, -20.423911706],
            [-42.918966377999901, -20.423756984],
            [-42.918922607999903, -20.423626285],
            [-42.918885192999902, -20.4234942269999],
            [-42.918756609, -20.423229681],
            [-42.918680824, -20.423262824999899],
            [-42.918476242999901, -20.423302658999901],
            [-42.918027799999898, -20.423183423999902],
            [-42.917601647, -20.423048175],
            [-42.917085782, -20.422928068],
            [-42.916804339999899, -20.422813451],
            [-42.916467559, -20.422521779],
            [-42.9164725259999, -20.422549558999901],
            [-42.916394382, -20.422602088],
            [-42.916298442, -20.42268917],
            [-42.916278670999901, -20.422755627999901],
            [-42.916333236999897, -20.422830392],
            [-42.916402739, -20.422834464999902],
            [-42.916417617, -20.422928839999901],
            [-42.916354032, -20.4229802259999],
            [-42.916302424, -20.423098013999901],
            [-42.916252296, -20.423180756],
            [-42.9161627, -20.423275869],
            [-42.916150337, -20.423342545],
            [-42.916110049999901, -20.423411393],
            [-42.916080627, -20.423483100999899],
            [-42.916057910999903, -20.423546555],
            [-42.915983181999898, -20.423694097999899],
            [-42.915978639999899, -20.423795335],
            [-42.915973775999902, -20.4238587799999],
            [-42.915928381999898, -20.4239768969999],
            [-42.91587928, -20.423995234],
            [-42.915849699, -20.424065108],
            [-42.915832135, -20.424156341],
            [-42.915840799, -20.42423471],
            [-42.915845917999903, -20.4243349229999],
            [-42.9158261709999, -20.424399197],
            [-42.9157855509999, -20.424499335999901],
            [-42.915863941999902, -20.4246740899999],
            [-42.915876255999898, -20.4247392199999],
            [-42.915878095, -20.4248689349999],
            [-42.915858601, -20.424951909],
            [-42.915814135999902, -20.425031928],
            [-42.915743442999897, -20.425137360999901],
            [-42.915700624, -20.425188295999899],
            [-42.915706719, -20.42529862],
            [-42.915758390999898, -20.425376528999902],
            [-42.915805623, -20.425457133],
            [-42.915854050999897, -20.425484933999901],
            [-42.915840607, -20.425566169],
            [-42.915858414999903, -20.425867321999899],
            [-42.9159244209999, -20.426078208],
            [-42.915949522999902, -20.4263496569999],
            [-42.915891427, -20.426701084999898],
            [-42.915900635999897, -20.426926849],
            [-42.916042445999899, -20.427088743],
            [-42.916083767, -20.427207182],
            [-42.916066348, -20.427266066],
            [-42.916012894999902, -20.427437573],
            [-42.915900799, -20.427583585],
            [-42.915762667, -20.427747648],
            [-42.915779611999902, -20.427815984999899],
            [-42.915752123999901, -20.427898295],
            [-42.915614827999903, -20.428000559999901],
            [-42.915524733, -20.427987662],
            [-42.915375018, -20.428044579],
            [-42.915268174999902, -20.428075622999899],
            [-42.915144551, -20.428106773],
            [-42.91502758, -20.428119470999899],
            [-42.914981092999902, -20.428168453999898],
            [-42.914969301, -20.428255055],
            [-42.9149317, -20.428334569999901],
            [-42.914849971, -20.428421906],
            [-42.914764221, -20.428517814],
            [-42.914620918, -20.428605185],
            [-42.914459909999898, -20.4287116419999],
            [-42.914217551, -20.42879254],
            [-42.91402297, -20.428795195],
            [-42.913688056, -20.428743557],
            [-42.913342020999899, -20.4285490299999],
            [-42.912837049, -20.428443396],
            [-42.912340223999898, -20.428436834],
            [-42.911951203999898, -20.428498577],
            [-42.911424306999898, -20.428731517],
            [-42.910867907999901, -20.429236623999898],
            [-42.910003979, -20.430078878],
            [-42.909892095, -20.430160391999902],
            [-42.909553936999899, -20.429914005999901],
            [-42.909342207999899, -20.429697303999902],
            [-42.909138938, -20.429396380999901],
            [-42.9087754399999, -20.429190465],
            [-42.908279928999903, -20.428977819999901],
            [-42.908070253999902, -20.428878261],
            [-42.90799455, -20.428795776]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 28,
        "id": 36939,
        "CD_SETOR": "315210505060025P",
        "AREA_KM2": 29.778487,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 389
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.940390426, -20.416749947],
            [-42.940328900999901, -20.416856757999899],
            [-42.940004119, -20.41726655],
            [-42.939412563, -20.417320187],
            [-42.938419156999899, -20.417403185999898],
            [-42.937153983, -20.417514325],
            [-42.937017824, -20.417497732999902],
            [-42.936912639999903, -20.417474065],
            [-42.936733954, -20.417399836999898],
            [-42.936605438999898, -20.417332685],
            [-42.936293973, -20.4171011789999],
            [-42.935721159, -20.416590810999899],
            [-42.935470696, -20.416326121999901],
            [-42.935300659999903, -20.416199897999899],
            [-42.935145540999898, -20.416119733999899],
            [-42.934946117, -20.416085403999901],
            [-42.934709822999899, -20.416090572],
            [-42.934537711, -20.416134151999898],
            [-42.934388813999902, -20.416230968],
            [-42.934290384999898, -20.416319909],
            [-42.934193563, -20.416450507],
            [-42.934169202, -20.416492622],
            [-42.933893151999897, -20.417255621],
            [-42.933801446, -20.417516234999901],
            [-42.933674359, -20.417816543999901],
            [-42.933598216999897, -20.417949431],
            [-42.933464489999899, -20.418089476],
            [-42.933278679999901, -20.418200151999901],
            [-42.9330709669999, -20.418271447999899],
            [-42.932830447, -20.418281170999901],
            [-42.932652335999897, -20.418240992999898],
            [-42.931768177, -20.417930301999899],
            [-42.931003825999902, -20.420167666999902],
            [-42.931245428, -20.420423398],
            [-42.931305419999902, -20.420711302],
            [-42.930245219, -20.4208630319999],
            [-42.930136025, -20.420703932],
            [-42.929380377, -20.4208228809999],
            [-42.928149775999898, -20.420792295999899],
            [-42.9269884079999, -20.420806959999901],
            [-42.9255219779999, -20.420775342999899],
            [-42.924067674999897, -20.4209236529999],
            [-42.924133977999901, -20.4212449629999],
            [-42.924131835, -20.421561788],
            [-42.924140436, -20.421857494999902],
            [-42.924117623, -20.422242552999901],
            [-42.924260118999896, -20.4223889209999],
            [-42.924371779999902, -20.4226143499999],
            [-42.924345925999901, -20.422871282],
            [-42.924373424, -20.423128248999902],
            [-42.92468337, -20.4233543409999],
            [-42.924902309999901, -20.423609785999901],
            [-42.925120426, -20.4239558629999],
            [-42.925348745999898, -20.424194932],
            [-42.925636473999901, -20.424371171],
            [-42.925779866, -20.424406037],
            [-42.926036675, -20.424466145999901],
            [-42.926225816999903, -20.424520293999901],
            [-42.925636789999899, -20.426080059999901],
            [-42.9249777489999, -20.426041637],
            [-42.924451476999899, -20.425868434999899],
            [-42.923350958999897, -20.42581797],
            [-42.923172843, -20.426313184],
            [-42.92250848, -20.427280263999901],
            [-42.922909846, -20.427778197999899],
            [-42.92320555, -20.428160413999901],
            [-42.923551898, -20.4288090279999],
            [-42.923782882999902, -20.429497457],
            [-42.923837578, -20.430602461],
            [-42.923915157, -20.431273181999899],
            [-42.924023857999899, -20.431780046],
            [-42.924897302, -20.43292159],
            [-42.925132689999899, -20.433239683999901],
            [-42.925639155999903, -20.433704862],
            [-42.927211906, -20.434704108999899],
            [-42.9282533579999, -20.435262244999901],
            [-42.929823399, -20.436042300999901],
            [-42.930521649999903, -20.436178587],
            [-42.932746719, -20.436221476],
            [-42.933577925, -20.436599746999899],
            [-42.933673663, -20.4369406389999],
            [-42.93355925, -20.437790864],
            [-42.932540585, -20.439716008999898],
            [-42.93217222, -20.440243247],
            [-42.931486122, -20.441016332999901],
            [-42.931416231999897, -20.441362463],
            [-42.93166718, -20.441810427],
            [-42.932186385, -20.442419931],
            [-42.932604200999897, -20.442710937],
            [-42.933312580999903, -20.442971219999901],
            [-42.934155141, -20.443028953999899],
            [-42.935081816, -20.443088911],
            [-42.935618017, -20.443154052],
            [-42.935890739999898, -20.443393611],
            [-42.936586645, -20.444457142],
            [-42.937012301, -20.444915794999901],
            [-42.937729037999901, -20.44532944],
            [-42.938329316, -20.445600425999899],
            [-42.939423401999903, -20.446017167],
            [-42.939756648999897, -20.446254435999901],
            [-42.940049465999898, -20.446581508999898],
            [-42.940105128, -20.446872923999901],
            [-42.940078716, -20.447210208999898],
            [-42.940085487, -20.447549277999901],
            [-42.940040522999901, -20.447890040999901],
            [-42.939979031999897, -20.4480549109999],
            [-42.939854384999897, -20.4482422469999],
            [-42.939478874, -20.448417392],
            [-42.938807868, -20.448668486],
            [-42.936664860999898, -20.4492742579999],
            [-42.936357425, -20.449503057],
            [-42.936174433, -20.449819287],
            [-42.936135125, -20.451213983999899],
            [-42.936573076999899, -20.452111003],
            [-42.937823597, -20.4533874749999],
            [-42.938931274, -20.454248499],
            [-42.9401225, -20.454664866],
            [-42.942053793, -20.454431364],
            [-42.942448548, -20.454461810999899],
            [-42.94267518, -20.454625098],
            [-42.943033738999901, -20.455312111999898],
            [-42.943172984999897, -20.455818103],
            [-42.943140114, -20.4562184649999],
            [-42.942871275999899, -20.4568188699999],
            [-42.942757883, -20.457292663],
            [-42.942819439999901, -20.457585542999901],
            [-42.9431042739999, -20.458123563],
            [-42.9437814469999, -20.458483455],
            [-42.944341466, -20.458551702],
            [-42.945038895, -20.458598563],
            [-42.945865419, -20.458986747],
            [-42.946926576, -20.459463405],
            [-42.947317327999897, -20.459747061],
            [-42.947938436, -20.4604562],
            [-42.948928458, -20.461204977],
            [-42.950201825, -20.461700798],
            [-42.950903484, -20.462076672],
            [-42.950996, -20.461924995999901],
            [-42.951141999999898, -20.461741995999901],
            [-42.951324999999898, -20.461580996],
            [-42.951557, -20.4614089959999],
            [-42.951789, -20.461224995999899],
            [-42.952021, -20.461064995999902],
            [-42.952204, -20.460915996],
            [-42.952423, -20.460834996],
            [-42.952704, -20.460708995999902],
            [-42.952972, -20.460663995999901],
            [-42.953215999999898, -20.460605996],
            [-42.953436, -20.460525995999902],
            [-42.953704, -20.460479995999901],
            [-42.953972, -20.460422996],
            [-42.954203999999898, -20.460400996],
            [-42.95446, -20.460411995999898],
            [-42.95474, -20.460423996],
            [-42.954984, -20.460469996],
            [-42.955289, -20.460516996],
            [-42.955569, -20.460550995999899],
            [-42.955862, -20.4606209959999],
            [-42.95613, -20.4606899959999],
            [-42.956398, -20.460770996],
            [-42.956654, -20.460885995999899],
            [-42.956971, -20.460965996],
            [-42.95719, -20.461046995999901],
            [-42.957507, -20.461161995999898],
            [-42.957726, -20.461253996],
            [-42.958006, -20.461323996],
            [-42.958299, -20.461392996],
            [-42.958543, -20.461496995999902],
            [-42.958835, -20.461553996],
            [-42.959091, -20.461737996],
            [-42.959323, -20.461864996],
            [-42.95953, -20.462025996],
            [-42.959712, -20.4621759959999],
            [-42.959907, -20.462370995999901],
            [-42.960102, -20.462623995999898],
            [-42.960259999999899, -20.462830996],
            [-42.960479, -20.463003995999902],
            [-42.960699, -20.463083996],
            [-42.96093, -20.4630959959999],
            [-42.961210999999899, -20.463049995999899],
            [-42.961528, -20.462969996],
            [-42.961747, -20.462843995999901],
            [-42.961991, -20.462694996],
            [-42.962211, -20.462545996],
            [-42.962443, -20.462407996],
            [-42.962711, -20.462281995999898],
            [-42.962943, -20.462166996],
            [-42.963186999999898, -20.462098996],
            [-42.963479999999898, -20.462075996],
            [-42.963687, -20.461972995999901],
            [-42.963992, -20.461880995999898],
            [-42.964222999999897, -20.461834995999901],
            [-42.964515999999897, -20.461777996],
            [-42.964748, -20.461755996],
            [-42.965065, -20.461709996],
            [-42.965308999999898, -20.461686996],
            [-42.966357, -20.461687996],
            [-42.966636999999899, -20.461699995999901],
            [-42.966893, -20.461699995999901],
            [-42.967247, -20.461734996],
            [-42.967613, -20.461780996],
            [-42.967856, -20.461827996],
            [-42.96821, -20.461850996],
            [-42.968442, -20.461793996],
            [-42.968746, -20.461908996],
            [-42.969014, -20.461977996],
            [-42.969245999999899, -20.462069996],
            [-42.96949, -20.462161996],
            [-42.969782, -20.462276996],
            [-42.970123, -20.4623699959999],
            [-42.970367, -20.462380996],
            [-42.970636, -20.462381996],
            [-42.970904, -20.462358996],
            [-42.971196, -20.462312996],
            [-42.971477, -20.462267996],
            [-42.971733, -20.462152995999901],
            [-42.972001, -20.462072996],
            [-42.972233, -20.461969995999901],
            [-42.972477, -20.461865996],
            [-42.972732999999899, -20.461762995999901],
            [-42.973014, -20.4617409959999],
            [-42.973256999999897, -20.461797996],
            [-42.973574, -20.461752996],
            [-42.973867, -20.461717995999901],
            [-42.974099, -20.461660996],
            [-42.974379, -20.4616269959999],
            [-42.97466, -20.461580996],
            [-42.974903, -20.461557996],
            [-42.975439999999899, -20.461558996],
            [-42.975757, -20.461478996],
            [-42.976062, -20.461490996],
            [-42.97633, -20.461524996],
            [-42.97661, -20.461559995999899],
            [-42.976903, -20.4616059959999],
            [-42.977195, -20.461686996],
            [-42.977488, -20.461755996],
            [-42.97778, -20.461836995999899],
            [-42.978085, -20.461962996],
            [-42.978377, -20.462020996],
            [-42.978694, -20.462135996],
            [-42.978926, -20.462216995999899],
            [-42.979255, -20.462308995999901],
            [-42.979547, -20.462377995999901],
            [-42.979802999999897, -20.462424996],
            [-42.980108, -20.462516996],
            [-42.980387999999898, -20.462619995999901],
            [-42.980595999999899, -20.4627359959999],
            [-42.980803, -20.462838996],
            [-42.981034, -20.462884996],
            [-42.981254, -20.462976996],
            [-42.981485, -20.463023995999901],
            [-42.981753, -20.463080996],
            [-42.982008999999898, -20.463138995999898],
            [-42.982326, -20.463173996],
            [-42.982607, -20.463196996],
            [-42.982886999999899, -20.463185995999901],
            [-42.983143, -20.463150996],
            [-42.983424, -20.463059996],
            [-42.983716, -20.462921996],
            [-42.983947999999899, -20.462875996],
            [-42.984192, -20.462841996],
            [-42.98446, -20.462749996],
            [-42.984814, -20.462681996],
            [-42.98507, -20.462681996],
            [-42.985399, -20.4626709959999],
            [-42.985728, -20.462682996],
            [-42.985971999999897, -20.462693995999899],
            [-42.986264999999896, -20.462728996],
            [-42.986556999999898, -20.462751996],
            [-42.986874, -20.462843995999901],
            [-42.987154, -20.462890995999899],
            [-42.987459, -20.462982995999901],
            [-42.987764, -20.463120995999901],
            [-42.988008, -20.463212996],
            [-42.988202999999899, -20.463339995999899],
            [-42.98841, -20.463500995999901],
            [-42.988556, -20.463672996],
            [-42.988714, -20.463868996],
            [-42.988885, -20.464052996],
            [-42.989006, -20.464259996],
            [-42.989078999999897, -20.464500996],
            [-42.989091, -20.464718995999899],
            [-42.989067, -20.464971996],
            [-42.989103, -20.465201996],
            [-42.989151, -20.465442996],
            [-42.989188, -20.465729995999901],
            [-42.9892, -20.465948996],
            [-42.989212, -20.466178995999901],
            [-42.989235999999899, -20.4664309959999],
            [-42.98926, -20.466718995999901],
            [-42.989321, -20.466936996],
            [-42.989418, -20.467189995999899],
            [-42.989466999999898, -20.467453996],
            [-42.989491, -20.467683995999899],
            [-42.989503, -20.467901996],
            [-42.989539, -20.468120996],
            [-42.989575, -20.468453995999901],
            [-42.989612, -20.468671996],
            [-42.989685, -20.468890996],
            [-42.989781999999899, -20.469096996],
            [-42.989928, -20.4693269959999],
            [-42.990062, -20.4695339959999],
            [-42.990769, -20.4700699959999],
            [-42.990744, -20.469775996],
            [-42.990757, -20.4694889959999],
            [-42.990794, -20.469247996],
            [-42.990855, -20.469028996],
            [-42.990952999999898, -20.468822996],
            [-42.991087, -20.468603996],
            [-42.991245999999897, -20.4684319959999],
            [-42.991502, -20.468213996],
            [-42.991661, -20.468052996],
            [-42.99188, -20.467846995999899],
            [-42.992123999999897, -20.467651996],
            [-42.992344, -20.467513996],
            [-42.992637, -20.467341996],
            [-42.992893, -20.467192995999898],
            [-42.9931, -20.467077995999901],
            [-42.993381, -20.466974996],
            [-42.9936, -20.466882996],
            [-42.993868, -20.4668139959999],
            [-42.994172999999897, -20.466745995999901],
            [-42.994503, -20.466676995999901],
            [-42.994783, -20.466619996],
            [-42.995160999999896, -20.466596996],
            [-42.995429, -20.4665859959999],
            [-42.996307, -20.466586995999901],
            [-42.996636, -20.466597996],
            [-42.996892, -20.466643996],
            [-42.997124, -20.466747996],
            [-42.997453, -20.466897995999901],
            [-42.997684, -20.466989995999899],
            [-42.997976999999899, -20.467150995999901],
            [-42.998172, -20.467276996],
            [-42.998391, -20.467403996],
            [-42.998623, -20.467530995999901],
            [-42.998842, -20.467645995999899],
            [-42.999061, -20.467748996],
            [-42.999293, -20.467875996],
            [-42.999561, -20.4679789959999],
            [-42.999794999999899, -20.468038996],
            [-42.9999, -20.468054996],
            [-43.000058, -20.468062996],
            [-43.000279, -20.468091995999899],
            [-43.000532, -20.468115995999899],
            [-43.000784, -20.4681829959999],
            [-43.001041, -20.4682729959999],
            [-43.001297, -20.468292995999899],
            [-43.001541, -20.468302996],
            [-43.001772, -20.468323996],
            [-43.002052999999897, -20.468354996],
            [-43.002612999999897, -20.468349996],
            [-43.002893, -20.468336995999898],
            [-43.003124, -20.468265996],
            [-43.003379, -20.468170996],
            [-43.003609, -20.468065996],
            [-43.003815, -20.467948996],
            [-43.003996, -20.467740996],
            [-43.004115, -20.467533995999901],
            [-43.004174, -20.467314996],
            [-43.004280999999899, -20.467095996],
            [-43.004425, -20.466875995999899],
            [-43.004435, -20.466646996],
            [-43.004469, -20.4663369959999],
            [-43.004491, -20.466117996],
            [-43.004500999999898, -20.465888995999901],
            [-43.004489, -20.464763995999899],
            [-43.004522999999899, -20.464498996],
            [-43.004568999999897, -20.464199995999898],
            [-43.004590999999898, -20.463981996],
            [-43.004674, -20.463739996],
            [-43.004782, -20.463543995999899],
            [-43.004975, -20.463369996],
            [-43.005218, -20.463287996],
            [-43.00546, -20.463216996],
            [-43.005669, -20.4633409959999],
            [-43.006025, -20.463670996],
            [-43.00621, -20.463875996],
            [-43.006394, -20.464022996],
            [-43.006627, -20.464181996],
            [-43.006824, -20.464317996],
            [-43.007056, -20.4644189959999],
            [-43.007288, -20.464474996],
            [-43.007532, -20.464540996],
            [-43.007776, -20.464550996],
            [-43.008032, -20.464547996],
            [-43.008311, -20.464499996],
            [-43.008566, -20.464393996],
            [-43.008833, -20.464310995999899],
            [-43.009065, -20.464297996],
            [-43.009285, -20.464410996],
            [-43.009481, -20.464534995999902],
            [-43.009724999999897, -20.464578995999901],
            [-43.009956, -20.464484996],
            [-43.0101, -20.464265995999899],
            [-43.010182, -20.463977996],
            [-43.010204, -20.463736996],
            [-43.010286999999899, -20.463482996],
            [-43.010333, -20.463252995999898],
            [-43.010367, -20.463011996],
            [-43.010376999999899, -20.462793996],
            [-43.010362, -20.4624839959999],
            [-43.010238, -20.462255996],
            [-43.01015, -20.461980996],
            [-43.01005, -20.4617409959999],
            [-43.009974, -20.461476995999899],
            [-43.009971999999898, -20.461247995999901],
            [-43.009976822999903, -20.461137057],
            [-43.009837096999902, -20.46079006],
            [-43.009809826, -20.460555395],
            [-43.009741058, -20.460383491999899],
            [-43.009552015999901, -20.4601948829999],
            [-43.009270171, -20.459999286],
            [-43.008693367, -20.459554344],
            [-43.008263568, -20.459098255999901],
            [-43.0075865109999, -20.457837078999901],
            [-43.007133268, -20.457233755999901],
            [-43.006821172999899, -20.4571394509999],
            [-43.006401996, -20.457264963999901],
            [-43.006004824999899, -20.457567347],
            [-43.0053399029999, -20.458730653],
            [-43.004788308999899, -20.459020196],
            [-43.003910752, -20.459534812],
            [-43.003038972999903, -20.4599384589999],
            [-43.001519187, -20.460289105],
            [-43.000718843, -20.460162231],
            [-43.000132451, -20.459872928],
            [-42.999595558, -20.458075365],
            [-42.999152196, -20.455461253],
            [-42.999193002, -20.453628203999902],
            [-42.999507466, -20.453075629999901],
            [-42.999940662, -20.452741246],
            [-43.00669082, -20.4538606529999],
            [-43.007090255, -20.4534656169999],
            [-43.007165998999902, -20.4529928279999],
            [-43.007105424, -20.451730153],
            [-43.00668346, -20.449959717],
            [-43.006644099, -20.449533774],
            [-43.006920782, -20.449078933],
            [-43.007207391, -20.448997941999899],
            [-43.007911588, -20.449098362],
            [-43.008165966, -20.449291613],
            [-43.008513590999897, -20.449451539999899],
            [-43.009005134999903, -20.449356413],
            [-43.009819813, -20.449320996],
            [-43.011859140999903, -20.449453419],
            [-43.012319010999903, -20.449550708999901],
            [-43.012679691999899, -20.449628563],
            [-43.013362819999898, -20.449600476999901],
            [-43.014069772, -20.44928262],
            [-43.014291162999903, -20.448805829],
            [-43.01426585, -20.448328135999901],
            [-43.014041018999897, -20.447838993999898],
            [-43.013985839, -20.447324711],
            [-43.013868848, -20.446637562],
            [-43.01372007, -20.4462017439999],
            [-43.012457965, -20.444686055],
            [-43.01178357, -20.443215105],
            [-43.010516902999903, -20.441023301],
            [-43.010218952999899, -20.440819237],
            [-43.009873292999899, -20.440731157],
            [-43.009439175999901, -20.440971385],
            [-43.008752383999898, -20.441547434],
            [-43.008166195999898, -20.441620182],
            [-43.007410037999897, -20.441486026],
            [-43.005874572, -20.440387036999901],
            [-43.005604036, -20.4402623],
            [-43.00516943, -20.440239525],
            [-43.003798812999896, -20.441059932],
            [-43.003211957, -20.441490348999899],
            [-43.002691441, -20.441537409],
            [-43.002367576, -20.441262061],
            [-43.002165901, -20.440907922999902],
            [-43.001594547, -20.440487354],
            [-43.000491223, -20.440209744],
            [-42.999012009, -20.440289182999901],
            [-42.998684292999897, -20.440395329999902],
            [-42.997903989, -20.442190975],
            [-42.99641472, -20.4432779119999],
            [-42.993391037, -20.444103459],
            [-42.991651638999898, -20.443315406],
            [-42.990837704999898, -20.44179596],
            [-42.990502126, -20.440609766],
            [-42.988971053999897, -20.440229057],
            [-42.9874245889999, -20.4404966819999],
            [-42.986252763, -20.4413661],
            [-42.985823507, -20.442685131],
            [-42.9850914579999, -20.444697286],
            [-42.9829616809999, -20.445955397],
            [-42.980889821999902, -20.44621734],
            [-42.980315785, -20.445320947],
            [-42.979780898999898, -20.4431052149999],
            [-42.978922627, -20.441244035],
            [-42.978337081999904, -20.437388894],
            [-42.978889248, -20.434001182],
            [-42.979308676999899, -20.432591124999899],
            [-42.979909892, -20.430714345],
            [-42.981068725999897, -20.429905961],
            [-42.982946614999904, -20.429314373],
            [-42.984654021, -20.429602759],
            [-42.985873308999899, -20.430224976],
            [-42.986339732, -20.431612088],
            [-42.98656962, -20.431911273],
            [-42.988113643, -20.431892725],
            [-42.98879957, -20.431285691],
            [-42.989103166999897, -20.430468642999902],
            [-42.989736185, -20.429945709],
            [-42.9906812259999, -20.429605895999899],
            [-42.99122676, -20.429528801999901],
            [-42.9917683319999, -20.4295806229999],
            [-42.992636559999902, -20.4304723189999],
            [-42.993148081999898, -20.430895527],
            [-42.993657238, -20.431166585],
            [-42.994363177, -20.431198534],
            [-42.995659529, -20.430963995],
            [-42.996452609999899, -20.430429273],
            [-42.996986976, -20.42982455],
            [-42.997193186999901, -20.427648548],
            [-42.99777929, -20.426723479],
            [-42.99859077, -20.4257256],
            [-42.998683937, -20.425218313999899],
            [-42.998519162, -20.424625492],
            [-42.997843577, -20.424057204],
            [-42.996817534999899, -20.424036036],
            [-42.995904621, -20.4246185659999],
            [-42.995147795, -20.424972893],
            [-42.993894283, -20.424566945],
            [-42.992710971, -20.42389258],
            [-42.991848446999903, -20.423800368999899],
            [-42.990880674, -20.424208228999898],
            [-42.990144932, -20.424332578],
            [-42.98893693, -20.424730092999901],
            [-42.986922061, -20.42485154],
            [-42.984944959, -20.4242728249999],
            [-42.983872795, -20.423841804999899],
            [-42.982682693999898, -20.424239714],
            [-42.975504326, -20.423293916],
            [-42.970278640999901, -20.422976655],
            [-42.968468597, -20.422886617],
            [-42.964675150999902, -20.424330655999899],
            [-42.960688301999902, -20.425928567],
            [-42.958480785999903, -20.425827177],
            [-42.957737622, -20.426157331],
            [-42.957234782999898, -20.426687289],
            [-42.9562586, -20.426930559],
            [-42.955474813, -20.4265969329999],
            [-42.953584942, -20.425294613999899],
            [-42.95293133, -20.424673501],
            [-42.952206298, -20.424210425],
            [-42.950164859, -20.423688263],
            [-42.946944533, -20.42242973],
            [-42.945318637, -20.42212754],
            [-42.943923087, -20.421765961999899],
            [-42.943801091999902, -20.421664253],
            [-42.943605904, -20.421525554],
            [-42.943353643, -20.421411303],
            [-42.943202437, -20.421374324999899],
            [-42.943106314, -20.42132849],
            [-42.942939685999903, -20.421212152],
            [-42.942843511, -20.421062508],
            [-42.942854632, -20.420981520999899],
            [-42.942934616999899, -20.420899033],
            [-42.942981516, -20.420891649999898],
            [-42.943080988, -20.420882816999899],
            [-42.943232497999901, -20.420767769999902],
            [-42.943345043, -20.4205765409999],
            [-42.9433600999999, -20.420453891],
            [-42.943285713, -20.420308457],
            [-42.943136801, -20.4202110159999],
            [-42.943130617999898, -20.420136353],
            [-42.943184451, -20.420077913999901],
            [-42.943325117, -20.420101337999899],
            [-42.943478879999901, -20.420112015],
            [-42.943646418, -20.4201075349999],
            [-42.943724651, -20.420115285999898],
            [-42.943769905999901, -20.42000694],
            [-42.943774867999899, -20.419785943],
            [-42.94376586, -20.419485231],
            [-42.943759278, -20.419355502],
            [-42.943872991, -20.4193352039999],
            [-42.943960860999901, -20.419285315],
            [-42.943996395999903, -20.418950628999902],
            [-42.944079082, -20.418876993],
            [-42.944103029, -20.418774511],
            [-42.944034363, -20.418673813999899],
            [-42.943843296, -20.418610697],
            [-42.943824441999901, -20.418528572],
            [-42.943839357999899, -20.418355284],
            [-42.943785981, -20.418248621],
            [-42.943800042, -20.418168674],
            [-42.943774845, -20.418138193999901],
            [-42.943661431, -20.418146559],
            [-42.943606384, -20.418049083],
            [-42.943503498, -20.417951192],
            [-42.943383226, -20.417946183],
            [-42.943312160999902, -20.417937797],
            [-42.943222354999897, -20.417993103999901],
            [-42.943186388999898, -20.418042753999899],
            [-42.943121526, -20.418105550999901],
            [-42.943118753, -20.418168992999899],
            [-42.943050701999901, -20.4182379179999],
            [-42.942980115999902, -20.418217669999901],
            [-42.942916048, -20.418175719],
            [-42.942792073, -20.418082174999899],
            [-42.942593054, -20.4180799969999],
            [-42.942459129, -20.417984057999899],
            [-42.942416486999903, -20.4178489459999],
            [-42.942451538999897, -20.417632645],
            [-42.942395336999901, -20.417572857],
            [-42.942326544, -20.417511724],
            [-42.942123912999897, -20.417485094],
            [-42.941675846, -20.417228457],
            [-42.941489366, -20.4171143209999],
            [-42.941430582999899, -20.417112078999899],
            [-42.941356227, -20.417145567],
            [-42.941296053, -20.417147359999898],
            [-42.941203953999903, -20.417093506],
            [-42.941189859, -20.417062181999899],
            [-42.941192782, -20.4170203309999],
            [-42.941233547, -20.416910770999898],
            [-42.941202013999899, -20.416863188],
            [-42.941110966999901, -20.416819624],
            [-42.941027584, -20.416782371],
            [-42.941015682999897, -20.416713990999899],
            [-42.941027414, -20.416528714],
            [-42.941042688, -20.416470172999901],
            [-42.941042212, -20.416406944999899],
            [-42.941023813999898, -20.4162937679999],
            [-42.940982534999897, -20.4161508249999],
            [-42.940912921999903, -20.415985765],
            [-42.940811880999902, -20.415807376],
            [-42.940802849, -20.415656299],
            [-42.940691879, -20.41571656],
            [-42.940652306999901, -20.41589055],
            [-42.940656496, -20.416184692999899],
            [-42.940582375, -20.416442382],
            [-42.940476343999897, -20.416671732999902],
            [-42.940390426, -20.416749947]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 29,
        "id": 36940,
        "CD_SETOR": "315210505060026P",
        "AREA_KM2": 33.488015,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 130
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.940802849, -20.415656299],
            [-42.940811880999902, -20.415807376],
            [-42.940912921999903, -20.415985765],
            [-42.940982534999897, -20.4161508249999],
            [-42.941023813999898, -20.4162937679999],
            [-42.941042212, -20.416406944999899],
            [-42.941042688, -20.416470172999901],
            [-42.941027414, -20.416528714],
            [-42.941015682999897, -20.416713990999899],
            [-42.941027584, -20.416782371],
            [-42.941110966999901, -20.416819624],
            [-42.941202013999899, -20.416863188],
            [-42.941233547, -20.416910770999898],
            [-42.941192782, -20.4170203309999],
            [-42.941189859, -20.417062181999899],
            [-42.941203953999903, -20.417093506],
            [-42.941296053, -20.417147359999898],
            [-42.941356227, -20.417145567],
            [-42.941430582999899, -20.417112078999899],
            [-42.941489366, -20.4171143209999],
            [-42.941675846, -20.417228457],
            [-42.942123912999897, -20.417485094],
            [-42.942326544, -20.417511724],
            [-42.942395336999901, -20.417572857],
            [-42.942451538999897, -20.417632645],
            [-42.942416486999903, -20.4178489459999],
            [-42.942459129, -20.417984057999899],
            [-42.942593054, -20.4180799969999],
            [-42.942792073, -20.418082174999899],
            [-42.942916048, -20.418175719],
            [-42.942980115999902, -20.418217669999901],
            [-42.943050701999901, -20.4182379179999],
            [-42.943118753, -20.418168992999899],
            [-42.943121526, -20.418105550999901],
            [-42.943186388999898, -20.418042753999899],
            [-42.943222354999897, -20.417993103999901],
            [-42.943312160999902, -20.417937797],
            [-42.943383226, -20.417946183],
            [-42.943503498, -20.417951192],
            [-42.943606384, -20.418049083],
            [-42.943661431, -20.418146559],
            [-42.943774845, -20.418138193999901],
            [-42.943800042, -20.418168674],
            [-42.943785981, -20.418248621],
            [-42.943839357999899, -20.418355284],
            [-42.943824441999901, -20.418528572],
            [-42.943843296, -20.418610697],
            [-42.944034363, -20.418673813999899],
            [-42.944103029, -20.418774511],
            [-42.944079082, -20.418876993],
            [-42.943996395999903, -20.418950628999902],
            [-42.943960860999901, -20.419285315],
            [-42.943872991, -20.4193352039999],
            [-42.943759278, -20.419355502],
            [-42.94376586, -20.419485231],
            [-42.943774867999899, -20.419785943],
            [-42.943769905999901, -20.42000694],
            [-42.943724651, -20.420115285999898],
            [-42.943646418, -20.4201075349999],
            [-42.943478879999901, -20.420112015],
            [-42.943325117, -20.420101337999899],
            [-42.943184451, -20.420077913999901],
            [-42.943130617999898, -20.420136353],
            [-42.943136801, -20.4202110159999],
            [-42.943285713, -20.420308457],
            [-42.9433600999999, -20.420453891],
            [-42.943345043, -20.4205765409999],
            [-42.943232497999901, -20.420767769999902],
            [-42.943080988, -20.420882816999899],
            [-42.942981516, -20.420891649999898],
            [-42.942934616999899, -20.420899033],
            [-42.942854632, -20.420981520999899],
            [-42.942843511, -20.421062508],
            [-42.942939685999903, -20.421212152],
            [-42.943106314, -20.42132849],
            [-42.943202437, -20.421374324999899],
            [-42.943353643, -20.421411303],
            [-42.943605904, -20.421525554],
            [-42.943801091999902, -20.421664253],
            [-42.943923087, -20.421765961999899],
            [-42.945318637, -20.42212754],
            [-42.946944533, -20.42242973],
            [-42.950164859, -20.423688263],
            [-42.952206298, -20.424210425],
            [-42.95293133, -20.424673501],
            [-42.953584942, -20.425294613999899],
            [-42.955474813, -20.4265969329999],
            [-42.9562586, -20.426930559],
            [-42.957234782999898, -20.426687289],
            [-42.957737622, -20.426157331],
            [-42.958480785999903, -20.425827177],
            [-42.960688301999902, -20.425928567],
            [-42.964675150999902, -20.424330655999899],
            [-42.968468597, -20.422886617],
            [-42.970278640999901, -20.422976655],
            [-42.975504326, -20.423293916],
            [-42.982682693999898, -20.424239714],
            [-42.983872795, -20.423841804999899],
            [-42.984944959, -20.4242728249999],
            [-42.986922061, -20.42485154],
            [-42.98893693, -20.424730092999901],
            [-42.990144932, -20.424332578],
            [-42.990880674, -20.424208228999898],
            [-42.991848446999903, -20.423800368999899],
            [-42.992710971, -20.42389258],
            [-42.993894283, -20.424566945],
            [-42.995147795, -20.424972893],
            [-42.995904621, -20.4246185659999],
            [-42.996817534999899, -20.424036036],
            [-42.997843577, -20.424057204],
            [-42.998519162, -20.424625492],
            [-42.998683937, -20.425218313999899],
            [-42.99859077, -20.4257256],
            [-42.99777929, -20.426723479],
            [-42.997193186999901, -20.427648548],
            [-42.996986976, -20.42982455],
            [-42.996452609999899, -20.430429273],
            [-42.995659529, -20.430963995],
            [-42.994363177, -20.431198534],
            [-42.993657238, -20.431166585],
            [-42.993148081999898, -20.430895527],
            [-42.992636559999902, -20.4304723189999],
            [-42.9917683319999, -20.4295806229999],
            [-42.99122676, -20.429528801999901],
            [-42.9906812259999, -20.429605895999899],
            [-42.989736185, -20.429945709],
            [-42.989103166999897, -20.430468642999902],
            [-42.98879957, -20.431285691],
            [-42.988113643, -20.431892725],
            [-42.98656962, -20.431911273],
            [-42.986339732, -20.431612088],
            [-42.985873308999899, -20.430224976],
            [-42.984654021, -20.429602759],
            [-42.982946614999904, -20.429314373],
            [-42.981068725999897, -20.429905961],
            [-42.979909892, -20.430714345],
            [-42.979308676999899, -20.432591124999899],
            [-42.978889248, -20.434001182],
            [-42.978337081999904, -20.437388894],
            [-42.978922627, -20.441244035],
            [-42.979780898999898, -20.4431052149999],
            [-42.980315785, -20.445320947],
            [-42.980889821999902, -20.44621734],
            [-42.9829616809999, -20.445955397],
            [-42.9850914579999, -20.444697286],
            [-42.985823507, -20.442685131],
            [-42.986252763, -20.4413661],
            [-42.9874245889999, -20.4404966819999],
            [-42.988971053999897, -20.440229057],
            [-42.990502126, -20.440609766],
            [-42.990837704999898, -20.44179596],
            [-42.991651638999898, -20.443315406],
            [-42.993391037, -20.444103459],
            [-42.99641472, -20.4432779119999],
            [-42.997903989, -20.442190975],
            [-42.998684292999897, -20.440395329999902],
            [-42.999012009, -20.440289182999901],
            [-43.000491223, -20.440209744],
            [-43.001594547, -20.440487354],
            [-43.002165901, -20.440907922999902],
            [-43.002367576, -20.441262061],
            [-43.002691441, -20.441537409],
            [-43.003211957, -20.441490348999899],
            [-43.003798812999896, -20.441059932],
            [-43.00516943, -20.440239525],
            [-43.005604036, -20.4402623],
            [-43.005874572, -20.440387036999901],
            [-43.007410037999897, -20.441486026],
            [-43.008166195999898, -20.441620182],
            [-43.008752383999898, -20.441547434],
            [-43.009439175999901, -20.440971385],
            [-43.009873292999899, -20.440731157],
            [-43.010218952999899, -20.440819237],
            [-43.010516902999903, -20.441023301],
            [-43.01178357, -20.443215105],
            [-43.012457965, -20.444686055],
            [-43.01372007, -20.4462017439999],
            [-43.013868848, -20.446637562],
            [-43.013985839, -20.447324711],
            [-43.014041018999897, -20.447838993999898],
            [-43.01426585, -20.448328135999901],
            [-43.014291162999903, -20.448805829],
            [-43.014069772, -20.44928262],
            [-43.013362819999898, -20.449600476999901],
            [-43.012679691999899, -20.449628563],
            [-43.012319010999903, -20.449550708999901],
            [-43.011859140999903, -20.449453419],
            [-43.009819813, -20.449320996],
            [-43.009005134999903, -20.449356413],
            [-43.008513590999897, -20.449451539999899],
            [-43.008165966, -20.449291613],
            [-43.007911588, -20.449098362],
            [-43.007207391, -20.448997941999899],
            [-43.006920782, -20.449078933],
            [-43.006644099, -20.449533774],
            [-43.00668346, -20.449959717],
            [-43.007105424, -20.451730153],
            [-43.007165998999902, -20.4529928279999],
            [-43.007090255, -20.4534656169999],
            [-43.00669082, -20.4538606529999],
            [-42.999940662, -20.452741246],
            [-42.999507466, -20.453075629999901],
            [-42.999193002, -20.453628203999902],
            [-42.999152196, -20.455461253],
            [-42.999595558, -20.458075365],
            [-43.000132451, -20.459872928],
            [-43.000718843, -20.460162231],
            [-43.001519187, -20.460289105],
            [-43.003038972999903, -20.4599384589999],
            [-43.003910752, -20.459534812],
            [-43.004788308999899, -20.459020196],
            [-43.0053399029999, -20.458730653],
            [-43.006004824999899, -20.457567347],
            [-43.006401996, -20.457264963999901],
            [-43.006821172999899, -20.4571394509999],
            [-43.007133268, -20.457233755999901],
            [-43.0075865109999, -20.457837078999901],
            [-43.008263568, -20.459098255999901],
            [-43.008693367, -20.459554344],
            [-43.009270171, -20.459999286],
            [-43.009552015999901, -20.4601948829999],
            [-43.009741058, -20.460383491999899],
            [-43.009809826, -20.460555395],
            [-43.009837096999902, -20.46079006],
            [-43.009976822999903, -20.461137057],
            [-43.009982, -20.461017996],
            [-43.01015, -20.460855996],
            [-43.010442, -20.460783996],
            [-43.010672999999898, -20.460724996],
            [-43.010904, -20.460653996],
            [-43.011183, -20.460547995999899],
            [-43.011412999999898, -20.460465996],
            [-43.011668, -20.460382996],
            [-43.011948, -20.460334996],
            [-43.012264, -20.460308996],
            [-43.012849, -20.460280995999899],
            [-43.013092, -20.460231995999901],
            [-43.013408, -20.460160996],
            [-43.013675, -20.4600779959999],
            [-43.013905, -20.459972996],
            [-43.014159999999897, -20.459900995999899],
            [-43.014439, -20.459806995999902],
            [-43.014707, -20.459723996],
            [-43.014937, -20.459641996],
            [-43.01518, -20.459570996],
            [-43.01541, -20.459464996],
            [-43.015666, -20.459405995999902],
            [-43.015872, -20.459300996],
            [-43.016077, -20.459171996],
            [-43.016307, -20.4590209959999],
            [-43.016513, -20.458846996],
            [-43.016644, -20.458638995999902],
            [-43.016751999999897, -20.458419996],
            [-43.016847, -20.458200996],
            [-43.016979, -20.457992996],
            [-43.017086, -20.4577629959999],
            [-43.017206, -20.457554995999899],
            [-43.017338, -20.457335995999902],
            [-43.017407999999897, -20.457116996],
            [-43.017528, -20.456874996],
            [-43.017659, -20.456643996],
            [-43.017767, -20.456413996],
            [-43.017947, -20.4561589959999],
            [-43.018116, -20.455996996],
            [-43.018321, -20.455811996],
            [-43.018563, -20.455625996],
            [-43.018731, -20.455405996],
            [-43.018912, -20.455266996],
            [-43.019092999999899, -20.455115996],
            [-43.019298999999897, -20.454964995999902],
            [-43.019529, -20.454813995999899],
            [-43.019770999999899, -20.454638996],
            [-43.020060999999899, -20.454498995999899],
            [-43.020241999999897, -20.454312995999899],
            [-43.020423, -20.454162996],
            [-43.020665999999899, -20.454045995999898],
            [-43.020944, -20.453893996],
            [-43.021138, -20.4537539959999],
            [-43.021391999999899, -20.453613995999898],
            [-43.021647, -20.453485995999898],
            [-43.021889, -20.453391995999901],
            [-43.022106999999899, -20.453262996],
            [-43.022351, -20.453283996],
            [-43.022643, -20.453223996],
            [-43.022897999999898, -20.453129996],
            [-43.023189, -20.453080996],
            [-43.023591, -20.453019996],
            [-43.023906, -20.452902996],
            [-43.024149, -20.452796996],
            [-43.024392, -20.452725996],
            [-43.024682, -20.452585996],
            [-43.024924999999897, -20.452456996],
            [-43.025179, -20.452282996],
            [-43.025384, -20.452085995999902],
            [-43.025551999999898, -20.451888996],
            [-43.025708, -20.451680995999901],
            [-43.025864, -20.451449996],
            [-43.025959, -20.451207995999901],
            [-43.026042, -20.450942996],
            [-43.026149, -20.450723996],
            [-43.026293, -20.450492996],
            [-43.026497999999897, -20.450318996],
            [-43.026727999999899, -20.450144995999899],
            [-43.026922, -20.450016995999899],
            [-43.027189, -20.4499689959999],
            [-43.027420999999897, -20.449977996],
            [-43.027651999999897, -20.449952996],
            [-43.027895999999899, -20.450007996],
            [-43.028152, -20.450074996],
            [-43.028396, -20.450083995999901],
            [-43.028762, -20.450126995999899],
            [-43.029079, -20.450180996],
            [-43.029334999999897, -20.450224996],
            [-43.02959, -20.450164996],
            [-43.029834999999899, -20.450242995999901],
            [-43.030092, -20.4503559959999],
            [-43.030324, -20.450456996],
            [-43.030557, -20.4505809959999],
            [-43.030838, -20.450669995999899],
            [-43.031082, -20.450747996],
            [-43.031401, -20.450905996],
            [-43.031621, -20.450995996],
            [-43.031841, -20.451073995999899],
            [-43.03211, -20.451186995999901],
            [-43.032355, -20.451287996],
            [-43.032598999999898, -20.451342995999902],
            [-43.032903, -20.451351996],
            [-43.033195, -20.451325996],
            [-43.033487, -20.451265996],
            [-43.033704999999898, -20.451160995999899],
            [-43.033910999999897, -20.451031995999902],
            [-43.034092, -20.450881996],
            [-43.034273, -20.450741996],
            [-43.034540999999898, -20.450716996],
            [-43.034844, -20.450575995999898],
            [-43.035062, -20.450401996],
            [-43.03523, -20.450227996],
            [-43.035423, -20.450042996],
            [-43.035604, -20.449880995999902],
            [-43.035749, -20.449706996],
            [-43.035905, -20.449521995999898],
            [-43.036062, -20.449359995999899],
            [-43.036234, -20.448892995999898],
            [-43.036093, -20.448807996],
            [-43.035896, -20.448649996],
            [-43.035736, -20.448455996],
            [-43.035575, -20.448181996],
            [-43.035414, -20.4479649959999],
            [-43.035254, -20.447793996],
            [-43.035106, -20.447623995999901],
            [-43.034909, -20.447429996],
            [-43.034688, -20.447213996],
            [-43.034515999999897, -20.447054996],
            [-43.034283, -20.446964996],
            [-43.034087, -20.446817995999901],
            [-43.033791999999899, -20.446601996],
            [-43.033584, -20.446443996],
            [-43.033363, -20.446272995999902],
            [-43.03313, -20.446114995999899],
            [-43.032969, -20.445932996],
            [-43.032833, -20.445715995999901],
            [-43.032781999999898, -20.445463995999901],
            [-43.032803, -20.445176995999901],
            [-43.032849999999897, -20.444946995999899],
            [-43.032969, -20.444703995999902],
            [-43.033101, -20.444484996],
            [-43.033208, -20.444219996],
            [-43.033266, -20.4440009959999],
            [-43.033312, -20.4437139959999],
            [-43.033309, -20.443460995999899],
            [-43.033281, -20.443025996],
            [-43.033241, -20.442761995999899],
            [-43.033178, -20.442520995999899],
            [-43.033103, -20.442303996],
            [-43.033015, -20.442074996],
            [-43.032891, -20.441869996],
            [-43.032742, -20.441583996],
            [-43.032642, -20.4413669959999],
            [-43.032553999999898, -20.441091996],
            [-43.032479, -20.440874995999899],
            [-43.032342, -20.440611996],
            [-43.032255, -20.440371996],
            [-43.03224, -20.440130995999901],
            [-43.032238, -20.439900995999899],
            [-43.032296, -20.439670996],
            [-43.032404, -20.439451995999899],
            [-43.032522, -20.439163995999898],
            [-43.032716, -20.438989996],
            [-43.032919999999898, -20.438746996],
            [-43.033064, -20.438492996],
            [-43.033183, -20.438261995999898],
            [-43.03329, -20.437985996],
            [-43.033324, -20.437766995999901],
            [-43.033285, -20.437549996],
            [-43.033234, -20.437308996],
            [-43.033159, -20.437068995999901],
            [-43.033071, -20.4368399959999],
            [-43.032958999999899, -20.436622996],
            [-43.032811, -20.4364179959999],
            [-43.032626, -20.436177996],
            [-43.032526, -20.435960995999899],
            [-43.032366, -20.435755996],
            [-43.032218, -20.435561995999901],
            [-43.031751, -20.4352219959999],
            [-43.031519, -20.435120996],
            [-43.031298, -20.434995995999898],
            [-43.031126, -20.434836995999898],
            [-43.031002, -20.434619996],
            [-43.030890999999897, -20.434425996],
            [-43.030752999999898, -20.434116995999901],
            [-43.03069, -20.433899996],
            [-43.030664, -20.433658996],
            [-43.030649, -20.433440995999899],
            [-43.030647, -20.4331999959999],
            [-43.030657, -20.432981996],
            [-43.030678, -20.432717996],
            [-43.030687999999898, -20.432441996],
            [-43.03071, -20.432223995999902],
            [-43.03072, -20.432005995999901],
            [-43.030717, -20.431763996],
            [-43.030642, -20.431535996],
            [-43.030518, -20.431329995999899],
            [-43.030358, -20.431147995999901],
            [-43.030138, -20.4310579959999],
            [-43.029905, -20.430979996],
            [-43.029649, -20.430924995999899],
            [-43.029417, -20.430834995999898],
            [-43.029244999999896, -20.430686996],
            [-43.029071, -20.430344996],
            [-43.028959, -20.430138996],
            [-43.028859, -20.429863995999899],
            [-43.028819999999897, -20.429634995999901],
            [-43.028769, -20.429371995999901],
            [-43.028728999999899, -20.429095995999901],
            [-43.028678, -20.428855996],
            [-43.028638999999899, -20.428591996],
            [-43.028633, -20.428052996],
            [-43.028593999999899, -20.427754996],
            [-43.028638999999899, -20.427466996],
            [-43.02872, -20.427041996],
            [-43.028778, -20.426753995999899],
            [-43.028898, -20.426545996],
            [-43.029029, -20.426292996],
            [-43.029111999999898, -20.426050996],
            [-43.029182999999897, -20.425808995999901],
            [-43.029204999999898, -20.425590996],
            [-43.029239, -20.4253379959999],
            [-43.029212, -20.425119996],
            [-43.029063999999899, -20.424948996],
            [-43.028965, -20.424731995999899],
            [-43.028841, -20.424526995999901],
            [-43.028753, -20.424285996],
            [-43.028653, -20.424068996],
            [-43.028602, -20.423827995999901],
            [-43.028526, -20.423564996],
            [-43.028463, -20.423347996],
            [-43.028217, -20.4230399959999],
            [-43.028105, -20.422822996],
            [-43.027918999999898, -20.422559996],
            [-43.027723, -20.422400996],
            [-43.027453, -20.422208995999899],
            [-43.027209, -20.422141995999901],
            [-43.026965, -20.4221439959999],
            [-43.026722, -20.422203996],
            [-43.026454, -20.422171996],
            [-43.026186, -20.422150996],
            [-43.025906, -20.422130996],
            [-43.025637, -20.4220989959999],
            [-43.025357, -20.422020996],
            [-43.025075, -20.4219199959999],
            [-43.024867, -20.421807995999899],
            [-43.024683, -20.4216709959999],
            [-43.024523, -20.421477996],
            [-43.024423, -20.421271996],
            [-43.024335999999899, -20.421042996],
            [-43.024273, -20.4208139959999],
            [-43.024245, -20.420527996],
            [-43.024304, -20.420273995999899],
            [-43.02435, -20.419998995999901],
            [-43.024394, -20.419630996],
            [-43.024403999999898, -20.419412995999899],
            [-43.024425999999899, -20.419182996],
            [-43.024436, -20.418952996],
            [-43.024434, -20.418688996],
            [-43.024453999999899, -20.418332995999901],
            [-43.024488, -20.418102996],
            [-43.024547, -20.417872996],
            [-43.024605, -20.417630996],
            [-43.0247, -20.417377996],
            [-43.024796, -20.417169995999899],
            [-43.024952, -20.416984995999901],
            [-43.025132, -20.416776996],
            [-43.025289, -20.416557995999899],
            [-43.025421, -20.416372996],
            [-43.025479, -20.416153995999899],
            [-43.025477, -20.415923995999901],
            [-43.025401, -20.415695996],
            [-43.025168999999899, -20.415605996],
            [-43.024937, -20.415504996],
            [-43.024668, -20.415437996],
            [-43.024653999999899, -20.415208995999901],
            [-43.024650999999899, -20.414978995999899],
            [-43.024636, -20.414703996],
            [-43.024549, -20.414486995999901],
            [-43.024437, -20.414245996],
            [-43.024277, -20.414086996],
            [-43.024081, -20.413939996],
            [-43.023835, -20.413757996],
            [-43.023614, -20.413599996],
            [-43.02343, -20.413440996],
            [-43.023355, -20.413222995999899],
            [-43.023365, -20.4129819959999],
            [-43.023386, -20.412728995999899],
            [-43.023457, -20.412452996],
            [-43.023527, -20.412233995999902],
            [-43.023586, -20.412003996],
            [-43.023583, -20.411728996],
            [-43.023556, -20.411487995999899],
            [-43.023603, -20.411257996],
            [-43.023673, -20.411027996],
            [-43.023732, -20.410785996],
            [-43.02379, -20.410509996],
            [-43.023848, -20.4102799959999],
            [-43.023868999999898, -20.410217996],
            [-43.024014, -20.409795996],
            [-43.0241125699999, -20.409649634999901],
            [-43.024106624, -20.409646575999901],
            [-43.023583829, -20.409065822999899],
            [-43.023425317, -20.408784265],
            [-43.02333277, -20.408573068],
            [-43.023230315, -20.4085135239999],
            [-43.023080235999899, -20.408433128],
            [-43.022877729999898, -20.408303913999902],
            [-43.022848094, -20.408198737],
            [-43.0227621229999, -20.408107508999901],
            [-43.022574768, -20.408091487],
            [-43.022439273, -20.408076573],
            [-43.022280767, -20.4081137269999],
            [-43.022196689999902, -20.408189574999898],
            [-43.022109417, -20.408224923],
            [-43.0219494689999, -20.408215601999899],
            [-43.021727681999899, -20.408077181],
            [-43.021635831, -20.407981895],
            [-43.021597307, -20.407841157],
            [-43.021636137, -20.407480262],
            [-43.021600811, -20.407304751],
            [-43.0215827769999, -20.407124956],
            [-43.021647666, -20.406975510999899],
            [-43.021700984999903, -20.406430047],
            [-43.021630044999903, -20.406209267999898],
            [-43.021705915, -20.4058587],
            [-43.021803889999902, -20.4056306959999],
            [-43.021795208999897, -20.405526688],
            [-43.0216288089999, -20.405272533],
            [-43.02154253, -20.405081450999901],
            [-43.021492445, -20.405033219],
            [-43.021415222, -20.405015541999902],
            [-43.021316816, -20.4049707799999],
            [-43.021114538999903, -20.404853303],
            [-43.020865498, -20.4048295609999],
            [-43.020733617, -20.404779258],
            [-43.020746155, -20.4045622269999],
            [-43.020679173, -20.404472976],
            [-43.020624672999901, -20.404279142999901],
            [-43.020574229, -20.4040599319999],
            [-43.020524958, -20.403605803999898],
            [-43.020455356, -20.403463342],
            [-43.020341904, -20.403382548],
            [-43.020210759999898, -20.40338544],
            [-43.020086455, -20.40350253],
            [-43.019975465, -20.4034971949999],
            [-43.01987824, -20.40348841],
            [-43.019793039, -20.403491909],
            [-43.019734578999902, -20.403388805],
            [-43.019605833, -20.40330671],
            [-43.019407348999898, -20.403286376],
            [-43.019200273, -20.40325789],
            [-43.019082672, -20.4031958099999],
            [-43.018977537999902, -20.403199479],
            [-43.018843849999897, -20.403208806999899],
            [-43.018771120999901, -20.403196052999899],
            [-43.018697645, -20.403156748999901],
            [-43.018384807, -20.403070068],
            [-43.018283790999902, -20.40307072],
            [-43.018178045, -20.403151931],
            [-43.018144413, -20.4031884669999],
            [-43.018119102, -20.403281622999899],
            [-43.018011175, -20.4033701349999],
            [-43.017874247999899, -20.403490325999901],
            [-43.01778139, -20.403605204],
            [-43.017669236, -20.403683229],
            [-43.017522183, -20.403619013],
            [-43.0173991569999, -20.403554665999899],
            [-43.017253657, -20.403206908999898],
            [-43.01699768, -20.40295509],
            [-43.016955959, -20.402817250999899],
            [-43.016917260999897, -20.402688321],
            [-43.016949607, -20.402573522],
            [-43.01704732, -20.402466279999899],
            [-43.017396435, -20.402226496],
            [-43.017568577, -20.402106617999902],
            [-43.017620257, -20.402001584999901],
            [-43.017603999999899, -20.401866459],
            [-43.017437527, -20.401452771],
            [-43.0172706, -20.401232668],
            [-43.017137232, -20.401160601999901],
            [-43.017055679999899, -20.401024701],
            [-43.016917028, -20.400865766],
            [-43.016830006, -20.400675481999901],
            [-43.016891412999897, -20.400630345],
            [-43.016947519, -20.400554150999898],
            [-43.017005262999902, -20.400516598],
            [-43.016992984999902, -20.400427212999901],
            [-43.0169090099999, -20.400346442999901],
            [-43.0168966109999, -20.400210073],
            [-43.016954907, -20.400064089],
            [-43.01695389, -20.399868988],
            [-43.016810482, -20.399667768],
            [-43.01668374, -20.399595901999898],
            [-43.016565924, -20.399595459],
            [-43.016487997999903, -20.399569957],
            [-43.016454699, -20.399464316],
            [-43.016514156, -20.39926364],
            [-43.016597238, -20.399198607999899],
            [-43.016616156999902, -20.399114684],
            [-43.016701302999898, -20.398896407999899],
            [-43.016816658, -20.398807533],
            [-43.016909806999898, -20.398577474],
            [-43.0168753029999, -20.398403325999901],
            [-43.016880574, -20.398328917999901],
            [-43.016876130999897, -20.398194696],
            [-43.016860935999901, -20.397996116999899],
            [-43.016839391, -20.397830113999898],
            [-43.016882501999902, -20.397719594],
            [-43.01689413, -20.397633437],
            [-43.016857408, -20.397520678],
            [-43.016751453, -20.3974240659999],
            [-43.016529469, -20.397336138],
            [-43.016286507, -20.3972608139999],
            [-43.016194631, -20.3971792319999],
            [-43.016050838, -20.3968905249999],
            [-43.016143316999901, -20.396794954],
            [-43.016160043999903, -20.396678415999901],
            [-43.016155784, -20.396606705999901],
            [-43.016087962, -20.396532346999901],
            [-43.01607842, -20.396309479],
            [-43.016078531999902, -20.396124660999899],
            [-43.016016243, -20.396029374],
            [-43.015860491, -20.395925335],
            [-43.015707667999898, -20.395858706999899],
            [-43.015516176999903, -20.395814527],
            [-43.01534929, -20.395731468],
            [-43.015208216, -20.395562323],
            [-43.015000261999901, -20.394982253],
            [-43.014956995, -20.394795008],
            [-43.014855114999897, -20.394721659],
            [-43.014743758, -20.394723166999899],
            [-43.014647463, -20.394708821999899],
            [-43.014540294, -20.394656567],
            [-43.014437748, -20.394697189999899],
            [-43.014352894, -20.3948117149999],
            [-43.014359812999899, -20.3949812339999],
            [-43.014413596999901, -20.395144385],
            [-43.014471962, -20.395240245],
            [-43.014458637, -20.3953257659999],
            [-43.014344994999902, -20.395408543],
            [-43.014291036, -20.395459784],
            [-43.014139964, -20.395429835999899],
            [-43.013956966, -20.395449006],
            [-43.013801077, -20.395542264],
            [-43.013500942999897, -20.3956752899999],
            [-43.013003685999898, -20.395807546999901],
            [-43.012659375999903, -20.395860083999899],
            [-43.012544324, -20.395886371],
            [-43.012267731999899, -20.395860700999901],
            [-43.012143006, -20.39587018],
            [-43.012049057, -20.395955477],
            [-43.012034819, -20.396045596],
            [-43.012071813, -20.39617915],
            [-43.012046211, -20.3962650829999],
            [-43.011903509, -20.396279797],
            [-43.011792168999897, -20.396221189999899],
            [-43.011758834, -20.396098722],
            [-43.011731343, -20.396004755],
            [-43.011646524, -20.395954279],
            [-43.011556946, -20.39599078],
            [-43.011463960999897, -20.39604653],
            [-43.011425334, -20.3961406459999],
            [-43.011306216, -20.396261533],
            [-43.011312979, -20.396366219999901],
            [-43.011578716, -20.396414708999899],
            [-43.011888370999898, -20.3965681029999],
            [-43.012038517999898, -20.396647373],
            [-43.011970677, -20.396749063],
            [-43.011815568, -20.396867239999899],
            [-43.011711518, -20.3969713299999],
            [-43.011611043999899, -20.3969401159999],
            [-43.011492725, -20.396920223999899],
            [-43.011461946999901, -20.3968386129999],
            [-43.011443611999901, -20.396693238999902],
            [-43.011286824, -20.396661816999899],
            [-43.010966198999903, -20.396742721],
            [-43.010820368999902, -20.396752321999902],
            [-43.010769331999903, -20.396837653],
            [-43.010775298999903, -20.396927106],
            [-43.010802187, -20.3970509379999],
            [-43.01083399, -20.397139527],
            [-43.010739053999899, -20.397186571],
            [-43.010589109, -20.397187726],
            [-43.010491129999899, -20.397200091999899],
            [-43.010357789, -20.397298368999898],
            [-43.010050774, -20.3974463129999],
            [-43.009527433, -20.397650854999899],
            [-43.009453790999899, -20.3977597149999],
            [-43.009430113, -20.397830434],
            [-43.009180612, -20.397997071],
            [-43.009023562, -20.398112581],
            [-43.008865804, -20.398292058],
            [-43.008842564, -20.398555997],
            [-43.008769999, -20.398720453],
            [-43.008685778, -20.398797444],
            [-43.0086026829999, -20.398790925999901],
            [-43.007940386, -20.398609006],
            [-43.00776146, -20.398538732],
            [-43.007642110999903, -20.398551348],
            [-43.00755108, -20.398596691],
            [-43.007506994, -20.398695319],
            [-43.007501195, -20.398831376],
            [-43.007439577, -20.3989234229999],
            [-43.007330923, -20.398961234999899],
            [-43.007193891, -20.3989182239999],
            [-43.007160299, -20.3988335089999],
            [-43.007017192, -20.398667936],
            [-43.00688336, -20.398662134999899],
            [-43.006699494, -20.398593196],
            [-43.006659159, -20.3985064469999],
            [-43.006667466, -20.398411673999899],
            [-43.006719571, -20.398319469999901],
            [-43.006678221999898, -20.39816529],
            [-43.006602629, -20.398054940999899],
            [-43.006542134, -20.398081487],
            [-43.006469513999903, -20.398196721],
            [-43.006300285, -20.398320133],
            [-43.006217153, -20.398337193],
            [-43.006090749, -20.398332320999899],
            [-43.006045024999899, -20.398416343],
            [-43.005909139, -20.39852023],
            [-43.005686293999901, -20.398653474],
            [-43.005544008, -20.398683537],
            [-43.005422476, -20.398629725],
            [-43.005376306999899, -20.398524150999901],
            [-43.005512262, -20.3983498589999],
            [-43.005551232999899, -20.398094184999898],
            [-43.005641091, -20.39786363],
            [-43.005683623, -20.397733573],
            [-43.005616877, -20.397638969],
            [-43.005546159999902, -20.39761055],
            [-43.005432415, -20.397614774],
            [-43.005340819, -20.397624811],
            [-43.005124212999903, -20.397568443999901],
            [-43.004901737999901, -20.397518863],
            [-43.004610243999899, -20.397516019],
            [-43.00429688, -20.397462704],
            [-43.004017403, -20.397458524999902],
            [-43.003868804, -20.397506951],
            [-43.003766013, -20.397584916],
            [-43.003743642, -20.397666069],
            [-43.003806085999898, -20.397764238],
            [-43.003789471999902, -20.397828487],
            [-43.003681076, -20.397825899999901],
            [-43.003641057, -20.397781086999899],
            [-43.003553044999897, -20.397797941],
            [-43.003345163999903, -20.397878288999902],
            [-43.003226094999903, -20.397821752],
            [-43.003125924, -20.3977817859999],
            [-43.0029881359999, -20.397776356999898],
            [-43.002882354, -20.397783127],
            [-43.00280355, -20.397730094999901],
            [-43.002705165, -20.3977267389999],
            [-43.002635568, -20.397766012],
            [-43.002518097999904, -20.397769444999899],
            [-43.002297244, -20.39781361],
            [-43.002127582999897, -20.397825898],
            [-43.0020172649999, -20.397876153],
            [-43.001599461, -20.397888033999902],
            [-43.001258445, -20.397971641],
            [-43.000946688999903, -20.397977598999901],
            [-43.000854034, -20.3979482189999],
            [-43.000699982, -20.3978450719999],
            [-43.000742124999903, -20.397762491],
            [-43.000845563, -20.397709872],
            [-43.000840018, -20.397592985],
            [-43.00082501, -20.397472380999901],
            [-43.0007885959999, -20.397468221999901],
            [-43.000744906, -20.397499568999901],
            [-43.000646517, -20.397477423999899],
            [-43.000539945, -20.397428674],
            [-43.000438388, -20.397432519999899],
            [-43.0003968729999, -20.397577058],
            [-43.0003426009999, -20.397703008],
            [-43.000186116999899, -20.397834281],
            [-42.999952198, -20.397980978],
            [-42.999915404, -20.398094804],
            [-42.999847218999903, -20.398232477],
            [-42.999787542, -20.398360031],
            [-42.999810399, -20.39845437],
            [-42.999878464999902, -20.398551445],
            [-42.999919168, -20.39851829],
            [-42.999944098999897, -20.398529718],
            [-42.999995656, -20.398549151999902],
            [-43.000031258, -20.398613856999901],
            [-42.999980105, -20.398698404],
            [-42.999825906999902, -20.398968559],
            [-42.999679812, -20.3991383769999],
            [-42.999529763999902, -20.399162967999899],
            [-42.999366384, -20.399243774],
            [-42.999239377, -20.399369313],
            [-42.999112818, -20.399465484],
            [-42.999014493999901, -20.399471814],
            [-42.99887611, -20.399434524999901],
            [-42.99880934, -20.399417843],
            [-42.998703186999897, -20.399433798],
            [-42.998565361, -20.399468578],
            [-42.998433749999897, -20.399420424],
            [-42.998364077, -20.3994582],
            [-42.998265381, -20.399408372],
            [-42.9982202899999, -20.399445536],
            [-42.998225594, -20.399501818],
            [-42.998183049, -20.399567510999901],
            [-42.998132751, -20.399589509],
            [-42.9979989079999, -20.39958652],
            [-42.997915323, -20.399627571999901],
            [-42.997878297999897, -20.3996756569999],
            [-42.997745532, -20.399635308],
            [-42.997747293, -20.399588556],
            [-42.997788456, -20.3995030839999],
            [-42.997749911, -20.399437153999902],
            [-42.997593997, -20.3993341249999],
            [-42.997470909999898, -20.399215863999899],
            [-42.997434214, -20.399099175999901],
            [-42.997283205, -20.398920462],
            [-42.997009053999903, -20.398726865999901],
            [-42.996896571, -20.398651378],
            [-42.996867651999899, -20.398559392],
            [-42.996911966, -20.398522084],
            [-42.996980746, -20.398440521999898],
            [-42.997021181, -20.398409893],
            [-42.9970119, -20.398331819],
            [-42.996890417, -20.398178882],
            [-42.996825717, -20.398138678],
            [-42.996686054, -20.398146244],
            [-42.9965992099999, -20.398150803],
            [-42.996475217999901, -20.398121048],
            [-42.996371447999898, -20.398168456],
            [-42.996281005999897, -20.398265371],
            [-42.996227738, -20.398280997],
            [-42.996167479999897, -20.398287206],
            [-42.996039924999899, -20.39810761],
            [-42.995856527999898, -20.398007508],
            [-42.995657724, -20.3980040389999],
            [-42.995461099, -20.398037411],
            [-42.995332849, -20.398021517],
            [-42.995118761999898, -20.397987064999899],
            [-42.994997813999902, -20.397927237],
            [-42.994836888999899, -20.397797354],
            [-42.994705934, -20.3976627609999],
            [-42.994717115999897, -20.397495896],
            [-42.994709268999898, -20.397404461999901],
            [-42.994651888, -20.397370085],
            [-42.994565461, -20.397364432],
            [-42.994505279, -20.397396108],
            [-42.9944332869999, -20.397383561],
            [-42.994349349999901, -20.397313982999901],
            [-42.994276774, -20.397299600999901],
            [-42.994169978999899, -20.397326536],
            [-42.994132153, -20.397317976],
            [-42.994088906999899, -20.397251086],
            [-42.994032789999899, -20.397231034999901],
            [-42.993958172, -20.397232428999899],
            [-42.993917667999902, -20.397259969999901],
            [-42.993832756, -20.397366115],
            [-42.993763916, -20.397551882999899],
            [-42.993685812, -20.3976014949999],
            [-42.993580543999897, -20.397600736],
            [-42.993416816, -20.397573727999902],
            [-42.993370206999899, -20.3975146669999],
            [-42.993403045, -20.397391071999898],
            [-42.993444038, -20.397274449],
            [-42.993378321, -20.397167610999901],
            [-42.993282719, -20.397148978],
            [-42.993095787, -20.397218753999901],
            [-42.992982137, -20.397221382999899],
            [-42.992829695999902, -20.397169645],
            [-42.992655553, -20.3971674709999],
            [-42.992539599999901, -20.397166746],
            [-42.992437073, -20.3970585399999],
            [-42.992361681, -20.397035373999898],
            [-42.9922899379999, -20.397073322],
            [-42.992116634, -20.39723423],
            [-42.991962763999901, -20.397331338999901],
            [-42.991839336999902, -20.3973382729999],
            [-42.991716380999897, -20.397248469],
            [-42.991447051, -20.397091501],
            [-42.991362113, -20.39702126],
            [-42.991270132999901, -20.397009345],
            [-42.991197529, -20.397054533],
            [-42.991166529999902, -20.397202707],
            [-42.99114178, -20.397289751],
            [-42.991062279999902, -20.397299824],
            [-42.99091321, -20.397212630999899],
            [-42.990659143999899, -20.3972073159999],
            [-42.990351382, -20.397329936999899],
            [-42.990246225, -20.3973609089999],
            [-42.990156256, -20.397344437],
            [-42.989996961, -20.3972348889999],
            [-42.989794546, -20.397159377],
            [-42.989398541999897, -20.3970355609999],
            [-42.989209592999899, -20.396981104],
            [-42.989068239999902, -20.3968867799999],
            [-42.988825541, -20.3968013329999],
            [-42.9885885099999, -20.396717881],
            [-42.98837692, -20.39675884],
            [-42.988096726, -20.396828373999899],
            [-42.987762107, -20.396810053],
            [-42.9876262719999, -20.396801259],
            [-42.987510437, -20.396681101],
            [-42.987242202999902, -20.396612123],
            [-42.986950180999898, -20.396669147],
            [-42.986721917, -20.396788178],
            [-42.9862778419999, -20.397183711],
            [-42.985859495999897, -20.397657414],
            [-42.985719894999903, -20.397718992],
            [-42.9851855369999, -20.397748189],
            [-42.984723701, -20.3978211149999],
            [-42.984453808999902, -20.3979281159999],
            [-42.98430344, -20.39792677],
            [-42.984174292, -20.397943991],
            [-42.98407055, -20.3980080229999],
            [-42.983940679999897, -20.398157727999902],
            [-42.983887995, -20.398342482],
            [-42.983833005, -20.398371175],
            [-42.983692903, -20.398233635999901],
            [-42.983645899, -20.398099347],
            [-42.983531453999902, -20.397819943999899],
            [-42.983450859, -20.397743114],
            [-42.983310828999898, -20.397727209],
            [-42.983252826999902, -20.397816723999899],
            [-42.983216964, -20.397970009999899],
            [-42.983144991, -20.398048311],
            [-42.982973886, -20.397984927],
            [-42.982833058, -20.397810885999899],
            [-42.982765806, -20.397603361999899],
            [-42.982705361999898, -20.397491761],
            [-42.982626631999899, -20.397484968],
            [-42.982558183, -20.397536807999899],
            [-42.982507492, -20.397655296],
            [-42.982379705, -20.397696476],
            [-42.982291796, -20.397660045999899],
            [-42.982286266, -20.397550324999902],
            [-42.9823349219999, -20.397419246],
            [-42.982304282, -20.397312628999899],
            [-42.982179034, -20.3972934],
            [-42.981895773, -20.397249164],
            [-42.981771491, -20.397219056],
            [-42.981614895, -20.397231116999901],
            [-42.981430834, -20.39704625],
            [-42.981136170999903, -20.396868783999899],
            [-42.98091919, -20.396829346999901],
            [-42.980687101999898, -20.396734255999899],
            [-42.9804394129999, -20.396646223999898],
            [-42.980332623, -20.396705595],
            [-42.980206922, -20.396693925],
            [-42.980068646999896, -20.3966596889999],
            [-42.97973446, -20.3965809339999],
            [-42.979522927999902, -20.396607221],
            [-42.979403465, -20.396682609],
            [-42.979363947, -20.396776967999902],
            [-42.9793615359999, -20.396870847999899],
            [-42.979312501, -20.397015557],
            [-42.979238687, -20.3971486639999],
            [-42.979222917999898, -20.39738912],
            [-42.979282198, -20.397646858],
            [-42.979419024, -20.397816475],
            [-42.979519629, -20.398046001],
            [-42.979528865, -20.3982928729999],
            [-42.979584659, -20.398513873999899],
            [-42.979561444999902, -20.398638648],
            [-42.979538195, -20.398732187],
            [-42.97947163, -20.398762206999901],
            [-42.979394441999901, -20.398716081],
            [-42.979387245999902, -20.39859688],
            [-42.979311895, -20.398512658999898],
            [-42.979201139999901, -20.398437416],
            [-42.9791181639999, -20.3984491329999],
            [-42.979061797, -20.398436017999899],
            [-42.979009991999902, -20.398431703],
            [-42.9789801169999, -20.398341353],
            [-42.978994315, -20.398230200999901],
            [-42.978946317999899, -20.398147577],
            [-42.978850055, -20.39798711],
            [-42.978715531, -20.397849005],
            [-42.978550040999899, -20.397774447],
            [-42.978374168999899, -20.397665631999899],
            [-42.978224333, -20.397754721999899],
            [-42.978012927999899, -20.397868951],
            [-42.977555607999903, -20.3976189649999],
            [-42.977421058999902, -20.397401891],
            [-42.977357796, -20.397308903],
            [-42.977286874999898, -20.397302051999901],
            [-42.977233141999903, -20.397336767],
            [-42.977197632999903, -20.397570051999899],
            [-42.977138298999897, -20.397719942],
            [-42.977038243, -20.39780286],
            [-42.976925577, -20.397856265],
            [-42.976756033999898, -20.397833539],
            [-42.976536589, -20.397755307999901],
            [-42.976441146999903, -20.397738056],
            [-42.976341406, -20.397756571999899],
            [-42.976256091, -20.397854154],
            [-42.976231288, -20.397913382],
            [-42.976186837999897, -20.397925508999901],
            [-42.976151359, -20.397890027],
            [-42.976126959999903, -20.397850579],
            [-42.976006942, -20.397819983],
            [-42.975809344, -20.397854756],
            [-42.97559566, -20.397795881],
            [-42.975261278999902, -20.397676356],
            [-42.974993759, -20.397610178],
            [-42.974779925999897, -20.397640753],
            [-42.974506072999901, -20.397759165],
            [-42.974168189, -20.397864236],
            [-42.973848561, -20.397969439],
            [-42.9736251859999, -20.398005016],
            [-42.973524298, -20.3980736789999],
            [-42.973395486999898, -20.398328384],
            [-42.973433078, -20.398442537999902],
            [-42.973482529, -20.3985894539999],
            [-42.973555672, -20.398842405],
            [-42.973584506, -20.399030824],
            [-42.973562093999902, -20.399202441999901],
            [-42.973503212, -20.399332407],
            [-42.973521547, -20.399386526999901],
            [-42.973585925, -20.39947275],
            [-42.973770671999901, -20.3997332759999],
            [-42.973900523999902, -20.399826882],
            [-42.973970408999897, -20.399863907999901],
            [-42.974038311, -20.3999345789999],
            [-42.974008303999902, -20.400057642],
            [-42.973937904, -20.400204258],
            [-42.973893162, -20.4004055619999],
            [-42.973968698, -20.400581858],
            [-42.974356418999903, -20.400721652999898],
            [-42.974619377, -20.400829421],
            [-42.97491127, -20.4010814489999],
            [-42.97505518, -20.401355859],
            [-42.975103433999898, -20.4015443709999],
            [-42.975036238, -20.401684007],
            [-42.974805708, -20.401905624999898],
            [-42.974708009, -20.402123888999899],
            [-42.974613577999897, -20.402661028999901],
            [-42.974596247, -20.4030121],
            [-42.974560923999903, -20.403078735],
            [-42.974504969, -20.403108231],
            [-42.973806285, -20.40324351],
            [-42.973416492999903, -20.403327705],
            [-42.973093780999903, -20.403492545999899],
            [-42.97249956, -20.403989234999901],
            [-42.97233031, -20.404043892999901],
            [-42.972007475999902, -20.404005355],
            [-42.971841804999897, -20.403894677],
            [-42.971480368999899, -20.403570735999899],
            [-42.971111576999903, -20.402994113],
            [-42.970917497999899, -20.402777665],
            [-42.970673269, -20.402652449999898],
            [-42.970459459, -20.402581542999901],
            [-42.970362932999898, -20.402573162],
            [-42.970287464, -20.402634205],
            [-42.97019796, -20.402948084999899],
            [-42.970040163, -20.403332083999899],
            [-42.969898167999901, -20.403552535],
            [-42.969752238, -20.403686345],
            [-42.969689543999898, -20.40369421],
            [-42.96961221, -20.403637565999901],
            [-42.969407912, -20.403491457],
            [-42.969085423, -20.4033430379999],
            [-42.968998504, -20.403315704],
            [-42.968833471, -20.403346056],
            [-42.968758033, -20.403336456],
            [-42.968631183, -20.403251606],
            [-42.968538122, -20.403242634],
            [-42.968487549, -20.403287793999901],
            [-42.9683678009999, -20.403378014999898],
            [-42.968137783, -20.403557572999901],
            [-42.968055245999899, -20.403632667999901],
            [-42.968025976, -20.403700033],
            [-42.968002752, -20.403836383999899],
            [-42.967932047, -20.403944171],
            [-42.967782457999903, -20.404151578],
            [-42.967520389, -20.4043643269999],
            [-42.967407306, -20.4045331959999],
            [-42.967347186, -20.404699277],
            [-42.967263223, -20.405102491],
            [-42.967157481999898, -20.405389984],
            [-42.967117984, -20.405604995],
            [-42.96700541, -20.405646719999901],
            [-42.966825163, -20.4056241219999],
            [-42.966681719999897, -20.405483306999901],
            [-42.966603264999897, -20.405434749999898],
            [-42.966545422, -20.405449609999899],
            [-42.966528073999903, -20.405511884],
            [-42.966584505, -20.405680905999901],
            [-42.966595098, -20.405931349],
            [-42.96656458, -20.4060010859999],
            [-42.966489591, -20.40605441],
            [-42.966317333999903, -20.406011491999902],
            [-42.966244042, -20.406019597],
            [-42.966209243, -20.406101706999898],
            [-42.966207905, -20.406162601999899],
            [-42.966276778, -20.406265237],
            [-42.96630089, -20.406331531],
            [-42.96626828, -20.406356784],
            [-42.9661427039999, -20.4063907469999],
            [-42.966061497999902, -20.4064480719999],
            [-42.965996227999902, -20.406554668],
            [-42.965984498999902, -20.4067406899999],
            [-42.965956414999901, -20.406795213999899],
            [-42.96582383, -20.406916162999899],
            [-42.965708868, -20.407093017],
            [-42.965639114999902, -20.4072457239999],
            [-42.965529375, -20.407360046999901],
            [-42.965412063, -20.40741916],
            [-42.965216568, -20.407506226],
            [-42.965047759, -20.407576137],
            [-42.96496516, -20.407675417999901],
            [-42.964540583, -20.407769046999899],
            [-42.9644290909999, -20.407841039999902],
            [-42.964236452, -20.407845419],
            [-42.963934399, -20.407818540999902],
            [-42.963764205, -20.407767783999901],
            [-42.9636001, -20.40780983],
            [-42.963545442999902, -20.407723931],
            [-42.963608892, -20.407584093],
            [-42.963771517999902, -20.407531541],
            [-42.963900676, -20.407460605],
            [-42.964046792, -20.407152793],
            [-42.963980579999898, -20.407025986],
            [-42.963767791, -20.406906159999899],
            [-42.963557521, -20.40693627],
            [-42.963376293, -20.406907793],
            [-42.963073035, -20.406847171],
            [-42.9628317, -20.406920714],
            [-42.962568658999899, -20.407103476],
            [-42.962450835, -20.407273238],
            [-42.962437033999898, -20.407541621999901],
            [-42.962394828999898, -20.4076998859999],
            [-42.962211329, -20.407722952],
            [-42.9620370109999, -20.407706178],
            [-42.96192902, -20.407741503999901],
            [-42.961760565, -20.407762562],
            [-42.961632342, -20.407720116],
            [-42.961595589, -20.4075435519999],
            [-42.961541454, -20.407435791999902],
            [-42.961464193, -20.407382169],
            [-42.961362564999902, -20.407389857999899],
            [-42.961315768, -20.407449517],
            [-42.961271574, -20.407527675],
            [-42.9612034149999, -20.4076577669999],
            [-42.961131155, -20.407699996],
            [-42.961033595999901, -20.407730692],
            [-42.960637495, -20.407780374],
            [-42.959999602, -20.407930599999901],
            [-42.959835026, -20.408039699],
            [-42.959738375, -20.408174766999899],
            [-42.959582966, -20.4082126129999],
            [-42.959398624, -20.408161723],
            [-42.959162695, -20.408179402],
            [-42.958961337999902, -20.408217775],
            [-42.958785028, -20.408351202999899],
            [-42.958713611999897, -20.408430070999898],
            [-42.958711804, -20.408543482],
            [-42.958991421, -20.408742961],
            [-42.959063430999898, -20.408816726999898],
            [-42.959049002, -20.408879971],
            [-42.9589859599999, -20.408952617999901],
            [-42.958854549, -20.4089913729999],
            [-42.958702741, -20.4089620469999],
            [-42.958548855999901, -20.408848185999901],
            [-42.958369709, -20.4089311339999],
            [-42.958181216, -20.409094604],
            [-42.958060605999897, -20.409155255],
            [-42.957856071, -20.409229554],
            [-42.957693158, -20.40934514],
            [-42.957537418999898, -20.4094523109999],
            [-42.957513586999902, -20.409572558],
            [-42.957528989, -20.409693789],
            [-42.957604273999898, -20.409745114],
            [-42.957652380999903, -20.409742448],
            [-42.957727137999903, -20.4097152689999],
            [-42.957775436, -20.409775376],
            [-42.957752121999903, -20.4099176789999],
            [-42.95770039, -20.409998754999901],
            [-42.957587038, -20.410036691],
            [-42.957509963999897, -20.410013584],
            [-42.957432218999898, -20.409906178],
            [-42.957333444, -20.409826322],
            [-42.957194242999897, -20.409864259],
            [-42.957036194, -20.409892485999901],
            [-42.956933292, -20.409916763999899],
            [-42.956899146, -20.410046765999901],
            [-42.956986865, -20.410250646999899],
            [-42.95712746, -20.410370685],
            [-42.957194231, -20.410432909],
            [-42.957171987, -20.410512556999901],
            [-42.9570734979999, -20.410588252],
            [-42.957036466, -20.410565757],
            [-42.956969893, -20.410466632],
            [-42.956887458, -20.410427431],
            [-42.956850979, -20.410465407999901],
            [-42.956833554, -20.4106511969999],
            [-42.956776224, -20.4107562209999],
            [-42.956679752, -20.410831731],
            [-42.956549927, -20.410822368],
            [-42.956434293, -20.410821301999899],
            [-42.956228412, -20.411037313999898],
            [-42.95610471, -20.41110114],
            [-42.955958983999899, -20.411075755999899],
            [-42.955811212999897, -20.410996492],
            [-42.955713601, -20.410861532],
            [-42.955692950999897, -20.410694602],
            [-42.955640985999899, -20.410610081],
            [-42.955539992, -20.41058286],
            [-42.955446240999898, -20.410629859],
            [-42.9553478, -20.4107399449999],
            [-42.955298144, -20.410734938],
            [-42.955211372, -20.410700108999901],
            [-42.955186496, -20.410476355],
            [-42.955127816, -20.410426647],
            [-42.954978059, -20.41046317],
            [-42.954880440999901, -20.410466748],
            [-42.95483244, -20.410398128999901],
            [-42.954728750999898, -20.410175105999901],
            [-42.954700728, -20.409993272999898],
            [-42.954707375999902, -20.409689007999901],
            [-42.95464657, -20.4095514549999],
            [-42.95447644, -20.409481092],
            [-42.954266956, -20.409491784999901],
            [-42.954139026999897, -20.4094307129999],
            [-42.954019279, -20.409327773],
            [-42.953790953999899, -20.409238447],
            [-42.953492389, -20.409259192],
            [-42.953319113, -20.409226359999899],
            [-42.953195127, -20.409150143],
            [-42.953080473999897, -20.409061946999898],
            [-42.952995577, -20.409053388],
            [-42.952761918, -20.409144419],
            [-42.952532548, -20.409166768],
            [-42.952344724999897, -20.409262957],
            [-42.952095944999897, -20.4094253499999],
            [-42.952091206, -20.409506227],
            [-42.952067975, -20.409630778999901],
            [-42.952113622999903, -20.409719907],
            [-42.952079610999903, -20.409799998],
            [-42.951947307, -20.409820086],
            [-42.951780616999898, -20.409878555],
            [-42.951724981, -20.410052016999899],
            [-42.951598292, -20.410294280999899],
            [-42.951509595999902, -20.410425561],
            [-42.951293072999903, -20.410574969],
            [-42.951154295, -20.410589824999899],
            [-42.951013276, -20.41054532],
            [-42.950972857999901, -20.410445098],
            [-42.950991218999903, -20.410336589999901],
            [-42.951096412, -20.410275317999901],
            [-42.951117456, -20.410219976],
            [-42.951079563, -20.410167605],
            [-42.950980215999898, -20.410074655],
            [-42.950868376, -20.410049306],
            [-42.950657073999899, -20.4101216789999],
            [-42.950470383, -20.410128431],
            [-42.9503127, -20.410039186999899],
            [-42.950111336, -20.40982272],
            [-42.94992381, -20.40970028],
            [-42.949520203, -20.409529034999899],
            [-42.949309673999899, -20.409507291999901],
            [-42.949143219999897, -20.409534498],
            [-42.948965873, -20.409599915999902],
            [-42.948716369, -20.409592066],
            [-42.948650536999899, -20.409682625],
            [-42.94862561, -20.4097655469999],
            [-42.948721066, -20.409885971999898],
            [-42.948694767999903, -20.410019723999898],
            [-42.948635329, -20.410141336],
            [-42.94856415, -20.410248264],
            [-42.948425934999896, -20.410436295],
            [-42.94823931, -20.410499178999899],
            [-42.948200014999898, -20.410486670999902],
            [-42.948067237, -20.410491032],
            [-42.947984759, -20.4105696029999],
            [-42.947929466999902, -20.4106398499999],
            [-42.947848584, -20.410695588],
            [-42.947809532, -20.410750623],
            [-42.947813488, -20.410846778],
            [-42.947958679, -20.410991399],
            [-42.948049351, -20.411134827999899],
            [-42.948059816, -20.411200391],
            [-42.947928952, -20.411298135],
            [-42.947853841, -20.411396437],
            [-42.9478417569999, -20.411563090999898],
            [-42.947808071999901, -20.41164256],
            [-42.947632311999897, -20.411665257],
            [-42.947505869999901, -20.411653435999899],
            [-42.947416480999898, -20.411679536999898],
            [-42.94728316, -20.41173156],
            [-42.947136989999898, -20.411759908],
            [-42.9470154589999, -20.411766042],
            [-42.946872555, -20.4117935589999],
            [-42.946786688, -20.411780004],
            [-42.946623974, -20.411785121],
            [-42.946490923999903, -20.4117913649999],
            [-42.946398916, -20.411781564],
            [-42.946355739, -20.411766758999899],
            [-42.946349069, -20.411722472999902],
            [-42.946409189999898, -20.411681722],
            [-42.94652537, -20.411602973],
            [-42.946570957999903, -20.411549758],
            [-42.946671631, -20.411480170999901],
            [-42.946668588, -20.411415055],
            [-42.946617815, -20.411361923999898],
            [-42.946611474999898, -20.411302738],
            [-42.946555346, -20.411259897],
            [-42.946442836, -20.411248378],
            [-42.946360671, -20.411217145],
            [-42.946291313, -20.411198574],
            [-42.946234804, -20.411154452999899],
            [-42.946185976, -20.411085833999898],
            [-42.946120979999897, -20.411030506],
            [-42.946077585999902, -20.410959496],
            [-42.946031041, -20.410951162999901],
            [-42.9459355279999, -20.410929747999901],
            [-42.945851466, -20.410903129],
            [-42.945828648, -20.410829549],
            [-42.9457926279999, -20.410792662999899],
            [-42.945721379, -20.410780384],
            [-42.945675894, -20.410801829],
            [-42.945600729, -20.410845449],
            [-42.945527424, -20.410860533999902],
            [-42.94546493, -20.410868649],
            [-42.945378360999896, -20.410879047],
            [-42.945359722, -20.4108511479999],
            [-42.945358792, -20.410813357999899],
            [-42.945437034, -20.410769273],
            [-42.945483802, -20.410725181],
            [-42.945502034999897, -20.410622716],
            [-42.945424632, -20.410511175999901],
            [-42.945342950999901, -20.410527930999901],
            [-42.945320281, -20.410574966],
            [-42.945331554999903, -20.410624449999901],
            [-42.945295886999901, -20.410724361],
            [-42.945237657999897, -20.410724968999901],
            [-42.945186718999899, -20.410687324],
            [-42.945082194, -20.41068375],
            [-42.945006599, -20.410637094],
            [-42.944897513999898, -20.4105254209999],
            [-42.9448507399999, -20.410445418999899],
            [-42.944754003999897, -20.410383564],
            [-42.944632021, -20.410395293],
            [-42.94455472, -20.410352418999899],
            [-42.944507232, -20.410401329],
            [-42.944432784999897, -20.410462245],
            [-42.944374452, -20.4105470659999],
            [-42.94431695, -20.410605279],
            [-42.944255590999902, -20.4105523869999],
            [-42.944210934, -20.410476999],
            [-42.944205196, -20.410414757999899],
            [-42.94425927, -20.410348556],
            [-42.944177335999903, -20.410296184999901],
            [-42.944102313, -20.410333342999898],
            [-42.944045245, -20.410348929999898],
            [-42.943888291, -20.410457196],
            [-42.94377825, -20.4105672869999],
            [-42.94372192, -20.410676368999901],
            [-42.94369054, -20.410786921],
            [-42.943615789, -20.410791644],
            [-42.943584205, -20.410746811],
            [-42.943565124, -20.410647571],
            [-42.943534031, -20.410538299999899],
            [-42.943553006999899, -20.410440061],
            [-42.943529349, -20.410364457999901],
            [-42.943484519, -20.410334706],
            [-42.94343636, -20.410345506999899],
            [-42.943407422999897, -20.410402486],
            [-42.943343205, -20.410451927999901],
            [-42.943261873, -20.4104972119999],
            [-42.943222703999901, -20.410550369],
            [-42.943256607, -20.410630506],
            [-42.943205615999901, -20.410673233],
            [-42.943132939999899, -20.410668513],
            [-42.94303789, -20.4106002259999],
            [-42.943026324999899, -20.410495808],
            [-42.943074387, -20.410415553999901],
            [-42.943159688, -20.410436789],
            [-42.943188494, -20.410390612999901],
            [-42.943166301, -20.410327106],
            [-42.943115711, -20.410294055],
            [-42.943031845, -20.410309218999899],
            [-42.9429477199999, -20.410302389],
            [-42.942887923999898, -20.410356435],
            [-42.942894527, -20.41046704],
            [-42.942929917, -20.410591576999899],
            [-42.9429767889999, -20.41071247],
            [-42.943010214, -20.4108085629999],
            [-42.942943713, -20.410856599999899],
            [-42.942948741, -20.4109570859999],
            [-42.942957276, -20.411067789999901],
            [-42.942914951, -20.4111113189999],
            [-42.9428508089999, -20.411127114],
            [-42.942820527, -20.411215888999902],
            [-42.94276896, -20.411277321999901],
            [-42.942689061, -20.411246417],
            [-42.942600445, -20.4111980559999],
            [-42.942527455999901, -20.4111880409999],
            [-42.942465758, -20.411245412],
            [-42.942438584999898, -20.411336121],
            [-42.942498497, -20.41137642],
            [-42.942525259, -20.411440077],
            [-42.942587878, -20.411477787],
            [-42.94259592, -20.411527486999901],
            [-42.942494336, -20.411589992],
            [-42.942419908, -20.411666864],
            [-42.942319945, -20.411715474999902],
            [-42.942290398999901, -20.411703975],
            [-42.942266768, -20.4116665449999],
            [-42.942230899, -20.411664926],
            [-42.942182557, -20.411698203],
            [-42.94210257, -20.411697859],
            [-42.942024342, -20.411669291],
            [-42.941989145, -20.411713258],
            [-42.941913815, -20.411739858],
            [-42.941844782, -20.411766538999899],
            [-42.941813022, -20.411817358],
            [-42.941754792, -20.411873817],
            [-42.94171124, -20.411880271],
            [-42.941697229999903, -20.411913775],
            [-42.94171891, -20.411959738999901],
            [-42.941753278, -20.412048334],
            [-42.941714363, -20.412095504],
            [-42.941740406999898, -20.412157088999901],
            [-42.941832458, -20.412237884],
            [-42.941814478, -20.412294559],
            [-42.941797498, -20.412373101999901],
            [-42.941811395999899, -20.412497811],
            [-42.941885946999903, -20.4125739209999],
            [-42.941883844, -20.412621778999899],
            [-42.941845337, -20.412665510999901],
            [-42.941762320999899, -20.412643108999902],
            [-42.941727944, -20.412679859],
            [-42.941655065, -20.412721872],
            [-42.941633362999902, -20.412773022],
            [-42.9416367319999, -20.412807378],
            [-42.941656639, -20.412912317],
            [-42.941642611, -20.412977859],
            [-42.941580759, -20.4130093489999],
            [-42.941454875, -20.413026036],
            [-42.941371932, -20.41304072],
            [-42.941365963, -20.41308055],
            [-42.941427901999901, -20.4131243419999],
            [-42.941505321, -20.413187772999901],
            [-42.941602088, -20.413245337],
            [-42.941613087999897, -20.413302474],
            [-42.941557095, -20.413346578999899],
            [-42.941433542, -20.413342242999899],
            [-42.941364221999898, -20.4133741919999],
            [-42.941263525, -20.413392696],
            [-42.941164023999903, -20.413463484],
            [-42.941090164999899, -20.413470993],
            [-42.9409795729999, -20.4134556069999],
            [-42.940940199, -20.413473441999901],
            [-42.940934587, -20.413520159],
            [-42.940996579999897, -20.413589881],
            [-42.941051734, -20.413662495],
            [-42.941065648999903, -20.413742053],
            [-42.941060037999897, -20.41380486],
            [-42.941048866, -20.413867746999902],
            [-42.941004173, -20.413879684999898],
            [-42.940959350999897, -20.413863239],
            [-42.9408838969999, -20.413823598],
            [-42.940811136, -20.413785483],
            [-42.94076639, -20.413784662],
            [-42.940726997999903, -20.4138091999999],
            [-42.940712877, -20.413886089],
            [-42.940676423, -20.413927273999899],
            [-42.940662652, -20.413996481],
            [-42.940692145, -20.414062301999898],
            [-42.940740669, -20.414148546],
            [-42.940815008, -20.414194990999899],
            [-42.940760562, -20.414241655],
            [-42.940697703999902, -20.414244337],
            [-42.940643371, -20.414240198999899],
            [-42.940594872, -20.414211555],
            [-42.940567122999902, -20.414158663],
            [-42.940570196, -20.414070938],
            [-42.940553931, -20.413987151],
            [-42.940500754, -20.413952966999901],
            [-42.940439534999904, -20.413954959],
            [-42.940404271, -20.414017991],
            [-42.940393562999901, -20.414108772],
            [-42.940423107, -20.414224444999899],
            [-42.940454245999902, -20.414292447],
            [-42.940480901999898, -20.414368609],
            [-42.940452248, -20.414466282],
            [-42.940438926999903, -20.414562141],
            [-42.940468371, -20.414609237],
            [-42.940512474999899, -20.414663386],
            [-42.940530027, -20.414716821],
            [-42.940509861, -20.4147594189999],
            [-42.940434432, -20.414853754],
            [-42.940449210999901, -20.414919524],
            [-42.940491703, -20.414984819],
            [-42.94055029, -20.41503359],
            [-42.940568906, -20.415133155],
            [-42.9405512599999, -20.415221104],
            [-42.940618441, -20.415311083999899],
            [-42.940639286, -20.4153871349999],
            [-42.940742156999903, -20.415422291],
            [-42.940791045, -20.41537779],
            [-42.940846463999897, -20.41538365],
            [-42.940887667, -20.415441599],
            [-42.940875800999898, -20.415528739],
            [-42.940832666999903, -20.415601291],
            [-42.940802849, -20.415656299]
          ],
          [
            [-42.995635751999899, -20.40612576],
            [-42.995891410999903, -20.405512152],
            [-42.997090949, -20.404207547999899],
            [-42.997698052, -20.404502181999899],
            [-42.997989716999903, -20.404663292999899],
            [-42.998175827999901, -20.404746626],
            [-43.000339677999897, -20.4051611259999],
            [-43.000673309, -20.405081066],
            [-43.001517491, -20.404938294],
            [-43.001898046, -20.405148906],
            [-43.002325821999897, -20.405324403999899],
            [-43.002878422, -20.405447188],
            [-43.003038586999899, -20.405452754],
            [-43.0030933789999, -20.405456209],
            [-43.003138029, -20.405458654],
            [-43.003222143, -20.40546753],
            [-43.003344458999898, -20.405476618],
            [-43.003736305, -20.405422299999898],
            [-43.003915332, -20.405390815],
            [-43.004130537, -20.405373869999899],
            [-43.004301810999898, -20.405374577],
            [-43.004550504, -20.405416669],
            [-43.0047300969999, -20.4054497989999],
            [-43.004859805, -20.405565989],
            [-43.005097957, -20.405746341999901],
            [-43.005415909, -20.4060150829999],
            [-43.005641727, -20.406235976999898],
            [-43.0059595, -20.406524916],
            [-43.006205479, -20.406775444999901],
            [-43.006334586, -20.407030157999898],
            [-43.006358938999902, -20.407286894],
            [-43.006341984, -20.407592563],
            [-43.00629946, -20.407864692],
            [-43.00620742, -20.408165546],
            [-43.006133323, -20.40831156],
            [-43.006047108, -20.408383172999901],
            [-43.00583356, -20.408512146],
            [-43.005938614999899, -20.4086291039999],
            [-43.006304919999899, -20.408998752999899],
            [-43.006498684, -20.409140212999901],
            [-43.006928984, -20.409402069999899],
            [-43.006542625, -20.409570062999901],
            [-43.006171122999902, -20.40972392],
            [-43.005795814, -20.409900100999899],
            [-43.005608783999897, -20.409978198],
            [-43.005439270999901, -20.410080697],
            [-43.004838978, -20.410613007999899],
            [-43.0042542099999, -20.411128345],
            [-43.003772386, -20.411586139999901],
            [-43.003558579, -20.411825633],
            [-43.003422805999897, -20.412093199],
            [-43.003364761999897, -20.412267218999901],
            [-43.003306365999897, -20.4125451419999],
            [-43.003185615999897, -20.413203022999902],
            [-43.003014228, -20.413207534],
            [-43.002768838999899, -20.413160713],
            [-43.00263682, -20.413042752],
            [-43.002493059, -20.412830728],
            [-43.002256873, -20.412451824],
            [-43.002146717999899, -20.41238512],
            [-43.002090506999899, -20.412372370999901],
            [-43.0019243099999, -20.412302953999902],
            [-43.001782471, -20.412250883],
            [-43.001241335, -20.412176216],
            [-43.001029139, -20.412099465],
            [-43.000943634999899, -20.412062358],
            [-43.000816297, -20.412024498999902],
            [-43.000325822, -20.411932726],
            [-42.999303602999902, -20.411879949],
            [-42.998589820999896, -20.412077826],
            [-42.998440824999903, -20.4120995389999],
            [-42.997696966, -20.4120413599999],
            [-42.996943284999901, -20.412075522],
            [-42.996700282999903, -20.4120639389999],
            [-42.996283349, -20.412097667],
            [-42.9959393789999, -20.411904790999898],
            [-42.99544384, -20.411659042999901],
            [-42.995407257999901, -20.411640098],
            [-42.995370647, -20.411639702999899],
            [-42.995263891, -20.411627907],
            [-42.995062805, -20.411648028999899],
            [-42.995077017, -20.411567807],
            [-42.995080689, -20.411220333],
            [-42.995045123, -20.410883596],
            [-42.994971031, -20.410585888],
            [-42.994993332, -20.410290846999899],
            [-42.994954833, -20.409855267999902],
            [-42.994682167999898, -20.409541805999901],
            [-42.994551831, -20.4092808199999],
            [-42.994542239999902, -20.408947731],
            [-42.994902910999897, -20.407711322999901],
            [-42.995423327, -20.406690644],
            [-42.995635751999899, -20.40612576]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 30,
        "id": 36941,
        "CD_SETOR": "315210505060027P",
        "AREA_KM2": 57.351214,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 116
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.983151, -20.344296996],
            [-42.982555080999902, -20.344601253999901],
            [-42.981758554, -20.344840376],
            [-42.981192301, -20.345065045],
            [-42.9807051989999, -20.345221529],
            [-42.980461198999897, -20.345408194999901],
            [-42.980193199, -20.345718177],
            [-42.979951715, -20.346146389999902],
            [-42.979830999999898, -20.3465149959999],
            [-42.979442773999899, -20.348116655],
            [-42.979301478, -20.348784032],
            [-42.9792182529999, -20.349427153],
            [-42.979292694, -20.3504093949999],
            [-42.979292889, -20.351735201999901],
            [-42.979355679, -20.352013312999901],
            [-42.979509768999897, -20.3524522],
            [-42.979793878, -20.3529083939999],
            [-42.980456091999898, -20.353741910999901],
            [-42.980464113, -20.353927574],
            [-42.980280445999902, -20.354893784],
            [-42.980054666999898, -20.355769344],
            [-42.979684634, -20.356955222],
            [-42.979646607999904, -20.357609512],
            [-42.979759693999902, -20.358165253],
            [-42.979905075, -20.358539648],
            [-42.980327042999903, -20.358993034],
            [-42.980641268999896, -20.3592155169999],
            [-42.981128203, -20.35933362],
            [-42.982168714999901, -20.359426087],
            [-42.982661188, -20.359448782999898],
            [-42.983596, -20.359332735999899],
            [-42.984097774, -20.359268818],
            [-42.984977064, -20.3590889879999],
            [-42.985969511, -20.358924809],
            [-42.986613403, -20.358904720999899],
            [-42.987380608, -20.359020245],
            [-42.987781398, -20.359281772],
            [-42.988040919, -20.35956469],
            [-42.988076919, -20.35978369],
            [-42.987842451999903, -20.360339913],
            [-42.986997651, -20.361388954999899],
            [-42.985706832999902, -20.362645866],
            [-42.985335, -20.362984996],
            [-42.985152, -20.363144996],
            [-42.984932, -20.363351995999899],
            [-42.984737, -20.363512995999901],
            [-42.984482027, -20.363560692],
            [-42.983320789999901, -20.3636118229999],
            [-42.982723123, -20.363707561999899],
            [-42.982501758, -20.363836868],
            [-42.982313054, -20.364103214999901],
            [-42.982288618, -20.364481344],
            [-42.982371999999899, -20.364739996],
            [-42.982371999999899, -20.364969996],
            [-42.98236, -20.365233996],
            [-42.982335, -20.365509996],
            [-42.982298, -20.3657859959999],
            [-42.982152, -20.366003996],
            [-42.982006, -20.366278995999899],
            [-42.981981, -20.366508995999901],
            [-42.98198, -20.367221996],
            [-42.981991999999899, -20.367485996],
            [-42.982041, -20.367749995999901],
            [-42.982149999999898, -20.3679459959999],
            [-42.982284, -20.368129995999901],
            [-42.982491, -20.368313995999898],
            [-42.982674, -20.368451996],
            [-42.982844, -20.368600995999898],
            [-42.983088, -20.3687619959999],
            [-42.983209, -20.368968996],
            [-42.983342999999898, -20.369152996],
            [-42.98344, -20.369428996],
            [-42.98355, -20.369623996],
            [-42.983609999999899, -20.3698429959999],
            [-42.983683, -20.370117996],
            [-42.983683, -20.370657996],
            [-42.983657999999899, -20.370887996],
            [-42.983584999999898, -20.371106996],
            [-42.982858344, -20.371672947],
            [-42.982018785, -20.372095762],
            [-42.981187602, -20.372512861],
            [-42.980088623, -20.373014028999901],
            [-42.979332235999898, -20.3733325389999],
            [-42.978473946, -20.373449454],
            [-42.975941667, -20.373543589999901],
            [-42.975707948, -20.373556221999898],
            [-42.975539237, -20.373655368999898],
            [-42.975427319999902, -20.373876174],
            [-42.975427328, -20.374105437],
            [-42.975740602, -20.374794010999899],
            [-42.976496117999901, -20.377188111],
            [-42.976609795999899, -20.377852339],
            [-42.976484893, -20.3790269859999],
            [-42.976052914, -20.381088218999899],
            [-42.975762559, -20.381350301999898],
            [-42.975477473, -20.381520483],
            [-42.97436701, -20.381616219],
            [-42.97309279, -20.38175654],
            [-42.971544687999902, -20.38197625],
            [-42.970506015999902, -20.382155929],
            [-42.970176450999901, -20.382252177999899],
            [-42.969954366, -20.382423141999901],
            [-42.969792, -20.383066996],
            [-42.969452644999897, -20.38368946],
            [-42.969224703999899, -20.384079533],
            [-42.969014339, -20.384292773],
            [-42.968565575, -20.384406978999898],
            [-42.968139037999897, -20.384439062],
            [-42.967472328, -20.384520086],
            [-42.967109639999897, -20.38455378],
            [-42.966636080999898, -20.384735168],
            [-42.966398785, -20.384834737999899],
            [-42.966080167, -20.385075226],
            [-42.965390177, -20.385455249],
            [-42.964918597, -20.385791600999902],
            [-42.964495241999899, -20.3858754349999],
            [-42.964148565, -20.385948681999899],
            [-42.963783591, -20.385890682],
            [-42.96358286, -20.385734823999901],
            [-42.963271, -20.385105995999901],
            [-42.963044113, -20.38501722],
            [-42.962778887, -20.3849875669999],
            [-42.962539999999898, -20.385001996],
            [-42.962164801, -20.385047202],
            [-42.96172215, -20.385104408],
            [-42.961319714999902, -20.385026837999899],
            [-42.961016898, -20.384950672999899],
            [-42.960723844, -20.384996185],
            [-42.960511268999902, -20.385163937],
            [-42.960446902999898, -20.385509655],
            [-42.960335414, -20.385955709],
            [-42.960281865999903, -20.386418853999899],
            [-42.960188979, -20.386879387],
            [-42.960020753, -20.387047346],
            [-42.959729371, -20.3872174279999],
            [-42.959063844, -20.387441285],
            [-42.958709327999898, -20.387607018],
            [-42.958455694, -20.387885651],
            [-42.958071269, -20.388359997999899],
            [-42.957815296, -20.388740834],
            [-42.957577, -20.389304422999899],
            [-42.957471806999898, -20.3897691569999],
            [-42.957349807, -20.390166522999898],
            [-42.957244918999898, -20.390382909],
            [-42.957034399999898, -20.390492080999898],
            [-42.956650695999897, -20.390538052999901],
            [-42.956274314, -20.390542442],
            [-42.955785029, -20.390508484],
            [-42.955493133999902, -20.390529423],
            [-42.955172612999903, -20.390623299],
            [-42.955057062, -20.390633992999899],
            [-42.954841583, -20.390579218],
            [-42.954610583, -20.390522217999901],
            [-42.954449723, -20.390412151],
            [-42.954338062, -20.390232139],
            [-42.954213774, -20.390085057],
            [-42.953940504999899, -20.38994171],
            [-42.953721505, -20.3899018729999],
            [-42.953448392, -20.389930483999901],
            [-42.953277366, -20.3899031989999],
            [-42.952797253, -20.389621894],
            [-42.952561139999901, -20.389440363999899],
            [-42.952420921999902, -20.389275078],
            [-42.952288591, -20.389189996],
            [-42.952082774, -20.3891746689999],
            [-42.951863773999897, -20.389093669],
            [-42.95117543, -20.388894526999898],
            [-42.950847661, -20.388870466],
            [-42.950580843999902, -20.388944016],
            [-42.950377852, -20.388966771],
            [-42.950172008, -20.388914363],
            [-42.949945121, -20.388866241],
            [-42.949854546, -20.388882587999898],
            [-42.949746858, -20.3889510979999],
            [-42.949578683, -20.389071097999899],
            [-42.949501789, -20.389109006999899],
            [-42.949346518, -20.389122056],
            [-42.94914518, -20.3891593829999],
            [-42.949013005, -20.389158526],
            [-42.948858532, -20.389093298],
            [-42.948603036, -20.388928876],
            [-42.948450833, -20.388708355999899],
            [-42.948316513, -20.388482403999902],
            [-42.948195078, -20.388318731],
            [-42.948031965, -20.388226935],
            [-42.947842078, -20.388205567999901],
            [-42.9475758519999, -20.388334915],
            [-42.947353486999901, -20.3883436909999],
            [-42.946998354999899, -20.388217037999901],
            [-42.946555703999898, -20.388044629],
            [-42.946062, -20.387857996],
            [-42.945758, -20.387742995999901],
            [-42.9453816179999, -20.387561465],
            [-42.945183868, -20.387406363],
            [-42.944930642, -20.387072748],
            [-42.94481332, -20.3869392779999],
            [-42.944633659, -20.386911993],
            [-42.944278424999901, -20.386964463],
            [-42.943978382, -20.3870433],
            [-42.9437885729999, -20.387026503999898],
            [-42.943679024999902, -20.387007986],
            [-42.943587266, -20.387032181999899],
            [-42.943519185999897, -20.387174199],
            [-42.943406694, -20.387356751],
            [-42.943270406, -20.387469139],
            [-42.942727522, -20.387684119],
            [-42.942468077999898, -20.387769466],
            [-42.942097323, -20.387854506999901],
            [-42.941931, -20.3878529959999],
            [-42.941383, -20.3878529959999],
            [-42.941127, -20.387817996],
            [-42.940941634, -20.387744199999901],
            [-42.94080946, -20.387668037],
            [-42.94064632, -20.387547179],
            [-42.940459473, -20.387493761],
            [-42.940094288, -20.387368872],
            [-42.939952374, -20.387269096],
            [-42.939841164, -20.387074707999901],
            [-42.93977618, -20.386874154999902],
            [-42.939678667, -20.3867307679999],
            [-42.939468172, -20.386541176],
            [-42.9392184169999, -20.386454178],
            [-42.939058, -20.386367995999901],
            [-42.938838, -20.386229995999901],
            [-42.938619591, -20.386133711],
            [-42.938425105, -20.385966058],
            [-42.938315, -20.385666995999902],
            [-42.938145, -20.385229996],
            [-42.93806, -20.384976996],
            [-42.938012, -20.384746995999901],
            [-42.937988, -20.384528996],
            [-42.937939, -20.384286996],
            [-42.937927, -20.384045995999902],
            [-42.937915, -20.383746996],
            [-42.937999972999897, -20.3835289959999],
            [-42.938035277, -20.383165318],
            [-42.938092293, -20.3828307429999],
            [-42.938150492, -20.38235508],
            [-42.938184126, -20.382014093999899],
            [-42.938179422, -20.3817153369999],
            [-42.938060145, -20.381430496],
            [-42.937962066999901, -20.3811881899999],
            [-42.9378011639999, -20.380800751],
            [-42.937729347, -20.380668306],
            [-42.937638642, -20.38057329],
            [-42.93748393, -20.38031388],
            [-42.937432, -20.380241995999899],
            [-42.937392583, -20.3800304059999],
            [-42.93734461, -20.379782996],
            [-42.937277236999897, -20.379563385],
            [-42.937245323, -20.379348364],
            [-42.937239, -20.379138995999899],
            [-42.937239565, -20.37852636],
            [-42.937287459999901, -20.378234318],
            [-42.937256008, -20.377961748],
            [-42.937231417, -20.377702031999899],
            [-42.937115121, -20.377505726],
            [-42.936979424999898, -20.377388873],
            [-42.936768980999901, -20.377317714],
            [-42.936504965, -20.377189555],
            [-42.936429086, -20.377133344],
            [-42.936415087999897, -20.377038414],
            [-42.936424538, -20.376918837999899],
            [-42.936466740999897, -20.376583058999898],
            [-42.936435893, -20.376381302],
            [-42.936347527, -20.376206386],
            [-42.936233492, -20.376074554],
            [-42.936188473, -20.3759351859999],
            [-42.936157201999897, -20.3757324699999],
            [-42.936079627, -20.375568585999901],
            [-42.935921191, -20.375440764],
            [-42.935788912, -20.3754068279999],
            [-42.935655884, -20.375411433],
            [-42.935542650999899, -20.3753600699999],
            [-42.935401691, -20.375295547999901],
            [-42.935259527, -20.375129725],
            [-42.935133488, -20.375033987999899],
            [-42.935046676, -20.37500283],
            [-42.935022416, -20.374914078],
            [-42.934993638, -20.374832301],
            [-42.934940377, -20.374844456999899],
            [-42.934875139, -20.374956380999901],
            [-42.934782093, -20.37513179],
            [-42.934623878, -20.375303642999899],
            [-42.934395858, -20.37538598],
            [-42.934159303999898, -20.375443134],
            [-42.93393586, -20.375467472],
            [-42.933800651, -20.375430314],
            [-42.933713608999902, -20.375356452999899],
            [-42.933704748, -20.375138018],
            [-42.933656566, -20.374955397999901],
            [-42.933510138, -20.374824432],
            [-42.933236, -20.374710996],
            [-42.933073478, -20.374656092999899],
            [-42.93302755, -20.374561726],
            [-42.933056416999896, -20.374414092999899],
            [-42.933083212, -20.374220208999901],
            [-42.933158813, -20.374179046999899],
            [-42.933167284, -20.3741366419999],
            [-42.933126383999898, -20.374095869],
            [-42.933068467999902, -20.373997174],
            [-42.933049057999902, -20.373893934],
            [-42.933078075, -20.3737754369999],
            [-42.933056001, -20.373722137999899],
            [-42.932870395999899, -20.373723104],
            [-42.932771277, -20.373702944999899],
            [-42.932723913, -20.373642603999901],
            [-42.932718496999897, -20.373512488],
            [-42.932711169999898, -20.373439905999899],
            [-42.93266848, -20.373399489],
            [-42.932557577, -20.373306394999901],
            [-42.932422104999901, -20.373160877],
            [-42.932241878999903, -20.373075564],
            [-42.932130054, -20.3728468689999],
            [-42.932000011, -20.372563393999901],
            [-42.931917271, -20.3723029189999],
            [-42.931860201999903, -20.372253123999901],
            [-42.931701045999901, -20.3721022729999],
            [-42.931603531999897, -20.371944705],
            [-42.931551062, -20.371748705999899],
            [-42.931421739, -20.371525145],
            [-42.93128669, -20.371365343],
            [-42.931225099999899, -20.371194064],
            [-42.931113731, -20.371048882],
            [-42.930989444, -20.370997052],
            [-42.930749886999898, -20.370992741],
            [-42.930573183, -20.370996146],
            [-42.930502879999899, -20.370945533999901],
            [-42.930504081, -20.3708219009999],
            [-42.930464747, -20.370717804],
            [-42.930438059, -20.370639006],
            [-42.930355978, -20.370532397],
            [-42.930286269, -20.370360553999902],
            [-42.930243102, -20.370242120999901],
            [-42.930144970999898, -20.3701803189999],
            [-42.930046429999898, -20.370181993],
            [-42.929932446, -20.370293987],
            [-42.929705559, -20.370414584999899],
            [-42.929544979, -20.370476107999899],
            [-42.929444395, -20.370458407999902],
            [-42.929212914999901, -20.370418055],
            [-42.928720408999901, -20.370117767],
            [-42.928308645, -20.369875793999899],
            [-42.928007589999901, -20.369628064],
            [-42.9278883489999, -20.369518515],
            [-42.927809384999897, -20.36947536],
            [-42.927658784999899, -20.369454216],
            [-42.927451661, -20.369417959],
            [-42.927315964999899, -20.369424584999901],
            [-42.9271676769999, -20.369412558],
            [-42.927090155, -20.369382139],
            [-42.926911034999897, -20.369231231999901],
            [-42.926784724999898, -20.369163333],
            [-42.926601922, -20.369151841],
            [-42.926428624, -20.369115807],
            [-42.926337956999902, -20.369050995999899],
            [-42.926331048999899, -20.368979238],
            [-42.926334510999901, -20.368925261],
            [-42.926318016, -20.368837155999898],
            [-42.926263849999899, -20.368806349],
            [-42.926053996999897, -20.368693477],
            [-42.925956047999897, -20.368616215],
            [-42.925864804, -20.368504433999899],
            [-42.925767933, -20.368425893999898],
            [-42.925661428, -20.368374267],
            [-42.925552583999902, -20.368357795],
            [-42.925019796, -20.368315371999898],
            [-42.924773562, -20.368288140999901],
            [-42.924636118, -20.368329124999899],
            [-42.9244770399999, -20.368562559999901],
            [-42.924378176999902, -20.368780159],
            [-42.924266702, -20.369112937],
            [-42.924196605, -20.369277317],
            [-42.924216580999897, -20.369444384],
            [-42.924177691, -20.369510189],
            [-42.92409143, -20.369525022999898],
            [-42.92393779, -20.369522321],
            [-42.923841331999903, -20.369456939],
            [-42.923624937999897, -20.369281799],
            [-42.923523495, -20.369208489],
            [-42.923407525999899, -20.36913143],
            [-42.923345810999898, -20.369038469],
            [-42.923328339, -20.368782995999901],
            [-42.923272389, -20.368657136],
            [-42.923178999999898, -20.368563996],
            [-42.922969661, -20.368435297],
            [-42.922937728, -20.36839236],
            [-42.922963852, -20.368204147],
            [-42.922951417, -20.368047541],
            [-42.922759782, -20.367852845999899],
            [-42.922592043, -20.36749909],
            [-42.92248253, -20.3673878029999],
            [-42.922292591, -20.367319537999901],
            [-42.921989159, -20.367255316],
            [-42.921720951999902, -20.367141355999902],
            [-42.921577238999902, -20.366940464],
            [-42.921556548, -20.36678072],
            [-42.921572534999903, -20.366716573],
            [-42.921877709999897, -20.366378687],
            [-42.921971271, -20.366349902],
            [-42.922151984, -20.366358940999898],
            [-42.922226731, -20.366360197999899],
            [-42.922284474, -20.366329024],
            [-42.922290744, -20.366134595999899],
            [-42.922240726, -20.366085843],
            [-42.922170687999902, -20.366077785999899],
            [-42.922096019, -20.365997524],
            [-42.922058256, -20.365859954],
            [-42.922091092999899, -20.365728169],
            [-42.922166135999902, -20.365623263],
            [-42.922186086, -20.365510581],
            [-42.922172197999899, -20.365275305999901],
            [-42.922222432999902, -20.365133436],
            [-42.922291145, -20.364917195],
            [-42.922304298, -20.364828996],
            [-42.922238933, -20.364615226999899],
            [-42.922131561, -20.364799306999899],
            [-42.921991986, -20.364926517999901],
            [-42.921491925999902, -20.365125163999899],
            [-42.920641023, -20.365503298],
            [-42.9200574669999, -20.365551089999901],
            [-42.9194854899999, -20.365666898],
            [-42.919187584, -20.365715895999902],
            [-42.919064909, -20.365789821],
            [-42.918934242, -20.365979839],
            [-42.918779838, -20.366186651],
            [-42.918687936, -20.366217719],
            [-42.918522247999903, -20.365968919999901],
            [-42.918423877999899, -20.365926588],
            [-42.918095169, -20.365887180999898],
            [-42.917729036, -20.365642337999901],
            [-42.917496324999902, -20.365652129],
            [-42.917276713, -20.365864619],
            [-42.917356992999899, -20.366687034999899],
            [-42.917401647, -20.3670494099999],
            [-42.917391458999901, -20.367261914],
            [-42.917225394, -20.367456589],
            [-42.916710441, -20.3675549989999],
            [-42.915576750999897, -20.367698199],
            [-42.914809702999897, -20.367569737999901],
            [-42.914148796999903, -20.367383340999901],
            [-42.913617405, -20.367093312],
            [-42.913120116999899, -20.366919969],
            [-42.912387029999898, -20.366475716],
            [-42.912240139999902, -20.366078085],
            [-42.912311019999898, -20.365454203999899],
            [-42.912747004, -20.364498925],
            [-42.912712095, -20.364051953999901],
            [-42.91243144, -20.363898314],
            [-42.912047545, -20.363776759],
            [-42.911465678, -20.363841573999899],
            [-42.910244498999901, -20.364385631999902],
            [-42.909084686, -20.365130553],
            [-42.908777646, -20.3651457889999],
            [-42.908425434, -20.36508363],
            [-42.907397521999897, -20.364358148],
            [-42.906802834, -20.363933502],
            [-42.905558129, -20.363196907],
            [-42.904629713, -20.362625328],
            [-42.904197279, -20.362143868],
            [-42.903829215, -20.3619502],
            [-42.903203585, -20.361991228],
            [-42.902418697, -20.362562719],
            [-42.900784288, -20.363550177],
            [-42.896993065999901, -20.366445665999901],
            [-42.896554233, -20.367004295],
            [-42.896274574, -20.367753788],
            [-42.896220957, -20.3679551819999],
            [-42.895400591999902, -20.371870031999901],
            [-42.895243031999897, -20.372254332],
            [-42.895246454999899, -20.372517027999901],
            [-42.895407159999898, -20.372740364],
            [-42.896105741, -20.373271074999899],
            [-42.896162304, -20.3733096509999],
            [-42.89647374, -20.373438474],
            [-42.896769009, -20.37367262],
            [-42.897140023, -20.374110073999901],
            [-42.897621788, -20.374788751],
            [-42.897891478, -20.3749939379999],
            [-42.898084761999897, -20.375095729],
            [-42.898569224, -20.374336066],
            [-42.898987281, -20.374688105],
            [-42.899067622, -20.374492642],
            [-42.899272074, -20.373948836],
            [-42.899446567, -20.373738245],
            [-42.899596969999898, -20.373556726999901],
            [-42.900107049, -20.372984922999901],
            [-42.900773218, -20.37258336],
            [-42.901456052, -20.372306341],
            [-42.902146046, -20.3721100829999],
            [-42.903073503, -20.372040319999901],
            [-42.903494088999899, -20.372070895],
            [-42.90392392, -20.372227804],
            [-42.905832298999897, -20.372646253],
            [-42.907527428, -20.372964049],
            [-42.908224753, -20.373157267],
            [-42.908620316999901, -20.373406508],
            [-42.908969082999903, -20.373827837],
            [-42.909254414, -20.37416948],
            [-42.909817933999904, -20.374774359],
            [-42.910401754, -20.375109252],
            [-42.911041655, -20.375382283],
            [-42.911661551, -20.3755746749999],
            [-42.912116872, -20.3756616209999],
            [-42.91302927, -20.375812010999901],
            [-42.913974892999903, -20.375874032999899],
            [-42.914680308, -20.3758043989999],
            [-42.914921176, -20.375779211999902],
            [-42.9150989789999, -20.3765587],
            [-42.915097041, -20.376563951],
            [-42.915095758999897, -20.376567426],
            [-42.914679842999902, -20.37734906],
            [-42.914482756, -20.377684518],
            [-42.913939236, -20.3786949509999],
            [-42.913929634999903, -20.378758632],
            [-42.913870298999903, -20.378916537999899],
            [-42.913709184, -20.3793942789999],
            [-42.913503018, -20.380007937999899],
            [-42.913339337, -20.380461038],
            [-42.913190894, -20.380717913],
            [-42.912852629, -20.381044755999898],
            [-42.912694727, -20.381173186],
            [-42.911744181, -20.3814539749999],
            [-42.911627081999903, -20.381494064],
            [-42.911543573, -20.381631641999899],
            [-42.911342638, -20.381890842],
            [-42.910717459, -20.382737721999899],
            [-42.910175122999902, -20.3836191739999],
            [-42.910195300999902, -20.384147800999902],
            [-42.911106601, -20.385069207999901],
            [-42.91141292, -20.385690167],
            [-42.91178079, -20.386643407],
            [-42.912381353999898, -20.3870431889999],
            [-42.912698303999903, -20.387592011],
            [-42.912798418, -20.388252462],
            [-42.912793251, -20.388769001999901],
            [-42.912887262, -20.389079320999901],
            [-42.913015779, -20.389264195],
            [-42.913230858, -20.38965059],
            [-42.913644252, -20.390035693999899],
            [-42.913973069, -20.390332299],
            [-42.914325124999898, -20.390659524999901],
            [-42.9145856069999, -20.390832646],
            [-42.914861975, -20.391011590999899],
            [-42.915120709999897, -20.3912414179999],
            [-42.915236438, -20.3915541279999],
            [-42.915245397, -20.3920083259999],
            [-42.915280132, -20.392524194],
            [-42.915232269999898, -20.393115446],
            [-42.915110996, -20.393573284],
            [-42.91441563, -20.394092015999899],
            [-42.913643619, -20.394550715],
            [-42.913523066, -20.394841581],
            [-42.913367623999903, -20.395377926],
            [-42.9131911059999, -20.3959250089999],
            [-42.913194843, -20.396453005],
            [-42.913240089999903, -20.396834339],
            [-42.913321881999899, -20.397051452],
            [-42.913458143, -20.397344651],
            [-42.913725272, -20.3978972859999],
            [-42.914023094, -20.398184918],
            [-42.917860316, -20.401857766],
            [-42.920165066, -20.404063216999901],
            [-42.92183024, -20.405677981999901],
            [-42.921927672, -20.405773326],
            [-42.922186193, -20.406016823],
            [-42.922333249, -20.4061504169999],
            [-42.922536421, -20.406281077],
            [-42.9225806379999, -20.406302937],
            [-42.922888551, -20.406389545],
            [-42.923106343, -20.406404849],
            [-42.92335258, -20.406394292999899],
            [-42.923501831, -20.4063633],
            [-42.923747891999902, -20.406312204],
            [-42.9240422519999, -20.406176869],
            [-42.924217978999899, -20.406116293],
            [-42.924412483, -20.406071297],
            [-42.924628065, -20.406055782],
            [-42.924803376999897, -20.406081469],
            [-42.924961116, -20.406121363],
            [-42.925197974, -20.406214372],
            [-42.925410686, -20.40630551],
            [-42.926223529, -20.406643143999901],
            [-42.927956083, -20.407275599999899],
            [-42.929258508, -20.407743791],
            [-42.929732687999902, -20.407919039],
            [-42.930080772999901, -20.408004478],
            [-42.930341283, -20.408021421999901],
            [-42.930507534999897, -20.407998253999899],
            [-42.930846389, -20.407929535999902],
            [-42.9310686009999, -20.407827703999899],
            [-42.931296346, -20.4076827979999],
            [-42.93144411, -20.407553207],
            [-42.931647977, -20.4073323379999],
            [-42.932151818, -20.406644921999899],
            [-42.932601512, -20.406034566],
            [-42.933075003999903, -20.405412654],
            [-42.933281238999903, -20.405150122999899],
            [-42.933547723999901, -20.404896768999901],
            [-42.933782745, -20.404726788999898],
            [-42.93413328, -20.404557963999899],
            [-42.934490431, -20.4044974339999],
            [-42.934910156999898, -20.404480603],
            [-42.935228179, -20.4045181299999],
            [-42.935537766, -20.404622279],
            [-42.935847886, -20.404780944],
            [-42.936068185, -20.404960402],
            [-42.936254289, -20.405211601],
            [-42.936372605, -20.4054144639999],
            [-42.936493098, -20.405732128999901],
            [-42.936546803999903, -20.405995468999901],
            [-42.936580852, -20.4061851129999],
            [-42.936781521999897, -20.407318298],
            [-42.936958321, -20.408215093],
            [-42.937152900999898, -20.408704223],
            [-42.937936968, -20.410321269999901],
            [-42.938111870999897, -20.410635301],
            [-42.938184044, -20.41080728],
            [-42.93825615, -20.410979097999899],
            [-42.938377933, -20.411372997999901],
            [-42.938457067999899, -20.411723039],
            [-42.938515579, -20.412014228],
            [-42.938571774, -20.412248121999902],
            [-42.938605003, -20.412466067],
            [-42.938620111, -20.412565151],
            [-42.938669483, -20.412888973],
            [-42.938832282, -20.4135118149999],
            [-42.938897583, -20.413723564999898],
            [-42.938956436, -20.413848409],
            [-42.939012675999898, -20.413967713],
            [-42.939082235999898, -20.4141239799999],
            [-42.939156485, -20.414279177],
            [-42.939316859, -20.414614392],
            [-42.939965770999898, -20.415934367],
            [-42.940118196, -20.41624589],
            [-42.940390426, -20.416749947],
            [-42.940476343999897, -20.416671732999902],
            [-42.940582375, -20.416442382],
            [-42.940656496, -20.416184692999899],
            [-42.940652306999901, -20.41589055],
            [-42.940691879, -20.41571656],
            [-42.940802849, -20.415656299],
            [-42.940832666999903, -20.415601291],
            [-42.940875800999898, -20.415528739],
            [-42.940887667, -20.415441599],
            [-42.940846463999897, -20.41538365],
            [-42.940791045, -20.41537779],
            [-42.940742156999903, -20.415422291],
            [-42.940639286, -20.4153871349999],
            [-42.940618441, -20.415311083999899],
            [-42.9405512599999, -20.415221104],
            [-42.940568906, -20.415133155],
            [-42.94055029, -20.41503359],
            [-42.940491703, -20.414984819],
            [-42.940449210999901, -20.414919524],
            [-42.940434432, -20.414853754],
            [-42.940509861, -20.4147594189999],
            [-42.940530027, -20.414716821],
            [-42.940512474999899, -20.414663386],
            [-42.940468371, -20.414609237],
            [-42.940438926999903, -20.414562141],
            [-42.940452248, -20.414466282],
            [-42.940480901999898, -20.414368609],
            [-42.940454245999902, -20.414292447],
            [-42.940423107, -20.414224444999899],
            [-42.940393562999901, -20.414108772],
            [-42.940404271, -20.414017991],
            [-42.940439534999904, -20.413954959],
            [-42.940500754, -20.413952966999901],
            [-42.940553931, -20.413987151],
            [-42.940570196, -20.414070938],
            [-42.940567122999902, -20.414158663],
            [-42.940594872, -20.414211555],
            [-42.940643371, -20.414240198999899],
            [-42.940697703999902, -20.414244337],
            [-42.940760562, -20.414241655],
            [-42.940815008, -20.414194990999899],
            [-42.940740669, -20.414148546],
            [-42.940692145, -20.414062301999898],
            [-42.940662652, -20.413996481],
            [-42.940676423, -20.413927273999899],
            [-42.940712877, -20.413886089],
            [-42.940726997999903, -20.4138091999999],
            [-42.94076639, -20.413784662],
            [-42.940811136, -20.413785483],
            [-42.9408838969999, -20.413823598],
            [-42.940959350999897, -20.413863239],
            [-42.941004173, -20.413879684999898],
            [-42.941048866, -20.413867746999902],
            [-42.941060037999897, -20.41380486],
            [-42.941065648999903, -20.413742053],
            [-42.941051734, -20.413662495],
            [-42.940996579999897, -20.413589881],
            [-42.940934587, -20.413520159],
            [-42.940940199, -20.413473441999901],
            [-42.9409795729999, -20.4134556069999],
            [-42.941090164999899, -20.413470993],
            [-42.941164023999903, -20.413463484],
            [-42.941263525, -20.413392696],
            [-42.941364221999898, -20.4133741919999],
            [-42.941433542, -20.413342242999899],
            [-42.941557095, -20.413346578999899],
            [-42.941613087999897, -20.413302474],
            [-42.941602088, -20.413245337],
            [-42.941505321, -20.413187772999901],
            [-42.941427901999901, -20.4131243419999],
            [-42.941365963, -20.41308055],
            [-42.941371932, -20.41304072],
            [-42.941454875, -20.413026036],
            [-42.941580759, -20.4130093489999],
            [-42.941642611, -20.412977859],
            [-42.941656639, -20.412912317],
            [-42.9416367319999, -20.412807378],
            [-42.941633362999902, -20.412773022],
            [-42.941655065, -20.412721872],
            [-42.941727944, -20.412679859],
            [-42.941762320999899, -20.412643108999902],
            [-42.941845337, -20.412665510999901],
            [-42.941883844, -20.412621778999899],
            [-42.941885946999903, -20.4125739209999],
            [-42.941811395999899, -20.412497811],
            [-42.941797498, -20.412373101999901],
            [-42.941814478, -20.412294559],
            [-42.941832458, -20.412237884],
            [-42.941740406999898, -20.412157088999901],
            [-42.941714363, -20.412095504],
            [-42.941753278, -20.412048334],
            [-42.94171891, -20.411959738999901],
            [-42.941697229999903, -20.411913775],
            [-42.94171124, -20.411880271],
            [-42.941754792, -20.411873817],
            [-42.941813022, -20.411817358],
            [-42.941844782, -20.411766538999899],
            [-42.941913815, -20.411739858],
            [-42.941989145, -20.411713258],
            [-42.942024342, -20.411669291],
            [-42.94210257, -20.411697859],
            [-42.942182557, -20.411698203],
            [-42.942230899, -20.411664926],
            [-42.942266768, -20.4116665449999],
            [-42.942290398999901, -20.411703975],
            [-42.942319945, -20.411715474999902],
            [-42.942419908, -20.411666864],
            [-42.942494336, -20.411589992],
            [-42.94259592, -20.411527486999901],
            [-42.942587878, -20.411477787],
            [-42.942525259, -20.411440077],
            [-42.942498497, -20.41137642],
            [-42.942438584999898, -20.411336121],
            [-42.942465758, -20.411245412],
            [-42.942527455999901, -20.4111880409999],
            [-42.942600445, -20.4111980559999],
            [-42.942689061, -20.411246417],
            [-42.94276896, -20.411277321999901],
            [-42.942820527, -20.411215888999902],
            [-42.9428508089999, -20.411127114],
            [-42.942914951, -20.4111113189999],
            [-42.942957276, -20.411067789999901],
            [-42.942948741, -20.4109570859999],
            [-42.942943713, -20.410856599999899],
            [-42.943010214, -20.4108085629999],
            [-42.9429767889999, -20.41071247],
            [-42.942929917, -20.410591576999899],
            [-42.942894527, -20.41046704],
            [-42.942887923999898, -20.410356435],
            [-42.9429477199999, -20.410302389],
            [-42.943031845, -20.410309218999899],
            [-42.943115711, -20.410294055],
            [-42.943166301, -20.410327106],
            [-42.943188494, -20.410390612999901],
            [-42.943159688, -20.410436789],
            [-42.943074387, -20.410415553999901],
            [-42.943026324999899, -20.410495808],
            [-42.94303789, -20.4106002259999],
            [-42.943132939999899, -20.410668513],
            [-42.943205615999901, -20.410673233],
            [-42.943256607, -20.410630506],
            [-42.943222703999901, -20.410550369],
            [-42.943261873, -20.4104972119999],
            [-42.943343205, -20.410451927999901],
            [-42.943407422999897, -20.410402486],
            [-42.94343636, -20.410345506999899],
            [-42.943484519, -20.410334706],
            [-42.943529349, -20.410364457999901],
            [-42.943553006999899, -20.410440061],
            [-42.943534031, -20.410538299999899],
            [-42.943565124, -20.410647571],
            [-42.943584205, -20.410746811],
            [-42.943615789, -20.410791644],
            [-42.94369054, -20.410786921],
            [-42.94372192, -20.410676368999901],
            [-42.94377825, -20.4105672869999],
            [-42.943888291, -20.410457196],
            [-42.944045245, -20.410348929999898],
            [-42.944102313, -20.410333342999898],
            [-42.944177335999903, -20.410296184999901],
            [-42.94425927, -20.410348556],
            [-42.944205196, -20.410414757999899],
            [-42.944210934, -20.410476999],
            [-42.944255590999902, -20.4105523869999],
            [-42.94431695, -20.410605279],
            [-42.944374452, -20.4105470659999],
            [-42.944432784999897, -20.410462245],
            [-42.944507232, -20.410401329],
            [-42.94455472, -20.410352418999899],
            [-42.944632021, -20.410395293],
            [-42.944754003999897, -20.410383564],
            [-42.9448507399999, -20.410445418999899],
            [-42.944897513999898, -20.4105254209999],
            [-42.945006599, -20.410637094],
            [-42.945082194, -20.41068375],
            [-42.945186718999899, -20.410687324],
            [-42.945237657999897, -20.410724968999901],
            [-42.945295886999901, -20.410724361],
            [-42.945331554999903, -20.410624449999901],
            [-42.945320281, -20.410574966],
            [-42.945342950999901, -20.410527930999901],
            [-42.945424632, -20.410511175999901],
            [-42.945502034999897, -20.410622716],
            [-42.945483802, -20.410725181],
            [-42.945437034, -20.410769273],
            [-42.945358792, -20.410813357999899],
            [-42.945359722, -20.4108511479999],
            [-42.945378360999896, -20.410879047],
            [-42.94546493, -20.410868649],
            [-42.945527424, -20.410860533999902],
            [-42.945600729, -20.410845449],
            [-42.945675894, -20.410801829],
            [-42.945721379, -20.410780384],
            [-42.9457926279999, -20.410792662999899],
            [-42.945828648, -20.410829549],
            [-42.945851466, -20.410903129],
            [-42.9459355279999, -20.410929747999901],
            [-42.946031041, -20.410951162999901],
            [-42.946077585999902, -20.410959496],
            [-42.946120979999897, -20.411030506],
            [-42.946185976, -20.411085833999898],
            [-42.946234804, -20.411154452999899],
            [-42.946291313, -20.411198574],
            [-42.946360671, -20.411217145],
            [-42.946442836, -20.411248378],
            [-42.946555346, -20.411259897],
            [-42.946611474999898, -20.411302738],
            [-42.946617815, -20.411361923999898],
            [-42.946668588, -20.411415055],
            [-42.946671631, -20.411480170999901],
            [-42.946570957999903, -20.411549758],
            [-42.94652537, -20.411602973],
            [-42.946409189999898, -20.411681722],
            [-42.946349069, -20.411722472999902],
            [-42.946355739, -20.411766758999899],
            [-42.946398916, -20.411781564],
            [-42.946490923999903, -20.4117913649999],
            [-42.946623974, -20.411785121],
            [-42.946786688, -20.411780004],
            [-42.946872555, -20.4117935589999],
            [-42.9470154589999, -20.411766042],
            [-42.947136989999898, -20.411759908],
            [-42.94728316, -20.41173156],
            [-42.947416480999898, -20.411679536999898],
            [-42.947505869999901, -20.411653435999899],
            [-42.947632311999897, -20.411665257],
            [-42.947808071999901, -20.41164256],
            [-42.9478417569999, -20.411563090999898],
            [-42.947853841, -20.411396437],
            [-42.947928952, -20.411298135],
            [-42.948059816, -20.411200391],
            [-42.948049351, -20.411134827999899],
            [-42.947958679, -20.410991399],
            [-42.947813488, -20.410846778],
            [-42.947809532, -20.410750623],
            [-42.947848584, -20.410695588],
            [-42.947929466999902, -20.4106398499999],
            [-42.947984759, -20.4105696029999],
            [-42.948067237, -20.410491032],
            [-42.948200014999898, -20.410486670999902],
            [-42.94823931, -20.410499178999899],
            [-42.948425934999896, -20.410436295],
            [-42.94856415, -20.410248264],
            [-42.948635329, -20.410141336],
            [-42.948694767999903, -20.410019723999898],
            [-42.948721066, -20.409885971999898],
            [-42.94862561, -20.4097655469999],
            [-42.948650536999899, -20.409682625],
            [-42.948716369, -20.409592066],
            [-42.948965873, -20.409599915999902],
            [-42.949143219999897, -20.409534498],
            [-42.949309673999899, -20.409507291999901],
            [-42.949520203, -20.409529034999899],
            [-42.94992381, -20.40970028],
            [-42.950111336, -20.40982272],
            [-42.9503127, -20.410039186999899],
            [-42.950470383, -20.410128431],
            [-42.950657073999899, -20.4101216789999],
            [-42.950868376, -20.410049306],
            [-42.950980215999898, -20.410074655],
            [-42.951079563, -20.410167605],
            [-42.951117456, -20.410219976],
            [-42.951096412, -20.410275317999901],
            [-42.950991218999903, -20.410336589999901],
            [-42.950972857999901, -20.410445098],
            [-42.951013276, -20.41054532],
            [-42.951154295, -20.410589824999899],
            [-42.951293072999903, -20.410574969],
            [-42.951509595999902, -20.410425561],
            [-42.951598292, -20.410294280999899],
            [-42.951724981, -20.410052016999899],
            [-42.951780616999898, -20.409878555],
            [-42.951947307, -20.409820086],
            [-42.952079610999903, -20.409799998],
            [-42.952113622999903, -20.409719907],
            [-42.952067975, -20.409630778999901],
            [-42.952091206, -20.409506227],
            [-42.952095944999897, -20.4094253499999],
            [-42.952344724999897, -20.409262957],
            [-42.952532548, -20.409166768],
            [-42.952761918, -20.409144419],
            [-42.952995577, -20.409053388],
            [-42.953080473999897, -20.409061946999898],
            [-42.953195127, -20.409150143],
            [-42.953319113, -20.409226359999899],
            [-42.953492389, -20.409259192],
            [-42.953790953999899, -20.409238447],
            [-42.954019279, -20.409327773],
            [-42.954139026999897, -20.4094307129999],
            [-42.954266956, -20.409491784999901],
            [-42.95447644, -20.409481092],
            [-42.95464657, -20.4095514549999],
            [-42.954707375999902, -20.409689007999901],
            [-42.954700728, -20.409993272999898],
            [-42.954728750999898, -20.410175105999901],
            [-42.95483244, -20.410398128999901],
            [-42.954880440999901, -20.410466748],
            [-42.954978059, -20.41046317],
            [-42.955127816, -20.410426647],
            [-42.955186496, -20.410476355],
            [-42.955211372, -20.410700108999901],
            [-42.955298144, -20.410734938],
            [-42.9553478, -20.4107399449999],
            [-42.955446240999898, -20.410629859],
            [-42.955539992, -20.41058286],
            [-42.955640985999899, -20.410610081],
            [-42.955692950999897, -20.410694602],
            [-42.955713601, -20.410861532],
            [-42.955811212999897, -20.410996492],
            [-42.955958983999899, -20.411075755999899],
            [-42.95610471, -20.41110114],
            [-42.956228412, -20.411037313999898],
            [-42.956434293, -20.410821301999899],
            [-42.956549927, -20.410822368],
            [-42.956679752, -20.410831731],
            [-42.956776224, -20.4107562209999],
            [-42.956833554, -20.4106511969999],
            [-42.956850979, -20.410465407999901],
            [-42.956887458, -20.410427431],
            [-42.956969893, -20.410466632],
            [-42.957036466, -20.410565757],
            [-42.9570734979999, -20.410588252],
            [-42.957171987, -20.410512556999901],
            [-42.957194231, -20.410432909],
            [-42.95712746, -20.410370685],
            [-42.956986865, -20.410250646999899],
            [-42.956899146, -20.410046765999901],
            [-42.956933292, -20.409916763999899],
            [-42.957036194, -20.409892485999901],
            [-42.957194242999897, -20.409864259],
            [-42.957333444, -20.409826322],
            [-42.957432218999898, -20.409906178],
            [-42.957509963999897, -20.410013584],
            [-42.957587038, -20.410036691],
            [-42.95770039, -20.409998754999901],
            [-42.957752121999903, -20.4099176789999],
            [-42.957775436, -20.409775376],
            [-42.957727137999903, -20.4097152689999],
            [-42.957652380999903, -20.409742448],
            [-42.957604273999898, -20.409745114],
            [-42.957528989, -20.409693789],
            [-42.957513586999902, -20.409572558],
            [-42.957537418999898, -20.4094523109999],
            [-42.957693158, -20.40934514],
            [-42.957856071, -20.409229554],
            [-42.958060605999897, -20.409155255],
            [-42.958181216, -20.409094604],
            [-42.958369709, -20.4089311339999],
            [-42.958548855999901, -20.408848185999901],
            [-42.958702741, -20.4089620469999],
            [-42.958854549, -20.4089913729999],
            [-42.9589859599999, -20.408952617999901],
            [-42.959049002, -20.408879971],
            [-42.959063430999898, -20.408816726999898],
            [-42.958991421, -20.408742961],
            [-42.958711804, -20.408543482],
            [-42.958713611999897, -20.408430070999898],
            [-42.958785028, -20.408351202999899],
            [-42.958961337999902, -20.408217775],
            [-42.959162695, -20.408179402],
            [-42.959398624, -20.408161723],
            [-42.959582966, -20.4082126129999],
            [-42.959738375, -20.408174766999899],
            [-42.959835026, -20.408039699],
            [-42.959999602, -20.407930599999901],
            [-42.960637495, -20.407780374],
            [-42.961033595999901, -20.407730692],
            [-42.961131155, -20.407699996],
            [-42.9612034149999, -20.4076577669999],
            [-42.961271574, -20.407527675],
            [-42.961315768, -20.407449517],
            [-42.961362564999902, -20.407389857999899],
            [-42.961464193, -20.407382169],
            [-42.961541454, -20.407435791999902],
            [-42.961595589, -20.4075435519999],
            [-42.961632342, -20.407720116],
            [-42.961760565, -20.407762562],
            [-42.96192902, -20.407741503999901],
            [-42.9620370109999, -20.407706178],
            [-42.962211329, -20.407722952],
            [-42.962394828999898, -20.4076998859999],
            [-42.962437033999898, -20.407541621999901],
            [-42.962450835, -20.407273238],
            [-42.962568658999899, -20.407103476],
            [-42.9628317, -20.406920714],
            [-42.963073035, -20.406847171],
            [-42.963376293, -20.406907793],
            [-42.963557521, -20.40693627],
            [-42.963767791, -20.406906159999899],
            [-42.963980579999898, -20.407025986],
            [-42.964046792, -20.407152793],
            [-42.963900676, -20.407460605],
            [-42.963771517999902, -20.407531541],
            [-42.963608892, -20.407584093],
            [-42.963545442999902, -20.407723931],
            [-42.9636001, -20.40780983],
            [-42.963764205, -20.407767783999901],
            [-42.963934399, -20.407818540999902],
            [-42.964236452, -20.407845419],
            [-42.9644290909999, -20.407841039999902],
            [-42.964540583, -20.407769046999899],
            [-42.96496516, -20.407675417999901],
            [-42.965047759, -20.407576137],
            [-42.965216568, -20.407506226],
            [-42.965412063, -20.40741916],
            [-42.965529375, -20.407360046999901],
            [-42.965639114999902, -20.4072457239999],
            [-42.965708868, -20.407093017],
            [-42.96582383, -20.406916162999899],
            [-42.965956414999901, -20.406795213999899],
            [-42.965984498999902, -20.4067406899999],
            [-42.965996227999902, -20.406554668],
            [-42.966061497999902, -20.4064480719999],
            [-42.9661427039999, -20.4063907469999],
            [-42.96626828, -20.406356784],
            [-42.96630089, -20.406331531],
            [-42.966276778, -20.406265237],
            [-42.966207905, -20.406162601999899],
            [-42.966209243, -20.406101706999898],
            [-42.966244042, -20.406019597],
            [-42.966317333999903, -20.406011491999902],
            [-42.966489591, -20.40605441],
            [-42.96656458, -20.4060010859999],
            [-42.966595098, -20.405931349],
            [-42.966584505, -20.405680905999901],
            [-42.966528073999903, -20.405511884],
            [-42.966545422, -20.405449609999899],
            [-42.966603264999897, -20.405434749999898],
            [-42.966681719999897, -20.405483306999901],
            [-42.966825163, -20.4056241219999],
            [-42.96700541, -20.405646719999901],
            [-42.967117984, -20.405604995],
            [-42.967157481999898, -20.405389984],
            [-42.967263223, -20.405102491],
            [-42.967347186, -20.404699277],
            [-42.967407306, -20.4045331959999],
            [-42.967520389, -20.4043643269999],
            [-42.967782457999903, -20.404151578],
            [-42.967932047, -20.403944171],
            [-42.968002752, -20.403836383999899],
            [-42.968025976, -20.403700033],
            [-42.968055245999899, -20.403632667999901],
            [-42.968137783, -20.403557572999901],
            [-42.9683678009999, -20.403378014999898],
            [-42.968487549, -20.403287793999901],
            [-42.968538122, -20.403242634],
            [-42.968631183, -20.403251606],
            [-42.968758033, -20.403336456],
            [-42.968833471, -20.403346056],
            [-42.968998504, -20.403315704],
            [-42.969085423, -20.4033430379999],
            [-42.969407912, -20.403491457],
            [-42.96961221, -20.403637565999901],
            [-42.969689543999898, -20.40369421],
            [-42.969752238, -20.403686345],
            [-42.969898167999901, -20.403552535],
            [-42.970040163, -20.403332083999899],
            [-42.97019796, -20.402948084999899],
            [-42.970287464, -20.402634205],
            [-42.970362932999898, -20.402573162],
            [-42.970459459, -20.402581542999901],
            [-42.970673269, -20.402652449999898],
            [-42.970917497999899, -20.402777665],
            [-42.971111576999903, -20.402994113],
            [-42.971480368999899, -20.403570735999899],
            [-42.971841804999897, -20.403894677],
            [-42.972007475999902, -20.404005355],
            [-42.97233031, -20.404043892999901],
            [-42.97249956, -20.403989234999901],
            [-42.973093780999903, -20.403492545999899],
            [-42.973416492999903, -20.403327705],
            [-42.973806285, -20.40324351],
            [-42.974504969, -20.403108231],
            [-42.974560923999903, -20.403078735],
            [-42.974596247, -20.4030121],
            [-42.974613577999897, -20.402661028999901],
            [-42.974708009, -20.402123888999899],
            [-42.974805708, -20.401905624999898],
            [-42.975036238, -20.401684007],
            [-42.975103433999898, -20.4015443709999],
            [-42.97505518, -20.401355859],
            [-42.97491127, -20.4010814489999],
            [-42.974619377, -20.400829421],
            [-42.974356418999903, -20.400721652999898],
            [-42.973968698, -20.400581858],
            [-42.973893162, -20.4004055619999],
            [-42.973937904, -20.400204258],
            [-42.974008303999902, -20.400057642],
            [-42.974038311, -20.3999345789999],
            [-42.973970408999897, -20.399863907999901],
            [-42.973900523999902, -20.399826882],
            [-42.973770671999901, -20.3997332759999],
            [-42.973585925, -20.39947275],
            [-42.973521547, -20.399386526999901],
            [-42.973503212, -20.399332407],
            [-42.973562093999902, -20.399202441999901],
            [-42.973584506, -20.399030824],
            [-42.973555672, -20.398842405],
            [-42.973482529, -20.3985894539999],
            [-42.973433078, -20.398442537999902],
            [-42.973395486999898, -20.398328384],
            [-42.973524298, -20.3980736789999],
            [-42.9736251859999, -20.398005016],
            [-42.973848561, -20.397969439],
            [-42.974168189, -20.397864236],
            [-42.974506072999901, -20.397759165],
            [-42.974779925999897, -20.397640753],
            [-42.974993759, -20.397610178],
            [-42.975261278999902, -20.397676356],
            [-42.97559566, -20.397795881],
            [-42.975809344, -20.397854756],
            [-42.976006942, -20.397819983],
            [-42.976126959999903, -20.397850579],
            [-42.976151359, -20.397890027],
            [-42.976186837999897, -20.397925508999901],
            [-42.976231288, -20.397913382],
            [-42.976256091, -20.397854154],
            [-42.976341406, -20.397756571999899],
            [-42.976441146999903, -20.397738056],
            [-42.976536589, -20.397755307999901],
            [-42.976756033999898, -20.397833539],
            [-42.976925577, -20.397856265],
            [-42.977038243, -20.39780286],
            [-42.977138298999897, -20.397719942],
            [-42.977197632999903, -20.397570051999899],
            [-42.977233141999903, -20.397336767],
            [-42.977286874999898, -20.397302051999901],
            [-42.977357796, -20.397308903],
            [-42.977421058999902, -20.397401891],
            [-42.977555607999903, -20.3976189649999],
            [-42.978012927999899, -20.397868951],
            [-42.978224333, -20.397754721999899],
            [-42.978374168999899, -20.397665631999899],
            [-42.978550040999899, -20.397774447],
            [-42.978715531, -20.397849005],
            [-42.978850055, -20.39798711],
            [-42.978946317999899, -20.398147577],
            [-42.978994315, -20.398230200999901],
            [-42.9789801169999, -20.398341353],
            [-42.979009991999902, -20.398431703],
            [-42.979061797, -20.398436017999899],
            [-42.9791181639999, -20.3984491329999],
            [-42.979201139999901, -20.398437416],
            [-42.979311895, -20.398512658999898],
            [-42.979387245999902, -20.39859688],
            [-42.979394441999901, -20.398716081],
            [-42.97947163, -20.398762206999901],
            [-42.979538195, -20.398732187],
            [-42.979561444999902, -20.398638648],
            [-42.979584659, -20.398513873999899],
            [-42.979528865, -20.3982928729999],
            [-42.979519629, -20.398046001],
            [-42.979419024, -20.397816475],
            [-42.979282198, -20.397646858],
            [-42.979222917999898, -20.39738912],
            [-42.979238687, -20.3971486639999],
            [-42.979312501, -20.397015557],
            [-42.9793615359999, -20.396870847999899],
            [-42.979363947, -20.396776967999902],
            [-42.979403465, -20.396682609],
            [-42.979522927999902, -20.396607221],
            [-42.97973446, -20.3965809339999],
            [-42.980068646999896, -20.3966596889999],
            [-42.980206922, -20.396693925],
            [-42.980332623, -20.396705595],
            [-42.9804394129999, -20.396646223999898],
            [-42.980687101999898, -20.396734255999899],
            [-42.98091919, -20.396829346999901],
            [-42.981136170999903, -20.396868783999899],
            [-42.981430834, -20.39704625],
            [-42.981614895, -20.397231116999901],
            [-42.981771491, -20.397219056],
            [-42.981895773, -20.397249164],
            [-42.982179034, -20.3972934],
            [-42.982304282, -20.397312628999899],
            [-42.9823349219999, -20.397419246],
            [-42.982286266, -20.397550324999902],
            [-42.982291796, -20.397660045999899],
            [-42.982379705, -20.397696476],
            [-42.982507492, -20.397655296],
            [-42.982558183, -20.397536807999899],
            [-42.982626631999899, -20.397484968],
            [-42.982705361999898, -20.397491761],
            [-42.982765806, -20.397603361999899],
            [-42.982833058, -20.397810885999899],
            [-42.982973886, -20.397984927],
            [-42.983144991, -20.398048311],
            [-42.983216964, -20.397970009999899],
            [-42.983252826999902, -20.397816723999899],
            [-42.983310828999898, -20.397727209],
            [-42.983450859, -20.397743114],
            [-42.983531453999902, -20.397819943999899],
            [-42.983645899, -20.398099347],
            [-42.983692903, -20.398233635999901],
            [-42.983833005, -20.398371175],
            [-42.983887995, -20.398342482],
            [-42.983940679999897, -20.398157727999902],
            [-42.98407055, -20.3980080229999],
            [-42.984174292, -20.397943991],
            [-42.98430344, -20.39792677],
            [-42.984453808999902, -20.3979281159999],
            [-42.984723701, -20.3978211149999],
            [-42.9851855369999, -20.397748189],
            [-42.985719894999903, -20.397718992],
            [-42.985859495999897, -20.397657414],
            [-42.9862778419999, -20.397183711],
            [-42.986721917, -20.396788178],
            [-42.986950180999898, -20.396669147],
            [-42.987242202999902, -20.396612123],
            [-42.987510437, -20.396681101],
            [-42.9876262719999, -20.396801259],
            [-42.987762107, -20.396810053],
            [-42.988096726, -20.396828373999899],
            [-42.98837692, -20.39675884],
            [-42.9885885099999, -20.396717881],
            [-42.988825541, -20.3968013329999],
            [-42.989068239999902, -20.3968867799999],
            [-42.989209592999899, -20.396981104],
            [-42.989398541999897, -20.3970355609999],
            [-42.989794546, -20.397159377],
            [-42.989996961, -20.3972348889999],
            [-42.990156256, -20.397344437],
            [-42.990246225, -20.3973609089999],
            [-42.990351382, -20.397329936999899],
            [-42.990659143999899, -20.3972073159999],
            [-42.99091321, -20.397212630999899],
            [-42.991062279999902, -20.397299824],
            [-42.99114178, -20.397289751],
            [-42.991166529999902, -20.397202707],
            [-42.991197529, -20.397054533],
            [-42.991270132999901, -20.397009345],
            [-42.991362113, -20.39702126],
            [-42.991447051, -20.397091501],
            [-42.991716380999897, -20.397248469],
            [-42.991839336999902, -20.3973382729999],
            [-42.991962763999901, -20.397331338999901],
            [-42.992116634, -20.39723423],
            [-42.9922899379999, -20.397073322],
            [-42.992361681, -20.397035373999898],
            [-42.992437073, -20.3970585399999],
            [-42.992539599999901, -20.397166746],
            [-42.992655553, -20.3971674709999],
            [-42.992829695999902, -20.397169645],
            [-42.992982137, -20.397221382999899],
            [-42.993095787, -20.397218753999901],
            [-42.993282719, -20.397148978],
            [-42.993378321, -20.397167610999901],
            [-42.993444038, -20.397274449],
            [-42.993403045, -20.397391071999898],
            [-42.993370206999899, -20.3975146669999],
            [-42.993416816, -20.397573727999902],
            [-42.993580543999897, -20.397600736],
            [-42.993685812, -20.3976014949999],
            [-42.993763916, -20.397551882999899],
            [-42.993832756, -20.397366115],
            [-42.993917667999902, -20.397259969999901],
            [-42.993958172, -20.397232428999899],
            [-42.994032789999899, -20.397231034999901],
            [-42.994088906999899, -20.397251086],
            [-42.994132153, -20.397317976],
            [-42.994169978999899, -20.397326536],
            [-42.994276774, -20.397299600999901],
            [-42.994349349999901, -20.397313982999901],
            [-42.9944332869999, -20.397383561],
            [-42.994505279, -20.397396108],
            [-42.994565461, -20.397364432],
            [-42.994651888, -20.397370085],
            [-42.994709268999898, -20.397404461999901],
            [-42.994717115999897, -20.397495896],
            [-42.994705934, -20.3976627609999],
            [-42.994836888999899, -20.397797354],
            [-42.994997813999902, -20.397927237],
            [-42.995118761999898, -20.397987064999899],
            [-42.995332849, -20.398021517],
            [-42.995461099, -20.398037411],
            [-42.995657724, -20.3980040389999],
            [-42.995856527999898, -20.398007508],
            [-42.996039924999899, -20.39810761],
            [-42.996167479999897, -20.398287206],
            [-42.996227738, -20.398280997],
            [-42.996281005999897, -20.398265371],
            [-42.996371447999898, -20.398168456],
            [-42.996475217999901, -20.398121048],
            [-42.9965992099999, -20.398150803],
            [-42.996686054, -20.398146244],
            [-42.996825717, -20.398138678],
            [-42.996890417, -20.398178882],
            [-42.9970119, -20.398331819],
            [-42.997021181, -20.398409893],
            [-42.996980746, -20.398440521999898],
            [-42.996911966, -20.398522084],
            [-42.996867651999899, -20.398559392],
            [-42.996896571, -20.398651378],
            [-42.997009053999903, -20.398726865999901],
            [-42.997283205, -20.398920462],
            [-42.997434214, -20.399099175999901],
            [-42.997470909999898, -20.399215863999899],
            [-42.997593997, -20.3993341249999],
            [-42.997749911, -20.399437153999902],
            [-42.997788456, -20.3995030839999],
            [-42.997747293, -20.399588556],
            [-42.997745532, -20.399635308],
            [-42.997878297999897, -20.3996756569999],
            [-42.997915323, -20.399627571999901],
            [-42.9979989079999, -20.39958652],
            [-42.998132751, -20.399589509],
            [-42.998183049, -20.399567510999901],
            [-42.998225594, -20.399501818],
            [-42.9982202899999, -20.399445536],
            [-42.998265381, -20.399408372],
            [-42.998364077, -20.3994582],
            [-42.998433749999897, -20.399420424],
            [-42.998565361, -20.399468578],
            [-42.998703186999897, -20.399433798],
            [-42.99880934, -20.399417843],
            [-42.99887611, -20.399434524999901],
            [-42.999014493999901, -20.399471814],
            [-42.999112818, -20.399465484],
            [-42.999239377, -20.399369313],
            [-42.999366384, -20.399243774],
            [-42.999529763999902, -20.399162967999899],
            [-42.999679812, -20.3991383769999],
            [-42.999825906999902, -20.398968559],
            [-42.999980105, -20.398698404],
            [-43.000031258, -20.398613856999901],
            [-42.999995656, -20.398549151999902],
            [-42.999944098999897, -20.398529718],
            [-42.999919168, -20.39851829],
            [-42.999878464999902, -20.398551445],
            [-42.999810399, -20.39845437],
            [-42.999787542, -20.398360031],
            [-42.999847218999903, -20.398232477],
            [-42.999915404, -20.398094804],
            [-42.999952198, -20.397980978],
            [-43.000186116999899, -20.397834281],
            [-43.0003426009999, -20.397703008],
            [-43.0003968729999, -20.397577058],
            [-43.000438388, -20.397432519999899],
            [-43.000539945, -20.397428674],
            [-43.000646517, -20.397477423999899],
            [-43.000744906, -20.397499568999901],
            [-43.0007885959999, -20.397468221999901],
            [-43.00082501, -20.397472380999901],
            [-43.000840018, -20.397592985],
            [-43.000845563, -20.397709872],
            [-43.000742124999903, -20.397762491],
            [-43.000699982, -20.3978450719999],
            [-43.000854034, -20.3979482189999],
            [-43.000946688999903, -20.397977598999901],
            [-43.001258445, -20.397971641],
            [-43.001599461, -20.397888033999902],
            [-43.0020172649999, -20.397876153],
            [-43.002127582999897, -20.397825898],
            [-43.002297244, -20.39781361],
            [-43.002518097999904, -20.397769444999899],
            [-43.002635568, -20.397766012],
            [-43.002705165, -20.3977267389999],
            [-43.00280355, -20.397730094999901],
            [-43.002882354, -20.397783127],
            [-43.0029881359999, -20.397776356999898],
            [-43.003125924, -20.3977817859999],
            [-43.003226094999903, -20.397821752],
            [-43.003345163999903, -20.397878288999902],
            [-43.003553044999897, -20.397797941],
            [-43.003641057, -20.397781086999899],
            [-43.003681076, -20.397825899999901],
            [-43.003789471999902, -20.397828487],
            [-43.003806085999898, -20.397764238],
            [-43.003743642, -20.397666069],
            [-43.003766013, -20.397584916],
            [-43.003868804, -20.397506951],
            [-43.004017403, -20.397458524999902],
            [-43.00429688, -20.397462704],
            [-43.004610243999899, -20.397516019],
            [-43.004901737999901, -20.397518863],
            [-43.005124212999903, -20.397568443999901],
            [-43.005340819, -20.397624811],
            [-43.005432415, -20.397614774],
            [-43.005546159999902, -20.39761055],
            [-43.005616877, -20.397638969],
            [-43.005683623, -20.397733573],
            [-43.005641091, -20.39786363],
            [-43.005551232999899, -20.398094184999898],
            [-43.005512262, -20.3983498589999],
            [-43.005376306999899, -20.398524150999901],
            [-43.005422476, -20.398629725],
            [-43.005544008, -20.398683537],
            [-43.005686293999901, -20.398653474],
            [-43.005909139, -20.39852023],
            [-43.006045024999899, -20.398416343],
            [-43.006090749, -20.398332320999899],
            [-43.006217153, -20.398337193],
            [-43.006300285, -20.398320133],
            [-43.006469513999903, -20.398196721],
            [-43.006542134, -20.398081487],
            [-43.006602629, -20.398054940999899],
            [-43.006678221999898, -20.39816529],
            [-43.006719571, -20.398319469999901],
            [-43.006667466, -20.398411673999899],
            [-43.006659159, -20.3985064469999],
            [-43.006699494, -20.398593196],
            [-43.00688336, -20.398662134999899],
            [-43.007017192, -20.398667936],
            [-43.007160299, -20.3988335089999],
            [-43.007193891, -20.3989182239999],
            [-43.007330923, -20.398961234999899],
            [-43.007439577, -20.3989234229999],
            [-43.007501195, -20.398831376],
            [-43.007506994, -20.398695319],
            [-43.00755108, -20.398596691],
            [-43.007642110999903, -20.398551348],
            [-43.00776146, -20.398538732],
            [-43.007940386, -20.398609006],
            [-43.0086026829999, -20.398790925999901],
            [-43.008685778, -20.398797444],
            [-43.008769999, -20.398720453],
            [-43.008842564, -20.398555997],
            [-43.008865804, -20.398292058],
            [-43.009023562, -20.398112581],
            [-43.009180612, -20.397997071],
            [-43.009430113, -20.397830434],
            [-43.009453790999899, -20.3977597149999],
            [-43.009527433, -20.397650854999899],
            [-43.010050774, -20.3974463129999],
            [-43.010357789, -20.397298368999898],
            [-43.010491129999899, -20.397200091999899],
            [-43.010589109, -20.397187726],
            [-43.010739053999899, -20.397186571],
            [-43.01083399, -20.397139527],
            [-43.010802187, -20.3970509379999],
            [-43.010775298999903, -20.396927106],
            [-43.010769331999903, -20.396837653],
            [-43.010820368999902, -20.396752321999902],
            [-43.010966198999903, -20.396742721],
            [-43.011286824, -20.396661816999899],
            [-43.011443611999901, -20.396693238999902],
            [-43.011461946999901, -20.3968386129999],
            [-43.011492725, -20.396920223999899],
            [-43.011611043999899, -20.3969401159999],
            [-43.011711518, -20.3969713299999],
            [-43.011815568, -20.396867239999899],
            [-43.011970677, -20.396749063],
            [-43.012038517999898, -20.396647373],
            [-43.011888370999898, -20.3965681029999],
            [-43.011578716, -20.396414708999899],
            [-43.011312979, -20.396366219999901],
            [-43.011306216, -20.396261533],
            [-43.011425334, -20.3961406459999],
            [-43.011463960999897, -20.39604653],
            [-43.011556946, -20.39599078],
            [-43.011646524, -20.395954279],
            [-43.011731343, -20.396004755],
            [-43.011758834, -20.396098722],
            [-43.011792168999897, -20.396221189999899],
            [-43.011903509, -20.396279797],
            [-43.012046211, -20.3962650829999],
            [-43.012071813, -20.39617915],
            [-43.012034819, -20.396045596],
            [-43.012049057, -20.395955477],
            [-43.012143006, -20.39587018],
            [-43.012267731999899, -20.395860700999901],
            [-43.012544324, -20.395886371],
            [-43.012659375999903, -20.395860083999899],
            [-43.013003685999898, -20.395807546999901],
            [-43.013500942999897, -20.3956752899999],
            [-43.013801077, -20.395542264],
            [-43.013956966, -20.395449006],
            [-43.014139964, -20.395429835999899],
            [-43.014291036, -20.395459784],
            [-43.014344994999902, -20.395408543],
            [-43.014458637, -20.3953257659999],
            [-43.014471962, -20.395240245],
            [-43.014413596999901, -20.395144385],
            [-43.014359812999899, -20.3949812339999],
            [-43.014352894, -20.3948117149999],
            [-43.014437748, -20.394697189999899],
            [-43.014540294, -20.394656567],
            [-43.014647463, -20.394708821999899],
            [-43.014743758, -20.394723166999899],
            [-43.014855114999897, -20.394721659],
            [-43.014956995, -20.394795008],
            [-43.015000261999901, -20.394982253],
            [-43.015208216, -20.395562323],
            [-43.01534929, -20.395731468],
            [-43.015516176999903, -20.395814527],
            [-43.015707667999898, -20.395858706999899],
            [-43.015860491, -20.395925335],
            [-43.016016243, -20.396029374],
            [-43.016078531999902, -20.396124660999899],
            [-43.01607842, -20.396309479],
            [-43.016087962, -20.396532346999901],
            [-43.016155784, -20.396606705999901],
            [-43.016160043999903, -20.396678415999901],
            [-43.016143316999901, -20.396794954],
            [-43.016050838, -20.3968905249999],
            [-43.016194631, -20.3971792319999],
            [-43.016286507, -20.3972608139999],
            [-43.016529469, -20.397336138],
            [-43.016751453, -20.3974240659999],
            [-43.016857408, -20.397520678],
            [-43.01689413, -20.397633437],
            [-43.016882501999902, -20.397719594],
            [-43.016839391, -20.397830113999898],
            [-43.016860935999901, -20.397996116999899],
            [-43.016876130999897, -20.398194696],
            [-43.016880574, -20.398328917999901],
            [-43.0168753029999, -20.398403325999901],
            [-43.016909806999898, -20.398577474],
            [-43.016816658, -20.398807533],
            [-43.016701302999898, -20.398896407999899],
            [-43.016616156999902, -20.399114684],
            [-43.016597238, -20.399198607999899],
            [-43.016514156, -20.39926364],
            [-43.016454699, -20.399464316],
            [-43.016487997999903, -20.399569957],
            [-43.016565924, -20.399595459],
            [-43.01668374, -20.399595901999898],
            [-43.016810482, -20.399667768],
            [-43.01695389, -20.399868988],
            [-43.016954907, -20.400064089],
            [-43.0168966109999, -20.400210073],
            [-43.0169090099999, -20.400346442999901],
            [-43.016992984999902, -20.400427212999901],
            [-43.017005262999902, -20.400516598],
            [-43.016947519, -20.400554150999898],
            [-43.016891412999897, -20.400630345],
            [-43.016830006, -20.400675481999901],
            [-43.016917028, -20.400865766],
            [-43.017055679999899, -20.401024701],
            [-43.017137232, -20.401160601999901],
            [-43.0172706, -20.401232668],
            [-43.017437527, -20.401452771],
            [-43.017603999999899, -20.401866459],
            [-43.017620257, -20.402001584999901],
            [-43.017568577, -20.402106617999902],
            [-43.017396435, -20.402226496],
            [-43.01704732, -20.402466279999899],
            [-43.016949607, -20.402573522],
            [-43.016917260999897, -20.402688321],
            [-43.016955959, -20.402817250999899],
            [-43.01699768, -20.40295509],
            [-43.017253657, -20.403206908999898],
            [-43.0173991569999, -20.403554665999899],
            [-43.017522183, -20.403619013],
            [-43.017669236, -20.403683229],
            [-43.01778139, -20.403605204],
            [-43.017874247999899, -20.403490325999901],
            [-43.018011175, -20.4033701349999],
            [-43.018119102, -20.403281622999899],
            [-43.018144413, -20.4031884669999],
            [-43.018178045, -20.403151931],
            [-43.018283790999902, -20.40307072],
            [-43.018384807, -20.403070068],
            [-43.018697645, -20.403156748999901],
            [-43.018771120999901, -20.403196052999899],
            [-43.018843849999897, -20.403208806999899],
            [-43.018977537999902, -20.403199479],
            [-43.019082672, -20.4031958099999],
            [-43.019200273, -20.40325789],
            [-43.019407348999898, -20.403286376],
            [-43.019605833, -20.40330671],
            [-43.019734578999902, -20.403388805],
            [-43.019793039, -20.403491909],
            [-43.01987824, -20.40348841],
            [-43.019975465, -20.4034971949999],
            [-43.020086455, -20.40350253],
            [-43.020210759999898, -20.40338544],
            [-43.020341904, -20.403382548],
            [-43.020455356, -20.403463342],
            [-43.020524958, -20.403605803999898],
            [-43.020574229, -20.4040599319999],
            [-43.020624672999901, -20.404279142999901],
            [-43.020679173, -20.404472976],
            [-43.020746155, -20.4045622269999],
            [-43.020733617, -20.404779258],
            [-43.020865498, -20.4048295609999],
            [-43.021114538999903, -20.404853303],
            [-43.021316816, -20.4049707799999],
            [-43.021415222, -20.405015541999902],
            [-43.021492445, -20.405033219],
            [-43.02154253, -20.405081450999901],
            [-43.0216288089999, -20.405272533],
            [-43.021795208999897, -20.405526688],
            [-43.021803889999902, -20.4056306959999],
            [-43.021705915, -20.4058587],
            [-43.021630044999903, -20.406209267999898],
            [-43.021700984999903, -20.406430047],
            [-43.021647666, -20.406975510999899],
            [-43.0215827769999, -20.407124956],
            [-43.021600811, -20.407304751],
            [-43.021636137, -20.407480262],
            [-43.021597307, -20.407841157],
            [-43.021635831, -20.407981895],
            [-43.021727681999899, -20.408077181],
            [-43.0219494689999, -20.408215601999899],
            [-43.022109417, -20.408224923],
            [-43.022196689999902, -20.408189574999898],
            [-43.022280767, -20.4081137269999],
            [-43.022439273, -20.408076573],
            [-43.022574768, -20.408091487],
            [-43.0227621229999, -20.408107508999901],
            [-43.022848094, -20.408198737],
            [-43.022877729999898, -20.408303913999902],
            [-43.023080235999899, -20.408433128],
            [-43.023230315, -20.4085135239999],
            [-43.02333277, -20.408573068],
            [-43.023425317, -20.408784265],
            [-43.023583829, -20.409065822999899],
            [-43.024106624, -20.409646575999901],
            [-43.0241125699999, -20.409649634999901],
            [-43.024146, -20.409599995999901],
            [-43.024338, -20.409322996],
            [-43.024421, -20.409103995999899],
            [-43.024552, -20.408861995999899],
            [-43.024647, -20.4086309959999],
            [-43.024851, -20.408330996],
            [-43.025007, -20.408053996],
            [-43.025149999999897, -20.407822995999901],
            [-43.025234, -20.407615995999901],
            [-43.025317, -20.4073969959999],
            [-43.025387, -20.4071659959999],
            [-43.025446, -20.406912996],
            [-43.025443, -20.406683996],
            [-43.025428, -20.406419996],
            [-43.025351999999899, -20.406121996],
            [-43.025252, -20.405824996],
            [-43.025128, -20.405630996],
            [-43.025028, -20.405389995999901],
            [-43.024965, -20.405172996],
            [-43.024959, -20.4046219959999],
            [-43.024968999999899, -20.404368996],
            [-43.02499, -20.404093996],
            [-43.025011999999897, -20.403863995999899],
            [-43.025058999999899, -20.403621996],
            [-43.025141, -20.403356996],
            [-43.025224, -20.403103996],
            [-43.025319, -20.402884996],
            [-43.025414, -20.402642995999901],
            [-43.025435, -20.402355996],
            [-43.025427, -20.401563995999901],
            [-43.025401, -20.401345996],
            [-43.025348999999899, -20.401024995999901],
            [-43.025297, -20.400761996],
            [-43.025196999999899, -20.400475996],
            [-43.02511, -20.400257995999901],
            [-43.02501, -20.400017996],
            [-43.02485, -20.399835996],
            [-43.024556, -20.399677996],
            [-43.024358999999897, -20.399529996],
            [-43.024138999999899, -20.399451996],
            [-43.023895, -20.399361996],
            [-43.023626, -20.399261996],
            [-43.023418, -20.399159996],
            [-43.023209999999899, -20.399023996],
            [-43.022977, -20.398911996],
            [-43.022769, -20.398775995999902],
            [-43.022644, -20.398523995999899],
            [-43.022593, -20.398294995999901],
            [-43.022579, -20.398076995999901],
            [-43.022711, -20.397857996],
            [-43.022756, -20.397547996],
            [-43.022922, -20.397132996],
            [-43.023042, -20.396936996],
            [-43.023174, -20.396705996],
            [-43.02333, -20.396543995999899],
            [-43.023511, -20.396334996],
            [-43.023655, -20.396149996],
            [-43.023823999999898, -20.395987996],
            [-43.023919, -20.395768996],
            [-43.023978, -20.3955499959999],
            [-43.024071999999897, -20.395285995999899],
            [-43.024082, -20.395066996],
            [-43.024075, -20.394320996],
            [-43.024048, -20.394091995999901],
            [-43.023873, -20.3936799959999],
            [-43.023725, -20.393474996],
            [-43.023576999999896, -20.393292996],
            [-43.023381, -20.393133996],
            [-43.023184, -20.392962995999898],
            [-43.023012, -20.392803995999898],
            [-43.022827, -20.392587996],
            [-43.022776, -20.392358995999899],
            [-43.022774, -20.392128995999901],
            [-43.022796, -20.391910996],
            [-43.022928, -20.391713996],
            [-43.023072, -20.391505996],
            [-43.023228, -20.3912749959999],
            [-43.023397, -20.391112995999901],
            [-43.023626, -20.390949996],
            [-43.023782, -20.390718996],
            [-43.02395, -20.390499996],
            [-43.023997, -20.390280995999898],
            [-43.024006, -20.390028996],
            [-43.023998999999897, -20.389281996],
            [-43.023984, -20.389052996],
            [-43.023885, -20.388835996],
            [-43.023724, -20.388641995999901],
            [-43.023601, -20.3884369959999],
            [-43.023392, -20.388323995999901],
            [-43.023147, -20.388176996],
            [-43.02289, -20.388040996],
            [-43.022694, -20.387916995999898],
            [-43.022462, -20.387803995999899],
            [-43.022205, -20.387737995999899],
            [-43.021986, -20.387658996],
            [-43.021742, -20.387649995999901],
            [-43.021413, -20.387640996],
            [-43.021181, -20.387597996],
            [-43.020901, -20.387530996],
            [-43.020681, -20.387440996],
            [-43.020449, -20.387385996],
            [-43.020240999999899, -20.387284995999899],
            [-43.020032, -20.387090995999898],
            [-43.019847, -20.386840995999901],
            [-43.019759, -20.386599996],
            [-43.019684, -20.386370996],
            [-43.019669, -20.3861419959999],
            [-43.019666, -20.385831996],
            [-43.019675, -20.385567996],
            [-43.019697, -20.385349995999899],
            [-43.019695, -20.385073996],
            [-43.019678999999897, -20.384763995999901],
            [-43.019567, -20.384466996],
            [-43.01943, -20.384203995999901],
            [-43.01921, -20.384079996],
            [-43.019002, -20.383966996],
            [-43.018866, -20.383760996],
            [-43.018718, -20.383578996],
            [-43.018543, -20.3831669959999],
            [-43.018442999999898, -20.382926996],
            [-43.018379, -20.382640996],
            [-43.018327999999897, -20.382376995999898],
            [-43.018289, -20.382159995999899],
            [-43.018211999999899, -20.381780996],
            [-43.018124, -20.381494996],
            [-43.017976, -20.381255995999901],
            [-43.017876, -20.3810269959999],
            [-43.017776, -20.380820996],
            [-43.017688, -20.380522996],
            [-43.017673, -20.3802599959999],
            [-43.017683, -20.3800179959999],
            [-43.017705, -20.379799996],
            [-43.017776, -20.379557996],
            [-43.017773, -20.379293995999902],
            [-43.017746, -20.379053996],
            [-43.017658, -20.378743996],
            [-43.017496999999899, -20.3784589959999],
            [-43.017361, -20.3782419959999],
            [-43.017249, -20.378035995999898],
            [-43.017113, -20.377841996],
            [-43.016953, -20.377636996],
            [-43.016877, -20.377350996],
            [-43.016825, -20.377052996],
            [-43.016762, -20.376834996],
            [-43.016626, -20.376629996],
            [-43.016525999999899, -20.376389995999901],
            [-43.016378, -20.376161996],
            [-43.016278, -20.375955996],
            [-43.016215, -20.375703996],
            [-43.016176, -20.375474996],
            [-43.016112, -20.375233996],
            [-43.015878, -20.374925995999899],
            [-43.015692999999899, -20.374709996],
            [-43.015521, -20.374561996],
            [-43.015361, -20.374402996],
            [-43.015238, -20.374208995999901],
            [-43.015077, -20.374014996],
            [-43.014966, -20.373809996],
            [-43.014792999999898, -20.373546996],
            [-43.014766, -20.373294996],
            [-43.014836, -20.3730189959999],
            [-43.014943, -20.372776996],
            [-43.015172999999898, -20.372659996],
            [-43.01539, -20.3724279959999],
            [-43.015571, -20.372265996],
            [-43.015763999999898, -20.372114995999901],
            [-43.015994, -20.371951996],
            [-43.016212, -20.3718239959999],
            [-43.016429, -20.371683995999899],
            [-43.016647, -20.371566996],
            [-43.016889, -20.371426996],
            [-43.017095, -20.371287996],
            [-43.017398, -20.371135996],
            [-43.017699999999898, -20.370971996],
            [-43.017918, -20.3708559959999],
            [-43.018136, -20.370749996],
            [-43.018379, -20.370655996],
            [-43.018620999999897, -20.370550996],
            [-43.018852, -20.370490995999901],
            [-43.019081, -20.370327996],
            [-43.019372, -20.3701539959999],
            [-43.019688, -20.370081996],
            [-43.019918, -20.370021995999899],
            [-43.020136, -20.369928995999899],
            [-43.020511999999897, -20.3697529959999],
            [-43.020705, -20.369612995999901],
            [-43.020923, -20.369473996],
            [-43.021104, -20.369333996],
            [-43.021296999999898, -20.369171996],
            [-43.021563, -20.368927996],
            [-43.021756, -20.368788996],
            [-43.02201, -20.368636996],
            [-43.02224, -20.368473996],
            [-43.022433, -20.368322996],
            [-43.022650999999897, -20.368217995999899],
            [-43.022893, -20.368089996],
            [-43.02322, -20.367902995999899],
            [-43.023499, -20.367773996],
            [-43.023729, -20.367702995999899],
            [-43.024033, -20.367619996],
            [-43.024262, -20.367479995999901],
            [-43.024492, -20.367328996],
            [-43.024734, -20.367154996],
            [-43.024939, -20.367014996],
            [-43.02512, -20.366829996],
            [-43.025337, -20.366609996],
            [-43.025517, -20.366412996],
            [-43.025551, -20.366170995999902],
            [-43.025512, -20.365918995999898],
            [-43.025437, -20.365689996],
            [-43.025165, -20.365301996],
            [-43.024957, -20.365131995999899],
            [-43.024784, -20.364949996],
            [-43.024588, -20.364802996],
            [-43.024367, -20.3646209959999],
            [-43.024183, -20.364484995999899],
            [-43.024011, -20.364336996],
            [-43.023803, -20.364189995999901],
            [-43.023557, -20.363996996],
            [-43.023348999999897, -20.363895995999901],
            [-43.022933, -20.363623995999902],
            [-43.022724, -20.363475996],
            [-43.022552, -20.363305996],
            [-43.022464, -20.363053996],
            [-43.022534999999898, -20.362834995999901],
            [-43.022667, -20.362649996],
            [-43.022872999999898, -20.362521995999899],
            [-43.023102, -20.362381996],
            [-43.023333, -20.362299995999901],
            [-43.023588, -20.362216996],
            [-43.023817, -20.362042996],
            [-43.02406, -20.3619599959999],
            [-43.024302, -20.361888995999902],
            [-43.024558, -20.361863996],
            [-43.024825, -20.361849995999901],
            [-43.025106, -20.361870995999901],
            [-43.025374, -20.361925996],
            [-43.02563, -20.3619809959999],
            [-43.025911, -20.362046995999901],
            [-43.026179, -20.362066996],
            [-43.026446, -20.361961995999899],
            [-43.026689, -20.361970996],
            [-43.026992999999898, -20.361967996],
            [-43.02737, -20.361929996],
            [-43.027625999999898, -20.361904996],
            [-43.028014, -20.361809996],
            [-43.028245, -20.361795996],
            [-43.028489, -20.361827995999899],
            [-43.028868, -20.361950996],
            [-43.029123, -20.361948996],
            [-43.029367, -20.361934995999899],
            [-43.029683, -20.361908996],
            [-43.029951, -20.361906996],
            [-43.030304, -20.361891995999901],
            [-43.030547, -20.361889995999899],
            [-43.030827, -20.361875996],
            [-43.031082, -20.361780995999901],
            [-43.031201, -20.361573995999901],
            [-43.031284, -20.3613549959999],
            [-43.031391, -20.361146995999899],
            [-43.031388999999898, -20.3609059959999],
            [-43.031410999999899, -20.360675995999902],
            [-43.031555, -20.360502996],
            [-43.031723, -20.360282996],
            [-43.031831, -20.360063996],
            [-43.031963, -20.359867995999899],
            [-43.032082, -20.359671996],
            [-43.032165, -20.359394995999899],
            [-43.032308999999898, -20.359186995999899],
            [-43.032391, -20.358933996],
            [-43.032535, -20.358725996],
            [-43.032654, -20.358471995999899],
            [-43.032761999999899, -20.3582649959999],
            [-43.032881, -20.358056996],
            [-43.032915, -20.357803996],
            [-43.0329, -20.357493996],
            [-43.032848, -20.357230995999899],
            [-43.032809, -20.357001996],
            [-43.032746, -20.356783995999901],
            [-43.032659, -20.3565549959999],
            [-43.032522, -20.356314996],
            [-43.032363, -20.356155995999899],
            [-43.032202, -20.3559739959999],
            [-43.032067, -20.355791995999901],
            [-43.031906999999897, -20.355620996],
            [-43.031722, -20.3554039959999],
            [-43.031513, -20.355256996],
            [-43.031281, -20.355109996],
            [-43.031036, -20.354973996],
            [-43.030815, -20.354815996],
            [-43.030594, -20.354668995999901],
            [-43.030386, -20.354543996],
            [-43.030178, -20.354396995999899],
            [-43.029994, -20.354248996],
            [-43.029906, -20.354020996],
            [-43.029891, -20.353756996],
            [-43.029901, -20.3534929959999],
            [-43.029934, -20.353227995999902],
            [-43.029992999999898, -20.352963996],
            [-43.030037999999898, -20.352664995999898],
            [-43.030048, -20.3524349959999],
            [-43.030057, -20.3521479959999],
            [-43.030067, -20.351894995999899],
            [-43.030099999999898, -20.3515849959999],
            [-43.030134, -20.351354995999898],
            [-43.030167999999897, -20.351113996],
            [-43.030251, -20.350848996],
            [-43.030333, -20.350606996],
            [-43.030526, -20.350386995999902],
            [-43.030658, -20.350201996],
            [-43.03079, -20.349994996],
            [-43.030932999999898, -20.349751996],
            [-43.031015, -20.349429996],
            [-43.031172, -20.349255996],
            [-43.031328, -20.349059995999902],
            [-43.031582, -20.348896996],
            [-43.031775, -20.348756996],
            [-43.031993, -20.348639996],
            [-43.03221, -20.3484779959999],
            [-43.032391, -20.348337995999898],
            [-43.032646, -20.348243996],
            [-43.032938, -20.3481839959999],
            [-43.033181, -20.348146996],
            [-43.033436, -20.3481109959999],
            [-43.033849, -20.348083996],
            [-43.034105, -20.348080996],
            [-43.034349, -20.348101996],
            [-43.034641, -20.348121996],
            [-43.034920999999898, -20.348119996],
            [-43.035177, -20.348140995999898],
            [-43.035761, -20.348134995999899],
            [-43.036052, -20.348074996],
            [-43.036283, -20.347992996],
            [-43.036561999999897, -20.347874996],
            [-43.036779, -20.347757995999899],
            [-43.03701, -20.347652996],
            [-43.037203, -20.347512996],
            [-43.037384, -20.347373995999899],
            [-43.03765, -20.347210995999902],
            [-43.037952, -20.346943995999901],
            [-43.038230999999897, -20.346883996],
            [-43.038424, -20.346744995999899],
            [-43.038702999999899, -20.346603995999899],
            [-43.03892, -20.346463996],
            [-43.039114, -20.346324996],
            [-43.039332, -20.346219995999899],
            [-43.039561999999897, -20.346079996],
            [-43.03978, -20.345996995999901],
            [-43.040058, -20.345833996],
            [-43.040301, -20.3457509959999],
            [-43.040456999999897, -20.345554996],
            [-43.040406, -20.345336996],
            [-43.040246, -20.345177996],
            [-43.040073999999898, -20.345018996],
            [-43.039878, -20.344870995999901],
            [-43.039657, -20.344700996],
            [-43.039473, -20.344564996],
            [-43.039252, -20.344417995999901],
            [-43.03902, -20.3442819959999],
            [-43.038811, -20.344157996],
            [-43.038555, -20.344091996],
            [-43.038299, -20.344093996],
            [-43.038032, -20.3441539959999],
            [-43.037765, -20.344201996],
            [-43.037411, -20.344124995999898],
            [-43.037167, -20.344069996],
            [-43.036898999999899, -20.344014995999899],
            [-43.036594, -20.3439949959999],
            [-43.036289, -20.343916996],
            [-43.036009, -20.343850996],
            [-43.035776999999896, -20.343806996],
            [-43.035472, -20.343740996],
            [-43.035190999999898, -20.3436289959999],
            [-43.034897999999899, -20.343516995999899],
            [-43.034666, -20.343438996],
            [-43.034385, -20.343371996],
            [-43.034179216, -20.3433277849999],
            [-43.034129, -20.343316995999899],
            [-43.033897, -20.343238996],
            [-43.033604, -20.343160995999899],
            [-43.033395, -20.343013996],
            [-43.033308, -20.342807996],
            [-43.033245, -20.342578995999901],
            [-43.033048999999899, -20.342419995999901],
            [-43.032864, -20.342260995999901],
            [-43.03268, -20.342113996],
            [-43.032508, -20.341931996],
            [-43.032457, -20.341701995999902],
            [-43.032454, -20.341460996],
            [-43.032488, -20.341173996],
            [-43.032398999999899, -20.340841995999899],
            [-43.032288, -20.3406359959999],
            [-43.032128, -20.340453996],
            [-43.031883, -20.340364995999899],
            [-43.031628, -20.340389995999899],
            [-43.031398, -20.340494996],
            [-43.031154, -20.340485995999899],
            [-43.030946, -20.340384996],
            [-43.030726, -20.340236995999899],
            [-43.030518, -20.340124995999901],
            [-43.030297, -20.340011995999902],
            [-43.030089, -20.339886996],
            [-43.029831999999899, -20.339762995999902],
            [-43.029576, -20.3396509959999],
            [-43.029343, -20.339549996],
            [-43.028989, -20.339414996],
            [-43.028757, -20.339336995999901],
            [-43.028523999999898, -20.339178995999902],
            [-43.02834, -20.339030996],
            [-43.028156, -20.338871996],
            [-43.027996, -20.338712996],
            [-43.027811, -20.338495995999899],
            [-43.027650999999899, -20.338325996],
            [-43.027345, -20.338155996],
            [-43.027112, -20.338020996],
            [-43.026904, -20.3378499959999],
            [-43.026707, -20.3376909959999],
            [-43.026499, -20.337543996],
            [-43.026303, -20.337418995999901],
            [-43.026119, -20.3372829959999],
            [-43.025971, -20.337077995999898],
            [-43.025676, -20.336850996],
            [-43.025297, -20.336636996],
            [-43.025052, -20.336512996],
            [-43.024783, -20.336388996],
            [-43.024514, -20.336275995999902],
            [-43.024293999999898, -20.336128996],
            [-43.02411, -20.335981995999902],
            [-43.023962, -20.335810996],
            [-43.023752, -20.335525995999902],
            [-43.023640999999898, -20.335331996],
            [-43.023565, -20.335079995999902],
            [-43.023465, -20.334816996],
            [-43.023377, -20.334598996],
            [-43.023302, -20.334381995999902],
            [-43.023226, -20.334083995999901],
            [-43.02309, -20.333866995999902],
            [-43.022954, -20.333684995999899],
            [-43.022672, -20.333445996],
            [-43.022499, -20.333229995999901],
            [-43.022314, -20.333012996],
            [-43.022142, -20.332819996],
            [-43.021982, -20.3326489959999],
            [-43.021834, -20.332443995999899],
            [-43.021601, -20.332284995999899],
            [-43.021356, -20.332114996],
            [-43.020938, -20.331716996],
            [-43.020571, -20.331501995999901],
            [-43.020363, -20.331377996],
            [-43.01963, -20.331120996],
            [-43.018776, -20.330944996],
            [-43.018045, -20.330882996],
            [-43.017752, -20.330815996],
            [-43.017436, -20.330761996],
            [-43.017156, -20.330752995999902],
            [-43.016827, -20.330755995999901],
            [-43.016596, -20.330768996],
            [-43.016243, -20.3307959959999],
            [-43.015635, -20.330800995999901],
            [-43.015391, -20.330756995999899],
            [-43.015158, -20.330621995999898],
            [-43.015059, -20.330404996],
            [-43.015056, -20.330151995999898],
            [-43.015065999999898, -20.3299219959999],
            [-43.015076, -20.329703996],
            [-43.015159, -20.3294969959999],
            [-43.015229, -20.329231996],
            [-43.015262999999898, -20.329001995999899],
            [-43.015225, -20.328783996],
            [-43.015137, -20.328555996],
            [-43.014989, -20.328372995999899],
            [-43.014840999999898, -20.328179996],
            [-43.014645, -20.328020996],
            [-43.014461, -20.327861996],
            [-43.01424, -20.327667995999899],
            [-43.014068, -20.327497996],
            [-43.013869999999898, -20.327258996],
            [-43.013734, -20.327052996],
            [-43.01355, -20.326870996],
            [-43.013426, -20.326653995999902],
            [-43.013302, -20.326402996],
            [-43.013178, -20.326173996],
            [-43.013054, -20.325979995999901],
            [-43.012966999999897, -20.325750996],
            [-43.012769999999897, -20.325569996],
            [-43.012549, -20.325387996],
            [-43.012329, -20.325228996],
            [-43.012071999999897, -20.3251279959999],
            [-43.011608, -20.324994995999901],
            [-43.011315, -20.324904995999901],
            [-43.011072, -20.324918996],
            [-43.010536, -20.3249239959999],
            [-43.010292999999898, -20.3249489959999],
            [-43.010026, -20.324974996],
            [-43.009758, -20.3250109959999],
            [-43.009503, -20.325059995999901],
            [-43.009175, -20.325085995999899],
            [-43.008883, -20.325110996],
            [-43.008543, -20.3251489959999],
            [-43.008238999999897, -20.325162996],
            [-43.007970999999898, -20.325187996],
            [-43.007692, -20.325213995999899],
            [-43.007448, -20.325226996],
            [-43.007157, -20.325298996],
            [-43.006793, -20.325416995999898],
            [-43.006563, -20.325521996],
            [-43.006284, -20.325593996],
            [-43.00603, -20.325733995999901],
            [-43.005786999999899, -20.325792996],
            [-43.005484, -20.325922996],
            [-43.005217, -20.326027995999901],
            [-43.004975, -20.326098995999899],
            [-43.004708, -20.326204995999898],
            [-43.004465, -20.326264996],
            [-43.004235, -20.326312996],
            [-43.004016, -20.326394996],
            [-43.003555999999897, -20.326639996],
            [-43.003338, -20.326733996],
            [-43.003072, -20.326839995999901],
            [-43.002781, -20.327025996],
            [-43.002552, -20.327199995999901],
            [-43.002309, -20.327270996],
            [-43.001933, -20.327331995999899],
            [-43.001592, -20.327392996],
            [-43.001313, -20.327481996],
            [-43.001061, -20.327660996],
            [-43.000743, -20.327742995999898],
            [-43.00056, -20.327836996],
            [-43.000389, -20.327930996],
            [-43.000177999999899, -20.328123995999899],
            [-43.000093, -20.328180996],
            [-42.999933999999897, -20.328375996],
            [-42.999703, -20.328490996],
            [-42.999386, -20.3286399959999],
            [-42.99913, -20.328765996],
            [-42.998849999999898, -20.328915996],
            [-42.998630999999897, -20.3289959959999],
            [-42.99835, -20.329110995999901],
            [-42.998216, -20.329305996],
            [-42.997985, -20.3293859959999],
            [-42.997741, -20.329442996],
            [-42.997485, -20.329511996],
            [-42.997228999999898, -20.329591996],
            [-42.996925, -20.329660996],
            [-42.996643999999897, -20.329694995999901],
            [-42.99634, -20.3297179959999],
            [-42.995755, -20.329740995999899],
            [-42.995402, -20.329785995999899],
            [-42.99517, -20.329820996],
            [-42.994841999999899, -20.329865996],
            [-42.994574, -20.329911996],
            [-42.994318, -20.329911996],
            [-42.99405, -20.329957996],
            [-42.993806, -20.329979996],
            [-42.993561999999898, -20.329979996],
            [-42.993209, -20.330025996],
            [-42.992965, -20.330082995999899],
            [-42.992660999999899, -20.330162996],
            [-42.992429, -20.330254996],
            [-42.992233999999897, -20.330380996],
            [-42.992015, -20.330472996],
            [-42.991759, -20.330610996],
            [-42.991552, -20.330782996],
            [-42.991380999999897, -20.3310239959999],
            [-42.991186, -20.3312079959999],
            [-42.990918, -20.331689996],
            [-42.990795999999897, -20.331896996],
            [-42.990674, -20.332091996],
            [-42.990502999999897, -20.332367996],
            [-42.990405, -20.332574995999899],
            [-42.990283, -20.332769996],
            [-42.990125, -20.3329879959999],
            [-42.989966, -20.333148996],
            [-42.989783, -20.333320995999902],
            [-42.989613, -20.333492996],
            [-42.98943, -20.333664996],
            [-42.989283, -20.333848996],
            [-42.989088, -20.334032996],
            [-42.988601, -20.334422995999901],
            [-42.988405999999898, -20.334548996],
            [-42.988162, -20.334675995999898],
            [-42.987918, -20.334789995999898],
            [-42.987699, -20.334961995999901],
            [-42.987455, -20.335076995999898],
            [-42.987224, -20.335214995999898],
            [-42.986992, -20.3353069959999],
            [-42.986748, -20.335420996],
            [-42.986517, -20.3355239959999],
            [-42.986322, -20.335661995999899],
            [-42.986163, -20.335833996],
            [-42.985968, -20.336052996],
            [-42.985785, -20.336235996],
            [-42.985712, -20.336488996],
            [-42.985663, -20.336775996],
            [-42.985661999999898, -20.337534995999899],
            [-42.985699, -20.3378679959999],
            [-42.985735, -20.338085996],
            [-42.985759, -20.338303996],
            [-42.985794999999897, -20.3386379959999],
            [-42.985844, -20.338890996],
            [-42.985868, -20.339119996],
            [-42.985856, -20.339430996],
            [-42.985855, -20.339728996],
            [-42.985806, -20.339970996],
            [-42.98577, -20.340199996],
            [-42.985708, -20.340441996],
            [-42.985768999999898, -20.340670996],
            [-42.985562, -20.340877996],
            [-42.985391, -20.341061996],
            [-42.98522, -20.341256996],
            [-42.985013, -20.341451995999901],
            [-42.984818, -20.341704996],
            [-42.984659, -20.341933996],
            [-42.984501, -20.342117996],
            [-42.984415, -20.342381996],
            [-42.98433, -20.342600995999899],
            [-42.98422, -20.342852996],
            [-42.984085999999898, -20.343070995999899],
            [-42.983988, -20.343323996],
            [-42.983891, -20.3435419959999],
            [-42.983805, -20.343817995999899],
            [-42.983598, -20.344001996],
            [-42.983378, -20.344184996],
            [-42.983151, -20.344296996]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 31,
        "id": 36942,
        "CD_SETOR": "315210505060028P",
        "AREA_KM2": 0.050692,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 352
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.903091526, -20.41673041],
            [-42.903208080999903, -20.417171141],
            [-42.903269807999898, -20.417379129],
            [-42.903433133, -20.417603589999899],
            [-42.903926199, -20.41785334],
            [-42.905205768, -20.418081512999901],
            [-42.905036203999899, -20.4195699059999],
            [-42.905010354, -20.419831455999901],
            [-42.904997006, -20.4199948209999],
            [-42.90502779, -20.420006106],
            [-42.905065637999897, -20.420031645999899],
            [-42.905093571999899, -20.420071741],
            [-42.905326246999898, -20.41988852],
            [-42.905496688, -20.419754304],
            [-42.905505089, -20.419677712],
            [-42.90551856, -20.419579661999901],
            [-42.905530379, -20.419480762],
            [-42.905593203, -20.418991516999899],
            [-42.905606689, -20.418884418999902],
            [-42.905623106, -20.418758652],
            [-42.905637049999903, -20.418650063],
            [-42.905657429, -20.418491356999901],
            [-42.905672126999903, -20.41842086],
            [-42.905695767999902, -20.418307463],
            [-42.905708045, -20.418172362999901],
            [-42.905753434, -20.418148164999899],
            [-42.905829895999901, -20.4181955029999],
            [-42.905892261, -20.418244993],
            [-42.905967929, -20.418246839999899],
            [-42.905998858, -20.418158128999899],
            [-42.906001696, -20.418027629999902],
            [-42.905981563, -20.417923858999899],
            [-42.905970461, -20.417866631],
            [-42.906025233, -20.417594542],
            [-42.906233653999898, -20.417260196],
            [-42.90630873, -20.417102618999898],
            [-42.906361091, -20.416932027],
            [-42.906270068, -20.4168431209999],
            [-42.906025811999903, -20.4168211809999],
            [-42.905401258, -20.416717245],
            [-42.905171062, -20.416673729],
            [-42.904817835, -20.41669467],
            [-42.904677186999898, -20.416718865999901],
            [-42.903842671999897, -20.4168136239999],
            [-42.903409752999899, -20.416792668],
            [-42.903091526, -20.41673041]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 32,
        "id": 36943,
        "CD_SETOR": "315210505060029P",
        "AREA_KM2": 0.050694,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 316
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.907761697999902, -20.417536846],
            [-42.90779667, -20.4175214589999],
            [-42.907856645, -20.417484615],
            [-42.907926696999901, -20.4174205479999],
            [-42.908063042, -20.417431778],
            [-42.908214223999899, -20.417507124999901],
            [-42.908425489, -20.417662004],
            [-42.908713897, -20.417863452999899],
            [-42.908868223999903, -20.417980047],
            [-42.908953482, -20.418120341],
            [-42.908989548, -20.418252089],
            [-42.909040169, -20.418656099],
            [-42.908929069, -20.418896242999899],
            [-42.908836696, -20.419294094],
            [-42.908860438, -20.419747001],
            [-42.908965754999898, -20.420107717],
            [-42.908990113999899, -20.420326401],
            [-42.909132445, -20.4206158],
            [-42.910242689, -20.420599102],
            [-42.910310466999903, -20.4205461509999],
            [-42.910868364999899, -20.420152247],
            [-42.910680403, -20.419978305],
            [-42.910592273, -20.419833508999901],
            [-42.910575567, -20.419496309],
            [-42.910356457, -20.419473335],
            [-42.910333798999901, -20.419471971999901],
            [-42.909973756999896, -20.419419957999899],
            [-42.909927825999901, -20.419228543],
            [-42.909896918999898, -20.419079433],
            [-42.909846494, -20.418927573],
            [-42.909771077, -20.418773901],
            [-42.909648795, -20.418574276999902],
            [-42.909563599, -20.418407757999901],
            [-42.909516739, -20.41795983],
            [-42.909502464, -20.417823381999899],
            [-42.909462846999901, -20.417749967],
            [-42.909440662, -20.417719297],
            [-42.909114716, -20.417321995999899],
            [-42.908923623, -20.417107449],
            [-42.908877253, -20.417005854],
            [-42.908381995999903, -20.416321325],
            [-42.908263658, -20.416441099999901],
            [-42.908203091, -20.416561564999899],
            [-42.9081183979999, -20.416741083],
            [-42.907909147999902, -20.4171890709999],
            [-42.907798872999898, -20.417449164999901],
            [-42.907761697999902, -20.417536846]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 33,
        "id": 36944,
        "CD_SETOR": "315210505060030P",
        "AREA_KM2": 0.508184,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 264
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.913522144999902, -20.41994482],
            [-42.913424845999899, -20.420324100999899],
            [-42.913414750999898, -20.420386078],
            [-42.913405912, -20.420440345],
            [-42.913365953, -20.420685662],
            [-42.913288775999902, -20.420957455],
            [-42.913182182, -20.421187092],
            [-42.913059750999899, -20.421311182],
            [-42.912869411, -20.421302644],
            [-42.912654742, -20.421206568999899],
            [-42.912526493, -20.421005771999901],
            [-42.912314449, -20.42059606],
            [-42.912080461, -20.420428656],
            [-42.911909400999903, -20.420344207],
            [-42.911753292, -20.420317533999899],
            [-42.91159651, -20.420254309],
            [-42.911344846, -20.420205676],
            [-42.910977615, -20.420202711999899],
            [-42.910868364999899, -20.420152247],
            [-42.910310466999903, -20.4205461509999],
            [-42.910242689, -20.420599102],
            [-42.909132445, -20.4206158],
            [-42.909248931, -20.420944705999901],
            [-42.909163774, -20.4213383919999],
            [-42.909131649, -20.422316077999898],
            [-42.909056407, -20.422842822],
            [-42.9088594, -20.423245809],
            [-42.908526633, -20.423641744999902],
            [-42.908300343999898, -20.423861431],
            [-42.908057289999903, -20.424032714],
            [-42.907755473999899, -20.4241132239999],
            [-42.907529982, -20.424322929],
            [-42.907334359999901, -20.424683586],
            [-42.907343227, -20.425024871999899],
            [-42.907373042, -20.425306615999901],
            [-42.907425450999902, -20.4254318],
            [-42.907508499999899, -20.425627779],
            [-42.907717182999903, -20.425994913999901],
            [-42.907759197, -20.426194254999899],
            [-42.907718578, -20.42645605],
            [-42.90760481, -20.426751964999902],
            [-42.907467987, -20.42693901],
            [-42.907421747999898, -20.427054773],
            [-42.907353337, -20.427226049],
            [-42.907318382, -20.427313560999899],
            [-42.907174843, -20.427744622],
            [-42.907171832, -20.427911408999901],
            [-42.907196363, -20.428125802999901],
            [-42.907362057999897, -20.428076511],
            [-42.907473399, -20.428027942],
            [-42.907584329, -20.428070766],
            [-42.907875766, -20.428188145],
            [-42.908022205999899, -20.428267456],
            [-42.90799455, -20.428795776],
            [-42.908416463, -20.428441973],
            [-42.908987189, -20.427959186],
            [-42.909082666, -20.427852131],
            [-42.909419895, -20.427565174999899],
            [-42.909582348, -20.427368892999901],
            [-42.909598402, -20.427207779],
            [-42.909707608, -20.427106449],
            [-42.910559894, -20.426718074],
            [-42.910758253, -20.426635734999898],
            [-42.910918275, -20.426519097],
            [-42.9112819489999, -20.426012952999901],
            [-42.911572433999901, -20.425591596999901],
            [-42.911896344, -20.425040994],
            [-42.9121818099999, -20.424577160999899],
            [-42.912391935999899, -20.424385347999898],
            [-42.912668601999897, -20.424099560999899],
            [-42.912778364, -20.423954866],
            [-42.912837725, -20.423889383],
            [-42.913270485999902, -20.423891388],
            [-42.913569542999902, -20.423818728],
            [-42.913904514, -20.423186911999899],
            [-42.914103247999897, -20.422996896],
            [-42.913999996, -20.422725048],
            [-42.914176943, -20.422536206],
            [-42.914024559, -20.421751902],
            [-42.914011565, -20.421230722],
            [-42.913986265999903, -20.421093442],
            [-42.914043862, -20.421015263],
            [-42.914082127, -20.420993113],
            [-42.914166308999903, -20.420944383999899],
            [-42.91434158, -20.420926506999901],
            [-42.914482106, -20.420912173],
            [-42.9146116759999, -20.420912954999899],
            [-42.914855741999901, -20.420960138999899],
            [-42.914935363, -20.420985752],
            [-42.915395017, -20.421133616],
            [-42.915589167999897, -20.421052835],
            [-42.915677219, -20.421016199999901],
            [-42.915891802, -20.421102355999899],
            [-42.91595768, -20.421134965],
            [-42.916085505, -20.421253686999901],
            [-42.916247572, -20.421409586],
            [-42.916481274, -20.421636063999902],
            [-42.916682208999902, -20.421753817],
            [-42.916660600999897, -20.421808847],
            [-42.916670842, -20.421931050999898],
            [-42.916638256999903, -20.421973468],
            [-42.916632757999899, -20.422036601999899],
            [-42.916587008, -20.422100545],
            [-42.916539249, -20.4221781319999],
            [-42.916484211, -20.422214428],
            [-42.916447526, -20.422265357999901],
            [-42.916427551, -20.42235026],
            [-42.916467559, -20.422521779],
            [-42.916804339999899, -20.422813451],
            [-42.917085782, -20.422928068],
            [-42.917601647, -20.423048175],
            [-42.918027799999898, -20.423183423999902],
            [-42.918476242999901, -20.423302658999901],
            [-42.918680824, -20.423262824999899],
            [-42.918756609, -20.423229681],
            [-42.918885192999902, -20.4234942269999],
            [-42.918922607999903, -20.423626285],
            [-42.918966377999901, -20.423756984],
            [-42.918955063999903, -20.423911706],
            [-42.918865191, -20.424018507],
            [-42.918747862, -20.4240932129999],
            [-42.918566294, -20.424218534],
            [-42.918499401, -20.424269769],
            [-42.918449893999899, -20.424388913],
            [-42.918419984, -20.424519654999902],
            [-42.918400246, -20.424646322999902],
            [-42.918389939999898, -20.424772865],
            [-42.918382225999899, -20.424963106],
            [-42.918410465, -20.425220397],
            [-42.918507028, -20.42549609],
            [-42.9200134989999, -20.425119777],
            [-42.920185913999902, -20.425081651],
            [-42.920139895, -20.42436728],
            [-42.920133537, -20.423787214],
            [-42.920158349, -20.423087236999901],
            [-42.920163814, -20.422655442],
            [-42.9201202, -20.422419939],
            [-42.919990379999902, -20.422159096],
            [-42.91974027, -20.422081247999898],
            [-42.919422217, -20.422196009999901],
            [-42.918641293, -20.422144218],
            [-42.918242109999902, -20.421952185],
            [-42.917892762999898, -20.42163999],
            [-42.91770014, -20.421336773],
            [-42.91716364, -20.421078946],
            [-42.916825414999899, -20.420820852],
            [-42.916591312, -20.420546993999899],
            [-42.91639592, -20.420293033],
            [-42.916222086999902, -20.420084458],
            [-42.916180424999901, -20.419661076999901],
            [-42.916074357999896, -20.419458801],
            [-42.91595326, -20.419381205],
            [-42.915728090999899, -20.419366356],
            [-42.915377134, -20.419450249],
            [-42.914879028, -20.4197527749999],
            [-42.914740907, -20.419782585],
            [-42.914544690999897, -20.419726135],
            [-42.914272125, -20.41949678],
            [-42.914074953, -20.419459862999901],
            [-42.913837822999902, -20.4195485239999],
            [-42.91355326, -20.4198008],
            [-42.913522144999902, -20.41994482]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 34,
        "id": 36945,
        "CD_SETOR": "315210505060032P",
        "AREA_KM2": 0.011707,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 387
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.910840155, -20.415074481],
            [-42.910830521, -20.415124771],
            [-42.910814242, -20.4152097539999],
            [-42.9108197689999, -20.415321218],
            [-42.911147442999898, -20.41521707],
            [-42.91129599, -20.414799936999898],
            [-42.911354741, -20.414653609],
            [-42.911411589999901, -20.4145720339999],
            [-42.911685749, -20.414281896],
            [-42.911827875, -20.414165833],
            [-42.912261934, -20.414143920999901],
            [-42.912502447999898, -20.414152983],
            [-42.913106652, -20.414133262],
            [-42.913476504, -20.414127559999901],
            [-42.913762957, -20.4141140999999],
            [-42.914319102, -20.414082632],
            [-42.914646332, -20.414074663999902],
            [-42.914916360999896, -20.414065061],
            [-42.914958438, -20.4140587969999],
            [-42.914622977, -20.414035409999901],
            [-42.914497401999903, -20.414033693],
            [-42.914285629999902, -20.413981657],
            [-42.9139496929999, -20.413956599999899],
            [-42.913808342, -20.413968229],
            [-42.913657314, -20.414019197],
            [-42.913562466, -20.4140336409999],
            [-42.913461115, -20.414032998],
            [-42.913320702, -20.414026669],
            [-42.913135498, -20.414002434999901],
            [-42.912959412, -20.414018132],
            [-42.912705499, -20.414049591],
            [-42.9126048689999, -20.414046164],
            [-42.912473238, -20.414038795],
            [-42.912109831, -20.413961647],
            [-42.911908065, -20.4139207119999],
            [-42.911628306999901, -20.413875542],
            [-42.911526513, -20.413895086],
            [-42.911207112999897, -20.414053228999901],
            [-42.911084190999901, -20.414221305],
            [-42.910975123, -20.414466906],
            [-42.910915062999898, -20.414641498],
            [-42.910840155, -20.415074481]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 35,
        "id": 36946,
        "CD_SETOR": "315210505060034P",
        "AREA_KM2": 0.07809,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 25
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.917234493, -20.418830672],
            [-42.916987457999902, -20.419012933],
            [-42.916777516, -20.419026629],
            [-42.916622179999898, -20.4189482099999],
            [-42.916298205, -20.418834696],
            [-42.916080905999898, -20.4188086079999],
            [-42.915704443, -20.418830333],
            [-42.915368425999901, -20.41888248],
            [-42.915136677, -20.418936550999899],
            [-42.914967295999901, -20.418942776999899],
            [-42.914790697999898, -20.418874286999898],
            [-42.914576997, -20.418758099999899],
            [-42.914430100999901, -20.418560616],
            [-42.914308009, -20.418356827],
            [-42.914191741, -20.418112553999901],
            [-42.913926006999901, -20.418242242],
            [-42.9137090689999, -20.4185642909999],
            [-42.913300121, -20.418829065],
            [-42.913182537999901, -20.41885838],
            [-42.912953708, -20.418882873],
            [-42.912517522, -20.419366282999899],
            [-42.912330192, -20.419547848],
            [-42.912475922999903, -20.419710245],
            [-42.91263359, -20.41975566],
            [-42.912792830999898, -20.419669616],
            [-42.912898511, -20.419744108],
            [-42.913069359, -20.419823865999899],
            [-42.913522144999902, -20.41994482],
            [-42.91355326, -20.4198008],
            [-42.913837822999902, -20.4195485239999],
            [-42.914074953, -20.419459862999901],
            [-42.914272125, -20.41949678],
            [-42.914544690999897, -20.419726135],
            [-42.914740907, -20.419782585],
            [-42.914879028, -20.4197527749999],
            [-42.915377134, -20.419450249],
            [-42.915728090999899, -20.419366356],
            [-42.91595326, -20.419381205],
            [-42.916074357999896, -20.419458801],
            [-42.916180424999901, -20.419661076999901],
            [-42.916222086999902, -20.420084458],
            [-42.91639592, -20.420293033],
            [-42.916591312, -20.420546993999899],
            [-42.916830656999899, -20.420355437999898],
            [-42.917046676, -20.420003467],
            [-42.9171626629999, -20.4198795259999],
            [-42.917369807999897, -20.419757187],
            [-42.917443252999902, -20.419703540999901],
            [-42.917372954, -20.419618532],
            [-42.917207689, -20.4195218999999],
            [-42.917569479999898, -20.419237838],
            [-42.917925924, -20.419000862],
            [-42.918024858, -20.418953857],
            [-42.918063075, -20.418965016999898],
            [-42.918109398999903, -20.418969627],
            [-42.918179177999903, -20.418977374999901],
            [-42.918229955, -20.4189758109999],
            [-42.918286560999903, -20.418959932999901],
            [-42.918308968, -20.418937978],
            [-42.918393895, -20.418793596999901],
            [-42.918462804999898, -20.418676615],
            [-42.918544853, -20.418557846999899],
            [-42.91856913, -20.4184703079999],
            [-42.91860435, -20.418338572],
            [-42.918623948999901, -20.418262381],
            [-42.918669082999898, -20.418169501],
            [-42.918791589, -20.418078625],
            [-42.918936738, -20.4180152869999],
            [-42.919066825999899, -20.4179967809999],
            [-42.919256034999897, -20.417992711999901],
            [-42.91936284, -20.418014416999899],
            [-42.919361701, -20.41801241],
            [-42.91934284, -20.417979166],
            [-42.919116024999902, -20.417620008],
            [-42.919038955, -20.417498512999899],
            [-42.918893808, -20.417378861],
            [-42.918589035, -20.417168994999901],
            [-42.918366370999898, -20.417019942],
            [-42.918164870999902, -20.416963294],
            [-42.918018006999901, -20.417030876],
            [-42.917950679999898, -20.417260539],
            [-42.917943240999897, -20.417628432],
            [-42.917942395, -20.417890237999899],
            [-42.917833669999901, -20.418205440999898],
            [-42.917626283999901, -20.418484960999901],
            [-42.917234493, -20.418830672]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 36,
        "id": 36947,
        "CD_SETOR": "315210505060035P",
        "AREA_KM2": 0.2077,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 74
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.917331823, -20.413525397999901],
            [-42.9168272799999, -20.413651967],
            [-42.915546284999898, -20.413985822],
            [-42.915340941999901, -20.414033689999901],
            [-42.915049193, -20.414092173],
            [-42.914738367, -20.414130323999899],
            [-42.914397298, -20.4141643839999],
            [-42.914165633, -20.414186254999901],
            [-42.913708077, -20.414234314999899],
            [-42.91332605, -20.414243988],
            [-42.912958905, -20.4142683329999],
            [-42.912492852, -20.414334219999901],
            [-42.912309636, -20.414425621],
            [-42.912155175999899, -20.414562365999899],
            [-42.912036547, -20.414688509],
            [-42.911965938, -20.414823261],
            [-42.911933469, -20.4150454749999],
            [-42.91194659, -20.415282928],
            [-42.912035153, -20.415514549],
            [-42.912099853, -20.41565614],
            [-42.912349645999903, -20.416175485999901],
            [-42.91254251, -20.416603321],
            [-42.912693927, -20.4170563549999],
            [-42.912729106, -20.417291677],
            [-42.912754887999903, -20.417464142],
            [-42.912824907999898, -20.417436008],
            [-42.912894931, -20.417285561],
            [-42.912897164999897, -20.417216981],
            [-42.912902057999901, -20.416863063999902],
            [-42.912879174999901, -20.416440026],
            [-42.912859609, -20.415707144],
            [-42.912863756, -20.415430171],
            [-42.912978591, -20.415269754999901],
            [-42.913264275, -20.41508418],
            [-42.913597584999899, -20.41493229],
            [-42.914032802999898, -20.414860094999899],
            [-42.914406353999901, -20.414836899],
            [-42.914804726999897, -20.414894816],
            [-42.915211999999897, -20.414883679],
            [-42.915561355, -20.41486685],
            [-42.915986797, -20.414839175999901],
            [-42.916391643999901, -20.414855963],
            [-42.916587622, -20.415179274],
            [-42.916781597, -20.415578641],
            [-42.916901459, -20.415953176999899],
            [-42.916870372999902, -20.416308904999902],
            [-42.916782105, -20.416581178999898],
            [-42.916721763999902, -20.416759471],
            [-42.916662516, -20.417109959],
            [-42.91668963, -20.417380116999901],
            [-42.916807934, -20.417751930999898],
            [-42.917025899999899, -20.418261281],
            [-42.9172075769999, -20.418763258999899],
            [-42.917234493, -20.418830672],
            [-42.917626283999901, -20.418484960999901],
            [-42.917833669999901, -20.418205440999898],
            [-42.917942395, -20.417890237999899],
            [-42.917943240999897, -20.417628432],
            [-42.917950679999898, -20.417260539],
            [-42.918018006999901, -20.417030876],
            [-42.918164870999902, -20.416963294],
            [-42.918366370999898, -20.417019942],
            [-42.918589035, -20.417168994999901],
            [-42.918893808, -20.417378861],
            [-42.919038955, -20.417498512999899],
            [-42.919116024999902, -20.417620008],
            [-42.91934284, -20.417979166],
            [-42.919361701, -20.41801241],
            [-42.919497102, -20.417963571],
            [-42.919634596, -20.4179197029999],
            [-42.919723073999897, -20.417858973],
            [-42.919801004, -20.417763543],
            [-42.919840737999898, -20.41765964],
            [-42.919906168999901, -20.4175895999999],
            [-42.920137994999898, -20.417431957],
            [-42.920227777, -20.417386168],
            [-42.920309045, -20.417324884],
            [-42.920404194999897, -20.417237535],
            [-42.920444792, -20.417200266999899],
            [-42.920520468999896, -20.417071407],
            [-42.920592041, -20.4169060249999],
            [-42.92059396, -20.416901825],
            [-42.920577588, -20.416888129],
            [-42.920296481, -20.4163223629999],
            [-42.92022947, -20.416218092],
            [-42.920116504, -20.416042316],
            [-42.9195641479999, -20.414980779],
            [-42.919295027, -20.414443682],
            [-42.919150237999901, -20.414233550999899],
            [-42.919039514, -20.414104739],
            [-42.9188871489999, -20.4139600469999],
            [-42.918665204, -20.413814511999899],
            [-42.918437109, -20.413674252],
            [-42.918306022, -20.413620520999899],
            [-42.918028432, -20.4135483539999],
            [-42.917686621, -20.413520177999899],
            [-42.917481388999903, -20.413527288999902],
            [-42.917331823, -20.413525397999901]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 37,
        "id": 36948,
        "CD_SETOR": "315210505060036P",
        "AREA_KM2": 0.045466,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 90
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.916591312, -20.420546993999899],
            [-42.916825414999899, -20.420820852],
            [-42.91716364, -20.421078946],
            [-42.91770014, -20.421336773],
            [-42.917702552, -20.421235479],
            [-42.917756074, -20.421197283],
            [-42.918074921, -20.421171785999899],
            [-42.918234807, -20.421141575],
            [-42.918317398999903, -20.421092293],
            [-42.918425899999903, -20.420967095],
            [-42.918512968, -20.420826853999898],
            [-42.918558172, -20.420728220999901],
            [-42.918651778, -20.420643804999902],
            [-42.918820061, -20.420403066999899],
            [-42.918867366, -20.4202683419999],
            [-42.918931761, -20.4200337259999],
            [-42.918929466, -20.4198986769999],
            [-42.918856049999903, -20.419883644],
            [-42.918807992, -20.419868884],
            [-42.918719565999901, -20.419790174],
            [-42.918674779, -20.419711279999898],
            [-42.918668173999897, -20.419635392],
            [-42.918669195999897, -20.419538836999902],
            [-42.91873372, -20.4193839829999],
            [-42.9188592369999, -20.419105090999899],
            [-42.918908739, -20.418855043],
            [-42.918972774999901, -20.4184974799999],
            [-42.918982921, -20.418440387],
            [-42.919046082, -20.418238044],
            [-42.919085824, -20.418195388999901],
            [-42.919165348, -20.418110038999899],
            [-42.919199616, -20.418092913],
            [-42.919263811999897, -20.418060829],
            [-42.919327848, -20.418028826],
            [-42.91936284, -20.418014416999899],
            [-42.919256034999897, -20.417992711999901],
            [-42.919066825999899, -20.4179967809999],
            [-42.918936738, -20.4180152869999],
            [-42.918791589, -20.418078625],
            [-42.918669082999898, -20.418169501],
            [-42.918623948999901, -20.418262381],
            [-42.91860435, -20.418338572],
            [-42.91856913, -20.4184703079999],
            [-42.918544853, -20.418557846999899],
            [-42.918462804999898, -20.418676615],
            [-42.918393895, -20.418793596999901],
            [-42.918308968, -20.418937978],
            [-42.918286560999903, -20.418959932999901],
            [-42.918229955, -20.4189758109999],
            [-42.918179177999903, -20.418977374999901],
            [-42.918109398999903, -20.418969627],
            [-42.918063075, -20.418965016999898],
            [-42.918024858, -20.418953857],
            [-42.917925924, -20.419000862],
            [-42.917569479999898, -20.419237838],
            [-42.917207689, -20.4195218999999],
            [-42.917372954, -20.419618532],
            [-42.917443252999902, -20.419703540999901],
            [-42.917369807999897, -20.419757187],
            [-42.9171626629999, -20.4198795259999],
            [-42.917046676, -20.420003467],
            [-42.916830656999899, -20.420355437999898],
            [-42.916591312, -20.420546993999899]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 38,
        "id": 36949,
        "CD_SETOR": "315210505060038P",
        "AREA_KM2": 0.762628,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 129
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.922536421, -20.406281077],
            [-42.922527036, -20.406354490999899],
            [-42.922539962, -20.4064715059999],
            [-42.922582411, -20.406573266],
            [-42.922836929, -20.406977981],
            [-42.923052739999903, -20.4074500729999],
            [-42.923230963, -20.407980337],
            [-42.923668756, -20.408771069999901],
            [-42.924101524, -20.409181451999899],
            [-42.924625433, -20.409793335],
            [-42.925077987, -20.410503043999899],
            [-42.925326176999903, -20.411794194],
            [-42.924715039, -20.4126322289999],
            [-42.9244350499999, -20.413080683],
            [-42.924507979999902, -20.413399662],
            [-42.924629142999898, -20.413932370999898],
            [-42.92466509, -20.414473170999901],
            [-42.924691095, -20.4149335349999],
            [-42.924699178, -20.415118891],
            [-42.924751752, -20.415431487],
            [-42.924716665999902, -20.415648905999898],
            [-42.924607852999898, -20.415891584999901],
            [-42.924243863999898, -20.416514466],
            [-42.924027377999899, -20.416845822],
            [-42.923860206, -20.417099632],
            [-42.923707396999902, -20.417223543],
            [-42.923431706, -20.417284685],
            [-42.923177385, -20.417273017],
            [-42.922685005999902, -20.417232844],
            [-42.922409899999899, -20.417242132999899],
            [-42.921839999, -20.416997829],
            [-42.921721955999899, -20.416976981999898],
            [-42.921346446, -20.417166348],
            [-42.921001921, -20.417243097],
            [-42.921111432, -20.4173320089999],
            [-42.922211784, -20.418159854999899],
            [-42.923015146, -20.418752675],
            [-42.9234219269999, -20.418956170999898],
            [-42.924093997999897, -20.4191498],
            [-42.924201487999902, -20.419174616],
            [-42.9246262569999, -20.419201191],
            [-42.9256236149999, -20.419227276999901],
            [-42.925993644, -20.419230942],
            [-42.926762821, -20.419254587999902],
            [-42.927384725, -20.419274704],
            [-42.927967974999902, -20.419286142],
            [-42.928200911, -20.419276394999901],
            [-42.928425018, -20.419211343],
            [-42.9283423259999, -20.419195667999901],
            [-42.928060414, -20.419003717],
            [-42.9277258609999, -20.4185084269999],
            [-42.927513088999902, -20.418058078999898],
            [-42.927319779, -20.4175178899999],
            [-42.927346338999897, -20.416949326999902],
            [-42.927726148, -20.415879262],
            [-42.928011679, -20.415316778],
            [-42.928629464, -20.413738372999902],
            [-42.929344334999897, -20.4119869469999],
            [-42.9297144, -20.411436738],
            [-42.929985003, -20.411237296],
            [-42.930533322, -20.410954058999899],
            [-42.930815464, -20.410933773999901],
            [-42.931053016, -20.410963536999901],
            [-42.931365694, -20.411040141999901],
            [-42.93205338, -20.410632683],
            [-42.932044845, -20.410246032],
            [-42.931984331, -20.409235242],
            [-42.931986386999903, -20.408553938],
            [-42.931754124, -20.4082273839999],
            [-42.93144411, -20.407553207],
            [-42.931296346, -20.4076827979999],
            [-42.9310686009999, -20.407827703999899],
            [-42.930846389, -20.407929535999902],
            [-42.930507534999897, -20.407998253999899],
            [-42.930341283, -20.408021421999901],
            [-42.930080772999901, -20.408004478],
            [-42.929732687999902, -20.407919039],
            [-42.929258508, -20.407743791],
            [-42.927956083, -20.407275599999899],
            [-42.926223529, -20.406643143999901],
            [-42.925410686, -20.40630551],
            [-42.925197974, -20.406214372],
            [-42.924961116, -20.406121363],
            [-42.924803376999897, -20.406081469],
            [-42.924628065, -20.406055782],
            [-42.924412483, -20.406071297],
            [-42.924217978999899, -20.406116293],
            [-42.9240422519999, -20.406176869],
            [-42.923747891999902, -20.406312204],
            [-42.923501831, -20.4063633],
            [-42.92335258, -20.406394292999899],
            [-42.923106343, -20.406404849],
            [-42.922888551, -20.406389545],
            [-42.9225806379999, -20.406302937],
            [-42.922536421, -20.406281077]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 39,
        "id": 36950,
        "CD_SETOR": "315210505060039P",
        "AREA_KM2": 0.132843,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 182
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.920845921, -20.414609504999898],
            [-42.92084971, -20.414735505],
            [-42.920789429, -20.415102588],
            [-42.920758835, -20.415748134],
            [-42.920740468, -20.415875394999901],
            [-42.92072947, -20.415951595],
            [-42.920735180999898, -20.416040273],
            [-42.920740563, -20.41612385],
            [-42.920744720999899, -20.416188415],
            [-42.920650755999901, -20.416288182],
            [-42.920552578, -20.416392422999898],
            [-42.920296481, -20.4163223629999],
            [-42.920577588, -20.416888129],
            [-42.92059396, -20.416901825],
            [-42.921001921, -20.417243097],
            [-42.921346446, -20.417166348],
            [-42.921721955999899, -20.416976981999898],
            [-42.921839999, -20.416997829],
            [-42.922409899999899, -20.417242132999899],
            [-42.922685005999902, -20.417232844],
            [-42.923177385, -20.417273017],
            [-42.923431706, -20.417284685],
            [-42.923707396999902, -20.417223543],
            [-42.923860206, -20.417099632],
            [-42.924027377999899, -20.416845822],
            [-42.924243863999898, -20.416514466],
            [-42.924607852999898, -20.415891584999901],
            [-42.924716665999902, -20.415648905999898],
            [-42.924751752, -20.415431487],
            [-42.924699178, -20.415118891],
            [-42.924691095, -20.4149335349999],
            [-42.92466509, -20.414473170999901],
            [-42.924629142999898, -20.413932370999898],
            [-42.924507979999902, -20.413399662],
            [-42.9244350499999, -20.413080683],
            [-42.923732276, -20.413035705],
            [-42.923498934999898, -20.413092467],
            [-42.923391071, -20.413118706],
            [-42.923292005999897, -20.413146531999899],
            [-42.923374807, -20.4133884039999],
            [-42.923532441, -20.413748878],
            [-42.923445853, -20.414235096],
            [-42.923422382, -20.414273389],
            [-42.922692815999902, -20.414455916],
            [-42.921984465, -20.414637287999899],
            [-42.921305748, -20.414823989999899],
            [-42.921008313, -20.414838652999901],
            [-42.920967374, -20.414823495],
            [-42.920845921, -20.414609504999898]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 40,
        "id": 36951,
        "CD_SETOR": "315210505060041P",
        "AREA_KM2": 0.192167,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 39
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.915609001999897, -20.410326158],
            [-42.915531245, -20.410407043],
            [-42.91538628, -20.410597943],
            [-42.914963181, -20.411162018],
            [-42.914710515, -20.4115049719999],
            [-42.914473111999897, -20.4118017],
            [-42.914269222, -20.411946755],
            [-42.914005480999897, -20.412258127],
            [-42.913988111, -20.412623738],
            [-42.913874629, -20.413216447],
            [-42.914574876, -20.413366944],
            [-42.914700153999902, -20.413634073],
            [-42.914809665999897, -20.4137685809999],
            [-42.914888323, -20.413865578],
            [-42.914938728, -20.414011435],
            [-42.915049193, -20.414092173],
            [-42.915340941999901, -20.414033689999901],
            [-42.915546284999898, -20.413985822],
            [-42.9168272799999, -20.413651967],
            [-42.917331823, -20.413525397999901],
            [-42.917460564999899, -20.413373367],
            [-42.917672406, -20.413102260999899],
            [-42.917815243999897, -20.412880002999898],
            [-42.917963538, -20.412592178],
            [-42.918144923999897, -20.412173313],
            [-42.918300062, -20.4118525539999],
            [-42.918528138999903, -20.411528119],
            [-42.918728815999899, -20.411291352],
            [-42.918928315999899, -20.411087473],
            [-42.919164529999897, -20.410901968],
            [-42.919410284, -20.41075833],
            [-42.919659232, -20.4101355019999],
            [-42.919640618, -20.410053474999899],
            [-42.919619669, -20.409912698],
            [-42.919602915, -20.409778968999898],
            [-42.919559664, -20.409586039],
            [-42.919421891999903, -20.409309783],
            [-42.91933276, -20.409147601],
            [-42.919191879, -20.408976942],
            [-42.918976267999902, -20.408848699],
            [-42.918826642999903, -20.408774010999899],
            [-42.918516928, -20.408717635999899],
            [-42.918389519999899, -20.408701166999901],
            [-42.918213614, -20.4087341809999],
            [-42.917982491, -20.408787854],
            [-42.917729252, -20.408878495],
            [-42.917593452999903, -20.408935648999901],
            [-42.917463827999903, -20.409021792],
            [-42.917199557999901, -20.409371913],
            [-42.917068918, -20.409586497],
            [-42.916821130999899, -20.409989757999899],
            [-42.91668634, -20.41019477],
            [-42.916564419999901, -20.410345748999902],
            [-42.916459863999897, -20.410448787],
            [-42.916363063999903, -20.410508137],
            [-42.916255313, -20.410550579999899],
            [-42.916141044, -20.4105507199999],
            [-42.916005737, -20.410529446999899],
            [-42.915784206999902, -20.410450092],
            [-42.915675409999899, -20.410384598999901],
            [-42.915609001999897, -20.410326158]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 41,
        "id": 36952,
        "CD_SETOR": "315210505060042P",
        "AREA_KM2": 0.145718,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 52
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.905324793, -20.413634413999901],
            [-42.905272574, -20.413744574],
            [-42.905157691999896, -20.413921560999899],
            [-42.90518794, -20.4140345009999],
            [-42.905387488, -20.414313992],
            [-42.905393812999897, -20.414323919],
            [-42.9058723159999, -20.414109956999901],
            [-42.906158741, -20.414030405999899],
            [-42.906536183999897, -20.413999272],
            [-42.906846843, -20.4140495],
            [-42.907214841, -20.414239237999901],
            [-42.907655709, -20.414482397],
            [-42.908026146999902, -20.414678196999901],
            [-42.908436880999901, -20.414827859],
            [-42.909115496, -20.414992759],
            [-42.909521240999901, -20.4150750109999],
            [-42.909754953999901, -20.4151216929999],
            [-42.910061842999902, -20.415245558],
            [-42.910419206, -20.414915101],
            [-42.910518345, -20.414867202],
            [-42.910468683, -20.414842597],
            [-42.910385114, -20.414662861],
            [-42.910102327999901, -20.414321774],
            [-42.909648335, -20.413438202],
            [-42.909478803, -20.413022198],
            [-42.908906029, -20.412408522],
            [-42.908541367999902, -20.412080839999899],
            [-42.908196646, -20.411925363],
            [-42.907795758, -20.411747735999899],
            [-42.907287021999899, -20.411516876999901],
            [-42.907173122, -20.411348775],
            [-42.906972179, -20.411000762],
            [-42.906926164, -20.410821449],
            [-42.906655726999901, -20.410983734],
            [-42.906300518, -20.411111841],
            [-42.905852108, -20.411231476999902],
            [-42.905642235999899, -20.411448681],
            [-42.905482702, -20.411746471999901],
            [-42.905387081999898, -20.412040498],
            [-42.905290532, -20.412438864],
            [-42.905289361, -20.413563369],
            [-42.905279799, -20.413604486999901],
            [-42.905324793, -20.413634413999901]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 42,
        "id": 36953,
        "CD_SETOR": "315210505060043P",
        "AREA_KM2": 0.017285,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 7
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.901042646999898, -20.407271189],
            [-42.901046556, -20.407378698999899],
            [-42.900953709, -20.4077268889999],
            [-42.900444533, -20.408488492999901],
            [-42.9001322, -20.408989059],
            [-42.900107453, -20.40904051],
            [-42.900034898999898, -20.409168795],
            [-42.899937891, -20.409472616999899],
            [-42.899920404999897, -20.409905680999898],
            [-42.899873944, -20.410277397],
            [-42.900020786, -20.41033544],
            [-42.900314195, -20.410453146999899],
            [-42.9004931919999, -20.4105548369999],
            [-42.900558299, -20.41064252],
            [-42.900654319999902, -20.410780832999901],
            [-42.900699946, -20.410863446],
            [-42.900736534, -20.410982557],
            [-42.900751231999898, -20.411123688],
            [-42.900760880999897, -20.4112163419999],
            [-42.90076604, -20.411471855999899],
            [-42.900767553, -20.411742913],
            [-42.900767527, -20.411868189],
            [-42.900793942999897, -20.412009872999899],
            [-42.900796686, -20.412143318999899],
            [-42.900828796, -20.412143226],
            [-42.900831223, -20.4120557379999],
            [-42.900819758999901, -20.411622526],
            [-42.900827266, -20.411212985],
            [-42.900815499, -20.410961712],
            [-42.900815023999897, -20.410806081],
            [-42.90080598, -20.4106195049999],
            [-42.900773885, -20.410535165999899],
            [-42.900754563, -20.410451902],
            [-42.900749993, -20.410403323],
            [-42.900669939999901, -20.410115658999899],
            [-42.900598430999899, -20.409954171],
            [-42.900507314, -20.409714645],
            [-42.900495309, -20.4096139399999],
            [-42.900531144, -20.409480301],
            [-42.9005696689999, -20.409344892],
            [-42.900651352999901, -20.409313563],
            [-42.90055589, -20.40921893],
            [-42.900537772, -20.409171634],
            [-42.900556032999901, -20.408976304],
            [-42.9006356209999, -20.4087536229999],
            [-42.900670765, -20.408641503999899],
            [-42.900737224, -20.40853548],
            [-42.90091647, -20.408255138],
            [-42.900968712, -20.408097284],
            [-42.900987143, -20.4079907039999],
            [-42.901012835, -20.407886859],
            [-42.901068209, -20.407545956],
            [-42.901097845, -20.407237762],
            [-42.901089641999903, -20.4070272129999],
            [-42.901115556, -20.406952209999901],
            [-42.901081018, -20.406979532],
            [-42.901049723, -20.4070042879999],
            [-42.901049052, -20.407038929],
            [-42.901048171, -20.407084405],
            [-42.901042646999898, -20.407271189]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 43,
        "id": 36954,
        "CD_SETOR": "315210505060045P",
        "AREA_KM2": 0.093286,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 247
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.90051741, -20.415080072],
            [-42.900704978, -20.41504975],
            [-42.90075567, -20.4150860079999],
            [-42.900760143999896, -20.415087555],
            [-42.900852813, -20.41511958],
            [-42.90086903, -20.415238512],
            [-42.900989545999899, -20.415318849999899],
            [-42.901107012, -20.415357475999901],
            [-42.901248500999898, -20.41550412],
            [-42.901516801999897, -20.415808382],
            [-42.901792437, -20.4159316299999],
            [-42.902122643, -20.415952726999901],
            [-42.902352354, -20.416003635],
            [-42.902682008999903, -20.415967242],
            [-42.9027837679999, -20.4161603159999],
            [-42.903516189, -20.416227689],
            [-42.903792672999899, -20.416230379999899],
            [-42.904036081, -20.416209879],
            [-42.904289579, -20.416194936999901],
            [-42.904555441999896, -20.416156432],
            [-42.904753113, -20.41611548],
            [-42.904940408999899, -20.416072992],
            [-42.905274482, -20.416005324],
            [-42.905522545999901, -20.415781102],
            [-42.905724989, -20.415709747999902],
            [-42.905777777, -20.415700714],
            [-42.905845857, -20.415698764],
            [-42.905960353999902, -20.415681709],
            [-42.90613496, -20.4157241519999],
            [-42.906202277, -20.41579037],
            [-42.906516155, -20.41549313],
            [-42.906102614, -20.415196426],
            [-42.905837057, -20.4149008539999],
            [-42.905690568999901, -20.414741101999901],
            [-42.905658992, -20.414696709999902],
            [-42.905393812999897, -20.414323919],
            [-42.905387488, -20.414313992],
            [-42.905317979, -20.414359988999902],
            [-42.905223173, -20.414402811999899],
            [-42.904888312, -20.414599315],
            [-42.904381498, -20.414855874999901],
            [-42.903847857, -20.415019458],
            [-42.903072099, -20.415033892999901],
            [-42.902535477999898, -20.414928910999901],
            [-42.902037304, -20.414720353],
            [-42.901728895, -20.4144838889999],
            [-42.901409123999898, -20.414340072999899],
            [-42.900922625, -20.414185018999898],
            [-42.900385018, -20.413802728],
            [-42.8999425599999, -20.4132661159999],
            [-42.899772109, -20.413049228],
            [-42.8995072499999, -20.413295369],
            [-42.899404161999897, -20.413378628],
            [-42.899323231, -20.413443394],
            [-42.899251927, -20.413495659],
            [-42.899214711, -20.413562763999899],
            [-42.899201843, -20.413640947],
            [-42.899224419999904, -20.413782404],
            [-42.899314279, -20.413870546999899],
            [-42.899538756, -20.414065093],
            [-42.899700541999898, -20.41416179],
            [-42.899931723999899, -20.414325468],
            [-42.900385878999899, -20.414572344],
            [-42.900438638, -20.414673808],
            [-42.900469223999899, -20.4148571119999],
            [-42.900503635999897, -20.415012625],
            [-42.90051741, -20.415080072]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 44,
        "id": 36955,
        "CD_SETOR": "315210505060046P",
        "AREA_KM2": 0.013002,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 33
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.903126175, -20.422833257],
            [-42.903201753, -20.422816398999899],
            [-42.903327899, -20.422826429999901],
            [-42.903494222999903, -20.422865338],
            [-42.903692908, -20.422916802],
            [-42.903827256999897, -20.422964377],
            [-42.903872427, -20.422922098],
            [-42.903916334, -20.422860891],
            [-42.90412823, -20.4225716609999],
            [-42.904378873, -20.422256881],
            [-42.904573453999902, -20.422020118],
            [-42.90440505, -20.4220182399999],
            [-42.904304367999899, -20.421978179],
            [-42.904204439, -20.42190864],
            [-42.904123822, -20.421846457999902],
            [-42.903534559, -20.42142053],
            [-42.903494564, -20.421786358],
            [-42.903297978999902, -20.4222354149999],
            [-42.903165226999903, -20.422690636],
            [-42.903126175, -20.422833257]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 45,
        "id": 36956,
        "CD_SETOR": "315210505060047P",
        "AREA_KM2": 0.023898,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 12
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.903534559, -20.42142053],
            [-42.904123822, -20.421846457999902],
            [-42.904204439, -20.42190864],
            [-42.904304367999899, -20.421978179],
            [-42.90440505, -20.4220182399999],
            [-42.904573453999902, -20.422020118],
            [-42.90452658, -20.421982586],
            [-42.904458904, -20.421929412999901],
            [-42.904226004999899, -20.421742150999901],
            [-42.904244500999901, -20.4216807449999],
            [-42.904297094, -20.421597318],
            [-42.904369667, -20.421482199],
            [-42.904462351999904, -20.4213351779999],
            [-42.904559777, -20.421180637],
            [-42.904782895, -20.420968721999898],
            [-42.90482085, -20.420778590999898],
            [-42.904874293, -20.420558759999899],
            [-42.9049803199999, -20.420420051],
            [-42.904854918999902, -20.420346123999899],
            [-42.904819736, -20.4203214529999],
            [-42.904648237999901, -20.420217991999898],
            [-42.904222567, -20.419842199999898],
            [-42.903890492999899, -20.419586968],
            [-42.903633311, -20.419467025],
            [-42.903629006, -20.419694885],
            [-42.903607988, -20.420424504],
            [-42.903557401999898, -20.421141937999899],
            [-42.903548639999897, -20.4212444519999],
            [-42.903535502999901, -20.421384368],
            [-42.903534559, -20.42142053]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 46,
        "id": 36957,
        "CD_SETOR": "315210505060048P",
        "AREA_KM2": 0.014189,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 335
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.9049803199999, -20.420420051],
            [-42.904874293, -20.420558759999899],
            [-42.90482085, -20.420778590999898],
            [-42.904782895, -20.420968721999898],
            [-42.904559777, -20.421180637],
            [-42.904462351999904, -20.4213351779999],
            [-42.904369667, -20.421482199],
            [-42.904297094, -20.421597318],
            [-42.904244500999901, -20.4216807449999],
            [-42.904226004999899, -20.421742150999901],
            [-42.904458904, -20.421929412999901],
            [-42.90452658, -20.421982586],
            [-42.904573453999902, -20.422020118],
            [-42.904947885999903, -20.42165002],
            [-42.90568166, -20.420852747],
            [-42.9058535039999, -20.420665305999901],
            [-42.9056553409999, -20.420527153],
            [-42.905199965, -20.4202096819999],
            [-42.9052835479999, -20.420129071],
            [-42.905297537, -20.420088854],
            [-42.905282499, -20.4200766129999],
            [-42.905255919999902, -20.420075214999901],
            [-42.905193588, -20.420098557],
            [-42.905161907, -20.42010235],
            [-42.905084906999903, -20.420095672999899],
            [-42.90505917, -20.4201220899999],
            [-42.905053063, -20.420153359],
            [-42.90505502, -20.420251484999898],
            [-42.905018199, -20.420337891],
            [-42.9049803199999, -20.420420051]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 47,
        "id": 36958,
        "CD_SETOR": "315210505060049P",
        "AREA_KM2": 0.059878,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 253
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.901674781999901, -20.404452574999901],
            [-42.90183888, -20.4044325529999],
            [-42.901927993999898, -20.404374351999898],
            [-42.901972177999902, -20.404345496],
            [-42.902292529, -20.404136272999899],
            [-42.902682532, -20.403857812999899],
            [-42.902755498, -20.403841982],
            [-42.902816244999897, -20.4038412109999],
            [-42.902912245, -20.403858367999899],
            [-42.903234853999898, -20.403951429],
            [-42.903345459, -20.403982565],
            [-42.903446377, -20.404015186],
            [-42.9035374279999, -20.404041117],
            [-42.903618080999898, -20.404067768999901],
            [-42.903685521999897, -20.40408708],
            [-42.9039754309999, -20.404170094999898],
            [-42.904476369999898, -20.404313537999901],
            [-42.905032262, -20.404471282],
            [-42.905066022, -20.404447465],
            [-42.9051043649999, -20.404456411999899],
            [-42.905215978999898, -20.404485528],
            [-42.905411163999901, -20.4045401569999],
            [-42.905441872, -20.404401605],
            [-42.905509408999897, -20.404416954999899],
            [-42.905558520999897, -20.4044298009999],
            [-42.905642166999897, -20.40445168],
            [-42.905674081, -20.404456814],
            [-42.905765322, -20.404469563],
            [-42.905758545, -20.404576],
            [-42.9061707769999, -20.404668946999902],
            [-42.906327403, -20.4047042609999],
            [-42.906585774, -20.404750083],
            [-42.906624649, -20.404752613],
            [-42.906461964, -20.404468526999899],
            [-42.906110154, -20.404052894],
            [-42.905824845, -20.4036533559999],
            [-42.905393525, -20.403092038999901],
            [-42.905098963999897, -20.402799871],
            [-42.904608202, -20.402125751],
            [-42.904539358, -20.401887062999901],
            [-42.904508246, -20.4017357879999],
            [-42.904368559, -20.4016063719999],
            [-42.904251404, -20.401483504999899],
            [-42.904156811999897, -20.401418899],
            [-42.904004722, -20.401982866],
            [-42.9038516909999, -20.402430063999901],
            [-42.903712007, -20.402863296],
            [-42.903642132999899, -20.403069424],
            [-42.903490749999897, -20.403246671],
            [-42.903321621, -20.403311298999899],
            [-42.902958285999901, -20.403452353],
            [-42.902844883999897, -20.403500048],
            [-42.902727749999897, -20.403530507],
            [-42.902636684, -20.403647077],
            [-42.902232043, -20.403917669],
            [-42.902114159999897, -20.4040546109999],
            [-42.901856108999901, -20.404259786],
            [-42.901721817, -20.404413662],
            [-42.901674781999901, -20.404452574999901]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 48,
        "id": 36959,
        "CD_SETOR": "315210505060050P",
        "AREA_KM2": 0.176228,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 367
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.903117241, -20.3922900429999],
            [-42.903131468, -20.39231898],
            [-42.903435348, -20.392312107999899],
            [-42.903445812, -20.392373892],
            [-42.903589274, -20.392348789],
            [-42.903593379, -20.39263117],
            [-42.903608635999902, -20.393124188999899],
            [-42.903962731, -20.393031435],
            [-42.904249116, -20.392955771999901],
            [-42.904625709999898, -20.392860321999901],
            [-42.904728185, -20.392894364],
            [-42.904840011, -20.392920558],
            [-42.905118901, -20.393114055999899],
            [-42.9055852669999, -20.393414538999899],
            [-42.907134006, -20.393311164],
            [-42.907570306, -20.393201003999899],
            [-42.908396059, -20.392784704],
            [-42.909260619999898, -20.392368508999901],
            [-42.909298680999903, -20.392257733999902],
            [-42.909261904999902, -20.392065488999901],
            [-42.908140988999897, -20.39045154],
            [-42.907979853, -20.390261461],
            [-42.907584574999902, -20.389763242],
            [-42.907341925, -20.389472623],
            [-42.907047243, -20.389278291],
            [-42.906776652, -20.389274427],
            [-42.906423404, -20.389265604999899],
            [-42.906169118, -20.389337445],
            [-42.905973533, -20.389395328],
            [-42.905690235, -20.389693747],
            [-42.905477045999902, -20.390011990999898],
            [-42.905151197999899, -20.390471885999901],
            [-42.904640504, -20.390946197999899],
            [-42.903654156, -20.391891239],
            [-42.903535209, -20.392061644],
            [-42.9033974439999, -20.3921444489999],
            [-42.903117241, -20.3922900429999]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 49,
        "id": 36960,
        "CD_SETOR": "315210505060051P",
        "AREA_KM2": 0.053001,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 293
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.904488675, -20.381915315999901],
            [-42.905124054999902, -20.381924742],
            [-42.905352079999901, -20.381933987],
            [-42.905549718, -20.3816773839999],
            [-42.905597206, -20.381164847],
            [-42.905639369, -20.380784858],
            [-42.905653843, -20.380630771],
            [-42.905774666999903, -20.380464028999899],
            [-42.905852056999898, -20.380347806],
            [-42.905805466999901, -20.38017323],
            [-42.905582523, -20.380009286],
            [-42.905287224999903, -20.379771688999899],
            [-42.905112123, -20.37963346],
            [-42.904951586, -20.379500633],
            [-42.904825251, -20.379403682],
            [-42.904483980999899, -20.379253252],
            [-42.904387395, -20.379210065999899],
            [-42.904276082, -20.379060033],
            [-42.904127801, -20.3788529989999],
            [-42.904067213, -20.378619995],
            [-42.903857011, -20.378398703],
            [-42.90379301, -20.378038213],
            [-42.903451442999902, -20.377980274],
            [-42.903398372, -20.3779522849999],
            [-42.90344327, -20.379169531999899],
            [-42.903456739, -20.379697151],
            [-42.903456463999902, -20.379713959],
            [-42.903470980999899, -20.38029877],
            [-42.903474165, -20.380433313],
            [-42.9035722839999, -20.380431089999899],
            [-42.903777162, -20.380426449],
            [-42.904019838999901, -20.3804168509999],
            [-42.904367087, -20.380411572],
            [-42.904514767, -20.38041163],
            [-42.904378223, -20.3809059119999],
            [-42.904649853, -20.38095616],
            [-42.904533624, -20.381422939999901],
            [-42.904488675, -20.381915315999901]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 50,
        "id": 36961,
        "CD_SETOR": "315210505060052P",
        "AREA_KM2": 0.183092,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 342
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.902915535, -20.375363133999901],
            [-42.903148686999899, -20.375623103999899],
            [-42.903131153999901, -20.375794711],
            [-42.903141717, -20.375924282],
            [-42.903159468999903, -20.376003269],
            [-42.903186506999901, -20.376122102],
            [-42.903251193, -20.37628296],
            [-42.903374755999899, -20.376590231],
            [-42.903476793, -20.376747802999901],
            [-42.903524773, -20.376843385],
            [-42.903603222999898, -20.37709568],
            [-42.903621504999897, -20.3772264249999],
            [-42.903603587, -20.377448382999901],
            [-42.903531363999903, -20.377615621],
            [-42.903501051, -20.377685814],
            [-42.903460354999901, -20.377740661],
            [-42.903392993, -20.377849700999899],
            [-42.903395518, -20.377897864],
            [-42.903398372, -20.3779522849999],
            [-42.903451442999902, -20.377980274],
            [-42.90379301, -20.378038213],
            [-42.903857011, -20.378398703],
            [-42.904067213, -20.378619995],
            [-42.904720510999901, -20.379057358],
            [-42.90507811, -20.379063238],
            [-42.906034313, -20.37909525],
            [-42.906628985999902, -20.379112864],
            [-42.906671242, -20.3791650329999],
            [-42.906744268999901, -20.379297229],
            [-42.907023551, -20.379741929],
            [-42.907509927, -20.380356704999901],
            [-42.90771255, -20.380292606],
            [-42.907822137, -20.379915232],
            [-42.908066661, -20.379657898999898],
            [-42.9085562389999, -20.379163775999899],
            [-42.908918199, -20.378787488999901],
            [-42.909094008999901, -20.378535842],
            [-42.909029048, -20.378468757999901],
            [-42.908799894, -20.3782360619999],
            [-42.908241382, -20.377767420999898],
            [-42.907919321, -20.377509180999901],
            [-42.907649467999903, -20.377332474],
            [-42.907384731, -20.377226224],
            [-42.907035452999899, -20.3770842919999],
            [-42.906714393, -20.376886449],
            [-42.906487249, -20.376680098],
            [-42.906253228, -20.376496222],
            [-42.906007895, -20.376303595],
            [-42.905599213999899, -20.37589736],
            [-42.905344172999897, -20.375707142],
            [-42.90552214, -20.375378164],
            [-42.905470925, -20.375345712],
            [-42.905357525999896, -20.375247826],
            [-42.905254032999899, -20.375157406],
            [-42.905122368, -20.375118052999898],
            [-42.905017653999899, -20.3751086679999],
            [-42.904913387, -20.375105052],
            [-42.904816936, -20.375125733999901],
            [-42.904727507, -20.375118746999899],
            [-42.904639512, -20.375101392],
            [-42.904582892, -20.375102239999901],
            [-42.904546622999902, -20.3751224059999],
            [-42.904497233, -20.375110347],
            [-42.904429842999903, -20.375121626999899],
            [-42.904355408, -20.375095851],
            [-42.904311961, -20.37506367],
            [-42.904255964, -20.37504709],
            [-42.904216676, -20.3750285049999],
            [-42.904165599999899, -20.375009736],
            [-42.904028222, -20.374988763],
            [-42.90390017, -20.374972327],
            [-42.903802234, -20.374956464],
            [-42.903743005, -20.374957692999899],
            [-42.903675786999898, -20.374959087],
            [-42.903575256, -20.374976653],
            [-42.903368733, -20.375049734999902],
            [-42.903210333999901, -20.375160233],
            [-42.903105505, -20.375237146],
            [-42.902915535, -20.375363133999901]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 51,
        "id": 36962,
        "CD_SETOR": "315210505060054P",
        "AREA_KM2": 0.849758,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 304
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.995635751999899, -20.40612576],
            [-42.995423327, -20.406690644],
            [-42.994902910999897, -20.407711322999901],
            [-42.994542239999902, -20.408947731],
            [-42.994551831, -20.4092808199999],
            [-42.994682167999898, -20.409541805999901],
            [-42.994954833, -20.409855267999902],
            [-42.994993332, -20.410290846999899],
            [-42.994971031, -20.410585888],
            [-42.995045123, -20.410883596],
            [-42.995080689, -20.411220333],
            [-42.995077017, -20.411567807],
            [-42.995062805, -20.411648028999899],
            [-42.995263891, -20.411627907],
            [-42.995370647, -20.411639702999899],
            [-42.995407257999901, -20.411640098],
            [-42.99544384, -20.411659042999901],
            [-42.9959393789999, -20.411904790999898],
            [-42.996283349, -20.412097667],
            [-42.996700282999903, -20.4120639389999],
            [-42.996943284999901, -20.412075522],
            [-42.997696966, -20.4120413599999],
            [-42.998440824999903, -20.4120995389999],
            [-42.998589820999896, -20.412077826],
            [-42.999303602999902, -20.411879949],
            [-43.000325822, -20.411932726],
            [-43.000816297, -20.412024498999902],
            [-43.000943634999899, -20.412062358],
            [-43.001029139, -20.412099465],
            [-43.001241335, -20.412176216],
            [-43.001782471, -20.412250883],
            [-43.0019243099999, -20.412302953999902],
            [-43.002090506999899, -20.412372370999901],
            [-43.002146717999899, -20.41238512],
            [-43.002256873, -20.412451824],
            [-43.002493059, -20.412830728],
            [-43.00263682, -20.413042752],
            [-43.002768838999899, -20.413160713],
            [-43.003014228, -20.413207534],
            [-43.003185615999897, -20.413203022999902],
            [-43.003306365999897, -20.4125451419999],
            [-43.003364761999897, -20.412267218999901],
            [-43.003422805999897, -20.412093199],
            [-43.003558579, -20.411825633],
            [-43.003772386, -20.411586139999901],
            [-43.0042542099999, -20.411128345],
            [-43.004838978, -20.410613007999899],
            [-43.005439270999901, -20.410080697],
            [-43.005608783999897, -20.409978198],
            [-43.005795814, -20.409900100999899],
            [-43.006171122999902, -20.40972392],
            [-43.006542625, -20.409570062999901],
            [-43.006928984, -20.409402069999899],
            [-43.006498684, -20.409140212999901],
            [-43.006304919999899, -20.408998752999899],
            [-43.005938614999899, -20.4086291039999],
            [-43.00583356, -20.408512146],
            [-43.006047108, -20.408383172999901],
            [-43.006133323, -20.40831156],
            [-43.00620742, -20.408165546],
            [-43.00629946, -20.407864692],
            [-43.006341984, -20.407592563],
            [-43.006358938999902, -20.407286894],
            [-43.006334586, -20.407030157999898],
            [-43.006205479, -20.406775444999901],
            [-43.0059595, -20.406524916],
            [-43.005641727, -20.406235976999898],
            [-43.005415909, -20.4060150829999],
            [-43.005097957, -20.405746341999901],
            [-43.004859805, -20.405565989],
            [-43.0047300969999, -20.4054497989999],
            [-43.004550504, -20.405416669],
            [-43.004301810999898, -20.405374577],
            [-43.004130537, -20.405373869999899],
            [-43.003915332, -20.405390815],
            [-43.003736305, -20.405422299999898],
            [-43.003344458999898, -20.405476618],
            [-43.003222143, -20.40546753],
            [-43.003138029, -20.405458654],
            [-43.0030933789999, -20.405456209],
            [-43.003038586999899, -20.405452754],
            [-43.002878422, -20.405447188],
            [-43.002325821999897, -20.405324403999899],
            [-43.001898046, -20.405148906],
            [-43.001517491, -20.404938294],
            [-43.000673309, -20.405081066],
            [-43.000339677999897, -20.4051611259999],
            [-42.998175827999901, -20.404746626],
            [-42.997989716999903, -20.404663292999899],
            [-42.997698052, -20.404502181999899],
            [-42.997090949, -20.404207547999899],
            [-42.995891410999903, -20.405512152],
            [-42.995635751999899, -20.40612576]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 52,
        "id": 36963,
        "CD_SETOR": "315210505060055P",
        "AREA_KM2": 0.046555,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 329
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.92062162, -20.411417179999901],
            [-42.920659284, -20.412073079],
            [-42.9207080259999, -20.41288373],
            [-42.921341807, -20.412715786],
            [-42.921948525999902, -20.412547541],
            [-42.922067306, -20.414279522],
            [-42.923143534, -20.413971570999902],
            [-42.922800575999901, -20.4132223249999],
            [-42.922649931, -20.41281901],
            [-42.922543646, -20.4125835369999],
            [-42.9224982429999, -20.412455828],
            [-42.922472496999902, -20.412278087999901],
            [-42.922461701, -20.4121732159999],
            [-42.9224206539999, -20.410961307],
            [-42.92181951, -20.4111132959999],
            [-42.921197021, -20.411263624999901],
            [-42.92062162, -20.411417179999901]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 53,
        "id": 36964,
        "CD_SETOR": "315210505060056P",
        "AREA_KM2": 0.171081,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 371
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.903165293, -20.426044089],
            [-42.903281944, -20.426253596],
            [-42.903289792, -20.426267639999899],
            [-42.903285449, -20.4263266],
            [-42.903369831, -20.426467671999902],
            [-42.903628168, -20.426818905999902],
            [-42.903821746999903, -20.427081399],
            [-42.904005670999901, -20.427327998],
            [-42.904056532, -20.427664536],
            [-42.904208185, -20.428210265],
            [-42.904538370999902, -20.427870116999902],
            [-42.904689698, -20.427860132],
            [-42.905700227999901, -20.42706197],
            [-42.906335871, -20.42646625],
            [-42.906723923, -20.426015118999899],
            [-42.906838679, -20.4258853649999],
            [-42.906879981, -20.425797396],
            [-42.906877897999898, -20.425699536],
            [-42.906838736999902, -20.425571927],
            [-42.906682902999897, -20.425287376],
            [-42.906623979, -20.425183037],
            [-42.906516659, -20.424922594999899],
            [-42.9061408049999, -20.42422428],
            [-42.905887404999902, -20.423753417999901],
            [-42.905813875, -20.423617438999901],
            [-42.905779621, -20.423469435],
            [-42.905779901, -20.423291497],
            [-42.905834359, -20.423151026],
            [-42.905879289, -20.423085414],
            [-42.9059728, -20.4229622669999],
            [-42.906059169999899, -20.422843761],
            [-42.90619457, -20.422628921999902],
            [-42.906263878999901, -20.422503982],
            [-42.906353221, -20.422395924999901],
            [-42.906415806, -20.422318462],
            [-42.906500869, -20.422260346],
            [-42.906610438, -20.4222564519999],
            [-42.906749786, -20.4223002059999],
            [-42.90689401, -20.422374486],
            [-42.906997084999901, -20.422452846999899],
            [-42.907099792999901, -20.422556474],
            [-42.907164247, -20.422643434],
            [-42.907213585, -20.422746025],
            [-42.907269712999899, -20.4229133169999],
            [-42.907342569, -20.422972651],
            [-42.907613379999901, -20.423060924],
            [-42.907688952999898, -20.423084060999901],
            [-42.907768866, -20.423065274],
            [-42.907812890999899, -20.423042752999901],
            [-42.907913176, -20.422993887],
            [-42.9079242399999, -20.422967406999899],
            [-42.907933783, -20.422938799999901],
            [-42.907925232, -20.422896502],
            [-42.907929219, -20.422819503],
            [-42.907923434999901, -20.422780240999899],
            [-42.907952876, -20.42230122],
            [-42.907951738, -20.421793043],
            [-42.907933236, -20.421443301999901],
            [-42.907959574, -20.42113334],
            [-42.908014396999903, -20.420975811999899],
            [-42.907972946, -20.420869875999902],
            [-42.907859827, -20.420791392],
            [-42.907689821, -20.420663084],
            [-42.9075594, -20.420547672999898],
            [-42.907458247, -20.420335169999898],
            [-42.907358208999902, -20.420191868],
            [-42.907361204, -20.419895029],
            [-42.907346801, -20.419699689],
            [-42.907322668999903, -20.419490638],
            [-42.907319634, -20.419271954],
            [-42.907346773, -20.418993579999899],
            [-42.9072476649999, -20.418959702999899],
            [-42.907190893999903, -20.419120569],
            [-42.907177964, -20.41920226],
            [-42.907151294999899, -20.419474767999901],
            [-42.90712273, -20.419798574],
            [-42.907117016, -20.420059622999901],
            [-42.907132940999901, -20.420277719999898],
            [-42.9071538809999, -20.420366916],
            [-42.907208258, -20.420510255999901],
            [-42.907275062999901, -20.420618944999902],
            [-42.907252437, -20.420665421],
            [-42.907111031999897, -20.421004586],
            [-42.907001774, -20.4212205279999],
            [-42.906952836999899, -20.421284480999901],
            [-42.906893582999899, -20.421324883],
            [-42.906518682999902, -20.421577288],
            [-42.905892259, -20.421962193],
            [-42.905820725, -20.4220144],
            [-42.905760793999903, -20.422115697],
            [-42.905723113, -20.422158613],
            [-42.905674948999902, -20.422191679],
            [-42.905557085999902, -20.422254581999901],
            [-42.905470271, -20.422312611999899],
            [-42.905422549, -20.422341783],
            [-42.905321838, -20.422414659],
            [-42.90516489, -20.4225681849999],
            [-42.904969047999899, -20.422791834],
            [-42.9049238979999, -20.422864182],
            [-42.904901257999903, -20.422918555],
            [-42.904901966999901, -20.422977187],
            [-42.904924977, -20.423063091],
            [-42.904962048, -20.423232762999898],
            [-42.904978822, -20.423335259999899],
            [-42.904953637999903, -20.423674094],
            [-42.904928906, -20.423739887],
            [-42.904882441, -20.4237908009999],
            [-42.9047646389999, -20.4237928709999],
            [-42.904635797, -20.423836444],
            [-42.90455066, -20.423885264],
            [-42.90427227, -20.424001405],
            [-42.904034549, -20.424119183],
            [-42.903682814, -20.424288472999901],
            [-42.903616179, -20.4249439939999],
            [-42.903603609, -20.425496186],
            [-42.903426103999898, -20.425667064],
            [-42.903267905, -20.425778178],
            [-42.903165293, -20.426044089]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 54,
        "id": 36965,
        "CD_SETOR": "315210505060057P",
        "AREA_KM2": 0.035438,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 380
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.897902006, -20.406749299],
            [-42.89830896, -20.4066576459999],
            [-42.898583849, -20.406583377999901],
            [-42.898746969999898, -20.407033753],
            [-42.899209932, -20.406845968],
            [-42.899624183999897, -20.406687389999899],
            [-42.899452342, -20.406254987],
            [-42.899230452, -20.405736520999898],
            [-42.899041053, -20.405294327],
            [-42.898967457, -20.405122212],
            [-42.898904073, -20.404985054],
            [-42.898871301, -20.404908185],
            [-42.898838617, -20.4048670029999],
            [-42.898692592, -20.404474674],
            [-42.898431189999897, -20.403830899],
            [-42.898297850999903, -20.403904831],
            [-42.898017388, -20.404117855],
            [-42.897916801999898, -20.404176761],
            [-42.897742676, -20.404357115],
            [-42.897570118, -20.404541529],
            [-42.897650519, -20.404576344],
            [-42.897720669, -20.4046128539999],
            [-42.897748869999901, -20.404641397],
            [-42.897791001, -20.404708191999902],
            [-42.897845839999903, -20.404829111],
            [-42.897997264, -20.405159173999898],
            [-42.898026151, -20.4052144519999],
            [-42.89820449, -20.405650556],
            [-42.89820693, -20.405716282],
            [-42.898199729999902, -20.405847439],
            [-42.898154675999898, -20.406019226],
            [-42.8981254569999, -20.4061335019999],
            [-42.898001289, -20.406473178999899],
            [-42.897902006, -20.406749299]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 55,
        "id": 36966,
        "CD_SETOR": "315210505060058P",
        "AREA_KM2": 0.027099,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 394
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.900766959999899, -20.406024492],
            [-42.900798968999901, -20.406088082999901],
            [-42.900800542999903, -20.406206015],
            [-42.899624183999897, -20.406687389999899],
            [-42.899209932, -20.406845968],
            [-42.899379175, -20.407201852],
            [-42.899654342, -20.407780471],
            [-42.899694392999898, -20.407824515],
            [-42.899781876, -20.4078505099999],
            [-42.899831092999896, -20.407857726],
            [-42.89989987, -20.407867811],
            [-42.900140957, -20.407772818999899],
            [-42.900350359999898, -20.407700942],
            [-42.900549248999901, -20.407632673],
            [-42.900631231, -20.407694892999899],
            [-42.900706410999902, -20.407682822],
            [-42.900820818999897, -20.407588357999899],
            [-42.901042646999898, -20.407271189],
            [-42.901048171, -20.407084405],
            [-42.901049052, -20.407038929],
            [-42.901049723, -20.4070042879999],
            [-42.901081018, -20.406979532],
            [-42.901115556, -20.406952209999901],
            [-42.901186825, -20.406920202],
            [-42.901199409999897, -20.4068046199999],
            [-42.901222479, -20.406259593],
            [-42.901235646, -20.405715432],
            [-42.901197040999897, -20.405639224],
            [-42.901069710999899, -20.4057287639999],
            [-42.90092473, -20.405894813],
            [-42.900766959999899, -20.406024492]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 56,
        "id": 36967,
        "CD_SETOR": "315210505060059P",
        "AREA_KM2": 0.081278,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 232
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.912953708, -20.418882873],
            [-42.913182537999901, -20.41885838],
            [-42.913300121, -20.418829065],
            [-42.9137090689999, -20.4185642909999],
            [-42.913926006999901, -20.418242242],
            [-42.914191741, -20.418112553999901],
            [-42.914308009, -20.418356827],
            [-42.914430100999901, -20.418560616],
            [-42.914576997, -20.418758099999899],
            [-42.914790697999898, -20.418874286999898],
            [-42.914967295999901, -20.418942776999899],
            [-42.915136677, -20.418936550999899],
            [-42.915368425999901, -20.41888248],
            [-42.915704443, -20.418830333],
            [-42.916080905999898, -20.4188086079999],
            [-42.916298205, -20.418834696],
            [-42.916622179999898, -20.4189482099999],
            [-42.916777516, -20.419026629],
            [-42.916987457999902, -20.419012933],
            [-42.917234493, -20.418830672],
            [-42.9172075769999, -20.418763258999899],
            [-42.917025899999899, -20.418261281],
            [-42.916807934, -20.417751930999898],
            [-42.91668963, -20.417380116999901],
            [-42.916662516, -20.417109959],
            [-42.916721763999902, -20.416759471],
            [-42.916651290999901, -20.4167605979999],
            [-42.916552625, -20.416750491999899],
            [-42.916332297999901, -20.417114953999899],
            [-42.915950772, -20.416379891],
            [-42.915425238999902, -20.4166199759999],
            [-42.914907148999902, -20.416834783999899],
            [-42.914779414, -20.416635898],
            [-42.914660571999903, -20.41652412],
            [-42.914602691, -20.416494871999902],
            [-42.914495077, -20.416489946999899],
            [-42.914422821999899, -20.416506259999899],
            [-42.914217876999899, -20.4165843759999],
            [-42.914085969, -20.416657605999902],
            [-42.913846534, -20.416844373999901],
            [-42.9137282149999, -20.416987318999901],
            [-42.913684960999902, -20.417120064],
            [-42.913656606, -20.417292173],
            [-42.913608613999898, -20.417634962],
            [-42.913494, -20.41787291],
            [-42.913205703, -20.418400006999899],
            [-42.913027638, -20.418756021],
            [-42.912995021, -20.4188212339999],
            [-42.912953708, -20.418882873]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 57,
        "id": 36968,
        "CD_SETOR": "315210505060060P",
        "AREA_KM2": 0.083769,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 87
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.914005480999897, -20.412258127],
            [-42.914269222, -20.411946755],
            [-42.914473111999897, -20.4118017],
            [-42.914710515, -20.4115049719999],
            [-42.914963181, -20.411162018],
            [-42.91538628, -20.410597943],
            [-42.915531245, -20.410407043],
            [-42.915609001999897, -20.410326158],
            [-42.915594246999902, -20.410313173],
            [-42.915576090999899, -20.410290707999899],
            [-42.915554108, -20.410263507],
            [-42.91553757, -20.410243086],
            [-42.915511772999899, -20.4102111239999],
            [-42.915460157, -20.410129971999901],
            [-42.915432413, -20.410062497],
            [-42.915424684999898, -20.409980128],
            [-42.91541233, -20.409909064],
            [-42.915404716999902, -20.409653245],
            [-42.915389522999902, -20.409543184],
            [-42.915353018, -20.409302282999899],
            [-42.915330229999903, -20.409090642],
            [-42.91531223, -20.408929692999902],
            [-42.915307842999901, -20.408728675],
            [-42.915298355999902, -20.40862255],
            [-42.915219635, -20.408460657],
            [-42.915011459, -20.408138338],
            [-42.914863289, -20.407892728],
            [-42.914769971999903, -20.407821612],
            [-42.914754084, -20.408031562],
            [-42.914764694, -20.408154383],
            [-42.914782647, -20.408312918],
            [-42.914801864, -20.408448491999899],
            [-42.914805742999903, -20.408592623999901],
            [-42.914789991, -20.408722476],
            [-42.91471544, -20.408875035999898],
            [-42.91464395, -20.409013033999901],
            [-42.914600569999898, -20.409179459],
            [-42.914577956, -20.4092779499999],
            [-42.914555838999902, -20.409373834],
            [-42.914461648, -20.409440237],
            [-42.914407097, -20.409497454],
            [-42.914349011999903, -20.409552421999901],
            [-42.9142058589999, -20.409737153],
            [-42.914092734, -20.409884351999899],
            [-42.913913089999902, -20.410105652],
            [-42.913717434, -20.410373321],
            [-42.913626205, -20.410457649],
            [-42.913501165, -20.410549278],
            [-42.913354317, -20.410633844],
            [-42.913169739, -20.4107268079999],
            [-42.913004015, -20.410800424999898],
            [-42.912876347999898, -20.4108393019999],
            [-42.912707374, -20.410890757999901],
            [-42.912558972999904, -20.410915404],
            [-42.912344757, -20.41094407],
            [-42.912161176, -20.410932482],
            [-42.912026258, -20.41090589],
            [-42.911471538999898, -20.4106249509999],
            [-42.911322470999899, -20.41055055],
            [-42.911136354, -20.410475108999901],
            [-42.91102362, -20.410436385],
            [-42.910922501, -20.41040888],
            [-42.910773107999901, -20.410404647999901],
            [-42.910622015999898, -20.4104155869999],
            [-42.910517023999901, -20.410445141],
            [-42.910462095, -20.4104966109999],
            [-42.910549407, -20.410766453999901],
            [-42.91061596, -20.411086676],
            [-42.910720136, -20.411113331],
            [-42.911309974999902, -20.411451930999899],
            [-42.911702628999898, -20.41169079],
            [-42.911762525, -20.411714848],
            [-42.913408215999901, -20.411931366],
            [-42.913470963, -20.41196077],
            [-42.9137847, -20.412209364],
            [-42.914005480999897, -20.412258127]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 58,
        "id": 36969,
        "CD_SETOR": "315210505060061P",
        "AREA_KM2": 0.034172,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 81
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.891799534, -20.467941542999899],
            [-42.891832606, -20.467977692],
            [-42.891885683999902, -20.468032089],
            [-42.892034536, -20.468097865999901],
            [-42.892246558, -20.468143934999901],
            [-42.892652337999898, -20.468130621999901],
            [-42.892749657, -20.468120466999899],
            [-42.892843672, -20.4681615649999],
            [-42.893006194, -20.468446113],
            [-42.89317675, -20.468740539999899],
            [-42.8931956489999, -20.468773165],
            [-42.893210294, -20.468798445999902],
            [-42.893472207999899, -20.469066644999899],
            [-42.893608704, -20.469162319],
            [-42.893648129, -20.469268359999901],
            [-42.893666539, -20.4693377789999],
            [-42.893675096, -20.469412597],
            [-42.893698161, -20.46952866],
            [-42.893739801, -20.469685789],
            [-42.893792033999901, -20.469837454],
            [-42.893880451, -20.469947009],
            [-42.893963163, -20.470016481999899],
            [-42.894110291999901, -20.470089822],
            [-42.894123607, -20.470110721],
            [-42.894137857, -20.470075267],
            [-42.894197752, -20.469690490999898],
            [-42.894243387, -20.4693591559999],
            [-42.894403107, -20.468928952999899],
            [-42.894488672, -20.4687579399999],
            [-42.894468706999902, -20.468667089],
            [-42.894343212, -20.468445307],
            [-42.89427476, -20.468258261],
            [-42.894212013, -20.4680525109999],
            [-42.894106483, -20.467905545999901],
            [-42.893938207, -20.467793318],
            [-42.893729999999898, -20.467675745999902],
            [-42.893527497, -20.467587567],
            [-42.89301411, -20.467456634],
            [-42.8927659719999, -20.4674031919999],
            [-42.892580583, -20.4673684549999],
            [-42.892326741, -20.4674031919999],
            [-42.892204099, -20.467461978],
            [-42.892141351, -20.4675314529999],
            [-42.892024412999902, -20.467734532],
            [-42.891953109, -20.467892185],
            [-42.891898919, -20.46792425],
            [-42.891836171, -20.467932267],
            [-42.891799534, -20.467941542999899]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 59,
        "id": 36970,
        "CD_SETOR": "315210505060062P",
        "AREA_KM2": 0.16785,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 344
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.9222203429999, -20.407593613],
            [-42.922282256, -20.408126912999901],
            [-42.922311602, -20.408406226],
            [-42.9223756069999, -20.409928024],
            [-42.9224206539999, -20.410961307],
            [-42.922461701, -20.4121732159999],
            [-42.922472496999902, -20.412278087999901],
            [-42.9224982429999, -20.412455828],
            [-42.922543646, -20.4125835369999],
            [-42.922649931, -20.41281901],
            [-42.922800575999901, -20.4132223249999],
            [-42.923143534, -20.413971570999902],
            [-42.922067306, -20.414279522],
            [-42.921948525999902, -20.412547541],
            [-42.921341807, -20.412715786],
            [-42.921387463, -20.413454769999898],
            [-42.921448776, -20.414447175],
            [-42.920972983, -20.414575290999899],
            [-42.920845921, -20.414609504999898],
            [-42.920967374, -20.414823495],
            [-42.921008313, -20.414838652999901],
            [-42.921305748, -20.414823989999899],
            [-42.921984465, -20.414637287999899],
            [-42.922692815999902, -20.414455916],
            [-42.923422382, -20.414273389],
            [-42.923445853, -20.414235096],
            [-42.923532441, -20.413748878],
            [-42.923374807, -20.4133884039999],
            [-42.923292005999897, -20.413146531999899],
            [-42.923391071, -20.413118706],
            [-42.923498934999898, -20.413092467],
            [-42.923732276, -20.413035705],
            [-42.9244350499999, -20.413080683],
            [-42.924715039, -20.4126322289999],
            [-42.925326176999903, -20.411794194],
            [-42.925077987, -20.410503043999899],
            [-42.924625433, -20.409793335],
            [-42.924101524, -20.409181451999899],
            [-42.923668756, -20.408771069999901],
            [-42.923230963, -20.407980337],
            [-42.923052739999903, -20.4074500729999],
            [-42.922836929, -20.406977981],
            [-42.922582411, -20.406573266],
            [-42.922539962, -20.4064715059999],
            [-42.922527036, -20.406354490999899],
            [-42.922536421, -20.406281077],
            [-42.922454022999901, -20.406409138999901],
            [-42.922387934, -20.40647182],
            [-42.922286015999902, -20.406548981],
            [-42.92222528, -20.4066512479999],
            [-42.92219391, -20.40677148],
            [-42.922171067, -20.4069682149999],
            [-42.922210506999903, -20.407196661999901],
            [-42.922202915999897, -20.407407804999899],
            [-42.922205752, -20.407495728999901],
            [-42.9222203429999, -20.407593613]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 60,
        "id": 36971,
        "CD_SETOR": "315210505060063P",
        "AREA_KM2": 0.131608,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 356
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.910840155, -20.415074481],
            [-42.910518345, -20.414867202],
            [-42.910419206, -20.414915101],
            [-42.910061842999902, -20.415245558],
            [-42.910328179, -20.415468233999899],
            [-42.910761904, -20.416039134999899],
            [-42.910823825, -20.416123097],
            [-42.910944856, -20.416278285],
            [-42.911000374, -20.416705124],
            [-42.910976897999902, -20.41718894],
            [-42.910936880999898, -20.417784228999899],
            [-42.910833295, -20.418569182999899],
            [-42.910823536, -20.418643137999901],
            [-42.910821472, -20.418652071999901],
            [-42.910575567, -20.419496309],
            [-42.910592273, -20.419833508999901],
            [-42.910680403, -20.419978305],
            [-42.910868364999899, -20.420152247],
            [-42.910977615, -20.420202711999899],
            [-42.911344846, -20.420205676],
            [-42.91159651, -20.420254309],
            [-42.911753292, -20.420317533999899],
            [-42.911909400999903, -20.420344207],
            [-42.912080461, -20.420428656],
            [-42.912314449, -20.42059606],
            [-42.912526493, -20.421005771999901],
            [-42.912654742, -20.421206568999899],
            [-42.912869411, -20.421302644],
            [-42.913059750999899, -20.421311182],
            [-42.913182182, -20.421187092],
            [-42.913288775999902, -20.420957455],
            [-42.913365953, -20.420685662],
            [-42.913405912, -20.420440345],
            [-42.913414750999898, -20.420386078],
            [-42.913424845999899, -20.420324100999899],
            [-42.913522144999902, -20.41994482],
            [-42.913069359, -20.419823865999899],
            [-42.912898511, -20.419744108],
            [-42.912792830999898, -20.419669616],
            [-42.91263359, -20.41975566],
            [-42.912475922999903, -20.419710245],
            [-42.912330192, -20.419547848],
            [-42.912517522, -20.419366282999899],
            [-42.912953708, -20.418882873],
            [-42.912869368, -20.418816116],
            [-42.912850279999901, -20.418803490999899],
            [-42.912792672, -20.418765388999901],
            [-42.912726633, -20.4187211],
            [-42.912639392, -20.418667823],
            [-42.912500457, -20.418572116],
            [-42.912423287, -20.41850576],
            [-42.912488822999897, -20.41838397],
            [-42.912568228999902, -20.41823346],
            [-42.912612924, -20.418149491],
            [-42.912632335, -20.418108252],
            [-42.912669888, -20.4180424739999],
            [-42.91268187, -20.417994737],
            [-42.912730337, -20.417800563999901],
            [-42.912735912, -20.417724172],
            [-42.912747171, -20.417569883999899],
            [-42.912754887999903, -20.417464142],
            [-42.912729106, -20.417291677],
            [-42.912693927, -20.4170563549999],
            [-42.91254251, -20.416603321],
            [-42.912349645999903, -20.416175485999901],
            [-42.912099853, -20.41565614],
            [-42.912035153, -20.415514549],
            [-42.91194659, -20.415282928],
            [-42.911933469, -20.4150454749999],
            [-42.911965938, -20.414823261],
            [-42.912036547, -20.414688509],
            [-42.912155175999899, -20.414562365999899],
            [-42.912309636, -20.414425621],
            [-42.912492852, -20.414334219999901],
            [-42.912958905, -20.4142683329999],
            [-42.91332605, -20.414243988],
            [-42.913708077, -20.414234314999899],
            [-42.914165633, -20.414186254999901],
            [-42.914397298, -20.4141643839999],
            [-42.914738367, -20.414130323999899],
            [-42.915049193, -20.414092173],
            [-42.914988824, -20.414070701999901],
            [-42.914958438, -20.4140587969999],
            [-42.914916360999896, -20.414065061],
            [-42.914646332, -20.414074663999902],
            [-42.914319102, -20.414082632],
            [-42.913762957, -20.4141140999999],
            [-42.913476504, -20.414127559999901],
            [-42.913106652, -20.414133262],
            [-42.912502447999898, -20.414152983],
            [-42.912261934, -20.414143920999901],
            [-42.911827875, -20.414165833],
            [-42.911685749, -20.414281896],
            [-42.911411589999901, -20.4145720339999],
            [-42.911354741, -20.414653609],
            [-42.91129599, -20.414799936999898],
            [-42.911147442999898, -20.41521707],
            [-42.9108197689999, -20.415321218],
            [-42.910814242, -20.4152097539999],
            [-42.910830521, -20.415124771],
            [-42.910840155, -20.415074481]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 61,
        "id": 36972,
        "CD_SETOR": "315210505060064P",
        "AREA_KM2": 0.311174,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 317
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.898084761999897, -20.375095729],
            [-42.899262214, -20.375927519],
            [-42.899610825, -20.376170413999901],
            [-42.899942223, -20.376406851],
            [-42.900600928, -20.376744174],
            [-42.901088322, -20.376929462],
            [-42.901678375, -20.377021382],
            [-42.901894496999901, -20.377165295999902],
            [-42.902108058, -20.377491762],
            [-42.902292987, -20.377982937],
            [-42.902511757, -20.378520814],
            [-42.90267188, -20.379054868],
            [-42.902703960999901, -20.3799032229999],
            [-42.902726975999897, -20.380501889999898],
            [-42.902743008999899, -20.380888015],
            [-42.902678484, -20.381716847],
            [-42.902566922999902, -20.382340065999902],
            [-42.902458672, -20.383099988],
            [-42.902355025, -20.3836035369999],
            [-42.902348321, -20.38363691],
            [-42.90234773, -20.3836692419999],
            [-42.902261118999903, -20.3849824559999],
            [-42.902303182, -20.3852970149999],
            [-42.902339074999901, -20.385749315999899],
            [-42.902385572, -20.386093498],
            [-42.902556848, -20.386582027],
            [-42.902778063, -20.387040425],
            [-42.902952531, -20.387326049],
            [-42.903048299, -20.387774820999901],
            [-42.9035194699999, -20.387758952999899],
            [-42.903592891, -20.38775648],
            [-42.903605352999897, -20.38775606],
            [-42.904533804, -20.387724791],
            [-42.904646118, -20.386853205],
            [-42.904609609, -20.386233391],
            [-42.904593097, -20.38564823],
            [-42.904580263, -20.385390304999898],
            [-42.904577285, -20.385334762],
            [-42.904588931, -20.3849998859999],
            [-42.904593255999899, -20.384875509],
            [-42.904640508999897, -20.384602822],
            [-42.904624767, -20.384530928999901],
            [-42.9045732129999, -20.384188926],
            [-42.90451053, -20.384026572],
            [-42.904380556, -20.383863011],
            [-42.904295926, -20.383802897],
            [-42.904391996999898, -20.383625299],
            [-42.904424003, -20.3834594369999],
            [-42.904431969, -20.383118669999899],
            [-42.904455686999903, -20.382582415999899],
            [-42.904488675, -20.381915315999901],
            [-42.904533624, -20.381422939999901],
            [-42.904649853, -20.38095616],
            [-42.904378223, -20.3809059119999],
            [-42.904514767, -20.38041163],
            [-42.904367087, -20.380411572],
            [-42.904019838999901, -20.3804168509999],
            [-42.903777162, -20.380426449],
            [-42.9035722839999, -20.380431089999899],
            [-42.903474165, -20.380433313],
            [-42.903470980999899, -20.38029877],
            [-42.903456463999902, -20.379713959],
            [-42.903456739, -20.379697151],
            [-42.90344327, -20.379169531999899],
            [-42.903398372, -20.3779522849999],
            [-42.903395518, -20.377897864],
            [-42.903392993, -20.377849700999899],
            [-42.903460354999901, -20.377740661],
            [-42.903501051, -20.377685814],
            [-42.903531363999903, -20.377615621],
            [-42.903603587, -20.377448382999901],
            [-42.903621504999897, -20.3772264249999],
            [-42.903603222999898, -20.37709568],
            [-42.903524773, -20.376843385],
            [-42.903476793, -20.376747802999901],
            [-42.903374755999899, -20.376590231],
            [-42.903251193, -20.37628296],
            [-42.903186506999901, -20.376122102],
            [-42.903159468999903, -20.376003269],
            [-42.903141717, -20.375924282],
            [-42.903131153999901, -20.375794711],
            [-42.903148686999899, -20.375623103999899],
            [-42.902915535, -20.375363133999901],
            [-42.902882604, -20.375373543],
            [-42.902691489999903, -20.375471344999902],
            [-42.902397187, -20.375532405999898],
            [-42.902301899, -20.3755605449999],
            [-42.902258898999897, -20.375555842],
            [-42.902095229999901, -20.375497089],
            [-42.9019567409999, -20.375425746],
            [-42.901566117999899, -20.375229678],
            [-42.901077502, -20.374866811999901],
            [-42.900793910999901, -20.374671294999899],
            [-42.90043926, -20.374426787999901],
            [-42.900203775, -20.374264437],
            [-42.899831075999899, -20.374007486],
            [-42.899467312999903, -20.373756697],
            [-42.899446567, -20.373738245],
            [-42.899272074, -20.373948836],
            [-42.899067622, -20.374492642],
            [-42.898987281, -20.374688105],
            [-42.898569224, -20.374336066],
            [-42.898084761999897, -20.375095729]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 62,
        "id": 36973,
        "CD_SETOR": "315210505060065P",
        "AREA_KM2": 1.438384,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 123
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.899446567, -20.373738245],
            [-42.899467312999903, -20.373756697],
            [-42.899831075999899, -20.374007486],
            [-42.900203775, -20.374264437],
            [-42.90043926, -20.374426787999901],
            [-42.900793910999901, -20.374671294999899],
            [-42.901077502, -20.374866811999901],
            [-42.901566117999899, -20.375229678],
            [-42.9019567409999, -20.375425746],
            [-42.902095229999901, -20.375497089],
            [-42.902258898999897, -20.375555842],
            [-42.902301899, -20.3755605449999],
            [-42.902397187, -20.375532405999898],
            [-42.902691489999903, -20.375471344999902],
            [-42.902882604, -20.375373543],
            [-42.902915535, -20.375363133999901],
            [-42.903105505, -20.375237146],
            [-42.903210333999901, -20.375160233],
            [-42.903368733, -20.375049734999902],
            [-42.903575256, -20.374976653],
            [-42.903675786999898, -20.374959087],
            [-42.903743005, -20.374957692999899],
            [-42.903802234, -20.374956464],
            [-42.90390017, -20.374972327],
            [-42.904028222, -20.374988763],
            [-42.904165599999899, -20.375009736],
            [-42.904216676, -20.3750285049999],
            [-42.904255964, -20.37504709],
            [-42.904311961, -20.37506367],
            [-42.904355408, -20.375095851],
            [-42.904429842999903, -20.375121626999899],
            [-42.904497233, -20.375110347],
            [-42.904546622999902, -20.3751224059999],
            [-42.904582892, -20.375102239999901],
            [-42.904639512, -20.375101392],
            [-42.904727507, -20.375118746999899],
            [-42.904816936, -20.375125733999901],
            [-42.904913387, -20.375105052],
            [-42.905017653999899, -20.3751086679999],
            [-42.905122368, -20.375118052999898],
            [-42.905254032999899, -20.375157406],
            [-42.905357525999896, -20.375247826],
            [-42.905470925, -20.375345712],
            [-42.90552214, -20.375378164],
            [-42.905344172999897, -20.375707142],
            [-42.905599213999899, -20.37589736],
            [-42.906007895, -20.376303595],
            [-42.906253228, -20.376496222],
            [-42.906487249, -20.376680098],
            [-42.906714393, -20.376886449],
            [-42.907035452999899, -20.3770842919999],
            [-42.907384731, -20.377226224],
            [-42.907649467999903, -20.377332474],
            [-42.907919321, -20.377509180999901],
            [-42.908241382, -20.377767420999898],
            [-42.908799894, -20.3782360619999],
            [-42.909029048, -20.378468757999901],
            [-42.909094008999901, -20.378535842],
            [-42.908918199, -20.378787488999901],
            [-42.9085562389999, -20.379163775999899],
            [-42.908066661, -20.379657898999898],
            [-42.907822137, -20.379915232],
            [-42.90771255, -20.380292606],
            [-42.907509927, -20.380356704999901],
            [-42.907023551, -20.379741929],
            [-42.906744268999901, -20.379297229],
            [-42.906671242, -20.3791650329999],
            [-42.906628985999902, -20.379112864],
            [-42.906034313, -20.37909525],
            [-42.90507811, -20.379063238],
            [-42.904720510999901, -20.379057358],
            [-42.904067213, -20.378619995],
            [-42.904127801, -20.3788529989999],
            [-42.904276082, -20.379060033],
            [-42.904387395, -20.379210065999899],
            [-42.904483980999899, -20.379253252],
            [-42.904825251, -20.379403682],
            [-42.904951586, -20.379500633],
            [-42.905112123, -20.37963346],
            [-42.905287224999903, -20.379771688999899],
            [-42.905582523, -20.380009286],
            [-42.905805466999901, -20.38017323],
            [-42.905852056999898, -20.380347806],
            [-42.905774666999903, -20.380464028999899],
            [-42.905653843, -20.380630771],
            [-42.905639369, -20.380784858],
            [-42.905597206, -20.381164847],
            [-42.905549718, -20.3816773839999],
            [-42.905352079999901, -20.381933987],
            [-42.905124054999902, -20.381924742],
            [-42.904488675, -20.381915315999901],
            [-42.904455686999903, -20.382582415999899],
            [-42.904431969, -20.383118669999899],
            [-42.904424003, -20.3834594369999],
            [-42.904391996999898, -20.383625299],
            [-42.904295926, -20.383802897],
            [-42.904380556, -20.383863011],
            [-42.90451053, -20.384026572],
            [-42.9045732129999, -20.384188926],
            [-42.904624767, -20.384530928999901],
            [-42.904640508999897, -20.384602822],
            [-42.904593255999899, -20.384875509],
            [-42.904588931, -20.3849998859999],
            [-42.904577285, -20.385334762],
            [-42.904580263, -20.385390304999898],
            [-42.904593097, -20.38564823],
            [-42.904609609, -20.386233391],
            [-42.904646118, -20.386853205],
            [-42.904533804, -20.387724791],
            [-42.9049955629999, -20.387710432999899],
            [-42.905033068, -20.387904923],
            [-42.905058794, -20.388186572999899],
            [-42.905218829, -20.388552054999899],
            [-42.905431474999901, -20.388774091999899],
            [-42.906141663, -20.38889619],
            [-42.906603205, -20.388948904],
            [-42.907190485999898, -20.388975435],
            [-42.907338222, -20.388950114],
            [-42.907637184, -20.388834184],
            [-42.90783299, -20.388870163],
            [-42.907949532, -20.3890180729999],
            [-42.908606428, -20.390263369],
            [-42.908871083, -20.39062381],
            [-42.909181571, -20.390881916999898],
            [-42.909423056, -20.390948981],
            [-42.913015779, -20.389264195],
            [-42.912887262, -20.389079320999901],
            [-42.912793251, -20.388769001999901],
            [-42.912798418, -20.388252462],
            [-42.912698303999903, -20.387592011],
            [-42.912381353999898, -20.3870431889999],
            [-42.91178079, -20.386643407],
            [-42.91141292, -20.385690167],
            [-42.911106601, -20.385069207999901],
            [-42.910195300999902, -20.384147800999902],
            [-42.910175122999902, -20.3836191739999],
            [-42.910717459, -20.382737721999899],
            [-42.911342638, -20.381890842],
            [-42.911543573, -20.381631641999899],
            [-42.911627081999903, -20.381494064],
            [-42.911744181, -20.3814539749999],
            [-42.912694727, -20.381173186],
            [-42.912852629, -20.381044755999898],
            [-42.913190894, -20.380717913],
            [-42.913339337, -20.380461038],
            [-42.913503018, -20.380007937999899],
            [-42.913709184, -20.3793942789999],
            [-42.913870298999903, -20.378916537999899],
            [-42.913929634999903, -20.378758632],
            [-42.913939236, -20.3786949509999],
            [-42.914482756, -20.377684518],
            [-42.914679842999902, -20.37734906],
            [-42.915095758999897, -20.376567426],
            [-42.915097041, -20.376563951],
            [-42.9150989789999, -20.3765587],
            [-42.914921176, -20.375779211999902],
            [-42.914680308, -20.3758043989999],
            [-42.913974892999903, -20.375874032999899],
            [-42.91302927, -20.375812010999901],
            [-42.912116872, -20.3756616209999],
            [-42.911661551, -20.3755746749999],
            [-42.911041655, -20.375382283],
            [-42.910401754, -20.375109252],
            [-42.909817933999904, -20.374774359],
            [-42.909254414, -20.37416948],
            [-42.908969082999903, -20.373827837],
            [-42.908620316999901, -20.373406508],
            [-42.908224753, -20.373157267],
            [-42.907527428, -20.372964049],
            [-42.905832298999897, -20.372646253],
            [-42.90392392, -20.372227804],
            [-42.903494088999899, -20.372070895],
            [-42.903073503, -20.372040319999901],
            [-42.902146046, -20.3721100829999],
            [-42.901456052, -20.372306341],
            [-42.900773218, -20.37258336],
            [-42.900107049, -20.372984922999901],
            [-42.899596969999898, -20.373556726999901],
            [-42.899446567, -20.373738245]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 63,
        "id": 36974,
        "CD_SETOR": "315210505060066P",
        "AREA_KM2": 1.183308,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 275
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.93144411, -20.407553207],
            [-42.931754124, -20.4082273839999],
            [-42.931986386999903, -20.408553938],
            [-42.931984331, -20.409235242],
            [-42.932044845, -20.410246032],
            [-42.93205338, -20.410632683],
            [-42.931365694, -20.411040141999901],
            [-42.931053016, -20.410963536999901],
            [-42.930815464, -20.410933773999901],
            [-42.930533322, -20.410954058999899],
            [-42.929985003, -20.411237296],
            [-42.9297144, -20.411436738],
            [-42.929344334999897, -20.4119869469999],
            [-42.928629464, -20.413738372999902],
            [-42.928011679, -20.415316778],
            [-42.927726148, -20.415879262],
            [-42.927346338999897, -20.416949326999902],
            [-42.927319779, -20.4175178899999],
            [-42.927513088999902, -20.418058078999898],
            [-42.9277258609999, -20.4185084269999],
            [-42.928060414, -20.419003717],
            [-42.9283423259999, -20.419195667999901],
            [-42.928425018, -20.419211343],
            [-42.928520854999903, -20.419175002],
            [-42.928659129, -20.419097517],
            [-42.928836164, -20.418962555999901],
            [-42.929290535999897, -20.4185247],
            [-42.92958386, -20.418226617999899],
            [-42.929829839, -20.417993760999899],
            [-42.930010523, -20.417804121],
            [-42.93020826, -20.417671373],
            [-42.930459115, -20.417611058],
            [-42.930723478999901, -20.417600792],
            [-42.930955227, -20.4176210279999],
            [-42.931098554, -20.417663339999901],
            [-42.931263633, -20.417712072],
            [-42.9314564599999, -20.417795683999898],
            [-42.931604235999899, -20.417874153],
            [-42.931768177, -20.417930301999899],
            [-42.932652335999897, -20.418240992999898],
            [-42.932830447, -20.418281170999901],
            [-42.9330709669999, -20.418271447999899],
            [-42.933278679999901, -20.418200151999901],
            [-42.933464489999899, -20.418089476],
            [-42.933598216999897, -20.417949431],
            [-42.933674359, -20.417816543999901],
            [-42.933801446, -20.417516234999901],
            [-42.933893151999897, -20.417255621],
            [-42.934169202, -20.416492622],
            [-42.934193563, -20.416450507],
            [-42.934290384999898, -20.416319909],
            [-42.934388813999902, -20.416230968],
            [-42.934537711, -20.416134151999898],
            [-42.934709822999899, -20.416090572],
            [-42.934946117, -20.416085403999901],
            [-42.935145540999898, -20.416119733999899],
            [-42.935300659999903, -20.416199897999899],
            [-42.935470696, -20.416326121999901],
            [-42.935721159, -20.416590810999899],
            [-42.936293973, -20.4171011789999],
            [-42.936605438999898, -20.417332685],
            [-42.936733954, -20.417399836999898],
            [-42.936912639999903, -20.417474065],
            [-42.937017824, -20.417497732999902],
            [-42.937153983, -20.417514325],
            [-42.938419156999899, -20.417403185999898],
            [-42.939412563, -20.417320187],
            [-42.940004119, -20.41726655],
            [-42.940328900999901, -20.416856757999899],
            [-42.940390426, -20.416749947],
            [-42.940118196, -20.41624589],
            [-42.939965770999898, -20.415934367],
            [-42.939316859, -20.414614392],
            [-42.939156485, -20.414279177],
            [-42.939082235999898, -20.4141239799999],
            [-42.939012675999898, -20.413967713],
            [-42.938956436, -20.413848409],
            [-42.938897583, -20.413723564999898],
            [-42.938832282, -20.4135118149999],
            [-42.938669483, -20.412888973],
            [-42.938620111, -20.412565151],
            [-42.938605003, -20.412466067],
            [-42.938571774, -20.412248121999902],
            [-42.938515579, -20.412014228],
            [-42.938457067999899, -20.411723039],
            [-42.938377933, -20.411372997999901],
            [-42.93825615, -20.410979097999899],
            [-42.938184044, -20.41080728],
            [-42.938111870999897, -20.410635301],
            [-42.937936968, -20.410321269999901],
            [-42.937152900999898, -20.408704223],
            [-42.936958321, -20.408215093],
            [-42.936781521999897, -20.407318298],
            [-42.936580852, -20.4061851129999],
            [-42.936546803999903, -20.405995468999901],
            [-42.936493098, -20.405732128999901],
            [-42.936372605, -20.4054144639999],
            [-42.936254289, -20.405211601],
            [-42.936068185, -20.404960402],
            [-42.935847886, -20.404780944],
            [-42.935537766, -20.404622279],
            [-42.935228179, -20.4045181299999],
            [-42.934910156999898, -20.404480603],
            [-42.934490431, -20.4044974339999],
            [-42.93413328, -20.404557963999899],
            [-42.933782745, -20.404726788999898],
            [-42.933547723999901, -20.404896768999901],
            [-42.933281238999903, -20.405150122999899],
            [-42.933075003999903, -20.405412654],
            [-42.932601512, -20.406034566],
            [-42.932151818, -20.406644921999899],
            [-42.931647977, -20.4073323379999],
            [-42.93144411, -20.407553207]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 64,
        "id": 36975,
        "CD_SETOR": "315210505060067P",
        "AREA_KM2": 0.579824,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 230
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.90051741, -20.415080072],
            [-42.900532004999903, -20.415145799],
            [-42.900653991, -20.41555203],
            [-42.900684564, -20.4156790719999],
            [-42.900657264, -20.415855388],
            [-42.900537439999901, -20.416028753],
            [-42.900386577999903, -20.416168027],
            [-42.900196786, -20.416340385],
            [-42.900042603, -20.416487485999902],
            [-42.899514305, -20.416955072],
            [-42.899402716999901, -20.417077013],
            [-42.899361128, -20.417148222],
            [-42.899355621, -20.417158298999901],
            [-42.899348687, -20.4171709579999],
            [-42.899274713, -20.417307568],
            [-42.899220887, -20.417493132999901],
            [-42.899196374, -20.417614016999899],
            [-42.899193785, -20.417755096],
            [-42.899204257999898, -20.417902796],
            [-42.899260829, -20.418087007999901],
            [-42.899317965, -20.418263055999901],
            [-42.899368304999904, -20.418371793999899],
            [-42.899405889, -20.418505652],
            [-42.899412706, -20.418680641],
            [-42.899383663, -20.418827911999902],
            [-42.899364580999901, -20.41889602],
            [-42.899307714, -20.41906112],
            [-42.899236046999903, -20.419162371],
            [-42.899103251, -20.419260356999899],
            [-42.898808329, -20.41944132],
            [-42.898582512, -20.419609039],
            [-42.8985033599999, -20.419675011],
            [-42.8984115, -20.4198100629999],
            [-42.898371310999899, -20.419913772],
            [-42.898324459, -20.42011782],
            [-42.898323263999899, -20.420235437],
            [-42.898373271, -20.420527056],
            [-42.898416220999898, -20.420734694],
            [-42.898469532, -20.421073282999899],
            [-42.89846778, -20.421285048],
            [-42.898476235, -20.421511134],
            [-42.898475418, -20.421629955999901],
            [-42.898453534999902, -20.421836566],
            [-42.89839254, -20.422009494999902],
            [-42.898351289999901, -20.422028359],
            [-42.898161718, -20.422030255],
            [-42.898016094, -20.422043218999899],
            [-42.897930760999898, -20.422059513],
            [-42.897815801, -20.422074616],
            [-42.897460036, -20.422071968],
            [-42.897325235, -20.422107062999899],
            [-42.897232126999903, -20.422157316],
            [-42.897153967999898, -20.422213574],
            [-42.897140653999898, -20.422328901999901],
            [-42.897253987999903, -20.422551628],
            [-42.8975628869999, -20.422823170999902],
            [-42.897639055, -20.422911161999899],
            [-42.897704549, -20.423069869],
            [-42.897693655999902, -20.423201583],
            [-42.897639378, -20.423325836999901],
            [-42.897533570999897, -20.423434697999902],
            [-42.89743042, -20.423492490999902],
            [-42.8973475559999, -20.423545702999899],
            [-42.8972744289999, -20.423586079],
            [-42.897232190999901, -20.423609966999901],
            [-42.897117383, -20.4235951759999],
            [-42.896992248999901, -20.423605284],
            [-42.896819798, -20.423601871],
            [-42.896668104999897, -20.423590948],
            [-42.896505595999898, -20.423555661],
            [-42.896146722, -20.423506489],
            [-42.896070075999901, -20.423489962],
            [-42.896038604, -20.423468713],
            [-42.896007133, -20.423447463999899],
            [-42.895986573999899, -20.423391672],
            [-42.895882688, -20.423127018999899],
            [-42.895717201, -20.42305665],
            [-42.895681725, -20.423013074],
            [-42.895655448999896, -20.4229405419999],
            [-42.895637568, -20.422891195],
            [-42.895512391, -20.42276907],
            [-42.895501081, -20.422762673],
            [-42.895349118999903, -20.422659086],
            [-42.895280844999903, -20.422610569],
            [-42.8952287279999, -20.422588467],
            [-42.895153669, -20.422575631],
            [-42.895103252, -20.422593779],
            [-42.895040663, -20.422617577],
            [-42.894971276, -20.422687328999899],
            [-42.894934967, -20.422723297999902],
            [-42.894907683, -20.42278035],
            [-42.894905607999902, -20.422859618],
            [-42.894909793999901, -20.422973515],
            [-42.894900962999898, -20.423018156],
            [-42.89489902, -20.42310778],
            [-42.8949120629999, -20.423146032],
            [-42.894923489999897, -20.42318598],
            [-42.894936037, -20.4232247049999],
            [-42.894951915, -20.423273706],
            [-42.894989704, -20.4233213639999],
            [-42.894992338999899, -20.423392653999901],
            [-42.894951219999903, -20.423487797],
            [-42.894873462, -20.423668404999901],
            [-42.894823674999898, -20.423851976],
            [-42.894770378, -20.423977991],
            [-42.894743439, -20.424046932],
            [-42.894621818, -20.424265800999901],
            [-42.894578248999899, -20.424376254],
            [-42.894528992999902, -20.4245572469999],
            [-42.894529356, -20.424655861],
            [-42.894523252999903, -20.4247611599999],
            [-42.894515124, -20.424884015],
            [-42.894495161, -20.425010491],
            [-42.894473005, -20.425150900999899],
            [-42.8944269959999, -20.425268355999901],
            [-42.894343189, -20.425412736999899],
            [-42.894237254, -20.425549348999901],
            [-42.894164267, -20.425606629999901],
            [-42.894056104999898, -20.4256556949999],
            [-42.893911269999897, -20.425726841],
            [-42.893884832, -20.425760065],
            [-42.893835134, -20.425837595],
            [-42.893792743999903, -20.425974593],
            [-42.893692383, -20.426211167],
            [-42.893672202999902, -20.426255157999901],
            [-42.89360878, -20.426393407],
            [-42.893546118, -20.426519718999899],
            [-42.893535484, -20.426553043999899],
            [-42.893531740999897, -20.426602589],
            [-42.893556985, -20.426670511999902],
            [-42.893660854, -20.4267937659999],
            [-42.893740223999899, -20.426882287],
            [-42.893835157999902, -20.426947191999901],
            [-42.893875787999903, -20.426986622999902],
            [-42.893890448, -20.427044211],
            [-42.893875297, -20.427178822],
            [-42.893919344, -20.427416702999899],
            [-42.893924888, -20.427515496999899],
            [-42.893902180999902, -20.427709707],
            [-42.893879884999897, -20.4278685469999],
            [-42.893805648999901, -20.428093153],
            [-42.893785328, -20.4282314189999],
            [-42.893776316, -20.428402747],
            [-42.893746155, -20.428437066],
            [-42.8935428039999, -20.428526325],
            [-42.893349231999899, -20.428546253],
            [-42.893270888, -20.428541203],
            [-42.893265058999901, -20.428539665999899],
            [-42.893214788, -20.428544106999901],
            [-42.893108393, -20.4285074699999],
            [-42.893079464, -20.4284916199999],
            [-42.893029579, -20.428489587999898],
            [-42.892974186999901, -20.428506276999901],
            [-42.89293656, -20.4285179509999],
            [-42.892789173, -20.428573873999898],
            [-42.892651720999901, -20.428604107],
            [-42.892524489, -20.428644130999899],
            [-42.892423474999902, -20.428730059],
            [-42.89228056, -20.428854837],
            [-42.892116644999902, -20.429020335],
            [-42.892040935999901, -20.4291052529999],
            [-42.892004095999901, -20.429127461],
            [-42.892031984, -20.429136228999901],
            [-42.892107707, -20.429260805],
            [-42.892106908, -20.429401933999898],
            [-42.892134085, -20.429489498999899],
            [-42.892121263, -20.429537314],
            [-42.892044534, -20.429593575999899],
            [-42.892007006, -20.429657209],
            [-42.892000337, -20.429735731999902],
            [-42.89199868, -20.429792651],
            [-42.891988763, -20.429855701],
            [-42.892069889, -20.429885831],
            [-42.89209403, -20.42992874],
            [-42.892110708, -20.430001029],
            [-42.892163919, -20.430081087],
            [-42.89222805, -20.430144357],
            [-42.892205723, -20.43022604],
            [-42.89209403, -20.430257487999899],
            [-42.8920830389999, -20.430289315],
            [-42.8921144299999, -20.43036782],
            [-42.89215259, -20.430378457],
            [-42.892154295999902, -20.430381269],
            [-42.892154935999898, -20.43038253],
            [-42.892163476999897, -20.430391988999901],
            [-42.892172017999897, -20.430401447999898],
            [-42.892187052999901, -20.430425661999902],
            [-42.892233571, -20.430456276],
            [-42.892253075, -20.430473493],
            [-42.892278843, -20.4304962379999],
            [-42.892293192, -20.430495825],
            [-42.892298008, -20.430495687],
            [-42.892302553, -20.430495556],
            [-42.892307128, -20.430495424999901],
            [-42.892311988, -20.4304952849999],
            [-42.892454082, -20.430504755999898],
            [-42.892660619, -20.430376327],
            [-42.89279736, -20.430270381],
            [-42.89303552, -20.430038836999898],
            [-42.893279123999903, -20.429800503],
            [-42.893519929, -20.429680587999901],
            [-42.893760932, -20.429504762],
            [-42.893974280999899, -20.429227766999901],
            [-42.894017609999899, -20.42904465],
            [-42.894027590999897, -20.428913893],
            [-42.894075315999899, -20.428563523],
            [-42.894176439, -20.428189811],
            [-42.894241018999899, -20.427878689],
            [-42.894307947999899, -20.427487884],
            [-42.894348593, -20.427447786999899],
            [-42.894548196999899, -20.427324778],
            [-42.894743843999898, -20.427195146],
            [-42.894888583, -20.427057544],
            [-42.895062099, -20.426886227999901],
            [-42.89523192, -20.426718555],
            [-42.895439591, -20.426505875],
            [-42.895588003999897, -20.426369284],
            [-42.8957647679999, -20.426274511],
            [-42.895969390999902, -20.426138925],
            [-42.896473796, -20.426094093],
            [-42.896791502, -20.426105607],
            [-42.897008633, -20.426072207999901],
            [-42.8972997669999, -20.42605399],
            [-42.897576054999902, -20.425969811],
            [-42.897722810999902, -20.425930422],
            [-42.897888356, -20.4258922769999],
            [-42.897836722, -20.425687506],
            [-42.897813927, -20.425560176],
            [-42.897729429, -20.425461873],
            [-42.897742179, -20.4252659059999],
            [-42.897683209, -20.425070731999899],
            [-42.89767741, -20.424978544999899],
            [-42.897702291, -20.424815894999899],
            [-42.897758471, -20.424625662],
            [-42.897855271, -20.424470202999899],
            [-42.897727115999899, -20.424357804],
            [-42.897704734999898, -20.424195535],
            [-42.897624702, -20.424113197999901],
            [-42.897581714999902, -20.424086261],
            [-42.897499958, -20.424035029],
            [-42.897478530999898, -20.424007891],
            [-42.897544820999897, -20.4240155859999],
            [-42.897625843, -20.423997186999902],
            [-42.897698983999902, -20.423964602999899],
            [-42.897769339, -20.423879992],
            [-42.897818781, -20.423826307999899],
            [-42.897953507, -20.423695193],
            [-42.8980132249999, -20.423645853],
            [-42.898105530999899, -20.423593357],
            [-42.898227104999897, -20.42358181],
            [-42.898325229, -20.4235757669999],
            [-42.898468118, -20.423621888],
            [-42.898605736, -20.423689549999899],
            [-42.898718006, -20.423719884],
            [-42.898792532999899, -20.4237307409999],
            [-42.898902882999899, -20.423713964999902],
            [-42.899023739, -20.423658438],
            [-42.899144141, -20.423589455999899],
            [-42.899208442, -20.423549415],
            [-42.899304609, -20.423489532],
            [-42.899474218, -20.423429846],
            [-42.89978258, -20.423396883999899],
            [-42.899861013, -20.423392783999901],
            [-42.899940619999903, -20.423396093],
            [-42.900029263, -20.423399779],
            [-42.900137678, -20.423442329],
            [-42.900299343, -20.423595142999901],
            [-42.900470222, -20.42375792],
            [-42.900582712, -20.423804048],
            [-42.900690106, -20.423839051],
            [-42.900791862999903, -20.423856977],
            [-42.900935418, -20.4238510439999],
            [-42.901037279, -20.423841020999902],
            [-42.901218389999897, -20.423792675],
            [-42.901432083, -20.423712021],
            [-42.901586178999899, -20.423662685999901],
            [-42.901947983999897, -20.423537215],
            [-42.902051601, -20.423507027],
            [-42.902169355999902, -20.4234727209999],
            [-42.902361332999902, -20.423314774999898],
            [-42.9025533719999, -20.42316132],
            [-42.902750746, -20.423030163],
            [-42.902985765, -20.422897286],
            [-42.903087019999901, -20.422841992],
            [-42.903126175, -20.422833257],
            [-42.903165226999903, -20.422690636],
            [-42.903297978999902, -20.4222354149999],
            [-42.903494564, -20.421786358],
            [-42.903534559, -20.42142053],
            [-42.903535502999901, -20.421384368],
            [-42.903548639999897, -20.4212444519999],
            [-42.903557401999898, -20.421141937999899],
            [-42.903607988, -20.420424504],
            [-42.903629006, -20.419694885],
            [-42.903633311, -20.419467025],
            [-42.903608084, -20.419217106],
            [-42.903667104, -20.418473395],
            [-42.903926199, -20.41785334],
            [-42.903433133, -20.417603589999899],
            [-42.903269807999898, -20.417379129],
            [-42.903208080999903, -20.417171141],
            [-42.903091526, -20.41673041],
            [-42.9027837679999, -20.4161603159999],
            [-42.902682008999903, -20.415967242],
            [-42.902352354, -20.416003635],
            [-42.902122643, -20.415952726999901],
            [-42.901792437, -20.4159316299999],
            [-42.901516801999897, -20.415808382],
            [-42.901248500999898, -20.41550412],
            [-42.901107012, -20.415357475999901],
            [-42.900989545999899, -20.415318849999899],
            [-42.90086903, -20.415238512],
            [-42.900852813, -20.41511958],
            [-42.900760143999896, -20.415087555],
            [-42.90075567, -20.4150860079999],
            [-42.900704978, -20.41504975],
            [-42.90051741, -20.415080072]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 65,
        "id": 36976,
        "CD_SETOR": "315210505060068P",
        "AREA_KM2": 0.015928,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 354
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.9195641479999, -20.414980779],
            [-42.920116504, -20.416042316],
            [-42.92022947, -20.416218092],
            [-42.920296481, -20.4163223629999],
            [-42.920552578, -20.416392422999898],
            [-42.920650755999901, -20.416288182],
            [-42.920744720999899, -20.416188415],
            [-42.920740563, -20.41612385],
            [-42.920735180999898, -20.416040273],
            [-42.92072947, -20.415951595],
            [-42.920740468, -20.415875394999901],
            [-42.920758835, -20.415748134],
            [-42.920789429, -20.415102588],
            [-42.92084971, -20.414735505],
            [-42.920845921, -20.414609504999898],
            [-42.920050889, -20.414828918999898],
            [-42.9195641479999, -20.414980779]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 66,
        "id": 36977,
        "CD_SETOR": "315210505060069P",
        "AREA_KM2": 0.012311,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 225
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.9207080259999, -20.41288373],
            [-42.920751607999897, -20.413086705999898],
            [-42.920845921, -20.414609504999898],
            [-42.920972983, -20.414575290999899],
            [-42.921448776, -20.414447175],
            [-42.921387463, -20.413454769999898],
            [-42.921341807, -20.412715786],
            [-42.9207080259999, -20.41288373]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 67,
        "id": 36978,
        "CD_SETOR": "315210505060070P",
        "AREA_KM2": 0.159198,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 67
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.898052307999897, -20.402953307],
            [-42.898326669, -20.4036168],
            [-42.898431189999897, -20.403830899],
            [-42.898692592, -20.404474674],
            [-42.898838617, -20.4048670029999],
            [-42.898871301, -20.404908185],
            [-42.899352620999899, -20.404713480999899],
            [-42.899883293999899, -20.404511591],
            [-42.900010296999902, -20.404463274],
            [-42.900301059, -20.4051790349999],
            [-42.900371122, -20.405273785],
            [-42.900458809, -20.405350783],
            [-42.900541752, -20.405562072],
            [-42.900627178, -20.40583355],
            [-42.900766959999899, -20.406024492],
            [-42.90092473, -20.405894813],
            [-42.901069710999899, -20.4057287639999],
            [-42.901197040999897, -20.405639224],
            [-42.9012488179999, -20.405185026],
            [-42.901323619, -20.405003868999898],
            [-42.9013945949999, -20.404824661],
            [-42.901482433, -20.4046776879999],
            [-42.901548094, -20.40460094],
            [-42.901674781999901, -20.404452574999901],
            [-42.901721817, -20.404413662],
            [-42.901856108999901, -20.404259786],
            [-42.902114159999897, -20.4040546109999],
            [-42.902232043, -20.403917669],
            [-42.902636684, -20.403647077],
            [-42.902727749999897, -20.403530507],
            [-42.902844883999897, -20.403500048],
            [-42.902958285999901, -20.403452353],
            [-42.903321621, -20.403311298999899],
            [-42.903490749999897, -20.403246671],
            [-42.903642132999899, -20.403069424],
            [-42.903712007, -20.402863296],
            [-42.9038516909999, -20.402430063999901],
            [-42.904004722, -20.401982866],
            [-42.904156811999897, -20.401418899],
            [-42.903871554, -20.401152888],
            [-42.903710745999902, -20.401088565],
            [-42.902990326999898, -20.4008505699999],
            [-42.902545368999903, -20.400774086999899],
            [-42.901944322, -20.400726241],
            [-42.9017766119999, -20.401244260999899],
            [-42.901427517999899, -20.4017069],
            [-42.900724149, -20.402237138],
            [-42.9000941749999, -20.402660757],
            [-42.899687742, -20.402742916],
            [-42.899257733, -20.402648691],
            [-42.898840736, -20.402668763999898],
            [-42.898426921, -20.402741442],
            [-42.898052307999897, -20.402953307]
          ],
          [
            [-42.902189262, -20.401653161999899],
            [-42.902218621, -20.4016409289999],
            [-42.902661521, -20.401795745999902],
            [-42.902678873, -20.401918042],
            [-42.902778884, -20.401919837],
            [-42.903197244999902, -20.4018733519999],
            [-42.903224157999901, -20.402073969999901],
            [-42.903221711, -20.402318626],
            [-42.903030879, -20.402465419999899],
            [-42.902810689, -20.402460527],
            [-42.902728308999897, -20.402433548],
            [-42.902699266999903, -20.402564889],
            [-42.901868763, -20.40232352],
            [-42.902003324, -20.4019858939999],
            [-42.902189262, -20.401653161999899]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 68,
        "id": 36979,
        "CD_SETOR": "315210505060071P",
        "AREA_KM2": 1.681882,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 66
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.904533804, -20.387724791],
            [-42.903605352999897, -20.38775606],
            [-42.903592891, -20.38775648],
            [-42.9035194699999, -20.387758952999899],
            [-42.903048299, -20.387774820999901],
            [-42.903012267, -20.3887058769999],
            [-42.902905404, -20.389135887],
            [-42.902864173, -20.3893941329999],
            [-42.9027868599999, -20.389653037],
            [-42.902446364, -20.390836600999901],
            [-42.90243988, -20.391416707],
            [-42.902499377, -20.391879726],
            [-42.902602475, -20.392172834999901],
            [-42.902732284, -20.392583746],
            [-42.902843794, -20.3931849639999],
            [-42.902857648, -20.393517367],
            [-42.902861538, -20.393610692],
            [-42.902828841, -20.3945220159999],
            [-42.902304767, -20.39744264],
            [-42.902177556, -20.399357063],
            [-42.902020275, -20.400491639999899],
            [-42.901944322, -20.400726241],
            [-42.902545368999903, -20.400774086999899],
            [-42.902990326999898, -20.4008505699999],
            [-42.903710745999902, -20.401088565],
            [-42.903871554, -20.401152888],
            [-42.904156811999897, -20.401418899],
            [-42.904251404, -20.401483504999899],
            [-42.904368559, -20.4016063719999],
            [-42.904508246, -20.4017357879999],
            [-42.904539358, -20.401887062999901],
            [-42.904608202, -20.402125751],
            [-42.905098963999897, -20.402799871],
            [-42.905393525, -20.403092038999901],
            [-42.905824845, -20.4036533559999],
            [-42.906110154, -20.404052894],
            [-42.906461964, -20.404468526999899],
            [-42.906624649, -20.404752613],
            [-42.906633978999899, -20.404768905],
            [-42.906827977, -20.4051523269999],
            [-42.906929971, -20.4053085359999],
            [-42.90704471, -20.405388547],
            [-42.907157685, -20.405461465],
            [-42.907293518, -20.405554117],
            [-42.90740879, -20.405640177],
            [-42.907582168, -20.405776607],
            [-42.90765228, -20.405847591999901],
            [-42.907705959999902, -20.405930591],
            [-42.907762612999903, -20.406019863],
            [-42.907832148999901, -20.406133145],
            [-42.907987766, -20.406388408999899],
            [-42.908368542999902, -20.407021529],
            [-42.908711787, -20.407561632],
            [-42.90872912, -20.407271528],
            [-42.908695764, -20.4069014099999],
            [-42.908674690999902, -20.406667591],
            [-42.908703199999898, -20.4065601309999],
            [-42.908723078, -20.406485206],
            [-42.908784507, -20.406385214],
            [-42.9088885359999, -20.406299271],
            [-42.909010329, -20.406218532],
            [-42.909077260999901, -20.406194189],
            [-42.909426531, -20.406079256999899],
            [-42.90968011, -20.405992985999902],
            [-42.909842929, -20.405931917],
            [-42.910056731999902, -20.405825679],
            [-42.910206288, -20.4057443959999],
            [-42.910339576999903, -20.405671954999899],
            [-42.910603034, -20.405442884],
            [-42.910789414, -20.405214941999901],
            [-42.911717962, -20.404062795],
            [-42.912732994, -20.402712337],
            [-42.912753379, -20.402553769999901],
            [-42.912716691999897, -20.402080340999898],
            [-42.912660921999901, -20.4020237239999],
            [-42.912610480999902, -20.401992298],
            [-42.910890115, -20.400406932],
            [-42.912467929999899, -20.398872056999899],
            [-42.913725272, -20.3978972859999],
            [-42.913458143, -20.397344651],
            [-42.913321881999899, -20.397051452],
            [-42.913240089999903, -20.396834339],
            [-42.913194843, -20.396453005],
            [-42.9131911059999, -20.3959250089999],
            [-42.913367623999903, -20.395377926],
            [-42.913523066, -20.394841581],
            [-42.913643619, -20.394550715],
            [-42.91441563, -20.394092015999899],
            [-42.915110996, -20.393573284],
            [-42.915232269999898, -20.393115446],
            [-42.915280132, -20.392524194],
            [-42.915245397, -20.3920083259999],
            [-42.915236438, -20.3915541279999],
            [-42.915120709999897, -20.3912414179999],
            [-42.914861975, -20.391011590999899],
            [-42.9145856069999, -20.390832646],
            [-42.914325124999898, -20.390659524999901],
            [-42.913973069, -20.390332299],
            [-42.913644252, -20.390035693999899],
            [-42.913230858, -20.38965059],
            [-42.913015779, -20.389264195],
            [-42.909423056, -20.390948981],
            [-42.909181571, -20.390881916999898],
            [-42.908871083, -20.39062381],
            [-42.908606428, -20.390263369],
            [-42.907949532, -20.3890180729999],
            [-42.90783299, -20.388870163],
            [-42.907637184, -20.388834184],
            [-42.907338222, -20.388950114],
            [-42.907190485999898, -20.388975435],
            [-42.906603205, -20.388948904],
            [-42.906141663, -20.38889619],
            [-42.905431474999901, -20.388774091999899],
            [-42.905218829, -20.388552054999899],
            [-42.905058794, -20.388186572999899],
            [-42.905033068, -20.387904923],
            [-42.9049955629999, -20.387710432999899],
            [-42.904533804, -20.387724791]
          ],
          [
            [-42.903117241, -20.3922900429999],
            [-42.9033974439999, -20.3921444489999],
            [-42.903535209, -20.392061644],
            [-42.903654156, -20.391891239],
            [-42.904640504, -20.390946197999899],
            [-42.905151197999899, -20.390471885999901],
            [-42.905477045999902, -20.390011990999898],
            [-42.905690235, -20.389693747],
            [-42.905973533, -20.389395328],
            [-42.906169118, -20.389337445],
            [-42.906423404, -20.389265604999899],
            [-42.906776652, -20.389274427],
            [-42.907047243, -20.389278291],
            [-42.907341925, -20.389472623],
            [-42.907584574999902, -20.389763242],
            [-42.907979853, -20.390261461],
            [-42.908140988999897, -20.39045154],
            [-42.909261904999902, -20.392065488999901],
            [-42.909298680999903, -20.392257733999902],
            [-42.909260619999898, -20.392368508999901],
            [-42.908396059, -20.392784704],
            [-42.907570306, -20.393201003999899],
            [-42.907134006, -20.393311164],
            [-42.9055852669999, -20.393414538999899],
            [-42.905118901, -20.393114055999899],
            [-42.904840011, -20.392920558],
            [-42.904728185, -20.392894364],
            [-42.904625709999898, -20.392860321999901],
            [-42.904249116, -20.392955771999901],
            [-42.903962731, -20.393031435],
            [-42.903608635999902, -20.393124188999899],
            [-42.903593379, -20.39263117],
            [-42.903589274, -20.392348789],
            [-42.903445812, -20.392373892],
            [-42.903435348, -20.392312107999899],
            [-42.903131468, -20.39231898],
            [-42.903117241, -20.3922900429999]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 69,
        "id": 36980,
        "CD_SETOR": "315210505060072P",
        "AREA_KM2": 0.009243,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 209
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.902189262, -20.401653161999899],
            [-42.902003324, -20.4019858939999],
            [-42.901868763, -20.40232352],
            [-42.902699266999903, -20.402564889],
            [-42.902728308999897, -20.402433548],
            [-42.902810689, -20.402460527],
            [-42.903030879, -20.402465419999899],
            [-42.903221711, -20.402318626],
            [-42.903224157999901, -20.402073969999901],
            [-42.903197244999902, -20.4018733519999],
            [-42.902778884, -20.401919837],
            [-42.902678873, -20.401918042],
            [-42.902661521, -20.401795745999902],
            [-42.902218621, -20.4016409289999],
            [-42.902189262, -20.401653161999899]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 70,
        "id": 36981,
        "CD_SETOR": "315210505060073P",
        "AREA_KM2": 0.001576,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 183
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.899927049, -20.410988549],
            [-42.899915485999898, -20.411489609999901],
            [-42.9001294, -20.411487682],
            [-42.900133255, -20.411431794999899],
            [-42.900196851, -20.4114279409999],
            [-42.900200705, -20.410976986],
            [-42.899927049, -20.410988549]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 71,
        "id": 36982,
        "CD_SETOR": "315210505060074P",
        "AREA_KM2": 0.083574,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050506",
        "NM_SUBDIST": "1 Subdistrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 379
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.899426006999903, -20.412347047],
            [-42.899772109, -20.413049228],
            [-42.8999425599999, -20.4132661159999],
            [-42.900385018, -20.413802728],
            [-42.900922625, -20.414185018999898],
            [-42.901409123999898, -20.414340072999899],
            [-42.901728895, -20.4144838889999],
            [-42.902037304, -20.414720353],
            [-42.902535477999898, -20.414928910999901],
            [-42.903072099, -20.415033892999901],
            [-42.903847857, -20.415019458],
            [-42.904381498, -20.414855874999901],
            [-42.904888312, -20.414599315],
            [-42.905223173, -20.414402811999899],
            [-42.905317979, -20.414359988999902],
            [-42.905387488, -20.414313992],
            [-42.90518794, -20.4140345009999],
            [-42.905157691999896, -20.413921560999899],
            [-42.905272574, -20.413744574],
            [-42.905324793, -20.413634413999901],
            [-42.905259848999897, -20.413692690999898],
            [-42.905167478, -20.4137682859999],
            [-42.904971955999898, -20.413907879],
            [-42.904600301999899, -20.414164791],
            [-42.904024314, -20.414417528999898],
            [-42.903744998, -20.414491941999898],
            [-42.903449125999899, -20.414508745],
            [-42.903077449, -20.41441342],
            [-42.902759365999898, -20.414305037],
            [-42.902492238, -20.414121311],
            [-42.902317063999902, -20.413987425],
            [-42.902061063, -20.413816440999899],
            [-42.901866378, -20.413735991],
            [-42.901594654, -20.413685288],
            [-42.901446291999903, -20.413582264999899],
            [-42.901309894999898, -20.413495312],
            [-42.901064391, -20.413316501999901],
            [-42.900909219, -20.413105202999901],
            [-42.90085791, -20.412861284],
            [-42.9008118369999, -20.4126565669999],
            [-42.900817657999902, -20.412504027999901],
            [-42.900824395, -20.412301831],
            [-42.900828796, -20.412143226],
            [-42.900796686, -20.412143318999899],
            [-42.900793942999897, -20.412009872999899],
            [-42.900767527, -20.411868189],
            [-42.900767553, -20.411742913],
            [-42.90076604, -20.411471855999899],
            [-42.900760880999897, -20.4112163419999],
            [-42.900751231999898, -20.411123688],
            [-42.900736534, -20.410982557],
            [-42.900699946, -20.410863446],
            [-42.900654319999902, -20.410780832999901],
            [-42.900558299, -20.41064252],
            [-42.9004931919999, -20.4105548369999],
            [-42.900314195, -20.410453146999899],
            [-42.900020786, -20.41033544],
            [-42.899873944, -20.410277397],
            [-42.899386708999899, -20.410089763],
            [-42.899395662, -20.410136093999899],
            [-42.899362800999903, -20.410678237],
            [-42.899343067, -20.411003767999901],
            [-42.899321242, -20.411519068],
            [-42.899366643, -20.411968461],
            [-42.899426006999903, -20.412347047]
          ],
          [
            [-42.899927049, -20.410988549],
            [-42.900200705, -20.410976986],
            [-42.900196851, -20.4114279409999],
            [-42.900133255, -20.411431794999899],
            [-42.9001294, -20.411487682],
            [-42.899915485999898, -20.411489609999901],
            [-42.899927049, -20.410988549]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 72,
        "id": 36983,
        "CD_SETOR": "315210505070001P",
        "AREA_KM2": 0.114519,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 280
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.890658695999903, -20.410473431],
            [-42.890691331, -20.4105104029999],
            [-42.890753313999902, -20.410582975999901],
            [-42.891594163, -20.411486854],
            [-42.893059638999901, -20.4103029629999],
            [-42.893438633, -20.410684107999899],
            [-42.893799524, -20.411112568],
            [-42.894597938999901, -20.4118988879999],
            [-42.895800632, -20.410895377],
            [-42.896010821999901, -20.410705715999899],
            [-42.895256349, -20.409877987999899],
            [-42.894473191, -20.409086491],
            [-42.894149506, -20.408696076],
            [-42.893797753999898, -20.408368481],
            [-42.893726232999903, -20.408289443999902],
            [-42.893253348, -20.407808492],
            [-42.893176962, -20.407736121],
            [-42.893096474999901, -20.407794373999899],
            [-42.8928268619999, -20.407999232],
            [-42.892606208, -20.408187198999901],
            [-42.892362529, -20.408121294],
            [-42.891610172999897, -20.408739532],
            [-42.8914662769999, -20.408859866999901],
            [-42.891881919, -20.409315173],
            [-42.892092768999902, -20.409524367],
            [-42.892333623, -20.409700905],
            [-42.892011008999901, -20.410093886],
            [-42.891903419, -20.4101916729999],
            [-42.892075522, -20.410370079],
            [-42.892048116999902, -20.410414751],
            [-42.891926468999898, -20.4103920219999],
            [-42.891870045, -20.410469507],
            [-42.8916567919999, -20.410513083],
            [-42.891343975, -20.410477619999899],
            [-42.891092279, -20.410310169],
            [-42.891029115, -20.4102873319999],
            [-42.890973365, -20.410289400999901],
            [-42.890658695999903, -20.410473431]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 73,
        "id": 36984,
        "CD_SETOR": "315210505070003P",
        "AREA_KM2": 0.093808,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 162
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.894597938999901, -20.4118988879999],
            [-42.894529088, -20.411973663],
            [-42.893196651, -20.413101431],
            [-42.893649992999897, -20.413483433],
            [-42.893945260999899, -20.413767006999901],
            [-42.893971969999903, -20.413809101],
            [-42.893776532999901, -20.413972722],
            [-42.893950484999898, -20.413952019],
            [-42.894104148999901, -20.414013306],
            [-42.894190454999901, -20.414107988999898],
            [-42.894378752, -20.414314563999898],
            [-42.894422455, -20.414311015],
            [-42.894445899999901, -20.4143336459999],
            [-42.894476668, -20.41438025],
            [-42.894577458999898, -20.414330815],
            [-42.894713407, -20.414264138],
            [-42.895712392, -20.413774175],
            [-42.895996818, -20.413566352],
            [-42.896051564999901, -20.413562734],
            [-42.896066677, -20.413562442999901],
            [-42.896413869999897, -20.4133652689999],
            [-42.896584587999897, -20.413440443],
            [-42.896858212, -20.413200095],
            [-42.89769086, -20.412477673999899],
            [-42.897367808999903, -20.412143683999901],
            [-42.896389664, -20.411104456],
            [-42.896010821999901, -20.410705715999899],
            [-42.895800632, -20.410895377],
            [-42.894597938999901, -20.4118988879999]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 74,
        "id": 36985,
        "CD_SETOR": "315210505070004P",
        "AREA_KM2": 0.111135,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 119
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.895256349, -20.409877987999899],
            [-42.896010821999901, -20.410705715999899],
            [-42.896389664, -20.411104456],
            [-42.897367808999903, -20.412143683999901],
            [-42.89769086, -20.412477673999899],
            [-42.897938841, -20.412732752999901],
            [-42.897975073999902, -20.412791019999901],
            [-42.898014078, -20.412853821999899],
            [-42.89805002, -20.412911693],
            [-42.898160511, -20.4130895979999],
            [-42.898263914, -20.413256091999902],
            [-42.898409436, -20.413490402],
            [-42.898530139999899, -20.413684751999899],
            [-42.898685976, -20.413999615],
            [-42.898738455999897, -20.414067467999899],
            [-42.898812518999897, -20.414163226],
            [-42.898802717, -20.414002046],
            [-42.89875602, -20.413898377999899],
            [-42.898742833, -20.413853338],
            [-42.898741601, -20.413813728],
            [-42.898740286, -20.4137714559999],
            [-42.89875481, -20.4137203569999],
            [-42.899083769999898, -20.4131819149999],
            [-42.899209534, -20.413022183],
            [-42.899426006999903, -20.412347047],
            [-42.899366643, -20.411968461],
            [-42.899321242, -20.411519068],
            [-42.899343067, -20.411003767999901],
            [-42.899362800999903, -20.410678237],
            [-42.899395662, -20.410136093999899],
            [-42.899386708999899, -20.410089763],
            [-42.898737936, -20.40984277],
            [-42.898584727, -20.409794575],
            [-42.898055057, -20.409551916999899],
            [-42.897710022999902, -20.409440615999898],
            [-42.897307391, -20.409310736],
            [-42.897012364, -20.409225591999899],
            [-42.896871613, -20.409197037999899],
            [-42.896479039, -20.409005400999899],
            [-42.896410531999898, -20.408973],
            [-42.895256349, -20.409877987999899]
          ],
          [
            [-42.897947337999902, -20.412620424999901],
            [-42.897810153, -20.412462413],
            [-42.898023453999897, -20.412371919],
            [-42.898474767, -20.412209651999898],
            [-42.898848027, -20.413106974],
            [-42.898618231999897, -20.413732201],
            [-42.897947337999902, -20.412620424999901]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 75,
        "id": 36986,
        "CD_SETOR": "315210505070005P",
        "AREA_KM2": 0.07538,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 389
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.893797753999898, -20.408368481],
            [-42.894149506, -20.408696076],
            [-42.894473191, -20.409086491],
            [-42.895256349, -20.409877987999899],
            [-42.896410531999898, -20.408973],
            [-42.896479039, -20.409005400999899],
            [-42.896871613, -20.409197037999899],
            [-42.897012364, -20.409225591999899],
            [-42.897307391, -20.409310736],
            [-42.897710022999902, -20.409440615999898],
            [-42.898055057, -20.409551916999899],
            [-42.898584727, -20.409794575],
            [-42.898737936, -20.40984277],
            [-42.899386708999899, -20.410089763],
            [-42.899316443, -20.409946749],
            [-42.89918784, -20.40971214],
            [-42.899016236, -20.409433163],
            [-42.898796384, -20.409175783],
            [-42.898595451999903, -20.409036582999899],
            [-42.898009413999901, -20.4088635959999],
            [-42.897676576, -20.408762953999901],
            [-42.897413757, -20.408616300999899],
            [-42.897055795, -20.408406037],
            [-42.896795951999898, -20.408230866999901],
            [-42.89659267, -20.408028793],
            [-42.896347622999897, -20.407655507],
            [-42.896344648, -20.40763097],
            [-42.896227836, -20.407668467999901],
            [-42.896044122, -20.407764754],
            [-42.895631011, -20.408082695],
            [-42.895484384, -20.407936589],
            [-42.895339438999898, -20.407718568],
            [-42.895239956, -20.407480167],
            [-42.895133154999897, -20.407130712],
            [-42.894943057, -20.407109558999899],
            [-42.894678999, -20.407020163],
            [-42.894479562, -20.407021868],
            [-42.8943050829999, -20.407041218],
            [-42.894209076, -20.407581832999899],
            [-42.894139503, -20.407780717],
            [-42.893797753999898, -20.408368481]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 76,
        "id": 36987,
        "CD_SETOR": "315210505070007P",
        "AREA_KM2": 0.047362,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 341
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.894742515, -20.401138826],
            [-42.894056761999899, -20.4016109629999],
            [-42.894199202, -20.401718461],
            [-42.894312989, -20.401823459],
            [-42.894398118, -20.40191318],
            [-42.894551946, -20.402138654999899],
            [-42.894722451999897, -20.402442083],
            [-42.894816408999901, -20.402603709999902],
            [-42.894908616, -20.402948274],
            [-42.895005249999898, -20.4034577309999],
            [-42.894974986, -20.403762247],
            [-42.894870174999902, -20.404888458999899],
            [-42.894829789, -20.4054765459999],
            [-42.894870690999902, -20.405553886],
            [-42.894970596, -20.405504526],
            [-42.895491838999902, -20.40501175],
            [-42.895884927999901, -20.404636487999898],
            [-42.895961826, -20.40453566],
            [-42.895993801, -20.404431229],
            [-42.896022957999897, -20.404325523],
            [-42.896039268, -20.404191609],
            [-42.896069444, -20.404088988999899],
            [-42.896188513, -20.403955461],
            [-42.896340413, -20.403828507],
            [-42.896386585999899, -20.403727537],
            [-42.896421357999898, -20.403630221999901],
            [-42.896425232999903, -20.403534614],
            [-42.896416906999903, -20.403447089999901],
            [-42.896401652, -20.403376045],
            [-42.89636689, -20.403302288],
            [-42.896285204, -20.403213203],
            [-42.896072323999903, -20.402982972],
            [-42.895795708, -20.402702586],
            [-42.895719211, -20.402626486],
            [-42.89565068, -20.402527635999899],
            [-42.895592399999899, -20.402455959],
            [-42.895546179999897, -20.402396974999899],
            [-42.895361256999898, -20.402051328],
            [-42.895192127, -20.401771348],
            [-42.894900408, -20.401271707],
            [-42.894810704999898, -20.401104706999899],
            [-42.894742515, -20.401138826]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 77,
        "id": 36988,
        "CD_SETOR": "315210505070008P",
        "AREA_KM2": 1.053226,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 353
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.871193794, -20.39586453],
            [-42.871462387, -20.395970243],
            [-42.872252902999897, -20.3961604419999],
            [-42.872508771, -20.396214195],
            [-42.872732529, -20.3962103819999],
            [-42.872818147, -20.3961969819999],
            [-42.873011961, -20.39615477],
            [-42.873231899, -20.396078638999899],
            [-42.873599516, -20.395872704999899],
            [-42.873797146, -20.395721598],
            [-42.874173981999903, -20.395404310999901],
            [-42.874678146, -20.394916603999899],
            [-42.874911267999899, -20.394748712],
            [-42.875059187, -20.394669667999899],
            [-42.875240373999901, -20.394586531],
            [-42.87539537, -20.394572470999901],
            [-42.875597775, -20.394575833],
            [-42.875727443999899, -20.394600479],
            [-42.875964954, -20.39467737],
            [-42.876104435999899, -20.394729862],
            [-42.876312562, -20.3948501049999],
            [-42.876515508, -20.395016514],
            [-42.876762398, -20.3953093289999],
            [-42.876941001, -20.395521155],
            [-42.877227101, -20.395929927999902],
            [-42.877406006999898, -20.396281135],
            [-42.877695978, -20.39689163],
            [-42.877830698, -20.397126289],
            [-42.877885544, -20.3971834819999],
            [-42.878056788, -20.397363796],
            [-42.8782018859999, -20.397491248999899],
            [-42.878354448, -20.397628848],
            [-42.879298481, -20.398004226999898],
            [-42.879683265, -20.398175012],
            [-42.880258278, -20.3977161359999],
            [-42.880449971, -20.397790122999901],
            [-42.881149486, -20.39830467],
            [-42.881670759, -20.398570350999901],
            [-42.882229025999898, -20.398691421],
            [-42.882440791, -20.398683197],
            [-42.882595609, -20.3984718479999],
            [-42.883815544, -20.396502666999901],
            [-42.8840890399999, -20.396177168999898],
            [-42.884079648999901, -20.395892370999899],
            [-42.88402861, -20.395597992],
            [-42.884053439999903, -20.395331187999901],
            [-42.884243607, -20.395067475],
            [-42.884520425999902, -20.394884127],
            [-42.884857919999902, -20.394649785999899],
            [-42.885238301, -20.394447306],
            [-42.884397693, -20.394038484],
            [-42.883550439, -20.393608155],
            [-42.883435333, -20.393402847999901],
            [-42.882988671, -20.392521368],
            [-42.882603853, -20.3917993109999],
            [-42.881103707, -20.390984257],
            [-42.879022074, -20.3900929069999],
            [-42.878181018, -20.38969513],
            [-42.876578596999899, -20.389008880999899],
            [-42.875296337, -20.388713056],
            [-42.8736913279999, -20.388750002999899],
            [-42.873148801999903, -20.388557284999901],
            [-42.872243624, -20.387882729],
            [-42.871575431999901, -20.387930035999901],
            [-42.871567415, -20.388255811],
            [-42.871446402999901, -20.388656868999899],
            [-42.871271475999897, -20.389006424999899],
            [-42.870915728999897, -20.389429041],
            [-42.870193082, -20.390142727999901],
            [-42.869941204, -20.390533986],
            [-42.869820513, -20.390850163],
            [-42.869777518, -20.391182525],
            [-42.8698186559999, -20.391591024],
            [-42.869873971999901, -20.391759108],
            [-42.869963534, -20.391993795],
            [-42.870028116, -20.392119646],
            [-42.870147457, -20.3922581849999],
            [-42.870338402, -20.392463396],
            [-42.870576777999901, -20.392676045],
            [-42.870797196999902, -20.392812943],
            [-42.87106747, -20.393014567],
            [-42.871225801, -20.393153814],
            [-42.871369484999903, -20.393299022],
            [-42.871467005999897, -20.3934577059999],
            [-42.871523285, -20.393599815999899],
            [-42.871560170999899, -20.3938284299999],
            [-42.871550127, -20.394064881],
            [-42.8714588019999, -20.394506086],
            [-42.87131126, -20.395216999999899],
            [-42.871193794, -20.39586453]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 78,
        "id": 36989,
        "CD_SETOR": "315210505070009P",
        "AREA_KM2": 0.428303,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 136
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.871193794, -20.39586453],
            [-42.871173068, -20.39590486],
            [-42.871129673, -20.395989300999901],
            [-42.871103432, -20.396040363999902],
            [-42.871059171, -20.396126489],
            [-42.870800589999902, -20.396543489],
            [-42.870475907, -20.396943692],
            [-42.87091909, -20.396925528999901],
            [-42.87120859, -20.396922203],
            [-42.871921, -20.396980664],
            [-42.872775741999902, -20.397136084],
            [-42.873338151, -20.39715223],
            [-42.873709925, -20.397091512],
            [-42.874126702, -20.398009210999898],
            [-42.874433226, -20.398599251999901],
            [-42.874799754, -20.398993203],
            [-42.875261097, -20.399373811],
            [-42.875812643, -20.399522878],
            [-42.8767981129999, -20.399643538],
            [-42.877826188, -20.399787409999899],
            [-42.878286703, -20.39983652],
            [-42.878944063999903, -20.399881544],
            [-42.879635334999897, -20.400114933999902],
            [-42.88026389, -20.400332561],
            [-42.880871677, -20.400519287999899],
            [-42.881716538, -20.400795673999902],
            [-42.882370045999899, -20.4009274709999],
            [-42.883213710999897, -20.401144450999901],
            [-42.8837789459999, -20.401266436],
            [-42.884378445, -20.401462804999898],
            [-42.884990973999898, -20.401714539999901],
            [-42.885374601, -20.401113219],
            [-42.885598721999898, -20.400771149],
            [-42.885741618999901, -20.400511461],
            [-42.885762439, -20.4004194309999],
            [-42.885777775999898, -20.400338437],
            [-42.885772372, -20.4001723],
            [-42.885787025, -20.4001286989999],
            [-42.885806746999897, -20.400070015],
            [-42.885846205, -20.3999526069999],
            [-42.8858780589999, -20.399857822999898],
            [-42.885882726, -20.39981355],
            [-42.885760694999902, -20.399695560999898],
            [-42.885689562, -20.399614616],
            [-42.885592252, -20.399559516],
            [-42.885521254, -20.3995130919999],
            [-42.885421809, -20.3994525539999],
            [-42.885344284, -20.399425806],
            [-42.8852606889999, -20.399408075999901],
            [-42.885220417, -20.399377511],
            [-42.885211136999899, -20.399341835],
            [-42.885143577, -20.399305135],
            [-42.884982767999901, -20.399291322],
            [-42.884902741, -20.399240527],
            [-42.884846043, -20.399185782],
            [-42.884797536, -20.3991225249999],
            [-42.884753664999899, -20.399054869999901],
            [-42.884735927999898, -20.398967268],
            [-42.884735911999897, -20.398885875],
            [-42.884705868, -20.3988360439999],
            [-42.884647846, -20.398803323],
            [-42.884557539, -20.398791694],
            [-42.884505508, -20.398790806],
            [-42.884368150999897, -20.3988145759999],
            [-42.884233631, -20.398834485],
            [-42.883969881, -20.398793978],
            [-42.883419722, -20.398735551],
            [-42.882885691, -20.398676001],
            [-42.882440791, -20.398683197],
            [-42.882229025999898, -20.398691421],
            [-42.881670759, -20.398570350999901],
            [-42.881149486, -20.39830467],
            [-42.880449971, -20.397790122999901],
            [-42.880258278, -20.3977161359999],
            [-42.879683265, -20.398175012],
            [-42.879298481, -20.398004226999898],
            [-42.878354448, -20.397628848],
            [-42.8782018859999, -20.397491248999899],
            [-42.878056788, -20.397363796],
            [-42.877885544, -20.3971834819999],
            [-42.877830698, -20.397126289],
            [-42.877695978, -20.39689163],
            [-42.877406006999898, -20.396281135],
            [-42.877227101, -20.395929927999902],
            [-42.876941001, -20.395521155],
            [-42.876762398, -20.3953093289999],
            [-42.876515508, -20.395016514],
            [-42.876312562, -20.3948501049999],
            [-42.876104435999899, -20.394729862],
            [-42.875964954, -20.39467737],
            [-42.875727443999899, -20.394600479],
            [-42.875597775, -20.394575833],
            [-42.87539537, -20.394572470999901],
            [-42.875240373999901, -20.394586531],
            [-42.875059187, -20.394669667999899],
            [-42.874911267999899, -20.394748712],
            [-42.874678146, -20.394916603999899],
            [-42.874173981999903, -20.395404310999901],
            [-42.873797146, -20.395721598],
            [-42.873599516, -20.395872704999899],
            [-42.873231899, -20.396078638999899],
            [-42.873011961, -20.39615477],
            [-42.872818147, -20.3961969819999],
            [-42.872732529, -20.3962103819999],
            [-42.872508771, -20.396214195],
            [-42.872252902999897, -20.3961604419999],
            [-42.871462387, -20.395970243],
            [-42.871193794, -20.39586453]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 79,
        "id": 36990,
        "CD_SETOR": "315210505070010P",
        "AREA_KM2": 0.076615,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 324
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.885631477999901, -20.4022485909999],
            [-42.885631076, -20.402250699],
            [-42.887405709999904, -20.403714647999902],
            [-42.887906738, -20.404448262],
            [-42.887976901999899, -20.404586464],
            [-42.888021201, -20.404795419],
            [-42.887982873999903, -20.404972481999899],
            [-42.887491544999897, -20.405351582],
            [-42.887204343999898, -20.405547629999901],
            [-42.887010781999898, -20.4057031749999],
            [-42.8869977109999, -20.4057675839999],
            [-42.888078729, -20.405652835],
            [-42.888666312999902, -20.405586166],
            [-42.888756262, -20.405552149999899],
            [-42.888843069, -20.405520271999901],
            [-42.889065719, -20.405419342999899],
            [-42.889339789, -20.405296939],
            [-42.889727342999898, -20.40505916],
            [-42.889969868, -20.404885751999899],
            [-42.890052198, -20.404809142],
            [-42.890174034, -20.4045653659999],
            [-42.890298495, -20.4043410049999],
            [-42.890429728999898, -20.40416593],
            [-42.890485882999897, -20.404033390999899],
            [-42.89055001, -20.403597024],
            [-42.890530568, -20.403368693999901],
            [-42.890356386, -20.403029473999901],
            [-42.890298238999897, -20.402945578999901],
            [-42.8901389769999, -20.40275214],
            [-42.889844636, -20.402512586999901],
            [-42.889744078, -20.40244956],
            [-42.889616416, -20.402388161],
            [-42.889610223, -20.402385400999901],
            [-42.889550149999899, -20.402358632999899],
            [-42.889596447, -20.40240842],
            [-42.889697774999902, -20.4025173869999],
            [-42.890113382, -20.4029978],
            [-42.890163515, -20.4031046449999],
            [-42.890284881, -20.4032133699999],
            [-42.890319581, -20.403346273],
            [-42.889229973999903, -20.403146698999901],
            [-42.889190180999897, -20.403126581],
            [-42.888989358999901, -20.403025049],
            [-42.888812851, -20.402954795],
            [-42.888685037999899, -20.402980725],
            [-42.8885654649999, -20.4030368539999],
            [-42.888397626, -20.403123615],
            [-42.887466061999902, -20.403572771],
            [-42.887451029, -20.403406769],
            [-42.885815349, -20.402078719],
            [-42.885665876, -20.402068082],
            [-42.885631477999901, -20.4022485909999]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 80,
        "id": 36991,
        "CD_SETOR": "315210505070011P",
        "AREA_KM2": 0.530833,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 220
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.878070357999903, -20.417658265999901],
            [-42.87833454, -20.417708509],
            [-42.879358109, -20.416351097],
            [-42.879754267, -20.415971075],
            [-42.880351904999898, -20.415661461],
            [-42.880881437, -20.41514398],
            [-42.881180146, -20.415021477],
            [-42.881645986, -20.4146826479999],
            [-42.8818677209999, -20.4143625889999],
            [-42.881964341999897, -20.413819316999898],
            [-42.881775784, -20.412805077],
            [-42.881823501, -20.4125211849999],
            [-42.881926499, -20.412145042],
            [-42.88212362, -20.41181654],
            [-42.882328001, -20.411484857999898],
            [-42.882454706, -20.411234825],
            [-42.882615162, -20.410821988],
            [-42.882743948, -20.410399902999899],
            [-42.882945338, -20.4101452109999],
            [-42.88308547, -20.409964256],
            [-42.883606124, -20.409609281],
            [-42.884112553, -20.409494701],
            [-42.8844313869999, -20.409412143999901],
            [-42.884871623, -20.409310966],
            [-42.884934582, -20.409298007],
            [-42.884979163, -20.409288831],
            [-42.885111455, -20.4092616],
            [-42.885587211, -20.409166512],
            [-42.885911678999904, -20.409088917],
            [-42.886365993, -20.408951075999902],
            [-42.886727244, -20.408857795],
            [-42.886662911, -20.408457898999899],
            [-42.886477907, -20.407623079],
            [-42.886376653, -20.40713378],
            [-42.886356528, -20.406847424],
            [-42.886335073999902, -20.40671772],
            [-42.886280169, -20.40632348],
            [-42.88626504, -20.40615206],
            [-42.886261191, -20.406108447],
            [-42.886258783999899, -20.406083871],
            [-42.886177811, -20.406076911],
            [-42.886092776999902, -20.406070131],
            [-42.885968835, -20.406058948999899],
            [-42.885546388, -20.406034357],
            [-42.885353941999902, -20.405936588],
            [-42.885238836, -20.405894187999898],
            [-42.885181851, -20.405872753],
            [-42.885152132, -20.405955832],
            [-42.885063824, -20.406170722999899],
            [-42.88493826, -20.406349514999899],
            [-42.884906585, -20.406393938],
            [-42.884877184999901, -20.4064184769999],
            [-42.884706686, -20.406538896],
            [-42.88456475, -20.406631589],
            [-42.884539425999897, -20.4066479869999],
            [-42.884394632, -20.406721443],
            [-42.884238726, -20.40676568],
            [-42.884144524, -20.406794481],
            [-42.884033701, -20.406819712999901],
            [-42.883845742999902, -20.406910072],
            [-42.883707017999903, -20.406971902999899],
            [-42.883487781, -20.40703501],
            [-42.883227201, -20.407072931],
            [-42.883001097, -20.407099978],
            [-42.882837598, -20.407100311],
            [-42.8826805859999, -20.407049926],
            [-42.88254861, -20.406990183999898],
            [-42.882438894, -20.406937957],
            [-42.882317878, -20.406816662],
            [-42.882240629, -20.40669207],
            [-42.882145017, -20.406621182],
            [-42.882024922, -20.406566518],
            [-42.881821839999901, -20.406506869999902],
            [-42.881707368, -20.406485218999901],
            [-42.881472308, -20.406448688999902],
            [-42.881342415999903, -20.406460448],
            [-42.881281783, -20.406468118],
            [-42.881225438999898, -20.406474585999899],
            [-42.881203367999902, -20.406479902999902],
            [-42.881149665, -20.406491409999902],
            [-42.881107875999902, -20.406503744],
            [-42.881083675999903, -20.406508741],
            [-42.881015017, -20.4065020789999],
            [-42.880967271, -20.406496177],
            [-42.8808881799999, -20.406457439999901],
            [-42.880856047999899, -20.406441700999899],
            [-42.880759992, -20.406377011],
            [-42.880593162, -20.406295242999899],
            [-42.88047527, -20.406274031],
            [-42.880396323, -20.4062598259999],
            [-42.880250197, -20.406251816],
            [-42.880211049, -20.406234859999898],
            [-42.880149208, -20.406208074999899],
            [-42.879907052, -20.406026384],
            [-42.879722351999902, -20.406450073],
            [-42.879711834, -20.406698026999901],
            [-42.879676364, -20.406845102],
            [-42.878951427999901, -20.408265195],
            [-42.87902135, -20.408673504],
            [-42.879083301, -20.409409705],
            [-42.879061872, -20.409728362],
            [-42.878968911999898, -20.409969257999901],
            [-42.878759812999903, -20.410435490999902],
            [-42.878516697, -20.410903355999899],
            [-42.878312531999903, -20.411449219],
            [-42.878240934, -20.411943241],
            [-42.878018263999898, -20.412325945],
            [-42.878032686, -20.412521522],
            [-42.878155417999899, -20.413075051],
            [-42.878068665999898, -20.413315444],
            [-42.878005085, -20.413812570999902],
            [-42.878147113999901, -20.4144460549999],
            [-42.878339672, -20.415107748],
            [-42.878353806999897, -20.415572379],
            [-42.878261709999897, -20.416103181],
            [-42.8781324079999, -20.416658841999901],
            [-42.878029362999897, -20.417128734],
            [-42.878070357999903, -20.417658265999901]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 81,
        "id": 36992,
        "CD_SETOR": "315210505070012P",
        "AREA_KM2": 0.221724,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 330
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.886727244, -20.408857795],
            [-42.886365993, -20.408951075999902],
            [-42.885911678999904, -20.409088917],
            [-42.885587211, -20.409166512],
            [-42.885111455, -20.4092616],
            [-42.884979163, -20.409288831],
            [-42.884934582, -20.409298007],
            [-42.884871623, -20.409310966],
            [-42.8844313869999, -20.409412143999901],
            [-42.884112553, -20.409494701],
            [-42.883606124, -20.409609281],
            [-42.88308547, -20.409964256],
            [-42.882945338, -20.4101452109999],
            [-42.882743948, -20.410399902999899],
            [-42.882615162, -20.410821988],
            [-42.882454706, -20.411234825],
            [-42.882328001, -20.411484857999898],
            [-42.88212362, -20.41181654],
            [-42.881926499, -20.412145042],
            [-42.881823501, -20.4125211849999],
            [-42.881775784, -20.412805077],
            [-42.881964341999897, -20.413819316999898],
            [-42.882668789, -20.413566952999901],
            [-42.883616772, -20.413227569],
            [-42.883378411, -20.412616974999899],
            [-42.883549242, -20.412364942999901],
            [-42.883726184999901, -20.412270077999899],
            [-42.883882168999897, -20.4121934],
            [-42.884045197, -20.412216357],
            [-42.884243887, -20.41223364],
            [-42.884575173, -20.412221643],
            [-42.884559441, -20.4121775589999],
            [-42.884591090999898, -20.412118742],
            [-42.884659053999897, -20.412028511],
            [-42.884734663, -20.411959877999902],
            [-42.8848593069999, -20.411906309],
            [-42.885011096, -20.411913491999901],
            [-42.885109660999902, -20.41193874],
            [-42.885210484, -20.411854387999899],
            [-42.885303214, -20.4118041209999],
            [-42.885472405, -20.411751618],
            [-42.885601779, -20.411664478],
            [-42.885648285999899, -20.41149825],
            [-42.885717176999897, -20.4113871449999],
            [-42.885980418, -20.411197248999901],
            [-42.886100061, -20.411133741999901],
            [-42.886188121, -20.411066462999901],
            [-42.886273640999903, -20.41099997],
            [-42.886381465999897, -20.410912083],
            [-42.886453888999903, -20.4108907409999],
            [-42.886505886999899, -20.410897722],
            [-42.886589708, -20.410896185],
            [-42.886632878999897, -20.410891276],
            [-42.886707502, -20.410862822999899],
            [-42.886845943, -20.410824868],
            [-42.886930994, -20.410798779999901],
            [-42.887007820999898, -20.410795879],
            [-42.887116831999897, -20.410867082],
            [-42.887233458, -20.410928718],
            [-42.887327011, -20.410939566],
            [-42.887473815, -20.410957494999899],
            [-42.887612749, -20.410969033],
            [-42.887762344, -20.4110125619999],
            [-42.887813743, -20.411027697],
            [-42.88784097, -20.4110416239999],
            [-42.887907049, -20.4110214909999],
            [-42.887940498, -20.4109779949999],
            [-42.888002292, -20.410977618999901],
            [-42.888082641, -20.410990526],
            [-42.888151239, -20.410994887999902],
            [-42.888234661, -20.411044166999901],
            [-42.888316933999903, -20.411085398],
            [-42.888432135, -20.411133008],
            [-42.88860299, -20.411121556999898],
            [-42.888698594999902, -20.4110964609999],
            [-42.888783705, -20.411074119],
            [-42.888943094999902, -20.411131059],
            [-42.88910952, -20.411106446],
            [-42.889240163, -20.411093310999899],
            [-42.889427573, -20.411168962],
            [-42.889537349, -20.411173638],
            [-42.889597185, -20.411176186],
            [-42.889708255, -20.411160326999902],
            [-42.889785398999898, -20.411158793],
            [-42.88987049, -20.411136765],
            [-42.889936007, -20.411110218],
            [-42.890003006, -20.411091036],
            [-42.8900953039999, -20.411019957999901],
            [-42.890205422, -20.410981003],
            [-42.890313590999902, -20.410965005],
            [-42.890410588, -20.410984115],
            [-42.890510433, -20.411068117],
            [-42.890571972, -20.411048793],
            [-42.890687431999901, -20.411122065],
            [-42.890768411, -20.411216424],
            [-42.89082611, -20.411341514999901],
            [-42.890918009, -20.411447762],
            [-42.890857681999897, -20.411526661],
            [-42.890859551999903, -20.411609358],
            [-42.890835831999901, -20.411659405],
            [-42.890778086999902, -20.411718456],
            [-42.890738792, -20.411812793],
            [-42.890670493, -20.41187124],
            [-42.890613141, -20.411996647],
            [-42.890561035, -20.4120620539999],
            [-42.890509033999898, -20.4121500319999],
            [-42.890691849999897, -20.412278254999901],
            [-42.891594163, -20.411486854],
            [-42.890753313999902, -20.410582975999901],
            [-42.890691331, -20.4105104029999],
            [-42.890658695999903, -20.410473431],
            [-42.889676763999901, -20.410018054],
            [-42.889581393, -20.409952949999902],
            [-42.889422849, -20.409737902],
            [-42.889189584999897, -20.409675326999899],
            [-42.888889193, -20.409636032],
            [-42.888482318999898, -20.409525496999901],
            [-42.8883197, -20.409469631],
            [-42.888317999, -20.409469047],
            [-42.887974544, -20.409075672],
            [-42.887443699, -20.4089067429999],
            [-42.887007852999901, -20.4088170869999],
            [-42.886727244, -20.408857795]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 82,
        "id": 36993,
        "CD_SETOR": "315210505070013P",
        "AREA_KM2": 0.278601,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 21
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.883616772, -20.413227569],
            [-42.882668789, -20.413566952999901],
            [-42.881964341999897, -20.413819316999898],
            [-42.8818677209999, -20.4143625889999],
            [-42.881645986, -20.4146826479999],
            [-42.881180146, -20.415021477],
            [-42.880881437, -20.41514398],
            [-42.880351904999898, -20.415661461],
            [-42.879754267, -20.415971075],
            [-42.879358109, -20.416351097],
            [-42.87833454, -20.417708509],
            [-42.878070357999903, -20.417658265999901],
            [-42.878039311, -20.4177298769999],
            [-42.878002076, -20.417855045],
            [-42.877951665, -20.417913921],
            [-42.877728864999902, -20.418120466999898],
            [-42.877852819999902, -20.4182649689999],
            [-42.878186316, -20.418180479999901],
            [-42.878875052, -20.418111462],
            [-42.879764639, -20.418025459],
            [-42.880428547999898, -20.417962189],
            [-42.880809976, -20.417925472],
            [-42.881573134999897, -20.418066906],
            [-42.881740169, -20.418112011999899],
            [-42.882029653, -20.418183585],
            [-42.882600915, -20.418346624],
            [-42.883057596999897, -20.418457437],
            [-42.883438091, -20.418471299],
            [-42.883615948999903, -20.418444934],
            [-42.883775797999903, -20.418386547999901],
            [-42.884008858, -20.418303224999899],
            [-42.884259502, -20.418184843999899],
            [-42.884284944999898, -20.418095637],
            [-42.884286335999903, -20.418093911],
            [-42.884405031, -20.417931671],
            [-42.884694652, -20.417617935],
            [-42.884949434, -20.417507382999901],
            [-42.88519758, -20.417399709999899],
            [-42.885361034, -20.417315075],
            [-42.885466769999901, -20.417241791],
            [-42.885579306999901, -20.417118491999901],
            [-42.885610051, -20.416973756999901],
            [-42.885586362, -20.416821543],
            [-42.885548367, -20.416575676],
            [-42.885493369999899, -20.416373892],
            [-42.885439280999897, -20.416179147],
            [-42.885350835999901, -20.415870791],
            [-42.885250445999901, -20.415518587999902],
            [-42.885211039, -20.415347397999899],
            [-42.885216279, -20.415234787],
            [-42.885289778, -20.414918906],
            [-42.885296556, -20.414865125999899],
            [-42.885272676999897, -20.414836347999898],
            [-42.885253088999903, -20.414806862],
            [-42.885210805, -20.41478196],
            [-42.885171386, -20.4147709339999],
            [-42.885133878, -20.414769357999901],
            [-42.88510888, -20.414773856],
            [-42.8850766159999, -20.414798033],
            [-42.885046985, -20.414871164],
            [-42.885005495, -20.414948406999901],
            [-42.88491928, -20.415108916999898],
            [-42.884509377, -20.414627684],
            [-42.884588403999899, -20.414566287],
            [-42.884711495, -20.414497100999899],
            [-42.885226757999902, -20.414300098999899],
            [-42.885478647999904, -20.414210185999899],
            [-42.8855122209999, -20.4141989949999],
            [-42.885561246, -20.414181721],
            [-42.885586886999903, -20.4141712959999],
            [-42.885534370999899, -20.414042601999899],
            [-42.885420022999902, -20.413759571999901],
            [-42.885371994, -20.4137693079999],
            [-42.885304832, -20.413763284],
            [-42.885182404, -20.4137350079999],
            [-42.88509995, -20.413695504],
            [-42.885021383, -20.413642223],
            [-42.884941458, -20.413574391999902],
            [-42.884884016999898, -20.413528696999901],
            [-42.884815848999899, -20.413472741],
            [-42.884780396999901, -20.413443437],
            [-42.884722296999897, -20.4133423329999],
            [-42.884570889, -20.41320228],
            [-42.88453221, -20.413140056],
            [-42.884507028, -20.413099545],
            [-42.8844536249999, -20.413024054999902],
            [-42.884421328, -20.41296482],
            [-42.884411567, -20.412820696],
            [-42.883616772, -20.413227569]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 83,
        "id": 36994,
        "CD_SETOR": "315210505070014P",
        "AREA_KM2": 0.048288,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 6
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.888027499, -20.412673454999901],
            [-42.887814485, -20.412610335],
            [-42.887726223999898, -20.4124383609999],
            [-42.887763011999901, -20.412324853],
            [-42.887744886999897, -20.412212684999901],
            [-42.88773529, -20.412139524999901],
            [-42.887687183, -20.412090576],
            [-42.8876348699999, -20.412073644],
            [-42.887576368999902, -20.412076327],
            [-42.886952803, -20.412226295],
            [-42.886457305, -20.412337181999899],
            [-42.886572956, -20.412769214],
            [-42.886618229, -20.412759416],
            [-42.886696488, -20.412946852999902],
            [-42.886739775, -20.412982870999901],
            [-42.886816804, -20.413116601999899],
            [-42.886905288, -20.413196209],
            [-42.886946259999903, -20.413187324],
            [-42.886996396, -20.413312956],
            [-42.887074223, -20.413439904],
            [-42.887158333999899, -20.413530271],
            [-42.8872665439999, -20.413616619],
            [-42.887361064999901, -20.413675605],
            [-42.8874452539999, -20.413728143],
            [-42.887839175, -20.413690109999902],
            [-42.887956478, -20.413664339],
            [-42.888087266999896, -20.413618759],
            [-42.888191707, -20.4135823619999],
            [-42.888306714999899, -20.413543525],
            [-42.888400353, -20.413510784],
            [-42.888524226, -20.413453262],
            [-42.888655465999904, -20.4133939079999],
            [-42.888757094, -20.4133496749999],
            [-42.888823072, -20.413352454999899],
            [-42.888870558999898, -20.413391048999902],
            [-42.889019974, -20.413534887],
            [-42.889842997, -20.412801693999899],
            [-42.889994226999903, -20.412670374],
            [-42.890095314, -20.4125825949999],
            [-42.890217064, -20.4126953419999],
            [-42.890691849999897, -20.412278254999901],
            [-42.890509033999898, -20.4121500319999],
            [-42.890420078, -20.412129249],
            [-42.890285537, -20.412106079999901],
            [-42.889943956, -20.411994503],
            [-42.889805700999901, -20.411971088],
            [-42.889628344999899, -20.411971104],
            [-42.889464705, -20.411976544],
            [-42.889270527999898, -20.412005861999901],
            [-42.889107783, -20.412049237],
            [-42.888948969, -20.412094214],
            [-42.888786973, -20.412201086],
            [-42.888694976999901, -20.412338002999899],
            [-42.8886635389999, -20.412384791],
            [-42.888584293999898, -20.4124372859999],
            [-42.888459135999902, -20.412519873999901],
            [-42.8882471209999, -20.412614408],
            [-42.888094908, -20.412669041],
            [-42.888027499, -20.412673454999901]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 84,
        "id": 36995,
        "CD_SETOR": "315210505070015P",
        "AREA_KM2": 0.062597,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 260
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.885420022999902, -20.413759571999901],
            [-42.885534370999899, -20.414042601999899],
            [-42.885586886999903, -20.4141712959999],
            [-42.885640908, -20.414303678],
            [-42.885708064, -20.414488213],
            [-42.885771986999899, -20.414664063],
            [-42.885778145, -20.414798991999898],
            [-42.885762636, -20.414921627999899],
            [-42.885752119, -20.414982998],
            [-42.886028538999902, -20.415221521],
            [-42.886102039, -20.415330571],
            [-42.886159518999897, -20.4154163269999],
            [-42.886274476, -20.415587837],
            [-42.886548427, -20.41603836],
            [-42.886698947, -20.416237903],
            [-42.886825135, -20.416341637999899],
            [-42.887273337, -20.416630434],
            [-42.887455695, -20.416742117999899],
            [-42.887626226, -20.416825821],
            [-42.887800047, -20.416889803999901],
            [-42.887904202, -20.4169091979999],
            [-42.888253858, -20.416993383],
            [-42.888320311, -20.41701784],
            [-42.88839239, -20.417080316],
            [-42.888502501999902, -20.4171757569999],
            [-42.888488762, -20.417091503999899],
            [-42.888401701, -20.416961405999899],
            [-42.888244904, -20.41685877],
            [-42.888133304, -20.416803525],
            [-42.887962877999897, -20.4167191609999],
            [-42.88779994, -20.416613147],
            [-42.887562957999897, -20.416319057],
            [-42.887187457, -20.415857225],
            [-42.887007102, -20.415599975],
            [-42.886941345, -20.415440493999899],
            [-42.886914530999903, -20.415332919],
            [-42.886907302999902, -20.415267026],
            [-42.886933231999897, -20.415137340999902],
            [-42.886980541999897, -20.414942235],
            [-42.887026451999901, -20.414707398],
            [-42.887034775, -20.41459127],
            [-42.8870486389999, -20.414397837],
            [-42.887009793999901, -20.414067279999902],
            [-42.886973, -20.413963149],
            [-42.886929876, -20.413885324],
            [-42.886904031, -20.413847592],
            [-42.886850948, -20.413770237999898],
            [-42.886819572, -20.413724287],
            [-42.88689017, -20.413681102999899],
            [-42.886948404999899, -20.413653173999901],
            [-42.887094104, -20.413642059],
            [-42.887232907999902, -20.413626569],
            [-42.8872665439999, -20.413616619],
            [-42.887158333999899, -20.413530271],
            [-42.887074223, -20.413439904],
            [-42.886996396, -20.413312956],
            [-42.886946259999903, -20.413187324],
            [-42.886905288, -20.413196209],
            [-42.886816804, -20.413116601999899],
            [-42.886739775, -20.412982870999901],
            [-42.886696488, -20.412946852999902],
            [-42.886618229, -20.412759416],
            [-42.886572956, -20.412769214],
            [-42.886457305, -20.412337181999899],
            [-42.885966964, -20.412406646],
            [-42.885613608, -20.412478525],
            [-42.885349931, -20.412547681],
            [-42.885294667, -20.412581244],
            [-42.885036424, -20.4126756759999],
            [-42.884961892, -20.412743760999899],
            [-42.884915292, -20.4128208499999],
            [-42.884882768999901, -20.4128984389999],
            [-42.884863706, -20.413038184],
            [-42.885005604, -20.4132294569999],
            [-42.885110502, -20.413355367999898],
            [-42.885228966999897, -20.413483788],
            [-42.885302617, -20.4135529059999],
            [-42.885383765, -20.413666206999899],
            [-42.885420022999902, -20.413759571999901]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 85,
        "id": 36996,
        "CD_SETOR": "315210505070016P",
        "AREA_KM2": 0.039925,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 361
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.888502501999902, -20.4171757569999],
            [-42.888707653, -20.4174901119999],
            [-42.889026554999901, -20.417883145],
            [-42.889128711999902, -20.417966538],
            [-42.889182472, -20.418010423999899],
            [-42.889171894999897, -20.417848512],
            [-42.889079745999901, -20.417629093999899],
            [-42.888660122, -20.416733673],
            [-42.888619805, -20.416632693999901],
            [-42.888490273999899, -20.416437485],
            [-42.888478247, -20.415903702],
            [-42.887932405999898, -20.415258353],
            [-42.887896639, -20.4151902569999],
            [-42.887877177, -20.415106255999898],
            [-42.88787788, -20.414976426],
            [-42.887963479999897, -20.414148125999901],
            [-42.887988144, -20.413979883],
            [-42.888010623, -20.4138190359999],
            [-42.887956478, -20.413664339],
            [-42.887839175, -20.413690109999902],
            [-42.8874452539999, -20.413728143],
            [-42.887361064999901, -20.413675605],
            [-42.8872665439999, -20.413616619],
            [-42.887232907999902, -20.413626569],
            [-42.887094104, -20.413642059],
            [-42.886948404999899, -20.413653173999901],
            [-42.88689017, -20.413681102999899],
            [-42.886819572, -20.413724287],
            [-42.886850948, -20.413770237999898],
            [-42.886904031, -20.413847592],
            [-42.886929876, -20.413885324],
            [-42.886973, -20.413963149],
            [-42.887009793999901, -20.414067279999902],
            [-42.8870486389999, -20.414397837],
            [-42.887034775, -20.41459127],
            [-42.887026451999901, -20.414707398],
            [-42.886980541999897, -20.414942235],
            [-42.886933231999897, -20.415137340999902],
            [-42.886907302999902, -20.415267026],
            [-42.886914530999903, -20.415332919],
            [-42.886941345, -20.415440493999899],
            [-42.887007102, -20.415599975],
            [-42.887187457, -20.415857225],
            [-42.887562957999897, -20.416319057],
            [-42.88779994, -20.416613147],
            [-42.887962877999897, -20.4167191609999],
            [-42.888133304, -20.416803525],
            [-42.888244904, -20.41685877],
            [-42.888401701, -20.416961405999899],
            [-42.888488762, -20.417091503999899],
            [-42.888502501999902, -20.4171757569999]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 86,
        "id": 36997,
        "CD_SETOR": "315210505070017P",
        "AREA_KM2": 0.100883,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 68
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.885814421, -20.4175590299999],
            [-42.886032142, -20.417505892],
            [-42.886169859999903, -20.417562815999901],
            [-42.886441510999902, -20.417773504],
            [-42.8867174539999, -20.418089420999902],
            [-42.886938018, -20.418331262],
            [-42.887540595, -20.418548459],
            [-42.888391113999901, -20.418962527],
            [-42.888653437, -20.419105256],
            [-42.888802719999902, -20.419005159999902],
            [-42.888992307, -20.418909427],
            [-42.889135930999899, -20.4188664919999],
            [-42.889329377, -20.418818008],
            [-42.889508473, -20.418781006],
            [-42.889593858, -20.41861166],
            [-42.889614998999903, -20.418495879],
            [-42.889611273999897, -20.418409172999901],
            [-42.889573649999903, -20.418350856],
            [-42.889496228, -20.418307152],
            [-42.889411998, -20.418270366999899],
            [-42.889282997999899, -20.418133429],
            [-42.889225686, -20.418063302],
            [-42.889357213, -20.418034602],
            [-42.889470758999899, -20.4179182],
            [-42.889585881, -20.4178186309999],
            [-42.889687591, -20.417722791],
            [-42.88990376, -20.417592552],
            [-42.889970478, -20.417529945999899],
            [-42.889947793, -20.4175009739999],
            [-42.889944634, -20.417399345],
            [-42.88992357, -20.41733112],
            [-42.889821743, -20.417032237999901],
            [-42.88966026, -20.416542837],
            [-42.889619755, -20.416481980999901],
            [-42.889573738, -20.416412844],
            [-42.889358019, -20.416011207999901],
            [-42.889231935999902, -20.415729928],
            [-42.889198169999901, -20.415650078999899],
            [-42.888933005999903, -20.41530348],
            [-42.888869254, -20.415243863999901],
            [-42.888825531, -20.415216653],
            [-42.888745614, -20.415212446999899],
            [-42.888646607999902, -20.415241664],
            [-42.888484526, -20.415328154],
            [-42.888457259999903, -20.415485889],
            [-42.888478247, -20.415903702],
            [-42.888490273999899, -20.416437485],
            [-42.888619805, -20.416632693999901],
            [-42.888660122, -20.416733673],
            [-42.889079745999901, -20.417629093999899],
            [-42.889171894999897, -20.417848512],
            [-42.889182472, -20.418010423999899],
            [-42.889128711999902, -20.417966538],
            [-42.889026554999901, -20.417883145],
            [-42.888707653, -20.4174901119999],
            [-42.888502501999902, -20.4171757569999],
            [-42.88839239, -20.417080316],
            [-42.888320311, -20.41701784],
            [-42.888253858, -20.416993383],
            [-42.887904202, -20.4169091979999],
            [-42.887800047, -20.416889803999901],
            [-42.887626226, -20.416825821],
            [-42.887455695, -20.416742117999899],
            [-42.887273337, -20.416630434],
            [-42.886825135, -20.416341637999899],
            [-42.886698947, -20.416237903],
            [-42.886548427, -20.41603836],
            [-42.886274476, -20.415587837],
            [-42.886159518999897, -20.4154163269999],
            [-42.886102039, -20.415330571],
            [-42.886028538999902, -20.415221521],
            [-42.885752119, -20.414982998],
            [-42.885704405999903, -20.415086894999899],
            [-42.885664463, -20.4152028409999],
            [-42.8856248989999, -20.41539956],
            [-42.885620554, -20.415527687999901],
            [-42.885630510999903, -20.41563421],
            [-42.885698073, -20.415846621],
            [-42.885801778, -20.415938336],
            [-42.885880652, -20.416119162],
            [-42.885904466999897, -20.416303711999898],
            [-42.88590232, -20.416484258],
            [-42.885861411999898, -20.4167435919999],
            [-42.885813492, -20.4169132],
            [-42.885814508, -20.4170286149999],
            [-42.885818002, -20.417244266],
            [-42.885812592, -20.417301472],
            [-42.885795637999898, -20.417455683],
            [-42.885814421, -20.4175590299999]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 87,
        "id": 36998,
        "CD_SETOR": "315210505070018P",
        "AREA_KM2": 0.034217,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 171
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.888478247, -20.415903702],
            [-42.888457259999903, -20.415485889],
            [-42.888484526, -20.415328154],
            [-42.888646607999902, -20.415241664],
            [-42.888745614, -20.415212446999899],
            [-42.888825531, -20.415216653],
            [-42.888869254, -20.415243863999901],
            [-42.888933005999903, -20.41530348],
            [-42.889198169999901, -20.415650078999899],
            [-42.889231935999902, -20.415729928],
            [-42.889358019, -20.416011207999901],
            [-42.889573738, -20.416412844],
            [-42.889619755, -20.416481980999901],
            [-42.88966026, -20.416542837],
            [-42.889724031, -20.416523668],
            [-42.889807635999901, -20.416626681],
            [-42.889839798, -20.4166387399999],
            [-42.889992456, -20.416548625],
            [-42.890246433, -20.41634504],
            [-42.890647292, -20.416014280999899],
            [-42.890761778, -20.415910046],
            [-42.890845366999898, -20.415833178],
            [-42.890911989, -20.41577431],
            [-42.890987971, -20.415669065],
            [-42.890866162999899, -20.415646594],
            [-42.890665578, -20.41560959],
            [-42.890030635, -20.415299386],
            [-42.889871715, -20.415216375999901],
            [-42.889804206, -20.415169315999901],
            [-42.889671738999901, -20.415105938999901],
            [-42.889537552, -20.414945847999899],
            [-42.889372023, -20.415047088999899],
            [-42.889168175, -20.415171765],
            [-42.889126571, -20.415109507],
            [-42.889053256, -20.414999796],
            [-42.888964154, -20.414878207],
            [-42.888640814, -20.414412847999898],
            [-42.888572956, -20.414303898999901],
            [-42.888510240999899, -20.414199642],
            [-42.888419206, -20.414048305999899],
            [-42.888245158, -20.413955354999899],
            [-42.888087927, -20.413829533],
            [-42.888047307, -20.413784690999901],
            [-42.887956478, -20.413664339],
            [-42.888010623, -20.4138190359999],
            [-42.887988144, -20.413979883],
            [-42.887963479999897, -20.414148125999901],
            [-42.88787788, -20.414976426],
            [-42.887877177, -20.415106255999898],
            [-42.887896639, -20.4151902569999],
            [-42.887932405999898, -20.415258353],
            [-42.888478247, -20.415903702]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 88,
        "id": 36999,
        "CD_SETOR": "315210505070019P",
        "AREA_KM2": 0.069773,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 145
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.894378752, -20.414314563999898],
            [-42.894190454999901, -20.414107988999898],
            [-42.894104148999901, -20.414013306],
            [-42.893950484999898, -20.413952019],
            [-42.893776532999901, -20.413972722],
            [-42.892554468, -20.41510898],
            [-42.892751628, -20.415301570999901],
            [-42.892814041999898, -20.415291001],
            [-42.892970071, -20.415211848],
            [-42.893414612, -20.414941105],
            [-42.893630791, -20.41480035],
            [-42.893796191, -20.414699126],
            [-42.893925483, -20.41462],
            [-42.894005365999902, -20.414613335],
            [-42.893876882, -20.414755699],
            [-42.893673892, -20.414909974999901],
            [-42.893631803999902, -20.414964300999898],
            [-42.893541961, -20.415023326],
            [-42.893420992, -20.415160111],
            [-42.893350891, -20.4152385629999],
            [-42.893234816, -20.41534962],
            [-42.89311973, -20.4154338939999],
            [-42.893056464, -20.415483843999901],
            [-42.893020624, -20.415503424999901],
            [-42.892856477999899, -20.415543328999899],
            [-42.892646785999901, -20.4155774399999],
            [-42.892481873, -20.415582136],
            [-42.892408583, -20.415565821],
            [-42.892181433, -20.415562459999901],
            [-42.892111198, -20.415607221],
            [-42.892015978, -20.415630276999899],
            [-42.892252598, -20.4157347],
            [-42.892288251, -20.415853841999901],
            [-42.892373565999897, -20.4158524059999],
            [-42.892510898999902, -20.4158591139999],
            [-42.893344630999898, -20.415861912],
            [-42.893420170999903, -20.415867507],
            [-42.893985318, -20.415895485],
            [-42.894838633999903, -20.415937450999898],
            [-42.895048464999903, -20.41595144],
            [-42.895546146999898, -20.415984946],
            [-42.896129894, -20.4158343719999],
            [-42.896263131, -20.415796545],
            [-42.896504996, -20.415666737],
            [-42.896778963, -20.415517113],
            [-42.897338510999901, -20.415379308999899],
            [-42.8973431699999, -20.415377652],
            [-42.897312462999899, -20.415353769],
            [-42.897257067999902, -20.415337985],
            [-42.896950192, -20.41513523],
            [-42.89690853, -20.41499235],
            [-42.896880189999898, -20.414911115999899],
            [-42.896505891999901, -20.414048985],
            [-42.8964412, -20.413893035999902],
            [-42.896433975, -20.413901338999899],
            [-42.896356292, -20.413979598],
            [-42.896156886, -20.4140721169999],
            [-42.896094713, -20.414100963],
            [-42.896036103, -20.414125410999901],
            [-42.896033890999902, -20.414237519],
            [-42.896031257999901, -20.414370924],
            [-42.895994604999899, -20.414411271999899],
            [-42.895874076, -20.414463435999899],
            [-42.89569854, -20.4145294489999],
            [-42.895236055999902, -20.414703373],
            [-42.8949117129999, -20.414831501],
            [-42.894624065999899, -20.414551649],
            [-42.894531691999902, -20.4144595409999],
            [-42.894457068, -20.414420607999901],
            [-42.894378752, -20.414314563999898]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 89,
        "id": 37000,
        "CD_SETOR": "315210505070020P",
        "AREA_KM2": 0.050702,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 355
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.896584587999897, -20.413440443],
            [-42.896436862, -20.4135863069999],
            [-42.896313839, -20.4137478389999],
            [-42.896408994, -20.413836546],
            [-42.8964412, -20.413893035999902],
            [-42.896505891999901, -20.414048985],
            [-42.896880189999898, -20.414911115999899],
            [-42.89690853, -20.41499235],
            [-42.896950192, -20.41513523],
            [-42.897257067999902, -20.415337985],
            [-42.897312462999899, -20.415353769],
            [-42.8973431699999, -20.415377652],
            [-42.897531275999903, -20.415310771],
            [-42.898220283999898, -20.4150771],
            [-42.898714933, -20.415056991],
            [-42.898771177, -20.414748475],
            [-42.898753069, -20.414673471],
            [-42.898750927, -20.414606959],
            [-42.898477838, -20.414170544999902],
            [-42.898718856999899, -20.414084247999899],
            [-42.898685976, -20.413999615],
            [-42.898530139999899, -20.413684751999899],
            [-42.898409436, -20.413490402],
            [-42.898263914, -20.413256091999902],
            [-42.898160511, -20.4130895979999],
            [-42.89805002, -20.412911693],
            [-42.898014078, -20.412853821999899],
            [-42.897975073999902, -20.412791019999901],
            [-42.897938841, -20.412732752999901],
            [-42.89769086, -20.412477673999899],
            [-42.896858212, -20.413200095],
            [-42.896584587999897, -20.413440443]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 90,
        "id": 37001,
        "CD_SETOR": "315210505070021P",
        "AREA_KM2": 0.188549,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 175
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.88966026, -20.416542837],
            [-42.889821743, -20.417032237999901],
            [-42.88992357, -20.41733112],
            [-42.889944634, -20.417399345],
            [-42.889947793, -20.4175009739999],
            [-42.889970478, -20.417529945999899],
            [-42.889998767999899, -20.417566076999901],
            [-42.890132542999901, -20.417674734999899],
            [-42.890616506999898, -20.418086461],
            [-42.890764665999903, -20.418221145],
            [-42.89084732, -20.418233347],
            [-42.890988218, -20.418225741],
            [-42.89110443, -20.418245452999901],
            [-42.891989124, -20.4186747689999],
            [-42.893977886, -20.419632786],
            [-42.89422175, -20.419679565],
            [-42.894611687, -20.419723202999901],
            [-42.895390068, -20.420016775],
            [-42.895774084, -20.419624021999901],
            [-42.896268377999903, -20.4191342509999],
            [-42.896447398, -20.418948699999898],
            [-42.896540167999902, -20.418850839999902],
            [-42.896197534999899, -20.418664395999901],
            [-42.895665376, -20.418521456],
            [-42.895212426999898, -20.418371088],
            [-42.894897741, -20.418246594],
            [-42.894714011, -20.418123287999901],
            [-42.894473261, -20.4179328079999],
            [-42.894438575, -20.41767913],
            [-42.894411894, -20.417641561],
            [-42.894373081, -20.417586906999901],
            [-42.894365434, -20.417569353],
            [-42.895546146999898, -20.415984946],
            [-42.895048464999903, -20.41595144],
            [-42.894838633999903, -20.415937450999898],
            [-42.893985318, -20.415895485],
            [-42.893420170999903, -20.415867507],
            [-42.893344630999898, -20.415861912],
            [-42.892510898999902, -20.4158591139999],
            [-42.892373565999897, -20.4158524059999],
            [-42.892288251, -20.415853841999901],
            [-42.892252598, -20.4157347],
            [-42.892085703, -20.4157673939999],
            [-42.892014831, -20.415781277999901],
            [-42.891715470999898, -20.415777638],
            [-42.891594354999903, -20.415776165999901],
            [-42.890987971, -20.415669065],
            [-42.890911989, -20.41577431],
            [-42.890845366999898, -20.415833178],
            [-42.890761778, -20.415910046],
            [-42.890647292, -20.416014280999899],
            [-42.890246433, -20.41634504],
            [-42.889992456, -20.416548625],
            [-42.889839798, -20.4166387399999],
            [-42.889807635999901, -20.416626681],
            [-42.889724031, -20.416523668],
            [-42.88966026, -20.416542837]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 91,
        "id": 37002,
        "CD_SETOR": "315210505070023P",
        "AREA_KM2": 3.106628,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 24
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.852664416, -20.3810695449999],
            [-42.8541505439999, -20.3794323669999],
            [-42.854281210999901, -20.379262003999902],
            [-42.85468507, -20.3791266919999],
            [-42.855147911, -20.3790518509999],
            [-42.855888045, -20.378759172999899],
            [-42.856452983999901, -20.378416412],
            [-42.857205609999902, -20.377615529],
            [-42.858580904, -20.3759882169999],
            [-42.858447216999899, -20.3755700799999],
            [-42.858363477999902, -20.375438315999901],
            [-42.85814045, -20.375317593],
            [-42.857868058, -20.375154511999899],
            [-42.857529751, -20.374930128],
            [-42.856890259999901, -20.37447076],
            [-42.856364004999897, -20.373964868],
            [-42.855973138, -20.373570722999901],
            [-42.855614925999902, -20.3731433959999],
            [-42.85543925, -20.37296205],
            [-42.855311902, -20.372867422],
            [-42.855209045, -20.372852069],
            [-42.854924516, -20.372889781],
            [-42.85424261, -20.372935750999901],
            [-42.853664986, -20.372996838999899],
            [-42.8533442579999, -20.3731181429999],
            [-42.852723216999898, -20.373350275],
            [-42.849274624, -20.375891162],
            [-42.848334420999898, -20.376386133],
            [-42.847866799, -20.376026915],
            [-42.848007454, -20.375519395],
            [-42.847969342999903, -20.375062242],
            [-42.847713518, -20.37472138],
            [-42.847612196999897, -20.374059422],
            [-42.847346901, -20.373148946999901],
            [-42.847094287999901, -20.37223863],
            [-42.847188322, -20.371254275],
            [-42.8478619159999, -20.370815795999899],
            [-42.8488646019999, -20.37089759],
            [-42.848955428999901, -20.3711358649999],
            [-42.8492449519999, -20.371524731],
            [-42.849404551, -20.37191344],
            [-42.84960591, -20.371994792],
            [-42.849947588, -20.37186797],
            [-42.85014903, -20.371584647],
            [-42.850303235999903, -20.371150464],
            [-42.85040227, -20.37081032],
            [-42.850321720999901, -20.3702997779999],
            [-42.850274011, -20.369899577999899],
            [-42.850258735999901, -20.369369273],
            [-42.850471537, -20.369301263999901],
            [-42.850884826, -20.369167262],
            [-42.851216902, -20.369062111999899],
            [-42.851599907, -20.36896466],
            [-42.851743036999899, -20.368934414],
            [-42.852303588, -20.368852623],
            [-42.852412436999899, -20.368803736],
            [-42.852460418, -20.368765249],
            [-42.852557615, -20.368687286],
            [-42.852677043, -20.368567504999898],
            [-42.852790356, -20.3684422269999],
            [-42.852872203999901, -20.368307640999902],
            [-42.852905451, -20.368233792],
            [-42.852981117999903, -20.368065719999901],
            [-42.853031003, -20.367861981],
            [-42.853015534999898, -20.367773449],
            [-42.852987841, -20.367660618999899],
            [-42.852881753, -20.367463385999901],
            [-42.852602082, -20.36716941],
            [-42.852474608, -20.367062878],
            [-42.852263354999899, -20.3668863309999],
            [-42.852019002, -20.366527602],
            [-42.851845414, -20.366214592999899],
            [-42.851712595, -20.365936212],
            [-42.851600276999903, -20.3656696699999],
            [-42.851539294, -20.365513888],
            [-42.851460276999902, -20.365324606999899],
            [-42.851422966, -20.365154151999899],
            [-42.851314037999899, -20.365149985],
            [-42.850946289, -20.36518238],
            [-42.850805292, -20.365220764],
            [-42.850630141, -20.36522982],
            [-42.850571031999898, -20.3652217389999],
            [-42.850503513, -20.365208026999898],
            [-42.850463944999902, -20.365201274999901],
            [-42.850411011999903, -20.365192242],
            [-42.850320188, -20.3651767439999],
            [-42.8501936219999, -20.365121686999899],
            [-42.850126017, -20.365126239999899],
            [-42.850056531999897, -20.365212411999899],
            [-42.849972557999898, -20.365370949],
            [-42.84995609, -20.365401554999899],
            [-42.849946068, -20.365430084],
            [-42.849933961, -20.3654921739999],
            [-42.8499213589999, -20.36560843],
            [-42.849841094999903, -20.3658134799999],
            [-42.849729349, -20.365977353999899],
            [-42.849689782, -20.366138296],
            [-42.849729443999898, -20.366257006],
            [-42.850020577999899, -20.366423136],
            [-42.850154953, -20.366504339],
            [-42.8503309, -20.366711942999899],
            [-42.850512522, -20.366965211],
            [-42.850588049999899, -20.367163218],
            [-42.850588430999899, -20.367321413],
            [-42.850510461999903, -20.367582071],
            [-42.850363844, -20.3677759139999],
            [-42.850209357, -20.3678740249999],
            [-42.850101489999901, -20.368018145999901],
            [-42.850142631, -20.368227596999901],
            [-42.850174889, -20.368324862],
            [-42.850099825, -20.368473728],
            [-42.850002646, -20.3685904129999],
            [-42.849855888, -20.368712840999901],
            [-42.849750867999902, -20.368705722],
            [-42.848465631, -20.3663246529999],
            [-42.848297471, -20.366088848999901],
            [-42.8479337959999, -20.365803182],
            [-42.846792319, -20.364997815],
            [-42.845023508999901, -20.363769089],
            [-42.844444898999903, -20.363588016999898],
            [-42.844110902999901, -20.363275176999899],
            [-42.843597659, -20.362769996999901],
            [-42.842385259, -20.361488372999901],
            [-42.84209555, -20.361951566999899],
            [-42.842018261, -20.362299685],
            [-42.84182777, -20.363244494],
            [-42.841306085, -20.364062010999898],
            [-42.841108595, -20.364325877999899],
            [-42.840924389999898, -20.364676416],
            [-42.840840806, -20.365121196],
            [-42.840763341999903, -20.36542336],
            [-42.840645557999899, -20.365637858],
            [-42.840602381999901, -20.365711013999899],
            [-42.840357637, -20.36585309],
            [-42.840029861999902, -20.365976649999901],
            [-42.839590766, -20.3662316479999],
            [-42.839408342999903, -20.366259213],
            [-42.838967182, -20.366155164],
            [-42.838855151, -20.366220272],
            [-42.838476592999903, -20.366797764],
            [-42.838168380999903, -20.36689423],
            [-42.837891209, -20.366966369999901],
            [-42.837612818, -20.366902003],
            [-42.8363789859999, -20.366506421999901],
            [-42.835605181999902, -20.36632034],
            [-42.835212203, -20.366220121],
            [-42.834929426, -20.366141603],
            [-42.834276211, -20.366197712],
            [-42.834035467, -20.366221406],
            [-42.833757836, -20.366271452],
            [-42.833583518, -20.366285423],
            [-42.833359872, -20.36631871],
            [-42.833174858999897, -20.366336861],
            [-42.833094348, -20.366331849999899],
            [-42.832840245999897, -20.366316035],
            [-42.832580752, -20.366375391],
            [-42.832422037, -20.366346792999899],
            [-42.832368711, -20.366316562999899],
            [-42.832062554999901, -20.366151108999901],
            [-42.831732038, -20.365989746],
            [-42.831637127, -20.365960693],
            [-42.831355862999899, -20.3658587739999],
            [-42.831120559999903, -20.365768417],
            [-42.830813361, -20.365633613999901],
            [-42.830452844, -20.36536017],
            [-42.830236229, -20.365286231],
            [-42.830007678999898, -20.36528076],
            [-42.829745937, -20.365302159],
            [-42.829526002, -20.365323718],
            [-42.829374470999902, -20.3653330449999],
            [-42.829218558, -20.365226591],
            [-42.8291099039999, -20.3650306669999],
            [-42.829018902, -20.36490994],
            [-42.82897965, -20.364857867999898],
            [-42.828847547999899, -20.364773494],
            [-42.828704238999897, -20.364692523],
            [-42.828618241, -20.364691152],
            [-42.828480624999898, -20.3647547109999],
            [-42.828300036, -20.364888086999901],
            [-42.828160387, -20.364911782],
            [-42.828075605, -20.3648650179999],
            [-42.827979989, -20.364750627],
            [-42.8279193179999, -20.364638284],
            [-42.827858169, -20.364522270999899],
            [-42.827744192999901, -20.3643129549999],
            [-42.827504142999899, -20.364229109999901],
            [-42.827297036999902, -20.364193326],
            [-42.827134058999903, -20.364217255],
            [-42.826931293, -20.364284515],
            [-42.826804347999897, -20.3643227489999],
            [-42.826606264, -20.364374982999902],
            [-42.826372867, -20.364435608],
            [-42.825936277, -20.364507261],
            [-42.825776596, -20.364524566999901],
            [-42.825673974, -20.364540292999902],
            [-42.825911194, -20.365160857],
            [-42.826111751, -20.365812897],
            [-42.826291263, -20.366222274999899],
            [-42.826428087, -20.3664975189999],
            [-42.826593776, -20.36668457],
            [-42.826875614999899, -20.366899191],
            [-42.827445517999898, -20.3671860819999],
            [-42.830121317, -20.368142084999899],
            [-42.831076265, -20.368483488],
            [-42.831425663999902, -20.368697767],
            [-42.8317785469999, -20.368974267999899],
            [-42.832079081, -20.3693484369999],
            [-42.832291848999901, -20.369850191999902],
            [-42.832360160999897, -20.370135836],
            [-42.832456138999902, -20.370537163999899],
            [-42.83260246, -20.371200663],
            [-42.832942937, -20.372765034999901],
            [-42.833050003, -20.37321302],
            [-42.833202811, -20.373648405],
            [-42.833692406, -20.374316985],
            [-42.834139947, -20.374763628],
            [-42.834304754999899, -20.374913493],
            [-42.834442649, -20.375013658999901],
            [-42.834623833, -20.375145271],
            [-42.834880413, -20.3752837229999],
            [-42.8351926939999, -20.375452230999901],
            [-42.835555457999902, -20.37554763],
            [-42.836243812, -20.375700713999901],
            [-42.836937938999903, -20.375841088],
            [-42.837365352999903, -20.375928098],
            [-42.837899918999902, -20.376116574],
            [-42.838112505, -20.376239989999899],
            [-42.838311378999897, -20.376375547999899],
            [-42.838521622, -20.376625357],
            [-42.838919788999902, -20.377098454],
            [-42.839227644999902, -20.377434209999901],
            [-42.839540594, -20.377738591],
            [-42.839801345, -20.3779237749999],
            [-42.840061948, -20.378034296],
            [-42.840389363, -20.3781245],
            [-42.841246915999903, -20.378252071],
            [-42.84157571, -20.378274313],
            [-42.84364002, -20.378490381999899],
            [-42.845096223, -20.378663354],
            [-42.846407008, -20.378819053],
            [-42.846761391, -20.3789371129999],
            [-42.846987101, -20.379053569],
            [-42.847161474, -20.3791016939999],
            [-42.847307664, -20.379107056999899],
            [-42.847489521999897, -20.379103813],
            [-42.847642056, -20.3790806],
            [-42.848057079999897, -20.378988133],
            [-42.848777989, -20.3791426559999],
            [-42.850437833999898, -20.379668659999901],
            [-42.851677783, -20.380427503],
            [-42.852664416, -20.3810695449999]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 92,
        "id": 37003,
        "CD_SETOR": "315210505070027P",
        "AREA_KM2": 33.326859,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 191
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.80518834, -20.365543789],
            [-42.805239, -20.365639996],
            [-42.805458, -20.365881995999899],
            [-42.805604, -20.3660549959999],
            [-42.805762, -20.366284995999901],
            [-42.805871, -20.366525996],
            [-42.805919, -20.366790996],
            [-42.805955, -20.367008996],
            [-42.806003, -20.3672959959999],
            [-42.806088, -20.367514996],
            [-42.806161, -20.3677449959999],
            [-42.806257, -20.368043996],
            [-42.806318, -20.368318996],
            [-42.806427, -20.368537995999901],
            [-42.806548, -20.3687789959999],
            [-42.806669999999897, -20.369031995999901],
            [-42.806767, -20.369261996],
            [-42.8069, -20.369491996],
            [-42.806997, -20.369767996],
            [-42.807155, -20.370170996],
            [-42.807276, -20.370480996],
            [-42.807347999999898, -20.3707449959999],
            [-42.807384, -20.370974996],
            [-42.807469, -20.371273996],
            [-42.807529, -20.371526995999901],
            [-42.807577, -20.371894996],
            [-42.807637999999898, -20.372124996],
            [-42.807735, -20.372399996],
            [-42.807783, -20.372629995999901],
            [-42.807904, -20.372836995999901],
            [-42.808000999999898, -20.373089996],
            [-42.80805, -20.373319996],
            [-42.808134, -20.373584996],
            [-42.808244, -20.373802995999899],
            [-42.808341, -20.374032996],
            [-42.80845, -20.374239996],
            [-42.808571, -20.374526995999901],
            [-42.808668, -20.374733996],
            [-42.80868, -20.374963996],
            [-42.808765, -20.375182996],
            [-42.808874, -20.375412996],
            [-42.808959, -20.375619996],
            [-42.809165, -20.375895995999901],
            [-42.809335, -20.376182996],
            [-42.80953, -20.376447996],
            [-42.809711999999898, -20.376654996],
            [-42.809869999999897, -20.376884995999902],
            [-42.810028, -20.377091995999901],
            [-42.810271, -20.377310995999899],
            [-42.810441, -20.377482996],
            [-42.81066, -20.377724995999898],
            [-42.810879, -20.377920995999901],
            [-42.811085999999897, -20.378081995999899],
            [-42.811268, -20.378254996],
            [-42.811499, -20.378426996],
            [-42.811706, -20.3785769959999],
            [-42.811937, -20.378795996],
            [-42.812119, -20.3789569959999],
            [-42.812326, -20.379129996],
            [-42.812496, -20.379428995999898],
            [-42.812593, -20.379669996],
            [-42.812727, -20.379876996],
            [-42.812933, -20.380141996],
            [-42.813079, -20.380348996],
            [-42.813188, -20.380544996],
            [-42.813322, -20.380762995999898],
            [-42.813504, -20.381038996],
            [-42.81365, -20.381268996],
            [-42.813698, -20.381509996],
            [-42.813698, -20.381762996],
            [-42.813661, -20.382003996],
            [-42.813611, -20.382268995999901],
            [-42.813561999999898, -20.382497995999898],
            [-42.813501, -20.3827619959999],
            [-42.813426999999898, -20.383106996],
            [-42.813317, -20.383416995999902],
            [-42.813266999999897, -20.383703996],
            [-42.813255, -20.383944996],
            [-42.813242, -20.384232996],
            [-42.813205, -20.384542995999901],
            [-42.813119, -20.384829996],
            [-42.812947999999899, -20.385081995999901],
            [-42.81285, -20.3852889959999],
            [-42.812727, -20.385586996],
            [-42.812642, -20.385850995999899],
            [-42.812544, -20.386091995999902],
            [-42.812482, -20.3863219959999],
            [-42.812336, -20.386539996],
            [-42.812274, -20.386757996],
            [-42.812274, -20.387045995999902],
            [-42.812285, -20.387320996],
            [-42.812297, -20.387608996],
            [-42.812333, -20.387872995999899],
            [-42.812381, -20.388159996],
            [-42.812527, -20.388366995999899],
            [-42.812624, -20.3886199959999],
            [-42.812769, -20.388976995999901],
            [-42.812927, -20.389389996],
            [-42.813024, -20.389654995999901],
            [-42.813133, -20.389884996],
            [-42.813242, -20.390160996],
            [-42.813363, -20.390390996],
            [-42.813473, -20.390608996],
            [-42.813581999999897, -20.390861996],
            [-42.813690999999899, -20.391137995999902],
            [-42.813824, -20.391425996],
            [-42.813921, -20.391655995999901],
            [-42.814067, -20.391919996],
            [-42.814236999999899, -20.392195995999899],
            [-42.814444, -20.392391996],
            [-42.814699, -20.392541996],
            [-42.815089, -20.392771996],
            [-42.815356, -20.392921995999899],
            [-42.815685, -20.393082996],
            [-42.815964999999899, -20.393221996],
            [-42.816195999999898, -20.393359996],
            [-42.816488999999898, -20.393497996],
            [-42.816793, -20.3936829959999],
            [-42.817048, -20.393843995999902],
            [-42.817231, -20.393981996],
            [-42.817498999999899, -20.3941089959999],
            [-42.817779, -20.394292995999901],
            [-42.818229, -20.394580995999899],
            [-42.818497, -20.394742995999898],
            [-42.818728, -20.394880995999898],
            [-42.819032, -20.395076995999901],
            [-42.819288, -20.395226996],
            [-42.819507, -20.395364996],
            [-42.819872, -20.395686995999899],
            [-42.820152, -20.395813996],
            [-42.820444, -20.395952995999899],
            [-42.820687, -20.396078996],
            [-42.820991, -20.396251995999901],
            [-42.821271, -20.396390996],
            [-42.821526999999897, -20.3965169959999],
            [-42.821917, -20.396678995999899],
            [-42.822184999999898, -20.396667996],
            [-42.822453, -20.396771995999899],
            [-42.822781, -20.396932996],
            [-42.823098, -20.397151995999899],
            [-42.823304999999898, -20.397289995999898],
            [-42.823535999999898, -20.397462995999899],
            [-42.823804, -20.397623995999901],
            [-42.82401, -20.397773996],
            [-42.824229, -20.397923996],
            [-42.8244, -20.398095995999899],
            [-42.824558, -20.398291996],
            [-42.824812999999899, -20.398418996],
            [-42.825069, -20.398499995999899],
            [-42.825313, -20.398568995999899],
            [-42.825568, -20.398672996],
            [-42.825812, -20.398753996],
            [-42.826006999999898, -20.3988809959999],
            [-42.826298999999899, -20.399041995999902],
            [-42.826566, -20.3992029959999],
            [-42.826845999999897, -20.399352995999902],
            [-42.827078, -20.399456996],
            [-42.827321, -20.399583995999901],
            [-42.827552, -20.399721996],
            [-42.82782, -20.399871995999899],
            [-42.828027, -20.399997996],
            [-42.828222, -20.400124995999899],
            [-42.828563, -20.400412995999901],
            [-42.828781999999897, -20.400596995999901],
            [-42.829013, -20.400757996],
            [-42.829195, -20.400896996],
            [-42.829585, -20.401149996],
            [-42.829766999999897, -20.401299996],
            [-42.829937, -20.4014489959999],
            [-42.830156, -20.401644996],
            [-42.830339, -20.4018289959999],
            [-42.830594, -20.402070996],
            [-42.830764, -20.402300996],
            [-42.830922, -20.402507995999901],
            [-42.831068, -20.402703996],
            [-42.831153, -20.402967996],
            [-42.831214, -20.403197996],
            [-42.831322999999898, -20.403438996],
            [-42.831359, -20.4036579959999],
            [-42.831407, -20.403875996],
            [-42.831443, -20.404116996],
            [-42.831504, -20.404381995999898],
            [-42.831517784, -20.4044512],
            [-42.831552, -20.404622995999901],
            [-42.8316, -20.404875995999902],
            [-42.831660999999897, -20.405151996],
            [-42.831697, -20.405404996],
            [-42.831721, -20.405633995999899],
            [-42.83172, -20.405898996],
            [-42.831744, -20.406127996],
            [-42.831744, -20.406426995999901],
            [-42.831730999999898, -20.406644996],
            [-42.831718, -20.406886996],
            [-42.83173, -20.407207996],
            [-42.831814999999899, -20.407449996],
            [-42.831851, -20.407748996],
            [-42.831910999999899, -20.4081159959999],
            [-42.831971, -20.408449995999899],
            [-42.832056, -20.408885996],
            [-42.832079, -20.4091739959999],
            [-42.83203, -20.409460996],
            [-42.831907999999899, -20.409701996],
            [-42.831798, -20.409965996],
            [-42.831589999999899, -20.4102069959999],
            [-42.83137, -20.410366995999901],
            [-42.831175, -20.410550995999898],
            [-42.83098, -20.410722995999901],
            [-42.830699, -20.410962996],
            [-42.830479, -20.411134995999902],
            [-42.830283999999899, -20.411306996],
            [-42.830089, -20.411432996],
            [-42.829845, -20.411604995999902],
            [-42.829636999999899, -20.411742995999901],
            [-42.829223, -20.411914996],
            [-42.828978999999897, -20.412051995999899],
            [-42.828758999999899, -20.412154996],
            [-42.828515, -20.412326995999901],
            [-42.828308, -20.412429996],
            [-42.828051, -20.412566996],
            [-42.827795, -20.412692996],
            [-42.827551, -20.412841995999901],
            [-42.827270999999897, -20.412990996],
            [-42.827039, -20.413093995999901],
            [-42.826819, -20.413208996],
            [-42.826636, -20.413357995999899],
            [-42.826404, -20.413529996],
            [-42.826185, -20.413712996],
            [-42.825953, -20.413884996],
            [-42.825745, -20.414044996],
            [-42.825574, -20.414262995999898],
            [-42.825403, -20.414457996],
            [-42.825243999999898, -20.4146649959999],
            [-42.825133999999899, -20.414882996],
            [-42.825036, -20.415112995999898],
            [-42.824987, -20.415341995999899],
            [-42.824973999999898, -20.415617996],
            [-42.824973999999898, -20.415858995999901],
            [-42.825034, -20.416169996],
            [-42.825046, -20.416410996],
            [-42.825143, -20.416674995999902],
            [-42.825313, -20.416927996],
            [-42.825446, -20.417203996],
            [-42.825519, -20.4174339959999],
            [-42.825653, -20.417652996],
            [-42.825761999999898, -20.417905995999899],
            [-42.825859, -20.418181995999898],
            [-42.825944, -20.418456996],
            [-42.826004, -20.418721995999899],
            [-42.826101, -20.419008995999899],
            [-42.826124999999898, -20.419238995999901],
            [-42.826149, -20.419491996],
            [-42.826075, -20.419709995999899],
            [-42.825904, -20.419996996],
            [-42.825794, -20.420214996],
            [-42.825659, -20.420398996],
            [-42.825501, -20.420604996],
            [-42.82533, -20.420822996],
            [-42.825218999999898, -20.421052996],
            [-42.825073, -20.421316996],
            [-42.824914, -20.4215809959999],
            [-42.824718, -20.421844995999901],
            [-42.824535, -20.4220509959999],
            [-42.824388, -20.422268996],
            [-42.824205, -20.422463996],
            [-42.824022, -20.422646996],
            [-42.82379, -20.422910995999899],
            [-42.823642999999898, -20.423174995999901],
            [-42.823557, -20.423484996],
            [-42.823580999999898, -20.423726996],
            [-42.823715, -20.423956996],
            [-42.823790752, -20.424127244],
            [-42.823812, -20.424174996],
            [-42.823945, -20.424473996],
            [-42.824042, -20.424726995999901],
            [-42.824139, -20.424956995999899],
            [-42.824248, -20.425197996],
            [-42.824345, -20.425485996],
            [-42.82454, -20.425669996],
            [-42.82471, -20.4259459959999],
            [-42.824818999999898, -20.426163996],
            [-42.824965, -20.426451996],
            [-42.825062, -20.426727996],
            [-42.825195999999899, -20.426957995999899],
            [-42.825268, -20.4272109959999],
            [-42.825389, -20.427486996],
            [-42.825449999999897, -20.4277049959999],
            [-42.825571, -20.4279469959999],
            [-42.825644, -20.4281989959999],
            [-42.825753, -20.428555995999901],
            [-42.825813, -20.428773996],
            [-42.825886, -20.429003995999899],
            [-42.825983, -20.429268996],
            [-42.826068, -20.429497996],
            [-42.826153, -20.429716996],
            [-42.826262, -20.4300619959999],
            [-42.826394999999899, -20.430463996],
            [-42.826419, -20.430681996],
            [-42.826419, -20.430911996],
            [-42.826321, -20.431118996],
            [-42.826223, -20.431336996],
            [-42.826088, -20.431531995999901],
            [-42.825869, -20.431737996],
            [-42.825661, -20.431921996],
            [-42.825513999999899, -20.4320939959999],
            [-42.825330999999899, -20.432288996],
            [-42.825258, -20.432506996],
            [-42.825134999999896, -20.432736996],
            [-42.825013, -20.432954996],
            [-42.824866, -20.433218995999901],
            [-42.824719, -20.433539996],
            [-42.82456, -20.433757995999901],
            [-42.824438, -20.433998996],
            [-42.824278999999898, -20.434216996],
            [-42.824181, -20.434480995999898],
            [-42.824083, -20.4348139959999],
            [-42.824058, -20.435043995999902],
            [-42.824045, -20.4353539959999],
            [-42.824045, -20.435606995999901],
            [-42.824081, -20.435859996],
            [-42.824153, -20.4361239959999],
            [-42.824298999999897, -20.436342996],
            [-42.824433, -20.436537996],
            [-42.824590999999899, -20.436721996],
            [-42.824761, -20.436905996],
            [-42.824906999999897, -20.437078996],
            [-42.825139, -20.437159995999899],
            [-42.825407, -20.437228995999899],
            [-42.825649999999897, -20.437263996],
            [-42.825966999999899, -20.437298996],
            [-42.826199, -20.437345996],
            [-42.82654, -20.437380995999899],
            [-42.826796, -20.437403995999901],
            [-42.827112999999898, -20.4374509959999],
            [-42.827344, -20.437484996],
            [-42.827587999999899, -20.437496995999901],
            [-42.827819, -20.437508996],
            [-42.828161, -20.437532996],
            [-42.828404, -20.437590996],
            [-42.828708999999897, -20.437659996],
            [-42.828952, -20.437752996],
            [-42.829196, -20.437821996],
            [-42.829451999999897, -20.437902995999899],
            [-42.829683, -20.437937996],
            [-42.829903, -20.4380179959999],
            [-42.830073, -20.4381909959999],
            [-42.830158, -20.4383979959999],
            [-42.830218, -20.438673996],
            [-42.830261262, -20.438823930999899],
            [-42.831199465999902, -20.437965907],
            [-42.833679539999899, -20.436626066],
            [-42.834245505, -20.43607069],
            [-42.835572197, -20.43490222],
            [-42.835986655999903, -20.4344642309999],
            [-42.837751011, -20.433629525],
            [-42.838932617, -20.432778183],
            [-42.840245469, -20.43237927],
            [-42.843595117999897, -20.431503994],
            [-42.844716719, -20.431392966],
            [-42.845763315999903, -20.431487373],
            [-42.846575013999903, -20.431693432],
            [-42.847544172, -20.4316394109999],
            [-42.848436135, -20.431381639999898],
            [-42.849625519, -20.430517156],
            [-42.85033794, -20.430147792],
            [-42.850721398, -20.429819085],
            [-42.852230075, -20.42982882],
            [-42.853261652, -20.430074797],
            [-42.854162774, -20.430191394],
            [-42.854644105, -20.430311156],
            [-42.854858712, -20.430336253999901],
            [-42.855387531, -20.430178646999899],
            [-42.857106773999902, -20.429911225999899],
            [-42.859049329999898, -20.428955958],
            [-42.860355134, -20.428887024999899],
            [-42.8608727129999, -20.4288968709999],
            [-42.860039437, -20.428018689999899],
            [-42.85738362, -20.42633448],
            [-42.853404296999898, -20.423258346],
            [-42.852183937999897, -20.421849053],
            [-42.8538148969999, -20.420660374],
            [-42.855878778, -20.419096504],
            [-42.857015361, -20.4177955709999],
            [-42.857237137, -20.4176572279999],
            [-42.857335836999901, -20.417322539999901],
            [-42.857283595, -20.416789132],
            [-42.857084857, -20.415183606],
            [-42.856771724999902, -20.414772957],
            [-42.855925492, -20.413254800999901],
            [-42.855721441999897, -20.412895153],
            [-42.8556264979999, -20.412482479999898],
            [-42.855654177, -20.411904326999899],
            [-42.855515356, -20.4116072539999],
            [-42.855100212, -20.411255701],
            [-42.854511895, -20.410871722],
            [-42.854308918, -20.410487786],
            [-42.854279839999897, -20.409920580999898],
            [-42.854511676999898, -20.409500376],
            [-42.854803096, -20.4091925439999],
            [-42.855311672, -20.409060181],
            [-42.85677363, -20.408640950999899],
            [-42.857114597, -20.408112376],
            [-42.857249033, -20.407559880999901],
            [-42.857743736, -20.406633944],
            [-42.858105345999903, -20.406507689],
            [-42.858429284, -20.406547845],
            [-42.858984805, -20.406823679],
            [-42.859437124, -20.406814858],
            [-42.859703521, -20.406787036],
            [-42.859862754999902, -20.4067087889999],
            [-42.85995601, -20.406618194],
            [-42.860239162, -20.4061189509999],
            [-42.860429532, -20.405960829],
            [-42.860786402, -20.405826276],
            [-42.861482835999901, -20.405741775],
            [-42.862001705, -20.405832711999899],
            [-42.862565119, -20.4054948039999],
            [-42.862747882999898, -20.405236126],
            [-42.862673163999901, -20.404465946999899],
            [-42.862730251, -20.404134116],
            [-42.863299825, -20.403600556],
            [-42.863645898999899, -20.403199518],
            [-42.864265354999901, -20.4027526469999],
            [-42.864766604, -20.402196573],
            [-42.8666959339999, -20.400547795],
            [-42.8681179, -20.3995806299999],
            [-42.869143188, -20.398342624999898],
            [-42.869283611999897, -20.398008349],
            [-42.869736948, -20.3974726349999],
            [-42.870337849, -20.397114501],
            [-42.870338287, -20.397100784],
            [-42.870339918, -20.397100768],
            [-42.870475907, -20.396943692],
            [-42.870800589999902, -20.396543489],
            [-42.871059171, -20.396126489],
            [-42.871103432, -20.396040363999902],
            [-42.871129673, -20.395989300999901],
            [-42.871173068, -20.39590486],
            [-42.871193794, -20.39586453],
            [-42.870693107, -20.395718561],
            [-42.870584944, -20.3956919089999],
            [-42.870384999999899, -20.395635],
            [-42.870135137, -20.395559929999902],
            [-42.869953897999899, -20.395494689],
            [-42.869703511999901, -20.3953458619999],
            [-42.869475915999899, -20.395187681],
            [-42.869344449, -20.395088925],
            [-42.869214518, -20.394965316],
            [-42.8690184, -20.394754831999901],
            [-42.868830235, -20.3945521549999],
            [-42.867979815, -20.3935866919999],
            [-42.867597571, -20.393176867],
            [-42.867256168, -20.3929408139999],
            [-42.86690528, -20.392750851999899],
            [-42.866498315999898, -20.392628835],
            [-42.866006646, -20.392570518],
            [-42.865696498, -20.392601768],
            [-42.865261892, -20.392742505999902],
            [-42.864905639, -20.392910151],
            [-42.864594069, -20.393056768999902],
            [-42.864334949, -20.393082692],
            [-42.863990015, -20.3930392779999],
            [-42.863636922, -20.392815723999899],
            [-42.863551574, -20.392715418],
            [-42.862412224, -20.391389688999901],
            [-42.861985352, -20.390869060999901],
            [-42.861700888, -20.390581835],
            [-42.861498261, -20.390410980999899],
            [-42.861030302, -20.390242757],
            [-42.860526094, -20.390091374999901],
            [-42.859614470999901, -20.389824783999899],
            [-42.859241415, -20.389680848],
            [-42.859051463999897, -20.389566986],
            [-42.858911039, -20.389472408],
            [-42.858411503999903, -20.388957689999899],
            [-42.858176132, -20.388757531],
            [-42.857540247, -20.388348456],
            [-42.857318980999899, -20.388114643999899],
            [-42.857178777999899, -20.387903639999902],
            [-42.857074752, -20.387681788],
            [-42.856827766, -20.3869174],
            [-42.856736403, -20.386648661],
            [-42.856547534999898, -20.3862298929999],
            [-42.855349449, -20.384101201],
            [-42.8548912259999, -20.383186031],
            [-42.854156047999901, -20.382244058],
            [-42.853740377, -20.381800369],
            [-42.853388478999896, -20.381561349],
            [-42.852664416, -20.3810695449999],
            [-42.851677783, -20.380427503],
            [-42.850437833999898, -20.379668659999901],
            [-42.848777989, -20.3791426559999],
            [-42.848057079999897, -20.378988133],
            [-42.847642056, -20.3790806],
            [-42.847489521999897, -20.379103813],
            [-42.847307664, -20.379107056999899],
            [-42.847161474, -20.3791016939999],
            [-42.846987101, -20.379053569],
            [-42.846761391, -20.3789371129999],
            [-42.846407008, -20.378819053],
            [-42.845096223, -20.378663354],
            [-42.84364002, -20.378490381999899],
            [-42.84157571, -20.378274313],
            [-42.841246915999903, -20.378252071],
            [-42.840389363, -20.3781245],
            [-42.840061948, -20.378034296],
            [-42.839801345, -20.3779237749999],
            [-42.839540594, -20.377738591],
            [-42.839227644999902, -20.377434209999901],
            [-42.838919788999902, -20.377098454],
            [-42.838521622, -20.376625357],
            [-42.838311378999897, -20.376375547999899],
            [-42.838112505, -20.376239989999899],
            [-42.837899918999902, -20.376116574],
            [-42.837365352999903, -20.375928098],
            [-42.836937938999903, -20.375841088],
            [-42.836243812, -20.375700713999901],
            [-42.835555457999902, -20.37554763],
            [-42.8351926939999, -20.375452230999901],
            [-42.834880413, -20.3752837229999],
            [-42.834623833, -20.375145271],
            [-42.834442649, -20.375013658999901],
            [-42.834304754999899, -20.374913493],
            [-42.834139947, -20.374763628],
            [-42.833692406, -20.374316985],
            [-42.833202811, -20.373648405],
            [-42.833050003, -20.37321302],
            [-42.832942937, -20.372765034999901],
            [-42.83260246, -20.371200663],
            [-42.832456138999902, -20.370537163999899],
            [-42.832360160999897, -20.370135836],
            [-42.832291848999901, -20.369850191999902],
            [-42.832079081, -20.3693484369999],
            [-42.8317785469999, -20.368974267999899],
            [-42.831425663999902, -20.368697767],
            [-42.831076265, -20.368483488],
            [-42.830121317, -20.368142084999899],
            [-42.827445517999898, -20.3671860819999],
            [-42.826875614999899, -20.366899191],
            [-42.826593776, -20.36668457],
            [-42.826428087, -20.3664975189999],
            [-42.826291263, -20.366222274999899],
            [-42.826111751, -20.365812897],
            [-42.825911194, -20.365160857],
            [-42.825673974, -20.364540292999902],
            [-42.825776596, -20.364524566999901],
            [-42.825936277, -20.364507261],
            [-42.826372867, -20.364435608],
            [-42.826606264, -20.364374982999902],
            [-42.826804347999897, -20.3643227489999],
            [-42.826931293, -20.364284515],
            [-42.827134058999903, -20.364217255],
            [-42.827297036999902, -20.364193326],
            [-42.827504142999899, -20.364229109999901],
            [-42.827744192999901, -20.3643129549999],
            [-42.827858169, -20.364522270999899],
            [-42.8279193179999, -20.364638284],
            [-42.827979989, -20.364750627],
            [-42.828075605, -20.3648650179999],
            [-42.828160387, -20.364911782],
            [-42.828300036, -20.364888086999901],
            [-42.828480624999898, -20.3647547109999],
            [-42.828618241, -20.364691152],
            [-42.828704238999897, -20.364692523],
            [-42.828847547999899, -20.364773494],
            [-42.82897965, -20.364857867999898],
            [-42.829018902, -20.36490994],
            [-42.8291099039999, -20.3650306669999],
            [-42.829218558, -20.365226591],
            [-42.829374470999902, -20.3653330449999],
            [-42.829526002, -20.365323718],
            [-42.829745937, -20.365302159],
            [-42.830007678999898, -20.36528076],
            [-42.830236229, -20.365286231],
            [-42.830452844, -20.36536017],
            [-42.830813361, -20.365633613999901],
            [-42.831120559999903, -20.365768417],
            [-42.831355862999899, -20.3658587739999],
            [-42.831637127, -20.365960693],
            [-42.831732038, -20.365989746],
            [-42.832062554999901, -20.366151108999901],
            [-42.832368711, -20.366316562999899],
            [-42.832422037, -20.366346792999899],
            [-42.832580752, -20.366375391],
            [-42.832840245999897, -20.366316035],
            [-42.833094348, -20.366331849999899],
            [-42.833174858999897, -20.366336861],
            [-42.833359872, -20.36631871],
            [-42.833583518, -20.366285423],
            [-42.833757836, -20.366271452],
            [-42.834035467, -20.366221406],
            [-42.834276211, -20.366197712],
            [-42.834929426, -20.366141603],
            [-42.835212203, -20.366220121],
            [-42.835605181999902, -20.36632034],
            [-42.8363789859999, -20.366506421999901],
            [-42.837612818, -20.366902003],
            [-42.837891209, -20.366966369999901],
            [-42.838168380999903, -20.36689423],
            [-42.838476592999903, -20.366797764],
            [-42.838855151, -20.366220272],
            [-42.838967182, -20.366155164],
            [-42.839408342999903, -20.366259213],
            [-42.839590766, -20.3662316479999],
            [-42.840029861999902, -20.365976649999901],
            [-42.840357637, -20.36585309],
            [-42.840602381999901, -20.365711013999899],
            [-42.840645557999899, -20.365637858],
            [-42.840763341999903, -20.36542336],
            [-42.840840806, -20.365121196],
            [-42.840924389999898, -20.364676416],
            [-42.841108595, -20.364325877999899],
            [-42.841306085, -20.364062010999898],
            [-42.84182777, -20.363244494],
            [-42.842018261, -20.362299685],
            [-42.84209555, -20.361951566999899],
            [-42.842385259, -20.361488372999901],
            [-42.843597659, -20.362769996999901],
            [-42.844110902999901, -20.363275176999899],
            [-42.844444898999903, -20.363588016999898],
            [-42.845023508999901, -20.363769089],
            [-42.846792319, -20.364997815],
            [-42.8479337959999, -20.365803182],
            [-42.848297471, -20.366088848999901],
            [-42.848465631, -20.3663246529999],
            [-42.849750867999902, -20.368705722],
            [-42.849855888, -20.368712840999901],
            [-42.850002646, -20.3685904129999],
            [-42.850099825, -20.368473728],
            [-42.850174889, -20.368324862],
            [-42.850142631, -20.368227596999901],
            [-42.850101489999901, -20.368018145999901],
            [-42.850209357, -20.3678740249999],
            [-42.850363844, -20.3677759139999],
            [-42.850510461999903, -20.367582071],
            [-42.850588430999899, -20.367321413],
            [-42.850588049999899, -20.367163218],
            [-42.850512522, -20.366965211],
            [-42.8503309, -20.366711942999899],
            [-42.850154953, -20.366504339],
            [-42.850020577999899, -20.366423136],
            [-42.849729443999898, -20.366257006],
            [-42.849689782, -20.366138296],
            [-42.849729349, -20.365977353999899],
            [-42.849841094999903, -20.3658134799999],
            [-42.8499213589999, -20.36560843],
            [-42.849933961, -20.3654921739999],
            [-42.849946068, -20.365430084],
            [-42.84995609, -20.365401554999899],
            [-42.849972557999898, -20.365370949],
            [-42.850056531999897, -20.365212411999899],
            [-42.850126017, -20.365126239999899],
            [-42.8501936219999, -20.365121686999899],
            [-42.850320188, -20.3651767439999],
            [-42.850411011999903, -20.365192242],
            [-42.850463944999902, -20.365201274999901],
            [-42.850503513, -20.365208026999898],
            [-42.850571031999898, -20.3652217389999],
            [-42.850630141, -20.36522982],
            [-42.850805292, -20.365220764],
            [-42.850946289, -20.36518238],
            [-42.85103365, -20.365008293],
            [-42.851047977, -20.3648279619999],
            [-42.851030109, -20.364249279],
            [-42.851254534, -20.363487377999899],
            [-42.851307592999902, -20.363007496],
            [-42.851207196, -20.362761566],
            [-42.85108201, -20.362608336999902],
            [-42.8511171, -20.362393602999902],
            [-42.851345124, -20.362252892],
            [-42.8515932829999, -20.362216154],
            [-42.85169303, -20.3618703029999],
            [-42.851650821, -20.361552076],
            [-42.851688951, -20.361260388999899],
            [-42.851949614, -20.3607741779999],
            [-42.852080311999899, -20.360495688],
            [-42.852034826, -20.36013774],
            [-42.852179045, -20.359710017999902],
            [-42.852303682, -20.359381238],
            [-42.852259945999897, -20.359111514999899],
            [-42.852409979999898, -20.358727248],
            [-42.852450843, -20.3585887529999],
            [-42.850844902999903, -20.355742746],
            [-42.850852859, -20.35534574],
            [-42.850942942, -20.3542544539999],
            [-42.850463740999899, -20.354060186],
            [-42.849833918999899, -20.353653418],
            [-42.849430239999897, -20.353501815],
            [-42.848633909999897, -20.353421947],
            [-42.847974425, -20.353423714],
            [-42.847516266999897, -20.353426037],
            [-42.847052163999898, -20.353483997999898],
            [-42.846408154, -20.353635133],
            [-42.845974026, -20.353713134],
            [-42.845606451, -20.353192104],
            [-42.845293931999898, -20.352690987],
            [-42.844723828, -20.351797834],
            [-42.844272286, -20.35110341],
            [-42.84409727, -20.351022656999898],
            [-42.843844729999901, -20.350954816],
            [-42.8426643069999, -20.350682671999898],
            [-42.841354285, -20.350542063],
            [-42.83994137, -20.350450803999902],
            [-42.839686104, -20.3503799389999],
            [-42.839365135, -20.350215584999901],
            [-42.839138098, -20.350136532],
            [-42.838903617, -20.350139148],
            [-42.83863622, -20.350479053],
            [-42.838061022, -20.352136244999901],
            [-42.8378738, -20.3534406439999],
            [-42.83763802, -20.353574312],
            [-42.835156414, -20.353318112],
            [-42.833506371999903, -20.351765213],
            [-42.832909211, -20.350888244],
            [-42.832582123999899, -20.350728045],
            [-42.830762294, -20.351408955999901],
            [-42.828385056999899, -20.351794523],
            [-42.828043465999897, -20.352031617],
            [-42.827708485999899, -20.352549526],
            [-42.827449414999897, -20.352788561],
            [-42.826977526, -20.352905983],
            [-42.826382178, -20.352726274],
            [-42.82593887, -20.352320066999901],
            [-42.824992804, -20.350640195],
            [-42.82432741, -20.349544668],
            [-42.823698479, -20.349130927],
            [-42.823141882999899, -20.348960908],
            [-42.821311893, -20.348940183],
            [-42.820515317999899, -20.349129650999899],
            [-42.82016788, -20.349303420999899],
            [-42.819802122999903, -20.350523852],
            [-42.819314937999899, -20.351487072999898],
            [-42.819107244999898, -20.35153056],
            [-42.818093963, -20.351248578],
            [-42.817123, -20.350660736999899],
            [-42.816700093999899, -20.350437155999899],
            [-42.816464625, -20.350376871],
            [-42.816187703, -20.350389794],
            [-42.815928179, -20.350499468],
            [-42.815570036999901, -20.350825514999901],
            [-42.815169791999899, -20.351186073],
            [-42.814934648, -20.351331435],
            [-42.81467609, -20.351394048],
            [-42.813850223, -20.351549787],
            [-42.813454115, -20.351639121999899],
            [-42.813054238999896, -20.351892359],
            [-42.81232091, -20.353058801],
            [-42.811717379, -20.354108493],
            [-42.811299682, -20.354572382],
            [-42.810657867, -20.354751968999899],
            [-42.810091504, -20.355063855],
            [-42.809803680999899, -20.355384048],
            [-42.808719352, -20.357163015],
            [-42.808359713999899, -20.3577587189999],
            [-42.808014603999901, -20.358959345999899],
            [-42.807957163, -20.359809329],
            [-42.808175492, -20.360717163999901],
            [-42.808817422999901, -20.361602906],
            [-42.809747951, -20.363355806],
            [-42.807993551, -20.364197675],
            [-42.807287467, -20.364365673],
            [-42.8065687819999, -20.364679899],
            [-42.80518834, -20.365543789]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 93,
        "id": 37004,
        "CD_SETOR": "315210505070028P",
        "AREA_KM2": 28.491479,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 345
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.870475907, -20.396943692],
            [-42.870339918, -20.397100768],
            [-42.870338287, -20.397100784],
            [-42.870337849, -20.397114501],
            [-42.869736948, -20.3974726349999],
            [-42.869283611999897, -20.398008349],
            [-42.869143188, -20.398342624999898],
            [-42.8681179, -20.3995806299999],
            [-42.8666959339999, -20.400547795],
            [-42.864766604, -20.402196573],
            [-42.864265354999901, -20.4027526469999],
            [-42.863645898999899, -20.403199518],
            [-42.863299825, -20.403600556],
            [-42.862730251, -20.404134116],
            [-42.862673163999901, -20.404465946999899],
            [-42.862747882999898, -20.405236126],
            [-42.862565119, -20.4054948039999],
            [-42.862001705, -20.405832711999899],
            [-42.861482835999901, -20.405741775],
            [-42.860786402, -20.405826276],
            [-42.860429532, -20.405960829],
            [-42.860239162, -20.4061189509999],
            [-42.85995601, -20.406618194],
            [-42.859862754999902, -20.4067087889999],
            [-42.859703521, -20.406787036],
            [-42.859437124, -20.406814858],
            [-42.858984805, -20.406823679],
            [-42.858429284, -20.406547845],
            [-42.858105345999903, -20.406507689],
            [-42.857743736, -20.406633944],
            [-42.857249033, -20.407559880999901],
            [-42.857114597, -20.408112376],
            [-42.85677363, -20.408640950999899],
            [-42.855311672, -20.409060181],
            [-42.854803096, -20.4091925439999],
            [-42.854511676999898, -20.409500376],
            [-42.854279839999897, -20.409920580999898],
            [-42.854308918, -20.410487786],
            [-42.854511895, -20.410871722],
            [-42.855100212, -20.411255701],
            [-42.855515356, -20.4116072539999],
            [-42.855654177, -20.411904326999899],
            [-42.8556264979999, -20.412482479999898],
            [-42.855721441999897, -20.412895153],
            [-42.855925492, -20.413254800999901],
            [-42.856771724999902, -20.414772957],
            [-42.857084857, -20.415183606],
            [-42.857283595, -20.416789132],
            [-42.857335836999901, -20.417322539999901],
            [-42.857237137, -20.4176572279999],
            [-42.857015361, -20.4177955709999],
            [-42.855878778, -20.419096504],
            [-42.8538148969999, -20.420660374],
            [-42.852183937999897, -20.421849053],
            [-42.853404296999898, -20.423258346],
            [-42.85738362, -20.42633448],
            [-42.860039437, -20.428018689999899],
            [-42.8608727129999, -20.4288968709999],
            [-42.860355134, -20.428887024999899],
            [-42.859049329999898, -20.428955958],
            [-42.857106773999902, -20.429911225999899],
            [-42.855387531, -20.430178646999899],
            [-42.854858712, -20.430336253999901],
            [-42.854644105, -20.430311156],
            [-42.854162774, -20.430191394],
            [-42.853261652, -20.430074797],
            [-42.852230075, -20.42982882],
            [-42.850721398, -20.429819085],
            [-42.85033794, -20.430147792],
            [-42.849625519, -20.430517156],
            [-42.848436135, -20.431381639999898],
            [-42.847544172, -20.4316394109999],
            [-42.846575013999903, -20.431693432],
            [-42.845763315999903, -20.431487373],
            [-42.844716719, -20.431392966],
            [-42.843595117999897, -20.431503994],
            [-42.840245469, -20.43237927],
            [-42.838932617, -20.432778183],
            [-42.837751011, -20.433629525],
            [-42.835986655999903, -20.4344642309999],
            [-42.835572197, -20.43490222],
            [-42.834245505, -20.43607069],
            [-42.833679539999899, -20.436626066],
            [-42.831199465999902, -20.437965907],
            [-42.830261262, -20.438823930999899],
            [-42.830291, -20.438926996],
            [-42.830315, -20.439236996],
            [-42.830447999999897, -20.439443996],
            [-42.83046, -20.439696996],
            [-42.83046, -20.439926995999901],
            [-42.830471, -20.440224995999898],
            [-42.830483, -20.440535995999898],
            [-42.830482, -20.440856996],
            [-42.830494, -20.441086996],
            [-42.830518, -20.441327995999899],
            [-42.83059, -20.441580996],
            [-42.830566, -20.441833996],
            [-42.830589, -20.442051996],
            [-42.830699, -20.442293995999901],
            [-42.830698, -20.442775996],
            [-42.830734, -20.443051996],
            [-42.830867, -20.443373995999899],
            [-42.830952, -20.443591996],
            [-42.830987999999898, -20.4438449959999],
            [-42.831171, -20.444097996],
            [-42.831254999999899, -20.444362995999899],
            [-42.831352, -20.444592996],
            [-42.831486, -20.445005996],
            [-42.831583, -20.445281995999899],
            [-42.831727999999899, -20.445833996],
            [-42.831812, -20.446362995999898],
            [-42.831885, -20.446638995999901],
            [-42.831908999999897, -20.446879996],
            [-42.831957, -20.4472939959999],
            [-42.83198, -20.447557995999901],
            [-42.832028, -20.448039996],
            [-42.832052, -20.448269996],
            [-42.832087999999899, -20.448499996],
            [-42.832087, -20.449074996],
            [-42.832147, -20.449372996],
            [-42.832208, -20.449591996],
            [-42.832293, -20.449821996],
            [-42.832341, -20.450096996],
            [-42.832389, -20.450326995999902],
            [-42.832425, -20.450579995999899],
            [-42.832498, -20.450844995999901],
            [-42.832534, -20.451062996],
            [-42.832583, -20.451280996],
            [-42.832594, -20.451522995999898],
            [-42.832643, -20.451763996],
            [-42.832715, -20.452062995999899],
            [-42.832788, -20.452441995999902],
            [-42.83286, -20.452682996],
            [-42.832945, -20.452970996],
            [-42.833005, -20.453223996],
            [-42.833101999999897, -20.453464996],
            [-42.8332, -20.453694996],
            [-42.83326, -20.4539129959999],
            [-42.833357, -20.454165996],
            [-42.833418, -20.454384995999899],
            [-42.833503, -20.454591996],
            [-42.833612, -20.454798996],
            [-42.833697, -20.455028996],
            [-42.833818, -20.455258995999898],
            [-42.833903, -20.455522996],
            [-42.833963, -20.455752995999902],
            [-42.834024, -20.456016996],
            [-42.834072, -20.456235996],
            [-42.834156999999898, -20.456510996],
            [-42.834193, -20.456729996],
            [-42.834266, -20.457005996],
            [-42.834375, -20.457372995999901],
            [-42.834399, -20.457591996],
            [-42.834386, -20.457821996],
            [-42.834398, -20.4580739959999],
            [-42.83447, -20.458533996],
            [-42.834506, -20.458786996],
            [-42.834542, -20.459085995999899],
            [-42.834578, -20.459326996],
            [-42.834627, -20.459544996],
            [-42.834638, -20.459786995999899],
            [-42.834674, -20.460027996],
            [-42.834759, -20.460245996],
            [-42.834783, -20.460475996],
            [-42.834807, -20.460762996],
            [-42.834831, -20.460981995999902],
            [-42.834854999999898, -20.461245995999899],
            [-42.834891, -20.461463996],
            [-42.834915, -20.461705996],
            [-42.834927, -20.461980996],
            [-42.83495, -20.462233996],
            [-42.834986, -20.462498996],
            [-42.834998, -20.462716995999902],
            [-42.835034, -20.462946996],
            [-42.835071, -20.4631649959999],
            [-42.835094, -20.463405995999899],
            [-42.835155, -20.463693996],
            [-42.835191, -20.463923995999899],
            [-42.835251, -20.464164996],
            [-42.835312, -20.464394996],
            [-42.835482, -20.4648549959999],
            [-42.835603, -20.465084996],
            [-42.835687999999898, -20.465291996],
            [-42.835822, -20.465509996],
            [-42.835955, -20.465785996],
            [-42.836126, -20.466015996],
            [-42.836271, -20.466234996],
            [-42.836405, -20.466441996],
            [-42.836539, -20.466774996],
            [-42.836610999999898, -20.4669939959999],
            [-42.836672, -20.467234995999899],
            [-42.836867, -20.467395995999901],
            [-42.837060999999899, -20.467568996],
            [-42.837256, -20.467694996],
            [-42.837475, -20.467833996],
            [-42.837646, -20.4679829959999],
            [-42.837865, -20.468121996],
            [-42.83806, -20.468270995999902],
            [-42.838266, -20.468420996],
            [-42.838449, -20.468616996],
            [-42.838594999999899, -20.468788995999901],
            [-42.83879, -20.469041995999898],
            [-42.838887, -20.469271996],
            [-42.838996, -20.469593996],
            [-42.839067999999898, -20.470030995999899],
            [-42.839115999999898, -20.470248996],
            [-42.839164, -20.470685995999901],
            [-42.839188, -20.470915996],
            [-42.839212, -20.471179995999901],
            [-42.839297, -20.471409996],
            [-42.839309, -20.471627995999899],
            [-42.839272, -20.471845996],
            [-42.839112999999898, -20.4720989959999],
            [-42.839051, -20.472328995999899],
            [-42.838807, -20.472718996],
            [-42.838798041, -20.472730602999899],
            [-42.840011319, -20.472603314],
            [-42.842405447, -20.4722788569999],
            [-42.845183369, -20.473281477999901],
            [-42.846954269, -20.474392833],
            [-42.848001799, -20.474703963],
            [-42.850539341999898, -20.473569826999899],
            [-42.851307877, -20.47337927],
            [-42.852955732999902, -20.473063797999899],
            [-42.8535014939999, -20.472894266],
            [-42.853747864999903, -20.472479307999901],
            [-42.853864651, -20.471964064],
            [-42.853819186, -20.471085192],
            [-42.853885883, -20.470271242],
            [-42.853808496999903, -20.469525489],
            [-42.853544, -20.468863876999901],
            [-42.853660469, -20.468463772],
            [-42.854048326999902, -20.468094527999899],
            [-42.854431110999897, -20.467892925999902],
            [-42.854899611, -20.467577633],
            [-42.855273826, -20.467462941],
            [-42.856011004, -20.467510869],
            [-42.856649633, -20.467672564999901],
            [-42.856790314, -20.467613943999901],
            [-42.8569966539999, -20.467047618],
            [-42.857235187999898, -20.465668954],
            [-42.857417943, -20.464067699999902],
            [-42.858405730999898, -20.463572056],
            [-42.858567179, -20.463445814],
            [-42.858740344999902, -20.463450096],
            [-42.860572807, -20.464155041],
            [-42.860822723, -20.464248472],
            [-42.861038881, -20.464199999],
            [-42.861486333999899, -20.463837514],
            [-42.862153045999897, -20.463363145],
            [-42.862459674, -20.463334952],
            [-42.863276814999899, -20.463210058],
            [-42.863619287999903, -20.463231532999899],
            [-42.863900007, -20.463202803999899],
            [-42.864239552, -20.462983492],
            [-42.864430497999898, -20.4624516219999],
            [-42.864498712999897, -20.462118217999901],
            [-42.864228286, -20.461676434],
            [-42.864116828, -20.461323169],
            [-42.864035271, -20.45964917],
            [-42.864395051, -20.459218124],
            [-42.866478361, -20.457782946],
            [-42.867091419, -20.457154596],
            [-42.86787084, -20.456850489],
            [-42.8684492679999, -20.4567011839999],
            [-42.869815721999899, -20.456727349999898],
            [-42.870123444999898, -20.456048961],
            [-42.870303867, -20.455476572999899],
            [-42.869876428999902, -20.454971601],
            [-42.869327255, -20.453987132999899],
            [-42.868773851999897, -20.4535020329999],
            [-42.868844677, -20.453215203],
            [-42.869049452999903, -20.452490178999899],
            [-42.869695673, -20.451409371],
            [-42.870174979, -20.450899862],
            [-42.871403965, -20.450499620999899],
            [-42.872276746, -20.450301270999901],
            [-42.872852516, -20.450291122],
            [-42.873755753, -20.450543825],
            [-42.87428834, -20.450660026],
            [-42.874602738, -20.450591491],
            [-42.8751764309999, -20.449717408],
            [-42.875706255, -20.448981875999898],
            [-42.875692505, -20.448087687999902],
            [-42.875855375999897, -20.447389219999899],
            [-42.876095918, -20.4468928329999],
            [-42.876421622, -20.446704908],
            [-42.876780808, -20.446631859],
            [-42.877335405999901, -20.446710229],
            [-42.877993655999902, -20.447017129],
            [-42.878446455999899, -20.447417123],
            [-42.879061253, -20.447615755],
            [-42.879319775999903, -20.447491753999898],
            [-42.879477584, -20.446817653],
            [-42.879712087, -20.4460895839999],
            [-42.880106328, -20.444925215999898],
            [-42.880342685, -20.443922823],
            [-42.880504689, -20.443318383],
            [-42.880679369999903, -20.443114434999899],
            [-42.882339864999899, -20.442720054999899],
            [-42.8831171799999, -20.442442674999899],
            [-42.884030756, -20.441563484],
            [-42.884636567, -20.441164278],
            [-42.885149022, -20.440993916],
            [-42.886006837, -20.440943548],
            [-42.886312041999901, -20.440673569],
            [-42.88660078, -20.440231449],
            [-42.886888536999898, -20.4398562609999],
            [-42.887076123, -20.439735503999898],
            [-42.887384679, -20.439656059999901],
            [-42.8878926739999, -20.43957023],
            [-42.888248652999899, -20.439514152],
            [-42.888441563, -20.439460794],
            [-42.889578743999898, -20.438250608],
            [-42.891196188, -20.436892611],
            [-42.891138826, -20.436751253],
            [-42.891233228, -20.436562712],
            [-42.891194147, -20.4363678879999],
            [-42.891113764, -20.436319812],
            [-42.891034094, -20.4362870329999],
            [-42.890912526, -20.436284074],
            [-42.890864489, -20.436252363999898],
            [-42.890832266, -20.436149053],
            [-42.890864131999898, -20.436025906999902],
            [-42.890886556999902, -20.435903198999899],
            [-42.890987259, -20.435822588],
            [-42.891129694, -20.435718223],
            [-42.891218006, -20.435640986],
            [-42.891380279, -20.435460899],
            [-42.891413574999902, -20.435358325],
            [-42.891467148999901, -20.435046953],
            [-42.891560893, -20.434853991999901],
            [-42.891773013, -20.434358597],
            [-42.892070794999903, -20.4339084819999],
            [-42.892236012999902, -20.4333793119999],
            [-42.892322310999901, -20.433274775],
            [-42.892498321999902, -20.4331704169999],
            [-42.892539828999901, -20.4330720049999],
            [-42.892500406, -20.432972945],
            [-42.892299690999899, -20.43285868],
            [-42.89188372, -20.432551429999901],
            [-42.891619859999899, -20.432432752],
            [-42.891268328, -20.4323035789999],
            [-42.891111983, -20.4323086009999],
            [-42.890905954999901, -20.432361767],
            [-42.890630643, -20.432340494],
            [-42.890433324999897, -20.432394201],
            [-42.890263312999899, -20.4325044509999],
            [-42.890062739, -20.432764844],
            [-42.889799321999902, -20.433099574],
            [-42.889730482999902, -20.4331847129999],
            [-42.889685573, -20.433210505],
            [-42.88958638, -20.433230275],
            [-42.889539565999897, -20.433203508],
            [-42.889473689999903, -20.433075324999901],
            [-42.889435762999902, -20.433001528],
            [-42.889343561, -20.432917666],
            [-42.889269883999901, -20.432880226],
            [-42.889175152999897, -20.432846945999898],
            [-42.889086886, -20.432821794999899],
            [-42.888877178, -20.43265723],
            [-42.888643251, -20.432515378999899],
            [-42.888594855, -20.432386291999901],
            [-42.888513681999903, -20.432272075999901],
            [-42.888387287999898, -20.432208418999899],
            [-42.888069387, -20.432102921],
            [-42.888017848999901, -20.432033535999899],
            [-42.888007495999901, -20.431884694],
            [-42.888025248, -20.431746544],
            [-42.888011686, -20.431651728],
            [-42.888143086999897, -20.431510665],
            [-42.888191954, -20.431450766],
            [-42.888202147999898, -20.431395543999901],
            [-42.888204002, -20.431385502],
            [-42.888103156999897, -20.431278138],
            [-42.887826364, -20.430941787999899],
            [-42.887669629999898, -20.43088792],
            [-42.887645703, -20.430796087],
            [-42.887681406, -20.430704551],
            [-42.887862566, -20.430341191],
            [-42.887933317, -20.430276384],
            [-42.888334146, -20.430067860999898],
            [-42.888700261999901, -20.4298218939999],
            [-42.888942035999897, -20.429641515999901],
            [-42.889520788, -20.429303059999899],
            [-42.889683249, -20.4292281429999],
            [-42.889789387999897, -20.4292114429999],
            [-42.889868092, -20.429286109],
            [-42.889936282999898, -20.429415017999901],
            [-42.890214434, -20.429557025],
            [-42.890469792, -20.429569337],
            [-42.890642022, -20.429721685999901],
            [-42.890759882999902, -20.429764801],
            [-42.890868047, -20.429748171],
            [-42.890848885, -20.429472955999898],
            [-42.890937849, -20.42935255],
            [-42.890908899, -20.429090356],
            [-42.89092999, -20.428894632],
            [-42.890912332, -20.42862998],
            [-42.890908732999897, -20.428487858],
            [-42.890950657, -20.4284015869999],
            [-42.891016662, -20.4283668909999],
            [-42.891085711999899, -20.428394222],
            [-42.891146402999901, -20.428455712999899],
            [-42.891334314999902, -20.428489961],
            [-42.891390359, -20.428498437],
            [-42.891424486999902, -20.4285238689999],
            [-42.891464721, -20.428576318],
            [-42.891570969, -20.428715994999902],
            [-42.891684641, -20.428840081],
            [-42.891805989, -20.428997411999902],
            [-42.891911764, -20.429111851],
            [-42.891971178999903, -20.429128789999901],
            [-42.892004095999901, -20.429127461],
            [-42.892040935999901, -20.4291052529999],
            [-42.892116644999902, -20.429020335],
            [-42.89228056, -20.428854837],
            [-42.892423474999902, -20.428730059],
            [-42.892524489, -20.428644130999899],
            [-42.892651720999901, -20.428604107],
            [-42.892789173, -20.428573873999898],
            [-42.89293656, -20.4285179509999],
            [-42.892974186999901, -20.428506276999901],
            [-42.893029579, -20.428489587999898],
            [-42.893079464, -20.4284916199999],
            [-42.893108393, -20.4285074699999],
            [-42.893214788, -20.428544106999901],
            [-42.893265058999901, -20.428539665999899],
            [-42.893262249, -20.428527243],
            [-42.893224792, -20.428357368],
            [-42.892569227, -20.427722982],
            [-42.892008039, -20.427248683],
            [-42.891665960999902, -20.427083674],
            [-42.891411285999901, -20.427052187],
            [-42.891500863, -20.4267598],
            [-42.8916009759999, -20.426413578999899],
            [-42.8917464479999, -20.4260024619999],
            [-42.892094316999902, -20.425496471],
            [-42.892353637, -20.425167577],
            [-42.892922876999897, -20.424927232],
            [-42.89328972, -20.424901932],
            [-42.893346644, -20.424775434],
            [-42.893201172, -20.424411753999902],
            [-42.8932327959999, -20.424057559999898],
            [-42.893517416, -20.423842513999901],
            [-42.893612289, -20.423659092],
            [-42.89330237, -20.423387122],
            [-42.892783729, -20.4232100259999],
            [-42.89221449, -20.422862156999901],
            [-42.891638924999903, -20.422501639],
            [-42.890788228, -20.421859663],
            [-42.890124116, -20.421195549999901],
            [-42.889687699, -20.420771783],
            [-42.889251282, -20.420487163],
            [-42.888833839, -20.420322715999902],
            [-42.888631443, -20.4199179239999],
            [-42.888485971, -20.419342358999899],
            [-42.888653437, -20.419105256],
            [-42.888391113999901, -20.418962527],
            [-42.887540595, -20.418548459],
            [-42.886938018, -20.418331262],
            [-42.8867174539999, -20.418089420999902],
            [-42.886441510999902, -20.417773504],
            [-42.886169859999903, -20.417562815999901],
            [-42.886032142, -20.417505892],
            [-42.885814421, -20.4175590299999],
            [-42.885755127, -20.417521249999901],
            [-42.885637142999897, -20.417651374999899],
            [-42.885591835, -20.417733380999898],
            [-42.885541647, -20.417811715],
            [-42.885440263, -20.417860958999899],
            [-42.885376393999898, -20.417870354999899],
            [-42.885316692999901, -20.417908521],
            [-42.885299156, -20.417969645],
            [-42.885308161, -20.418057169999901],
            [-42.885314368, -20.418117503],
            [-42.885302183, -20.418256708999898],
            [-42.885257931, -20.418352341999899],
            [-42.885143647999897, -20.418483810999899],
            [-42.885013050999902, -20.418581488],
            [-42.884870846999902, -20.418674166],
            [-42.88475198, -20.418714778],
            [-42.884635687999896, -20.418737097],
            [-42.884535824999901, -20.4184697929999],
            [-42.884433707, -20.418310321],
            [-42.884313403, -20.418131264],
            [-42.884286335999903, -20.418093911],
            [-42.884284944999898, -20.418095637],
            [-42.884259502, -20.418184843999899],
            [-42.884008858, -20.418303224999899],
            [-42.883775797999903, -20.418386547999901],
            [-42.883615948999903, -20.418444934],
            [-42.883438091, -20.418471299],
            [-42.883057596999897, -20.418457437],
            [-42.882600915, -20.418346624],
            [-42.882029653, -20.418183585],
            [-42.881740169, -20.418112011999899],
            [-42.881573134999897, -20.418066906],
            [-42.880809976, -20.417925472],
            [-42.880428547999898, -20.417962189],
            [-42.879764639, -20.418025459],
            [-42.878875052, -20.418111462],
            [-42.878186316, -20.418180479999901],
            [-42.877852819999902, -20.4182649689999],
            [-42.877728864999902, -20.418120466999898],
            [-42.877951665, -20.417913921],
            [-42.878002076, -20.417855045],
            [-42.878039311, -20.4177298769999],
            [-42.878070357999903, -20.417658265999901],
            [-42.878029362999897, -20.417128734],
            [-42.8781324079999, -20.416658841999901],
            [-42.878261709999897, -20.416103181],
            [-42.878353806999897, -20.415572379],
            [-42.878339672, -20.415107748],
            [-42.878147113999901, -20.4144460549999],
            [-42.878005085, -20.413812570999902],
            [-42.878068665999898, -20.413315444],
            [-42.878155417999899, -20.413075051],
            [-42.878032686, -20.412521522],
            [-42.878018263999898, -20.412325945],
            [-42.878240934, -20.411943241],
            [-42.878312531999903, -20.411449219],
            [-42.878516697, -20.410903355999899],
            [-42.878759812999903, -20.410435490999902],
            [-42.878968911999898, -20.409969257999901],
            [-42.879061872, -20.409728362],
            [-42.879083301, -20.409409705],
            [-42.87902135, -20.408673504],
            [-42.878951427999901, -20.408265195],
            [-42.879676364, -20.406845102],
            [-42.879711834, -20.406698026999901],
            [-42.879722351999902, -20.406450073],
            [-42.879907052, -20.406026384],
            [-42.880149208, -20.406208074999899],
            [-42.880211049, -20.406234859999898],
            [-42.880250197, -20.406251816],
            [-42.880396323, -20.4062598259999],
            [-42.88047527, -20.406274031],
            [-42.880593162, -20.406295242999899],
            [-42.880759992, -20.406377011],
            [-42.880856047999899, -20.406441700999899],
            [-42.8808881799999, -20.406457439999901],
            [-42.880967271, -20.406496177],
            [-42.881015017, -20.4065020789999],
            [-42.881083675999903, -20.406508741],
            [-42.881107875999902, -20.406503744],
            [-42.881149665, -20.406491409999902],
            [-42.881203367999902, -20.406479902999902],
            [-42.881225438999898, -20.406474585999899],
            [-42.881281783, -20.406468118],
            [-42.881342415999903, -20.406460448],
            [-42.881472308, -20.406448688999902],
            [-42.881707368, -20.406485218999901],
            [-42.881821839999901, -20.406506869999902],
            [-42.882024922, -20.406566518],
            [-42.882145017, -20.406621182],
            [-42.882240629, -20.40669207],
            [-42.882317878, -20.406816662],
            [-42.882438894, -20.406937957],
            [-42.88254861, -20.406990183999898],
            [-42.8826805859999, -20.407049926],
            [-42.882837598, -20.407100311],
            [-42.883001097, -20.407099978],
            [-42.883227201, -20.407072931],
            [-42.883487781, -20.40703501],
            [-42.883707017999903, -20.406971902999899],
            [-42.883845742999902, -20.406910072],
            [-42.884033701, -20.406819712999901],
            [-42.884144524, -20.406794481],
            [-42.884238726, -20.40676568],
            [-42.884394632, -20.406721443],
            [-42.884539425999897, -20.4066479869999],
            [-42.88456475, -20.406631589],
            [-42.884706686, -20.406538896],
            [-42.884877184999901, -20.4064184769999],
            [-42.884906585, -20.406393938],
            [-42.88493826, -20.406349514999899],
            [-42.885063824, -20.406170722999899],
            [-42.885152132, -20.405955832],
            [-42.885181851, -20.405872753],
            [-42.885245157, -20.405088665999902],
            [-42.885244722, -20.404147551999898],
            [-42.885155981, -20.403495366],
            [-42.8851273349999, -20.403161136999898],
            [-42.885170189, -20.4027629519999],
            [-42.885321002999902, -20.402475917999901],
            [-42.885549656, -20.402281319999901],
            [-42.885631477999901, -20.4022485909999],
            [-42.885665876, -20.402068082],
            [-42.885815349, -20.402078719],
            [-42.885683904, -20.401998646],
            [-42.884990973999898, -20.401714539999901],
            [-42.884378445, -20.401462804999898],
            [-42.8837789459999, -20.401266436],
            [-42.883213710999897, -20.401144450999901],
            [-42.882370045999899, -20.4009274709999],
            [-42.881716538, -20.400795673999902],
            [-42.880871677, -20.400519287999899],
            [-42.88026389, -20.400332561],
            [-42.879635334999897, -20.400114933999902],
            [-42.878944063999903, -20.399881544],
            [-42.878286703, -20.39983652],
            [-42.877826188, -20.399787409999899],
            [-42.8767981129999, -20.399643538],
            [-42.875812643, -20.399522878],
            [-42.875261097, -20.399373811],
            [-42.874799754, -20.398993203],
            [-42.874433226, -20.398599251999901],
            [-42.874126702, -20.398009210999898],
            [-42.873709925, -20.397091512],
            [-42.873338151, -20.39715223],
            [-42.872775741999902, -20.397136084],
            [-42.871921, -20.396980664],
            [-42.87120859, -20.396922203],
            [-42.87091909, -20.396925528999901],
            [-42.870475907, -20.396943692]
          ],
          [
            [-42.872293187, -20.428998958],
            [-42.872763655, -20.427110319999901],
            [-42.874219883, -20.427445441],
            [-42.874003799, -20.428165324],
            [-42.873838282, -20.428546743],
            [-42.873549490999899, -20.428931507],
            [-42.873231591, -20.429202088],
            [-42.872293187, -20.428998958]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 94,
        "id": 37005,
        "CD_SETOR": "315210505070029P",
        "AREA_KM2": 29.756163,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 78
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.891196188, -20.436892611],
            [-42.889578743999898, -20.438250608],
            [-42.888441563, -20.439460794],
            [-42.888248652999899, -20.439514152],
            [-42.8878926739999, -20.43957023],
            [-42.887384679, -20.439656059999901],
            [-42.887076123, -20.439735503999898],
            [-42.886888536999898, -20.4398562609999],
            [-42.88660078, -20.440231449],
            [-42.886312041999901, -20.440673569],
            [-42.886006837, -20.440943548],
            [-42.885149022, -20.440993916],
            [-42.884636567, -20.441164278],
            [-42.884030756, -20.441563484],
            [-42.8831171799999, -20.442442674999899],
            [-42.882339864999899, -20.442720054999899],
            [-42.880679369999903, -20.443114434999899],
            [-42.880504689, -20.443318383],
            [-42.880342685, -20.443922823],
            [-42.880106328, -20.444925215999898],
            [-42.879712087, -20.4460895839999],
            [-42.879477584, -20.446817653],
            [-42.879319775999903, -20.447491753999898],
            [-42.879061253, -20.447615755],
            [-42.878446455999899, -20.447417123],
            [-42.877993655999902, -20.447017129],
            [-42.877335405999901, -20.446710229],
            [-42.876780808, -20.446631859],
            [-42.876421622, -20.446704908],
            [-42.876095918, -20.4468928329999],
            [-42.875855375999897, -20.447389219999899],
            [-42.875692505, -20.448087687999902],
            [-42.875706255, -20.448981875999898],
            [-42.8751764309999, -20.449717408],
            [-42.874602738, -20.450591491],
            [-42.87428834, -20.450660026],
            [-42.873755753, -20.450543825],
            [-42.872852516, -20.450291122],
            [-42.872276746, -20.450301270999901],
            [-42.871403965, -20.450499620999899],
            [-42.870174979, -20.450899862],
            [-42.869695673, -20.451409371],
            [-42.869049452999903, -20.452490178999899],
            [-42.868844677, -20.453215203],
            [-42.868773851999897, -20.4535020329999],
            [-42.869327255, -20.453987132999899],
            [-42.869876428999902, -20.454971601],
            [-42.870303867, -20.455476572999899],
            [-42.870123444999898, -20.456048961],
            [-42.869815721999899, -20.456727349999898],
            [-42.8684492679999, -20.4567011839999],
            [-42.86787084, -20.456850489],
            [-42.867091419, -20.457154596],
            [-42.866478361, -20.457782946],
            [-42.864395051, -20.459218124],
            [-42.864035271, -20.45964917],
            [-42.864116828, -20.461323169],
            [-42.864228286, -20.461676434],
            [-42.864498712999897, -20.462118217999901],
            [-42.864430497999898, -20.4624516219999],
            [-42.864239552, -20.462983492],
            [-42.863900007, -20.463202803999899],
            [-42.863619287999903, -20.463231532999899],
            [-42.863276814999899, -20.463210058],
            [-42.862459674, -20.463334952],
            [-42.862153045999897, -20.463363145],
            [-42.861486333999899, -20.463837514],
            [-42.861038881, -20.464199999],
            [-42.860822723, -20.464248472],
            [-42.860572807, -20.464155041],
            [-42.858740344999902, -20.463450096],
            [-42.858567179, -20.463445814],
            [-42.858405730999898, -20.463572056],
            [-42.857417943, -20.464067699999902],
            [-42.857235187999898, -20.465668954],
            [-42.8569966539999, -20.467047618],
            [-42.856790314, -20.467613943999901],
            [-42.856649633, -20.467672564999901],
            [-42.856011004, -20.467510869],
            [-42.855273826, -20.467462941],
            [-42.854899611, -20.467577633],
            [-42.854431110999897, -20.467892925999902],
            [-42.854048326999902, -20.468094527999899],
            [-42.853660469, -20.468463772],
            [-42.853544, -20.468863876999901],
            [-42.853808496999903, -20.469525489],
            [-42.853885883, -20.470271242],
            [-42.853819186, -20.471085192],
            [-42.853864651, -20.471964064],
            [-42.853747864999903, -20.472479307999901],
            [-42.8535014939999, -20.472894266],
            [-42.852955732999902, -20.473063797999899],
            [-42.851307877, -20.47337927],
            [-42.850539341999898, -20.473569826999899],
            [-42.848001799, -20.474703963],
            [-42.846954269, -20.474392833],
            [-42.845183369, -20.473281477999901],
            [-42.842405447, -20.4722788569999],
            [-42.840011319, -20.472603314],
            [-42.838798041, -20.472730602999899],
            [-42.838648, -20.472924996],
            [-42.838501, -20.473108996],
            [-42.838342, -20.473280996],
            [-42.838196, -20.473463996],
            [-42.838061, -20.473647996],
            [-42.837877999999897, -20.473808995999899],
            [-42.837732, -20.473980996],
            [-42.83767, -20.474244996],
            [-42.837633, -20.474474996],
            [-42.837632, -20.475002995999901],
            [-42.837656, -20.475243996],
            [-42.837668, -20.475496995999901],
            [-42.837667, -20.475979996],
            [-42.837715, -20.4761979959999],
            [-42.837751, -20.476427995999899],
            [-42.83775, -20.476921995999898],
            [-42.837701, -20.477357996],
            [-42.8377, -20.477599996],
            [-42.837674999999898, -20.477828996],
            [-42.837674999999898, -20.478093995999899],
            [-42.837649999999897, -20.478322996],
            [-42.837648, -20.4791279959999],
            [-42.837671999999898, -20.479563996],
            [-42.837732, -20.479839996],
            [-42.837768, -20.480069995999902],
            [-42.837816, -20.480551996],
            [-42.83784, -20.480804996],
            [-42.837851999999899, -20.481034995999899],
            [-42.837888, -20.481287995999899],
            [-42.837924, -20.481597995999898],
            [-42.838009, -20.4818279959999],
            [-42.83813, -20.482069996],
            [-42.838288, -20.482264996],
            [-42.838422, -20.482448996],
            [-42.838605, -20.482667996],
            [-42.838787, -20.482862995999898],
            [-42.838945, -20.483081996],
            [-42.839042, -20.483322996],
            [-42.839176, -20.483587996],
            [-42.839261, -20.483805995999901],
            [-42.839346, -20.484058995999899],
            [-42.839382, -20.484300995999899],
            [-42.839418, -20.484564996],
            [-42.839453999999897, -20.484782996],
            [-42.83949, -20.485046995999902],
            [-42.839537999999898, -20.485276996],
            [-42.839562, -20.485506996],
            [-42.839574, -20.485724995999899],
            [-42.839622, -20.485954996],
            [-42.839683, -20.486173995999899],
            [-42.839768, -20.486380996],
            [-42.839841, -20.486598995999898],
            [-42.839938, -20.486817996],
            [-42.840072, -20.487024995999899],
            [-42.84023, -20.487185995999901],
            [-42.8404, -20.487334996],
            [-42.840564, -20.4876789959999],
            [-42.840875, -20.4875889959999],
            [-42.841168, -20.487508996],
            [-42.841424, -20.487497995999899],
            [-42.841643, -20.487589995999901],
            [-42.842472, -20.4875919959999],
            [-42.842826, -20.487660995999899],
            [-42.843117999999897, -20.487695996],
            [-42.84346, -20.487765996],
            [-42.843691, -20.487868996],
            [-42.843935, -20.487834995999901],
            [-42.844215, -20.487892996],
            [-42.844459, -20.487962996],
            [-42.844738999999898, -20.487997995999901],
            [-42.845031999999897, -20.487997995999901],
            [-42.845264, -20.487986996],
            [-42.845508, -20.487940996],
            [-42.845751999999898, -20.487860995999899],
            [-42.846008, -20.487780996],
            [-42.846349, -20.487712996],
            [-42.846679, -20.487655995999901],
            [-42.84691, -20.487587995999899],
            [-42.847264, -20.487541995999901],
            [-42.847507999999898, -20.487507996],
            [-42.847848999999897, -20.487485996],
            [-42.848092999999899, -20.4874749959999],
            [-42.848349, -20.487428995999899],
            [-42.848593999999899, -20.487314996],
            [-42.848825, -20.487234995999899],
            [-42.849033, -20.4870739959999],
            [-42.849119, -20.486798995999902],
            [-42.849179999999897, -20.486545996],
            [-42.849193, -20.486304995999902],
            [-42.849194, -20.485741995999899],
            [-42.849182, -20.485465996],
            [-42.849134, -20.485178995999899],
            [-42.849134999999897, -20.484707995999901],
            [-42.849196, -20.484477996],
            [-42.849319, -20.484270996],
            [-42.849538, -20.484087996],
            [-42.849746, -20.483961995999898],
            [-42.849978, -20.483881996],
            [-42.850246, -20.483812995999902],
            [-42.850478, -20.483767995999902],
            [-42.850758, -20.483767995999902],
            [-42.851025999999898, -20.483745996],
            [-42.851245999999897, -20.483653995999902],
            [-42.85149, -20.483688996],
            [-42.851720999999898, -20.483849995999901],
            [-42.851878999999897, -20.484045996],
            [-42.852013, -20.484275996],
            [-42.852159, -20.4845059959999],
            [-42.852268, -20.484712995999899],
            [-42.852438999999897, -20.4849309959999],
            [-42.852572, -20.485149996],
            [-42.85273, -20.485321996],
            [-42.852913, -20.4854949959999],
            [-42.853119999999898, -20.485644996],
            [-42.853315, -20.485793995999899],
            [-42.853546, -20.485920995999901],
            [-42.853814, -20.486024996],
            [-42.854033, -20.486220995999901],
            [-42.854216, -20.486358996],
            [-42.85446, -20.486519996],
            [-42.854691, -20.486623995999899],
            [-42.854897999999899, -20.486761995999899],
            [-42.855227, -20.486923996],
            [-42.855483, -20.487038996],
            [-42.855727, -20.487119995999901],
            [-42.855958, -20.487199996],
            [-42.856238, -20.487257996],
            [-42.856518999999899, -20.487304996],
            [-42.856811, -20.4873399959999],
            [-42.857079999999897, -20.487351996],
            [-42.857323, -20.487317995999899],
            [-42.857554999999898, -20.4872259959999],
            [-42.857774999999897, -20.487145996],
            [-42.858007, -20.487065996],
            [-42.858238, -20.487008995999901],
            [-42.858507, -20.486847995999899],
            [-42.858751, -20.486733995999899],
            [-42.859093, -20.486630996],
            [-42.859312, -20.486550995999899],
            [-42.859532, -20.486447996],
            [-42.859751, -20.486355995999901],
            [-42.859958999999897, -20.4861959959999],
            [-42.860179, -20.486092996],
            [-42.860447, -20.4859439959999],
            [-42.860667, -20.485805996],
            [-42.860911, -20.485668996],
            [-42.861021, -20.485427995999899],
            [-42.861179999999898, -20.485186996],
            [-42.861363, -20.485014996],
            [-42.861546, -20.484853996],
            [-42.861742, -20.484704995999898],
            [-42.861998, -20.484555995999902],
            [-42.86223, -20.484475996],
            [-42.862474, -20.484430996],
            [-42.862742, -20.484430996],
            [-42.86301, -20.484511996],
            [-42.863242, -20.4845929959999],
            [-42.863534, -20.4847309959999],
            [-42.863765, -20.4848689959999],
            [-42.863971999999897, -20.4850419959999],
            [-42.864179, -20.485225995999901],
            [-42.864313, -20.485421996],
            [-42.864447, -20.485662996],
            [-42.864605, -20.485869996],
            [-42.864739, -20.486099996],
            [-42.864798999999898, -20.486317996],
            [-42.864909, -20.486559996],
            [-42.865006, -20.486858996],
            [-42.865054, -20.487168996],
            [-42.865052999999897, -20.487478995999901],
            [-42.865041, -20.487731996],
            [-42.865004, -20.4879729959999],
            [-42.864942, -20.488190996],
            [-42.864843999999898, -20.4883979959999],
            [-42.864478, -20.488741995999899],
            [-42.864209, -20.488959995999899],
            [-42.864002, -20.489177996],
            [-42.863806, -20.489383996],
            [-42.863684, -20.489625996],
            [-42.863501, -20.489820995999899],
            [-42.863366, -20.490003995999899],
            [-42.86322, -20.490187996],
            [-42.863085, -20.490370996],
            [-42.862938, -20.490565995999901],
            [-42.862767, -20.490783995999902],
            [-42.862633, -20.490967995999899],
            [-42.862425, -20.491185996],
            [-42.862156, -20.491460995999901],
            [-42.862022, -20.491644995999899],
            [-42.861874999999898, -20.491828996],
            [-42.861826, -20.492103996],
            [-42.861801, -20.492356996],
            [-42.861797, -20.494470996],
            [-42.861759999999897, -20.494757996],
            [-42.861699, -20.495044995999901],
            [-42.861649999999898, -20.495285996],
            [-42.861601, -20.495504996],
            [-42.861539, -20.495733996],
            [-42.861453, -20.496009995999898],
            [-42.861331, -20.496342996],
            [-42.861208, -20.496629996],
            [-42.861098, -20.496824995999901],
            [-42.860976, -20.497019995999899],
            [-42.860744, -20.497157995999899],
            [-42.860561, -20.497409995999899],
            [-42.860413999999899, -20.497616996],
            [-42.860205999999899, -20.497845996],
            [-42.859986, -20.498017995999898],
            [-42.859803, -20.498201996],
            [-42.859632, -20.4983509959999],
            [-42.859449, -20.498499996],
            [-42.859156, -20.498682996],
            [-42.858949, -20.498808996],
            [-42.858497, -20.498842996],
            [-42.858252999999898, -20.498841996],
            [-42.857985, -20.498876996],
            [-42.857753, -20.498898996],
            [-42.857424, -20.498932996],
            [-42.857143999999899, -20.4989559959999],
            [-42.856766, -20.498988996],
            [-42.856473, -20.499022996],
            [-42.856229, -20.499057996],
            [-42.855887, -20.499125996],
            [-42.855631, -20.499182995999899],
            [-42.855351, -20.499319995999901],
            [-42.855131, -20.499399996],
            [-42.854923, -20.4995259959999],
            [-42.854789, -20.499709996],
            [-42.854494, -20.500152995999901],
            [-42.854506, -20.500842996],
            [-42.854531, -20.501107996],
            [-42.854568, -20.501348995999901],
            [-42.854617, -20.501566996],
            [-42.854654, -20.501818995999901],
            [-42.854679, -20.502060996],
            [-42.854643, -20.502347996],
            [-42.854557999999898, -20.502554996],
            [-42.854498, -20.502783995999899],
            [-42.854401, -20.503036995999899],
            [-42.854328, -20.503278995999899],
            [-42.854329, -20.503519996],
            [-42.854366, -20.503817996],
            [-42.854501, -20.504104996],
            [-42.854635, -20.5043229959999],
            [-42.854745, -20.504517995999901],
            [-42.85488, -20.504712996],
            [-42.855027, -20.504896996],
            [-42.855149, -20.505114996],
            [-42.855308, -20.5053329959999],
            [-42.855502999999899, -20.505538995999899],
            [-42.855662, -20.505710995999902],
            [-42.855820999999899, -20.505905996],
            [-42.855992, -20.5060549959999],
            [-42.856139, -20.506237996],
            [-42.856261, -20.506467996],
            [-42.856371, -20.506696996],
            [-42.856433, -20.506926995999901],
            [-42.856493999999898, -20.507144996],
            [-42.856518999999899, -20.507408996],
            [-42.856522, -20.508488996],
            [-42.856571, -20.508752995999899],
            [-42.856657, -20.5089819959999],
            [-42.856755, -20.509245995999901],
            [-42.856999, -20.509497995999901],
            [-42.857182, -20.509704996],
            [-42.857366, -20.510002996],
            [-42.857561, -20.510128995999899],
            [-42.857805, -20.510254996],
            [-42.858062, -20.510368996],
            [-42.858306, -20.510506995999901],
            [-42.858526, -20.510689995999901],
            [-42.85877, -20.510884996],
            [-42.858965, -20.511021996],
            [-42.859234, -20.511227995999899],
            [-42.859453, -20.511376996],
            [-42.85971, -20.511606996],
            [-42.859918, -20.511846996],
            [-42.860089, -20.512168996],
            [-42.860175, -20.5124899959999],
            [-42.8602, -20.512719995999898],
            [-42.860298, -20.513006995999898],
            [-42.860433, -20.513292996],
            [-42.860531, -20.513510996],
            [-42.860629, -20.5137519959999],
            [-42.860739, -20.514061996],
            [-42.860886, -20.514360995999901],
            [-42.861082, -20.514865996],
            [-42.86112, -20.515094996],
            [-42.861169, -20.515370995999898],
            [-42.86123, -20.515668995999899],
            [-42.861291999999899, -20.515955995999899],
            [-42.861366, -20.516196996],
            [-42.861415, -20.516426996],
            [-42.861733, -20.516954996],
            [-42.861929, -20.517275996],
            [-42.862112, -20.517470995999901],
            [-42.862259, -20.517688995999901],
            [-42.862442, -20.517860996],
            [-42.862710999999898, -20.518043996],
            [-42.862992, -20.518238996],
            [-42.863236, -20.518386995999901],
            [-42.863468, -20.518455995999901],
            [-42.863797, -20.518592995999899],
            [-42.864102, -20.518741996],
            [-42.864346, -20.518878996],
            [-42.864566, -20.518981996],
            [-42.864834, -20.519072996],
            [-42.865103, -20.519095996],
            [-42.865346, -20.519094996],
            [-42.865907, -20.519116996],
            [-42.866151, -20.5191739959999],
            [-42.866395, -20.519207996],
            [-42.866639, -20.519276996],
            [-42.866822, -20.519425995999899],
            [-42.867091, -20.519573996],
            [-42.867370999999899, -20.519688996],
            [-42.867603, -20.519859996],
            [-42.867762, -20.520031995999901],
            [-42.868055, -20.520111996],
            [-42.868299, -20.520180996],
            [-42.868592, -20.520248996],
            [-42.868885, -20.520374995999902],
            [-42.869129, -20.520465995999899],
            [-42.869348, -20.520580996],
            [-42.869568, -20.520705995999901],
            [-42.869751, -20.520854996],
            [-42.870044, -20.520842995999899],
            [-42.870275999999897, -20.520968996],
            [-42.870483, -20.521106996],
            [-42.870691, -20.521255996],
            [-42.870911, -20.521369996],
            [-42.871094, -20.521506996],
            [-42.871301, -20.521621996],
            [-42.871485, -20.521770995999901],
            [-42.871924, -20.522068996],
            [-42.872132, -20.5221709959999],
            [-42.872327, -20.522354995999901],
            [-42.872394, -20.522447996],
            [-42.872703, -20.521970995999901],
            [-42.872886, -20.521706996],
            [-42.873215, -20.521284996],
            [-42.873373, -20.521077996],
            [-42.873506, -20.5208709959999],
            [-42.873675999999897, -20.520651996],
            [-42.873871, -20.520444996],
            [-42.874029, -20.5202839959999],
            [-42.874151, -20.520076995999901],
            [-42.874333, -20.519903996],
            [-42.87454, -20.519742995999898],
            [-42.874722, -20.519558995999901],
            [-42.874794999999899, -20.519317995999899],
            [-42.87499, -20.519190996],
            [-42.875233, -20.519017995999899],
            [-42.87544, -20.518879996],
            [-42.875684, -20.518729995999902],
            [-42.875939, -20.518637995999899],
            [-42.876171, -20.518579996],
            [-42.876476, -20.518532996],
            [-42.876756, -20.518474996],
            [-42.877, -20.5184169959999],
            [-42.877487, -20.5182789959999],
            [-42.877766999999899, -20.5181169959999],
            [-42.877962, -20.517967996],
            [-42.878327, -20.517667995999901],
            [-42.878534, -20.517540996],
            [-42.878704, -20.517391996],
            [-42.878898999999898, -20.517195995999899],
            [-42.879165999999898, -20.5170349959999],
            [-42.879422, -20.5169309959999],
            [-42.879654, -20.516815995999899],
            [-42.879921, -20.5166309959999],
            [-42.88019, -20.516618996],
            [-42.880458, -20.516595996],
            [-42.880714, -20.516571996],
            [-42.880957, -20.516536996],
            [-42.88125, -20.516409995999901],
            [-42.881518, -20.5162949959999],
            [-42.881797999999897, -20.516202995999901],
            [-42.882102, -20.516155996],
            [-42.882468, -20.516085996],
            [-42.882723999999897, -20.516016996],
            [-42.882967999999899, -20.515946996],
            [-42.883272, -20.515865995999899],
            [-42.883516, -20.515830996],
            [-42.883857, -20.515772996],
            [-42.884161999999897, -20.5157149959999],
            [-42.88443, -20.515622995999902],
            [-42.884686, -20.515564996],
            [-42.884977999999897, -20.515448996],
            [-42.885258, -20.515287995999898],
            [-42.885489, -20.5151269959999],
            [-42.885684, -20.514942995999899],
            [-42.885829999999899, -20.514769995999899],
            [-42.885975, -20.514573996],
            [-42.886109, -20.514378995999898],
            [-42.88617, -20.514160995999902],
            [-42.886218, -20.5138619959999],
            [-42.886217, -20.513551995999901],
            [-42.886204, -20.513321995999899],
            [-42.886082, -20.5130809959999],
            [-42.885935, -20.512862996],
            [-42.885849, -20.512621996],
            [-42.885775, -20.512369996],
            [-42.885677, -20.512139996],
            [-42.885555, -20.511875996],
            [-42.88542, -20.511669996],
            [-42.885298, -20.511439996],
            [-42.885163, -20.511175996],
            [-42.885064999999898, -20.510912995999899],
            [-42.885052, -20.510682996],
            [-42.885051, -20.510131995999899],
            [-42.885061999999898, -20.509889996],
            [-42.885098, -20.509637996],
            [-42.885147, -20.509395996],
            [-42.885207, -20.509165996],
            [-42.885315999999897, -20.508878996],
            [-42.885486, -20.5087059959999],
            [-42.885644, -20.508533996],
            [-42.885839, -20.508383996],
            [-42.886034, -20.508256995999901],
            [-42.886302, -20.508084996],
            [-42.886521, -20.507911996],
            [-42.886752, -20.507669995999901],
            [-42.887007, -20.507427995999901],
            [-42.887141, -20.5071749959999],
            [-42.887237, -20.506910995999899],
            [-42.88731, -20.506692996],
            [-42.887322, -20.506473996],
            [-42.887309, -20.506244996],
            [-42.887296, -20.505991996],
            [-42.887296, -20.505738995999899],
            [-42.887259, -20.505509995999901],
            [-42.887234, -20.505233996],
            [-42.887208, -20.504935996],
            [-42.887183, -20.5046829959999],
            [-42.887133999999897, -20.504395996],
            [-42.887048, -20.504165995999902],
            [-42.886938, -20.5038909959999],
            [-42.886975999999898, -20.503031995999901],
            [-42.887068999999897, -20.5027649959999],
            [-42.887116999999897, -20.502500996],
            [-42.887239, -20.502270995999901],
            [-42.887311, -20.5020179959999],
            [-42.88742, -20.501810996],
            [-42.887566, -20.501615995999899],
            [-42.887773, -20.501465996],
            [-42.888066, -20.501407996],
            [-42.888322, -20.501372995999901],
            [-42.888626, -20.5013029959999],
            [-42.888869999999898, -20.501198996],
            [-42.889089, -20.501083996],
            [-42.889312, -20.500904996],
            [-42.889718, -20.500613996],
            [-42.890091, -20.500227996],
            [-42.890240999999897, -20.500008996],
            [-42.890432, -20.499796995999901],
            [-42.890642, -20.499616995999901],
            [-42.890737, -20.499362995999899],
            [-42.890943999999898, -20.499247996],
            [-42.891103, -20.499041996],
            [-42.891249, -20.498857996],
            [-42.891396, -20.498662996],
            [-42.891531, -20.498467995999899],
            [-42.891641, -20.498272996],
            [-42.891763, -20.4980779959999],
            [-42.891885, -20.497859995999899],
            [-42.892056, -20.497618996],
            [-42.892178999999899, -20.497411996],
            [-42.89235, -20.4972279959999],
            [-42.892496, -20.497032996],
            [-42.892654999999898, -20.496860996],
            [-42.89285, -20.496700996],
            [-42.893034, -20.496516996],
            [-42.893253, -20.496287996],
            [-42.893436999999899, -20.496114996],
            [-42.893583, -20.495931996],
            [-42.893729999999898, -20.495759995999901],
            [-42.893912999999898, -20.495541995999901],
            [-42.894095999999898, -20.4953579959999],
            [-42.894292, -20.495162996],
            [-42.894487, -20.494990996],
            [-42.894731, -20.494795995999901],
            [-42.894975, -20.494761996],
            [-42.895231, -20.4947049959999],
            [-42.895524, -20.4945669959999],
            [-42.895732, -20.4944529959999],
            [-42.896, -20.494383996],
            [-42.896366, -20.494280995999901],
            [-42.89661, -20.494246996],
            [-42.896854, -20.494201996],
            [-42.897159, -20.494155996],
            [-42.8975, -20.494087996],
            [-42.897756, -20.493995996],
            [-42.898049, -20.493846995999899],
            [-42.898293, -20.493731996],
            [-42.898513, -20.4936059959999],
            [-42.89872, -20.493468995999901],
            [-42.898904, -20.493296996],
            [-42.899099, -20.493078996],
            [-42.899245999999899, -20.492894996],
            [-42.89938, -20.492699995999899],
            [-42.899441, -20.492435995999902],
            [-42.899478, -20.492194996],
            [-42.89954, -20.491975996],
            [-42.899565, -20.491723996],
            [-42.899577, -20.4915049959999],
            [-42.899578, -20.490677995999899],
            [-42.899591, -20.490447995999901],
            [-42.899628, -20.490218996],
            [-42.899653, -20.489896996],
            [-42.899726, -20.4896209959999],
            [-42.899763, -20.489345995999901],
            [-42.899811999999898, -20.489092996],
            [-42.899836999999899, -20.488862996],
            [-42.899911, -20.488644995999898],
            [-42.89996, -20.488426995999902],
            [-42.900081999999898, -20.488219995999899],
            [-42.900216999999898, -20.488001996],
            [-42.900632, -20.487565995999901],
            [-42.900839, -20.4873019959999],
            [-42.901046999999899, -20.487106996],
            [-42.901267, -20.486922996],
            [-42.901438, -20.486762996],
            [-42.901645, -20.4866249959999],
            [-42.901840999999898, -20.486475996],
            [-42.902024, -20.486303995999901],
            [-42.902183, -20.4860859959999],
            [-42.902232, -20.485867996],
            [-42.902232, -20.485614996],
            [-42.902159, -20.485384995999901],
            [-42.902162, -20.483776996],
            [-42.902174, -20.483431996],
            [-42.902187, -20.483213996],
            [-42.902236, -20.482948995999902],
            [-42.902371, -20.482673996],
            [-42.902444, -20.482455995999899],
            [-42.902554, -20.482191995999901],
            [-42.902676, -20.481984995999898],
            [-42.90275, -20.481743996],
            [-42.902824, -20.481513995999901],
            [-42.902885, -20.481169995999899],
            [-42.902921999999897, -20.480893996],
            [-42.902947, -20.480675995999899],
            [-42.902959, -20.480456995999901],
            [-42.902972, -20.480192996],
            [-42.902983999999897, -20.479974996],
            [-42.902997, -20.4795499959999],
            [-42.90301, -20.479296996],
            [-42.903035, -20.479009995999899],
            [-42.903059, -20.478745995999901],
            [-42.903133, -20.478377995999899],
            [-42.903182, -20.478159995999899],
            [-42.903292025, -20.477710343],
            [-42.903083636, -20.477757245],
            [-42.902946893, -20.477858812],
            [-42.902856845, -20.477903254],
            [-42.902553861999898, -20.477963433],
            [-42.902400805999903, -20.477961967999899],
            [-42.902312213999899, -20.477962963],
            [-42.902156197999901, -20.477935602999899],
            [-42.901948708, -20.4780752039999],
            [-42.9019163, -20.478287179999899],
            [-42.902012582999902, -20.478415723],
            [-42.901935168, -20.478505346],
            [-42.901685495, -20.478493768],
            [-42.90154616, -20.478528937],
            [-42.901367691, -20.478650817],
            [-42.901026253999902, -20.478636903999899],
            [-42.900853755, -20.4785958749999],
            [-42.900644515, -20.478554745999901],
            [-42.900461385, -20.478491935],
            [-42.900376638, -20.478499755],
            [-42.90034459, -20.478556747],
            [-42.900364376, -20.478630618999901],
            [-42.900274275999898, -20.478731755],
            [-42.900032874, -20.47880472],
            [-42.899962601, -20.478790843999899],
            [-42.899976153999901, -20.478717036],
            [-42.900039451999902, -20.478639996],
            [-42.89998997, -20.4784699759999],
            [-42.8998802, -20.478281448],
            [-42.899840383, -20.478234686999901],
            [-42.899774973, -20.478235541999901],
            [-42.899695739, -20.478317403999899],
            [-42.899665778, -20.4784071199999],
            [-42.899606635999902, -20.478459657],
            [-42.899513961, -20.478414754],
            [-42.899281884999901, -20.478200162],
            [-42.899034862999898, -20.477802782],
            [-42.898898711999898, -20.477628044],
            [-42.898974016999901, -20.477293727],
            [-42.898969971, -20.477153792],
            [-42.898908389, -20.476937149],
            [-42.898970689, -20.476868988],
            [-42.899055539, -20.476776155],
            [-42.899207007, -20.476387794999901],
            [-42.899319728, -20.4762990739999],
            [-42.899297439, -20.476134729],
            [-42.899237038, -20.476000225],
            [-42.899139858, -20.475875284],
            [-42.898949955, -20.475697609999902],
            [-42.898870963, -20.47516307],
            [-42.898854839, -20.474875338999901],
            [-42.898911423999898, -20.474657894],
            [-42.898809846999903, -20.474629494999899],
            [-42.8986360379999, -20.4746720979999],
            [-42.898576128999899, -20.474774775999901],
            [-42.898461149, -20.474889812],
            [-42.898341042, -20.474891146999902],
            [-42.898194818, -20.474764644999901],
            [-42.897838093, -20.474497345],
            [-42.897522592, -20.474280840999899],
            [-42.897254678, -20.474181389],
            [-42.897134716, -20.473771703999901],
            [-42.896956599, -20.473570568],
            [-42.896671524999903, -20.4735168899999],
            [-42.896170944999902, -20.473372502],
            [-42.896042396, -20.473293457],
            [-42.896031308, -20.473120552999902],
            [-42.89566804, -20.473037024],
            [-42.895275336999902, -20.472902583],
            [-42.895099688, -20.472722362],
            [-42.8951622989999, -20.472613237],
            [-42.895469821999903, -20.472583880999899],
            [-42.895661509, -20.472367756],
            [-42.895708439, -20.472055144],
            [-42.895560409, -20.471790284],
            [-42.895236908, -20.471720517999898],
            [-42.895204129, -20.471501015999898],
            [-42.895257651, -20.471327163999899],
            [-42.895357912999899, -20.4707878409999],
            [-42.895598966, -20.470357554],
            [-42.895615432999897, -20.470117844],
            [-42.895673689, -20.4697991479999],
            [-42.895316337999901, -20.469745114],
            [-42.895198287, -20.469854986],
            [-42.895005335999898, -20.469893225],
            [-42.894769443, -20.470308483999901],
            [-42.894605073, -20.470323826],
            [-42.894163952, -20.470174041],
            [-42.894123607, -20.470110721],
            [-42.893963875999901, -20.4705081389999],
            [-42.893721442999897, -20.471015825999899],
            [-42.893590243999903, -20.471256309999902],
            [-42.8933306979999, -20.471825451],
            [-42.893270803, -20.471937676],
            [-42.893079708999899, -20.4721460929999],
            [-42.892902875999901, -20.472317102],
            [-42.892591991, -20.4722155659999],
            [-42.892306775999899, -20.4721460929999],
            [-42.891935997, -20.471975084],
            [-42.891553808, -20.471761323],
            [-42.891188733, -20.471456712],
            [-42.890606894, -20.470949026],
            [-42.890013646999897, -20.4705321869999],
            [-42.889796883, -20.470345144],
            [-42.889403285999897, -20.470072595],
            [-42.889446069, -20.470011138],
            [-42.889477441999901, -20.469957696999899],
            [-42.889485998999902, -20.469882879],
            [-42.88950026, -20.4697759969999],
            [-42.889534485, -20.469709195999901],
            [-42.889574415, -20.469671787],
            [-42.889648571, -20.4696477379999],
            [-42.889708466999899, -20.469613001],
            [-42.889782623, -20.469498103],
            [-42.889910969, -20.46928701],
            [-42.890047873, -20.4690812609999],
            [-42.890104916, -20.4688835269999],
            [-42.890087803, -20.468739234999902],
            [-42.890039316, -20.468597615],
            [-42.889868186999898, -20.468087248],
            [-42.889762656999899, -20.467646353],
            [-42.889717022999903, -20.4674031919999],
            [-42.889739839999898, -20.467363110999901],
            [-42.889791179, -20.467355094],
            [-42.88992523, -20.4673470779999],
            [-42.890047873, -20.467341733999898],
            [-42.890096359, -20.467325700999901],
            [-42.890204740999899, -20.467261570999899],
            [-42.890293158, -20.467152015],
            [-42.890364374999898, -20.4671000129999],
            [-42.890088643999903, -20.467134385],
            [-42.890031775, -20.467099603999898],
            [-42.889997391999898, -20.467003335],
            [-42.889988591, -20.466790604],
            [-42.889886615, -20.466635868],
            [-42.889851275, -20.4665509169999],
            [-42.8898472939999, -20.466541347],
            [-42.889893048, -20.466434],
            [-42.88997749, -20.466368838999902],
            [-42.890183259, -20.466278189],
            [-42.890386105999902, -20.466141137],
            [-42.890609895, -20.465929623],
            [-42.890650767999901, -20.465827175],
            [-42.890587380999897, -20.465544021],
            [-42.890509467999898, -20.465427030999901],
            [-42.890449369999899, -20.465390676],
            [-42.890164672, -20.465426581],
            [-42.890097872999903, -20.465390663999901],
            [-42.890065917, -20.465327667999901],
            [-42.890060578, -20.465218959],
            [-42.890112873, -20.465057773999899],
            [-42.890116102, -20.464986113999899],
            [-42.890654884999897, -20.463858554],
            [-42.890738373, -20.463741900999899],
            [-42.891010974, -20.4635726459999],
            [-42.891541607, -20.4632297009999],
            [-42.891907316999898, -20.462936844],
            [-42.892170321, -20.462736408999898],
            [-42.892221664, -20.462652748],
            [-42.892202258999902, -20.462580914999901],
            [-42.892134143, -20.462495378],
            [-42.8919126179999, -20.46234901],
            [-42.891648381, -20.462308037],
            [-42.891456342, -20.4623269749999],
            [-42.890930581, -20.462314431],
            [-42.890829293, -20.462386556],
            [-42.890741566, -20.462500699],
            [-42.890574963999903, -20.462940881],
            [-42.890486762, -20.4629895109999],
            [-42.890419048999902, -20.462947749],
            [-42.889924112, -20.462469929],
            [-42.889872693, -20.462455145],
            [-42.889678021, -20.462631127],
            [-42.889644168, -20.462902778999901],
            [-42.889641776, -20.463012842999898],
            [-42.889614067, -20.46304155],
            [-42.889526577, -20.463038691999898],
            [-42.889391732, -20.462913749999899],
            [-42.888988955999899, -20.462860367],
            [-42.888875404, -20.462801855999899],
            [-42.888474365, -20.461725251],
            [-42.888313842999899, -20.461207074999901],
            [-42.888101537, -20.460877938],
            [-42.887853361999902, -20.460760045],
            [-42.887706674, -20.460751544999901],
            [-42.887638001, -20.460709296999902],
            [-42.887570619, -20.460535245],
            [-42.88750957, -20.460284123],
            [-42.887549603, -20.460059778999899],
            [-42.887633593, -20.459916887],
            [-42.887729525999902, -20.459806849],
            [-42.887750817, -20.459644633],
            [-42.887738659, -20.459330854],
            [-42.887809919999903, -20.459199464999902],
            [-42.8880496089999, -20.459017566999901],
            [-42.888198960999901, -20.45883638],
            [-42.888241897999897, -20.4587280759999],
            [-42.888211354, -20.458631431],
            [-42.888019884999899, -20.458430666],
            [-42.887927077, -20.4583081819999],
            [-42.8878874, -20.458133573999898],
            [-42.887916710999903, -20.457955806],
            [-42.887992439999898, -20.457713144],
            [-42.888116994, -20.457545249],
            [-42.888178938, -20.457457088999899],
            [-42.888217203999901, -20.457342937],
            [-42.888362634, -20.456627145],
            [-42.888378541, -20.456368785999899],
            [-42.888328937, -20.456166637],
            [-42.887959666, -20.455727641],
            [-42.887829462999903, -20.455588121999899],
            [-42.887770569999901, -20.455425767],
            [-42.887799023999897, -20.455184201999899],
            [-42.887746477999897, -20.45494831],
            [-42.887796978, -20.454716966],
            [-42.887910075, -20.454532065],
            [-42.887958603999898, -20.454426754999901],
            [-42.887935491, -20.454253309],
            [-42.887889183, -20.454156075],
            [-42.887837493999903, -20.454002795],
            [-42.887930305999902, -20.453837274],
            [-42.8880940029999, -20.4537791999999],
            [-42.888125726999903, -20.453721576],
            [-42.888096647999902, -20.453676307],
            [-42.887868722, -20.4536406829999],
            [-42.887764881, -20.453646971],
            [-42.8876611909999, -20.4535066],
            [-42.887654551, -20.45341561],
            [-42.887666324, -20.453340001999901],
            [-42.887760930999903, -20.453114619],
            [-42.887775801, -20.452568583],
            [-42.887857329, -20.452283805],
            [-42.888071719, -20.452073533],
            [-42.888304615, -20.451926876],
            [-42.88920694, -20.451576158],
            [-42.889265307, -20.451509513],
            [-42.88932111, -20.451374207999901],
            [-42.889324971, -20.4511348529999],
            [-42.889239561999901, -20.4507094639999],
            [-42.889082866, -20.450471029],
            [-42.888872743, -20.450296170999898],
            [-42.888556152, -20.450207453],
            [-42.888515369999901, -20.450138131],
            [-42.888552827, -20.450032007999901],
            [-42.888701242, -20.449896478],
            [-42.88883135, -20.449825599999901],
            [-42.888964793999897, -20.449821777],
            [-42.889018442999898, -20.449870616999899],
            [-42.889070220999898, -20.449957997],
            [-42.889178327, -20.4500021439999],
            [-42.889288625999903, -20.450014799],
            [-42.889364203999897, -20.44996771],
            [-42.889484433999897, -20.449806376],
            [-42.889531561, -20.449653767],
            [-42.889548509, -20.449494322],
            [-42.889526287, -20.4493844039999],
            [-42.889548432, -20.449281336],
            [-42.889607568, -20.449162553999901],
            [-42.889614836, -20.448921051],
            [-42.889620615999902, -20.448766237],
            [-42.889704653, -20.448643354],
            [-42.889877542, -20.448424619],
            [-42.889972023, -20.448144387],
            [-42.8902151469999, -20.44790016],
            [-42.890457517, -20.447734427999901],
            [-42.890487556, -20.4476156489999],
            [-42.89047819, -20.44741177],
            [-42.890404740999898, -20.447297874],
            [-42.890337128999903, -20.447251885999901],
            [-42.890220037999903, -20.447180585999899],
            [-42.890157428999899, -20.447072209999899],
            [-42.89015341, -20.446992977],
            [-42.890224382, -20.446867179],
            [-42.8902583019999, -20.44677002],
            [-42.890222713999897, -20.446666759],
            [-42.890251679, -20.446593698],
            [-42.890320522, -20.446514755],
            [-42.890351864, -20.4464160439999],
            [-42.890337957, -20.445787507],
            [-42.890155184, -20.445196963999901],
            [-42.890042531999903, -20.4449222719999],
            [-42.890128724, -20.4445381479999],
            [-42.890187066, -20.444399849],
            [-42.890198461, -20.444208478999901],
            [-42.890201112999897, -20.443994707999899],
            [-42.890258198, -20.443837863999899],
            [-42.890353505, -20.44365771],
            [-42.890456762, -20.443579126],
            [-42.890778859, -20.443354063999902],
            [-42.890812195, -20.44327759],
            [-42.890798009, -20.443163049],
            [-42.890754047, -20.4431100309999],
            [-42.890525222999898, -20.443017421],
            [-42.890390582999899, -20.442850609999901],
            [-42.89034069, -20.442762267],
            [-42.890331558, -20.44267123],
            [-42.890418290999897, -20.442408651999902],
            [-42.890485082999902, -20.442019458999901],
            [-42.890471213999902, -20.441892767],
            [-42.890514029, -20.441809957],
            [-42.890624984999903, -20.441613339],
            [-42.890701513, -20.441371795],
            [-42.890716247999897, -20.441237514],
            [-42.890802795, -20.441093634],
            [-42.890929437, -20.440994729],
            [-42.891341686, -20.440679917],
            [-42.891361181, -20.440595256999899],
            [-42.891334107, -20.440501052999899],
            [-42.891261683, -20.440412713999901],
            [-42.891106787999902, -20.440284887],
            [-42.891228966, -20.440109207],
            [-42.891444032, -20.439985985],
            [-42.891494533, -20.439934085999901],
            [-42.891509712999898, -20.439748095],
            [-42.891544766, -20.439377147],
            [-42.891468797999899, -20.439234519],
            [-42.891477773, -20.439172831999901],
            [-42.891509801, -20.439102099],
            [-42.891919562999902, -20.438733786999901],
            [-42.892252180999897, -20.438345311],
            [-42.892435546999899, -20.438221945],
            [-42.892495872, -20.438119232],
            [-42.892653955, -20.437924193999901],
            [-42.892722752999902, -20.437862469],
            [-42.892772878999899, -20.437728179],
            [-42.892829208, -20.437689527],
            [-42.8929223, -20.437680344999901],
            [-42.892966701, -20.437768239],
            [-42.892970311999903, -20.437847785],
            [-42.892969525, -20.437989178999899],
            [-42.893033058999897, -20.438055574],
            [-42.893125301999902, -20.4380731799999],
            [-42.893224436, -20.438047276],
            [-42.893254617, -20.438007717999898],
            [-42.893242027, -20.4377645829999],
            [-42.893350407999897, -20.437679311],
            [-42.893363389999898, -20.437465738999901],
            [-42.893343644, -20.437332409],
            [-42.893193595999897, -20.4372384],
            [-42.893149827999899, -20.437112043],
            [-42.893176716, -20.4369817699999],
            [-42.893221207, -20.436892643],
            [-42.893182447, -20.436714337999899],
            [-42.893077149, -20.436598197],
            [-42.892988446999901, -20.436597235],
            [-42.892890957, -20.436675367],
            [-42.892719938, -20.436805222],
            [-42.892461012, -20.4368296919999],
            [-42.892345914, -20.43691918],
            [-42.892317261, -20.437016119],
            [-42.892195048, -20.437119768999899],
            [-42.892100147, -20.437274261],
            [-42.891886577, -20.437270628],
            [-42.891833770999902, -20.437171388],
            [-42.891780727, -20.437013975],
            [-42.891675280999898, -20.436963357],
            [-42.891575401, -20.4369580759999],
            [-42.891416693999901, -20.437001892],
            [-42.891196188, -20.436892611]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 95,
        "id": 37006,
        "CD_SETOR": "315210505070030P",
        "AREA_KM2": 0.054525,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 255
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.890275469, -20.40758621],
            [-42.88948368, -20.408240189],
            [-42.889535623, -20.4082809619999],
            [-42.889758448, -20.4083265679999],
            [-42.890026302, -20.408341556],
            [-42.890358789999901, -20.408368423999899],
            [-42.890400942, -20.408381246],
            [-42.890916546, -20.409005229999899],
            [-42.890535381999896, -20.409300115999901],
            [-42.890559397999901, -20.409333865999901],
            [-42.890568281, -20.409363412999902],
            [-42.890581622999903, -20.409407792],
            [-42.890569023, -20.409454828],
            [-42.890547328, -20.409493234],
            [-42.890524473, -20.409533693999901],
            [-42.890448493, -20.409604277],
            [-42.890376922, -20.409633442],
            [-42.890333418, -20.4096365189999],
            [-42.890289083, -20.4096396539999],
            [-42.890228534, -20.409612579999902],
            [-42.890036133, -20.409859372],
            [-42.889933754, -20.410007360999899],
            [-42.889848285, -20.40998445],
            [-42.889676763999901, -20.410018054],
            [-42.890658695999903, -20.410473431],
            [-42.890973365, -20.410289400999901],
            [-42.891029115, -20.4102873319999],
            [-42.891092279, -20.410310169],
            [-42.891343975, -20.410477619999899],
            [-42.8916567919999, -20.410513083],
            [-42.891870045, -20.410469507],
            [-42.891926468999898, -20.4103920219999],
            [-42.892048116999902, -20.410414751],
            [-42.892075522, -20.410370079],
            [-42.891903419, -20.4101916729999],
            [-42.892011008999901, -20.410093886],
            [-42.892333623, -20.409700905],
            [-42.892092768999902, -20.409524367],
            [-42.891881919, -20.409315173],
            [-42.8914662769999, -20.408859866999901],
            [-42.891610172999897, -20.408739532],
            [-42.892362529, -20.408121294],
            [-42.892606208, -20.408187198999901],
            [-42.8928268619999, -20.407999232],
            [-42.893096474999901, -20.407794373999899],
            [-42.893176962, -20.407736121],
            [-42.893163678, -20.407670117999899],
            [-42.8926480229999, -20.407162752],
            [-42.892136805999897, -20.407618188],
            [-42.891114559999899, -20.408440281],
            [-42.890275469, -20.40758621]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 96,
        "id": 37007,
        "CD_SETOR": "315210505070031P",
        "AREA_KM2": 0.033675,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 96
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.89240806, -20.405147298],
            [-42.892869417, -20.405576611999901],
            [-42.892436023, -20.405941694],
            [-42.8919162669999, -20.406409886999899],
            [-42.892334696, -20.406852962],
            [-42.8926480229999, -20.407162752],
            [-42.893163678, -20.407670117999899],
            [-42.893176962, -20.407736121],
            [-42.893253348, -20.407808492],
            [-42.893639917, -20.407456875],
            [-42.893892912, -20.406935018],
            [-42.893938477999903, -20.406915813999898],
            [-42.89418152, -20.406934889],
            [-42.894343143, -20.406889684],
            [-42.894379356999899, -20.406759526],
            [-42.894281670999902, -20.4067105],
            [-42.894183254, -20.406661106999898],
            [-42.893747614999903, -20.406442468],
            [-42.893663151999903, -20.406387488999901],
            [-42.893591162, -20.406323987999901],
            [-42.893473678, -20.406200205999902],
            [-42.893425185, -20.406147086],
            [-42.893391863, -20.405873660999902],
            [-42.893429579, -20.405659697],
            [-42.89344512, -20.405619822999899],
            [-42.893435882999903, -20.4055576609999],
            [-42.893124676999903, -20.4050947069999],
            [-42.892813213999901, -20.405117565],
            [-42.8927036649999, -20.405125604],
            [-42.892599924, -20.405133217],
            [-42.892477938, -20.405141953999902],
            [-42.89240806, -20.405147298]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 97,
        "id": 37008,
        "CD_SETOR": "315210505070032P",
        "AREA_KM2": 0.013917,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 31
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.894378752, -20.414314563999898],
            [-42.894457068, -20.414420607999901],
            [-42.894531691999902, -20.4144595409999],
            [-42.894624065999899, -20.414551649],
            [-42.8949117129999, -20.414831501],
            [-42.895236055999902, -20.414703373],
            [-42.89569854, -20.4145294489999],
            [-42.895874076, -20.414463435999899],
            [-42.895994604999899, -20.414411271999899],
            [-42.896031257999901, -20.414370924],
            [-42.896033890999902, -20.414237519],
            [-42.896036103, -20.414125410999901],
            [-42.896094713, -20.414100963],
            [-42.896156886, -20.4140721169999],
            [-42.896356292, -20.413979598],
            [-42.896433975, -20.413901338999899],
            [-42.8964412, -20.413893035999902],
            [-42.896408994, -20.413836546],
            [-42.896313839, -20.4137478389999],
            [-42.896436862, -20.4135863069999],
            [-42.896584587999897, -20.413440443],
            [-42.896413869999897, -20.4133652689999],
            [-42.896066677, -20.413562442999901],
            [-42.896051564999901, -20.413562734],
            [-42.895996818, -20.413566352],
            [-42.895712392, -20.413774175],
            [-42.894713407, -20.414264138],
            [-42.894577458999898, -20.414330815],
            [-42.894476668, -20.41438025],
            [-42.894445899999901, -20.4143336459999],
            [-42.894422455, -20.414311015],
            [-42.894378752, -20.414314563999898]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 98,
        "id": 37009,
        "CD_SETOR": "315210505070033P",
        "AREA_KM2": 0.233371,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 131
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.901625036, -20.393655639],
            [-42.901696392, -20.394192190999899],
            [-42.901691743, -20.394393223999899],
            [-42.901614885999898, -20.3945950029999],
            [-42.901539141999898, -20.394724685],
            [-42.900727579, -20.395781020999902],
            [-42.900662117, -20.3959763309999],
            [-42.900796797999902, -20.397502503999899],
            [-42.900695373, -20.397919809],
            [-42.900557318, -20.398500517999899],
            [-42.900544583999903, -20.398892637],
            [-42.900651393, -20.399375710999902],
            [-42.900905446, -20.400068786],
            [-42.900937352, -20.400307234],
            [-42.900881356, -20.401097959],
            [-42.900816410999902, -20.401380182],
            [-42.900707005999898, -20.401614858999899],
            [-42.900557609, -20.401821491],
            [-42.900392081999897, -20.401983137999899],
            [-42.900206652999898, -20.402114402999899],
            [-42.900060171, -20.402171079],
            [-42.899946617, -20.4021973709999],
            [-42.899792732, -20.402184373999901],
            [-42.899693721999903, -20.402158011],
            [-42.899338491, -20.4020030729999],
            [-42.899132651999899, -20.401941596],
            [-42.898868039, -20.40191326],
            [-42.898746686, -20.401916186999902],
            [-42.898642931, -20.4019421909999],
            [-42.898441324, -20.402041793],
            [-42.897507016, -20.4026963929999],
            [-42.897429777, -20.402767989],
            [-42.897363787, -20.402863984],
            [-42.896993898999902, -20.403405807999899],
            [-42.896353033, -20.40441457],
            [-42.896297709, -20.404577784],
            [-42.896112796999901, -20.404830619999899],
            [-42.895891003, -20.405032892],
            [-42.895799398999898, -20.405049236],
            [-42.895705792999898, -20.4050829409999],
            [-42.895478974, -20.40533557],
            [-42.895370505, -20.405432216],
            [-42.895259667999902, -20.405499483],
            [-42.895159183, -20.405536608],
            [-42.895069118, -20.405538027],
            [-42.894970596, -20.405504526],
            [-42.894824931999899, -20.405632365],
            [-42.894644438999897, -20.406105626999899],
            [-42.894379356999899, -20.406759526],
            [-42.894343143, -20.406889684],
            [-42.89418152, -20.406934889],
            [-42.893938477999903, -20.406915813999898],
            [-42.893892912, -20.406935018],
            [-42.893639917, -20.407456875],
            [-42.893253348, -20.407808492],
            [-42.893726232999903, -20.408289443999902],
            [-42.893797753999898, -20.408368481],
            [-42.894139503, -20.407780717],
            [-42.894209076, -20.407581832999899],
            [-42.8943050829999, -20.407041218],
            [-42.894479562, -20.407021868],
            [-42.894678999, -20.407020163],
            [-42.894943057, -20.407109558999899],
            [-42.895133154999897, -20.407130712],
            [-42.895239956, -20.407480167],
            [-42.895339438999898, -20.407718568],
            [-42.895484384, -20.407936589],
            [-42.895631011, -20.408082695],
            [-42.896044122, -20.407764754],
            [-42.896227836, -20.407668467999901],
            [-42.896344648, -20.40763097],
            [-42.896326412999898, -20.407449976999899],
            [-42.896310166, -20.407130416],
            [-42.896405737999899, -20.406482814999901],
            [-42.896477712, -20.406121810999899],
            [-42.896582697, -20.4059181269999],
            [-42.896720635, -20.405600612999901],
            [-42.896830038, -20.405317098],
            [-42.896873494, -20.405055182],
            [-42.896938642, -20.404515275999898],
            [-42.897112802, -20.404074353999899],
            [-42.897374587999899, -20.403647407999902],
            [-42.897710947999897, -20.4032770059999],
            [-42.898052307999897, -20.402953307],
            [-42.898426921, -20.402741442],
            [-42.898840736, -20.402668763999898],
            [-42.899257733, -20.402648691],
            [-42.899687742, -20.402742916],
            [-42.9000941749999, -20.402660757],
            [-42.900724149, -20.402237138],
            [-42.901427517999899, -20.4017069],
            [-42.9017766119999, -20.401244260999899],
            [-42.901944322, -20.400726241],
            [-42.902020275, -20.400491639999899],
            [-42.902177556, -20.399357063],
            [-42.902304767, -20.39744264],
            [-42.902828841, -20.3945220159999],
            [-42.902861538, -20.393610692],
            [-42.902857648, -20.393517367],
            [-42.901625036, -20.393655639]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 99,
        "id": 37010,
        "CD_SETOR": "315210505070034P",
        "AREA_KM2": 0.149532,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 272
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.902348321, -20.38363691],
            [-42.902355025, -20.3836035369999],
            [-42.902458672, -20.383099988],
            [-42.902566922999902, -20.382340065999902],
            [-42.902678484, -20.381716847],
            [-42.902743008999899, -20.380888015],
            [-42.902726975999897, -20.380501889999898],
            [-42.902703960999901, -20.3799032229999],
            [-42.90267188, -20.379054868],
            [-42.902511757, -20.378520814],
            [-42.902292987, -20.377982937],
            [-42.902108058, -20.377491762],
            [-42.901894496999901, -20.377165295999902],
            [-42.901678375, -20.377021382],
            [-42.901088322, -20.376929462],
            [-42.900600928, -20.376744174],
            [-42.899942223, -20.376406851],
            [-42.899610825, -20.376170413999901],
            [-42.899262214, -20.375927519],
            [-42.898084761999897, -20.375095729],
            [-42.897891478, -20.3749939379999],
            [-42.897621788, -20.374788751],
            [-42.897140023, -20.374110073999901],
            [-42.896769009, -20.37367262],
            [-42.89647374, -20.373438474],
            [-42.896162304, -20.3733096509999],
            [-42.896105741, -20.373271074999899],
            [-42.895407159999898, -20.372740364],
            [-42.895246454999899, -20.372517027999901],
            [-42.894672682, -20.372530847999901],
            [-42.894685011, -20.372567858],
            [-42.894749493999903, -20.372707236999901],
            [-42.894846264, -20.3728346],
            [-42.895031659, -20.373042853999898],
            [-42.895179169, -20.3731928999999],
            [-42.895341515, -20.373354382999899],
            [-42.895412671, -20.373480695],
            [-42.895547731, -20.373720445999901],
            [-42.895709407, -20.374007443],
            [-42.895848144, -20.374253721999899],
            [-42.896056651, -20.37462385],
            [-42.896342707, -20.375155569999901],
            [-42.896456457999903, -20.375308253999901],
            [-42.896604690999901, -20.375507222],
            [-42.896906492, -20.375759214],
            [-42.897068772, -20.375855448],
            [-42.897999419, -20.376391778],
            [-42.898452657999897, -20.376659543],
            [-42.898691436, -20.376799431999899],
            [-42.899204836, -20.3770956279999],
            [-42.899661804999901, -20.377358156],
            [-42.900125894, -20.377630369],
            [-42.9004919079999, -20.377851652999901],
            [-42.900664127, -20.377958448999902],
            [-42.900922937, -20.378100568],
            [-42.901242636999903, -20.378290331],
            [-42.901336052, -20.3783685669999],
            [-42.901408300999897, -20.378459248],
            [-42.901534098, -20.378644382999902],
            [-42.901621878, -20.378874378],
            [-42.901627593999898, -20.378938865],
            [-42.901683205, -20.379589091],
            [-42.901696894999901, -20.379685507],
            [-42.901746626, -20.379910724999899],
            [-42.901994143, -20.380389382],
            [-42.9020423159999, -20.3804825399999],
            [-42.902117825999902, -20.380602004],
            [-42.902249331999897, -20.380991209999902],
            [-42.902313449, -20.381420675999902],
            [-42.902281340999899, -20.3819219539999],
            [-42.902234881, -20.3821762339999],
            [-42.902113113, -20.382604593999901],
            [-42.902034879, -20.382878350999899],
            [-42.901960485, -20.383141518999899],
            [-42.901916206, -20.383249572],
            [-42.901895982, -20.383298924],
            [-42.901846358999897, -20.383420015999899],
            [-42.901904348, -20.3834583249999],
            [-42.901938464, -20.383526844],
            [-42.901953896, -20.383622283],
            [-42.902348321, -20.38363691]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 100,
        "id": 37011,
        "CD_SETOR": "315210505070035P",
        "AREA_KM2": 0.109815,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 119
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.891511779, -20.403095258999901],
            [-42.891559206, -20.403205130999901],
            [-42.891694258, -20.403498408],
            [-42.891696017, -20.403552712],
            [-42.891675429, -20.403741569],
            [-42.891599863, -20.403815704],
            [-42.89161336, -20.403924582999899],
            [-42.891658502, -20.404288279],
            [-42.891674871, -20.40443533],
            [-42.891731272, -20.404532781999901],
            [-42.891853569, -20.404638813],
            [-42.892039688999901, -20.404800177],
            [-42.892116932, -20.404905271],
            [-42.892160705, -20.404941661999899],
            [-42.892235137999897, -20.4050035399999],
            [-42.89231116, -20.405066740999899],
            [-42.892374525, -20.405119419],
            [-42.89240806, -20.405147298],
            [-42.892477938, -20.405141953999902],
            [-42.892599924, -20.405133217],
            [-42.8927036649999, -20.405125604],
            [-42.892813213999901, -20.405117565],
            [-42.893124676999903, -20.4050947069999],
            [-42.893435882999903, -20.4055576609999],
            [-42.89344512, -20.405619822999899],
            [-42.893429579, -20.405659697],
            [-42.893391863, -20.405873660999902],
            [-42.893425185, -20.406147086],
            [-42.893473678, -20.406200205999902],
            [-42.893591162, -20.406323987999901],
            [-42.893663151999903, -20.406387488999901],
            [-42.893747614999903, -20.406442468],
            [-42.894183254, -20.406661106999898],
            [-42.894281670999902, -20.4067105],
            [-42.894379356999899, -20.406759526],
            [-42.894644438999897, -20.406105626999899],
            [-42.894824931999899, -20.405632365],
            [-42.894970596, -20.405504526],
            [-42.894870690999902, -20.405553886],
            [-42.894829789, -20.4054765459999],
            [-42.894870174999902, -20.404888458999899],
            [-42.894974986, -20.403762247],
            [-42.895005249999898, -20.4034577309999],
            [-42.894908616, -20.402948274],
            [-42.894816408999901, -20.402603709999902],
            [-42.894722451999897, -20.402442083],
            [-42.894551946, -20.402138654999899],
            [-42.894398118, -20.40191318],
            [-42.894312989, -20.401823459],
            [-42.894199202, -20.401718461],
            [-42.894056761999899, -20.4016109629999],
            [-42.893984948, -20.401526732],
            [-42.893454345999899, -20.401128749999899],
            [-42.893209484, -20.400944747],
            [-42.891795500999898, -20.4022108509999],
            [-42.891591848, -20.402478028],
            [-42.891484799999901, -20.4028683569999],
            [-42.891511779, -20.403095258999901]
          ],
          [
            [-42.893279306999901, -20.404756327],
            [-42.893248458999899, -20.4046185],
            [-42.893227822, -20.404507816],
            [-42.89332179, -20.404423729],
            [-42.893386479, -20.404349351999901],
            [-42.893513571, -20.404024733],
            [-42.893526295, -20.403839127],
            [-42.893510499999898, -20.403689331],
            [-42.893481377999898, -20.4036088309999],
            [-42.893264668, -20.403390841],
            [-42.89301934, -20.403052301999899],
            [-42.892810349999898, -20.40276986],
            [-42.892673613999897, -20.402628358999898],
            [-42.89269704, -20.402406943],
            [-42.892702244, -20.402256037],
            [-42.892742644, -20.402213919],
            [-42.892821889999901, -20.402197776],
            [-42.893231976, -20.402070743],
            [-42.89369277, -20.401921974999901],
            [-42.893801513, -20.401891994],
            [-42.893881962, -20.401860364],
            [-42.894071765, -20.402028614],
            [-42.894320953, -20.402345332],
            [-42.894517916, -20.402601709],
            [-42.894567488999897, -20.402848424999899],
            [-42.8946307269999, -20.403306859999901],
            [-42.894583418, -20.403473991999899],
            [-42.894375037, -20.403572512],
            [-42.894299337999897, -20.403917497],
            [-42.894269401, -20.404413926],
            [-42.894180091, -20.404652142],
            [-42.894110817999902, -20.404719002],
            [-42.894027606, -20.404764445999898],
            [-42.893802316999903, -20.404980479],
            [-42.893660595, -20.404941806999901],
            [-42.893561851999898, -20.405002732],
            [-42.893471868999903, -20.405076965],
            [-42.893447884999901, -20.405047144],
            [-42.893279306999901, -20.404756327]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 101,
        "id": 37012,
        "CD_SETOR": "315210505070036P",
        "AREA_KM2": 0.040957,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 368
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.893279306999901, -20.404756327],
            [-42.893447884999901, -20.405047144],
            [-42.893471868999903, -20.405076965],
            [-42.893561851999898, -20.405002732],
            [-42.893660595, -20.404941806999901],
            [-42.893802316999903, -20.404980479],
            [-42.894027606, -20.404764445999898],
            [-42.894110817999902, -20.404719002],
            [-42.894180091, -20.404652142],
            [-42.894269401, -20.404413926],
            [-42.894299337999897, -20.403917497],
            [-42.894375037, -20.403572512],
            [-42.894583418, -20.403473991999899],
            [-42.8946307269999, -20.403306859999901],
            [-42.894567488999897, -20.402848424999899],
            [-42.894517916, -20.402601709],
            [-42.894320953, -20.402345332],
            [-42.894071765, -20.402028614],
            [-42.893881962, -20.401860364],
            [-42.893801513, -20.401891994],
            [-42.89369277, -20.401921974999901],
            [-42.893231976, -20.402070743],
            [-42.892821889999901, -20.402197776],
            [-42.892742644, -20.402213919],
            [-42.892702244, -20.402256037],
            [-42.89269704, -20.402406943],
            [-42.892673613999897, -20.402628358999898],
            [-42.892810349999898, -20.40276986],
            [-42.89301934, -20.403052301999899],
            [-42.893264668, -20.403390841],
            [-42.893481377999898, -20.4036088309999],
            [-42.893510499999898, -20.403689331],
            [-42.893526295, -20.403839127],
            [-42.893513571, -20.404024733],
            [-42.893386479, -20.404349351999901],
            [-42.89332179, -20.404423729],
            [-42.893227822, -20.404507816],
            [-42.893248458999899, -20.4046185],
            [-42.893279306999901, -20.404756327]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 102,
        "id": 37013,
        "CD_SETOR": "315210505070037P",
        "AREA_KM2": 0.116057,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 211
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.891511779, -20.403095258999901],
            [-42.891484799999901, -20.4028683569999],
            [-42.891591848, -20.402478028],
            [-42.891795500999898, -20.4022108509999],
            [-42.893209484, -20.400944747],
            [-42.892954480999897, -20.400725135],
            [-42.892584384, -20.400251214],
            [-42.892363066, -20.400007495],
            [-42.892122097, -20.399782742],
            [-42.8920315989999, -20.39964731],
            [-42.891904116, -20.39945653],
            [-42.891801148999903, -20.399356860999902],
            [-42.891216965, -20.398862922],
            [-42.890792741, -20.398595008],
            [-42.890166641, -20.398566283],
            [-42.889797461999898, -20.398569494],
            [-42.889064323999897, -20.398591462999899],
            [-42.888840504, -20.398731351],
            [-42.8884712, -20.399151015],
            [-42.888299371, -20.399554579],
            [-42.888426435, -20.399642021],
            [-42.888616683, -20.399826673],
            [-42.888840504, -20.399955369999901],
            [-42.8893832689999, -20.400100853],
            [-42.889573516, -20.400184786],
            [-42.8902337869999, -20.400889821],
            [-42.890407248, -20.4010464959999],
            [-42.890659045999897, -20.401208765],
            [-42.890664641999898, -20.401303889],
            [-42.890591899999897, -20.401359844],
            [-42.890149854, -20.4013654399999],
            [-42.889937225, -20.4014381819999],
            [-42.8897972359999, -20.4015793629999],
            [-42.890389507999899, -20.401814537],
            [-42.890615547, -20.4019071],
            [-42.8908186849999, -20.402000116],
            [-42.891005918, -20.402217925999899],
            [-42.891303201, -20.402647901],
            [-42.891412612, -20.402897832],
            [-42.891511779, -20.403095258999901]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 103,
        "id": 37014,
        "CD_SETOR": "315210505070039P",
        "AREA_KM2": 0.026918,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 257
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.885777775999898, -20.400338437],
            [-42.886142556, -20.4006416],
            [-42.886726313999901, -20.401293039999899],
            [-42.886903131999901, -20.401476729],
            [-42.887010899, -20.401582934],
            [-42.887252326, -20.40170912],
            [-42.88744709, -20.401765704],
            [-42.887573174, -20.401783630999901],
            [-42.888495565, -20.401920819999901],
            [-42.888916735, -20.401980056],
            [-42.889200112, -20.402019912],
            [-42.889476048999903, -20.4020664449999],
            [-42.889691319999898, -20.4021418039999],
            [-42.890086992, -20.402328420999901],
            [-42.890254262, -20.402433139],
            [-42.890566928999903, -20.402628881999899],
            [-42.8907830919999, -20.402824113999898],
            [-42.890900723999899, -20.402959375],
            [-42.891126041999897, -20.403251269],
            [-42.891261533, -20.4034335929999],
            [-42.891386455, -20.403622879],
            [-42.891439336, -20.403661655999901],
            [-42.89158572, -20.403802131],
            [-42.891558183, -20.403728015999899],
            [-42.891538483, -20.40365593],
            [-42.891512513, -20.403446240999902],
            [-42.891416292, -20.40328533],
            [-42.891275547, -20.403055491],
            [-42.891128705, -20.402845614999901],
            [-42.891028948, -20.402677911],
            [-42.890903513, -20.402488763],
            [-42.89083606, -20.402379648],
            [-42.8907045179999, -20.402279813],
            [-42.890640511, -20.4022257],
            [-42.890479491999898, -20.402133510999899],
            [-42.890138776999898, -20.401967536],
            [-42.889951661999902, -20.401876386],
            [-42.889802704, -20.401836108999898],
            [-42.889681604, -20.401784926999898],
            [-42.889473956, -20.401695227999902],
            [-42.889435681999899, -20.401678516999901],
            [-42.88924144, -20.401656778],
            [-42.889091099, -20.401647871],
            [-42.888956838999903, -20.401610021],
            [-42.888830223, -20.401584218],
            [-42.888750317, -20.4015495589999],
            [-42.888696146, -20.4015164099999],
            [-42.888645282, -20.401503677999901],
            [-42.888624283999903, -20.401543649],
            [-42.888526167999899, -20.401547174],
            [-42.888506542999899, -20.40154301],
            [-42.888394506999902, -20.401525536],
            [-42.888329999, -20.401515387],
            [-42.888267114, -20.401532254],
            [-42.888223968, -20.40153221],
            [-42.888058196, -20.401529957],
            [-42.888025311, -20.401532006],
            [-42.887913624, -20.401481315],
            [-42.88782255, -20.401358711999901],
            [-42.887749797, -20.401330747],
            [-42.887642001, -20.40133205],
            [-42.887565439999896, -20.4013504979999],
            [-42.887516377999901, -20.401297074999899],
            [-42.88740088, -20.401137752],
            [-42.887320304999903, -20.401117165999899],
            [-42.887252562, -20.401069849],
            [-42.887034554, -20.400917572],
            [-42.886921452, -20.400892063],
            [-42.886824531999899, -20.4008963869999],
            [-42.886728119999901, -20.400845352],
            [-42.88659946, -20.40073303],
            [-42.88643295, -20.400683876],
            [-42.886409910999902, -20.400623701],
            [-42.886160007999898, -20.400441000999901],
            [-42.886050312999899, -20.40037682],
            [-42.885945106, -20.4003003249999],
            [-42.885900869999901, -20.400239633999899],
            [-42.885772372, -20.4001723],
            [-42.885777775999898, -20.400338437]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 104,
        "id": 37015,
        "CD_SETOR": "315210505070041P",
        "AREA_KM2": 0.07261,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 269
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.884990973999898, -20.401714539999901],
            [-42.885683904, -20.401998646],
            [-42.885815349, -20.402078719],
            [-42.887451029, -20.403406769],
            [-42.887466061999902, -20.403572771],
            [-42.888397626, -20.403123615],
            [-42.8885654649999, -20.4030368539999],
            [-42.888685037999899, -20.402980725],
            [-42.888812851, -20.402954795],
            [-42.888989358999901, -20.403025049],
            [-42.889190180999897, -20.403126581],
            [-42.889229973999903, -20.403146698999901],
            [-42.890319581, -20.403346273],
            [-42.890284881, -20.4032133699999],
            [-42.890163515, -20.4031046449999],
            [-42.890113382, -20.4029978],
            [-42.889697774999902, -20.4025173869999],
            [-42.889596447, -20.40240842],
            [-42.889550149999899, -20.402358632999899],
            [-42.889521429, -20.402329879],
            [-42.8892609339999, -20.402078768],
            [-42.889200112, -20.402019912],
            [-42.888916735, -20.401980056],
            [-42.888495565, -20.401920819999901],
            [-42.887573174, -20.401783630999901],
            [-42.88744709, -20.401765704],
            [-42.887252326, -20.40170912],
            [-42.887010899, -20.401582934],
            [-42.886903131999901, -20.401476729],
            [-42.886726313999901, -20.401293039999899],
            [-42.886142556, -20.4006416],
            [-42.885777775999898, -20.400338437],
            [-42.885762439, -20.4004194309999],
            [-42.885741618999901, -20.400511461],
            [-42.885598721999898, -20.400771149],
            [-42.885374601, -20.401113219],
            [-42.884990973999898, -20.401714539999901]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 105,
        "id": 37016,
        "CD_SETOR": "315210505070042P",
        "AREA_KM2": 0.059459,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 36
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.889550149999899, -20.402358632999899],
            [-42.889622143999901, -20.402376221999901],
            [-42.889721210999902, -20.4024004249999],
            [-42.8899283229999, -20.402472148],
            [-42.890105411, -20.402574168999902],
            [-42.890229261999899, -20.402657916],
            [-42.890380875999902, -20.402762002],
            [-42.890558862, -20.402929146],
            [-42.890732075, -20.403132662],
            [-42.890808788999898, -20.403330684],
            [-42.890888317, -20.403677643],
            [-42.89086981, -20.4038965579999],
            [-42.890814269, -20.404023977],
            [-42.890744156, -20.404312735],
            [-42.890490752999902, -20.405356367],
            [-42.888774235, -20.405573920999899],
            [-42.888840463, -20.406139884],
            [-42.888871298, -20.406131361999901],
            [-42.889691569, -20.406038983],
            [-42.890506784, -20.405949705],
            [-42.891048511, -20.405882376],
            [-42.891166787, -20.405867599],
            [-42.891184116, -20.405929505],
            [-42.891240911999901, -20.406000730999899],
            [-42.891323031999903, -20.406029905],
            [-42.891401119, -20.406025972],
            [-42.89147503, -20.405999349],
            [-42.891507046999898, -20.405970296],
            [-42.8918079439999, -20.406293525],
            [-42.8919162669999, -20.406409886999899],
            [-42.892436023, -20.405941694],
            [-42.892869417, -20.405576611999901],
            [-42.89240806, -20.405147298],
            [-42.892374525, -20.405119419],
            [-42.89231116, -20.405066740999899],
            [-42.892235137999897, -20.4050035399999],
            [-42.892160705, -20.404941661999899],
            [-42.892116932, -20.404905271],
            [-42.892039688999901, -20.404800177],
            [-42.891853569, -20.404638813],
            [-42.891731272, -20.404532781999901],
            [-42.891674871, -20.40443533],
            [-42.891658502, -20.404288279],
            [-42.89161336, -20.403924582999899],
            [-42.891599863, -20.403815704],
            [-42.89158572, -20.403802131],
            [-42.891439336, -20.403661655999901],
            [-42.891386455, -20.403622879],
            [-42.891261533, -20.4034335929999],
            [-42.891126041999897, -20.403251269],
            [-42.890900723999899, -20.402959375],
            [-42.8907830919999, -20.402824113999898],
            [-42.890566928999903, -20.402628881999899],
            [-42.890254262, -20.402433139],
            [-42.890086992, -20.402328420999901],
            [-42.889691319999898, -20.4021418039999],
            [-42.889476048999903, -20.4020664449999],
            [-42.889200112, -20.402019912],
            [-42.8892609339999, -20.402078768],
            [-42.889521429, -20.402329879],
            [-42.889550149999899, -20.402358632999899]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 106,
        "id": 37017,
        "CD_SETOR": "315210505070043P",
        "AREA_KM2": 0.116376,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 343
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.885181851, -20.405872753],
            [-42.885238836, -20.405894187999898],
            [-42.885353941999902, -20.405936588],
            [-42.885546388, -20.406034357],
            [-42.885968835, -20.406058948999899],
            [-42.886092776999902, -20.406070131],
            [-42.886177811, -20.406076911],
            [-42.886258783999899, -20.406083871],
            [-42.886326149999903, -20.406089661],
            [-42.886395326, -20.40616625],
            [-42.886480972, -20.406251453],
            [-42.88662721, -20.406350999],
            [-42.8867287469999, -20.406379326],
            [-42.886856609999903, -20.406387590999898],
            [-42.8872121429999, -20.406347531999899],
            [-42.887953841, -20.406252949],
            [-42.888840463, -20.406139884],
            [-42.888774235, -20.405573920999899],
            [-42.890490752999902, -20.405356367],
            [-42.890744156, -20.404312735],
            [-42.890814269, -20.404023977],
            [-42.89086981, -20.4038965579999],
            [-42.890888317, -20.403677643],
            [-42.890808788999898, -20.403330684],
            [-42.890732075, -20.403132662],
            [-42.890558862, -20.402929146],
            [-42.890380875999902, -20.402762002],
            [-42.890229261999899, -20.402657916],
            [-42.890105411, -20.402574168999902],
            [-42.8899283229999, -20.402472148],
            [-42.889721210999902, -20.4024004249999],
            [-42.889622143999901, -20.402376221999901],
            [-42.889550149999899, -20.402358632999899],
            [-42.889610223, -20.402385400999901],
            [-42.889616416, -20.402388161],
            [-42.889744078, -20.40244956],
            [-42.889844636, -20.402512586999901],
            [-42.8901389769999, -20.40275214],
            [-42.890298238999897, -20.402945578999901],
            [-42.890356386, -20.403029473999901],
            [-42.890530568, -20.403368693999901],
            [-42.89055001, -20.403597024],
            [-42.890485882999897, -20.404033390999899],
            [-42.890429728999898, -20.40416593],
            [-42.890298495, -20.4043410049999],
            [-42.890174034, -20.4045653659999],
            [-42.890052198, -20.404809142],
            [-42.889969868, -20.404885751999899],
            [-42.889727342999898, -20.40505916],
            [-42.889339789, -20.405296939],
            [-42.889065719, -20.405419342999899],
            [-42.888843069, -20.405520271999901],
            [-42.888756262, -20.405552149999899],
            [-42.888666312999902, -20.405586166],
            [-42.888078729, -20.405652835],
            [-42.8869977109999, -20.4057675839999],
            [-42.887010781999898, -20.4057031749999],
            [-42.887204343999898, -20.405547629999901],
            [-42.887491544999897, -20.405351582],
            [-42.887982873999903, -20.404972481999899],
            [-42.888021201, -20.404795419],
            [-42.887976901999899, -20.404586464],
            [-42.887906738, -20.404448262],
            [-42.887405709999904, -20.403714647999902],
            [-42.885631076, -20.402250699],
            [-42.885631477999901, -20.4022485909999],
            [-42.885549656, -20.402281319999901],
            [-42.885321002999902, -20.402475917999901],
            [-42.885170189, -20.4027629519999],
            [-42.8851273349999, -20.403161136999898],
            [-42.885155981, -20.403495366],
            [-42.885244722, -20.404147551999898],
            [-42.885245157, -20.405088665999902],
            [-42.885181851, -20.405872753]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 107,
        "id": 37018,
        "CD_SETOR": "315210505070044P",
        "AREA_KM2": 0.05788,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 265
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.888871298, -20.406131361999901],
            [-42.889209960999899, -20.406470082999899],
            [-42.890275469, -20.40758621],
            [-42.891114559999899, -20.408440281],
            [-42.892136805999897, -20.407618188],
            [-42.8926480229999, -20.407162752],
            [-42.892334696, -20.406852962],
            [-42.8919162669999, -20.406409886999899],
            [-42.8918079439999, -20.406293525],
            [-42.891507046999898, -20.405970296],
            [-42.89147503, -20.405999349],
            [-42.891401119, -20.406025972],
            [-42.891323031999903, -20.406029905],
            [-42.891240911999901, -20.406000730999899],
            [-42.891184116, -20.405929505],
            [-42.891166787, -20.405867599],
            [-42.891048511, -20.405882376],
            [-42.890506784, -20.405949705],
            [-42.889691569, -20.406038983],
            [-42.888871298, -20.406131361999901]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 108,
        "id": 37019,
        "CD_SETOR": "315210505070045P",
        "AREA_KM2": 0.078579,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 291
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.888840463, -20.406139884],
            [-42.887953841, -20.406252949],
            [-42.888030033999897, -20.406488785],
            [-42.888088836999898, -20.406684793999901],
            [-42.888137839, -20.4070719119999],
            [-42.888196642, -20.407091513],
            [-42.888216242999903, -20.407120915],
            [-42.888196642, -20.407174816999898],
            [-42.888074135999901, -20.407267921],
            [-42.887858525999903, -20.407478630999901],
            [-42.887770321999902, -20.40765994],
            [-42.887706619, -20.4077922459999],
            [-42.887716419999897, -20.407846148],
            [-42.887814423999899, -20.407855949],
            [-42.888353449, -20.4077922459999],
            [-42.8883485489999, -20.408047058],
            [-42.888157439999901, -20.4080715589999],
            [-42.888132938999902, -20.408434176],
            [-42.88815254, -20.408517479999901],
            [-42.888177040999899, -20.4087036879999],
            [-42.888137839, -20.408801692999901],
            [-42.888074135999901, -20.408870296],
            [-42.8882603449999, -20.408987902],
            [-42.888319147999901, -20.409090805999899],
            [-42.888304446999904, -20.4092623139999],
            [-42.888363249999898, -20.409355419],
            [-42.888324048, -20.409463224],
            [-42.8883197, -20.409469631],
            [-42.888482318999898, -20.409525496999901],
            [-42.888889193, -20.409636032],
            [-42.889189584999897, -20.409675326999899],
            [-42.889422849, -20.409737902],
            [-42.889581393, -20.409952949999902],
            [-42.889676763999901, -20.410018054],
            [-42.889848285, -20.40998445],
            [-42.889933754, -20.410007360999899],
            [-42.890036133, -20.409859372],
            [-42.890228534, -20.409612579999902],
            [-42.890289083, -20.4096396539999],
            [-42.890333418, -20.4096365189999],
            [-42.890376922, -20.409633442],
            [-42.890448493, -20.409604277],
            [-42.890524473, -20.409533693999901],
            [-42.890547328, -20.409493234],
            [-42.890569023, -20.409454828],
            [-42.890581622999903, -20.409407792],
            [-42.890568281, -20.409363412999902],
            [-42.890559397999901, -20.409333865999901],
            [-42.890535381999896, -20.409300115999901],
            [-42.890916546, -20.409005229999899],
            [-42.890400942, -20.408381246],
            [-42.890358789999901, -20.408368423999899],
            [-42.890026302, -20.408341556],
            [-42.889758448, -20.4083265679999],
            [-42.889535623, -20.4082809619999],
            [-42.88948368, -20.408240189],
            [-42.890275469, -20.40758621],
            [-42.889209960999899, -20.406470082999899],
            [-42.888871298, -20.406131361999901],
            [-42.888840463, -20.406139884]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 109,
        "id": 37020,
        "CD_SETOR": "315210505070046P",
        "AREA_KM2": 0.049189,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 283
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.886727244, -20.408857795],
            [-42.887007852999901, -20.4088170869999],
            [-42.887443699, -20.4089067429999],
            [-42.887974544, -20.409075672],
            [-42.888317999, -20.409469047],
            [-42.8883197, -20.409469631],
            [-42.888324048, -20.409463224],
            [-42.888363249999898, -20.409355419],
            [-42.888304446999904, -20.4092623139999],
            [-42.888319147999901, -20.409090805999899],
            [-42.8882603449999, -20.408987902],
            [-42.888074135999901, -20.408870296],
            [-42.888137839, -20.408801692999901],
            [-42.888177040999899, -20.4087036879999],
            [-42.88815254, -20.408517479999901],
            [-42.888132938999902, -20.408434176],
            [-42.888157439999901, -20.4080715589999],
            [-42.8883485489999, -20.408047058],
            [-42.888353449, -20.4077922459999],
            [-42.887814423999899, -20.407855949],
            [-42.887716419999897, -20.407846148],
            [-42.887706619, -20.4077922459999],
            [-42.887770321999902, -20.40765994],
            [-42.887858525999903, -20.407478630999901],
            [-42.888074135999901, -20.407267921],
            [-42.888196642, -20.407174816999898],
            [-42.888216242999903, -20.407120915],
            [-42.888196642, -20.407091513],
            [-42.888137839, -20.4070719119999],
            [-42.888088836999898, -20.406684793999901],
            [-42.888030033999897, -20.406488785],
            [-42.887953841, -20.406252949],
            [-42.8872121429999, -20.406347531999899],
            [-42.886856609999903, -20.406387590999898],
            [-42.8867287469999, -20.406379326],
            [-42.88662721, -20.406350999],
            [-42.886480972, -20.406251453],
            [-42.886395326, -20.40616625],
            [-42.886326149999903, -20.406089661],
            [-42.886258783999899, -20.406083871],
            [-42.886261191, -20.406108447],
            [-42.88626504, -20.40615206],
            [-42.886280169, -20.40632348],
            [-42.886335073999902, -20.40671772],
            [-42.886356528, -20.406847424],
            [-42.886376653, -20.40713378],
            [-42.886477907, -20.407623079],
            [-42.886662911, -20.408457898999899],
            [-42.886727244, -20.408857795]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 110,
        "id": 37021,
        "CD_SETOR": "315210505070047P",
        "AREA_KM2": 0.070553,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 336
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.883616772, -20.413227569],
            [-42.884411567, -20.412820696],
            [-42.884408315, -20.412740281],
            [-42.884415555, -20.4127205519999],
            [-42.884420395999904, -20.412703243],
            [-42.884542349, -20.412733327999899],
            [-42.884662767, -20.412755791999899],
            [-42.884717948999899, -20.412617081],
            [-42.884793959, -20.412524373999901],
            [-42.884950484999898, -20.412411915],
            [-42.885108106, -20.4123060649999],
            [-42.885286365, -20.412191069],
            [-42.885391388999899, -20.412143903999901],
            [-42.885499683, -20.412111911],
            [-42.885647466, -20.41208066],
            [-42.885787674, -20.41206498],
            [-42.885914627, -20.412030898999902],
            [-42.886109667, -20.411971899],
            [-42.886371932, -20.411830799],
            [-42.886553795999902, -20.411769186999901],
            [-42.886738294999901, -20.411724938],
            [-42.886809935, -20.411707583999899],
            [-42.886879208, -20.411694606],
            [-42.887030760999899, -20.411666215],
            [-42.887194572999903, -20.411655176],
            [-42.887502675, -20.411649715],
            [-42.887628651999897, -20.411654373],
            [-42.887725701, -20.411666351],
            [-42.887790782, -20.411674382999902],
            [-42.887867061999899, -20.411709044],
            [-42.887955173, -20.411748278999902],
            [-42.888040797, -20.4117820939999],
            [-42.888233134, -20.411846211999901],
            [-42.8881274559999, -20.412185398],
            [-42.888030990999901, -20.412607496],
            [-42.888027499, -20.412673454999901],
            [-42.888094908, -20.412669041],
            [-42.8882471209999, -20.412614408],
            [-42.888459135999902, -20.412519873999901],
            [-42.888584293999898, -20.4124372859999],
            [-42.8886635389999, -20.412384791],
            [-42.888694976999901, -20.412338002999899],
            [-42.888786973, -20.412201086],
            [-42.888948969, -20.412094214],
            [-42.889107783, -20.412049237],
            [-42.889270527999898, -20.412005861999901],
            [-42.889464705, -20.411976544],
            [-42.889628344999899, -20.411971104],
            [-42.889805700999901, -20.411971088],
            [-42.889943956, -20.411994503],
            [-42.890285537, -20.412106079999901],
            [-42.890420078, -20.412129249],
            [-42.890509033999898, -20.4121500319999],
            [-42.890561035, -20.4120620539999],
            [-42.890613141, -20.411996647],
            [-42.890670493, -20.41187124],
            [-42.890738792, -20.411812793],
            [-42.890778086999902, -20.411718456],
            [-42.890835831999901, -20.411659405],
            [-42.890859551999903, -20.411609358],
            [-42.890857681999897, -20.411526661],
            [-42.890918009, -20.411447762],
            [-42.89082611, -20.411341514999901],
            [-42.890768411, -20.411216424],
            [-42.890687431999901, -20.411122065],
            [-42.890571972, -20.411048793],
            [-42.890510433, -20.411068117],
            [-42.890410588, -20.410984115],
            [-42.890313590999902, -20.410965005],
            [-42.890205422, -20.410981003],
            [-42.8900953039999, -20.411019957999901],
            [-42.890003006, -20.411091036],
            [-42.889936007, -20.411110218],
            [-42.88987049, -20.411136765],
            [-42.889785398999898, -20.411158793],
            [-42.889708255, -20.411160326999902],
            [-42.889597185, -20.411176186],
            [-42.889537349, -20.411173638],
            [-42.889427573, -20.411168962],
            [-42.889240163, -20.411093310999899],
            [-42.88910952, -20.411106446],
            [-42.888943094999902, -20.411131059],
            [-42.888783705, -20.411074119],
            [-42.888698594999902, -20.4110964609999],
            [-42.88860299, -20.411121556999898],
            [-42.888432135, -20.411133008],
            [-42.888316933999903, -20.411085398],
            [-42.888234661, -20.411044166999901],
            [-42.888151239, -20.410994887999902],
            [-42.888082641, -20.410990526],
            [-42.888002292, -20.410977618999901],
            [-42.887940498, -20.4109779949999],
            [-42.887907049, -20.4110214909999],
            [-42.88784097, -20.4110416239999],
            [-42.887813743, -20.411027697],
            [-42.887762344, -20.4110125619999],
            [-42.887612749, -20.410969033],
            [-42.887473815, -20.410957494999899],
            [-42.887327011, -20.410939566],
            [-42.887233458, -20.410928718],
            [-42.887116831999897, -20.410867082],
            [-42.887007820999898, -20.410795879],
            [-42.886930994, -20.410798779999901],
            [-42.886845943, -20.410824868],
            [-42.886707502, -20.410862822999899],
            [-42.886632878999897, -20.410891276],
            [-42.886589708, -20.410896185],
            [-42.886505886999899, -20.410897722],
            [-42.886453888999903, -20.4108907409999],
            [-42.886381465999897, -20.410912083],
            [-42.886273640999903, -20.41099997],
            [-42.886188121, -20.411066462999901],
            [-42.886100061, -20.411133741999901],
            [-42.885980418, -20.411197248999901],
            [-42.885717176999897, -20.4113871449999],
            [-42.885648285999899, -20.41149825],
            [-42.885601779, -20.411664478],
            [-42.885472405, -20.411751618],
            [-42.885303214, -20.4118041209999],
            [-42.885210484, -20.411854387999899],
            [-42.885109660999902, -20.41193874],
            [-42.885011096, -20.411913491999901],
            [-42.8848593069999, -20.411906309],
            [-42.884734663, -20.411959877999902],
            [-42.884659053999897, -20.412028511],
            [-42.884591090999898, -20.412118742],
            [-42.884559441, -20.4121775589999],
            [-42.884575173, -20.412221643],
            [-42.884243887, -20.41223364],
            [-42.884045197, -20.412216357],
            [-42.883882168999897, -20.4121934],
            [-42.883726184999901, -20.412270077999899],
            [-42.883549242, -20.412364942999901],
            [-42.883378411, -20.412616974999899],
            [-42.883616772, -20.413227569]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 111,
        "id": 37022,
        "CD_SETOR": "315210505070048P",
        "AREA_KM2": 0.023893,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 308
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.884411567, -20.412820696],
            [-42.884421328, -20.41296482],
            [-42.8844536249999, -20.413024054999902],
            [-42.884507028, -20.413099545],
            [-42.88453221, -20.413140056],
            [-42.884570889, -20.41320228],
            [-42.884722296999897, -20.4133423329999],
            [-42.884780396999901, -20.413443437],
            [-42.884815848999899, -20.413472741],
            [-42.884884016999898, -20.413528696999901],
            [-42.884941458, -20.413574391999902],
            [-42.885021383, -20.413642223],
            [-42.88509995, -20.413695504],
            [-42.885182404, -20.4137350079999],
            [-42.885304832, -20.413763284],
            [-42.885371994, -20.4137693079999],
            [-42.885420022999902, -20.413759571999901],
            [-42.885383765, -20.413666206999899],
            [-42.885302617, -20.4135529059999],
            [-42.885228966999897, -20.413483788],
            [-42.885110502, -20.413355367999898],
            [-42.885005604, -20.4132294569999],
            [-42.884863706, -20.413038184],
            [-42.884882768999901, -20.4128984389999],
            [-42.884915292, -20.4128208499999],
            [-42.884961892, -20.412743760999899],
            [-42.885036424, -20.4126756759999],
            [-42.885294667, -20.412581244],
            [-42.885349931, -20.412547681],
            [-42.885613608, -20.412478525],
            [-42.885966964, -20.412406646],
            [-42.886457305, -20.412337181999899],
            [-42.886952803, -20.412226295],
            [-42.887576368999902, -20.412076327],
            [-42.8876348699999, -20.412073644],
            [-42.887687183, -20.412090576],
            [-42.88773529, -20.412139524999901],
            [-42.887744886999897, -20.412212684999901],
            [-42.887763011999901, -20.412324853],
            [-42.887726223999898, -20.4124383609999],
            [-42.887814485, -20.412610335],
            [-42.888027499, -20.412673454999901],
            [-42.888030990999901, -20.412607496],
            [-42.8881274559999, -20.412185398],
            [-42.888233134, -20.411846211999901],
            [-42.888040797, -20.4117820939999],
            [-42.887955173, -20.411748278999902],
            [-42.887867061999899, -20.411709044],
            [-42.887790782, -20.411674382999902],
            [-42.887725701, -20.411666351],
            [-42.887628651999897, -20.411654373],
            [-42.887502675, -20.411649715],
            [-42.887194572999903, -20.411655176],
            [-42.887030760999899, -20.411666215],
            [-42.886879208, -20.411694606],
            [-42.886809935, -20.411707583999899],
            [-42.886738294999901, -20.411724938],
            [-42.886553795999902, -20.411769186999901],
            [-42.886371932, -20.411830799],
            [-42.886109667, -20.411971899],
            [-42.885914627, -20.412030898999902],
            [-42.885787674, -20.41206498],
            [-42.885647466, -20.41208066],
            [-42.885499683, -20.412111911],
            [-42.885391388999899, -20.412143903999901],
            [-42.885286365, -20.412191069],
            [-42.885108106, -20.4123060649999],
            [-42.884950484999898, -20.412411915],
            [-42.884793959, -20.412524373999901],
            [-42.884717948999899, -20.412617081],
            [-42.884662767, -20.412755791999899],
            [-42.884542349, -20.412733327999899],
            [-42.884420395999904, -20.412703243],
            [-42.884415555, -20.4127205519999],
            [-42.884408315, -20.412740281],
            [-42.884411567, -20.412820696]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 112,
        "id": 37023,
        "CD_SETOR": "315210505070050P",
        "AREA_KM2": 0.032416,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 124
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.887956478, -20.413664339],
            [-42.888047307, -20.413784690999901],
            [-42.888087927, -20.413829533],
            [-42.888245158, -20.413955354999899],
            [-42.888419206, -20.414048305999899],
            [-42.888510240999899, -20.414199642],
            [-42.888572956, -20.414303898999901],
            [-42.888640814, -20.414412847999898],
            [-42.888964154, -20.414878207],
            [-42.889053256, -20.414999796],
            [-42.889126571, -20.415109507],
            [-42.889168175, -20.415171765],
            [-42.889372023, -20.415047088999899],
            [-42.889537552, -20.414945847999899],
            [-42.889671738999901, -20.415105938999901],
            [-42.889804206, -20.415169315999901],
            [-42.889871715, -20.415216375999901],
            [-42.890030635, -20.415299386],
            [-42.890665578, -20.41560959],
            [-42.890866162999899, -20.415646594],
            [-42.890987971, -20.415669065],
            [-42.891594354999903, -20.415776165999901],
            [-42.891715470999898, -20.415777638],
            [-42.892014831, -20.415781277999901],
            [-42.892085703, -20.4157673939999],
            [-42.892252598, -20.4157347],
            [-42.892015978, -20.415630276999899],
            [-42.891932854999901, -20.415617938],
            [-42.891699914999897, -20.415594257999899],
            [-42.891391595999899, -20.415521757],
            [-42.8912921639999, -20.415498376999899],
            [-42.891129276999898, -20.415381281999899],
            [-42.89110284, -20.415338905999899],
            [-42.891057745999902, -20.415263235999898],
            [-42.890948997, -20.41512875],
            [-42.890846156999899, -20.415083970999898],
            [-42.890750984, -20.415052538999898],
            [-42.890610061, -20.415018276999898],
            [-42.890590406999898, -20.414994094999901],
            [-42.890525273, -20.414913955],
            [-42.890374456, -20.414767314],
            [-42.890230399, -20.414640593999898],
            [-42.8899323219999, -20.414368904],
            [-42.889743528999901, -20.414197133999899],
            [-42.889354193, -20.413841949999899],
            [-42.889019974, -20.413534887],
            [-42.888870558999898, -20.413391048999902],
            [-42.888823072, -20.413352454999899],
            [-42.888757094, -20.4133496749999],
            [-42.888655465999904, -20.4133939079999],
            [-42.888524226, -20.413453262],
            [-42.888400353, -20.413510784],
            [-42.888306714999899, -20.413543525],
            [-42.888191707, -20.4135823619999],
            [-42.888087266999896, -20.413618759],
            [-42.887956478, -20.413664339]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 113,
        "id": 37024,
        "CD_SETOR": "315210505070051P",
        "AREA_KM2": 0.017122,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 233
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.8899323219999, -20.414368904],
            [-42.890230399, -20.414640593999898],
            [-42.890374456, -20.414767314],
            [-42.890525273, -20.414913955],
            [-42.890590406999898, -20.414994094999901],
            [-42.890610061, -20.415018276999898],
            [-42.890750984, -20.415052538999898],
            [-42.890846156999899, -20.415083970999898],
            [-42.890948997, -20.41512875],
            [-42.891057745999902, -20.415263235999898],
            [-42.89110284, -20.415338905999899],
            [-42.891129276999898, -20.415381281999899],
            [-42.8912921639999, -20.415498376999899],
            [-42.891391595999899, -20.415521757],
            [-42.891699914999897, -20.415594257999899],
            [-42.891932854999901, -20.415617938],
            [-42.892015978, -20.415630276999899],
            [-42.892111198, -20.415607221],
            [-42.892181433, -20.415562459999901],
            [-42.892408583, -20.415565821],
            [-42.892481873, -20.415582136],
            [-42.892646785999901, -20.4155774399999],
            [-42.892856477999899, -20.415543328999899],
            [-42.893020624, -20.415503424999901],
            [-42.893056464, -20.415483843999901],
            [-42.89311973, -20.4154338939999],
            [-42.893234816, -20.41534962],
            [-42.893350891, -20.4152385629999],
            [-42.893420992, -20.415160111],
            [-42.893541961, -20.415023326],
            [-42.893631803999902, -20.414964300999898],
            [-42.893673892, -20.414909974999901],
            [-42.893876882, -20.414755699],
            [-42.894005365999902, -20.414613335],
            [-42.893925483, -20.41462],
            [-42.893796191, -20.414699126],
            [-42.893630791, -20.41480035],
            [-42.893414612, -20.414941105],
            [-42.892970071, -20.415211848],
            [-42.892814041999898, -20.415291001],
            [-42.892751628, -20.415301570999901],
            [-42.89257427, -20.415301972999899],
            [-42.892348840999901, -20.415350739],
            [-42.892147264999899, -20.41533917],
            [-42.892057049999899, -20.415321935],
            [-42.892008618, -20.415319488],
            [-42.8915376859999, -20.4152805579999],
            [-42.891430124, -20.415230833],
            [-42.891341268, -20.415123650999899],
            [-42.891261837999899, -20.415010512],
            [-42.891222844, -20.4149127929999],
            [-42.891187128999903, -20.414776785],
            [-42.8911284389999, -20.414696739],
            [-42.890872077, -20.414449846],
            [-42.890652802, -20.414247073999899],
            [-42.890561271, -20.414155863],
            [-42.890471625, -20.4140665319999],
            [-42.890427372, -20.4140221329999],
            [-42.890394797999903, -20.413989973],
            [-42.890365588999899, -20.414013906],
            [-42.890310164, -20.4140593179999],
            [-42.890241046, -20.4141159499999],
            [-42.890124852, -20.414211153999901],
            [-42.89004934, -20.4142730249999],
            [-42.8899323219999, -20.414368904]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 114,
        "id": 37025,
        "CD_SETOR": "315210505070052P",
        "AREA_KM2": 0.020491,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 343
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.889019974, -20.413534887],
            [-42.889354193, -20.413841949999899],
            [-42.889743528999901, -20.414197133999899],
            [-42.8899323219999, -20.414368904],
            [-42.89004934, -20.4142730249999],
            [-42.890124852, -20.414211153999901],
            [-42.890241046, -20.4141159499999],
            [-42.890310164, -20.4140593179999],
            [-42.890365588999899, -20.414013906],
            [-42.890394797999903, -20.413989973],
            [-42.891012641, -20.413491436999902],
            [-42.890539213, -20.413037421],
            [-42.890217064, -20.4126953419999],
            [-42.890095314, -20.4125825949999],
            [-42.889994226999903, -20.412670374],
            [-42.889842997, -20.412801693999899],
            [-42.889019974, -20.413534887]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 115,
        "id": 37026,
        "CD_SETOR": "315210505070053P",
        "AREA_KM2": 0.043833,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 126
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.891012641, -20.413491436999902],
            [-42.890394797999903, -20.413989973],
            [-42.890427372, -20.4140221329999],
            [-42.890471625, -20.4140665319999],
            [-42.890561271, -20.414155863],
            [-42.890652802, -20.414247073999899],
            [-42.890872077, -20.414449846],
            [-42.8911284389999, -20.414696739],
            [-42.891187128999903, -20.414776785],
            [-42.891222844, -20.4149127929999],
            [-42.891261837999899, -20.415010512],
            [-42.891341268, -20.415123650999899],
            [-42.891430124, -20.415230833],
            [-42.8915376859999, -20.4152805579999],
            [-42.892008618, -20.415319488],
            [-42.892057049999899, -20.415321935],
            [-42.892147264999899, -20.41533917],
            [-42.892348840999901, -20.415350739],
            [-42.89257427, -20.415301972999899],
            [-42.892751628, -20.415301570999901],
            [-42.892554468, -20.41510898],
            [-42.893776532999901, -20.413972722],
            [-42.893971969999903, -20.413809101],
            [-42.893945260999899, -20.413767006999901],
            [-42.893649992999897, -20.413483433],
            [-42.893196651, -20.413101431],
            [-42.892162407999898, -20.413971316],
            [-42.8917514299999, -20.414288154],
            [-42.891014707, -20.413493416999898],
            [-42.891012641, -20.413491436999902]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 116,
        "id": 37027,
        "CD_SETOR": "315210505070054P",
        "AREA_KM2": 0.021434,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 100
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.898812518999897, -20.414163226],
            [-42.89902691, -20.414489149],
            [-42.899108032, -20.414616726],
            [-42.89919214, -20.414748999],
            [-42.899307318, -20.414862925],
            [-42.899388721, -20.414943444],
            [-42.899533087, -20.415025795],
            [-42.899856824, -20.415158907],
            [-42.90051741, -20.415080072],
            [-42.900503635999897, -20.415012625],
            [-42.900469223999899, -20.4148571119999],
            [-42.900438638, -20.414673808],
            [-42.900385878999899, -20.414572344],
            [-42.899931723999899, -20.414325468],
            [-42.899700541999898, -20.41416179],
            [-42.899538756, -20.414065093],
            [-42.899314279, -20.413870546999899],
            [-42.899224419999904, -20.413782404],
            [-42.899201843, -20.413640947],
            [-42.899214711, -20.413562763999899],
            [-42.899251927, -20.413495659],
            [-42.899323231, -20.413443394],
            [-42.899404161999897, -20.413378628],
            [-42.8995072499999, -20.413295369],
            [-42.899772109, -20.413049228],
            [-42.899426006999903, -20.412347047],
            [-42.899209534, -20.413022183],
            [-42.899083769999898, -20.4131819149999],
            [-42.89875481, -20.4137203569999],
            [-42.898740286, -20.4137714559999],
            [-42.898741601, -20.413813728],
            [-42.898742833, -20.413853338],
            [-42.89875602, -20.413898377999899],
            [-42.898802717, -20.414002046],
            [-42.898812518999897, -20.414163226]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 117,
        "id": 37028,
        "CD_SETOR": "315210505070055P",
        "AREA_KM2": 0.12953,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 212
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.889329377, -20.418818008],
            [-42.889374743, -20.418882722],
            [-42.889466483, -20.419036605],
            [-42.890801884, -20.419667589],
            [-42.891669994, -20.420073212],
            [-42.892427611, -20.42051251],
            [-42.892937947999897, -20.421051743],
            [-42.893228996999902, -20.421446796999899],
            [-42.893654979, -20.421650107999898],
            [-42.893692334, -20.421615451],
            [-42.893782941, -20.421584724999899],
            [-42.893832199, -20.421589293],
            [-42.893952424, -20.4216004419999],
            [-42.894109429, -20.421660884999898],
            [-42.894181432, -20.421752657],
            [-42.894383279, -20.422023673],
            [-42.894468865, -20.422052123999901],
            [-42.894644415999899, -20.4222920459999],
            [-42.894641002999897, -20.42248867],
            [-42.894550357999897, -20.422725777],
            [-42.894710167, -20.42285405],
            [-42.89489902, -20.42310778],
            [-42.894870673, -20.42302918],
            [-42.894781542, -20.422763233],
            [-42.894793105, -20.4224587419999],
            [-42.8948200849999, -20.422181232],
            [-42.895151556, -20.422015496],
            [-42.8953442709999, -20.421811218],
            [-42.895533583, -20.4215559159999],
            [-42.895590635, -20.421418071999899],
            [-42.895608927, -20.421104495],
            [-42.895520809999901, -20.420680619999899],
            [-42.895681356, -20.420222882],
            [-42.895390068, -20.420016775],
            [-42.894611687, -20.419723202999901],
            [-42.89422175, -20.419679565],
            [-42.893977886, -20.419632786],
            [-42.891989124, -20.4186747689999],
            [-42.89110443, -20.418245452999901],
            [-42.890988218, -20.418225741],
            [-42.89084732, -20.418233347],
            [-42.890764665999903, -20.418221145],
            [-42.890616506999898, -20.418086461],
            [-42.890132542999901, -20.417674734999899],
            [-42.889998767999899, -20.417566076999901],
            [-42.889970478, -20.417529945999899],
            [-42.88990376, -20.417592552],
            [-42.889687591, -20.417722791],
            [-42.889585881, -20.4178186309999],
            [-42.889470758999899, -20.4179182],
            [-42.889357213, -20.418034602],
            [-42.889225686, -20.418063302],
            [-42.889282997999899, -20.418133429],
            [-42.889411998, -20.418270366999899],
            [-42.889496228, -20.418307152],
            [-42.889573649999903, -20.418350856],
            [-42.889611273999897, -20.418409172999901],
            [-42.889614998999903, -20.418495879],
            [-42.889593858, -20.41861166],
            [-42.889508473, -20.418781006],
            [-42.889329377, -20.418818008]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 118,
        "id": 37029,
        "CD_SETOR": "315210505070056P",
        "AREA_KM2": 0.15106,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 54
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.888653437, -20.419105256],
            [-42.888485971, -20.419342358999899],
            [-42.888631443, -20.4199179239999],
            [-42.888833839, -20.420322715999902],
            [-42.889251282, -20.420487163],
            [-42.889687699, -20.420771783],
            [-42.890124116, -20.421195549999901],
            [-42.890788228, -20.421859663],
            [-42.891638924999903, -20.422501639],
            [-42.89221449, -20.422862156999901],
            [-42.892783729, -20.4232100259999],
            [-42.89330237, -20.423387122],
            [-42.893612289, -20.423659092],
            [-42.893517416, -20.423842513999901],
            [-42.8932327959999, -20.424057559999898],
            [-42.894578248999899, -20.424376254],
            [-42.894621818, -20.424265800999901],
            [-42.894743439, -20.424046932],
            [-42.894770378, -20.423977991],
            [-42.894823674999898, -20.423851976],
            [-42.894873462, -20.423668404999901],
            [-42.894951219999903, -20.423487797],
            [-42.894992338999899, -20.423392653999901],
            [-42.894989704, -20.4233213639999],
            [-42.894951915, -20.423273706],
            [-42.894936037, -20.4232247049999],
            [-42.894923489999897, -20.42318598],
            [-42.8949120629999, -20.423146032],
            [-42.89489902, -20.42310778],
            [-42.894710167, -20.42285405],
            [-42.894550357999897, -20.422725777],
            [-42.894641002999897, -20.42248867],
            [-42.894644415999899, -20.4222920459999],
            [-42.894468865, -20.422052123999901],
            [-42.894383279, -20.422023673],
            [-42.894181432, -20.421752657],
            [-42.894109429, -20.421660884999898],
            [-42.893952424, -20.4216004419999],
            [-42.893832199, -20.421589293],
            [-42.893782941, -20.421584724999899],
            [-42.893692334, -20.421615451],
            [-42.893654979, -20.421650107999898],
            [-42.893228996999902, -20.421446796999899],
            [-42.892937947999897, -20.421051743],
            [-42.892427611, -20.42051251],
            [-42.891669994, -20.420073212],
            [-42.890801884, -20.419667589],
            [-42.889466483, -20.419036605],
            [-42.889374743, -20.418882722],
            [-42.889329377, -20.418818008],
            [-42.889135930999899, -20.4188664919999],
            [-42.888992307, -20.418909427],
            [-42.888802719999902, -20.419005159999902],
            [-42.888653437, -20.419105256]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 119,
        "id": 37030,
        "CD_SETOR": "315210505070057P",
        "AREA_KM2": 0.082787,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 18
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.893265058999901, -20.428539665999899],
            [-42.893270888, -20.428541203],
            [-42.893349231999899, -20.428546253],
            [-42.8935428039999, -20.428526325],
            [-42.893746155, -20.428437066],
            [-42.893776316, -20.428402747],
            [-42.893785328, -20.4282314189999],
            [-42.893805648999901, -20.428093153],
            [-42.893879884999897, -20.4278685469999],
            [-42.893902180999902, -20.427709707],
            [-42.893924888, -20.427515496999899],
            [-42.893919344, -20.427416702999899],
            [-42.893875297, -20.427178822],
            [-42.893890448, -20.427044211],
            [-42.893875787999903, -20.426986622999902],
            [-42.893835157999902, -20.426947191999901],
            [-42.893740223999899, -20.426882287],
            [-42.893660854, -20.4267937659999],
            [-42.893556985, -20.426670511999902],
            [-42.893531740999897, -20.426602589],
            [-42.893535484, -20.426553043999899],
            [-42.893546118, -20.426519718999899],
            [-42.89360878, -20.426393407],
            [-42.893672202999902, -20.426255157999901],
            [-42.893692383, -20.426211167],
            [-42.893792743999903, -20.425974593],
            [-42.893835134, -20.425837595],
            [-42.893884832, -20.425760065],
            [-42.893911269999897, -20.425726841],
            [-42.894056104999898, -20.4256556949999],
            [-42.894164267, -20.425606629999901],
            [-42.894237254, -20.425549348999901],
            [-42.894343189, -20.425412736999899],
            [-42.8944269959999, -20.425268355999901],
            [-42.894473005, -20.425150900999899],
            [-42.894495161, -20.425010491],
            [-42.894515124, -20.424884015],
            [-42.894523252999903, -20.4247611599999],
            [-42.894529356, -20.424655861],
            [-42.894528992999902, -20.4245572469999],
            [-42.894578248999899, -20.424376254],
            [-42.8932327959999, -20.424057559999898],
            [-42.893201172, -20.424411753999902],
            [-42.893346644, -20.424775434],
            [-42.89328972, -20.424901932],
            [-42.892922876999897, -20.424927232],
            [-42.892353637, -20.425167577],
            [-42.892094316999902, -20.425496471],
            [-42.8917464479999, -20.4260024619999],
            [-42.8916009759999, -20.426413578999899],
            [-42.891500863, -20.4267598],
            [-42.891411285999901, -20.427052187],
            [-42.891665960999902, -20.427083674],
            [-42.892008039, -20.427248683],
            [-42.892569227, -20.427722982],
            [-42.893224792, -20.428357368],
            [-42.893262249, -20.428527243],
            [-42.893265058999901, -20.428539665999899]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 120,
        "id": 37031,
        "CD_SETOR": "315210505070060P",
        "AREA_KM2": 0.008363,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 16
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.897947337999902, -20.412620424999901],
            [-42.898618231999897, -20.413732201],
            [-42.898848027, -20.413106974],
            [-42.898474767, -20.412209651999898],
            [-42.898023453999897, -20.412371919],
            [-42.897810153, -20.412462413],
            [-42.897947337999902, -20.412620424999901]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 121,
        "id": 37032,
        "CD_SETOR": "315210505070061P",
        "AREA_KM2": 4.442315,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 302
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.8992547779999, -20.3455079679999],
            [-42.89914764, -20.345857748],
            [-42.899113843999899, -20.345991985],
            [-42.89906386, -20.346156098999899],
            [-42.899066086999902, -20.346249529],
            [-42.89916597, -20.34664535],
            [-42.899244885, -20.3468215259999],
            [-42.89940417, -20.34714263],
            [-42.899549381, -20.347349414],
            [-42.900040073999897, -20.3480395569999],
            [-42.90036164, -20.348490023],
            [-42.900550334999899, -20.3488075879999],
            [-42.90064131, -20.349111416],
            [-42.900604087, -20.349466456],
            [-42.900483583, -20.349723404],
            [-42.900239902, -20.350014988999899],
            [-42.899944737999903, -20.350332742],
            [-42.899711249999903, -20.350696828],
            [-42.899596201999898, -20.351009765],
            [-42.899552383, -20.3512404589999],
            [-42.899557673999901, -20.351614642],
            [-42.899666822, -20.352236603999899],
            [-42.899790998, -20.3529277129999],
            [-42.899909943, -20.353596469],
            [-42.900013215999898, -20.354386711],
            [-42.900051006, -20.355319236],
            [-42.900069672, -20.355939442],
            [-42.900103337, -20.356988651999899],
            [-42.900109203999897, -20.357199206],
            [-42.900132425, -20.3577920819999],
            [-42.900243025999899, -20.358324529999901],
            [-42.900491807, -20.358747541],
            [-42.90093006, -20.359304774999899],
            [-42.901070013, -20.359516724999899],
            [-42.901205463999901, -20.359915116],
            [-42.901213399, -20.360445291],
            [-42.901119047999899, -20.361043066],
            [-42.9008981859999, -20.361451026999902],
            [-42.900602595999899, -20.361848831],
            [-42.900329109, -20.362206344999901],
            [-42.900064025999903, -20.362553815],
            [-42.899760949, -20.362952967999899],
            [-42.899437491999898, -20.363382353],
            [-42.899197562, -20.36370046],
            [-42.898657987, -20.364408521],
            [-42.898390535, -20.364724623],
            [-42.898087668999899, -20.364992788999899],
            [-42.897863535999903, -20.365168952],
            [-42.897541716, -20.365406633],
            [-42.897097045999899, -20.365735045],
            [-42.896551002, -20.366167885],
            [-42.896292452, -20.366455071],
            [-42.896134908, -20.366706581],
            [-42.896009684, -20.3669912679999],
            [-42.895924555, -20.367255314],
            [-42.895841855999898, -20.367552229999902],
            [-42.895905816, -20.367562946],
            [-42.895907982, -20.367563651],
            [-42.896274574, -20.367753788],
            [-42.896554233, -20.367004295],
            [-42.896993065999901, -20.366445665999901],
            [-42.900784288, -20.363550177],
            [-42.902418697, -20.362562719],
            [-42.903203585, -20.361991228],
            [-42.903829215, -20.3619502],
            [-42.904197279, -20.362143868],
            [-42.904629713, -20.362625328],
            [-42.905558129, -20.363196907],
            [-42.906802834, -20.363933502],
            [-42.907397521999897, -20.364358148],
            [-42.908425434, -20.36508363],
            [-42.908777646, -20.3651457889999],
            [-42.909084686, -20.365130553],
            [-42.910244498999901, -20.364385631999902],
            [-42.911465678, -20.363841573999899],
            [-42.912047545, -20.363776759],
            [-42.91243144, -20.363898314],
            [-42.912712095, -20.364051953999901],
            [-42.912747004, -20.364498925],
            [-42.912311019999898, -20.365454203999899],
            [-42.912240139999902, -20.366078085],
            [-42.912387029999898, -20.366475716],
            [-42.913120116999899, -20.366919969],
            [-42.913617405, -20.367093312],
            [-42.914148796999903, -20.367383340999901],
            [-42.914809702999897, -20.367569737999901],
            [-42.915576750999897, -20.367698199],
            [-42.916710441, -20.3675549989999],
            [-42.917225394, -20.367456589],
            [-42.917391458999901, -20.367261914],
            [-42.917401647, -20.3670494099999],
            [-42.917356992999899, -20.366687034999899],
            [-42.917276713, -20.365864619],
            [-42.917496324999902, -20.365652129],
            [-42.917729036, -20.365642337999901],
            [-42.918095169, -20.365887180999898],
            [-42.918423877999899, -20.365926588],
            [-42.918522247999903, -20.365968919999901],
            [-42.918687936, -20.366217719],
            [-42.918779838, -20.366186651],
            [-42.918934242, -20.365979839],
            [-42.919064909, -20.365789821],
            [-42.919187584, -20.365715895999902],
            [-42.9194854899999, -20.365666898],
            [-42.9200574669999, -20.365551089999901],
            [-42.920641023, -20.365503298],
            [-42.921491925999902, -20.365125163999899],
            [-42.921991986, -20.364926517999901],
            [-42.922131561, -20.364799306999899],
            [-42.922238933, -20.364615226999899],
            [-42.922223797999898, -20.364495146],
            [-42.922128308, -20.364235353],
            [-42.921928238, -20.363862519],
            [-42.921642753999897, -20.363263439],
            [-42.921484205999903, -20.362853522],
            [-42.921210295, -20.362488829],
            [-42.920823382, -20.362206344999901],
            [-42.9206484279999, -20.362161611],
            [-42.920436563, -20.362058986],
            [-42.920226177, -20.361819345],
            [-42.920145133999902, -20.361565040999899],
            [-42.920175247, -20.36129891],
            [-42.920237995, -20.361074258],
            [-42.920330855, -20.360692604999901],
            [-42.920481811999899, -20.360018743],
            [-42.920697828, -20.359391607],
            [-42.920863279999899, -20.359012169],
            [-42.921080914, -20.358606344],
            [-42.92114821, -20.358289692],
            [-42.921154913999899, -20.357962003999901],
            [-42.921136617999899, -20.3575287799999],
            [-42.921095505, -20.357325741],
            [-42.921048097, -20.357007719],
            [-42.921008244999904, -20.356965967],
            [-42.920770721999901, -20.356976322],
            [-42.920618578, -20.35689343],
            [-42.920462567999898, -20.356671560999899],
            [-42.920527975, -20.3561075759999],
            [-42.920633838999898, -20.355678222999899],
            [-42.920659429999901, -20.355346305999898],
            [-42.920735769, -20.35506787],
            [-42.920762543, -20.354759780999899],
            [-42.920815472999898, -20.354374908],
            [-42.920882741999897, -20.354053989],
            [-42.920923855, -20.353787855999901],
            [-42.921166, -20.352476996],
            [-42.921223502, -20.352210247999899],
            [-42.921336931, -20.351787303],
            [-42.921397457, -20.351408853],
            [-42.921357364, -20.350981544],
            [-42.920960086, -20.350616108999901],
            [-42.920452338, -20.35051606],
            [-42.919157028, -20.350023431],
            [-42.918839891999902, -20.349917886],
            [-42.916930618, -20.349056747],
            [-42.916648705999897, -20.348885502],
            [-42.916452202, -20.348760957],
            [-42.916145426999897, -20.3485907909999],
            [-42.915936362, -20.3484899749999],
            [-42.915641407, -20.348377121999899],
            [-42.91534558, -20.3482421319999],
            [-42.91507616, -20.348152717999898],
            [-42.914842174999897, -20.348050875999899],
            [-42.9146214209999, -20.347948815999899],
            [-42.914388685999903, -20.347824805999899],
            [-42.914082349999902, -20.347688677999901],
            [-42.913851039999898, -20.347527831],
            [-42.913597751999902, -20.347412484],
            [-42.913379878999898, -20.347319728999899],
            [-42.913116037999899, -20.347191549999899],
            [-42.912911823999899, -20.347064593],
            [-42.91267277, -20.346968777],
            [-42.91239747, -20.346794496],
            [-42.912161254999901, -20.34668599],
            [-42.911962581999902, -20.346554967],
            [-42.911741371999902, -20.346420042],
            [-42.91124417, -20.346206572],
            [-42.910980681999902, -20.346100774],
            [-42.910662871999897, -20.345920253],
            [-42.910380972, -20.345784389],
            [-42.910160168999901, -20.345659612],
            [-42.909927414, -20.345533415],
            [-42.909658729999897, -20.345464474],
            [-42.9094387069999, -20.3453607],
            [-42.909120973, -20.345233755],
            [-42.908829198, -20.345152603],
            [-42.908475725, -20.344945669],
            [-42.908229088, -20.344865034999899],
            [-42.908001075, -20.3447741589999],
            [-42.907725307, -20.344647342],
            [-42.907592903, -20.344574495],
            [-42.904786021999897, -20.344127608],
            [-42.903466464, -20.344386412999899],
            [-42.903115157999899, -20.344598545999901],
            [-42.902603629999902, -20.34483009],
            [-42.901780054, -20.34507009],
            [-42.901223946, -20.345334748],
            [-42.899814367, -20.345666129999898],
            [-42.899326776, -20.3455283169999],
            [-42.8992547779999, -20.3455079679999]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 122,
        "id": 37033,
        "CD_SETOR": "315210505070062P",
        "AREA_KM2": 24.007284,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 238
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.806801999999898, -20.317812995999901],
            [-42.806275, -20.318135996],
            [-42.805993999999899, -20.318181996],
            [-42.805763, -20.318238995999899],
            [-42.805507, -20.318352995999899],
            [-42.805250999999899, -20.318340996],
            [-42.80508, -20.318489996],
            [-42.804995, -20.318753995999899],
            [-42.804932999999899, -20.318971996],
            [-42.804871999999897, -20.319213996],
            [-42.804847, -20.319534996],
            [-42.804785, -20.3199029959999],
            [-42.804748, -20.320166996],
            [-42.804699, -20.320476996],
            [-42.804662, -20.320694995999901],
            [-42.804613, -20.320970996],
            [-42.804539, -20.321211995999899],
            [-42.804440999999898, -20.321452995999898],
            [-42.80427, -20.3217279959999],
            [-42.804122999999898, -20.322003996],
            [-42.804001, -20.322210996],
            [-42.803829999999898, -20.322427995999899],
            [-42.803647, -20.322634996],
            [-42.803513, -20.322818996],
            [-42.80333, -20.323001996],
            [-42.803134, -20.323242996],
            [-42.802999999999898, -20.323425996],
            [-42.802853, -20.323598995999902],
            [-42.802768, -20.323827996],
            [-42.802657, -20.324057996],
            [-42.80256, -20.324286996],
            [-42.802594999999897, -20.324608995999899],
            [-42.802594, -20.325458996],
            [-42.802641999999899, -20.325769996],
            [-42.80269, -20.326010996],
            [-42.802738, -20.326228995999902],
            [-42.802762, -20.326470996],
            [-42.802762, -20.3267459959999],
            [-42.802748999999899, -20.327033996],
            [-42.8027, -20.327285995999901],
            [-42.802661999999899, -20.327630996],
            [-42.802589, -20.327917995999901],
            [-42.802442, -20.328273996],
            [-42.802332, -20.328468996],
            [-42.802173, -20.328743996],
            [-42.802014, -20.328961996],
            [-42.801795, -20.329087995999899],
            [-42.801611, -20.329294995999899],
            [-42.801441, -20.329466996],
            [-42.801245, -20.329661996],
            [-42.801037999999899, -20.329890996],
            [-42.800891, -20.330062996],
            [-42.800732, -20.330234995999898],
            [-42.800548999999897, -20.330383996],
            [-42.800379, -20.330532995999899],
            [-42.800097999999899, -20.330589996],
            [-42.799891, -20.330738996],
            [-42.799708, -20.330876996],
            [-42.799512999999898, -20.331025995999902],
            [-42.799304999999897, -20.331185995999899],
            [-42.79911, -20.331323996],
            [-42.798927, -20.331472995999899],
            [-42.798683, -20.331655995999899],
            [-42.798244, -20.331999996],
            [-42.798048999999899, -20.332159996],
            [-42.797817, -20.332320996],
            [-42.797646, -20.332503996],
            [-42.797451, -20.332664995999899],
            [-42.79728, -20.3328249959999],
            [-42.797120999999898, -20.332996996],
            [-42.796975, -20.333168995999898],
            [-42.796827999999898, -20.3333639959999],
            [-42.796645, -20.333535996],
            [-42.796487, -20.333696996],
            [-42.796267, -20.333856996],
            [-42.796047, -20.334028996],
            [-42.795804, -20.334143996],
            [-42.795584, -20.334257996],
            [-42.795328, -20.334337995999899],
            [-42.795096999999899, -20.334394996],
            [-42.794816, -20.334462996],
            [-42.794585, -20.334519995999901],
            [-42.794328999999898, -20.334576996],
            [-42.794035999999899, -20.334633996],
            [-42.793756, -20.3346909959999],
            [-42.793378, -20.334758995999898],
            [-42.793086, -20.334815996],
            [-42.792806, -20.3348499959999],
            [-42.792513, -20.334883996],
            [-42.79227, -20.334940995999901],
            [-42.791977, -20.335020996],
            [-42.791721, -20.335088996],
            [-42.791452999999898, -20.335157996],
            [-42.791185, -20.335260995999899],
            [-42.790892, -20.335386996],
            [-42.790709, -20.335558996],
            [-42.790549999999897, -20.335787996],
            [-42.790355, -20.335925996],
            [-42.790075, -20.336016996],
            [-42.789843, -20.336130996],
            [-42.78966, -20.336371996],
            [-42.789561999999897, -20.336578996],
            [-42.789428, -20.336819996],
            [-42.78933, -20.337025996],
            [-42.789219, -20.3373249959999],
            [-42.789108999999897, -20.337519996],
            [-42.78895, -20.337852996],
            [-42.788790999999897, -20.338104996],
            [-42.788632999999898, -20.338288996],
            [-42.788425, -20.338437996],
            [-42.788218, -20.338563995999898],
            [-42.788046999999899, -20.338712996],
            [-42.787767, -20.338746995999902],
            [-42.786975, -20.3387449959999],
            [-42.786731, -20.338813995999899],
            [-42.78653, -20.338943996],
            [-42.786353, -20.339145996],
            [-42.786181999999897, -20.339352995999899],
            [-42.78606, -20.339558995999901],
            [-42.785974, -20.339811996],
            [-42.785888, -20.340029995999899],
            [-42.785815, -20.340247996],
            [-42.785851, -20.3405009959999],
            [-42.78585, -20.340776995999899],
            [-42.785825, -20.341005996],
            [-42.785825, -20.341235996],
            [-42.785836, -20.341453995999899],
            [-42.785897, -20.3416729959999],
            [-42.785994, -20.3418799959999],
            [-42.786018, -20.342155996],
            [-42.786017, -20.342407996],
            [-42.786041, -20.342626996],
            [-42.786053, -20.342844995999901],
            [-42.786088999999897, -20.343074995999899],
            [-42.786149, -20.343350996],
            [-42.786209, -20.343568995999899],
            [-42.786318, -20.343878995999901],
            [-42.786428, -20.344097996],
            [-42.786499999999897, -20.344327995999901],
            [-42.786646, -20.344545996],
            [-42.786791, -20.344787996],
            [-42.786814999999898, -20.345017996],
            [-42.7869, -20.345224996],
            [-42.787081999999899, -20.345454995999901],
            [-42.787228, -20.345661995999901],
            [-42.787397999999897, -20.345811996],
            [-42.787666, -20.3459959959999],
            [-42.787872999999898, -20.346099996],
            [-42.788116, -20.346214996],
            [-42.788322999999899, -20.346352996],
            [-42.788590999999897, -20.346502996],
            [-42.788846, -20.346606995999899],
            [-42.78909, -20.346687996],
            [-42.789333, -20.346745996],
            [-42.789589, -20.346826995999901],
            [-42.789808, -20.346907996],
            [-42.790027, -20.3470119959999],
            [-42.790185, -20.347172995999902],
            [-42.79027, -20.347413995999901],
            [-42.790391, -20.347632996],
            [-42.7905, -20.347908995999902],
            [-42.790706999999898, -20.348011996],
            [-42.790804, -20.348218996],
            [-42.790901, -20.348448995999899],
            [-42.791071, -20.348793995999898],
            [-42.791167, -20.349035995999898],
            [-42.791264, -20.349242995999901],
            [-42.791361, -20.349506995999899],
            [-42.791434, -20.349748996],
            [-42.791519, -20.349966996],
            [-42.791579, -20.350242996],
            [-42.791603, -20.3504959959999],
            [-42.791602, -20.350736996],
            [-42.791541, -20.351057996],
            [-42.791406, -20.351333995999902],
            [-42.791284, -20.351562995999899],
            [-42.791186, -20.351804995999899],
            [-42.791002999999897, -20.351999996],
            [-42.790807, -20.3521479959999],
            [-42.790515, -20.3520789959999],
            [-42.790258999999899, -20.352089996],
            [-42.790028, -20.352146995999899],
            [-42.789796, -20.352226996],
            [-42.78954, -20.352283996],
            [-42.789223, -20.352374996],
            [-42.788943, -20.352489996],
            [-42.788675, -20.352603996],
            [-42.788443, -20.352660995999901],
            [-42.78815, -20.352855996],
            [-42.787833, -20.353084996],
            [-42.787601, -20.353187996],
            [-42.787089, -20.353393996],
            [-42.78687, -20.353553996],
            [-42.786638, -20.353656995999899],
            [-42.78637, -20.353759996],
            [-42.786126, -20.353885996],
            [-42.785858, -20.354102995999899],
            [-42.785674, -20.354355996],
            [-42.785528, -20.354538996],
            [-42.785405, -20.354825995999899],
            [-42.785367999999899, -20.355067995999899],
            [-42.785355, -20.355285996],
            [-42.785355, -20.355584995999902],
            [-42.785379, -20.355871996],
            [-42.785427, -20.3561019959999],
            [-42.785438, -20.356319996],
            [-42.785462, -20.356537995999901],
            [-42.785485999999899, -20.356767996],
            [-42.785498, -20.356985995999899],
            [-42.785534, -20.357215996],
            [-42.785593999999897, -20.357457995999901],
            [-42.785666999999897, -20.357710996],
            [-42.785727, -20.357939996],
            [-42.785775, -20.358192996],
            [-42.785798999999898, -20.358445995999901],
            [-42.785811, -20.358663996],
            [-42.785749, -20.358927996],
            [-42.785737, -20.359157996],
            [-42.785772999999899, -20.359433996],
            [-42.785845, -20.359663995999899],
            [-42.785918, -20.359904996],
            [-42.785978, -20.360123996],
            [-42.786039, -20.360364995999898],
            [-42.786014, -20.360582995999899],
            [-42.785928, -20.360823996],
            [-42.785769, -20.3609959959999],
            [-42.785598, -20.361202996],
            [-42.785415, -20.361385996],
            [-42.78522, -20.361523996],
            [-42.785061, -20.361729996],
            [-42.784987999999899, -20.3619599959999],
            [-42.784926, -20.362200996],
            [-42.784877, -20.3624539959999],
            [-42.784875999999898, -20.362763996],
            [-42.784863, -20.363039995999898],
            [-42.784911, -20.363280996],
            [-42.785032999999899, -20.363476996],
            [-42.785093, -20.363705996],
            [-42.785154, -20.363924996],
            [-42.785287, -20.364211995999899],
            [-42.785445, -20.364373995999902],
            [-42.785615, -20.364580996],
            [-42.785798, -20.364718996],
            [-42.786003999999899, -20.364856996],
            [-42.786175, -20.365017995999899],
            [-42.786369, -20.3651569959999],
            [-42.786576, -20.365305996],
            [-42.786783, -20.365421996],
            [-42.787281999999898, -20.365479996],
            [-42.787514, -20.365399996],
            [-42.787757999999897, -20.365342996],
            [-42.788001, -20.365331996],
            [-42.788257, -20.365343996],
            [-42.788525, -20.365275996],
            [-42.788794, -20.365172996],
            [-42.789062, -20.365046995999901],
            [-42.789367, -20.364943996],
            [-42.789598, -20.364829996],
            [-42.789842, -20.364737996],
            [-42.790121999999897, -20.364680995999901],
            [-42.790658999999899, -20.364555996],
            [-42.790927, -20.3645449959999],
            [-42.791317, -20.364510996],
            [-42.791548, -20.3644999959999],
            [-42.791841, -20.364488996],
            [-42.792072, -20.364477995999898],
            [-42.794143, -20.364481995999899],
            [-42.794387, -20.364459995999901],
            [-42.794655, -20.364459995999901],
            [-42.794972, -20.364425996],
            [-42.795215, -20.364403996],
            [-42.795532, -20.3643239959999],
            [-42.795836999999899, -20.3642099959999],
            [-42.796142, -20.364071996],
            [-42.796569, -20.363716995999901],
            [-42.796763999999897, -20.3635569959999],
            [-42.796972, -20.363430996],
            [-42.797167, -20.363304996],
            [-42.797411, -20.363097996],
            [-42.797618, -20.362925995999898],
            [-42.797789, -20.362742996],
            [-42.7979, -20.362524995999902],
            [-42.798058, -20.3623639959999],
            [-42.798266, -20.362191996],
            [-42.798473, -20.362008995999901],
            [-42.798644, -20.361859996],
            [-42.798852, -20.361652996],
            [-42.799034999999897, -20.361469996],
            [-42.799206, -20.3612409959999],
            [-42.799377, -20.361079995999901],
            [-42.799584, -20.360930996],
            [-42.799779, -20.3607709959999],
            [-42.800011, -20.360724995999899],
            [-42.800193, -20.360885996],
            [-42.800680999999898, -20.360886996],
            [-42.800936, -20.3608989959999],
            [-42.801192, -20.361002996],
            [-42.801411, -20.361117996],
            [-42.80163, -20.3613029959999],
            [-42.801848999999898, -20.361451996],
            [-42.802056, -20.361613996],
            [-42.802311, -20.361831996],
            [-42.802542, -20.361981995999901],
            [-42.802724, -20.362165995999899],
            [-42.802979999999899, -20.362350996],
            [-42.803235, -20.3625229959999],
            [-42.803466, -20.362707995999902],
            [-42.803649, -20.362879995999901],
            [-42.803819, -20.363075996],
            [-42.803953, -20.363282996],
            [-42.804062, -20.363512995999901],
            [-42.804159, -20.363730996],
            [-42.804304, -20.364006995999901],
            [-42.804426, -20.364248996],
            [-42.804559, -20.364512996],
            [-42.804705, -20.364754995999899],
            [-42.804825999999899, -20.364984996],
            [-42.805008, -20.365237996],
            [-42.80513, -20.365432996],
            [-42.80518834, -20.365543789],
            [-42.8065687819999, -20.364679899],
            [-42.807287467, -20.364365673],
            [-42.807993551, -20.364197675],
            [-42.809747951, -20.363355806],
            [-42.808817422999901, -20.361602906],
            [-42.808175492, -20.360717163999901],
            [-42.807957163, -20.359809329],
            [-42.808014603999901, -20.358959345999899],
            [-42.808359713999899, -20.3577587189999],
            [-42.808719352, -20.357163015],
            [-42.809803680999899, -20.355384048],
            [-42.810091504, -20.355063855],
            [-42.810657867, -20.354751968999899],
            [-42.811299682, -20.354572382],
            [-42.811717379, -20.354108493],
            [-42.81232091, -20.353058801],
            [-42.813054238999896, -20.351892359],
            [-42.813454115, -20.351639121999899],
            [-42.813850223, -20.351549787],
            [-42.81467609, -20.351394048],
            [-42.814934648, -20.351331435],
            [-42.815169791999899, -20.351186073],
            [-42.815570036999901, -20.350825514999901],
            [-42.815928179, -20.350499468],
            [-42.816187703, -20.350389794],
            [-42.816464625, -20.350376871],
            [-42.816700093999899, -20.350437155999899],
            [-42.817123, -20.350660736999899],
            [-42.818093963, -20.351248578],
            [-42.819107244999898, -20.35153056],
            [-42.819314937999899, -20.351487072999898],
            [-42.819802122999903, -20.350523852],
            [-42.82016788, -20.349303420999899],
            [-42.820515317999899, -20.349129650999899],
            [-42.821311893, -20.348940183],
            [-42.823141882999899, -20.348960908],
            [-42.823698479, -20.349130927],
            [-42.82432741, -20.349544668],
            [-42.824992804, -20.350640195],
            [-42.82593887, -20.352320066999901],
            [-42.826382178, -20.352726274],
            [-42.826977526, -20.352905983],
            [-42.827449414999897, -20.352788561],
            [-42.827708485999899, -20.352549526],
            [-42.828043465999897, -20.352031617],
            [-42.828385056999899, -20.351794523],
            [-42.830762294, -20.351408955999901],
            [-42.832582123999899, -20.350728045],
            [-42.832909211, -20.350888244],
            [-42.833506371999903, -20.351765213],
            [-42.835156414, -20.353318112],
            [-42.83763802, -20.353574312],
            [-42.8378738, -20.3534406439999],
            [-42.838061022, -20.352136244999901],
            [-42.83863622, -20.350479053],
            [-42.838903617, -20.350139148],
            [-42.839138098, -20.350136532],
            [-42.839365135, -20.350215584999901],
            [-42.839686104, -20.3503799389999],
            [-42.83994137, -20.350450803999902],
            [-42.841354285, -20.350542063],
            [-42.8426643069999, -20.350682671999898],
            [-42.843844729999901, -20.350954816],
            [-42.84409727, -20.351022656999898],
            [-42.844272286, -20.35110341],
            [-42.844723828, -20.351797834],
            [-42.845293931999898, -20.352690987],
            [-42.845606451, -20.353192104],
            [-42.845974026, -20.353713134],
            [-42.846408154, -20.353635133],
            [-42.847052163999898, -20.353483997999898],
            [-42.847516266999897, -20.353426037],
            [-42.847974425, -20.353423714],
            [-42.848633909999897, -20.353421947],
            [-42.849430239999897, -20.353501815],
            [-42.849833918999899, -20.353653418],
            [-42.850463740999899, -20.354060186],
            [-42.850942942, -20.3542544539999],
            [-42.850852859, -20.35534574],
            [-42.850844902999903, -20.355742746],
            [-42.852450843, -20.3585887529999],
            [-42.852557567, -20.35832353],
            [-42.852726214, -20.358101161],
            [-42.853034828, -20.3579154409999],
            [-42.853278573, -20.357845509999901],
            [-42.853382398999898, -20.357474322],
            [-42.853371056, -20.357272671],
            [-42.853589892999899, -20.357080039],
            [-42.853823627999901, -20.357050961],
            [-42.85408681, -20.3567106409999],
            [-42.854645075, -20.356663679999901],
            [-42.854867188999897, -20.3568534579999],
            [-42.855524414999898, -20.3569484299999],
            [-42.855918651, -20.357205704],
            [-42.856107998, -20.357541787999899],
            [-42.856404851, -20.357553321999902],
            [-42.856542245, -20.3573611099999],
            [-42.856616577, -20.357075645999899],
            [-42.856934549, -20.356965099],
            [-42.857316164999901, -20.356994539],
            [-42.857515862999897, -20.356851203],
            [-42.857560395999897, -20.356660409],
            [-42.857806695, -20.3564867599999],
            [-42.85803243, -20.356481178],
            [-42.858257422, -20.356589148999898],
            [-42.85840015, -20.356612866],
            [-42.858553714, -20.356522481],
            [-42.858577006, -20.356350022],
            [-42.858493553, -20.356093095999899],
            [-42.858339674, -20.355981153999899],
            [-42.858269013, -20.355875746],
            [-42.858282647, -20.355774159],
            [-42.858387854999897, -20.355657360999899],
            [-42.858725276, -20.355589118999902],
            [-42.859137057999902, -20.355259128999901],
            [-42.859391204, -20.355095032],
            [-42.859547979999903, -20.35505445],
            [-42.85964016, -20.354974887],
            [-42.859696418999903, -20.354640345],
            [-42.85970474, -20.35449626],
            [-42.859676604999898, -20.354413361999899],
            [-42.859648485, -20.3542658759999],
            [-42.859682952, -20.354127214],
            [-42.859749504, -20.353945363],
            [-42.859759241999903, -20.353799409],
            [-42.85970213, -20.353653418],
            [-42.859685274, -20.3535246329999],
            [-42.859701395999899, -20.353212454999898],
            [-42.859541873999902, -20.352353575999899],
            [-42.859445798, -20.352276522],
            [-42.859265638999901, -20.352278284],
            [-42.858972061, -20.352485135999899],
            [-42.858303728, -20.353022526],
            [-42.858193963, -20.353072509],
            [-42.858003145999902, -20.353054997999902],
            [-42.857466691999903, -20.352641293],
            [-42.857243153, -20.352395542],
            [-42.857123562, -20.3520492339999],
            [-42.857084402999902, -20.351844849],
            [-42.857051672999901, -20.351736844999898],
            [-42.856916326, -20.351645485999899],
            [-42.856510122, -20.351297702999901],
            [-42.856139065, -20.3510403289999],
            [-42.855842659, -20.350891083],
            [-42.855695511, -20.350591299],
            [-42.855625689, -20.350170066],
            [-42.856041026, -20.348152684],
            [-42.856115254, -20.346873801999902],
            [-42.8551948369999, -20.344131505999901],
            [-42.8547867139999, -20.343272634],
            [-42.8534440029999, -20.340736364],
            [-42.851443849, -20.3387094889999],
            [-42.84944669, -20.337128371999899],
            [-42.846031991, -20.334962138],
            [-42.844571103, -20.333894874],
            [-42.8441832, -20.333355524999899],
            [-42.843915415, -20.332721966],
            [-42.843875792999903, -20.331516964999899],
            [-42.844235837, -20.330757705999901],
            [-42.84486378, -20.329920995999899],
            [-42.844861, -20.329920995999899],
            [-42.844580999999899, -20.329839996],
            [-42.844338, -20.329781996],
            [-42.844009, -20.329803996],
            [-42.843765, -20.329780996],
            [-42.843437, -20.329642996],
            [-42.843168999999897, -20.329515995999898],
            [-42.84295, -20.329400995999901],
            [-42.842682, -20.329192996],
            [-42.842512, -20.328893996],
            [-42.842354, -20.328640995999901],
            [-42.84216, -20.328353996],
            [-42.842039, -20.328134996],
            [-42.841832, -20.327835996],
            [-42.841662, -20.327686996],
            [-42.84154, -20.327490995999899],
            [-42.841382, -20.327295996],
            [-42.841212, -20.327053996],
            [-42.841018, -20.326765995999899],
            [-42.840871999999898, -20.326478995999899],
            [-42.840775, -20.326248995999901],
            [-42.840642, -20.325972996],
            [-42.840556999999897, -20.325754995999901],
            [-42.840483999999897, -20.325501996],
            [-42.840387, -20.325282995999899],
            [-42.840241999999897, -20.324960996],
            [-42.840127, -20.3247859959999],
            [-42.83995, -20.324535996],
            [-42.839743999999897, -20.324362995999898],
            [-42.839488, -20.3243399959999],
            [-42.839232, -20.324338995999899],
            [-42.838976, -20.324361995999901],
            [-42.838696, -20.324395996],
            [-42.838416, -20.324452995999899],
            [-42.838111, -20.324486996],
            [-42.83788, -20.324520995999901],
            [-42.8376, -20.3245439959999],
            [-42.837221999999898, -20.324599996],
            [-42.83693, -20.324599996],
            [-42.836723, -20.324461996],
            [-42.836430999999898, -20.324368996],
            [-42.836199, -20.324333996],
            [-42.835918999999897, -20.324276996],
            [-42.835676, -20.324206995999901],
            [-42.835274, -20.324068995999902],
            [-42.835043, -20.324010996],
            [-42.834775, -20.323963996],
            [-42.834422, -20.323986996],
            [-42.834166, -20.3239289959999],
            [-42.833909999999896, -20.3239289959999],
            [-42.83363, -20.3239739959999],
            [-42.833386, -20.324007996],
            [-42.833131, -20.324041995999899],
            [-42.832838, -20.324052996],
            [-42.832375, -20.324189996],
            [-42.832265999999898, -20.323925996],
            [-42.832145, -20.3236839959999],
            [-42.832048, -20.323465996],
            [-42.831950999999897, -20.323223996],
            [-42.831865999999899, -20.323017996],
            [-42.83183, -20.322798996],
            [-42.831794, -20.322557995999901],
            [-42.831782, -20.322281996],
            [-42.831721, -20.322051996],
            [-42.831746, -20.321753995999899],
            [-42.831759, -20.321534996],
            [-42.83176, -20.321017996],
            [-42.831712, -20.3205589959999],
            [-42.831627, -20.320305995999899],
            [-42.831482, -20.320075996],
            [-42.83136, -20.319810995999902],
            [-42.831251, -20.319569995999899],
            [-42.831105, -20.319316995999898],
            [-42.830959999999898, -20.319109996],
            [-42.83079, -20.318890995999901],
            [-42.83062, -20.318660995999899],
            [-42.83045, -20.318384996],
            [-42.83028, -20.318074996],
            [-42.830146, -20.317878995999902],
            [-42.829976, -20.317636996],
            [-42.829891, -20.317384995999902],
            [-42.829721, -20.317177995999899],
            [-42.829563, -20.317015996],
            [-42.829368, -20.316877996],
            [-42.829161999999897, -20.316739996],
            [-42.828858, -20.316566995999899],
            [-42.828639, -20.316462995999899],
            [-42.828346, -20.316358996],
            [-42.82803, -20.316220996],
            [-42.827762, -20.316105996],
            [-42.827494, -20.316070996],
            [-42.827239, -20.316092996],
            [-42.826666, -20.316091996],
            [-42.826386, -20.316022996],
            [-42.826131, -20.315884996],
            [-42.825924, -20.315711995999902],
            [-42.825729, -20.315573996],
            [-42.825122, -20.315020995999902],
            [-42.824793, -20.314744995999899],
            [-42.824513, -20.314640996],
            [-42.824233, -20.314628996],
            [-42.823977, -20.314662996],
            [-42.82377, -20.314765995999899],
            [-42.823563, -20.3150189959999],
            [-42.823355, -20.3152019959999],
            [-42.823233, -20.315408996],
            [-42.823085999999897, -20.315706996],
            [-42.822976, -20.315959995999901],
            [-42.822878, -20.316188995999902],
            [-42.822756, -20.316383996],
            [-42.822634, -20.316602996],
            [-42.822451, -20.316762996],
            [-42.822268, -20.316900996],
            [-42.822085, -20.317060996],
            [-42.821841, -20.317140996],
            [-42.821621999999898, -20.317232996],
            [-42.821366, -20.317266995999901],
            [-42.821135, -20.317357995999899],
            [-42.820853999999898, -20.3174039959999],
            [-42.820598, -20.317483996],
            [-42.820391, -20.317609995999899],
            [-42.820147, -20.317815996],
            [-42.819903, -20.317941995999899],
            [-42.819647, -20.3180329959999],
            [-42.81955, -20.318251996],
            [-42.819403, -20.318446996],
            [-42.819280999999897, -20.318698996],
            [-42.819171, -20.3188939959999],
            [-42.818987999999898, -20.319146996],
            [-42.818878, -20.319341995999899],
            [-42.818755, -20.319536996],
            [-42.818536, -20.319708996],
            [-42.818292, -20.319753996],
            [-42.818024, -20.319753996],
            [-42.817793, -20.319741995999902],
            [-42.817562, -20.319660996],
            [-42.81733, -20.319556996],
            [-42.817063, -20.319292995999898],
            [-42.816893, -20.319108996],
            [-42.816747, -20.318924996],
            [-42.816600999999899, -20.3187519959999],
            [-42.816468, -20.318463995999899],
            [-42.816189, -20.317935996],
            [-42.816068, -20.317647995999899],
            [-42.815958, -20.317429995999898],
            [-42.815812999999899, -20.317072996],
            [-42.815752, -20.316854996],
            [-42.815655, -20.3166129959999],
            [-42.815571, -20.316382996],
            [-42.815497999999899, -20.316107996],
            [-42.815474, -20.315831995999901],
            [-42.815377, -20.315601995999899],
            [-42.815256, -20.315325996],
            [-42.815123, -20.3151299959999],
            [-42.814916, -20.314876996],
            [-42.814794999999897, -20.314589995999899],
            [-42.814564, -20.314451995999899],
            [-42.814345, -20.314301995999902],
            [-42.814138, -20.3141409959999],
            [-42.813883, -20.314001995999899],
            [-42.813664, -20.313886996],
            [-42.813285999999898, -20.313828995999899],
            [-42.813055, -20.313862996],
            [-42.812773999999898, -20.313907996],
            [-42.812397, -20.313930996],
            [-42.812140999999897, -20.313998996],
            [-42.811897, -20.314170996],
            [-42.811702, -20.314319995999899],
            [-42.81147, -20.3144799959999],
            [-42.811251, -20.314605996],
            [-42.810859999999899, -20.314926995999901],
            [-42.810676999999899, -20.315064995999901],
            [-42.81047, -20.315202996],
            [-42.810251, -20.315373995999899],
            [-42.810055, -20.315522996],
            [-42.809872, -20.315660996],
            [-42.809688999999899, -20.315821996],
            [-42.80947, -20.315935996],
            [-42.809287, -20.316096995999899],
            [-42.809043, -20.316244996],
            [-42.808836, -20.3163709959999],
            [-42.808616, -20.316519996],
            [-42.808421, -20.316645996],
            [-42.808189, -20.316783996],
            [-42.807946, -20.316897996],
            [-42.807714, -20.3170239959999],
            [-42.80747, -20.317287995999902],
            [-42.807287, -20.317505996],
            [-42.807116, -20.317654995999899],
            [-42.806909, -20.317780996],
            [-42.806801999999898, -20.317812995999901]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 123,
        "id": 37034,
        "CD_SETOR": "315210505070063P",
        "AREA_KM2": 0.030364,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 305
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.872293187, -20.428998958],
            [-42.873231591, -20.429202088],
            [-42.873549490999899, -20.428931507],
            [-42.873838282, -20.428546743],
            [-42.874003799, -20.428165324],
            [-42.874219883, -20.427445441],
            [-42.872763655, -20.427110319999901],
            [-42.872293187, -20.428998958]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 124,
        "id": 37035,
        "CD_SETOR": "315210505070064P",
        "AREA_KM2": 0.164612,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 313
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.890364374999898, -20.4671000129999],
            [-42.890293158, -20.467152015],
            [-42.890204740999899, -20.467261570999899],
            [-42.890096359, -20.467325700999901],
            [-42.890047873, -20.467341733999898],
            [-42.88992523, -20.4673470779999],
            [-42.889791179, -20.467355094],
            [-42.889739839999898, -20.467363110999901],
            [-42.889717022999903, -20.4674031919999],
            [-42.889762656999899, -20.467646353],
            [-42.889868186999898, -20.468087248],
            [-42.890039316, -20.468597615],
            [-42.890087803, -20.468739234999902],
            [-42.890104916, -20.4688835269999],
            [-42.890047873, -20.4690812609999],
            [-42.889910969, -20.46928701],
            [-42.889782623, -20.469498103],
            [-42.889708466999899, -20.469613001],
            [-42.889648571, -20.4696477379999],
            [-42.889574415, -20.469671787],
            [-42.889534485, -20.469709195999901],
            [-42.88950026, -20.4697759969999],
            [-42.889485998999902, -20.469882879],
            [-42.889477441999901, -20.469957696999899],
            [-42.889446069, -20.470011138],
            [-42.889403285999897, -20.470072595],
            [-42.889796883, -20.470345144],
            [-42.890013646999897, -20.4705321869999],
            [-42.890606894, -20.470949026],
            [-42.891188733, -20.471456712],
            [-42.891553808, -20.471761323],
            [-42.891935997, -20.471975084],
            [-42.892306775999899, -20.4721460929999],
            [-42.892591991, -20.4722155659999],
            [-42.892902875999901, -20.472317102],
            [-42.893079708999899, -20.4721460929999],
            [-42.893270803, -20.471937676],
            [-42.8933306979999, -20.471825451],
            [-42.893590243999903, -20.471256309999902],
            [-42.893721442999897, -20.471015825999899],
            [-42.893963875999901, -20.4705081389999],
            [-42.894123607, -20.470110721],
            [-42.894110291999901, -20.470089822],
            [-42.893963163, -20.470016481999899],
            [-42.893880451, -20.469947009],
            [-42.893792033999901, -20.469837454],
            [-42.893739801, -20.469685789],
            [-42.893698161, -20.46952866],
            [-42.893675096, -20.469412597],
            [-42.893666539, -20.4693377789999],
            [-42.893648129, -20.469268359999901],
            [-42.893608704, -20.469162319],
            [-42.893472207999899, -20.469066644999899],
            [-42.893210294, -20.468798445999902],
            [-42.8931956489999, -20.468773165],
            [-42.89317675, -20.468740539999899],
            [-42.893006194, -20.468446113],
            [-42.892843672, -20.4681615649999],
            [-42.892749657, -20.468120466999899],
            [-42.892652337999898, -20.468130621999901],
            [-42.892246558, -20.468143934999901],
            [-42.892034536, -20.468097865999901],
            [-42.891885683999902, -20.468032089],
            [-42.891832606, -20.467977692],
            [-42.891799534, -20.467941542999899],
            [-42.89179838, -20.467940283],
            [-42.891764153999901, -20.467929593999902],
            [-42.891684456, -20.467945539],
            [-42.891581617, -20.467945626999899],
            [-42.891461826, -20.467878825],
            [-42.891410488, -20.467758580999899],
            [-42.891353444999901, -20.467673073999901],
            [-42.891299253999897, -20.467619632],
            [-42.891196673, -20.4675488039999],
            [-42.891130976999897, -20.467491370999898],
            [-42.891088195, -20.467488698999901],
            [-42.8910276249999, -20.4675287969999],
            [-42.89096963, -20.467567190999901],
            [-42.890795699, -20.467599672999899],
            [-42.890624838, -20.467563199],
            [-42.8905787959999, -20.4674872259999],
            [-42.890606181, -20.467429912999901],
            [-42.890603328999902, -20.46733639],
            [-42.890567212999898, -20.46722763],
            [-42.890517825, -20.467162519999899],
            [-42.890447992, -20.467094555],
            [-42.890364374999898, -20.4671000129999]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 125,
        "id": 37036,
        "CD_SETOR": "315210505070065P",
        "AREA_KM2": 0.048193,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 55
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.891594163, -20.411486854],
            [-42.892404637, -20.412309569999898],
            [-42.893196651, -20.413101431],
            [-42.894529088, -20.411973663],
            [-42.894597938999901, -20.4118988879999],
            [-42.893799524, -20.411112568],
            [-42.893438633, -20.410684107999899],
            [-42.893059638999901, -20.4103029629999],
            [-42.891594163, -20.411486854]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 126,
        "id": 37037,
        "CD_SETOR": "315210505070066P",
        "AREA_KM2": 0.047798,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 143
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.890691849999897, -20.412278254999901],
            [-42.890217064, -20.4126953419999],
            [-42.890539213, -20.413037421],
            [-42.891012641, -20.413491436999902],
            [-42.891014707, -20.413493416999898],
            [-42.8917514299999, -20.414288154],
            [-42.892162407999898, -20.413971316],
            [-42.893196651, -20.413101431],
            [-42.892404637, -20.412309569999898],
            [-42.891594163, -20.411486854],
            [-42.890691849999897, -20.412278254999901]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 127,
        "id": 37038,
        "CD_SETOR": "315210505070067P",
        "AREA_KM2": 0.031708,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 309
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.895756669, -20.4023749149999],
            [-42.895695382, -20.402418257],
            [-42.89566934, -20.402451955999901],
            [-42.89565068, -20.402527635999899],
            [-42.895719211, -20.402626486],
            [-42.895795708, -20.402702586],
            [-42.896072323999903, -20.402982972],
            [-42.896285204, -20.403213203],
            [-42.89636689, -20.403302288],
            [-42.896401652, -20.403376045],
            [-42.896416906999903, -20.403447089999901],
            [-42.896425232999903, -20.403534614],
            [-42.896421357999898, -20.403630221999901],
            [-42.896386585999899, -20.403727537],
            [-42.896340413, -20.403828507],
            [-42.896188513, -20.403955461],
            [-42.896069444, -20.404088988999899],
            [-42.896039268, -20.404191609],
            [-42.896022957999897, -20.404325523],
            [-42.895993801, -20.404431229],
            [-42.895961826, -20.40453566],
            [-42.895884927999901, -20.404636487999898],
            [-42.895491838999902, -20.40501175],
            [-42.894970596, -20.405504526],
            [-42.895069118, -20.405538027],
            [-42.895159183, -20.405536608],
            [-42.895259667999902, -20.405499483],
            [-42.895370505, -20.405432216],
            [-42.895478974, -20.40533557],
            [-42.895705792999898, -20.4050829409999],
            [-42.895799398999898, -20.405049236],
            [-42.895891003, -20.405032892],
            [-42.896112796999901, -20.404830619999899],
            [-42.896297709, -20.404577784],
            [-42.896353033, -20.40441457],
            [-42.896993898999902, -20.403405807999899],
            [-42.897363787, -20.402863984],
            [-42.897429777, -20.402767989],
            [-42.897507016, -20.4026963929999],
            [-42.898441324, -20.402041793],
            [-42.898642931, -20.4019421909999],
            [-42.898746686, -20.401916186999902],
            [-42.898868039, -20.40191326],
            [-42.899132651999899, -20.401941596],
            [-42.899338491, -20.4020030729999],
            [-42.899693721999903, -20.402158011],
            [-42.899792732, -20.402184373999901],
            [-42.899946617, -20.4021973709999],
            [-42.900060171, -20.402171079],
            [-42.900206652999898, -20.402114402999899],
            [-42.900392081999897, -20.401983137999899],
            [-42.900557609, -20.401821491],
            [-42.900707005999898, -20.401614858999899],
            [-42.900816410999902, -20.401380182],
            [-42.900881356, -20.401097959],
            [-42.900937352, -20.400307234],
            [-42.900905446, -20.400068786],
            [-42.900651393, -20.399375710999902],
            [-42.900544583999903, -20.398892637],
            [-42.900557318, -20.398500517999899],
            [-42.9005494479999, -20.3984926319999],
            [-42.900546518999903, -20.39850372],
            [-42.900521296, -20.398566778],
            [-42.900467067, -20.398715172],
            [-42.900456977, -20.398864827999901],
            [-42.900460341, -20.39894386],
            [-42.900507423, -20.399180955],
            [-42.900524237999903, -20.399282266999901],
            [-42.900604952, -20.399568126],
            [-42.900663805, -20.399830444],
            [-42.9007007989999, -20.399993552],
            [-42.900737792, -20.400158341999902],
            [-42.90072434, -20.400339105999901],
            [-42.9007108879999, -20.400667004],
            [-42.900689027999903, -20.400936047999899],
            [-42.900662124, -20.401096213],
            [-42.900562914, -20.401373664999898],
            [-42.900324137, -20.401720059],
            [-42.900097130999903, -20.401915115999898],
            [-42.8999424309999, -20.401994148],
            [-42.89976587, -20.401967242999898],
            [-42.899295042999903, -20.401831039999902],
            [-42.89913698, -20.401783957],
            [-42.898723323999903, -20.401760415999899],
            [-42.898514814999899, -20.401820950999898],
            [-42.8982255929999, -20.401997510999902],
            [-42.898035579999899, -20.402148007999902],
            [-42.897921235999902, -20.402240490999901],
            [-42.89769423, -20.402385103],
            [-42.897512626, -20.402457408],
            [-42.897428549, -20.402493981],
            [-42.897273848999902, -20.402621777],
            [-42.897058613999903, -20.402769751999902],
            [-42.896897187, -20.402801700999898],
            [-42.896747531, -20.402857191],
            [-42.896639913999898, -20.402993395],
            [-42.896359098999902, -20.402828605],
            [-42.896180856999898, -20.402720987],
            [-42.895910130999901, -20.402539383],
            [-42.895756669, -20.4023749149999]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 128,
        "id": 37039,
        "CD_SETOR": "315210505070068P",
        "AREA_KM2": 0.021915,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 81
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.900444397999898, -20.400664423],
            [-42.900450374999899, -20.400843899999899],
            [-42.900441059999899, -20.400976128],
            [-42.900311284999901, -20.401327976999902],
            [-42.900145986, -20.40148907],
            [-42.900033197, -20.4015989869999],
            [-42.899863756999899, -20.401681645999901],
            [-42.8996957479999, -20.401688708999899],
            [-42.89942543, -20.401602004],
            [-42.898998918, -20.401549064999902],
            [-42.898580776, -20.401555726],
            [-42.898359980999899, -20.401606224],
            [-42.898212097999902, -20.401697591999898],
            [-42.898082202999902, -20.4017992509999],
            [-42.897862937999903, -20.401970853],
            [-42.897589925, -20.402130642],
            [-42.897301076, -20.402251446],
            [-42.89721036, -20.402296708999899],
            [-42.897152444, -20.402338256],
            [-42.89702255, -20.402431433999901],
            [-42.896882868, -20.4025316349999],
            [-42.896704091999901, -20.402608926],
            [-42.896459568999902, -20.4026118969999],
            [-42.8963525509999, -20.402575729],
            [-42.896266737, -20.402546727],
            [-42.896049982, -20.402433365],
            [-42.895912541, -20.402355411],
            [-42.8958423569999, -20.402352344],
            [-42.895756669, -20.4023749149999],
            [-42.895910130999901, -20.402539383],
            [-42.896180856999898, -20.402720987],
            [-42.896359098999902, -20.402828605],
            [-42.896639913999898, -20.402993395],
            [-42.896747531, -20.402857191],
            [-42.896897187, -20.402801700999898],
            [-42.897058613999903, -20.402769751999902],
            [-42.897273848999902, -20.402621777],
            [-42.897428549, -20.402493981],
            [-42.897512626, -20.402457408],
            [-42.89769423, -20.402385103],
            [-42.897921235999902, -20.402240490999901],
            [-42.898035579999899, -20.402148007999902],
            [-42.8982255929999, -20.401997510999902],
            [-42.898514814999899, -20.401820950999898],
            [-42.898723323999903, -20.401760415999899],
            [-42.89913698, -20.401783957],
            [-42.899295042999903, -20.401831039999902],
            [-42.89976587, -20.401967242999898],
            [-42.8999424309999, -20.401994148],
            [-42.900097130999903, -20.401915115999898],
            [-42.900324137, -20.401720059],
            [-42.900562914, -20.401373664999898],
            [-42.900662124, -20.401096213],
            [-42.900689027999903, -20.400936047999899],
            [-42.9007108879999, -20.400667004],
            [-42.90072434, -20.400339105999901],
            [-42.900737792, -20.400158341999902],
            [-42.9007007989999, -20.399993552],
            [-42.900663805, -20.399830444],
            [-42.900604952, -20.399568126],
            [-42.900524237999903, -20.399282266999901],
            [-42.900507423, -20.399180955],
            [-42.900460341, -20.39894386],
            [-42.900456977, -20.398864827999901],
            [-42.900467067, -20.398715172],
            [-42.900521296, -20.398566778],
            [-42.900546518999903, -20.39850372],
            [-42.9005494479999, -20.3984926319999],
            [-42.900497539, -20.398427820999899],
            [-42.9004684619999, -20.398521490999901],
            [-42.900432903, -20.398590638],
            [-42.900382543, -20.3986622799999],
            [-42.900354029, -20.398763232],
            [-42.900361392, -20.398960338999899],
            [-42.900365437999902, -20.3991101],
            [-42.900404637999898, -20.399439365],
            [-42.900460537, -20.399908887],
            [-42.900474571, -20.400451453],
            [-42.900444397999898, -20.400664423]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 129,
        "id": 37040,
        "CD_SETOR": "315210505070069P",
        "AREA_KM2": 0.15291,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 131
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.8973431699999, -20.415377652],
            [-42.897338510999901, -20.415379308999899],
            [-42.896778963, -20.415517113],
            [-42.896504996, -20.415666737],
            [-42.896263131, -20.415796545],
            [-42.896129894, -20.4158343719999],
            [-42.895546146999898, -20.415984946],
            [-42.894365434, -20.417569353],
            [-42.894373081, -20.417586906999901],
            [-42.894411894, -20.417641561],
            [-42.89444417, -20.417645557],
            [-42.898271942999898, -20.418540709],
            [-42.898300461, -20.417999261],
            [-42.898306901, -20.417916426],
            [-42.898354496, -20.417782136],
            [-42.898523183999899, -20.417589785],
            [-42.898705333, -20.417436818999899],
            [-42.899108646999899, -20.4169938749999],
            [-42.899361128, -20.417148222],
            [-42.899402716999901, -20.417077013],
            [-42.899514305, -20.416955072],
            [-42.900042603, -20.416487485999902],
            [-42.900196786, -20.416340385],
            [-42.900386577999903, -20.416168027],
            [-42.900537439999901, -20.416028753],
            [-42.900657264, -20.415855388],
            [-42.900684564, -20.4156790719999],
            [-42.900653991, -20.41555203],
            [-42.900532004999903, -20.415145799],
            [-42.90051741, -20.415080072],
            [-42.899856824, -20.415158907],
            [-42.899533087, -20.415025795],
            [-42.899388721, -20.414943444],
            [-42.899307318, -20.414862925],
            [-42.89919214, -20.414748999],
            [-42.899108032, -20.414616726],
            [-42.89902691, -20.414489149],
            [-42.898812518999897, -20.414163226],
            [-42.898738455999897, -20.414067467999899],
            [-42.898685976, -20.413999615],
            [-42.898718856999899, -20.414084247999899],
            [-42.898477838, -20.414170544999902],
            [-42.898750927, -20.414606959],
            [-42.898753069, -20.414673471],
            [-42.898771177, -20.414748475],
            [-42.898714933, -20.415056991],
            [-42.898220283999898, -20.4150771],
            [-42.897531275999903, -20.415310771],
            [-42.8973431699999, -20.415377652]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 130,
        "id": 37041,
        "CD_SETOR": "315210505070070P",
        "AREA_KM2": 0.183884,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 265
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.894411894, -20.417641561],
            [-42.894438575, -20.41767913],
            [-42.894473261, -20.4179328079999],
            [-42.894714011, -20.418123287999901],
            [-42.894897741, -20.418246594],
            [-42.895212426999898, -20.418371088],
            [-42.895665376, -20.418521456],
            [-42.896197534999899, -20.418664395999901],
            [-42.896540167999902, -20.418850839999902],
            [-42.896447398, -20.418948699999898],
            [-42.896268377999903, -20.4191342509999],
            [-42.895774084, -20.419624021999901],
            [-42.895390068, -20.420016775],
            [-42.895681356, -20.420222882],
            [-42.895520809999901, -20.420680619999899],
            [-42.895608927, -20.421104495],
            [-42.895590635, -20.421418071999899],
            [-42.895533583, -20.4215559159999],
            [-42.8953442709999, -20.421811218],
            [-42.895151556, -20.422015496],
            [-42.8948200849999, -20.422181232],
            [-42.894793105, -20.4224587419999],
            [-42.894781542, -20.422763233],
            [-42.894870673, -20.42302918],
            [-42.89489902, -20.42310778],
            [-42.894900962999898, -20.423018156],
            [-42.894909793999901, -20.422973515],
            [-42.894905607999902, -20.422859618],
            [-42.894907683, -20.42278035],
            [-42.894934967, -20.422723297999902],
            [-42.894971276, -20.422687328999899],
            [-42.895040663, -20.422617577],
            [-42.895103252, -20.422593779],
            [-42.895153669, -20.422575631],
            [-42.8952287279999, -20.422588467],
            [-42.895280844999903, -20.422610569],
            [-42.895349118999903, -20.422659086],
            [-42.895501081, -20.422762673],
            [-42.895512391, -20.42276907],
            [-42.895637568, -20.422891195],
            [-42.895655448999896, -20.4229405419999],
            [-42.895681725, -20.423013074],
            [-42.895717201, -20.42305665],
            [-42.895882688, -20.423127018999899],
            [-42.895986573999899, -20.423391672],
            [-42.896007133, -20.423447463999899],
            [-42.896038604, -20.423468713],
            [-42.896070075999901, -20.423489962],
            [-42.896146722, -20.423506489],
            [-42.896505595999898, -20.423555661],
            [-42.896668104999897, -20.423590948],
            [-42.896819798, -20.423601871],
            [-42.896992248999901, -20.423605284],
            [-42.897117383, -20.4235951759999],
            [-42.897232190999901, -20.423609966999901],
            [-42.8972744289999, -20.423586079],
            [-42.8973475559999, -20.423545702999899],
            [-42.89743042, -20.423492490999902],
            [-42.897533570999897, -20.423434697999902],
            [-42.897639378, -20.423325836999901],
            [-42.897693655999902, -20.423201583],
            [-42.897704549, -20.423069869],
            [-42.897639055, -20.422911161999899],
            [-42.8975628869999, -20.422823170999902],
            [-42.897253987999903, -20.422551628],
            [-42.897140653999898, -20.422328901999901],
            [-42.897153967999898, -20.422213574],
            [-42.897232126999903, -20.422157316],
            [-42.897325235, -20.422107062999899],
            [-42.897460036, -20.422071968],
            [-42.897815801, -20.422074616],
            [-42.897930760999898, -20.422059513],
            [-42.898016094, -20.422043218999899],
            [-42.898161718, -20.422030255],
            [-42.898351289999901, -20.422028359],
            [-42.89839254, -20.422009494999902],
            [-42.898453534999902, -20.421836566],
            [-42.898475418, -20.421629955999901],
            [-42.898476235, -20.421511134],
            [-42.89846778, -20.421285048],
            [-42.898469532, -20.421073282999899],
            [-42.898416220999898, -20.420734694],
            [-42.898373271, -20.420527056],
            [-42.898323263999899, -20.420235437],
            [-42.898324459, -20.42011782],
            [-42.898371310999899, -20.419913772],
            [-42.8984115, -20.4198100629999],
            [-42.8985033599999, -20.419675011],
            [-42.898582512, -20.419609039],
            [-42.898808329, -20.41944132],
            [-42.899103251, -20.419260356999899],
            [-42.899236046999903, -20.419162371],
            [-42.899307714, -20.41906112],
            [-42.899364580999901, -20.41889602],
            [-42.899383663, -20.418827911999902],
            [-42.899412706, -20.418680641],
            [-42.899405889, -20.418505652],
            [-42.899368304999904, -20.418371793999899],
            [-42.899317965, -20.418263055999901],
            [-42.899260829, -20.418087007999901],
            [-42.899204257999898, -20.417902796],
            [-42.899193785, -20.417755096],
            [-42.899196374, -20.417614016999899],
            [-42.899220887, -20.417493132999901],
            [-42.899274713, -20.417307568],
            [-42.899348687, -20.4171709579999],
            [-42.899355621, -20.417158298999901],
            [-42.899361128, -20.417148222],
            [-42.899108646999899, -20.4169938749999],
            [-42.898705333, -20.417436818999899],
            [-42.898523183999899, -20.417589785],
            [-42.898354496, -20.417782136],
            [-42.898306901, -20.417916426],
            [-42.898300461, -20.417999261],
            [-42.898271942999898, -20.418540709],
            [-42.89444417, -20.417645557],
            [-42.894411894, -20.417641561]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 131,
        "id": 37042,
        "CD_SETOR": "315210505070071P",
        "AREA_KM2": 0.04255,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 106
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.894742515, -20.401138826],
            [-42.894810704999898, -20.401104706999899],
            [-42.894900408, -20.401271707],
            [-42.895192127, -20.401771348],
            [-42.895361256999898, -20.402051328],
            [-42.895546179999897, -20.402396974999899],
            [-42.895592399999899, -20.402455959],
            [-42.89565068, -20.402527635999899],
            [-42.89566934, -20.402451955999901],
            [-42.895695382, -20.402418257],
            [-42.895756669, -20.4023749149999],
            [-42.8958423569999, -20.402352344],
            [-42.895912541, -20.402355411],
            [-42.896049982, -20.402433365],
            [-42.896135969999897, -20.402332215999898],
            [-42.896458262, -20.402063566],
            [-42.896320776, -20.401397073999899],
            [-42.896233229, -20.400926706],
            [-42.895663391, -20.400531391999898],
            [-42.895522254, -20.400388006999901],
            [-42.895445843999902, -20.400324415999901],
            [-42.895416887, -20.4003216629999],
            [-42.895407501, -20.400245246999901],
            [-42.895372838, -20.400155898],
            [-42.895347612, -20.400037003],
            [-42.895344762, -20.399959096],
            [-42.895361556, -20.399902699999899],
            [-42.895384922999902, -20.399842516],
            [-42.89542431, -20.399778177],
            [-42.895471729, -20.3997387699999],
            [-42.895521362, -20.399662552999899],
            [-42.895576573, -20.399590916],
            [-42.895614416999898, -20.399512260999899],
            [-42.895647734, -20.399387256999901],
            [-42.895639098999901, -20.399262096],
            [-42.895502495999899, -20.3991658059999],
            [-42.895336479, -20.399167615],
            [-42.895145849, -20.39912757],
            [-42.894966052, -20.39916704],
            [-42.894753889, -20.399264926999901],
            [-42.894642674999901, -20.399283823],
            [-42.894490011, -20.399228014999899],
            [-42.894451997, -20.399328706999899],
            [-42.894359139, -20.399595652],
            [-42.894521111, -20.399761473999899],
            [-42.894598970999901, -20.399876529],
            [-42.894616309999897, -20.399961828999899],
            [-42.894590729999898, -20.400118482],
            [-42.894554236, -20.400166982],
            [-42.894500660999903, -20.400215526],
            [-42.894466837, -20.40033419],
            [-42.894473409999897, -20.400509316999901],
            [-42.8945001559999, -20.4006698929999],
            [-42.894532145, -20.400750409],
            [-42.894575374999903, -20.400859211999901],
            [-42.8946175519999, -20.400961428999899],
            [-42.894678432, -20.4010661259999],
            [-42.894742515, -20.401138826]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 132,
        "id": 37043,
        "CD_SETOR": "315210505070072P",
        "AREA_KM2": 0.377263,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 152
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.882440791, -20.398683197],
            [-42.882885691, -20.398676001],
            [-42.883419722, -20.398735551],
            [-42.883969881, -20.398793978],
            [-42.884233631, -20.398834485],
            [-42.884368150999897, -20.3988145759999],
            [-42.884505508, -20.398790806],
            [-42.884557539, -20.398791694],
            [-42.884647846, -20.398803323],
            [-42.884705868, -20.3988360439999],
            [-42.884735911999897, -20.398885875],
            [-42.884735927999898, -20.398967268],
            [-42.884753664999899, -20.399054869999901],
            [-42.884797536, -20.3991225249999],
            [-42.884846043, -20.399185782],
            [-42.884902741, -20.399240527],
            [-42.884982767999901, -20.399291322],
            [-42.885143577, -20.399305135],
            [-42.885211136999899, -20.399341835],
            [-42.885220417, -20.399377511],
            [-42.8852606889999, -20.399408075999901],
            [-42.885344284, -20.399425806],
            [-42.885421809, -20.3994525539999],
            [-42.885521254, -20.3995130919999],
            [-42.885592252, -20.399559516],
            [-42.885689562, -20.399614616],
            [-42.885760694999902, -20.399695560999898],
            [-42.885882726, -20.39981355],
            [-42.8858780589999, -20.399857822999898],
            [-42.885846205, -20.3999526069999],
            [-42.885806746999897, -20.400070015],
            [-42.885787025, -20.4001286989999],
            [-42.885772372, -20.4001723],
            [-42.885900869999901, -20.400239633999899],
            [-42.885945106, -20.4003003249999],
            [-42.886050312999899, -20.40037682],
            [-42.886160007999898, -20.400441000999901],
            [-42.886409910999902, -20.400623701],
            [-42.88643295, -20.400683876],
            [-42.88659946, -20.40073303],
            [-42.886728119999901, -20.400845352],
            [-42.886824531999899, -20.4008963869999],
            [-42.886921452, -20.400892063],
            [-42.887034554, -20.400917572],
            [-42.887252562, -20.401069849],
            [-42.887320304999903, -20.401117165999899],
            [-42.88740088, -20.401137752],
            [-42.887516377999901, -20.401297074999899],
            [-42.887565439999896, -20.4013504979999],
            [-42.887642001, -20.40133205],
            [-42.887749797, -20.401330747],
            [-42.88782255, -20.401358711999901],
            [-42.887913624, -20.401481315],
            [-42.888025311, -20.401532006],
            [-42.888058196, -20.401529957],
            [-42.888223968, -20.40153221],
            [-42.888267114, -20.401532254],
            [-42.888329999, -20.401515387],
            [-42.888394506999902, -20.401525536],
            [-42.888506542999899, -20.40154301],
            [-42.888526167999899, -20.401547174],
            [-42.888624283999903, -20.401543649],
            [-42.888645282, -20.401503677999901],
            [-42.888696146, -20.4015164099999],
            [-42.888750317, -20.4015495589999],
            [-42.888830223, -20.401584218],
            [-42.888956838999903, -20.401610021],
            [-42.889091099, -20.401647871],
            [-42.88924144, -20.401656778],
            [-42.889435681999899, -20.401678516999901],
            [-42.889473956, -20.401695227999902],
            [-42.889681604, -20.401784926999898],
            [-42.889802704, -20.401836108999898],
            [-42.889951661999902, -20.401876386],
            [-42.890138776999898, -20.401967536],
            [-42.890479491999898, -20.402133510999899],
            [-42.890640511, -20.4022257],
            [-42.8907045179999, -20.402279813],
            [-42.89083606, -20.402379648],
            [-42.890903513, -20.402488763],
            [-42.891028948, -20.402677911],
            [-42.891128705, -20.402845614999901],
            [-42.891275547, -20.403055491],
            [-42.891416292, -20.40328533],
            [-42.891512513, -20.403446240999902],
            [-42.891538483, -20.40365593],
            [-42.891558183, -20.403728015999899],
            [-42.89158572, -20.403802131],
            [-42.891599863, -20.403815704],
            [-42.891675429, -20.403741569],
            [-42.891696017, -20.403552712],
            [-42.891694258, -20.403498408],
            [-42.891559206, -20.403205130999901],
            [-42.891511779, -20.403095258999901],
            [-42.891412612, -20.402897832],
            [-42.891303201, -20.402647901],
            [-42.891005918, -20.402217925999899],
            [-42.8908186849999, -20.402000116],
            [-42.890615547, -20.4019071],
            [-42.890389507999899, -20.401814537],
            [-42.8897972359999, -20.4015793629999],
            [-42.889937225, -20.4014381819999],
            [-42.890149854, -20.4013654399999],
            [-42.890591899999897, -20.401359844],
            [-42.890664641999898, -20.401303889],
            [-42.890659045999897, -20.401208765],
            [-42.890407248, -20.4010464959999],
            [-42.8902337869999, -20.400889821],
            [-42.889573516, -20.400184786],
            [-42.8893832689999, -20.400100853],
            [-42.888840504, -20.399955369999901],
            [-42.888616683, -20.399826673],
            [-42.888426435, -20.399642021],
            [-42.888299371, -20.399554579],
            [-42.8884712, -20.399151015],
            [-42.888840504, -20.398731351],
            [-42.889064323999897, -20.398591462999899],
            [-42.889797461999898, -20.398569494],
            [-42.890166641, -20.398566283],
            [-42.890792741, -20.398595008],
            [-42.890348482, -20.398314441],
            [-42.889869898, -20.397853559999898],
            [-42.889512176, -20.397362014999899],
            [-42.889322034, -20.3967263709999],
            [-42.889037119, -20.396147685],
            [-42.888920450999898, -20.395952071],
            [-42.888622819, -20.395502724],
            [-42.887966751, -20.395019835],
            [-42.887213974, -20.394770347],
            [-42.886766778, -20.394717963999899],
            [-42.886308058999902, -20.394786465],
            [-42.886075801999901, -20.394790999],
            [-42.885699839, -20.3946930389999],
            [-42.885238301, -20.394447306],
            [-42.884857919999902, -20.394649785999899],
            [-42.884520425999902, -20.394884127],
            [-42.884243607, -20.395067475],
            [-42.884053439999903, -20.395331187999901],
            [-42.88402861, -20.395597992],
            [-42.884079648999901, -20.395892370999899],
            [-42.8840890399999, -20.396177168999898],
            [-42.883815544, -20.396502666999901],
            [-42.882595609, -20.3984718479999],
            [-42.882440791, -20.398683197]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 133,
        "id": 37044,
        "CD_SETOR": "315210505070073P",
        "AREA_KM2": 0.238761,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 284
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.899863756999899, -20.401681645999901],
            [-42.900033197, -20.4015989869999],
            [-42.900145986, -20.40148907],
            [-42.900311284999901, -20.401327976999902],
            [-42.900441059999899, -20.400976128],
            [-42.900450374999899, -20.400843899999899],
            [-42.900444397999898, -20.400664423],
            [-42.900191563, -20.400459272],
            [-42.900001042, -20.4002775169999],
            [-42.899858438, -20.400135501],
            [-42.899847533, -20.399959168999899],
            [-42.899837558999899, -20.399797899],
            [-42.899820371, -20.399704432],
            [-42.899781855999898, -20.399489713],
            [-42.899735538999899, -20.399157574999901],
            [-42.899678781, -20.399005594],
            [-42.899561838, -20.398847471],
            [-42.899443694, -20.398644131],
            [-42.899236796, -20.39831493],
            [-42.899253905, -20.398246232],
            [-42.899305279, -20.398174862],
            [-42.899346065, -20.398143661999899],
            [-42.899460387, -20.398029033999901],
            [-42.89958589, -20.397878309],
            [-42.8997148609999, -20.397713972],
            [-42.899796519999903, -20.3975300889999],
            [-42.899830726999902, -20.3973449819999],
            [-42.899844551999898, -20.397158267999899],
            [-42.899830756, -20.397018874],
            [-42.899800656, -20.396841329],
            [-42.899792929999897, -20.3965035589999],
            [-42.900020923999897, -20.396792266],
            [-42.900341762, -20.397241253999901],
            [-42.900423453, -20.397353833999901],
            [-42.900493017, -20.3975300029999],
            [-42.900504473, -20.39762905],
            [-42.900534948, -20.397892532999901],
            [-42.900516986, -20.398302923],
            [-42.900497539, -20.398427820999899],
            [-42.9005494479999, -20.3984926319999],
            [-42.900557318, -20.398500517999899],
            [-42.900695373, -20.397919809],
            [-42.900796797999902, -20.397502503999899],
            [-42.900662117, -20.3959763309999],
            [-42.900727579, -20.395781020999902],
            [-42.901539141999898, -20.394724685],
            [-42.901614885999898, -20.3945950029999],
            [-42.901691743, -20.394393223999899],
            [-42.901696392, -20.394192190999899],
            [-42.901625036, -20.393655639],
            [-42.901455574, -20.393677148999899],
            [-42.901091224, -20.393678828],
            [-42.901072661999898, -20.393684446],
            [-42.900949156, -20.393700301],
            [-42.900610464, -20.393996727999902],
            [-42.900493785, -20.394138911],
            [-42.9002008309999, -20.394378875],
            [-42.899972869, -20.394467018999901],
            [-42.899564615, -20.394599834],
            [-42.899477642, -20.394571373],
            [-42.899227125, -20.394301902999899],
            [-42.899040860999897, -20.394198273],
            [-42.898856542, -20.3941618019999],
            [-42.89873567, -20.394152689999899],
            [-42.898639126, -20.394207848999901],
            [-42.898601923999898, -20.3942636949999],
            [-42.8985922909999, -20.3943838],
            [-42.898518515, -20.3944469529999],
            [-42.8983588789999, -20.394443844],
            [-42.8981504, -20.39457528],
            [-42.898018712, -20.394707124],
            [-42.897932569, -20.394818238],
            [-42.897806381, -20.394837891999899],
            [-42.897624056, -20.394942332],
            [-42.896437730999899, -20.39515067],
            [-42.895903834, -20.395332876999898],
            [-42.896066477, -20.3956331639999],
            [-42.896184574, -20.395885517999901],
            [-42.896418755, -20.3961262199999],
            [-42.896563834999903, -20.3963266989999],
            [-42.896856962, -20.396892339],
            [-42.8969307959999, -20.397181734],
            [-42.896950896, -20.397437587],
            [-42.897082516999902, -20.397609079999899],
            [-42.897211289, -20.397856187999899],
            [-42.897386453, -20.398099801999901],
            [-42.897650573, -20.398658638],
            [-42.897845611, -20.3991484649999],
            [-42.897980455, -20.399680580999899],
            [-42.898154451, -20.400177474],
            [-42.898283474, -20.400486889],
            [-42.89845436, -20.4005431379999],
            [-42.898940282999902, -20.400921269],
            [-42.8992807119999, -20.40113428],
            [-42.899649360999902, -20.401394966999899],
            [-42.899810123999899, -20.401536689999901],
            [-42.899863756999899, -20.401681645999901]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 134,
        "id": 37045,
        "CD_SETOR": "315210505070074P",
        "AREA_KM2": 0.030431,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 27
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.884286335999903, -20.418093911],
            [-42.884313403, -20.418131264],
            [-42.884433707, -20.418310321],
            [-42.884535824999901, -20.4184697929999],
            [-42.884635687999896, -20.418737097],
            [-42.88475198, -20.418714778],
            [-42.884870846999902, -20.418674166],
            [-42.885013050999902, -20.418581488],
            [-42.885143647999897, -20.418483810999899],
            [-42.885257931, -20.418352341999899],
            [-42.885302183, -20.418256708999898],
            [-42.885314368, -20.418117503],
            [-42.885308161, -20.418057169999901],
            [-42.885299156, -20.417969645],
            [-42.885316692999901, -20.417908521],
            [-42.885376393999898, -20.417870354999899],
            [-42.885440263, -20.417860958999899],
            [-42.885541647, -20.417811715],
            [-42.885591835, -20.417733380999898],
            [-42.885637142999897, -20.417651374999899],
            [-42.885755127, -20.417521249999901],
            [-42.885814421, -20.4175590299999],
            [-42.885795637999898, -20.417455683],
            [-42.885812592, -20.417301472],
            [-42.885818002, -20.417244266],
            [-42.885814508, -20.4170286149999],
            [-42.885813492, -20.4169132],
            [-42.885861411999898, -20.4167435919999],
            [-42.88590232, -20.416484258],
            [-42.885904466999897, -20.416303711999898],
            [-42.885880652, -20.416119162],
            [-42.885801778, -20.415938336],
            [-42.885698073, -20.415846621],
            [-42.885630510999903, -20.41563421],
            [-42.885620554, -20.415527687999901],
            [-42.8856248989999, -20.41539956],
            [-42.885664463, -20.4152028409999],
            [-42.885704405999903, -20.415086894999899],
            [-42.885752119, -20.414982998],
            [-42.885762636, -20.414921627999899],
            [-42.885778145, -20.414798991999898],
            [-42.885771986999899, -20.414664063],
            [-42.885708064, -20.414488213],
            [-42.885640908, -20.414303678],
            [-42.885586886999903, -20.4141712959999],
            [-42.885561246, -20.414181721],
            [-42.8855122209999, -20.4141989949999],
            [-42.885478647999904, -20.414210185999899],
            [-42.885226757999902, -20.414300098999899],
            [-42.884711495, -20.414497100999899],
            [-42.884588403999899, -20.414566287],
            [-42.884509377, -20.414627684],
            [-42.88491928, -20.415108916999898],
            [-42.885005495, -20.414948406999901],
            [-42.885046985, -20.414871164],
            [-42.8850766159999, -20.414798033],
            [-42.88510888, -20.414773856],
            [-42.885133878, -20.414769357999901],
            [-42.885171386, -20.4147709339999],
            [-42.885210805, -20.41478196],
            [-42.885253088999903, -20.414806862],
            [-42.885272676999897, -20.414836347999898],
            [-42.885296556, -20.414865125999899],
            [-42.885289778, -20.414918906],
            [-42.885216279, -20.415234787],
            [-42.885211039, -20.415347397999899],
            [-42.885250445999901, -20.415518587999902],
            [-42.885350835999901, -20.415870791],
            [-42.885439280999897, -20.416179147],
            [-42.885493369999899, -20.416373892],
            [-42.885548367, -20.416575676],
            [-42.885586362, -20.416821543],
            [-42.885610051, -20.416973756999901],
            [-42.885579306999901, -20.417118491999901],
            [-42.885466769999901, -20.417241791],
            [-42.885361034, -20.417315075],
            [-42.88519758, -20.417399709999899],
            [-42.884949434, -20.417507382999901],
            [-42.884694652, -20.417617935],
            [-42.884405031, -20.417931671],
            [-42.884286335999903, -20.418093911]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 135,
        "id": 37046,
        "CD_SETOR": "315210505070075P",
        "AREA_KM2": 0.031074,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 160
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.900444397999898, -20.400664423],
            [-42.900474571, -20.400451453],
            [-42.900460537, -20.399908887],
            [-42.900404637999898, -20.399439365],
            [-42.900365437999902, -20.3991101],
            [-42.900361392, -20.398960338999899],
            [-42.900354029, -20.398763232],
            [-42.900382543, -20.3986622799999],
            [-42.900432903, -20.398590638],
            [-42.9004684619999, -20.398521490999901],
            [-42.900497539, -20.398427820999899],
            [-42.900516986, -20.398302923],
            [-42.900534948, -20.397892532999901],
            [-42.900504473, -20.39762905],
            [-42.900493017, -20.3975300029999],
            [-42.900423453, -20.397353833999901],
            [-42.900341762, -20.397241253999901],
            [-42.900020923999897, -20.396792266],
            [-42.899792929999897, -20.3965035589999],
            [-42.899800656, -20.396841329],
            [-42.899830756, -20.397018874],
            [-42.899844551999898, -20.397158267999899],
            [-42.899830726999902, -20.3973449819999],
            [-42.899796519999903, -20.3975300889999],
            [-42.8997148609999, -20.397713972],
            [-42.89958589, -20.397878309],
            [-42.899460387, -20.398029033999901],
            [-42.899346065, -20.398143661999899],
            [-42.899305279, -20.398174862],
            [-42.899253905, -20.398246232],
            [-42.899236796, -20.39831493],
            [-42.899443694, -20.398644131],
            [-42.899561838, -20.398847471],
            [-42.899678781, -20.399005594],
            [-42.899735538999899, -20.399157574999901],
            [-42.899781855999898, -20.399489713],
            [-42.899820371, -20.399704432],
            [-42.899837558999899, -20.399797899],
            [-42.899847533, -20.399959168999899],
            [-42.899858438, -20.400135501],
            [-42.900001042, -20.4002775169999],
            [-42.900191563, -20.400459272],
            [-42.900444397999898, -20.400664423]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 136,
        "id": 37047,
        "CD_SETOR": "315210505070076P",
        "AREA_KM2": 0.476614,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 283
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.895903834, -20.395332876999898],
            [-42.895786723999898, -20.395120036],
            [-42.895171217, -20.394616439999901],
            [-42.894734766999903, -20.394627630999899],
            [-42.893951394999903, -20.394582866999901],
            [-42.893324697, -20.394750732],
            [-42.892317503999898, -20.395108845],
            [-42.8911760189999, -20.395500531],
            [-42.889855478, -20.3957131609999],
            [-42.889161633999898, -20.3957131609999],
            [-42.888622819, -20.395502724],
            [-42.888920450999898, -20.395952071],
            [-42.889037119, -20.396147685],
            [-42.889322034, -20.3967263709999],
            [-42.889512176, -20.397362014999899],
            [-42.889869898, -20.397853559999898],
            [-42.890348482, -20.398314441],
            [-42.890792741, -20.398595008],
            [-42.891216965, -20.398862922],
            [-42.891801148999903, -20.399356860999902],
            [-42.891904116, -20.39945653],
            [-42.8920315989999, -20.39964731],
            [-42.892122097, -20.399782742],
            [-42.892363066, -20.400007495],
            [-42.892584384, -20.400251214],
            [-42.892954480999897, -20.400725135],
            [-42.893209484, -20.400944747],
            [-42.893454345999899, -20.401128749999899],
            [-42.893984948, -20.401526732],
            [-42.894056761999899, -20.4016109629999],
            [-42.894742515, -20.401138826],
            [-42.894678432, -20.4010661259999],
            [-42.8946175519999, -20.400961428999899],
            [-42.894575374999903, -20.400859211999901],
            [-42.894532145, -20.400750409],
            [-42.8945001559999, -20.4006698929999],
            [-42.894473409999897, -20.400509316999901],
            [-42.894466837, -20.40033419],
            [-42.894500660999903, -20.400215526],
            [-42.894554236, -20.400166982],
            [-42.894590729999898, -20.400118482],
            [-42.894616309999897, -20.399961828999899],
            [-42.894598970999901, -20.399876529],
            [-42.894521111, -20.399761473999899],
            [-42.894359139, -20.399595652],
            [-42.894451997, -20.399328706999899],
            [-42.894490011, -20.399228014999899],
            [-42.894642674999901, -20.399283823],
            [-42.894753889, -20.399264926999901],
            [-42.894966052, -20.39916704],
            [-42.895145849, -20.39912757],
            [-42.895336479, -20.399167615],
            [-42.895502495999899, -20.3991658059999],
            [-42.895639098999901, -20.399262096],
            [-42.895647734, -20.399387256999901],
            [-42.895614416999898, -20.399512260999899],
            [-42.895576573, -20.399590916],
            [-42.895521362, -20.399662552999899],
            [-42.895471729, -20.3997387699999],
            [-42.89542431, -20.399778177],
            [-42.895384922999902, -20.399842516],
            [-42.895361556, -20.399902699999899],
            [-42.895344762, -20.399959096],
            [-42.895347612, -20.400037003],
            [-42.895372838, -20.400155898],
            [-42.895407501, -20.400245246999901],
            [-42.895416887, -20.4003216629999],
            [-42.895445843999902, -20.400324415999901],
            [-42.895522254, -20.400388006999901],
            [-42.895663391, -20.400531391999898],
            [-42.896233229, -20.400926706],
            [-42.896320776, -20.401397073999899],
            [-42.896458262, -20.402063566],
            [-42.896135969999897, -20.402332215999898],
            [-42.896049982, -20.402433365],
            [-42.896266737, -20.402546727],
            [-42.8963525509999, -20.402575729],
            [-42.896459568999902, -20.4026118969999],
            [-42.896704091999901, -20.402608926],
            [-42.896882868, -20.4025316349999],
            [-42.89702255, -20.402431433999901],
            [-42.897152444, -20.402338256],
            [-42.89721036, -20.402296708999899],
            [-42.897301076, -20.402251446],
            [-42.897589925, -20.402130642],
            [-42.897862937999903, -20.401970853],
            [-42.898082202999902, -20.4017992509999],
            [-42.898212097999902, -20.401697591999898],
            [-42.898359980999899, -20.401606224],
            [-42.898580776, -20.401555726],
            [-42.898998918, -20.401549064999902],
            [-42.89942543, -20.401602004],
            [-42.8996957479999, -20.401688708999899],
            [-42.899863756999899, -20.401681645999901],
            [-42.899810123999899, -20.401536689999901],
            [-42.899649360999902, -20.401394966999899],
            [-42.8992807119999, -20.40113428],
            [-42.898940282999902, -20.400921269],
            [-42.89845436, -20.4005431379999],
            [-42.898283474, -20.400486889],
            [-42.898154451, -20.400177474],
            [-42.897980455, -20.399680580999899],
            [-42.897845611, -20.3991484649999],
            [-42.897650573, -20.398658638],
            [-42.897386453, -20.398099801999901],
            [-42.897211289, -20.397856187999899],
            [-42.897082516999902, -20.397609079999899],
            [-42.896950896, -20.397437587],
            [-42.8969307959999, -20.397181734],
            [-42.896856962, -20.396892339],
            [-42.896563834999903, -20.3963266989999],
            [-42.896418755, -20.3961262199999],
            [-42.896184574, -20.395885517999901],
            [-42.896066477, -20.3956331639999],
            [-42.895903834, -20.395332876999898]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 137,
        "id": 37048,
        "CD_SETOR": "315210505070077P",
        "AREA_KM2": 32.123176,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210505",
        "NM_DIST": "Ponte Nova",
        "CD_SUBDIST": "31521050507",
        "NM_SUBDIST": "2 Sub-Distrito",
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 349
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.888622819, -20.395502724],
            [-42.889161633999898, -20.3957131609999],
            [-42.889855478, -20.3957131609999],
            [-42.8911760189999, -20.395500531],
            [-42.892317503999898, -20.395108845],
            [-42.893324697, -20.394750732],
            [-42.893951394999903, -20.394582866999901],
            [-42.894734766999903, -20.394627630999899],
            [-42.895171217, -20.394616439999901],
            [-42.895786723999898, -20.395120036],
            [-42.895903834, -20.395332876999898],
            [-42.896437730999899, -20.39515067],
            [-42.897624056, -20.394942332],
            [-42.897806381, -20.394837891999899],
            [-42.897932569, -20.394818238],
            [-42.898018712, -20.394707124],
            [-42.8981504, -20.39457528],
            [-42.8983588789999, -20.394443844],
            [-42.898518515, -20.3944469529999],
            [-42.8985922909999, -20.3943838],
            [-42.898601923999898, -20.3942636949999],
            [-42.898639126, -20.394207848999901],
            [-42.89873567, -20.394152689999899],
            [-42.898856542, -20.3941618019999],
            [-42.899040860999897, -20.394198273],
            [-42.899227125, -20.394301902999899],
            [-42.899477642, -20.394571373],
            [-42.899564615, -20.394599834],
            [-42.899972869, -20.394467018999901],
            [-42.9002008309999, -20.394378875],
            [-42.900493785, -20.394138911],
            [-42.900610464, -20.393996727999902],
            [-42.900949156, -20.393700301],
            [-42.901072661999898, -20.393684446],
            [-42.901091224, -20.393678828],
            [-42.901455574, -20.393677148999899],
            [-42.901625036, -20.393655639],
            [-42.902857648, -20.393517367],
            [-42.902843794, -20.3931849639999],
            [-42.902732284, -20.392583746],
            [-42.902602475, -20.392172834999901],
            [-42.902499377, -20.391879726],
            [-42.90243988, -20.391416707],
            [-42.902446364, -20.390836600999901],
            [-42.9027868599999, -20.389653037],
            [-42.902864173, -20.3893941329999],
            [-42.902905404, -20.389135887],
            [-42.903012267, -20.3887058769999],
            [-42.903048299, -20.387774820999901],
            [-42.902952531, -20.387326049],
            [-42.902778063, -20.387040425],
            [-42.902556848, -20.386582027],
            [-42.902385572, -20.386093498],
            [-42.902339074999901, -20.385749315999899],
            [-42.902303182, -20.3852970149999],
            [-42.902261118999903, -20.3849824559999],
            [-42.90234773, -20.3836692419999],
            [-42.902348321, -20.38363691],
            [-42.901953896, -20.383622283],
            [-42.901938464, -20.383526844],
            [-42.901904348, -20.3834583249999],
            [-42.901846358999897, -20.383420015999899],
            [-42.901895982, -20.383298924],
            [-42.901916206, -20.383249572],
            [-42.901960485, -20.383141518999899],
            [-42.902034879, -20.382878350999899],
            [-42.902113113, -20.382604593999901],
            [-42.902234881, -20.3821762339999],
            [-42.902281340999899, -20.3819219539999],
            [-42.902313449, -20.381420675999902],
            [-42.902249331999897, -20.380991209999902],
            [-42.902117825999902, -20.380602004],
            [-42.9020423159999, -20.3804825399999],
            [-42.901994143, -20.380389382],
            [-42.901746626, -20.379910724999899],
            [-42.901696894999901, -20.379685507],
            [-42.901683205, -20.379589091],
            [-42.901627593999898, -20.378938865],
            [-42.901621878, -20.378874378],
            [-42.901534098, -20.378644382999902],
            [-42.901408300999897, -20.378459248],
            [-42.901336052, -20.3783685669999],
            [-42.901242636999903, -20.378290331],
            [-42.900922937, -20.378100568],
            [-42.900664127, -20.377958448999902],
            [-42.9004919079999, -20.377851652999901],
            [-42.900125894, -20.377630369],
            [-42.899661804999901, -20.377358156],
            [-42.899204836, -20.3770956279999],
            [-42.898691436, -20.376799431999899],
            [-42.898452657999897, -20.376659543],
            [-42.897999419, -20.376391778],
            [-42.897068772, -20.375855448],
            [-42.896906492, -20.375759214],
            [-42.896604690999901, -20.375507222],
            [-42.896456457999903, -20.375308253999901],
            [-42.896342707, -20.375155569999901],
            [-42.896056651, -20.37462385],
            [-42.895848144, -20.374253721999899],
            [-42.895709407, -20.374007443],
            [-42.895547731, -20.373720445999901],
            [-42.895412671, -20.373480695],
            [-42.895341515, -20.373354382999899],
            [-42.895179169, -20.3731928999999],
            [-42.895031659, -20.373042853999898],
            [-42.894846264, -20.3728346],
            [-42.894749493999903, -20.372707236999901],
            [-42.894685011, -20.372567858],
            [-42.894672682, -20.372530847999901],
            [-42.895246454999899, -20.372517027999901],
            [-42.895243031999897, -20.372254332],
            [-42.895400591999902, -20.371870031999901],
            [-42.896220957, -20.3679551819999],
            [-42.896274574, -20.367753788],
            [-42.895907982, -20.367563651],
            [-42.895905816, -20.367562946],
            [-42.895841855999898, -20.367552229999902],
            [-42.895924555, -20.367255314],
            [-42.896009684, -20.3669912679999],
            [-42.896134908, -20.366706581],
            [-42.896292452, -20.366455071],
            [-42.896551002, -20.366167885],
            [-42.897097045999899, -20.365735045],
            [-42.897541716, -20.365406633],
            [-42.897863535999903, -20.365168952],
            [-42.898087668999899, -20.364992788999899],
            [-42.898390535, -20.364724623],
            [-42.898657987, -20.364408521],
            [-42.899197562, -20.36370046],
            [-42.899437491999898, -20.363382353],
            [-42.899760949, -20.362952967999899],
            [-42.900064025999903, -20.362553815],
            [-42.900329109, -20.362206344999901],
            [-42.900602595999899, -20.361848831],
            [-42.9008981859999, -20.361451026999902],
            [-42.901119047999899, -20.361043066],
            [-42.901213399, -20.360445291],
            [-42.901205463999901, -20.359915116],
            [-42.901070013, -20.359516724999899],
            [-42.90093006, -20.359304774999899],
            [-42.900491807, -20.358747541],
            [-42.900243025999899, -20.358324529999901],
            [-42.900132425, -20.3577920819999],
            [-42.900109203999897, -20.357199206],
            [-42.900103337, -20.356988651999899],
            [-42.900069672, -20.355939442],
            [-42.900051006, -20.355319236],
            [-42.900013215999898, -20.354386711],
            [-42.899909943, -20.353596469],
            [-42.899790998, -20.3529277129999],
            [-42.899666822, -20.352236603999899],
            [-42.899557673999901, -20.351614642],
            [-42.899552383, -20.3512404589999],
            [-42.899596201999898, -20.351009765],
            [-42.899711249999903, -20.350696828],
            [-42.899944737999903, -20.350332742],
            [-42.900239902, -20.350014988999899],
            [-42.900483583, -20.349723404],
            [-42.900604087, -20.349466456],
            [-42.90064131, -20.349111416],
            [-42.900550334999899, -20.3488075879999],
            [-42.90036164, -20.348490023],
            [-42.900040073999897, -20.3480395569999],
            [-42.899549381, -20.347349414],
            [-42.89940417, -20.34714263],
            [-42.899244885, -20.3468215259999],
            [-42.89916597, -20.34664535],
            [-42.899066086999902, -20.346249529],
            [-42.89906386, -20.346156098999899],
            [-42.899113843999899, -20.345991985],
            [-42.89914764, -20.345857748],
            [-42.8992547779999, -20.3455079679999],
            [-42.899128209, -20.345470787999901],
            [-42.898673787999897, -20.344947654],
            [-42.897947, -20.343853996],
            [-42.897595, -20.343151996],
            [-42.897239788, -20.342416995999901],
            [-42.89718005, -20.341744361999901],
            [-42.897488, -20.341083996],
            [-42.897928, -20.340360996],
            [-42.898232999999898, -20.339705996],
            [-42.898601475, -20.338660995999899],
            [-42.89876, -20.337558995999899],
            [-42.898859, -20.336524996],
            [-42.898726, -20.335708995999902],
            [-42.898459, -20.334949996],
            [-42.898281212, -20.3334752929999],
            [-42.898360162, -20.332111996],
            [-42.898217108, -20.330839699],
            [-42.898092004, -20.329476617999902],
            [-42.89798495, -20.3283888889999],
            [-42.897920053999897, -20.327813996],
            [-42.897713, -20.327384996],
            [-42.897326, -20.327330995999901],
            [-42.897058, -20.327295996],
            [-42.89629, -20.327294996],
            [-42.896035, -20.327283995999899],
            [-42.895511, -20.327282996],
            [-42.895182, -20.327316996],
            [-42.894925999999899, -20.327270996],
            [-42.894695, -20.327223995999901],
            [-42.894450999999897, -20.327189996],
            [-42.892746, -20.327186996],
            [-42.892307, -20.327232996],
            [-42.892039, -20.327335995999899],
            [-42.891796, -20.327403995999902],
            [-42.891588, -20.327552996],
            [-42.89132, -20.327644995999901],
            [-42.891089, -20.327747996],
            [-42.890869, -20.327885996],
            [-42.890638, -20.3279659959999],
            [-42.890394, -20.327999996],
            [-42.890149999999899, -20.328102995999899],
            [-42.889930999999898, -20.328182996],
            [-42.889675, -20.328285996],
            [-42.889443, -20.328365995999899],
            [-42.889236, -20.328468996],
            [-42.889017, -20.3285499959999],
            [-42.888821999999898, -20.328675996],
            [-42.888578, -20.328835996],
            [-42.888358, -20.328996995999901],
            [-42.888054, -20.3289959959999],
            [-42.88781, -20.328961996],
            [-42.887579, -20.329052996],
            [-42.887323, -20.329121996],
            [-42.887090999999899, -20.329258996],
            [-42.886872, -20.3293619959999],
            [-42.886433, -20.329545996],
            [-42.886164999999899, -20.329613996],
            [-42.885897, -20.329647995999899],
            [-42.885276, -20.329646996],
            [-42.885032, -20.329669996],
            [-42.884751999999899, -20.329680996],
            [-42.88351, -20.329678995999899],
            [-42.883229, -20.329747996],
            [-42.882925, -20.329816996],
            [-42.882511, -20.329803996],
            [-42.881706999999899, -20.329802996],
            [-42.881475, -20.329814996],
            [-42.881244, -20.329894996],
            [-42.881024, -20.329997996],
            [-42.880793, -20.330042996],
            [-42.880536999999897, -20.330088996],
            [-42.88033, -20.330272996],
            [-42.880074, -20.330444996],
            [-42.879817, -20.330638995999902],
            [-42.8795, -20.330845995999901],
            [-42.879293, -20.331005995999899],
            [-42.879037, -20.331235996],
            [-42.878842, -20.331361995999899],
            [-42.878634, -20.331533996],
            [-42.87839, -20.331843996],
            [-42.87828, -20.332038995999898],
            [-42.87811, -20.332222995999899],
            [-42.877914, -20.332394995999898],
            [-42.877719, -20.332543996],
            [-42.877536, -20.332692995999899],
            [-42.877341, -20.332864996],
            [-42.877122, -20.333047995999902],
            [-42.876914, -20.333219996],
            [-42.8765, -20.333610995999901],
            [-42.876292, -20.3337479959999],
            [-42.876, -20.333793995999901],
            [-42.875768, -20.333781996],
            [-42.875488, -20.333734996],
            [-42.875196, -20.333642996],
            [-42.874941, -20.333573995999899],
            [-42.874709, -20.333492996],
            [-42.874440999999898, -20.333400996],
            [-42.873832, -20.333399996],
            [-42.873539999999899, -20.333433995999901],
            [-42.873345, -20.333594995999899],
            [-42.873101, -20.333708995999899],
            [-42.872845, -20.333788996],
            [-42.872601, -20.3338579959999],
            [-42.872369999999897, -20.333925995999898],
            [-42.872114, -20.334028996],
            [-42.871893999999898, -20.334120996],
            [-42.871663, -20.3342009959999],
            [-42.871346, -20.334211996],
            [-42.871078, -20.334176995999901],
            [-42.870798, -20.334141996],
            [-42.870542, -20.334198995999898],
            [-42.870311, -20.334256996],
            [-42.870066999999899, -20.334324996],
            [-42.86986, -20.3344739959999],
            [-42.869689, -20.334622996],
            [-42.869445, -20.334875995999901],
            [-42.869262, -20.335070996],
            [-42.869067, -20.335219996],
            [-42.868884, -20.335402996],
            [-42.868713, -20.335563996],
            [-42.868591, -20.335758996],
            [-42.868444, -20.335988996],
            [-42.868213, -20.336102996],
            [-42.867993, -20.336274995999901],
            [-42.867786, -20.336423996],
            [-42.867603, -20.3365849959999],
            [-42.867396, -20.336687996],
            [-42.867164, -20.336721995999898],
            [-42.866335999999897, -20.336720995999901],
            [-42.866068, -20.336708996],
            [-42.8658, -20.3367199959999],
            [-42.864789, -20.336718995999899],
            [-42.864472, -20.336694995999899],
            [-42.864241, -20.336648996],
            [-42.863997, -20.336613996],
            [-42.863729, -20.336578996],
            [-42.8634, -20.336578996],
            [-42.86312, -20.336589995999901],
            [-42.862864, -20.336600996],
            [-42.862572, -20.336622996],
            [-42.862243, -20.336656995999899],
            [-42.862, -20.336644996],
            [-42.861476, -20.336644996],
            [-42.861208, -20.3366789959999],
            [-42.859088, -20.336675995999901],
            [-42.858857, -20.336663996],
            [-42.858601, -20.3366059959999],
            [-42.858394, -20.336490996],
            [-42.858151, -20.336374996],
            [-42.85792, -20.336190996],
            [-42.857725, -20.335983996],
            [-42.857567, -20.3357769959999],
            [-42.857434, -20.335546995999898],
            [-42.857288, -20.335305995999899],
            [-42.857203, -20.335086996],
            [-42.857094, -20.334891996],
            [-42.856972, -20.334672995999899],
            [-42.856827, -20.334419996],
            [-42.856681, -20.334201995999901],
            [-42.856547, -20.333959996],
            [-42.856414, -20.333683995999898],
            [-42.856304, -20.333488996],
            [-42.856171, -20.333258995999898],
            [-42.856001, -20.333005995999901],
            [-42.855843, -20.332763996],
            [-42.855685, -20.332602995999899],
            [-42.855235, -20.332176995999902],
            [-42.855027999999898, -20.3320159959999],
            [-42.854736, -20.3318779959999],
            [-42.854505, -20.331796996],
            [-42.854237, -20.331727995999898],
            [-42.853993, -20.331588995999901],
            [-42.853787, -20.331462996],
            [-42.853531, -20.3314049959999],
            [-42.853299999999898, -20.331335996],
            [-42.853056, -20.3312319959999],
            [-42.85274, -20.331070995999902],
            [-42.852411, -20.3308859959999],
            [-42.852156, -20.3307129959999],
            [-42.851912, -20.330563996],
            [-42.85173, -20.330413995999901],
            [-42.851498999999897, -20.330321996],
            [-42.851194, -20.330366996],
            [-42.850938, -20.330320996],
            [-42.850658, -20.330251996],
            [-42.850269, -20.330158996],
            [-42.849989, -20.330043996],
            [-42.849684, -20.3299629959999],
            [-42.849441, -20.329858995999899],
            [-42.849234, -20.329754996],
            [-42.848918, -20.329535995999901],
            [-42.848661999999898, -20.329547996],
            [-42.848406, -20.329592996],
            [-42.84815, -20.329615996],
            [-42.847821, -20.329637996],
            [-42.847566, -20.329671995999899],
            [-42.847260999999897, -20.329705996],
            [-42.847005, -20.329774996],
            [-42.846774, -20.329796996],
            [-42.846493, -20.329853995999901],
            [-42.846152, -20.3298769959999],
            [-42.845908999999899, -20.329898995999901],
            [-42.845616, -20.3299219959999],
            [-42.845385, -20.329932996],
            [-42.845129, -20.329920995999899],
            [-42.84486378, -20.329920995999899],
            [-42.844235837, -20.330757705999901],
            [-42.843875792999903, -20.331516964999899],
            [-42.843915415, -20.332721966],
            [-42.8441832, -20.333355524999899],
            [-42.844571103, -20.333894874],
            [-42.846031991, -20.334962138],
            [-42.84944669, -20.337128371999899],
            [-42.851443849, -20.3387094889999],
            [-42.8534440029999, -20.340736364],
            [-42.8547867139999, -20.343272634],
            [-42.8551948369999, -20.344131505999901],
            [-42.856115254, -20.346873801999902],
            [-42.856041026, -20.348152684],
            [-42.855625689, -20.350170066],
            [-42.855695511, -20.350591299],
            [-42.855842659, -20.350891083],
            [-42.856139065, -20.3510403289999],
            [-42.856510122, -20.351297702999901],
            [-42.856916326, -20.351645485999899],
            [-42.857051672999901, -20.351736844999898],
            [-42.857084402999902, -20.351844849],
            [-42.857123562, -20.3520492339999],
            [-42.857243153, -20.352395542],
            [-42.857466691999903, -20.352641293],
            [-42.858003145999902, -20.353054997999902],
            [-42.858193963, -20.353072509],
            [-42.858303728, -20.353022526],
            [-42.858972061, -20.352485135999899],
            [-42.859265638999901, -20.352278284],
            [-42.859445798, -20.352276522],
            [-42.859541873999902, -20.352353575999899],
            [-42.859701395999899, -20.353212454999898],
            [-42.859685274, -20.3535246329999],
            [-42.85970213, -20.353653418],
            [-42.859759241999903, -20.353799409],
            [-42.859749504, -20.353945363],
            [-42.859682952, -20.354127214],
            [-42.859648485, -20.3542658759999],
            [-42.859676604999898, -20.354413361999899],
            [-42.85970474, -20.35449626],
            [-42.859696418999903, -20.354640345],
            [-42.85964016, -20.354974887],
            [-42.859547979999903, -20.35505445],
            [-42.859391204, -20.355095032],
            [-42.859137057999902, -20.355259128999901],
            [-42.858725276, -20.355589118999902],
            [-42.858387854999897, -20.355657360999899],
            [-42.858282647, -20.355774159],
            [-42.858269013, -20.355875746],
            [-42.858339674, -20.355981153999899],
            [-42.858493553, -20.356093095999899],
            [-42.858577006, -20.356350022],
            [-42.858553714, -20.356522481],
            [-42.85840015, -20.356612866],
            [-42.858257422, -20.356589148999898],
            [-42.85803243, -20.356481178],
            [-42.857806695, -20.3564867599999],
            [-42.857560395999897, -20.356660409],
            [-42.857515862999897, -20.356851203],
            [-42.857316164999901, -20.356994539],
            [-42.856934549, -20.356965099],
            [-42.856616577, -20.357075645999899],
            [-42.856542245, -20.3573611099999],
            [-42.856404851, -20.357553321999902],
            [-42.856107998, -20.357541787999899],
            [-42.855918651, -20.357205704],
            [-42.855524414999898, -20.3569484299999],
            [-42.854867188999897, -20.3568534579999],
            [-42.854645075, -20.356663679999901],
            [-42.85408681, -20.3567106409999],
            [-42.853823627999901, -20.357050961],
            [-42.853589892999899, -20.357080039],
            [-42.853371056, -20.357272671],
            [-42.853382398999898, -20.357474322],
            [-42.853278573, -20.357845509999901],
            [-42.853034828, -20.3579154409999],
            [-42.852726214, -20.358101161],
            [-42.852557567, -20.35832353],
            [-42.852450843, -20.3585887529999],
            [-42.852409979999898, -20.358727248],
            [-42.852259945999897, -20.359111514999899],
            [-42.852303682, -20.359381238],
            [-42.852179045, -20.359710017999902],
            [-42.852034826, -20.36013774],
            [-42.852080311999899, -20.360495688],
            [-42.851949614, -20.3607741779999],
            [-42.851688951, -20.361260388999899],
            [-42.851650821, -20.361552076],
            [-42.85169303, -20.3618703029999],
            [-42.8515932829999, -20.362216154],
            [-42.851345124, -20.362252892],
            [-42.8511171, -20.362393602999902],
            [-42.85108201, -20.362608336999902],
            [-42.851207196, -20.362761566],
            [-42.851307592999902, -20.363007496],
            [-42.851254534, -20.363487377999899],
            [-42.851030109, -20.364249279],
            [-42.851047977, -20.3648279619999],
            [-42.85103365, -20.365008293],
            [-42.850946289, -20.36518238],
            [-42.851314037999899, -20.365149985],
            [-42.851422966, -20.365154151999899],
            [-42.851460276999902, -20.365324606999899],
            [-42.851539294, -20.365513888],
            [-42.851600276999903, -20.3656696699999],
            [-42.851712595, -20.365936212],
            [-42.851845414, -20.366214592999899],
            [-42.852019002, -20.366527602],
            [-42.852263354999899, -20.3668863309999],
            [-42.852474608, -20.367062878],
            [-42.852602082, -20.36716941],
            [-42.852881753, -20.367463385999901],
            [-42.852987841, -20.367660618999899],
            [-42.853015534999898, -20.367773449],
            [-42.853031003, -20.367861981],
            [-42.852981117999903, -20.368065719999901],
            [-42.852905451, -20.368233792],
            [-42.852872203999901, -20.368307640999902],
            [-42.852790356, -20.3684422269999],
            [-42.852677043, -20.368567504999898],
            [-42.852557615, -20.368687286],
            [-42.852460418, -20.368765249],
            [-42.852412436999899, -20.368803736],
            [-42.852303588, -20.368852623],
            [-42.851743036999899, -20.368934414],
            [-42.851599907, -20.36896466],
            [-42.851216902, -20.369062111999899],
            [-42.850884826, -20.369167262],
            [-42.850471537, -20.369301263999901],
            [-42.850258735999901, -20.369369273],
            [-42.850274011, -20.369899577999899],
            [-42.850321720999901, -20.3702997779999],
            [-42.85040227, -20.37081032],
            [-42.850303235999903, -20.371150464],
            [-42.85014903, -20.371584647],
            [-42.849947588, -20.37186797],
            [-42.84960591, -20.371994792],
            [-42.849404551, -20.37191344],
            [-42.8492449519999, -20.371524731],
            [-42.848955428999901, -20.3711358649999],
            [-42.8488646019999, -20.37089759],
            [-42.8478619159999, -20.370815795999899],
            [-42.847188322, -20.371254275],
            [-42.847094287999901, -20.37223863],
            [-42.847346901, -20.373148946999901],
            [-42.847612196999897, -20.374059422],
            [-42.847713518, -20.37472138],
            [-42.847969342999903, -20.375062242],
            [-42.848007454, -20.375519395],
            [-42.847866799, -20.376026915],
            [-42.848334420999898, -20.376386133],
            [-42.849274624, -20.375891162],
            [-42.852723216999898, -20.373350275],
            [-42.8533442579999, -20.3731181429999],
            [-42.853664986, -20.372996838999899],
            [-42.85424261, -20.372935750999901],
            [-42.854924516, -20.372889781],
            [-42.855209045, -20.372852069],
            [-42.855311902, -20.372867422],
            [-42.85543925, -20.37296205],
            [-42.855614925999902, -20.3731433959999],
            [-42.855973138, -20.373570722999901],
            [-42.856364004999897, -20.373964868],
            [-42.856890259999901, -20.37447076],
            [-42.857529751, -20.374930128],
            [-42.857868058, -20.375154511999899],
            [-42.85814045, -20.375317593],
            [-42.858363477999902, -20.375438315999901],
            [-42.858447216999899, -20.3755700799999],
            [-42.858580904, -20.3759882169999],
            [-42.857205609999902, -20.377615529],
            [-42.856452983999901, -20.378416412],
            [-42.855888045, -20.378759172999899],
            [-42.855147911, -20.3790518509999],
            [-42.85468507, -20.3791266919999],
            [-42.854281210999901, -20.379262003999902],
            [-42.8541505439999, -20.3794323669999],
            [-42.852664416, -20.3810695449999],
            [-42.853388478999896, -20.381561349],
            [-42.853740377, -20.381800369],
            [-42.854156047999901, -20.382244058],
            [-42.8548912259999, -20.383186031],
            [-42.855349449, -20.384101201],
            [-42.856547534999898, -20.3862298929999],
            [-42.856736403, -20.386648661],
            [-42.856827766, -20.3869174],
            [-42.857074752, -20.387681788],
            [-42.857178777999899, -20.387903639999902],
            [-42.857318980999899, -20.388114643999899],
            [-42.857540247, -20.388348456],
            [-42.858176132, -20.388757531],
            [-42.858411503999903, -20.388957689999899],
            [-42.858911039, -20.389472408],
            [-42.859051463999897, -20.389566986],
            [-42.859241415, -20.389680848],
            [-42.859614470999901, -20.389824783999899],
            [-42.860526094, -20.390091374999901],
            [-42.861030302, -20.390242757],
            [-42.861498261, -20.390410980999899],
            [-42.861700888, -20.390581835],
            [-42.861985352, -20.390869060999901],
            [-42.862412224, -20.391389688999901],
            [-42.863551574, -20.392715418],
            [-42.863636922, -20.392815723999899],
            [-42.863990015, -20.3930392779999],
            [-42.864334949, -20.393082692],
            [-42.864594069, -20.393056768999902],
            [-42.864905639, -20.392910151],
            [-42.865261892, -20.392742505999902],
            [-42.865696498, -20.392601768],
            [-42.866006646, -20.392570518],
            [-42.866498315999898, -20.392628835],
            [-42.86690528, -20.392750851999899],
            [-42.867256168, -20.3929408139999],
            [-42.867597571, -20.393176867],
            [-42.867979815, -20.3935866919999],
            [-42.868830235, -20.3945521549999],
            [-42.8690184, -20.394754831999901],
            [-42.869214518, -20.394965316],
            [-42.869344449, -20.395088925],
            [-42.869475915999899, -20.395187681],
            [-42.869703511999901, -20.3953458619999],
            [-42.869953897999899, -20.395494689],
            [-42.870135137, -20.395559929999902],
            [-42.870384999999899, -20.395635],
            [-42.870584944, -20.3956919089999],
            [-42.870693107, -20.395718561],
            [-42.871193794, -20.39586453],
            [-42.87131126, -20.395216999999899],
            [-42.8714588019999, -20.394506086],
            [-42.871550127, -20.394064881],
            [-42.871560170999899, -20.3938284299999],
            [-42.871523285, -20.393599815999899],
            [-42.871467005999897, -20.3934577059999],
            [-42.871369484999903, -20.393299022],
            [-42.871225801, -20.393153814],
            [-42.87106747, -20.393014567],
            [-42.870797196999902, -20.392812943],
            [-42.870576777999901, -20.392676045],
            [-42.870338402, -20.392463396],
            [-42.870147457, -20.3922581849999],
            [-42.870028116, -20.392119646],
            [-42.869963534, -20.391993795],
            [-42.869873971999901, -20.391759108],
            [-42.8698186559999, -20.391591024],
            [-42.869777518, -20.391182525],
            [-42.869820513, -20.390850163],
            [-42.869941204, -20.390533986],
            [-42.870193082, -20.390142727999901],
            [-42.870915728999897, -20.389429041],
            [-42.871271475999897, -20.389006424999899],
            [-42.871446402999901, -20.388656868999899],
            [-42.871567415, -20.388255811],
            [-42.871575431999901, -20.387930035999901],
            [-42.872243624, -20.387882729],
            [-42.873148801999903, -20.388557284999901],
            [-42.8736913279999, -20.388750002999899],
            [-42.875296337, -20.388713056],
            [-42.876578596999899, -20.389008880999899],
            [-42.878181018, -20.38969513],
            [-42.879022074, -20.3900929069999],
            [-42.881103707, -20.390984257],
            [-42.882603853, -20.3917993109999],
            [-42.882988671, -20.392521368],
            [-42.883435333, -20.393402847999901],
            [-42.883550439, -20.393608155],
            [-42.884397693, -20.394038484],
            [-42.885238301, -20.394447306],
            [-42.885699839, -20.3946930389999],
            [-42.886075801999901, -20.394790999],
            [-42.886308058999902, -20.394786465],
            [-42.886766778, -20.394717963999899],
            [-42.887213974, -20.394770347],
            [-42.887966751, -20.395019835],
            [-42.888622819, -20.395502724]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 138,
        "id": 37049,
        "CD_SETOR": "315210513000003P",
        "AREA_KM2": 33.823645,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210513",
        "NM_DIST": "Rosário do Pontal",
        "CD_SUBDIST": "31521051300",
        "NM_SUBDIST": null,
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 350
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.914676, -20.276283996],
            [-42.914258, -20.276304996],
            [-42.913736, -20.276350996],
            [-42.912959999999899, -20.276359995999901],
            [-42.912729, -20.276370996],
            [-42.912486, -20.276381995999898],
            [-42.912242, -20.276404995999901],
            [-42.911876999999897, -20.276404995999901],
            [-42.911572, -20.276449995999901],
            [-42.911256, -20.276518995999901],
            [-42.910963, -20.276586995999899],
            [-42.910695, -20.276609995999902],
            [-42.910403, -20.276655995999899],
            [-42.910159, -20.276723995999902],
            [-42.909855, -20.276872996],
            [-42.90955, -20.2769879959999],
            [-42.909258, -20.277124995999898],
            [-42.908709, -20.277434996],
            [-42.908405, -20.277560996],
            [-42.908184999999897, -20.2776869959999],
            [-42.907941999999899, -20.277835996],
            [-42.907686, -20.277789996],
            [-42.907308999999898, -20.277811996],
            [-42.907077, -20.2778699959999],
            [-42.90676, -20.277949996],
            [-42.906407, -20.278029995999901],
            [-42.906078, -20.278143996],
            [-42.905749, -20.278246996],
            [-42.905469, -20.278292995999902],
            [-42.905127999999898, -20.2784989959999],
            [-42.904896999999899, -20.2786369959999],
            [-42.904641, -20.278727995999901],
            [-42.90408, -20.278991995999899],
            [-42.903885, -20.279129995999899],
            [-42.903532, -20.279461996],
            [-42.903433999999898, -20.279680996],
            [-42.903373, -20.279921995999899],
            [-42.903371999999898, -20.280219996],
            [-42.903384, -20.2804619959999],
            [-42.903456999999896, -20.2807489959999],
            [-42.903528999999899, -20.281001995999901],
            [-42.903627, -20.281219995999901],
            [-42.903771999999897, -20.281530996],
            [-42.903869, -20.281748996],
            [-42.904015, -20.281955996],
            [-42.904173, -20.282162996],
            [-42.904319, -20.282335996],
            [-42.904502, -20.282473996],
            [-42.904696, -20.282599995999899],
            [-42.90494, -20.282738996],
            [-42.905256, -20.282784996],
            [-42.905572999999897, -20.282865995999899],
            [-42.905901, -20.2829119959999],
            [-42.906180999999897, -20.282923996],
            [-42.906425, -20.2829359959999],
            [-42.906655999999899, -20.282924996],
            [-42.9069, -20.2828669959999],
            [-42.90718, -20.282798995999901],
            [-42.907533, -20.2826839959999],
            [-42.907899, -20.282661995999899],
            [-42.908179, -20.282592995999899],
            [-42.908423, -20.282466996],
            [-42.908715, -20.282363996],
            [-42.908983, -20.282294996],
            [-42.909348, -20.282249996],
            [-42.909665, -20.282249996],
            [-42.909895999999897, -20.282284995999898],
            [-42.910187999999899, -20.282767996],
            [-42.910235999999898, -20.283008996],
            [-42.91026, -20.283284996],
            [-42.910296, -20.283502996],
            [-42.910333, -20.283790995999901],
            [-42.910369, -20.284065996],
            [-42.91049, -20.284330995999898],
            [-42.910624, -20.284537995999901],
            [-42.910794, -20.284709995999901],
            [-42.911000999999899, -20.284848995999901],
            [-42.91122, -20.284951996],
            [-42.911499999999897, -20.285032995999899],
            [-42.911743, -20.285090996],
            [-42.912717, -20.285091995999899],
            [-42.912949, -20.2850689959999],
            [-42.913204999999898, -20.284977996],
            [-42.913424, -20.284862996],
            [-42.913765, -20.284701996],
            [-42.914058, -20.284621995999899],
            [-42.914447, -20.284564996],
            [-42.914813, -20.2845539959999],
            [-42.915323999999899, -20.284519996],
            [-42.915653, -20.2845089959999],
            [-42.915933, -20.284486995999899],
            [-42.916201, -20.284452996],
            [-42.916432, -20.284429996],
            [-42.916895, -20.284360996],
            [-42.917236, -20.284338996],
            [-42.917468, -20.284177996],
            [-42.917699, -20.284109995999899],
            [-42.917991, -20.284097996],
            [-42.918284, -20.284098996],
            [-42.918552, -20.284121996],
            [-42.918795, -20.284213995999899],
            [-42.919086999999898, -20.284351995999899],
            [-42.919282, -20.284478996],
            [-42.919463999999898, -20.284674996],
            [-42.919598, -20.284904996],
            [-42.919756, -20.285088996],
            [-42.919841, -20.285340995999899],
            [-42.919926, -20.285547995999899],
            [-42.919998, -20.285823996],
            [-42.920009999999898, -20.286041995999899],
            [-42.919998, -20.286294996],
            [-42.919778, -20.286581995999899],
            [-42.919643999999899, -20.286776996],
            [-42.919485, -20.287006995999899],
            [-42.919327, -20.2872599959999],
            [-42.919205, -20.287500995999899],
            [-42.919106999999897, -20.287752995999899],
            [-42.919106999999897, -20.287982996],
            [-42.919058, -20.288200995999901],
            [-42.91896, -20.288465996],
            [-42.918813, -20.288752996],
            [-42.918728, -20.288993995999899],
            [-42.91863, -20.289223996],
            [-42.918472, -20.289429996],
            [-42.918398, -20.289670995999899],
            [-42.918385999999899, -20.2898899959999],
            [-42.918385, -20.290176996],
            [-42.918433999999898, -20.290509996],
            [-42.918482, -20.2907509959999],
            [-42.918555, -20.290969995999902],
            [-42.918591, -20.291245995999901],
            [-42.918591, -20.291474996],
            [-42.918578, -20.2917629959999],
            [-42.91848, -20.2920499959999],
            [-42.918395, -20.2922569959999],
            [-42.918321999999897, -20.292497996],
            [-42.918273, -20.292726995999899],
            [-42.918223, -20.292991996],
            [-42.91815, -20.293232996],
            [-42.918112999999899, -20.293508996],
            [-42.91804, -20.2938299959999],
            [-42.917991, -20.294162996],
            [-42.917942, -20.2943929959999],
            [-42.91788, -20.294691995999901],
            [-42.917746, -20.294897996],
            [-42.917612, -20.295093995999899],
            [-42.917246, -20.295437996],
            [-42.917099999999898, -20.295620996],
            [-42.916941, -20.2958169959999],
            [-42.916745999999897, -20.295942996],
            [-42.916466, -20.296171995999899],
            [-42.916173, -20.2963559959999],
            [-42.915832, -20.296504996],
            [-42.915466, -20.296664995999901],
            [-42.915137, -20.296779995999898],
            [-42.914845, -20.296847995999901],
            [-42.914419, -20.296881996],
            [-42.914127, -20.296904996],
            [-42.913785, -20.296949996],
            [-42.913554, -20.296961995999901],
            [-42.913359, -20.296776995999899],
            [-42.913201, -20.2966049959999],
            [-42.913165, -20.296340996],
            [-42.913153, -20.2960419959999],
            [-42.913154, -20.295754996],
            [-42.913117999999898, -20.295512996],
            [-42.913069, -20.295179995999899],
            [-42.912984, -20.2949389959999],
            [-42.912778, -20.294673996],
            [-42.912595, -20.294455996],
            [-42.912316, -20.294133996],
            [-42.912158, -20.293937996],
            [-42.912011999999898, -20.293765995999902],
            [-42.911793, -20.2936049959999],
            [-42.911586, -20.293500996],
            [-42.911367, -20.2933629959999],
            [-42.911148, -20.293281996],
            [-42.910904, -20.2932699959999],
            [-42.910649, -20.293223995999899],
            [-42.910381, -20.293165996],
            [-42.910113, -20.293061995999899],
            [-42.909894, -20.292969996],
            [-42.909663, -20.2928899959999],
            [-42.909431, -20.292854996],
            [-42.909151, -20.292854996],
            [-42.908944, -20.292957995999899],
            [-42.908761, -20.293094996],
            [-42.908542, -20.293243995999902],
            [-42.908262, -20.293415996],
            [-42.908103, -20.293576996],
            [-42.907798, -20.2937609959999],
            [-42.907628, -20.293955996],
            [-42.907372, -20.294115996],
            [-42.907201, -20.294264996],
            [-42.907018, -20.294425996],
            [-42.906774, -20.294609996],
            [-42.906517999999899, -20.294654996],
            [-42.906225999999897, -20.294654996],
            [-42.905922, -20.2945969959999],
            [-42.905642, -20.294515996],
            [-42.905313, -20.294400996],
            [-42.905046, -20.294193995999901],
            [-42.904851, -20.294066996],
            [-42.904583, -20.293928996],
            [-42.904424999999897, -20.2937679959999],
            [-42.904218, -20.293491995999901],
            [-42.904073, -20.293272995999899],
            [-42.903902, -20.293019995999899],
            [-42.903708, -20.292766996],
            [-42.903513, -20.2925719959999],
            [-42.903307, -20.292283996],
            [-42.903088, -20.291996996],
            [-42.902881, -20.291662996],
            [-42.902675, -20.291271996],
            [-42.902529, -20.2910199959999],
            [-42.902358999999898, -20.290823996],
            [-42.902104, -20.290604996],
            [-42.901945, -20.290443996],
            [-42.901763, -20.290305996],
            [-42.901556, -20.290202995999898],
            [-42.901337, -20.290121996],
            [-42.901092999999896, -20.290201996],
            [-42.900874, -20.290316995999898],
            [-42.900679, -20.290499995999902],
            [-42.900557, -20.290740996],
            [-42.900447, -20.291050996],
            [-42.900446, -20.291590996],
            [-42.900531, -20.2919359959999],
            [-42.900664, -20.292154996],
            [-42.90081, -20.292430996],
            [-42.900944, -20.292832996],
            [-42.900992, -20.2930629959999],
            [-42.901004, -20.293280996],
            [-42.901001999999899, -20.294498996],
            [-42.901074999999899, -20.294808996],
            [-42.901147, -20.295130996],
            [-42.901147, -20.2953609959999],
            [-42.901159, -20.295601996],
            [-42.901158, -20.295865996],
            [-42.901085, -20.296084995999902],
            [-42.900962999999898, -20.296302996],
            [-42.900804, -20.296462995999899],
            [-42.900596999999898, -20.296680996],
            [-42.900194999999897, -20.296944996],
            [-42.899915, -20.2971169959999],
            [-42.899718999999898, -20.297277995999899],
            [-42.899427, -20.297403996],
            [-42.899122, -20.297540996],
            [-42.898891, -20.297609995999899],
            [-42.898647, -20.297678995999899],
            [-42.898428, -20.297758996],
            [-42.89816, -20.2977699959999],
            [-42.897904, -20.297780996],
            [-42.897393, -20.297779996],
            [-42.897161, -20.297802996],
            [-42.896504, -20.297801996],
            [-42.896236, -20.297766996],
            [-42.896017, -20.297686996],
            [-42.895786, -20.297605996],
            [-42.89525, -20.297604996],
            [-42.89497, -20.297627996],
            [-42.894738, -20.297673996],
            [-42.894494, -20.2977079959999],
            [-42.894250999999898, -20.2977529959999],
            [-42.893971, -20.297798995999901],
            [-42.893617999999897, -20.297832996],
            [-42.893324999999898, -20.297843995999902],
            [-42.893033, -20.297889996],
            [-42.892789, -20.297992995999898],
            [-42.892448, -20.298141996],
            [-42.892204, -20.298221995999899],
            [-42.891961, -20.2983129959999],
            [-42.891741, -20.298415996],
            [-42.891522, -20.298507996],
            [-42.891205, -20.298645995999902],
            [-42.8909, -20.298736995999899],
            [-42.890474, -20.298988995999899],
            [-42.89023, -20.299126995999899],
            [-42.89000722, -20.299210081999899],
            [-42.889986, -20.299217996],
            [-42.889755, -20.299309996],
            [-42.889487, -20.299389995999899],
            [-42.889109, -20.299526995999901],
            [-42.888768, -20.299698996],
            [-42.8885, -20.299847996],
            [-42.888232, -20.299950996],
            [-42.887976, -20.300042995999899],
            [-42.887781, -20.300180995999899],
            [-42.887585999999899, -20.300421996],
            [-42.887427, -20.300673995999901],
            [-42.88728, -20.3009499959999],
            [-42.887085, -20.301270996],
            [-42.886951, -20.301465995999902],
            [-42.886840999999897, -20.301706996],
            [-42.886694, -20.301925996],
            [-42.886572, -20.302361995999899],
            [-42.886485999999898, -20.302568995999899],
            [-42.886425, -20.302866996],
            [-42.8864, -20.303119996],
            [-42.886388, -20.303372996],
            [-42.886387, -20.303602995999899],
            [-42.886399, -20.303877996],
            [-42.886459, -20.304176996],
            [-42.886544, -20.304383996],
            [-42.886629, -20.304601996],
            [-42.886812, -20.304797995999898],
            [-42.886945, -20.305015996],
            [-42.887115999999899, -20.305165996],
            [-42.887334, -20.305384995999901],
            [-42.887505, -20.305545996],
            [-42.887723999999899, -20.305648995999899],
            [-42.888039999999897, -20.305913996],
            [-42.88821, -20.306109996],
            [-42.888647999999897, -20.306270996],
            [-42.88888, -20.306316996],
            [-42.889159999999897, -20.306363996],
            [-42.889390999999897, -20.306397995999902],
            [-42.889647, -20.306444996],
            [-42.890037, -20.306444996],
            [-42.890352999999898, -20.306422996],
            [-42.890597, -20.306296996],
            [-42.890804, -20.306193995999902],
            [-42.891218, -20.306147995999901],
            [-42.891461999999898, -20.306147995999901],
            [-42.891779, -20.306125995999899],
            [-42.892071, -20.306068996],
            [-42.892388, -20.305999996],
            [-42.892729, -20.306023996],
            [-42.89296, -20.306092996],
            [-42.893214999999898, -20.306219996],
            [-42.893349, -20.306426996],
            [-42.893397999999898, -20.3066449959999],
            [-42.893397, -20.306954995999899],
            [-42.893433, -20.307207995999899],
            [-42.893445, -20.3074259959999],
            [-42.893445, -20.3077479959999],
            [-42.893432, -20.307965996],
            [-42.893407, -20.308184996],
            [-42.893322, -20.308471996],
            [-42.893261, -20.308689995999899],
            [-42.893248, -20.308930996],
            [-42.893162, -20.3091609959999],
            [-42.89304, -20.309378996],
            [-42.89293, -20.309619996],
            [-42.892869, -20.3098839959999],
            [-42.892808, -20.310159996],
            [-42.892783, -20.310400995999899],
            [-42.892782, -20.310894995999899],
            [-42.892794, -20.311159996],
            [-42.892794, -20.311446996],
            [-42.892829999999897, -20.3116879959999],
            [-42.892854, -20.311928995999899],
            [-42.892914, -20.3121819959999],
            [-42.892975, -20.3124239959999],
            [-42.893048, -20.312721995999901],
            [-42.893108, -20.312940996],
            [-42.893254, -20.313112996],
            [-42.893315, -20.313388996],
            [-42.893326, -20.313618995999899],
            [-42.893363, -20.313905996],
            [-42.893374, -20.3141589959999],
            [-42.89341, -20.314422996],
            [-42.893482999999897, -20.3146529959999],
            [-42.893641, -20.314940996],
            [-42.893774999999899, -20.315216995999901],
            [-42.893872, -20.315480996],
            [-42.894139, -20.3159869959999],
            [-42.894272999999899, -20.316227996],
            [-42.894443, -20.316492996],
            [-42.894674, -20.316756996],
            [-42.89482, -20.316929995999899],
            [-42.895257, -20.317435996],
            [-42.895586, -20.317516995999899],
            [-42.895878, -20.317654995999899],
            [-42.896195, -20.317815996],
            [-42.896475, -20.317908996],
            [-42.896718, -20.318023996],
            [-42.896962, -20.318069996],
            [-42.897241, -20.318173995999899],
            [-42.897461, -20.3182779959999],
            [-42.897667, -20.318380996],
            [-42.898080999999898, -20.318737996],
            [-42.898312, -20.318898996],
            [-42.898530999999899, -20.319059996],
            [-42.898688999999898, -20.319232996],
            [-42.898835, -20.319427995999899],
            [-42.898883, -20.319726995999901],
            [-42.898895, -20.320002996],
            [-42.898907, -20.320243995999899],
            [-42.898907, -20.320473996],
            [-42.898930999999898, -20.320691995999901],
            [-42.89893, -20.3212779959999],
            [-42.898868999999898, -20.321599996],
            [-42.898746, -20.321874996],
            [-42.898673, -20.322116996],
            [-42.89866, -20.322334995999899],
            [-42.898611, -20.322587996],
            [-42.898562, -20.322851995999901],
            [-42.898477, -20.323092996],
            [-42.89844, -20.323333996],
            [-42.898390999999897, -20.323666995999901],
            [-42.898366, -20.323965996],
            [-42.898317, -20.324252995999899],
            [-42.89828, -20.324482996],
            [-42.898109, -20.324849996],
            [-42.898048, -20.325068996],
            [-42.897985999999896, -20.325389996],
            [-42.897961, -20.325608996],
            [-42.897839, -20.325849995999899],
            [-42.897815, -20.326078996],
            [-42.897778, -20.326412995999899],
            [-42.897753, -20.326699995999899],
            [-42.897752, -20.327227996],
            [-42.897713, -20.327384996],
            [-42.897920053999897, -20.327813996],
            [-42.89798495, -20.3283888889999],
            [-42.898092004, -20.329476617999902],
            [-42.898217108, -20.330839699],
            [-42.898360162, -20.332111996],
            [-42.898281212, -20.3334752929999],
            [-42.898459, -20.334949996],
            [-42.898726, -20.335708995999902],
            [-42.898859, -20.336524996],
            [-42.89876, -20.337558995999899],
            [-42.898601475, -20.338660995999899],
            [-42.898232999999898, -20.339705996],
            [-42.897928, -20.340360996],
            [-42.897488, -20.341083996],
            [-42.89718005, -20.341744361999901],
            [-42.897239788, -20.342416995999901],
            [-42.897595, -20.343151996],
            [-42.897947, -20.343853996],
            [-42.898673787999897, -20.344947654],
            [-42.899128209, -20.345470787999901],
            [-42.8992547779999, -20.3455079679999],
            [-42.899335316, -20.345504697],
            [-42.899704855, -20.344428986],
            [-42.900150895, -20.343091786999899],
            [-42.900668954, -20.341556769],
            [-42.900751473999897, -20.341320557],
            [-42.900831470999897, -20.34128178],
            [-42.900905307999899, -20.341298813],
            [-42.901038931, -20.341400064],
            [-42.901178903999899, -20.341502798],
            [-42.901381135, -20.341598825999899],
            [-42.901560821, -20.341641614],
            [-42.901729098, -20.3416683569999],
            [-42.901971911, -20.341700819],
            [-42.902337149999902, -20.341733527],
            [-42.902639913, -20.341757226],
            [-42.902798980999897, -20.3417600229999],
            [-42.902995833, -20.341747515999899],
            [-42.903219678, -20.341678993],
            [-42.903723323, -20.341495707],
            [-42.904037097999897, -20.341426574],
            [-42.904235173, -20.3414144],
            [-42.904558758999897, -20.341450901],
            [-42.904775089999902, -20.341494915999899],
            [-42.904915011999897, -20.341533985999899],
            [-42.905048332, -20.341608293],
            [-42.9054775339999, -20.341983601],
            [-42.905734055, -20.34222492],
            [-42.905967344, -20.342407668],
            [-42.906302194, -20.342633281],
            [-42.906915376, -20.342978005],
            [-42.907331172999903, -20.343199802999901],
            [-42.90767468, -20.3433806799999],
            [-42.907788604, -20.343090532],
            [-42.908071494999902, -20.343271612],
            [-42.908252072, -20.343052476],
            [-42.907928991999903, -20.342694286],
            [-42.907583217, -20.342294468],
            [-42.907651293, -20.341904002],
            [-42.907688087999901, -20.341786955],
            [-42.907953108999898, -20.341617475],
            [-42.908227736, -20.341385181],
            [-42.908336661, -20.341232587],
            [-42.908390862, -20.340850174],
            [-42.908469466, -20.340406785],
            [-42.908510323, -20.34031737],
            [-42.9085526, -20.339991428],
            [-42.909751336999904, -20.340739447],
            [-42.910372368, -20.341159682999901],
            [-42.910390060999902, -20.341361075999899],
            [-42.9103635499999, -20.341467861],
            [-42.910417730999903, -20.341701053],
            [-42.910414634999903, -20.341818578],
            [-42.910442891999899, -20.3419248709999],
            [-42.910488527, -20.342045428999899],
            [-42.910467298999897, -20.342089909],
            [-42.910452582999902, -20.342198375999899],
            [-42.91044141, -20.342312364999898],
            [-42.910417689, -20.342329634999899],
            [-42.910793618, -20.342398864],
            [-42.911169125, -20.342493019],
            [-42.91155091, -20.342649492],
            [-42.911729029999897, -20.342688692],
            [-42.911724008, -20.342667666],
            [-42.911728936, -20.342560243],
            [-42.911748743, -20.342486097999899],
            [-42.911828279, -20.34223865],
            [-42.911816273999897, -20.3419237059999],
            [-42.912673004999903, -20.341863336],
            [-42.913117873, -20.342091605999901],
            [-42.91336359, -20.3422385759999],
            [-42.913847112, -20.342425853999899],
            [-42.914042211, -20.342702378999899],
            [-42.914516105, -20.342866877999899],
            [-42.914808262999898, -20.343017484],
            [-42.914611787999903, -20.343636842],
            [-42.914345044, -20.34397396],
            [-42.913950489999898, -20.343694451],
            [-42.913440264, -20.343422092],
            [-42.912993763, -20.343777079999899],
            [-42.913146523, -20.3440469559999],
            [-42.912756817999899, -20.344210857],
            [-42.912555353, -20.344370673],
            [-42.912782788, -20.344896430999899],
            [-42.91277947, -20.345334646],
            [-42.912869891999897, -20.345585055],
            [-42.913778518, -20.346177511999901],
            [-42.914459577999899, -20.346638141],
            [-42.915020107, -20.346986398],
            [-42.9152252109999, -20.347117424],
            [-42.915505788, -20.3473080169999],
            [-42.915890775, -20.3475604889999],
            [-42.916119339, -20.347729488],
            [-42.916363293, -20.3478668989999],
            [-42.916970599, -20.348223944],
            [-42.917662035, -20.3471004469999],
            [-42.917922788999903, -20.3469056389999],
            [-42.918906431, -20.346163906999902],
            [-42.919707794, -20.345291076],
            [-42.919990475, -20.344691666],
            [-42.920244990999898, -20.343957004999901],
            [-42.920073713, -20.341249326],
            [-42.920230328999899, -20.3407380969999],
            [-42.920365014, -20.340508432],
            [-42.920730653, -20.340311750999899],
            [-42.92209981, -20.339761791999901],
            [-42.9231496089999, -20.339609417],
            [-42.923529131999899, -20.339262611],
            [-42.924345251, -20.337890303999899],
            [-42.924454592, -20.336930933],
            [-42.924796365, -20.334591010999901],
            [-42.925187002, -20.334066896],
            [-42.925508056, -20.333993285],
            [-42.926113574999903, -20.3338911879999],
            [-42.9268713499999, -20.333897007],
            [-42.9274702, -20.333864535],
            [-42.928027513, -20.333941116999899],
            [-42.928714806, -20.333761659999901],
            [-42.92950739, -20.333298526],
            [-42.929532981, -20.332939888999899],
            [-42.929314941, -20.331957402999901],
            [-42.929008479999901, -20.331114066999898],
            [-42.929527946, -20.330600455],
            [-42.930376407, -20.329946376999899],
            [-42.930893254999901, -20.32933431],
            [-42.931093679999897, -20.328279084],
            [-42.9312619029999, -20.327664071],
            [-42.931853014999902, -20.327005437],
            [-42.933754867999902, -20.324552006],
            [-42.934164734, -20.324658650999901],
            [-42.934927385, -20.324455792],
            [-42.935218039999903, -20.32455336],
            [-42.936244753, -20.324793434],
            [-42.936959467999898, -20.3248070829999],
            [-42.937650462, -20.324638593],
            [-42.938854411, -20.324117407],
            [-42.939943812, -20.323354641],
            [-42.9411158939999, -20.322049399],
            [-42.943164789, -20.319971451],
            [-42.944254472, -20.319274614999902],
            [-42.9458226469999, -20.318907203],
            [-42.94674714, -20.318800886],
            [-42.948532265, -20.318748205],
            [-42.949433182999897, -20.318672331999899],
            [-42.951244443999897, -20.318519793],
            [-42.951448147999898, -20.318547418],
            [-42.9535845, -20.318858496],
            [-42.953597, -20.318731995999901],
            [-42.953707, -20.318444996],
            [-42.953877, -20.318248996],
            [-42.954071999999897, -20.318111996],
            [-42.954292, -20.317996996],
            [-42.954498999999899, -20.317893996],
            [-42.954779, -20.317732996],
            [-42.955035, -20.317629995999901],
            [-42.955279, -20.317526996],
            [-42.955582999999898, -20.317411996],
            [-42.955827, -20.317297996],
            [-42.956083, -20.317171996],
            [-42.956351, -20.317056996],
            [-42.956631, -20.316849996],
            [-42.956851, -20.316712996],
            [-42.957058, -20.316528995999899],
            [-42.957253, -20.316367995999901],
            [-42.957351, -20.316149996],
            [-42.957351, -20.3158969959999],
            [-42.957327, -20.315667995999899],
            [-42.95723, -20.315460996],
            [-42.95712, -20.315230996],
            [-42.956962, -20.315069996],
            [-42.956768, -20.314942996],
            [-42.956524, -20.314804996],
            [-42.956171, -20.314643996],
            [-42.955842, -20.314516996],
            [-42.955537999999898, -20.3143899959999],
            [-42.955282, -20.314274995999899],
            [-42.955063, -20.314171996],
            [-42.954759, -20.314033996],
            [-42.954442, -20.313883996],
            [-42.954186999999898, -20.313779995999901],
            [-42.953931, -20.313687995999899],
            [-42.953578, -20.313514996],
            [-42.953370999999898, -20.313365996],
            [-42.953176, -20.313215995999901],
            [-42.952982, -20.312985996],
            [-42.952835999999898, -20.312709996],
            [-42.952739, -20.312502996],
            [-42.952604999999899, -20.312192996],
            [-42.952484, -20.311916996],
            [-42.952362, -20.311663995999901],
            [-42.952301, -20.311422996],
            [-42.952253, -20.311204996],
            [-42.952205, -20.3109169959999],
            [-42.952107, -20.3106989959999],
            [-42.95201, -20.310468995999901],
            [-42.951889, -20.310158995999899],
            [-42.951743, -20.309870996],
            [-42.951536, -20.309560996],
            [-42.951293, -20.309353996],
            [-42.951121999999899, -20.309203996],
            [-42.950928, -20.308973996],
            [-42.95077, -20.308640996],
            [-42.950721, -20.3083769959999],
            [-42.950685, -20.308111996],
            [-42.950673, -20.307870996],
            [-42.950686, -20.307652995999899],
            [-42.950722, -20.307422996],
            [-42.950796, -20.3072049959999],
            [-42.951003, -20.306997996],
            [-42.951222, -20.306859996],
            [-42.95143, -20.306699996],
            [-42.951625, -20.306515996],
            [-42.951783, -20.306274996],
            [-42.951857, -20.306044996],
            [-42.951942, -20.305826996],
            [-42.952015, -20.305608995999901],
            [-42.952077, -20.305390996],
            [-42.952089, -20.305091995999899],
            [-42.952077, -20.304815995999899],
            [-42.951956, -20.304597995999899],
            [-42.952053, -20.304333996],
            [-42.952005, -20.304103996],
            [-42.951932, -20.3038279959999],
            [-42.951884, -20.303609996],
            [-42.951811, -20.3033339959999],
            [-42.951726, -20.303080995999899],
            [-42.951653, -20.302804996],
            [-42.951556, -20.302574996],
            [-42.951434, -20.302344995999899],
            [-42.951289, -20.302103996],
            [-42.951265, -20.301815996],
            [-42.951326, -20.301586996],
            [-42.951327, -20.300770996],
            [-42.951315, -20.300540995999899],
            [-42.951278, -20.300310996],
            [-42.951242, -20.300092995999901],
            [-42.951218, -20.299828996],
            [-42.951206, -20.299586996],
            [-42.951219, -20.299299995999899],
            [-42.951231, -20.299069996],
            [-42.951231, -20.298828995999902],
            [-42.951244, -20.298587996],
            [-42.951329, -20.298380995999899],
            [-42.951389999999897, -20.298150995999901],
            [-42.951512, -20.297909995999898],
            [-42.951659, -20.297680995999901],
            [-42.951732, -20.297404996],
            [-42.951818, -20.297197996],
            [-42.951879, -20.296875996],
            [-42.952074, -20.296439995999901],
            [-42.952172, -20.296083995999901],
            [-42.952269999999899, -20.2958309959999],
            [-42.952404, -20.295509996],
            [-42.952575, -20.295061996],
            [-42.952672999999898, -20.294854996],
            [-42.95271, -20.294406996],
            [-42.95271, -20.293912996],
            [-42.952697999999899, -20.293498996],
            [-42.952674, -20.293222995999901],
            [-42.95259, -20.292866995999901],
            [-42.952492, -20.292625996],
            [-42.952261, -20.292383996],
            [-42.952018, -20.292245996],
            [-42.951811, -20.2921429959999],
            [-42.951543, -20.291992996],
            [-42.951324, -20.291854995999898],
            [-42.951081, -20.291739995999901],
            [-42.950776, -20.291578995999899],
            [-42.950545, -20.291462996],
            [-42.950313999999899, -20.291336995999899],
            [-42.950130999999899, -20.291152995999902],
            [-42.949937, -20.290968995999901],
            [-42.949694, -20.290703995999898],
            [-42.949523, -20.290485996],
            [-42.949329, -20.290289996],
            [-42.949037, -20.290071995999899],
            [-42.948939, -20.289852996],
            [-42.948818, -20.289645995999901],
            [-42.948769, -20.289427996],
            [-42.948721, -20.289197995999899],
            [-42.94866, -20.288967995999901],
            [-42.948612, -20.288692996],
            [-42.948588, -20.288473995999901],
            [-42.948576, -20.288197996],
            [-42.948674, -20.287922996],
            [-42.948747, -20.287669996],
            [-42.948833, -20.287405996],
            [-42.948955, -20.287198996],
            [-42.949113, -20.286969996],
            [-42.949295999999897, -20.2867859959999],
            [-42.949454, -20.286613996],
            [-42.949661999999897, -20.286383995999898],
            [-42.949905, -20.286200995999899],
            [-42.950113, -20.286062995999899],
            [-42.950344, -20.285890996],
            [-42.950588, -20.285706995999899],
            [-42.950795, -20.2855459959999],
            [-42.951014, -20.285408995999902],
            [-42.951295, -20.285259996],
            [-42.951501999999898, -20.2851449959999],
            [-42.951684999999898, -20.2850069959999],
            [-42.951952999999897, -20.2847779959999],
            [-42.952148, -20.284628996],
            [-42.952331, -20.284490995999899],
            [-42.952489, -20.284306995999899],
            [-42.952695999999897, -20.284077996],
            [-42.952806, -20.283870996],
            [-42.952892, -20.283571996],
            [-42.952892, -20.283307996],
            [-42.95288, -20.283054996],
            [-42.952807, -20.282778995999902],
            [-42.952722, -20.282525995999901],
            [-42.952601, -20.282319996],
            [-42.952492, -20.282066996],
            [-42.952333, -20.281893996],
            [-42.952199999999898, -20.281686996],
            [-42.951993, -20.2814919959999],
            [-42.951762, -20.281284996],
            [-42.951567, -20.2811119959999],
            [-42.951202, -20.280743996],
            [-42.950983, -20.2805719959999],
            [-42.950776, -20.280421996],
            [-42.950581999999898, -20.280202996],
            [-42.950436, -20.280019996],
            [-42.950278, -20.2798469959999],
            [-42.950059, -20.279570996],
            [-42.949913, -20.279352995999901],
            [-42.949790999999898, -20.279122996],
            [-42.949632999999899, -20.278869996],
            [-42.949512, -20.278673996],
            [-42.949317, -20.278420996],
            [-42.949269, -20.278190996],
            [-42.949269, -20.277961995999899],
            [-42.949342, -20.277685995999899],
            [-42.949464, -20.277478996],
            [-42.949806, -20.277111996],
            [-42.950001, -20.276962996],
            [-42.950195999999899, -20.276836995999901],
            [-42.950378999999899, -20.276698995999901],
            [-42.95061, -20.2765149959999],
            [-42.950793, -20.276319995999899],
            [-42.951024, -20.276170996],
            [-42.951268, -20.275998995999899],
            [-42.951451, -20.275849996],
            [-42.951622, -20.275676996],
            [-42.951755999999897, -20.275493996],
            [-42.951926, -20.275332996],
            [-42.952097, -20.275160996],
            [-42.952243, -20.274988995999902],
            [-42.952340999999898, -20.2747589959999],
            [-42.952450999999897, -20.274540995999899],
            [-42.952622, -20.274230996],
            [-42.952732, -20.274000995999899],
            [-42.952829, -20.273736996],
            [-42.952877999999899, -20.273472996],
            [-42.952987999999898, -20.272950996],
            [-42.952794, -20.272897995999902],
            [-42.952501, -20.272908996],
            [-42.952258, -20.272977996],
            [-42.952002, -20.273069995999901],
            [-42.951709999999899, -20.2731379959999],
            [-42.951343999999899, -20.273229995999898],
            [-42.951064, -20.273286996],
            [-42.950783999999899, -20.273401996],
            [-42.950479, -20.273458995999899],
            [-42.950223999999899, -20.2735049959999],
            [-42.949968, -20.273561996],
            [-42.949723999999897, -20.273595995999901],
            [-42.949457, -20.273606996],
            [-42.949115999999897, -20.273606996],
            [-42.948872, -20.2735949959999],
            [-42.94858, -20.273548995999899],
            [-42.948287999999899, -20.273513996],
            [-42.94774, -20.273513996],
            [-42.947447, -20.2735249959999],
            [-42.947204, -20.273570995999901],
            [-42.946875, -20.273615995999901],
            [-42.946618999999899, -20.273638995999899],
            [-42.946084, -20.273637995999898],
            [-42.945827999999899, -20.273603996],
            [-42.945524, -20.2736149959999],
            [-42.945292, -20.273625996],
            [-42.945061, -20.273671996],
            [-42.944792999999898, -20.273763996],
            [-42.944537, -20.273843996],
            [-42.944305, -20.273992995999901],
            [-42.944305, -20.274256996],
            [-42.944293, -20.274520996],
            [-42.944378, -20.274750996],
            [-42.94456, -20.274934996],
            [-42.944755, -20.275153996],
            [-42.944852, -20.275429996],
            [-42.944912, -20.275658995999901],
            [-42.945034, -20.275957995999899],
            [-42.945034, -20.276222995999898],
            [-42.944912, -20.276417996],
            [-42.944656, -20.276623996],
            [-42.944375, -20.276749996],
            [-42.944156, -20.276830995999902],
            [-42.943864, -20.276841996],
            [-42.94362, -20.276841996],
            [-42.943376999999899, -20.276852995999899],
            [-42.943109, -20.2768989959999],
            [-42.942865, -20.276920995999902],
            [-42.942573, -20.276954996],
            [-42.942329, -20.276977996],
            [-42.941879, -20.277080995999899],
            [-42.94155, -20.277183996],
            [-42.941319, -20.2772409959999],
            [-42.941063, -20.277275996],
            [-42.940831, -20.2773099959999],
            [-42.940562999999898, -20.277320996],
            [-42.940271, -20.277366996],
            [-42.940002999999898, -20.277388996],
            [-42.939771999999898, -20.277411996],
            [-42.939248, -20.277411996],
            [-42.939053, -20.277548996],
            [-42.938809999999897, -20.277479996],
            [-42.938615, -20.2773309959999],
            [-42.938481, -20.277123995999901],
            [-42.93819, -20.276732996],
            [-42.938032, -20.276502996],
            [-42.937898, -20.276260996],
            [-42.937764, -20.276053996],
            [-42.937619, -20.275766996],
            [-42.937461, -20.275490996],
            [-42.937339, -20.275283995999899],
            [-42.937204999999899, -20.275053995999901],
            [-42.937084, -20.274858996],
            [-42.936937999999898, -20.274639996],
            [-42.936768, -20.2744449959999],
            [-42.936585, -20.274191996],
            [-42.936304999999898, -20.274076996],
            [-42.93605, -20.273995995999901],
            [-42.935831, -20.273914996],
            [-42.935563, -20.273891996],
            [-42.935332, -20.273879995999899],
            [-42.935039, -20.273867996],
            [-42.934698, -20.273890996],
            [-42.934455, -20.273890996],
            [-42.934138, -20.273855996],
            [-42.933883, -20.273878996],
            [-42.933602, -20.273935996],
            [-42.933359, -20.2740159959999],
            [-42.933115, -20.274118996],
            [-42.932907999999898, -20.274221995999898],
            [-42.932713, -20.274348996],
            [-42.932494, -20.274474996],
            [-42.932287, -20.274669995999901],
            [-42.932067, -20.274841996],
            [-42.931909, -20.275071996],
            [-42.931738, -20.275289996],
            [-42.931567, -20.275461995999901],
            [-42.931299, -20.2757029959999],
            [-42.931067, -20.275966996],
            [-42.930836, -20.276172996],
            [-42.930665, -20.276425995999901],
            [-42.930518999999897, -20.276597996],
            [-42.930324, -20.276792995999902],
            [-42.930141, -20.276999996],
            [-42.929909, -20.277125995999899],
            [-42.929605, -20.277297996],
            [-42.929349, -20.277412996],
            [-42.929032, -20.277446996],
            [-42.928728, -20.277491996],
            [-42.928484, -20.277526995999899],
            [-42.928154999999897, -20.277606996],
            [-42.927899, -20.277663995999902],
            [-42.927656, -20.277720996],
            [-42.9274, -20.277789996],
            [-42.92712, -20.277742996],
            [-42.926851999999897, -20.277765996],
            [-42.926608, -20.277903996],
            [-42.926400999999899, -20.2780529959999],
            [-42.926206, -20.278213996],
            [-42.92595, -20.278362995999899],
            [-42.925755, -20.278545995999899],
            [-42.925597, -20.278706996],
            [-42.925426, -20.2788789959999],
            [-42.925230999999897, -20.279050996],
            [-42.925024, -20.2791999959999],
            [-42.924853, -20.279348996],
            [-42.924585, -20.279509995999899],
            [-42.924352999999897, -20.279647995999898],
            [-42.924098, -20.279750996],
            [-42.923878, -20.279841996],
            [-42.923659, -20.279922996],
            [-42.923428, -20.280048995999898],
            [-42.923135, -20.2801399959999],
            [-42.922855, -20.280231995999898],
            [-42.922598999999899, -20.280300995999902],
            [-42.922367999999899, -20.280368995999901],
            [-42.922123999999897, -20.280402996],
            [-42.921855999999899, -20.280483995999901],
            [-42.921624999999899, -20.2805519959999],
            [-42.921357, -20.280574995999899],
            [-42.921039999999898, -20.280505995999899],
            [-42.920785, -20.280447996],
            [-42.920553, -20.280389995999901],
            [-42.920321999999899, -20.280286996],
            [-42.920091, -20.280124995999898],
            [-42.919835, -20.280090996],
            [-42.919555, -20.280020996],
            [-42.919251, -20.279882996],
            [-42.919008, -20.2797569959999],
            [-42.918777, -20.279652996],
            [-42.918594, -20.279502996],
            [-42.918399, -20.2793769959999],
            [-42.91818, -20.279238995999901],
            [-42.917937, -20.279088995999899],
            [-42.917729999999899, -20.278950995999899],
            [-42.917523, -20.278789995999901],
            [-42.917256, -20.278765995999901],
            [-42.916976, -20.278639996],
            [-42.916732, -20.278569995999899],
            [-42.916513, -20.278489996],
            [-42.916258, -20.2783399959999],
            [-42.916075, -20.278178996],
            [-42.915905, -20.278005995999902],
            [-42.915747, -20.277833996],
            [-42.91554, -20.277591996],
            [-42.915346, -20.277407996],
            [-42.915151, -20.277246996],
            [-42.914981, -20.277097996],
            [-42.914884, -20.276890996],
            [-42.914847, -20.276671995999902],
            [-42.914738, -20.2764769959999],
            [-42.914676, -20.276283996]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 139,
        "id": 37050,
        "CD_SETOR": "315210513000005P",
        "AREA_KM2": 0.07558,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210513",
        "NM_DIST": "Rosário do Pontal",
        "CD_SUBDIST": "31521051300",
        "NM_SUBDIST": null,
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 154
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.908071494999902, -20.343271612],
            [-42.908139639, -20.343322413],
            [-42.90820559, -20.343404166],
            [-42.90827108, -20.343454482],
            [-42.908323757999902, -20.343499258999898],
            [-42.908453564, -20.343584442999902],
            [-42.908511843, -20.343622898],
            [-42.908615418, -20.343686117999901],
            [-42.9087737199999, -20.343744678999901],
            [-42.908909778, -20.343786328],
            [-42.908994637, -20.343803588],
            [-42.909153751, -20.3438390919999],
            [-42.909193504, -20.343851809999901],
            [-42.909272422, -20.343888148],
            [-42.909396640999901, -20.343918674],
            [-42.909512778, -20.343936953],
            [-42.9096612129999, -20.343943023999898],
            [-42.909668439, -20.343871651],
            [-42.909673039, -20.343792605],
            [-42.909715626, -20.343691094],
            [-42.909728356, -20.34362485],
            [-42.909711957, -20.3435605889999],
            [-42.909644527, -20.343414440999901],
            [-42.909590030999901, -20.343345159],
            [-42.909586181, -20.34329852],
            [-42.909606853, -20.343234767999899],
            [-42.9096476019999, -20.343137576999901],
            [-42.909656692999903, -20.343078493],
            [-42.909642272, -20.343024822],
            [-42.90958776, -20.342965057],
            [-42.909571979, -20.342896589],
            [-42.909567787999897, -20.342798634],
            [-42.909547245999903, -20.342737778999901],
            [-42.909565181999902, -20.3426822549999],
            [-42.909602466, -20.342605981],
            [-42.909681670999902, -20.342535285999901],
            [-42.909752114999897, -20.3425213259999],
            [-42.909844537, -20.342510806999901],
            [-42.9099170329999, -20.342468353999902],
            [-42.909972218, -20.342451533],
            [-42.910027778, -20.342455201],
            [-42.910241814, -20.342464171],
            [-42.910301115, -20.342414507999901],
            [-42.910363636999897, -20.342368988],
            [-42.910417689, -20.342329634999899],
            [-42.91044141, -20.342312364999898],
            [-42.910452582999902, -20.342198375999899],
            [-42.910467298999897, -20.342089909],
            [-42.910488527, -20.342045428999899],
            [-42.910442891999899, -20.3419248709999],
            [-42.910414634999903, -20.341818578],
            [-42.910417730999903, -20.341701053],
            [-42.9103635499999, -20.341467861],
            [-42.910390060999902, -20.341361075999899],
            [-42.910372368, -20.341159682999901],
            [-42.909751336999904, -20.340739447],
            [-42.9085526, -20.339991428],
            [-42.908510323, -20.34031737],
            [-42.908469466, -20.340406785],
            [-42.908390862, -20.340850174],
            [-42.908336661, -20.341232587],
            [-42.908227736, -20.341385181],
            [-42.907953108999898, -20.341617475],
            [-42.907688087999901, -20.341786955],
            [-42.907651293, -20.341904002],
            [-42.907583217, -20.342294468],
            [-42.907928991999903, -20.342694286],
            [-42.908252072, -20.343052476],
            [-42.908071494999902, -20.343271612]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 140,
        "id": 37051,
        "CD_SETOR": "315210513000007P",
        "AREA_KM2": 36.689323,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210513",
        "NM_DIST": "Rosário do Pontal",
        "CD_SUBDIST": "31521051300",
        "NM_SUBDIST": null,
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 388
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.916970599, -20.348223944],
            [-42.917340963, -20.348377205],
            [-42.9174929, -20.348445378],
            [-42.917525069, -20.348479165999901],
            [-42.9175886089999, -20.348488322],
            [-42.917699227, -20.348570451999901],
            [-42.917853728, -20.348694883],
            [-42.917989615, -20.348790471],
            [-42.918302416, -20.348868062],
            [-42.9185271919999, -20.3487377669999],
            [-42.918712136, -20.3487485869999],
            [-42.918887282, -20.34916694],
            [-42.918984332, -20.349317679],
            [-42.919163910999899, -20.349463903],
            [-42.919259644, -20.349528695999901],
            [-42.919405336999901, -20.349612088999901],
            [-42.919538614, -20.34972534],
            [-42.919693265, -20.349816256999901],
            [-42.91987469, -20.349797322],
            [-42.92004718, -20.349753819999901],
            [-42.9203524119999, -20.34970021],
            [-42.920545961, -20.3497057599999],
            [-42.920779019, -20.349710494],
            [-42.920939232, -20.349699733],
            [-42.921106815, -20.349680387],
            [-42.921215795, -20.349707011],
            [-42.921374682, -20.349758112],
            [-42.921347467, -20.3498791879999],
            [-42.921341975, -20.349898198999899],
            [-42.921293090999903, -20.349993518],
            [-42.92121458, -20.350059299],
            [-42.921180177999901, -20.350100521999899],
            [-42.920960086, -20.350616108999901],
            [-42.921357364, -20.350981544],
            [-42.921397457, -20.351408853],
            [-42.921336931, -20.351787303],
            [-42.921223502, -20.352210247999899],
            [-42.921166, -20.352476996],
            [-42.920923855, -20.353787855999901],
            [-42.920882741999897, -20.354053989],
            [-42.920815472999898, -20.354374908],
            [-42.920762543, -20.354759780999899],
            [-42.920735769, -20.35506787],
            [-42.920659429999901, -20.355346305999898],
            [-42.920633838999898, -20.355678222999899],
            [-42.920527975, -20.3561075759999],
            [-42.920462567999898, -20.356671560999899],
            [-42.920618578, -20.35689343],
            [-42.920770721999901, -20.356976322],
            [-42.921008244999904, -20.356965967],
            [-42.921048097, -20.357007719],
            [-42.921095505, -20.357325741],
            [-42.921136617999899, -20.3575287799999],
            [-42.921154913999899, -20.357962003999901],
            [-42.92114821, -20.358289692],
            [-42.921080914, -20.358606344],
            [-42.920863279999899, -20.359012169],
            [-42.920697828, -20.359391607],
            [-42.920481811999899, -20.360018743],
            [-42.920330855, -20.360692604999901],
            [-42.920237995, -20.361074258],
            [-42.920175247, -20.36129891],
            [-42.920145133999902, -20.361565040999899],
            [-42.920226177, -20.361819345],
            [-42.920436563, -20.362058986],
            [-42.9206484279999, -20.362161611],
            [-42.920823382, -20.362206344999901],
            [-42.921210295, -20.362488829],
            [-42.921484205999903, -20.362853522],
            [-42.921642753999897, -20.363263439],
            [-42.921928238, -20.363862519],
            [-42.922128308, -20.364235353],
            [-42.922223797999898, -20.364495146],
            [-42.922238933, -20.364615226999899],
            [-42.922304298, -20.364828996],
            [-42.922291145, -20.364917195],
            [-42.922222432999902, -20.365133436],
            [-42.922172197999899, -20.365275305999901],
            [-42.922186086, -20.365510581],
            [-42.922166135999902, -20.365623263],
            [-42.922091092999899, -20.365728169],
            [-42.922058256, -20.365859954],
            [-42.922096019, -20.365997524],
            [-42.922170687999902, -20.366077785999899],
            [-42.922240726, -20.366085843],
            [-42.922290744, -20.366134595999899],
            [-42.922284474, -20.366329024],
            [-42.922226731, -20.366360197999899],
            [-42.922151984, -20.366358940999898],
            [-42.921971271, -20.366349902],
            [-42.921877709999897, -20.366378687],
            [-42.921572534999903, -20.366716573],
            [-42.921556548, -20.36678072],
            [-42.921577238999902, -20.366940464],
            [-42.921720951999902, -20.367141355999902],
            [-42.921989159, -20.367255316],
            [-42.922292591, -20.367319537999901],
            [-42.92248253, -20.3673878029999],
            [-42.922592043, -20.36749909],
            [-42.922759782, -20.367852845999899],
            [-42.922951417, -20.368047541],
            [-42.922963852, -20.368204147],
            [-42.922937728, -20.36839236],
            [-42.922969661, -20.368435297],
            [-42.923178999999898, -20.368563996],
            [-42.923272389, -20.368657136],
            [-42.923328339, -20.368782995999901],
            [-42.923345810999898, -20.369038469],
            [-42.923407525999899, -20.36913143],
            [-42.923523495, -20.369208489],
            [-42.923624937999897, -20.369281799],
            [-42.923841331999903, -20.369456939],
            [-42.92393779, -20.369522321],
            [-42.92409143, -20.369525022999898],
            [-42.924177691, -20.369510189],
            [-42.924216580999897, -20.369444384],
            [-42.924196605, -20.369277317],
            [-42.924266702, -20.369112937],
            [-42.924378176999902, -20.368780159],
            [-42.9244770399999, -20.368562559999901],
            [-42.924636118, -20.368329124999899],
            [-42.924773562, -20.368288140999901],
            [-42.925019796, -20.368315371999898],
            [-42.925552583999902, -20.368357795],
            [-42.925661428, -20.368374267],
            [-42.925767933, -20.368425893999898],
            [-42.925864804, -20.368504433999899],
            [-42.925956047999897, -20.368616215],
            [-42.926053996999897, -20.368693477],
            [-42.926263849999899, -20.368806349],
            [-42.926318016, -20.368837155999898],
            [-42.926334510999901, -20.368925261],
            [-42.926331048999899, -20.368979238],
            [-42.926337956999902, -20.369050995999899],
            [-42.926428624, -20.369115807],
            [-42.926601922, -20.369151841],
            [-42.926784724999898, -20.369163333],
            [-42.926911034999897, -20.369231231999901],
            [-42.927090155, -20.369382139],
            [-42.9271676769999, -20.369412558],
            [-42.927315964999899, -20.369424584999901],
            [-42.927451661, -20.369417959],
            [-42.927658784999899, -20.369454216],
            [-42.927809384999897, -20.36947536],
            [-42.9278883489999, -20.369518515],
            [-42.928007589999901, -20.369628064],
            [-42.928308645, -20.369875793999899],
            [-42.928720408999901, -20.370117767],
            [-42.929212914999901, -20.370418055],
            [-42.929444395, -20.370458407999902],
            [-42.929544979, -20.370476107999899],
            [-42.929705559, -20.370414584999899],
            [-42.929932446, -20.370293987],
            [-42.930046429999898, -20.370181993],
            [-42.930144970999898, -20.3701803189999],
            [-42.930243102, -20.370242120999901],
            [-42.930286269, -20.370360553999902],
            [-42.930355978, -20.370532397],
            [-42.930438059, -20.370639006],
            [-42.930464747, -20.370717804],
            [-42.930504081, -20.3708219009999],
            [-42.930502879999899, -20.370945533999901],
            [-42.930573183, -20.370996146],
            [-42.930749886999898, -20.370992741],
            [-42.930989444, -20.370997052],
            [-42.931113731, -20.371048882],
            [-42.931225099999899, -20.371194064],
            [-42.93128669, -20.371365343],
            [-42.931421739, -20.371525145],
            [-42.931551062, -20.371748705999899],
            [-42.931603531999897, -20.371944705],
            [-42.931701045999901, -20.3721022729999],
            [-42.931860201999903, -20.372253123999901],
            [-42.931917271, -20.3723029189999],
            [-42.932000011, -20.372563393999901],
            [-42.932130054, -20.3728468689999],
            [-42.932241878999903, -20.373075564],
            [-42.932422104999901, -20.373160877],
            [-42.932557577, -20.373306394999901],
            [-42.93266848, -20.373399489],
            [-42.932711169999898, -20.373439905999899],
            [-42.932718496999897, -20.373512488],
            [-42.932723913, -20.373642603999901],
            [-42.932771277, -20.373702944999899],
            [-42.932870395999899, -20.373723104],
            [-42.933056001, -20.373722137999899],
            [-42.933078075, -20.3737754369999],
            [-42.933049057999902, -20.373893934],
            [-42.933068467999902, -20.373997174],
            [-42.933126383999898, -20.374095869],
            [-42.933167284, -20.3741366419999],
            [-42.933158813, -20.374179046999899],
            [-42.933083212, -20.374220208999901],
            [-42.933056416999896, -20.374414092999899],
            [-42.93302755, -20.374561726],
            [-42.933073478, -20.374656092999899],
            [-42.933236, -20.374710996],
            [-42.933510138, -20.374824432],
            [-42.933656566, -20.374955397999901],
            [-42.933704748, -20.375138018],
            [-42.933713608999902, -20.375356452999899],
            [-42.933800651, -20.375430314],
            [-42.93393586, -20.375467472],
            [-42.934159303999898, -20.375443134],
            [-42.934395858, -20.37538598],
            [-42.934623878, -20.375303642999899],
            [-42.934782093, -20.37513179],
            [-42.934875139, -20.374956380999901],
            [-42.934940377, -20.374844456999899],
            [-42.934993638, -20.374832301],
            [-42.935022416, -20.374914078],
            [-42.935046676, -20.37500283],
            [-42.935133488, -20.375033987999899],
            [-42.935259527, -20.375129725],
            [-42.935401691, -20.375295547999901],
            [-42.935542650999899, -20.3753600699999],
            [-42.935655884, -20.375411433],
            [-42.935788912, -20.3754068279999],
            [-42.935921191, -20.375440764],
            [-42.936079627, -20.375568585999901],
            [-42.936157201999897, -20.3757324699999],
            [-42.936188473, -20.3759351859999],
            [-42.936233492, -20.376074554],
            [-42.936347527, -20.376206386],
            [-42.936435893, -20.376381302],
            [-42.936466740999897, -20.376583058999898],
            [-42.936424538, -20.376918837999899],
            [-42.936415087999897, -20.377038414],
            [-42.936429086, -20.377133344],
            [-42.936504965, -20.377189555],
            [-42.936768980999901, -20.377317714],
            [-42.936979424999898, -20.377388873],
            [-42.937115121, -20.377505726],
            [-42.937231417, -20.377702031999899],
            [-42.937256008, -20.377961748],
            [-42.937287459999901, -20.378234318],
            [-42.937239565, -20.37852636],
            [-42.937239, -20.379138995999899],
            [-42.937245323, -20.379348364],
            [-42.937277236999897, -20.379563385],
            [-42.93734461, -20.379782996],
            [-42.937392583, -20.3800304059999],
            [-42.937432, -20.380241995999899],
            [-42.93748393, -20.38031388],
            [-42.937638642, -20.38057329],
            [-42.937729347, -20.380668306],
            [-42.9378011639999, -20.380800751],
            [-42.937962066999901, -20.3811881899999],
            [-42.938060145, -20.381430496],
            [-42.938179422, -20.3817153369999],
            [-42.938184126, -20.382014093999899],
            [-42.938150492, -20.38235508],
            [-42.938092293, -20.3828307429999],
            [-42.938035277, -20.383165318],
            [-42.937999972999897, -20.3835289959999],
            [-42.937915, -20.383746996],
            [-42.937927, -20.384045995999902],
            [-42.937939, -20.384286996],
            [-42.937988, -20.384528996],
            [-42.938012, -20.384746995999901],
            [-42.93806, -20.384976996],
            [-42.938145, -20.385229996],
            [-42.938315, -20.385666995999902],
            [-42.938425105, -20.385966058],
            [-42.938619591, -20.386133711],
            [-42.938838, -20.386229995999901],
            [-42.939058, -20.386367995999901],
            [-42.9392184169999, -20.386454178],
            [-42.939468172, -20.386541176],
            [-42.939678667, -20.3867307679999],
            [-42.93977618, -20.386874154999902],
            [-42.939841164, -20.387074707999901],
            [-42.939952374, -20.387269096],
            [-42.940094288, -20.387368872],
            [-42.940459473, -20.387493761],
            [-42.94064632, -20.387547179],
            [-42.94080946, -20.387668037],
            [-42.940941634, -20.387744199999901],
            [-42.941127, -20.387817996],
            [-42.941383, -20.3878529959999],
            [-42.941931, -20.3878529959999],
            [-42.942097323, -20.387854506999901],
            [-42.942468077999898, -20.387769466],
            [-42.942727522, -20.387684119],
            [-42.943270406, -20.387469139],
            [-42.943406694, -20.387356751],
            [-42.943519185999897, -20.387174199],
            [-42.943587266, -20.387032181999899],
            [-42.943679024999902, -20.387007986],
            [-42.9437885729999, -20.387026503999898],
            [-42.943978382, -20.3870433],
            [-42.944278424999901, -20.386964463],
            [-42.944633659, -20.386911993],
            [-42.94481332, -20.3869392779999],
            [-42.944930642, -20.387072748],
            [-42.945183868, -20.387406363],
            [-42.9453816179999, -20.387561465],
            [-42.945758, -20.387742995999901],
            [-42.946062, -20.387857996],
            [-42.946555703999898, -20.388044629],
            [-42.946998354999899, -20.388217037999901],
            [-42.947353486999901, -20.3883436909999],
            [-42.9475758519999, -20.388334915],
            [-42.947842078, -20.388205567999901],
            [-42.948031965, -20.388226935],
            [-42.948195078, -20.388318731],
            [-42.948316513, -20.388482403999902],
            [-42.948450833, -20.388708355999899],
            [-42.948603036, -20.388928876],
            [-42.948858532, -20.389093298],
            [-42.949013005, -20.389158526],
            [-42.94914518, -20.3891593829999],
            [-42.949346518, -20.389122056],
            [-42.949501789, -20.389109006999899],
            [-42.949578683, -20.389071097999899],
            [-42.949746858, -20.3889510979999],
            [-42.949854546, -20.388882587999898],
            [-42.949945121, -20.388866241],
            [-42.950172008, -20.388914363],
            [-42.950377852, -20.388966771],
            [-42.950580843999902, -20.388944016],
            [-42.950847661, -20.388870466],
            [-42.95117543, -20.388894526999898],
            [-42.951863773999897, -20.389093669],
            [-42.952082774, -20.3891746689999],
            [-42.952288591, -20.389189996],
            [-42.952420921999902, -20.389275078],
            [-42.952561139999901, -20.389440363999899],
            [-42.952797253, -20.389621894],
            [-42.953277366, -20.3899031989999],
            [-42.953448392, -20.389930483999901],
            [-42.953721505, -20.3899018729999],
            [-42.953940504999899, -20.38994171],
            [-42.954213774, -20.390085057],
            [-42.954338062, -20.390232139],
            [-42.954449723, -20.390412151],
            [-42.954610583, -20.390522217999901],
            [-42.954841583, -20.390579218],
            [-42.955057062, -20.390633992999899],
            [-42.955172612999903, -20.390623299],
            [-42.955493133999902, -20.390529423],
            [-42.955785029, -20.390508484],
            [-42.956274314, -20.390542442],
            [-42.956650695999897, -20.390538052999901],
            [-42.957034399999898, -20.390492080999898],
            [-42.957244918999898, -20.390382909],
            [-42.957349807, -20.390166522999898],
            [-42.957471806999898, -20.3897691569999],
            [-42.957577, -20.389304422999899],
            [-42.957815296, -20.388740834],
            [-42.958071269, -20.388359997999899],
            [-42.958455694, -20.387885651],
            [-42.958709327999898, -20.387607018],
            [-42.959063844, -20.387441285],
            [-42.959729371, -20.3872174279999],
            [-42.960020753, -20.387047346],
            [-42.960188979, -20.386879387],
            [-42.960281865999903, -20.386418853999899],
            [-42.960335414, -20.385955709],
            [-42.960446902999898, -20.385509655],
            [-42.960511268999902, -20.385163937],
            [-42.960723844, -20.384996185],
            [-42.961016898, -20.384950672999899],
            [-42.961319714999902, -20.385026837999899],
            [-42.96172215, -20.385104408],
            [-42.962164801, -20.385047202],
            [-42.962539999999898, -20.385001996],
            [-42.962778887, -20.3849875669999],
            [-42.963044113, -20.38501722],
            [-42.963271, -20.385105995999901],
            [-42.96358286, -20.385734823999901],
            [-42.963783591, -20.385890682],
            [-42.964148565, -20.385948681999899],
            [-42.964495241999899, -20.3858754349999],
            [-42.964918597, -20.385791600999902],
            [-42.965390177, -20.385455249],
            [-42.966080167, -20.385075226],
            [-42.966398785, -20.384834737999899],
            [-42.966636080999898, -20.384735168],
            [-42.967109639999897, -20.38455378],
            [-42.967472328, -20.384520086],
            [-42.968139037999897, -20.384439062],
            [-42.968565575, -20.384406978999898],
            [-42.969014339, -20.384292773],
            [-42.969224703999899, -20.384079533],
            [-42.969452644999897, -20.38368946],
            [-42.969792, -20.383066996],
            [-42.969954366, -20.382423141999901],
            [-42.970176450999901, -20.382252177999899],
            [-42.970506015999902, -20.382155929],
            [-42.971544687999902, -20.38197625],
            [-42.97309279, -20.38175654],
            [-42.97436701, -20.381616219],
            [-42.975477473, -20.381520483],
            [-42.975762559, -20.381350301999898],
            [-42.976052914, -20.381088218999899],
            [-42.976484893, -20.3790269859999],
            [-42.976609795999899, -20.377852339],
            [-42.976496117999901, -20.377188111],
            [-42.975740602, -20.374794010999899],
            [-42.975427328, -20.374105437],
            [-42.975427319999902, -20.373876174],
            [-42.975539237, -20.373655368999898],
            [-42.975707948, -20.373556221999898],
            [-42.975941667, -20.373543589999901],
            [-42.978473946, -20.373449454],
            [-42.979332235999898, -20.3733325389999],
            [-42.980088623, -20.373014028999901],
            [-42.981187602, -20.372512861],
            [-42.982018785, -20.372095762],
            [-42.982858344, -20.371672947],
            [-42.983584999999898, -20.371106996],
            [-42.983657999999899, -20.370887996],
            [-42.983683, -20.370657996],
            [-42.983683, -20.370117996],
            [-42.983609999999899, -20.3698429959999],
            [-42.98355, -20.369623996],
            [-42.98344, -20.369428996],
            [-42.983342999999898, -20.369152996],
            [-42.983209, -20.368968996],
            [-42.983088, -20.3687619959999],
            [-42.982844, -20.368600995999898],
            [-42.982674, -20.368451996],
            [-42.982491, -20.368313995999898],
            [-42.982284, -20.368129995999901],
            [-42.982149999999898, -20.3679459959999],
            [-42.982041, -20.367749995999901],
            [-42.981991999999899, -20.367485996],
            [-42.98198, -20.367221996],
            [-42.981981, -20.366508995999901],
            [-42.982006, -20.366278995999899],
            [-42.982152, -20.366003996],
            [-42.982298, -20.3657859959999],
            [-42.982335, -20.365509996],
            [-42.98236, -20.365233996],
            [-42.982371999999899, -20.364969996],
            [-42.982371999999899, -20.364739996],
            [-42.982288618, -20.364481344],
            [-42.982313054, -20.364103214999901],
            [-42.982501758, -20.363836868],
            [-42.982723123, -20.363707561999899],
            [-42.983320789999901, -20.3636118229999],
            [-42.984482027, -20.363560692],
            [-42.984737, -20.363512995999901],
            [-42.984932, -20.363351995999899],
            [-42.985152, -20.363144996],
            [-42.985335, -20.362984996],
            [-42.985706832999902, -20.362645866],
            [-42.986997651, -20.361388954999899],
            [-42.987842451999903, -20.360339913],
            [-42.988076919, -20.35978369],
            [-42.988040919, -20.35956469],
            [-42.987781398, -20.359281772],
            [-42.987380608, -20.359020245],
            [-42.986613403, -20.358904720999899],
            [-42.985969511, -20.358924809],
            [-42.984977064, -20.3590889879999],
            [-42.984097774, -20.359268818],
            [-42.983596, -20.359332735999899],
            [-42.982661188, -20.359448782999898],
            [-42.982168714999901, -20.359426087],
            [-42.981128203, -20.35933362],
            [-42.980641268999896, -20.3592155169999],
            [-42.980327042999903, -20.358993034],
            [-42.979905075, -20.358539648],
            [-42.979759693999902, -20.358165253],
            [-42.979646607999904, -20.357609512],
            [-42.979684634, -20.356955222],
            [-42.980054666999898, -20.355769344],
            [-42.980280445999902, -20.354893784],
            [-42.980464113, -20.353927574],
            [-42.980456091999898, -20.353741910999901],
            [-42.979793878, -20.3529083939999],
            [-42.979509768999897, -20.3524522],
            [-42.979355679, -20.352013312999901],
            [-42.979292889, -20.351735201999901],
            [-42.979292694, -20.3504093949999],
            [-42.9792182529999, -20.349427153],
            [-42.979301478, -20.348784032],
            [-42.979442773999899, -20.348116655],
            [-42.979830999999898, -20.3465149959999],
            [-42.979951715, -20.346146389999902],
            [-42.980193199, -20.345718177],
            [-42.980461198999897, -20.345408194999901],
            [-42.9807051989999, -20.345221529],
            [-42.981192301, -20.345065045],
            [-42.981758554, -20.344840376],
            [-42.982555080999902, -20.344601253999901],
            [-42.983151, -20.344296996],
            [-42.982294, -20.344321996],
            [-42.982062, -20.344310995999901],
            [-42.981795, -20.3442649959999],
            [-42.981502, -20.344217995999902],
            [-42.981259, -20.344183996],
            [-42.980953999999898, -20.344113996],
            [-42.980735, -20.344010996],
            [-42.98054, -20.343872996],
            [-42.980382, -20.343665996],
            [-42.980187, -20.343504996],
            [-42.980004999999899, -20.343331996],
            [-42.979664, -20.342929996],
            [-42.979469, -20.342722996],
            [-42.979323, -20.342538996],
            [-42.979141, -20.342388996],
            [-42.978969999999897, -20.342239995999901],
            [-42.978739, -20.342112996],
            [-42.978495, -20.3420559959999],
            [-42.978251999999898, -20.341974996],
            [-42.977984, -20.341905996],
            [-42.977716, -20.341824996],
            [-42.977436, -20.341767995999898],
            [-42.97718, -20.341766995999901],
            [-42.976936, -20.3417899959999],
            [-42.976705, -20.341800996],
            [-42.976437, -20.341846996],
            [-42.976155999999897, -20.341961996],
            [-42.975912999999899, -20.342030995999899],
            [-42.975742, -20.3422599959999],
            [-42.975474, -20.3422599959999],
            [-42.975242, -20.342247996],
            [-42.97495, -20.342224996],
            [-42.974719, -20.342178996],
            [-42.974414, -20.342120995999899],
            [-42.974171, -20.342063996],
            [-42.973939, -20.341959996],
            [-42.973708, -20.341901995999901],
            [-42.973452, -20.341752996],
            [-42.973233, -20.341660996],
            [-42.973002, -20.341349996],
            [-42.972783, -20.341246995999899],
            [-42.972539, -20.341096996],
            [-42.972247, -20.340946996],
            [-42.971991, -20.340785996],
            [-42.971772, -20.340670996],
            [-42.971468, -20.340567995999901],
            [-42.971176, -20.340405996],
            [-42.970944, -20.3402799959999],
            [-42.970688, -20.340164995999899],
            [-42.970433, -20.340060996],
            [-42.970201, -20.339956996],
            [-42.969969999999897, -20.339818996],
            [-42.969751, -20.339703996],
            [-42.969544, -20.3395779959999],
            [-42.969337, -20.339462996],
            [-42.969106, -20.339324996],
            [-42.968899, -20.339208996],
            [-42.968704, -20.339082995999899],
            [-42.968521, -20.338932996],
            [-42.968315, -20.338656995999902],
            [-42.968156, -20.338449996],
            [-42.96801, -20.3382549959999],
            [-42.967816, -20.338024995999898],
            [-42.967658, -20.337794996],
            [-42.967512, -20.337587996],
            [-42.967377999999897, -20.337346996],
            [-42.967305, -20.337070996],
            [-42.967293, -20.336852995999902],
            [-42.967306, -20.336622996],
            [-42.967306, -20.336358995999898],
            [-42.967329999999897, -20.336116995999902],
            [-42.967379, -20.3358419959999],
            [-42.967416, -20.335519996],
            [-42.967441, -20.335232995999899],
            [-42.967466, -20.335013995999901],
            [-42.967478, -20.3347849959999],
            [-42.967478, -20.334255996],
            [-42.967467, -20.334002996],
            [-42.967467, -20.333738996],
            [-42.967443, -20.333497995999899],
            [-42.967406, -20.333244996],
            [-42.96737, -20.333003995999899],
            [-42.967284999999897, -20.332761995999899],
            [-42.967199999999899, -20.332531995999901],
            [-42.967078, -20.332301996],
            [-42.966993, -20.3320609959999],
            [-42.966872, -20.331842996],
            [-42.966799, -20.331589995999899],
            [-42.966726, -20.331370996],
            [-42.966653, -20.331060996],
            [-42.966507, -20.330830996],
            [-42.966422, -20.330531995999898],
            [-42.966313, -20.330290996],
            [-42.966203999999898, -20.330095996],
            [-42.966119, -20.329761995999899],
            [-42.966082, -20.329497995999901],
            [-42.965997, -20.329290995999902],
            [-42.965851, -20.329049996],
            [-42.965778999999898, -20.328692996],
            [-42.965730999999899, -20.328164995999899],
            [-42.965719, -20.327934996],
            [-42.965695, -20.327624995999901],
            [-42.965658, -20.327371996],
            [-42.96561, -20.326992995999898],
            [-42.965561, -20.326773995999901],
            [-42.965488999999899, -20.326509996],
            [-42.965282, -20.326291996],
            [-42.965063, -20.326141995999901],
            [-42.964868, -20.325968996],
            [-42.964624, -20.325876996],
            [-42.964357, -20.325842995999899],
            [-42.964089, -20.325795996],
            [-42.963845, -20.325680996],
            [-42.963626, -20.325554995999902],
            [-42.963394999999899, -20.325381996],
            [-42.96309, -20.325243995999902],
            [-42.962774, -20.325197995999901],
            [-42.962468999999899, -20.325139996],
            [-42.962226, -20.325024996],
            [-42.961885, -20.324897995999901],
            [-42.96158, -20.324736996],
            [-42.961337, -20.324655995999901],
            [-42.961008, -20.324586995999901],
            [-42.960813, -20.324391996],
            [-42.960619, -20.324230996],
            [-42.960448, -20.324023995999902],
            [-42.960265999999898, -20.323827996],
            [-42.96012, -20.3235519959999],
            [-42.960011, -20.323321995999901],
            [-42.959901, -20.3230579959999],
            [-42.959829, -20.322816996],
            [-42.959744, -20.3225639959999],
            [-42.959537, -20.322126996],
            [-42.959391, -20.321884995999898],
            [-42.95888, -20.321562996],
            [-42.958648, -20.321470996],
            [-42.958393, -20.321344995999901],
            [-42.958174, -20.321263996],
            [-42.95793, -20.321171996],
            [-42.957673999999898, -20.321079996],
            [-42.957443, -20.3209639959999],
            [-42.9572, -20.320860996],
            [-42.956932, -20.320733995999898],
            [-42.956615, -20.320652996],
            [-42.956347, -20.320560995999902],
            [-42.956079, -20.320526996],
            [-42.955762999999898, -20.320502996],
            [-42.955495, -20.320445995999901],
            [-42.955142, -20.320410996],
            [-42.954849, -20.3203759959999],
            [-42.954594, -20.320340996],
            [-42.95435, -20.320294996],
            [-42.954058, -20.320190996],
            [-42.953826999999897, -20.320075996],
            [-42.953656, -20.319914996],
            [-42.953608, -20.319684996],
            [-42.953572, -20.3194329959999],
            [-42.95356, -20.319202995999898],
            [-42.953572, -20.318984995999902],
            [-42.9535845, -20.318858496],
            [-42.951448147999898, -20.318547418],
            [-42.951244443999897, -20.318519793],
            [-42.949433182999897, -20.318672331999899],
            [-42.948532265, -20.318748205],
            [-42.94674714, -20.318800886],
            [-42.9458226469999, -20.318907203],
            [-42.944254472, -20.319274614999902],
            [-42.943164789, -20.319971451],
            [-42.9411158939999, -20.322049399],
            [-42.939943812, -20.323354641],
            [-42.938854411, -20.324117407],
            [-42.937650462, -20.324638593],
            [-42.936959467999898, -20.3248070829999],
            [-42.936244753, -20.324793434],
            [-42.935218039999903, -20.32455336],
            [-42.934927385, -20.324455792],
            [-42.934164734, -20.324658650999901],
            [-42.933754867999902, -20.324552006],
            [-42.931853014999902, -20.327005437],
            [-42.9312619029999, -20.327664071],
            [-42.931093679999897, -20.328279084],
            [-42.930893254999901, -20.32933431],
            [-42.930376407, -20.329946376999899],
            [-42.929527946, -20.330600455],
            [-42.929008479999901, -20.331114066999898],
            [-42.929314941, -20.331957402999901],
            [-42.929532981, -20.332939888999899],
            [-42.92950739, -20.333298526],
            [-42.928714806, -20.333761659999901],
            [-42.928027513, -20.333941116999899],
            [-42.9274702, -20.333864535],
            [-42.9268713499999, -20.333897007],
            [-42.926113574999903, -20.3338911879999],
            [-42.925508056, -20.333993285],
            [-42.925187002, -20.334066896],
            [-42.924796365, -20.334591010999901],
            [-42.924454592, -20.336930933],
            [-42.924345251, -20.337890303999899],
            [-42.923529131999899, -20.339262611],
            [-42.9231496089999, -20.339609417],
            [-42.92209981, -20.339761791999901],
            [-42.920730653, -20.340311750999899],
            [-42.920365014, -20.340508432],
            [-42.920230328999899, -20.3407380969999],
            [-42.920073713, -20.341249326],
            [-42.920244990999898, -20.343957004999901],
            [-42.919990475, -20.344691666],
            [-42.919707794, -20.345291076],
            [-42.918906431, -20.346163906999902],
            [-42.917922788999903, -20.3469056389999],
            [-42.917662035, -20.3471004469999],
            [-42.916970599, -20.348223944]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 141,
        "id": 37052,
        "CD_SETOR": "315210513000008P",
        "AREA_KM2": 0.538766,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210513",
        "NM_DIST": "Rosário do Pontal",
        "CD_SUBDIST": "31521051300",
        "NM_SUBDIST": null,
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 29
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.8992547779999, -20.3455079679999],
            [-42.899326776, -20.3455283169999],
            [-42.899814367, -20.345666129999898],
            [-42.901223946, -20.345334748],
            [-42.901780054, -20.34507009],
            [-42.902603629999902, -20.34483009],
            [-42.903115157999899, -20.344598545999901],
            [-42.903466464, -20.344386412999899],
            [-42.904786021999897, -20.344127608],
            [-42.907592903, -20.344574495],
            [-42.907725307, -20.344647342],
            [-42.908001075, -20.3447741589999],
            [-42.908229088, -20.344865034999899],
            [-42.908475725, -20.344945669],
            [-42.908829198, -20.345152603],
            [-42.909120973, -20.345233755],
            [-42.9094387069999, -20.3453607],
            [-42.909658729999897, -20.345464474],
            [-42.909927414, -20.345533415],
            [-42.910160168999901, -20.345659612],
            [-42.910380972, -20.345784389],
            [-42.910662871999897, -20.345920253],
            [-42.910980681999902, -20.346100774],
            [-42.91124417, -20.346206572],
            [-42.911741371999902, -20.346420042],
            [-42.911962581999902, -20.346554967],
            [-42.912161254999901, -20.34668599],
            [-42.91239747, -20.346794496],
            [-42.91267277, -20.346968777],
            [-42.912911823999899, -20.347064593],
            [-42.913116037999899, -20.347191549999899],
            [-42.913379878999898, -20.347319728999899],
            [-42.913597751999902, -20.347412484],
            [-42.913851039999898, -20.347527831],
            [-42.914082349999902, -20.347688677999901],
            [-42.914388685999903, -20.347824805999899],
            [-42.9146214209999, -20.347948815999899],
            [-42.914842174999897, -20.348050875999899],
            [-42.91507616, -20.348152717999898],
            [-42.91534558, -20.3482421319999],
            [-42.915641407, -20.348377121999899],
            [-42.915936362, -20.3484899749999],
            [-42.916145426999897, -20.3485907909999],
            [-42.916452202, -20.348760957],
            [-42.916648705999897, -20.348885502],
            [-42.916930618, -20.349056747],
            [-42.918839891999902, -20.349917886],
            [-42.919157028, -20.350023431],
            [-42.920452338, -20.35051606],
            [-42.920960086, -20.350616108999901],
            [-42.921180177999901, -20.350100521999899],
            [-42.92121458, -20.350059299],
            [-42.921293090999903, -20.349993518],
            [-42.921341975, -20.349898198999899],
            [-42.921347467, -20.3498791879999],
            [-42.921374682, -20.349758112],
            [-42.921215795, -20.349707011],
            [-42.921106815, -20.349680387],
            [-42.920939232, -20.349699733],
            [-42.920779019, -20.349710494],
            [-42.920545961, -20.3497057599999],
            [-42.9203524119999, -20.34970021],
            [-42.92004718, -20.349753819999901],
            [-42.91987469, -20.349797322],
            [-42.919693265, -20.349816256999901],
            [-42.919538614, -20.34972534],
            [-42.919405336999901, -20.349612088999901],
            [-42.919259644, -20.349528695999901],
            [-42.919163910999899, -20.349463903],
            [-42.918984332, -20.349317679],
            [-42.918887282, -20.34916694],
            [-42.918712136, -20.3487485869999],
            [-42.9185271919999, -20.3487377669999],
            [-42.918302416, -20.348868062],
            [-42.917989615, -20.348790471],
            [-42.917853728, -20.348694883],
            [-42.917699227, -20.348570451999901],
            [-42.9175886089999, -20.348488322],
            [-42.917525069, -20.348479165999901],
            [-42.9174929, -20.348445378],
            [-42.917340963, -20.348377205],
            [-42.916970599, -20.348223944],
            [-42.916363293, -20.3478668989999],
            [-42.916119339, -20.347729488],
            [-42.915890775, -20.3475604889999],
            [-42.915505788, -20.3473080169999],
            [-42.9152252109999, -20.347117424],
            [-42.915020107, -20.346986398],
            [-42.914459577999899, -20.346638141],
            [-42.913778518, -20.346177511999901],
            [-42.912869891999897, -20.345585055],
            [-42.91277947, -20.345334646],
            [-42.912782788, -20.344896430999899],
            [-42.912555353, -20.344370673],
            [-42.912756817999899, -20.344210857],
            [-42.913146523, -20.3440469559999],
            [-42.912993763, -20.343777079999899],
            [-42.913440264, -20.343422092],
            [-42.913950489999898, -20.343694451],
            [-42.914345044, -20.34397396],
            [-42.914611787999903, -20.343636842],
            [-42.914808262999898, -20.343017484],
            [-42.914516105, -20.342866877999899],
            [-42.914042211, -20.342702378999899],
            [-42.913847112, -20.342425853999899],
            [-42.91336359, -20.3422385759999],
            [-42.913117873, -20.342091605999901],
            [-42.912673004999903, -20.341863336],
            [-42.911816273999897, -20.3419237059999],
            [-42.911828279, -20.34223865],
            [-42.911748743, -20.342486097999899],
            [-42.911728936, -20.342560243],
            [-42.911724008, -20.342667666],
            [-42.911729029999897, -20.342688692],
            [-42.91155091, -20.342649492],
            [-42.911169125, -20.342493019],
            [-42.910793618, -20.342398864],
            [-42.910417689, -20.342329634999899],
            [-42.910363636999897, -20.342368988],
            [-42.910301115, -20.342414507999901],
            [-42.910241814, -20.342464171],
            [-42.910027778, -20.342455201],
            [-42.909972218, -20.342451533],
            [-42.9099170329999, -20.342468353999902],
            [-42.909844537, -20.342510806999901],
            [-42.909752114999897, -20.3425213259999],
            [-42.909681670999902, -20.342535285999901],
            [-42.909602466, -20.342605981],
            [-42.909565181999902, -20.3426822549999],
            [-42.909547245999903, -20.342737778999901],
            [-42.909567787999897, -20.342798634],
            [-42.909571979, -20.342896589],
            [-42.90958776, -20.342965057],
            [-42.909642272, -20.343024822],
            [-42.909656692999903, -20.343078493],
            [-42.9096476019999, -20.343137576999901],
            [-42.909606853, -20.343234767999899],
            [-42.909586181, -20.34329852],
            [-42.909590030999901, -20.343345159],
            [-42.909644527, -20.343414440999901],
            [-42.909711957, -20.3435605889999],
            [-42.909728356, -20.34362485],
            [-42.909715626, -20.343691094],
            [-42.909673039, -20.343792605],
            [-42.909668439, -20.343871651],
            [-42.9096612129999, -20.343943023999898],
            [-42.909512778, -20.343936953],
            [-42.909396640999901, -20.343918674],
            [-42.909272422, -20.343888148],
            [-42.909193504, -20.343851809999901],
            [-42.909153751, -20.3438390919999],
            [-42.908994637, -20.343803588],
            [-42.908909778, -20.343786328],
            [-42.9087737199999, -20.343744678999901],
            [-42.908615418, -20.343686117999901],
            [-42.908511843, -20.343622898],
            [-42.908453564, -20.343584442999902],
            [-42.908323757999902, -20.343499258999898],
            [-42.90827108, -20.343454482],
            [-42.90820559, -20.343404166],
            [-42.908139639, -20.343322413],
            [-42.908071494999902, -20.343271612],
            [-42.907788604, -20.343090532],
            [-42.90767468, -20.3433806799999],
            [-42.907331172999903, -20.343199802999901],
            [-42.906915376, -20.342978005],
            [-42.906302194, -20.342633281],
            [-42.905967344, -20.342407668],
            [-42.905734055, -20.34222492],
            [-42.9054775339999, -20.341983601],
            [-42.905048332, -20.341608293],
            [-42.904915011999897, -20.341533985999899],
            [-42.904775089999902, -20.341494915999899],
            [-42.904558758999897, -20.341450901],
            [-42.904235173, -20.3414144],
            [-42.904037097999897, -20.341426574],
            [-42.903723323, -20.341495707],
            [-42.903219678, -20.341678993],
            [-42.902995833, -20.341747515999899],
            [-42.902798980999897, -20.3417600229999],
            [-42.902639913, -20.341757226],
            [-42.902337149999902, -20.341733527],
            [-42.901971911, -20.341700819],
            [-42.901729098, -20.3416683569999],
            [-42.901560821, -20.341641614],
            [-42.901381135, -20.341598825999899],
            [-42.901178903999899, -20.341502798],
            [-42.901038931, -20.341400064],
            [-42.900905307999899, -20.341298813],
            [-42.900831470999897, -20.34128178],
            [-42.900751473999897, -20.341320557],
            [-42.900668954, -20.341556769],
            [-42.900150895, -20.343091786999899],
            [-42.899704855, -20.344428986],
            [-42.899335316, -20.345504697],
            [-42.8992547779999, -20.3455079679999]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 142,
        "id": 37053,
        "CD_SETOR": "315210515000001P",
        "AREA_KM2": 0.315296,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210515",
        "NM_DIST": "Vau-Açu",
        "CD_SUBDIST": "31521051500",
        "NM_SUBDIST": null,
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 76
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.8996415679999, -20.525917799],
            [-42.899759079, -20.52581401],
            [-42.89977555, -20.525807832999899],
            [-42.899837485, -20.525750756],
            [-42.899907277999901, -20.525679813999901],
            [-42.899958435, -20.525533336],
            [-42.899981426999901, -20.525343672],
            [-42.900029157999903, -20.525251445],
            [-42.900029001, -20.525190358],
            [-42.900054575999903, -20.525133051999902],
            [-42.900047457, -20.525064444999899],
            [-42.900140416999903, -20.524995127999901],
            [-42.900192273, -20.524934293999902],
            [-42.900157697999902, -20.524897584],
            [-42.900156073999902, -20.524829904999901],
            [-42.900178382, -20.524728857],
            [-42.900194757999898, -20.524625939],
            [-42.900209326, -20.524493622],
            [-42.900210767, -20.524449343999901],
            [-42.900212338, -20.524401091],
            [-42.900215263999897, -20.524311222999899],
            [-42.900226467, -20.52416893],
            [-42.900227785, -20.5241147519999],
            [-42.900235278, -20.523806729999901],
            [-42.900235224, -20.523736998999901],
            [-42.900288100999902, -20.523644023],
            [-42.900331114, -20.523553637],
            [-42.900380573999897, -20.523479553],
            [-42.900393715999897, -20.523401066],
            [-42.900408620999897, -20.523356615],
            [-42.900440584, -20.523279339999899],
            [-42.900478215, -20.523171783999899],
            [-42.900571936999903, -20.523093786999901],
            [-42.900594780999903, -20.523073914999902],
            [-42.900596282, -20.52302581],
            [-42.900569638, -20.522969009999901],
            [-42.900534644999901, -20.522913663999901],
            [-42.900595231999901, -20.5228509149999],
            [-42.900605868, -20.52273981],
            [-42.9006255789999, -20.522672175],
            [-42.900636903, -20.522633321999901],
            [-42.900645373, -20.522546039],
            [-42.900646094, -20.522538607999898],
            [-42.900645803, -20.522483432999898],
            [-42.900637425999903, -20.522386851],
            [-42.900583898, -20.522257502999899],
            [-42.900554133999897, -20.522179175],
            [-42.900531688999898, -20.522108409999898],
            [-42.900496147999903, -20.522054447999899],
            [-42.900492185, -20.522013983],
            [-42.900428147, -20.521975934999901],
            [-42.900386693, -20.521924467],
            [-42.900318055999897, -20.5218435479999],
            [-42.900260019, -20.521800943],
            [-42.900242638, -20.521757219999898],
            [-42.900197196, -20.521709268999899],
            [-42.900189059, -20.521666314],
            [-42.900079439, -20.521586086],
            [-42.899963753, -20.521537259999899],
            [-42.899890324999902, -20.521493749999902],
            [-42.899710237, -20.521350985],
            [-42.899609238, -20.521303748],
            [-42.899489683999903, -20.521268065],
            [-42.899419069, -20.5212121819999],
            [-42.899382165, -20.521144761],
            [-42.899325102, -20.521040512],
            [-42.899287312999903, -20.520950797],
            [-42.899278514999899, -20.520848568],
            [-42.89920388, -20.5207357809999],
            [-42.899205439, -20.520684785999901],
            [-42.899174318, -20.520616824999902],
            [-42.899152414999897, -20.520558214999902],
            [-42.899096103, -20.520425598],
            [-42.899076582, -20.520323673],
            [-42.899089796999903, -20.520210678999899],
            [-42.8990909199999, -20.520137881],
            [-42.899043146999901, -20.520027042],
            [-42.899094818, -20.519963983],
            [-42.899050941, -20.519879064],
            [-42.899093729, -20.519855107999899],
            [-42.899155153, -20.519780475999902],
            [-42.899201634999898, -20.519724779999901],
            [-42.899249373, -20.5196675779999],
            [-42.899263340999902, -20.519574129],
            [-42.899201640999898, -20.519524896999901],
            [-42.899194902, -20.519450051],
            [-42.899164885999902, -20.519384284999902],
            [-42.899156516999902, -20.5193132429999],
            [-42.899227587, -20.519301868],
            [-42.899276947, -20.519284969999902],
            [-42.899343121, -20.519260043],
            [-42.899417894, -20.519224316],
            [-42.899443418, -20.51916218],
            [-42.899444420999899, -20.519089073],
            [-42.899435608, -20.519057021],
            [-42.899416683, -20.518988189],
            [-42.899405691, -20.518906473999898],
            [-42.899396855, -20.518818312999901],
            [-42.899393271999898, -20.518769829999901],
            [-42.89938677, -20.518681855999901],
            [-42.899383939, -20.518545944],
            [-42.899385241999902, -20.518359581],
            [-42.899432798, -20.5183107299999],
            [-42.899489550999903, -20.5182629409999],
            [-42.899526914, -20.518243067],
            [-42.899568750999897, -20.518223508999899],
            [-42.89963947, -20.51817263],
            [-42.899713285, -20.518139279],
            [-42.899771825999899, -20.518111593],
            [-42.899804835999902, -20.51808557],
            [-42.899809828999899, -20.518040608],
            [-42.899815896999897, -20.5179942779999],
            [-42.899849731, -20.517945574],
            [-42.899888475, -20.51792053],
            [-42.899969992, -20.517869296999901],
            [-42.900001695, -20.517849645],
            [-42.900023158, -20.517831231999899],
            [-42.900014360999897, -20.5178021309999],
            [-42.899965957999903, -20.517764198999899],
            [-42.89985329, -20.517696542],
            [-42.899805124, -20.517651472999901],
            [-42.899839014, -20.5174964559999],
            [-42.899789187, -20.5174189529999],
            [-42.899758925999897, -20.517390571],
            [-42.899718799, -20.517358746],
            [-42.899708331999904, -20.517259587999899],
            [-42.899710327, -20.517214527],
            [-42.899700364, -20.517184539],
            [-42.899683665999902, -20.517143613],
            [-42.899655329, -20.5171695249999],
            [-42.899601984999897, -20.5171838979999],
            [-42.899590678, -20.517193662999901],
            [-42.899562562, -20.517221398999901],
            [-42.899546867, -20.517232063999899],
            [-42.899531015, -20.517214144],
            [-42.8994401369999, -20.517211833],
            [-42.899398416999901, -20.517280012],
            [-42.899373398, -20.517289617],
            [-42.899276949999901, -20.517276076],
            [-42.899151804, -20.5172206569999],
            [-42.89911484, -20.517207277999901],
            [-42.89907799, -20.517211352999901],
            [-42.899025779999903, -20.5172172269999],
            [-42.899019329999902, -20.517264836],
            [-42.899035203, -20.517286125999899],
            [-42.8990454, -20.51730881],
            [-42.899017605, -20.517318218],
            [-42.898990164999901, -20.517297366],
            [-42.898781754, -20.517353473999901],
            [-42.898713395, -20.517378499],
            [-42.898338537, -20.517339997],
            [-42.898071036, -20.517309757],
            [-42.897982253999899, -20.517344308],
            [-42.897794507, -20.517319339],
            [-42.897619835999897, -20.5172858349999],
            [-42.897360982, -20.5172757],
            [-42.897266064999897, -20.5172714129999],
            [-42.897144343, -20.517264074],
            [-42.896997206, -20.517255204],
            [-42.896952067, -20.517223568],
            [-42.896924445, -20.5173073509999],
            [-42.896898795, -20.517386976999902],
            [-42.896868041999902, -20.517481308999901],
            [-42.896713169999899, -20.51783474],
            [-42.896608909, -20.518072671],
            [-42.896551642, -20.518203358999902],
            [-42.896341623, -20.5186641499999],
            [-42.896249775999898, -20.518870805999899],
            [-42.895903566999898, -20.5196688149999],
            [-42.8955890189999, -20.520436428],
            [-42.895466262999904, -20.520735997],
            [-42.895298012, -20.521119975],
            [-42.894958965999898, -20.5218921049999],
            [-42.894802840999901, -20.522242429],
            [-42.894655239, -20.522573063],
            [-42.89468264, -20.522596549],
            [-42.8947067779999, -20.522605129999899],
            [-42.894810462999899, -20.522646606],
            [-42.895042579999902, -20.522760891],
            [-42.895191525, -20.52285109],
            [-42.895404275, -20.522968373],
            [-42.896004794, -20.52329973],
            [-42.89629653, -20.523482401],
            [-42.896487479, -20.523748537],
            [-42.896779306, -20.523354328],
            [-42.896978869, -20.523095331],
            [-42.897119116999903, -20.522907243],
            [-42.897268601, -20.522883072],
            [-42.897299301, -20.522887015],
            [-42.897385223, -20.522907688],
            [-42.897916468999902, -20.5230225349999],
            [-42.898345574, -20.52314725],
            [-42.8983213879999, -20.523299401],
            [-42.898297543999902, -20.523591553],
            [-42.898370692999897, -20.523930570999902],
            [-42.898340138, -20.523982573999898],
            [-42.898315491999902, -20.524001359],
            [-42.898229865, -20.524034768],
            [-42.898800159, -20.524612734],
            [-42.898973999, -20.524761261],
            [-42.899036615999897, -20.524888834],
            [-42.899098282, -20.52499276],
            [-42.899159654999899, -20.525132625],
            [-42.899211982999901, -20.525285088],
            [-42.8992938539999, -20.525415509],
            [-42.899372442, -20.525523021],
            [-42.899122868999903, -20.525822987],
            [-42.899036477, -20.525952875],
            [-42.899085336, -20.526042418],
            [-42.899151930999899, -20.526150311],
            [-42.899334814, -20.526007755],
            [-42.8996415679999, -20.525917799]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 143,
        "id": 37054,
        "CD_SETOR": "315210515000003P",
        "AREA_KM2": 26.647745,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210515",
        "NM_DIST": "Vau-Açu",
        "CD_SUBDIST": "31521051500",
        "NM_SUBDIST": null,
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 77
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.926799108999901, -20.492638676],
            [-42.926841566999897, -20.492989741],
            [-42.926708356999903, -20.494037528],
            [-42.926177539, -20.495358481],
            [-42.925573809, -20.496486036],
            [-42.925360708, -20.497084211],
            [-42.925104856, -20.498206517],
            [-42.924937416, -20.49852827],
            [-42.924400917999897, -20.499155655],
            [-42.923844007, -20.499604285],
            [-42.922659989, -20.500024988],
            [-42.92150271, -20.500171813],
            [-42.920552160999897, -20.500396383],
            [-42.920404429, -20.500586752999901],
            [-42.920177082, -20.501183597],
            [-42.919976866999903, -20.5020625729999],
            [-42.919999208, -20.502493713],
            [-42.91991358, -20.502848839],
            [-42.917991607, -20.504248844],
            [-42.917115689999903, -20.505086849999898],
            [-42.916601804, -20.505869565999902],
            [-42.915486868, -20.510025890999898],
            [-42.91529287, -20.510786432],
            [-42.915087109, -20.5112890579999],
            [-42.9146921829999, -20.512132827999899],
            [-42.914155646, -20.513782116],
            [-42.913925685, -20.514387079999899],
            [-42.913541140999897, -20.515073361],
            [-42.913264593, -20.51525964],
            [-42.912771627, -20.515276014999898],
            [-42.912436221, -20.51536134],
            [-42.911873676, -20.515674475],
            [-42.911199632, -20.516364360999901],
            [-42.910329492, -20.5173469339999],
            [-42.909811001, -20.517945219999898],
            [-42.910644342999902, -20.518813078999901],
            [-42.91114426, -20.519567359],
            [-42.91191451, -20.520352],
            [-42.912297205999899, -20.5206694029999],
            [-42.91255059, -20.521038389],
            [-42.913257574999903, -20.522067917999902],
            [-42.91378752, -20.522938731999901],
            [-42.914034321, -20.523507606],
            [-42.914267418, -20.5237848209999],
            [-42.914354427, -20.524014866],
            [-42.914360034, -20.5242720809999],
            [-42.914198101, -20.524686416999899],
            [-42.913939875, -20.5251307],
            [-42.913659373, -20.525587668999901],
            [-42.913593428, -20.525968399],
            [-42.913921435, -20.52701554],
            [-42.914579142, -20.527834173],
            [-42.914914841, -20.528221609],
            [-42.916035447, -20.529253201],
            [-42.917417106999899, -20.530354444],
            [-42.917506683999903, -20.530570614999899],
            [-42.917567791, -20.5309170959999],
            [-42.917839767, -20.531864245999898],
            [-42.918369393, -20.532985281],
            [-42.918323, -20.533052996],
            [-42.918414, -20.532983995999899],
            [-42.918584, -20.532719996],
            [-42.918790999999899, -20.5324659959999],
            [-42.918985, -20.532270996],
            [-42.919106999999897, -20.532074995999899],
            [-42.919277, -20.531879995999901],
            [-42.919484, -20.531752996],
            [-42.919679, -20.531613996],
            [-42.919897999999897, -20.531475995999902],
            [-42.920153, -20.531348996],
            [-42.920397, -20.531244995999899],
            [-42.920677, -20.5311529959999],
            [-42.920921, -20.531071996],
            [-42.921200999999897, -20.530944996],
            [-42.921481, -20.530828996],
            [-42.921847, -20.530759996],
            [-42.922126999999897, -20.530689996],
            [-42.922456, -20.530573995999902],
            [-42.922699, -20.530515996],
            [-42.923053, -20.530400996],
            [-42.923296, -20.530261996],
            [-42.923515, -20.530112996],
            [-42.923807, -20.529824995999899],
            [-42.924014, -20.529708996],
            [-42.924246, -20.529593996],
            [-42.924501, -20.529443995999902],
            [-42.924732999999897, -20.529339996],
            [-42.925049, -20.529178995999899],
            [-42.925293, -20.529005996],
            [-42.925524, -20.528832995999899],
            [-42.925828, -20.528625996],
            [-42.926084, -20.528613995999901],
            [-42.926364, -20.5284749959999],
            [-42.926583, -20.528370996],
            [-42.926815, -20.528243996],
            [-42.927034, -20.528059996],
            [-42.927228, -20.527932995999901],
            [-42.927448, -20.527817996],
            [-42.927691, -20.527667995999899],
            [-42.927934, -20.527529995999899],
            [-42.928154, -20.527448996],
            [-42.92841, -20.527470996],
            [-42.928642, -20.5275969959999],
            [-42.928801, -20.527768996],
            [-42.928972, -20.527963996],
            [-42.929119, -20.5281359959999],
            [-42.929278, -20.528307996],
            [-42.929498, -20.5285019959999],
            [-42.929643999999897, -20.528673996],
            [-42.929876, -20.528811995999899],
            [-42.930095999999899, -20.528936996],
            [-42.93034, -20.529005996],
            [-42.930608, -20.529004996],
            [-42.931071, -20.528785996],
            [-42.931303, -20.528738996],
            [-42.931595, -20.528715996],
            [-42.931826999999899, -20.528691996],
            [-42.932449, -20.528690996],
            [-42.932729, -20.528712996],
            [-42.932985, -20.528723995999901],
            [-42.933217, -20.528814995999898],
            [-42.933376, -20.5289759959999],
            [-42.93362, -20.5290209959999],
            [-42.933852, -20.5290659959999],
            [-42.934108, -20.529111995999902],
            [-42.934376, -20.529156995999902],
            [-42.934632, -20.529155995999901],
            [-42.934901, -20.529143996],
            [-42.935181, -20.529108995999898],
            [-42.935473, -20.5290859959999],
            [-42.935717, -20.529038995999901],
            [-42.935949, -20.529026996],
            [-42.936217, -20.5289689959999],
            [-42.936448, -20.528898996],
            [-42.936704, -20.528852996],
            [-42.936936, -20.5287719959999],
            [-42.937192, -20.5287479959999],
            [-42.937448, -20.528689996],
            [-42.937716, -20.528666996],
            [-42.937947, -20.528562995999899],
            [-42.93813, -20.528389995999898],
            [-42.938409, -20.528182995999899],
            [-42.938616, -20.5280669959999],
            [-42.93886, -20.527939995999901],
            [-42.939042, -20.527801995999901],
            [-42.939298, -20.527709995999899],
            [-42.939542, -20.527662995999901],
            [-42.939908, -20.527638996],
            [-42.940188, -20.527638996],
            [-42.940481, -20.527625995999902],
            [-42.940944, -20.5275789959999],
            [-42.941273, -20.527520996],
            [-42.941565, -20.527497996],
            [-42.941907, -20.527484995999899],
            [-42.942175, -20.527484995999899],
            [-42.942455, -20.527471996],
            [-42.942698999999898, -20.527471996],
            [-42.942931, -20.527436995999899],
            [-42.943248, -20.527389996],
            [-42.943589, -20.527342996],
            [-42.943942, -20.5273309959999],
            [-42.944198, -20.527329995999899],
            [-42.944467, -20.527352995999902],
            [-42.944699, -20.527409996],
            [-42.944978999999897, -20.527488995999899],
            [-42.945235, -20.527568996],
            [-42.94548, -20.527648996],
            [-42.945772, -20.527762996],
            [-42.946004, -20.527842995999901],
            [-42.946284999999897, -20.5279109959999],
            [-42.946578, -20.5279559959999],
            [-42.947468, -20.527953996],
            [-42.947773, -20.527941996],
            [-42.948040999999897, -20.527894995999901],
            [-42.948309, -20.5277799959999],
            [-42.948527, -20.5275729959999],
            [-42.948722, -20.527364996],
            [-42.948782, -20.527031995999899],
            [-42.948854, -20.526801996],
            [-42.948866, -20.526503996],
            [-42.948865, -20.526273995999901],
            [-42.948816, -20.526043995999899],
            [-42.948876, -20.525825996],
            [-42.948973, -20.525618995999899],
            [-42.949118999999897, -20.525388995999901],
            [-42.949300999999899, -20.525146995999901],
            [-42.949471, -20.524985995999899],
            [-42.949677999999899, -20.524847995999899],
            [-42.949922, -20.524697995999901],
            [-42.950201999999898, -20.524650995999899],
            [-42.950433, -20.5245589959999],
            [-42.95075, -20.524465995999901],
            [-42.951128, -20.524372996],
            [-42.951408, -20.524280996],
            [-42.951761, -20.524187996],
            [-42.952066, -20.524163996],
            [-42.952359, -20.524151995999901],
            [-42.952956, -20.5241509959999],
            [-42.9532, -20.524160996],
            [-42.953432, -20.524206996],
            [-42.953676, -20.524251996],
            [-42.953968, -20.524319996],
            [-42.954212, -20.524388996],
            [-42.954469, -20.524456996],
            [-42.954713, -20.524490995999901],
            [-42.954956, -20.524443995999899],
            [-42.955188, -20.524339996],
            [-42.95537, -20.524201995999899],
            [-42.955553, -20.524028996],
            [-42.955674, -20.523810996],
            [-42.955881, -20.523625996],
            [-42.95599, -20.523361995999899],
            [-42.955989, -20.523108996],
            [-42.955964, -20.522856996],
            [-42.955902, -20.522615996],
            [-42.955779999999898, -20.522362996],
            [-42.955681, -20.522098996],
            [-42.955644, -20.521857995999898],
            [-42.955643, -20.521616996],
            [-42.955716, -20.521329996],
            [-42.955886, -20.521087995999899],
            [-42.956031, -20.5208919959999],
            [-42.956238, -20.520696996],
            [-42.956421, -20.520546996],
            [-42.956615, -20.520385996],
            [-42.956822, -20.520235995999901],
            [-42.957065, -20.520073996],
            [-42.957297, -20.5199929959999],
            [-42.957553, -20.519888996],
            [-42.957869, -20.519784995999899],
            [-42.958186, -20.519703996],
            [-42.958466, -20.519645996],
            [-42.958734999999898, -20.519621996],
            [-42.959088, -20.519620996],
            [-42.959356, -20.519666996],
            [-42.959612999999898, -20.5196999959999],
            [-42.959881, -20.519745995999902],
            [-42.960113, -20.519825996],
            [-42.960272, -20.520019996],
            [-42.960492, -20.520168995999899],
            [-42.960710999999897, -20.5202839959999],
            [-42.960944, -20.520443996],
            [-42.961163, -20.520557996],
            [-42.961395, -20.520694996],
            [-42.961652, -20.520831996],
            [-42.961896, -20.520842995999899],
            [-42.961963157, -20.520818157],
            [-42.962115, -20.520761996],
            [-42.962236, -20.520566996],
            [-42.962308999999898, -20.520347995999899],
            [-42.962356999999898, -20.520129996],
            [-42.962368, -20.519888996],
            [-42.962392, -20.519635996],
            [-42.962416, -20.5194059959999],
            [-42.962501, -20.519187996],
            [-42.962536, -20.518946996],
            [-42.962572, -20.5186589959999],
            [-42.962608, -20.518394995999898],
            [-42.962705, -20.5181649959999],
            [-42.96279, -20.517957996],
            [-42.962935, -20.517773996],
            [-42.96313, -20.517600995999899],
            [-42.963312, -20.517405996],
            [-42.963458, -20.517197995999901],
            [-42.963543, -20.516967995999899],
            [-42.96354, -20.516175996],
            [-42.963515, -20.515853996],
            [-42.963454, -20.5156249959999],
            [-42.963331, -20.515406996],
            [-42.963233, -20.515131996],
            [-42.963135, -20.514924995999898],
            [-42.963036, -20.514695995999901],
            [-42.962926, -20.514500996],
            [-42.962742999999897, -20.514259996],
            [-42.962608, -20.514052996],
            [-42.962558, -20.513834996],
            [-42.96257, -20.513559996],
            [-42.962594, -20.513329996],
            [-42.962703, -20.513099995999902],
            [-42.962885, -20.512915995999901],
            [-42.963067, -20.512765995999899],
            [-42.963311, -20.512661996],
            [-42.963591, -20.5125349959999],
            [-42.963847, -20.512407996],
            [-42.964139, -20.5123379959999],
            [-42.964407, -20.512222995999899],
            [-42.964663, -20.512164996],
            [-42.964967, -20.512071996],
            [-42.965211, -20.512001996],
            [-42.965455, -20.511966996],
            [-42.965772, -20.511920996],
            [-42.966136999999897, -20.511861995999901],
            [-42.966453999999899, -20.511780996],
            [-42.966697, -20.511687996],
            [-42.966929, -20.511583996],
            [-42.967148, -20.511491996],
            [-42.967379, -20.5113879959999],
            [-42.967611, -20.511341995999899],
            [-42.96783, -20.511191996],
            [-42.968024, -20.511041996],
            [-42.968268, -20.510822995999899],
            [-42.968462, -20.510638996],
            [-42.968657, -20.510430996],
            [-42.968815, -20.510258995999902],
            [-42.96907, -20.510073996],
            [-42.969252, -20.509924995999899],
            [-42.969447, -20.509716996],
            [-42.969605, -20.509555996],
            [-42.9698, -20.509405995999899],
            [-42.970384, -20.509128996],
            [-42.970652, -20.509082996],
            [-42.970896, -20.509035996],
            [-42.9712, -20.509000995999902],
            [-42.971516999999899, -20.508999995999901],
            [-42.971809999999898, -20.509021995999898],
            [-42.972115, -20.509078996],
            [-42.972359, -20.509146996],
            [-42.9727, -20.509122996],
            [-42.972944, -20.509007996],
            [-42.973224, -20.5088919959999],
            [-42.973430999999898, -20.508730995999901],
            [-42.973613, -20.508523996],
            [-42.973624, -20.508281995999901],
            [-42.973599, -20.508006995999899],
            [-42.97355, -20.507753996],
            [-42.9735, -20.507535995999898],
            [-42.973463, -20.507317995999902],
            [-42.973413999999899, -20.5070879959999],
            [-42.973401, -20.506823996],
            [-42.9734, -20.506502995999899],
            [-42.973436, -20.506249996],
            [-42.973458999999899, -20.505973996],
            [-42.973507, -20.5057329959999],
            [-42.97358, -20.505467995999901],
            [-42.973664, -20.505203996],
            [-42.973723999999898, -20.504962996],
            [-42.973833, -20.504628996],
            [-42.974003, -20.504387995999899],
            [-42.974149, -20.504134995999902],
            [-42.974306, -20.503926996],
            [-42.974464, -20.503708995999901],
            [-42.974634, -20.503501996],
            [-42.974877999999897, -20.503305995999899],
            [-42.975072, -20.503120996],
            [-42.975144999999898, -20.502856995999899],
            [-42.975266, -20.502637996],
            [-42.975459999999899, -20.502476995999899],
            [-42.975642999999899, -20.502338995999899],
            [-42.975862, -20.5021769959999],
            [-42.976081, -20.502084996],
            [-42.976324, -20.501992996],
            [-42.976641, -20.501887995999901],
            [-42.976835999999899, -20.501760996],
            [-42.977091, -20.501599996],
            [-42.977249, -20.5013929959999],
            [-42.977358, -20.501127996],
            [-42.977528999999898, -20.500797996],
            [-42.977901, -20.500429996],
            [-42.978411, -20.500098996],
            [-42.978737, -20.499807996],
            [-42.978997999999898, -20.499550996],
            [-42.979205, -20.499271995999901],
            [-42.979289999999899, -20.499053995999901],
            [-42.979388, -20.498823995999899],
            [-42.979523, -20.498639996],
            [-42.979669, -20.4984449959999],
            [-42.979779, -20.498237995999901],
            [-42.979926, -20.498054996],
            [-42.980084, -20.497881995999901],
            [-42.980267, -20.497698995999901],
            [-42.98045, -20.497491996],
            [-42.980865, -20.497147996],
            [-42.981061, -20.497009996],
            [-42.981256, -20.496837996],
            [-42.981476, -20.496711995999899],
            [-42.981756, -20.496642995999899],
            [-42.982, -20.496585996],
            [-42.982232, -20.4965509959999],
            [-42.982476, -20.496539996],
            [-42.982731999999899, -20.4965059959999],
            [-42.982988, -20.4964369959999],
            [-42.983244, -20.4963919959999],
            [-42.983452, -20.496264996],
            [-42.983609999999899, -20.496092996],
            [-42.983635, -20.495874995999898],
            [-42.983537, -20.495667996],
            [-42.983404, -20.495448995999901],
            [-42.983233, -20.4952649959999],
            [-42.983062, -20.495115996],
            [-42.982855, -20.494965996],
            [-42.982721, -20.494690996],
            [-42.982514, -20.494494996],
            [-42.982295, -20.494333996],
            [-42.982112, -20.494103996],
            [-42.98193, -20.493908996],
            [-42.981783, -20.493735995999899],
            [-42.981552, -20.493471996],
            [-42.981369, -20.493275996],
            [-42.981211, -20.493068996],
            [-42.981065, -20.492827996],
            [-42.980797, -20.492551996],
            [-42.98059, -20.492321995999902],
            [-42.980528999999898, -20.492056996],
            [-42.980517, -20.4918049959999],
            [-42.98053, -20.491574995999901],
            [-42.980579, -20.491310996],
            [-42.980628, -20.491057995999899],
            [-42.980665, -20.490827996],
            [-42.980725999999898, -20.490551996],
            [-42.980824, -20.490276996],
            [-42.980946, -20.490058996],
            [-42.981091999999897, -20.489885995999899],
            [-42.981276, -20.489598995999899],
            [-42.981445999999899, -20.489438995999901],
            [-42.981642, -20.489277995999899],
            [-42.981849, -20.489093996],
            [-42.982056999999898, -20.488956996],
            [-42.982337, -20.4887159959999],
            [-42.98252, -20.488577995999901],
            [-42.982838, -20.488324996],
            [-42.982996, -20.488164996],
            [-42.983227999999897, -20.4880379959999],
            [-42.983509, -20.487877996],
            [-42.98374, -20.487797995999902],
            [-42.984009, -20.487693996],
            [-42.984265, -20.4876369959999],
            [-42.98457, -20.487545995999898],
            [-42.984802, -20.487533996],
            [-42.985131, -20.487534996],
            [-42.985387, -20.487545995999898],
            [-42.985655, -20.487557996],
            [-42.985923, -20.487580996],
            [-42.986253, -20.487581996],
            [-42.986533, -20.487558996],
            [-42.986801, -20.487524995999902],
            [-42.987057999999898, -20.487397996],
            [-42.987289, -20.487283996],
            [-42.987497, -20.487179996],
            [-42.987752999999898, -20.487065996],
            [-42.988020999999897, -20.4869629959999],
            [-42.98829, -20.486812996],
            [-42.988582, -20.486675996],
            [-42.98879, -20.486537996],
            [-42.989033999999897, -20.486376995999901],
            [-42.989278, -20.4861479959999],
            [-42.989498, -20.485952996],
            [-42.989656, -20.485757996],
            [-42.989753999999898, -20.485492995999898],
            [-42.989936999999898, -20.485355996],
            [-42.990119999999898, -20.485194996],
            [-42.990376, -20.485033996],
            [-42.990596, -20.484919996],
            [-42.990852, -20.484873996],
            [-42.991242, -20.4848169959999],
            [-42.991498, -20.484793996],
            [-42.99173, -20.484736996],
            [-42.99195, -20.484633996],
            [-42.992194, -20.484483995999899],
            [-42.992413, -20.484334996],
            [-42.992657, -20.484196996],
            [-42.992853, -20.484036995999901],
            [-42.99306, -20.483875995999899],
            [-42.993304, -20.4837149959999],
            [-42.993548, -20.483519995999899],
            [-42.993853, -20.483359995999901],
            [-42.994073, -20.4832449959999],
            [-42.994341, -20.483072996],
            [-42.994585, -20.482946996],
            [-42.994878, -20.482820995999901],
            [-42.995134, -20.482728995999899],
            [-42.995414, -20.482671996],
            [-42.995671, -20.4825909959999],
            [-42.995963, -20.4824769959999],
            [-42.996293, -20.482373996],
            [-42.996573, -20.482269996],
            [-42.996828999999899, -20.482189995999899],
            [-42.99711, -20.482097995999901],
            [-42.997366, -20.482040996],
            [-42.997683, -20.481995996],
            [-42.998084999999897, -20.481949996],
            [-42.998354, -20.481903996],
            [-42.998598, -20.4818929959999],
            [-42.998915, -20.481858996],
            [-42.999170999999897, -20.481800995999901],
            [-42.999402, -20.481720996],
            [-42.999643999999897, -20.4815999959999],
            [-42.9998, -20.481429996],
            [-42.999951, -20.480968995999898],
            [-43.00006, -20.480716996],
            [-43.000206999999897, -20.480491996],
            [-43.000374, -20.480284996],
            [-43.00055, -20.480077996],
            [-43.000725, -20.479907996],
            [-43.000887, -20.479649996],
            [-43.001055999999899, -20.4794879959999],
            [-43.001369, -20.479117996],
            [-43.001489, -20.478909996],
            [-43.001621, -20.478713996],
            [-43.001839, -20.478596996],
            [-43.001971, -20.478411996],
            [-43.002079, -20.478215996],
            [-43.00226, -20.478076996],
            [-43.002477999999897, -20.477924996],
            [-43.002671, -20.477785996],
            [-43.00284, -20.477623996],
            [-43.002899, -20.477404996],
            [-43.003104999999898, -20.477276995999901],
            [-43.003323, -20.477182996],
            [-43.003566, -20.477077995999899],
            [-43.003808999999897, -20.477017996],
            [-43.004052, -20.476969996],
            [-43.004283, -20.476921995999898],
            [-43.004514, -20.476908996],
            [-43.004758, -20.476905996],
            [-43.004989, -20.476892995999901],
            [-43.005451, -20.476796996],
            [-43.005446, -20.476325996],
            [-43.005322, -20.476120996],
            [-43.005174, -20.475857995999899],
            [-43.00511, -20.475617996],
            [-43.005035, -20.4753889959999],
            [-43.004948, -20.475182995999901],
            [-43.004848, -20.474942996],
            [-43.00476, -20.474724996],
            [-43.004697, -20.4745079959999],
            [-43.004586, -20.474313996],
            [-43.004486, -20.474084995999899],
            [-43.004386, -20.473821996],
            [-43.004236999999897, -20.473615996],
            [-43.004089, -20.473433996],
            [-43.003917, -20.473262996],
            [-43.003769, -20.473080996],
            [-43.003597, -20.472909995999899],
            [-43.00334, -20.4728209959999],
            [-43.002853, -20.4728249959999],
            [-43.002659999999899, -20.472987996],
            [-43.002417, -20.473046995999901],
            [-43.002174, -20.473152996],
            [-43.001931, -20.473246995999901],
            [-43.001737999999897, -20.473385995999902],
            [-43.001594, -20.473559995999899],
            [-43.001498, -20.473766995999899],
            [-43.001341, -20.473928995999898],
            [-43.001198, -20.474159996],
            [-43.001053, -20.474333995999899],
            [-43.000920999999899, -20.474518996],
            [-43.000752, -20.4747149959999],
            [-43.000571, -20.474865995999899],
            [-43.000365, -20.474982995999898],
            [-43.00014, -20.475109996],
            [-42.999952999999898, -20.475158995999902],
            [-42.99987, -20.475178996],
            [-42.999701, -20.475155996],
            [-42.999505999999897, -20.475045995999899],
            [-42.999322999999897, -20.474895995999901],
            [-42.99914, -20.4747119959999],
            [-42.998982, -20.474539996],
            [-42.998762999999897, -20.474389996],
            [-42.998494999999899, -20.474320996],
            [-42.998251, -20.474320996],
            [-42.998019, -20.474343996],
            [-42.9978, -20.474423995999899],
            [-42.997556, -20.474538996],
            [-42.997336, -20.474664995999898],
            [-42.997104, -20.474790996],
            [-42.996885, -20.474893995999899],
            [-42.996665, -20.475042996],
            [-42.996433, -20.475123996],
            [-42.996165, -20.475134996],
            [-42.995909, -20.475134996],
            [-42.995641, -20.475122996],
            [-42.995359999999899, -20.475099996],
            [-42.995129, -20.475076995999899],
            [-42.994873, -20.475075995999902],
            [-42.994616999999899, -20.475052995999899],
            [-42.994372999999896, -20.475006995999902],
            [-42.994031999999898, -20.474937995999898],
            [-42.993811999999899, -20.474833996],
            [-42.993556, -20.474753995999901],
            [-42.993325, -20.474660996],
            [-42.99313, -20.4745349959999],
            [-42.992935, -20.474361996],
            [-42.992752, -20.474189996],
            [-42.992667, -20.4739719959999],
            [-42.992557, -20.473729996],
            [-42.992472, -20.473511996],
            [-42.992363, -20.473223995999899],
            [-42.992266, -20.472959995999901],
            [-42.992193, -20.4727069959999],
            [-42.992095, -20.472442995999899],
            [-42.99201, -20.472166995999899],
            [-42.991889, -20.471706996],
            [-42.99173, -20.471545996],
            [-42.991644999999899, -20.471281996],
            [-42.991304, -20.470879996],
            [-42.991158, -20.470672996],
            [-42.991024, -20.470476995999899],
            [-42.990917619999898, -20.470337625],
            [-42.990881195, -20.470367911],
            [-42.990219496, -20.4709931689999],
            [-42.989449416, -20.471393974],
            [-42.989192722, -20.471511543],
            [-42.988730673, -20.471677208],
            [-42.988456867, -20.4718749379999],
            [-42.98843405, -20.47216886],
            [-42.988713560999898, -20.472430717],
            [-42.98904441, -20.472970462],
            [-42.988753491, -20.473959099999899],
            [-42.9886337, -20.474910323],
            [-42.988696448, -20.475417996],
            [-42.988616587, -20.475920324],
            [-42.988297147, -20.4763050839999],
            [-42.987960593, -20.476834128],
            [-42.988091792, -20.4772776679999],
            [-42.988091792, -20.477844116],
            [-42.987840801999901, -20.47832506],
            [-42.9866486029999, -20.4788915029999],
            [-42.986294936, -20.479666352],
            [-42.985268162, -20.479490008],
            [-42.984201458, -20.479115943],
            [-42.983254542999902, -20.479062504999899],
            [-42.982792495, -20.479041129999899],
            [-42.982130796, -20.47908388],
            [-42.979883301, -20.479757196],
            [-42.979512520999897, -20.4799281969999],
            [-42.97887364, -20.480168665999901],
            [-42.977476086, -20.480510664999901],
            [-42.977436155, -20.48048929],
            [-42.976489241, -20.480654946],
            [-42.975816134, -20.480900758],
            [-42.975348380999897, -20.481023664],
            [-42.974646752, -20.481189319],
            [-42.973956530999899, -20.481333599],
            [-42.972695879999897, -20.482076372999899],
            [-42.972353622, -20.482402337999901],
            [-42.972102633, -20.483214573999899],
            [-42.97200566, -20.483513817999899],
            [-42.97167481, -20.484069555],
            [-42.971138606, -20.484422233],
            [-42.970379933999901, -20.484577198],
            [-42.969347454999898, -20.484951248999899],
            [-42.968508922999902, -20.485261176999899],
            [-42.966078889999899, -20.485261176999899],
            [-42.965685293, -20.485560416999899],
            [-42.965120567, -20.485784845999898],
            [-42.964658519, -20.486201643],
            [-42.964373303999899, -20.486516912],
            [-42.9640994969999, -20.487697828],
            [-42.963825691, -20.488355075],
            [-42.96354618, -20.490171844],
            [-42.963774352, -20.492400027],
            [-42.963238148, -20.4926565059999],
            [-42.962371093999899, -20.4926671929999],
            [-42.961287276, -20.49240537],
            [-42.960893679, -20.491790885999901],
            [-42.960414517999901, -20.491374105],
            [-42.959730002, -20.490075662],
            [-42.958708932, -20.488830643],
            [-42.958400899, -20.488093245],
            [-42.957568071, -20.487991719],
            [-42.957465393999897, -20.4880130929999],
            [-42.957385534, -20.488189427999899],
            [-42.957185883, -20.488451257999898],
            [-42.956701016999901, -20.488766521999899],
            [-42.955012543999899, -20.489193998],
            [-42.953563651, -20.489653532],
            [-42.953227097, -20.489888643],
            [-42.952822092, -20.490070319],
            [-42.951481580999904, -20.4900542889999],
            [-42.950945377, -20.489327583],
            [-42.949798812, -20.4886382789999],
            [-42.948509639999898, -20.488333702],
            [-42.947859348999899, -20.48826958],
            [-42.946284961999901, -20.488333702],
            [-42.945577628, -20.488264237],
            [-42.9446193059999, -20.487665767],
            [-42.943849225, -20.487623019],
            [-42.942674139, -20.48765508],
            [-42.941561799999903, -20.487831415],
            [-42.940985665, -20.487981031999901],
            [-42.940175654, -20.488584843999899],
            [-42.940055863999902, -20.489076441999899],
            [-42.939901848, -20.489274149],
            [-42.939553885, -20.489530633999902],
            [-42.939080428, -20.489749714],
            [-42.938795212999899, -20.489926047],
            [-42.938293234999897, -20.4903909229999],
            [-42.937933863999902, -20.4905351949999],
            [-42.937169486999899, -20.490567255],
            [-42.936519196999903, -20.490968009],
            [-42.935874611, -20.4909092309999],
            [-42.935509534999902, -20.490422983],
            [-42.934665299, -20.490241308],
            [-42.932252379, -20.490155812999902],
            [-42.93102025, -20.490241308],
            [-42.930911868, -20.490251994999898],
            [-42.930141787, -20.490444356999902],
            [-42.929645513, -20.491000068999899],
            [-42.929411635999898, -20.491171057],
            [-42.928675781, -20.491448912],
            [-42.927934222, -20.4917748559999],
            [-42.927352383, -20.492164919999901],
            [-42.926878926, -20.492603072999898],
            [-42.926799108999901, -20.492638676]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 144,
        "id": 37055,
        "CD_SETOR": "315210515000005P",
        "AREA_KM2": 0.145353,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210515",
        "NM_DIST": "Vau-Açu",
        "CD_SUBDIST": "31521051500",
        "NM_SUBDIST": null,
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 162
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.8996415679999, -20.525917799],
            [-42.8998575049999, -20.526159005],
            [-42.899981454999903, -20.5263529449999],
            [-42.900053811, -20.526471813999901],
            [-42.900128880999901, -20.5266785439999],
            [-42.900183147999897, -20.526831006999899],
            [-42.900191031, -20.526996391],
            [-42.900240278999902, -20.527307272],
            [-42.900226607, -20.527450953],
            [-42.900062028, -20.527645262],
            [-42.899913559, -20.527803127],
            [-42.900005958999898, -20.52789602],
            [-42.899960948999897, -20.528140409999899],
            [-42.8999391259999, -20.528500998],
            [-42.899887958, -20.528769262],
            [-42.89992373, -20.529420504],
            [-42.899908507, -20.529515223],
            [-42.899877386999897, -20.529710294],
            [-42.899828628, -20.529945311999899],
            [-42.899756201999899, -20.5304759049999],
            [-42.899790616, -20.530683414999899],
            [-42.899868470999898, -20.530677458],
            [-42.899970798, -20.530692877],
            [-42.900155588, -20.530699749],
            [-42.9002291749999, -20.530693189],
            [-42.900329605, -20.530683865999901],
            [-42.900492276, -20.530664143],
            [-42.900629747, -20.530629248],
            [-42.900752133, -20.530600229999902],
            [-42.900911755, -20.530585427999899],
            [-42.900960989999902, -20.530561232],
            [-42.901003782, -20.530584871],
            [-42.901073298, -20.530597707],
            [-42.901189422999899, -20.530644897999899],
            [-42.901734744999899, -20.530947194],
            [-42.901954685, -20.531089091],
            [-42.902176614, -20.531256856],
            [-42.902370206999898, -20.531380456],
            [-42.902532387999898, -20.53146641],
            [-42.902691596999901, -20.531601515],
            [-42.902827004, -20.531717312],
            [-42.903035737, -20.531902004],
            [-42.903217522, -20.5320591559999],
            [-42.903341305, -20.532166985999901],
            [-42.903483369, -20.532283284],
            [-42.903839331, -20.5327331619999],
            [-42.904102543, -20.533074036999899],
            [-42.904539797, -20.533575522],
            [-42.904707539, -20.533417434999901],
            [-42.904807558, -20.533187584],
            [-42.904880109, -20.533017254],
            [-42.904847742, -20.532632863999901],
            [-42.90477429, -20.532493374],
            [-42.904718416, -20.532355004],
            [-42.904639898, -20.532204763],
            [-42.904582000999902, -20.532054364],
            [-42.904528957, -20.531879443],
            [-42.904522099, -20.531817007],
            [-42.90451983, -20.531755044],
            [-42.904586369, -20.53163036],
            [-42.904612156, -20.5316001909999],
            [-42.904743781, -20.531472138],
            [-42.905014363, -20.531320019],
            [-42.905351993999901, -20.531166977999899],
            [-42.905646035, -20.531030508],
            [-42.905757931, -20.5309775499999],
            [-42.905857872, -20.530949959999901],
            [-42.906031852999902, -20.530904554],
            [-42.906345589999901, -20.5308538049999],
            [-42.906639362, -20.530833045],
            [-42.906742424999898, -20.530837644],
            [-42.907190977, -20.530903237],
            [-42.907441683999899, -20.5309170969999],
            [-42.907631707999897, -20.530945400999901],
            [-42.907743837999902, -20.530956373],
            [-42.907914713, -20.530951731],
            [-42.907995375, -20.530934424],
            [-42.908342547, -20.530905627],
            [-42.908317985999901, -20.530857429],
            [-42.908263873, -20.530742136999901],
            [-42.908250183, -20.530694486999899],
            [-42.908224574, -20.530610731],
            [-42.90818409, -20.530497318999899],
            [-42.908165650999898, -20.5303820459999],
            [-42.908150943, -20.530290097],
            [-42.90811359, -20.530222507],
            [-42.907928782999903, -20.530090142999899],
            [-42.907904578999897, -20.530021711],
            [-42.907808837999902, -20.529924505999901],
            [-42.907692515, -20.529949051],
            [-42.907599551, -20.529842684999899],
            [-42.907538499999902, -20.529806643],
            [-42.90750019, -20.529818511],
            [-42.907430041999902, -20.529905875999901],
            [-42.90725105, -20.5299744769999],
            [-42.907138582999899, -20.529996624],
            [-42.907033053, -20.530031347],
            [-42.906841959, -20.530098122],
            [-42.906733577, -20.530100793],
            [-42.9066565689999, -20.530106135],
            [-42.906527529, -20.530147247999899],
            [-42.906506716, -20.53020344],
            [-42.906471488, -20.530241443],
            [-42.906400724, -20.530274678],
            [-42.906342832999897, -20.530293104999899],
            [-42.906290492, -20.530315082],
            [-42.906235503999902, -20.530364581999901],
            [-42.906108956, -20.530391931999901],
            [-42.906047827999899, -20.530425606999898],
            [-42.905991248, -20.5305055329999],
            [-42.905922256999901, -20.530538464],
            [-42.905832991, -20.530514189],
            [-42.905800757999899, -20.530490916],
            [-42.905739255, -20.530485214999899],
            [-42.905624165999903, -20.530495324999901],
            [-42.905563577, -20.530557601999899],
            [-42.905448489, -20.530610243999899],
            [-42.905379422, -20.530691015999899],
            [-42.905336255, -20.530694126],
            [-42.905221167, -20.530726482],
            [-42.905105697, -20.530822346],
            [-42.904996482999898, -20.530804197999899],
            [-42.904855612999903, -20.530939173],
            [-42.904735683, -20.530978230999899],
            [-42.904684244999899, -20.530971334999901],
            [-42.9046322, -20.530964356],
            [-42.904563289, -20.530985058999899],
            [-42.90451783, -20.530965942],
            [-42.904421559999903, -20.531002608999898],
            [-42.904274019, -20.531005449],
            [-42.90416641, -20.530996421999902],
            [-42.904064171999899, -20.5309535449999],
            [-42.903989242, -20.530954601],
            [-42.903884278, -20.530942157],
            [-42.903830835, -20.530927412],
            [-42.903680591999901, -20.530885957],
            [-42.903550961999898, -20.530836264999898],
            [-42.903447282999899, -20.530813409],
            [-42.903324331, -20.530776402],
            [-42.90311335, -20.530716035999902],
            [-42.903043086, -20.53071492],
            [-42.902994253, -20.530737539],
            [-42.902938907, -20.530777448],
            [-42.902880320999898, -20.530783555],
            [-42.902846096, -20.53081395],
            [-42.902834686999903, -20.530859356],
            [-42.902786662, -20.530828082],
            [-42.902675197, -20.530810129],
            [-42.902596648, -20.530801508],
            [-42.902549472, -20.530827304999899],
            [-42.902353763, -20.530713166999899],
            [-42.902267185, -20.530649329],
            [-42.902158496, -20.530613963],
            [-42.901946742999897, -20.530613794],
            [-42.90189155, -20.530586374999899],
            [-42.901844066, -20.530528965],
            [-42.901773917999897, -20.530485621],
            [-42.901716666, -20.530438852],
            [-42.901668543, -20.53039954],
            [-42.901614579999901, -20.530359852999901],
            [-42.901522622999899, -20.530296781999901],
            [-42.901434589, -20.530234715999899],
            [-42.901356811, -20.530209729],
            [-42.9012907749999, -20.530130673],
            [-42.901250402, -20.530086678],
            [-42.901246579, -20.53004886],
            [-42.901266313999898, -20.530000207999901],
            [-42.90122407, -20.529971571],
            [-42.901226075, -20.5299351899999],
            [-42.901216979, -20.529904969],
            [-42.901236018, -20.529864695999901],
            [-42.901277525999902, -20.529825679999899],
            [-42.901310872, -20.529793227],
            [-42.901383494999898, -20.529753961],
            [-42.901420217, -20.529738311],
            [-42.901460336, -20.529739462999899],
            [-42.901501806999903, -20.529708425],
            [-42.901553890999899, -20.529654825],
            [-42.9016047329999, -20.529625402],
            [-42.901641100999903, -20.529603546],
            [-42.901621829, -20.529564664],
            [-42.90160872, -20.529508638],
            [-42.901605178999901, -20.529422559999901],
            [-42.901572495, -20.529380871999901],
            [-42.901511674999902, -20.529352065],
            [-42.901483927, -20.529311198],
            [-42.901515204, -20.529304289],
            [-42.901521235, -20.529277892],
            [-42.90145568, -20.529189378],
            [-42.90145143, -20.529136719],
            [-42.901447113, -20.5290847109999],
            [-42.901432703, -20.529020764999899],
            [-42.901447444, -20.528908079],
            [-42.901392391999899, -20.528772417],
            [-42.901332356999902, -20.52868337],
            [-42.901274682999897, -20.528580134],
            [-42.901237067, -20.528527316999899],
            [-42.901227483, -20.528497296],
            [-42.901214534, -20.528456735],
            [-42.901204477, -20.528425232],
            [-42.90116499, -20.528364193],
            [-42.901145194, -20.5282967549999],
            [-42.901146281, -20.528229315],
            [-42.901128037, -20.528130113],
            [-42.901107421999903, -20.528022414],
            [-42.901093322, -20.527948748],
            [-42.901046846, -20.527839319],
            [-42.901039725, -20.5277881119999],
            [-42.901007369, -20.5277515679999],
            [-42.9009515179999, -20.527658034999899],
            [-42.900886704999898, -20.527589384999899],
            [-42.9008762939999, -20.527509263],
            [-42.900884076999901, -20.527418554999901],
            [-42.900884183, -20.527314879],
            [-42.9008559379999, -20.527271712999902],
            [-42.900781918, -20.527149257],
            [-42.900730058, -20.527087127999899],
            [-42.900694604, -20.527035407],
            [-42.900743336999902, -20.526985789],
            [-42.90078017, -20.526898644],
            [-42.900767775, -20.526835306999899],
            [-42.9008107289999, -20.526737361999899],
            [-42.90078725, -20.5266789739999],
            [-42.900799236, -20.526616195],
            [-42.900833201999902, -20.526593949999899],
            [-42.900879384999897, -20.526530813],
            [-42.900882384999903, -20.526442184],
            [-42.900916507, -20.526349352999901],
            [-42.900934962, -20.5262635949999],
            [-42.900962822999901, -20.526202172],
            [-42.90094727, -20.526141675],
            [-42.900899136, -20.526063178],
            [-42.900714325, -20.526012554],
            [-42.900673249, -20.526020071999898],
            [-42.900626947, -20.525974619],
            [-42.900622816999899, -20.5259038579999],
            [-42.900610537, -20.525851719999899],
            [-42.900599068, -20.525803023],
            [-42.900519927999902, -20.525670764],
            [-42.900469459, -20.52559901],
            [-42.900412018999901, -20.525525636999902],
            [-42.900390213, -20.525472396999898],
            [-42.900357605999901, -20.525414063],
            [-42.900318292999899, -20.525393645],
            [-42.900288968, -20.525389963],
            [-42.900187378999902, -20.525398580999902],
            [-42.90015704, -20.52539408],
            [-42.900064513, -20.52534732],
            [-42.900032747, -20.525311245999902],
            [-42.900029157999903, -20.525251445],
            [-42.899981426999901, -20.525343672],
            [-42.899958435, -20.525533336],
            [-42.899907277999901, -20.525679813999901],
            [-42.899837485, -20.525750756],
            [-42.89977555, -20.525807832999899],
            [-42.899759079, -20.52581401],
            [-42.8996415679999, -20.525917799]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 145,
        "id": 37056,
        "CD_SETOR": "315210515000006P",
        "AREA_KM2": 18.082702,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210515",
        "NM_DIST": "Vau-Açu",
        "CD_SUBDIST": "31521051500",
        "NM_SUBDIST": null,
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 41
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.950903484, -20.462076672],
            [-42.950848999999899, -20.462165996],
            [-42.950715, -20.462418995999901],
            [-42.95058, -20.462648995999899],
            [-42.950421999999897, -20.462854996],
            [-42.950287, -20.463061996],
            [-42.950176999999897, -20.463268996],
            [-42.950092, -20.463486996],
            [-42.949982, -20.4637279959999],
            [-42.949835, -20.463968995999899],
            [-42.949664, -20.464186996],
            [-42.949506, -20.464370996],
            [-42.949297999999899, -20.464565996],
            [-42.949115, -20.464749996],
            [-42.948895, -20.464921995999902],
            [-42.948554, -20.465035995999902],
            [-42.948261, -20.465104996],
            [-42.948029, -20.465184995999898],
            [-42.947809999999897, -20.465287996],
            [-42.947505, -20.465402996],
            [-42.947273, -20.465505995999902],
            [-42.946979999999897, -20.465585996],
            [-42.946736, -20.465665995999899],
            [-42.946517, -20.465746996],
            [-42.946249, -20.465837995999902],
            [-42.945956, -20.465952995999899],
            [-42.945127, -20.466158995999901],
            [-42.944609887, -20.466296471],
            [-42.943468, -20.466673995999901],
            [-42.943199, -20.466822996],
            [-42.943004, -20.4669489959999],
            [-42.94276, -20.4670979959999],
            [-42.942540999999899, -20.467212995999901],
            [-42.942309, -20.4673499959999],
            [-42.942089, -20.467476996],
            [-42.941882, -20.467636996],
            [-42.941345, -20.468003995999901],
            [-42.941076, -20.468187995999902],
            [-42.940857, -20.468405995999898],
            [-42.940539, -20.468772995999899],
            [-42.940393, -20.468956996],
            [-42.940258, -20.469185996],
            [-42.940136, -20.469404996],
            [-42.940014, -20.469645995999901],
            [-42.939904, -20.469874995999898],
            [-42.939806, -20.470104996],
            [-42.939684, -20.470334996],
            [-42.939537, -20.470540996],
            [-42.939366, -20.470804996],
            [-42.939183, -20.471069996],
            [-42.939075773999903, -20.471224178999901],
            [-42.938937844, -20.471299514],
            [-42.938432629, -20.471400463],
            [-42.937492704, -20.471803513999902],
            [-42.936982940999897, -20.47198846],
            [-42.936652785, -20.471990707],
            [-42.936227403, -20.471783602],
            [-42.935699778999897, -20.4714686149999],
            [-42.935256129, -20.471100346999901],
            [-42.934797316999898, -20.4708035469999],
            [-42.934431801, -20.470639201],
            [-42.93388, -20.470557996],
            [-42.933636, -20.470534996],
            [-42.933368, -20.4705229959999],
            [-42.933112, -20.470510996],
            [-42.932832, -20.470510996],
            [-42.932582887, -20.470536244],
            [-42.932332, -20.470635995999899],
            [-42.932096042999902, -20.4708402219999],
            [-42.931952973, -20.471403962],
            [-42.931747747, -20.4716651869999],
            [-42.93132121, -20.472081548999899],
            [-42.930895855, -20.4722877089999],
            [-42.930507473, -20.472344708],
            [-42.930007343999897, -20.472301795],
            [-42.929639596999898, -20.472116378],
            [-42.929348521999898, -20.471925357],
            [-42.928933984, -20.4711105979999],
            [-42.928748027, -20.470828469],
            [-42.928420333, -20.470197633999899],
            [-42.927854758, -20.469315559999899],
            [-42.927348203999898, -20.468256430999901],
            [-42.926982075, -20.467522291],
            [-42.92675285, -20.467190275999901],
            [-42.926593231, -20.4670974379999],
            [-42.92640428, -20.467073200999899],
            [-42.926151763, -20.4671371069999],
            [-42.925282629, -20.467534987],
            [-42.923936441, -20.468425743],
            [-42.923291365999901, -20.468837080999901],
            [-42.922531, -20.469314996],
            [-42.92163928, -20.470046114],
            [-42.920780914, -20.470656495],
            [-42.919680677, -20.471182149999901],
            [-42.918293505, -20.471789784999899],
            [-42.916058785, -20.4730863099999],
            [-42.916430413999898, -20.474472582],
            [-42.9164123689999, -20.4756655969999],
            [-42.9161993559999, -20.476804804],
            [-42.915950607999903, -20.477449835999899],
            [-42.91539956, -20.478186453],
            [-42.914372482, -20.480137816],
            [-42.914207689, -20.480583189],
            [-42.914074110999898, -20.4811854279999],
            [-42.913798239999899, -20.481854468999899],
            [-42.914440146999901, -20.482442867],
            [-42.914750736, -20.48295252],
            [-42.914956423, -20.483672550999898],
            [-42.914970888, -20.484489641],
            [-42.916721217, -20.485071951],
            [-42.917818297999901, -20.485379716999901],
            [-42.918992069999902, -20.4860294809999],
            [-42.919818423, -20.486780805],
            [-42.921041336, -20.488075002999899],
            [-42.921764412999899, -20.489023232],
            [-42.922412552, -20.489546004],
            [-42.923408009999903, -20.490226849],
            [-42.923760221999899, -20.490364714],
            [-42.925689647, -20.491569921],
            [-42.926782553, -20.492501778],
            [-42.926799108999901, -20.492638676],
            [-42.926878926, -20.492603072999898],
            [-42.927352383, -20.492164919999901],
            [-42.927934222, -20.4917748559999],
            [-42.928675781, -20.491448912],
            [-42.929411635999898, -20.491171057],
            [-42.929645513, -20.491000068999899],
            [-42.930141787, -20.490444356999902],
            [-42.930911868, -20.490251994999898],
            [-42.93102025, -20.490241308],
            [-42.932252379, -20.490155812999902],
            [-42.934665299, -20.490241308],
            [-42.935509534999902, -20.490422983],
            [-42.935874611, -20.4909092309999],
            [-42.936519196999903, -20.490968009],
            [-42.937169486999899, -20.490567255],
            [-42.937933863999902, -20.4905351949999],
            [-42.938293234999897, -20.4903909229999],
            [-42.938795212999899, -20.489926047],
            [-42.939080428, -20.489749714],
            [-42.939553885, -20.489530633999902],
            [-42.939901848, -20.489274149],
            [-42.940055863999902, -20.489076441999899],
            [-42.940175654, -20.488584843999899],
            [-42.940985665, -20.487981031999901],
            [-42.941561799999903, -20.487831415],
            [-42.942674139, -20.48765508],
            [-42.943849225, -20.487623019],
            [-42.9446193059999, -20.487665767],
            [-42.945577628, -20.488264237],
            [-42.946284961999901, -20.488333702],
            [-42.947859348999899, -20.48826958],
            [-42.948509639999898, -20.488333702],
            [-42.949798812, -20.4886382789999],
            [-42.950945377, -20.489327583],
            [-42.951481580999904, -20.4900542889999],
            [-42.952822092, -20.490070319],
            [-42.953227097, -20.489888643],
            [-42.953563651, -20.489653532],
            [-42.955012543999899, -20.489193998],
            [-42.956701016999901, -20.488766521999899],
            [-42.957185883, -20.488451257999898],
            [-42.957385534, -20.488189427999899],
            [-42.957465393999897, -20.4880130929999],
            [-42.957568071, -20.487991719],
            [-42.958400899, -20.488093245],
            [-42.958708932, -20.488830643],
            [-42.959730002, -20.490075662],
            [-42.960414517999901, -20.491374105],
            [-42.960893679, -20.491790885999901],
            [-42.961287276, -20.49240537],
            [-42.962371093999899, -20.4926671929999],
            [-42.963238148, -20.4926565059999],
            [-42.963774352, -20.492400027],
            [-42.96354618, -20.490171844],
            [-42.963825691, -20.488355075],
            [-42.9640994969999, -20.487697828],
            [-42.964373303999899, -20.486516912],
            [-42.964658519, -20.486201643],
            [-42.965120567, -20.485784845999898],
            [-42.965685293, -20.485560416999899],
            [-42.966078889999899, -20.485261176999899],
            [-42.968508922999902, -20.485261176999899],
            [-42.969347454999898, -20.484951248999899],
            [-42.970379933999901, -20.484577198],
            [-42.971138606, -20.484422233],
            [-42.97167481, -20.484069555],
            [-42.97200566, -20.483513817999899],
            [-42.972102633, -20.483214573999899],
            [-42.972353622, -20.482402337999901],
            [-42.972695879999897, -20.482076372999899],
            [-42.973956530999899, -20.481333599],
            [-42.974646752, -20.481189319],
            [-42.975348380999897, -20.481023664],
            [-42.975816134, -20.480900758],
            [-42.976489241, -20.480654946],
            [-42.977436155, -20.48048929],
            [-42.977476086, -20.480510664999901],
            [-42.97887364, -20.480168665999901],
            [-42.979512520999897, -20.4799281969999],
            [-42.979883301, -20.479757196],
            [-42.982130796, -20.47908388],
            [-42.982792495, -20.479041129999899],
            [-42.983254542999902, -20.479062504999899],
            [-42.984201458, -20.479115943],
            [-42.985268162, -20.479490008],
            [-42.986294936, -20.479666352],
            [-42.9866486029999, -20.4788915029999],
            [-42.987840801999901, -20.47832506],
            [-42.988091792, -20.477844116],
            [-42.988091792, -20.4772776679999],
            [-42.987960593, -20.476834128],
            [-42.988297147, -20.4763050839999],
            [-42.988616587, -20.475920324],
            [-42.988696448, -20.475417996],
            [-42.9886337, -20.474910323],
            [-42.988753491, -20.473959099999899],
            [-42.98904441, -20.472970462],
            [-42.988713560999898, -20.472430717],
            [-42.98843405, -20.47216886],
            [-42.988456867, -20.4718749379999],
            [-42.988730673, -20.471677208],
            [-42.989192722, -20.471511543],
            [-42.989449416, -20.471393974],
            [-42.990219496, -20.4709931689999],
            [-42.990881195, -20.470367911],
            [-42.990917619999898, -20.470337625],
            [-42.990866, -20.470269996],
            [-42.990769, -20.4700699959999],
            [-42.990062, -20.4695339959999],
            [-42.989928, -20.4693269959999],
            [-42.989781999999899, -20.469096996],
            [-42.989685, -20.468890996],
            [-42.989612, -20.468671996],
            [-42.989575, -20.468453995999901],
            [-42.989539, -20.468120996],
            [-42.989503, -20.467901996],
            [-42.989491, -20.467683995999899],
            [-42.989466999999898, -20.467453996],
            [-42.989418, -20.467189995999899],
            [-42.989321, -20.466936996],
            [-42.98926, -20.466718995999901],
            [-42.989235999999899, -20.4664309959999],
            [-42.989212, -20.466178995999901],
            [-42.9892, -20.465948996],
            [-42.989188, -20.465729995999901],
            [-42.989151, -20.465442996],
            [-42.989103, -20.465201996],
            [-42.989067, -20.464971996],
            [-42.989091, -20.464718995999899],
            [-42.989078999999897, -20.464500996],
            [-42.989006, -20.464259996],
            [-42.988885, -20.464052996],
            [-42.988714, -20.463868996],
            [-42.988556, -20.463672996],
            [-42.98841, -20.463500995999901],
            [-42.988202999999899, -20.463339995999899],
            [-42.988008, -20.463212996],
            [-42.987764, -20.463120995999901],
            [-42.987459, -20.462982995999901],
            [-42.987154, -20.462890995999899],
            [-42.986874, -20.462843995999901],
            [-42.986556999999898, -20.462751996],
            [-42.986264999999896, -20.462728996],
            [-42.985971999999897, -20.462693995999899],
            [-42.985728, -20.462682996],
            [-42.985399, -20.4626709959999],
            [-42.98507, -20.462681996],
            [-42.984814, -20.462681996],
            [-42.98446, -20.462749996],
            [-42.984192, -20.462841996],
            [-42.983947999999899, -20.462875996],
            [-42.983716, -20.462921996],
            [-42.983424, -20.463059996],
            [-42.983143, -20.463150996],
            [-42.982886999999899, -20.463185995999901],
            [-42.982607, -20.463196996],
            [-42.982326, -20.463173996],
            [-42.982008999999898, -20.463138995999898],
            [-42.981753, -20.463080996],
            [-42.981485, -20.463023995999901],
            [-42.981254, -20.462976996],
            [-42.981034, -20.462884996],
            [-42.980803, -20.462838996],
            [-42.980595999999899, -20.4627359959999],
            [-42.980387999999898, -20.462619995999901],
            [-42.980108, -20.462516996],
            [-42.979802999999897, -20.462424996],
            [-42.979547, -20.462377995999901],
            [-42.979255, -20.462308995999901],
            [-42.978926, -20.462216995999899],
            [-42.978694, -20.462135996],
            [-42.978377, -20.462020996],
            [-42.978085, -20.461962996],
            [-42.97778, -20.461836995999899],
            [-42.977488, -20.461755996],
            [-42.977195, -20.461686996],
            [-42.976903, -20.4616059959999],
            [-42.97661, -20.461559995999899],
            [-42.97633, -20.461524996],
            [-42.976062, -20.461490996],
            [-42.975757, -20.461478996],
            [-42.975439999999899, -20.461558996],
            [-42.974903, -20.461557996],
            [-42.97466, -20.461580996],
            [-42.974379, -20.4616269959999],
            [-42.974099, -20.461660996],
            [-42.973867, -20.461717995999901],
            [-42.973574, -20.461752996],
            [-42.973256999999897, -20.461797996],
            [-42.973014, -20.4617409959999],
            [-42.972732999999899, -20.461762995999901],
            [-42.972477, -20.461865996],
            [-42.972233, -20.461969995999901],
            [-42.972001, -20.462072996],
            [-42.971733, -20.462152995999901],
            [-42.971477, -20.462267996],
            [-42.971196, -20.462312996],
            [-42.970904, -20.462358996],
            [-42.970636, -20.462381996],
            [-42.970367, -20.462380996],
            [-42.970123, -20.4623699959999],
            [-42.969782, -20.462276996],
            [-42.96949, -20.462161996],
            [-42.969245999999899, -20.462069996],
            [-42.969014, -20.461977996],
            [-42.968746, -20.461908996],
            [-42.968442, -20.461793996],
            [-42.96821, -20.461850996],
            [-42.967856, -20.461827996],
            [-42.967613, -20.461780996],
            [-42.967247, -20.461734996],
            [-42.966893, -20.461699995999901],
            [-42.966636999999899, -20.461699995999901],
            [-42.966357, -20.461687996],
            [-42.965308999999898, -20.461686996],
            [-42.965065, -20.461709996],
            [-42.964748, -20.461755996],
            [-42.964515999999897, -20.461777996],
            [-42.964222999999897, -20.461834995999901],
            [-42.963992, -20.461880995999898],
            [-42.963687, -20.461972995999901],
            [-42.963479999999898, -20.462075996],
            [-42.963186999999898, -20.462098996],
            [-42.962943, -20.462166996],
            [-42.962711, -20.462281995999898],
            [-42.962443, -20.462407996],
            [-42.962211, -20.462545996],
            [-42.961991, -20.462694996],
            [-42.961747, -20.462843995999901],
            [-42.961528, -20.462969996],
            [-42.961210999999899, -20.463049995999899],
            [-42.96093, -20.4630959959999],
            [-42.960699, -20.463083996],
            [-42.960479, -20.463003995999902],
            [-42.960259999999899, -20.462830996],
            [-42.960102, -20.462623995999898],
            [-42.959907, -20.462370995999901],
            [-42.959712, -20.4621759959999],
            [-42.95953, -20.462025996],
            [-42.959323, -20.461864996],
            [-42.959091, -20.461737996],
            [-42.958835, -20.461553996],
            [-42.958543, -20.461496995999902],
            [-42.958299, -20.461392996],
            [-42.958006, -20.461323996],
            [-42.957726, -20.461253996],
            [-42.957507, -20.461161995999898],
            [-42.95719, -20.461046995999901],
            [-42.956971, -20.460965996],
            [-42.956654, -20.460885995999899],
            [-42.956398, -20.460770996],
            [-42.95613, -20.4606899959999],
            [-42.955862, -20.4606209959999],
            [-42.955569, -20.460550995999899],
            [-42.955289, -20.460516996],
            [-42.954984, -20.460469996],
            [-42.95474, -20.460423996],
            [-42.95446, -20.460411995999898],
            [-42.954203999999898, -20.460400996],
            [-42.953972, -20.460422996],
            [-42.953704, -20.460479995999901],
            [-42.953436, -20.460525995999902],
            [-42.953215999999898, -20.460605996],
            [-42.952972, -20.460663995999901],
            [-42.952704, -20.460708995999902],
            [-42.952423, -20.460834996],
            [-42.952204, -20.460915996],
            [-42.952021, -20.461064995999902],
            [-42.951789, -20.461224995999899],
            [-42.951557, -20.4614089959999],
            [-42.951324999999898, -20.461580996],
            [-42.951141999999898, -20.461741995999901],
            [-42.950996, -20.461924995999901],
            [-42.950903484, -20.462076672]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "fid": 146,
        "id": 37057,
        "CD_SETOR": "315210515000007P",
        "AREA_KM2": 32.239284,
        "CD_REGIAO": "3",
        "NM_REGIAO": "Sudeste",
        "CD_UF": "31",
        "NM_UF": "Minas Gerais",
        "CD_MUN": "3152105",
        "NM_MUN": "Ponte Nova",
        "CD_DIST": "315210515",
        "NM_DIST": "Vau-Açu",
        "CD_SUBDIST": "31521051500",
        "NM_SUBDIST": null,
        "CD_MICRO": "31060",
        "NM_MICRO": "Ponte Nova",
        "CD_MESO": "3112",
        "NM_MESO": "Zona da Mata",
        "CD_RGI": "310030",
        "NM_RGI": "Ponte Nova",
        "CD_RGINT": "3106",
        "NM_RGINT": "Juíz de Fora",
        "CD_CONCURB": null,
        "NM_CONCURB": null,
        "casos": 361
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-42.903292025, -20.477710343],
            [-42.903182, -20.478159995999899],
            [-42.903133, -20.478377995999899],
            [-42.903059, -20.478745995999901],
            [-42.903035, -20.479009995999899],
            [-42.90301, -20.479296996],
            [-42.902997, -20.4795499959999],
            [-42.902983999999897, -20.479974996],
            [-42.902972, -20.480192996],
            [-42.902959, -20.480456995999901],
            [-42.902947, -20.480675995999899],
            [-42.902921999999897, -20.480893996],
            [-42.902885, -20.481169995999899],
            [-42.902824, -20.481513995999901],
            [-42.90275, -20.481743996],
            [-42.902676, -20.481984995999898],
            [-42.902554, -20.482191995999901],
            [-42.902444, -20.482455995999899],
            [-42.902371, -20.482673996],
            [-42.902236, -20.482948995999902],
            [-42.902187, -20.483213996],
            [-42.902174, -20.483431996],
            [-42.902162, -20.483776996],
            [-42.902159, -20.485384995999901],
            [-42.902232, -20.485614996],
            [-42.902232, -20.485867996],
            [-42.902183, -20.4860859959999],
            [-42.902024, -20.486303995999901],
            [-42.901840999999898, -20.486475996],
            [-42.901645, -20.4866249959999],
            [-42.901438, -20.486762996],
            [-42.901267, -20.486922996],
            [-42.901046999999899, -20.487106996],
            [-42.900839, -20.4873019959999],
            [-42.900632, -20.487565995999901],
            [-42.900216999999898, -20.488001996],
            [-42.900081999999898, -20.488219995999899],
            [-42.89996, -20.488426995999902],
            [-42.899911, -20.488644995999898],
            [-42.899836999999899, -20.488862996],
            [-42.899811999999898, -20.489092996],
            [-42.899763, -20.489345995999901],
            [-42.899726, -20.4896209959999],
            [-42.899653, -20.489896996],
            [-42.899628, -20.490218996],
            [-42.899591, -20.490447995999901],
            [-42.899578, -20.490677995999899],
            [-42.899577, -20.4915049959999],
            [-42.899565, -20.491723996],
            [-42.89954, -20.491975996],
            [-42.899478, -20.492194996],
            [-42.899441, -20.492435995999902],
            [-42.89938, -20.492699995999899],
            [-42.899245999999899, -20.492894996],
            [-42.899099, -20.493078996],
            [-42.898904, -20.493296996],
            [-42.89872, -20.493468995999901],
            [-42.898513, -20.4936059959999],
            [-42.898293, -20.493731996],
            [-42.898049, -20.493846995999899],
            [-42.897756, -20.493995996],
            [-42.8975, -20.494087996],
            [-42.897159, -20.494155996],
            [-42.896854, -20.494201996],
            [-42.89661, -20.494246996],
            [-42.896366, -20.494280995999901],
            [-42.896, -20.494383996],
            [-42.895732, -20.4944529959999],
            [-42.895524, -20.4945669959999],
            [-42.895231, -20.4947049959999],
            [-42.894975, -20.494761996],
            [-42.894731, -20.494795995999901],
            [-42.894487, -20.494990996],
            [-42.894292, -20.495162996],
            [-42.894095999999898, -20.4953579959999],
            [-42.893912999999898, -20.495541995999901],
            [-42.893729999999898, -20.495759995999901],
            [-42.893583, -20.495931996],
            [-42.893436999999899, -20.496114996],
            [-42.893253, -20.496287996],
            [-42.893034, -20.496516996],
            [-42.89285, -20.496700996],
            [-42.892654999999898, -20.496860996],
            [-42.892496, -20.497032996],
            [-42.89235, -20.4972279959999],
            [-42.892178999999899, -20.497411996],
            [-42.892056, -20.497618996],
            [-42.891885, -20.497859995999899],
            [-42.891763, -20.4980779959999],
            [-42.891641, -20.498272996],
            [-42.891531, -20.498467995999899],
            [-42.891396, -20.498662996],
            [-42.891249, -20.498857996],
            [-42.891103, -20.499041996],
            [-42.890943999999898, -20.499247996],
            [-42.890737, -20.499362995999899],
            [-42.890642, -20.499616995999901],
            [-42.890432, -20.499796995999901],
            [-42.890240999999897, -20.500008996],
            [-42.890091, -20.500227996],
            [-42.889718, -20.500613996],
            [-42.889312, -20.500904996],
            [-42.889089, -20.501083996],
            [-42.888869999999898, -20.501198996],
            [-42.888626, -20.5013029959999],
            [-42.888322, -20.501372995999901],
            [-42.888066, -20.501407996],
            [-42.887773, -20.501465996],
            [-42.887566, -20.501615995999899],
            [-42.88742, -20.501810996],
            [-42.887311, -20.5020179959999],
            [-42.887239, -20.502270995999901],
            [-42.887116999999897, -20.502500996],
            [-42.887068999999897, -20.5027649959999],
            [-42.886975999999898, -20.503031995999901],
            [-42.886938, -20.5038909959999],
            [-42.887048, -20.504165995999902],
            [-42.887133999999897, -20.504395996],
            [-42.887183, -20.5046829959999],
            [-42.887208, -20.504935996],
            [-42.887234, -20.505233996],
            [-42.887259, -20.505509995999901],
            [-42.887296, -20.505738995999899],
            [-42.887296, -20.505991996],
            [-42.887309, -20.506244996],
            [-42.887322, -20.506473996],
            [-42.88731, -20.506692996],
            [-42.887237, -20.506910995999899],
            [-42.887141, -20.5071749959999],
            [-42.887007, -20.507427995999901],
            [-42.886752, -20.507669995999901],
            [-42.886521, -20.507911996],
            [-42.886302, -20.508084996],
            [-42.886034, -20.508256995999901],
            [-42.885839, -20.508383996],
            [-42.885644, -20.508533996],
            [-42.885486, -20.5087059959999],
            [-42.885315999999897, -20.508878996],
            [-42.885207, -20.509165996],
            [-42.885147, -20.509395996],
            [-42.885098, -20.509637996],
            [-42.885061999999898, -20.509889996],
            [-42.885051, -20.510131995999899],
            [-42.885052, -20.510682996],
            [-42.885064999999898, -20.510912995999899],
            [-42.885163, -20.511175996],
            [-42.885298, -20.511439996],
            [-42.88542, -20.511669996],
            [-42.885555, -20.511875996],
            [-42.885677, -20.512139996],
            [-42.885775, -20.512369996],
            [-42.885849, -20.512621996],
            [-42.885935, -20.512862996],
            [-42.886082, -20.5130809959999],
            [-42.886204, -20.513321995999899],
            [-42.886217, -20.513551995999901],
            [-42.886218, -20.5138619959999],
            [-42.88617, -20.514160995999902],
            [-42.886109, -20.514378995999898],
            [-42.885975, -20.514573996],
            [-42.885829999999899, -20.514769995999899],
            [-42.885684, -20.514942995999899],
            [-42.885489, -20.5151269959999],
            [-42.885258, -20.515287995999898],
            [-42.884977999999897, -20.515448996],
            [-42.884686, -20.515564996],
            [-42.88443, -20.515622995999902],
            [-42.884161999999897, -20.5157149959999],
            [-42.883857, -20.515772996],
            [-42.883516, -20.515830996],
            [-42.883272, -20.515865995999899],
            [-42.882967999999899, -20.515946996],
            [-42.882723999999897, -20.516016996],
            [-42.882468, -20.516085996],
            [-42.882102, -20.516155996],
            [-42.881797999999897, -20.516202995999901],
            [-42.881518, -20.5162949959999],
            [-42.88125, -20.516409995999901],
            [-42.880957, -20.516536996],
            [-42.880714, -20.516571996],
            [-42.880458, -20.516595996],
            [-42.88019, -20.516618996],
            [-42.879921, -20.5166309959999],
            [-42.879654, -20.516815995999899],
            [-42.879422, -20.5169309959999],
            [-42.879165999999898, -20.5170349959999],
            [-42.878898999999898, -20.517195995999899],
            [-42.878704, -20.517391996],
            [-42.878534, -20.517540996],
            [-42.878327, -20.517667995999901],
            [-42.877962, -20.517967996],
            [-42.877766999999899, -20.5181169959999],
            [-42.877487, -20.5182789959999],
            [-42.877, -20.5184169959999],
            [-42.876756, -20.518474996],
            [-42.876476, -20.518532996],
            [-42.876171, -20.518579996],
            [-42.875939, -20.518637995999899],
            [-42.875684, -20.518729995999902],
            [-42.87544, -20.518879996],
            [-42.875233, -20.519017995999899],
            [-42.87499, -20.519190996],
            [-42.874794999999899, -20.519317995999899],
            [-42.874722, -20.519558995999901],
            [-42.87454, -20.519742995999898],
            [-42.874333, -20.519903996],
            [-42.874151, -20.520076995999901],
            [-42.874029, -20.5202839959999],
            [-42.873871, -20.520444996],
            [-42.873675999999897, -20.520651996],
            [-42.873506, -20.5208709959999],
            [-42.873373, -20.521077996],
            [-42.873215, -20.521284996],
            [-42.872886, -20.521706996],
            [-42.872703, -20.521970995999901],
            [-42.872394, -20.522447996],
            [-42.872449, -20.522549996],
            [-42.872523, -20.522836996],
            [-42.872512, -20.523134996],
            [-42.872451, -20.523422996],
            [-42.872306, -20.523675996],
            [-42.872318999999898, -20.524008996],
            [-42.872331, -20.524272996],
            [-42.872283, -20.524536995999899],
            [-42.872211, -20.524835996],
            [-42.872186999999897, -20.525053995999901],
            [-42.872138999999898, -20.525294996],
            [-42.872103, -20.525582996],
            [-42.87203, -20.525915996],
            [-42.871958, -20.526156995999902],
            [-42.871836, -20.526432996],
            [-42.871752, -20.526662996],
            [-42.871703, -20.526915996],
            [-42.871679, -20.527133996],
            [-42.871631, -20.527397995999898],
            [-42.871583, -20.527627996],
            [-42.871523, -20.527880996],
            [-42.87145, -20.528121996],
            [-42.871365, -20.528397996],
            [-42.871232, -20.528581996],
            [-42.871085999999899, -20.528822996],
            [-42.870879, -20.528949995999898],
            [-42.870672, -20.529064995999899],
            [-42.870427999999897, -20.529203996],
            [-42.870221, -20.529330995999899],
            [-42.86999, -20.529479996],
            [-42.869832, -20.529709996],
            [-42.869649, -20.529848995999899],
            [-42.869491, -20.530020996],
            [-42.869309, -20.5302509959999],
            [-42.869139, -20.530469996],
            [-42.868969, -20.530699996],
            [-42.868811, -20.530917996],
            [-42.868701, -20.531147996],
            [-42.868617, -20.5313779959999],
            [-42.868568, -20.531595996],
            [-42.868508, -20.531814996],
            [-42.868459, -20.532032995999899],
            [-42.868436, -20.532262995999901],
            [-42.868423999999898, -20.532492996],
            [-42.8684, -20.532721996],
            [-42.868400999999899, -20.533273995999899],
            [-42.868414, -20.533514996],
            [-42.868463, -20.533801996],
            [-42.868488, -20.534042996],
            [-42.86855, -20.5343529959999],
            [-42.868599, -20.534570996],
            [-42.868636, -20.534846995999899],
            [-42.868686, -20.535282996],
            [-42.868711, -20.535523995999899],
            [-42.868748, -20.535764996],
            [-42.868773, -20.5359949959999],
            [-42.868859, -20.536200995999899],
            [-42.86886, -20.536499996],
            [-42.868873, -20.536889996],
            [-42.86891, -20.537154996],
            [-42.86902, -20.537429996],
            [-42.869143, -20.537704995999899],
            [-42.869229, -20.537945996],
            [-42.869314, -20.538163996],
            [-42.869473, -20.538324996],
            [-42.869669, -20.538473995999901],
            [-42.869828, -20.538633995999898],
            [-42.870022999999897, -20.538817996],
            [-42.87006, -20.539046996],
            [-42.870062, -20.540057996],
            [-42.869904, -20.5402769959999],
            [-42.869759, -20.540506996],
            [-42.869698, -20.540873996],
            [-42.869614, -20.541138996],
            [-42.869552999999897, -20.541413996],
            [-42.869529, -20.541677996],
            [-42.869469, -20.541988995999901],
            [-42.869433, -20.542217996],
            [-42.869409, -20.542447996],
            [-42.86941, -20.542688996],
            [-42.869374, -20.542964996],
            [-42.869314, -20.543263996],
            [-42.869278, -20.543516995999902],
            [-42.869229, -20.543780996],
            [-42.869206, -20.544044996],
            [-42.869182, -20.544262995999901],
            [-42.869146, -20.544481995999899],
            [-42.869036, -20.544745996],
            [-42.868794, -20.545297995999899],
            [-42.868721, -20.545527996],
            [-42.868624, -20.545814995999901],
            [-42.868576, -20.546032996],
            [-42.868515, -20.546274996],
            [-42.868455, -20.546503996],
            [-42.868406999999898, -20.546733995999901],
            [-42.868383, -20.546951996],
            [-42.868383, -20.547227995999901],
            [-42.868347, -20.547445996],
            [-42.868299, -20.547664996],
            [-42.868239, -20.547882995999899],
            [-42.868117, -20.5481359959999],
            [-42.86802, -20.548376995999899],
            [-42.867948, -20.548618996],
            [-42.867875, -20.548905996],
            [-42.867863, -20.549135996],
            [-42.867779, -20.549468996],
            [-42.867779, -20.549721996],
            [-42.867829, -20.549973995999899],
            [-42.868, -20.5503409959999],
            [-42.868098, -20.550547996],
            [-42.868245, -20.5507659959999],
            [-42.868378999999898, -20.550948995999899],
            [-42.868551, -20.551120996],
            [-42.868771, -20.5513499959999],
            [-42.868966, -20.551544996],
            [-42.869149, -20.551705996],
            [-42.86932, -20.551877996],
            [-42.869491, -20.552026995999899],
            [-42.869882, -20.552392996],
            [-42.8702, -20.552702995999901],
            [-42.870396, -20.553000996],
            [-42.870555, -20.553287995999899],
            [-42.870751, -20.553643995999899],
            [-42.870849, -20.553895995999898],
            [-42.870874, -20.554113996],
            [-42.870947999999899, -20.554400996],
            [-42.871046, -20.554607996],
            [-42.871181, -20.5548829959999],
            [-42.871205, -20.555100996],
            [-42.871206, -20.555353996],
            [-42.871194, -20.555571996],
            [-42.871158, -20.555790995999899],
            [-42.871134, -20.5560089959999],
            [-42.871098, -20.556238995999902],
            [-42.87105, -20.556502995999899],
            [-42.87099, -20.556743996],
            [-42.870928999999897, -20.557042996],
            [-42.870880999999898, -20.557272995999899],
            [-42.870845, -20.557524995999898],
            [-42.870809, -20.5577439959999],
            [-42.870773, -20.558018995999898],
            [-42.870748999999897, -20.5582379959999],
            [-42.870737, -20.558455996],
            [-42.870665, -20.558673996],
            [-42.87058, -20.558903996],
            [-42.87047, -20.559110996],
            [-42.870385999999897, -20.559329996],
            [-42.870325, -20.559570995999898],
            [-42.870289, -20.559788996],
            [-42.870241, -20.560018995999901],
            [-42.870058, -20.560271996],
            [-42.8699, -20.560432996],
            [-42.869699, -20.560548996],
            [-42.870047999999898, -20.560880996],
            [-42.870292, -20.560960995999899],
            [-42.870573, -20.561177996],
            [-42.87078, -20.561326995999899],
            [-42.870950999999899, -20.561475996],
            [-42.871135, -20.561681995999901],
            [-42.871294, -20.561876996],
            [-42.871488999999897, -20.562083996],
            [-42.871697, -20.562301996],
            [-42.871832, -20.562507996],
            [-42.872003, -20.562782996],
            [-42.872174, -20.563046996],
            [-42.872309, -20.563310995999899],
            [-42.872406168999902, -20.5634623679999],
            [-42.872456, -20.563539996],
            [-42.872603, -20.563792996],
            [-42.872676, -20.564010996],
            [-42.872762, -20.5642519959999],
            [-42.872836, -20.564469996],
            [-42.872873, -20.564722995999901],
            [-42.872959, -20.564986996],
            [-42.873069, -20.565192996],
            [-42.873326, -20.565295996],
            [-42.873643, -20.565306995999901],
            [-42.874167, -20.5653059959999],
            [-42.874447, -20.565281996],
            [-42.874691, -20.5652469959999],
            [-42.874935, -20.565223995999901],
            [-42.875179, -20.565211996],
            [-42.875411, -20.5652229959999],
            [-42.875655, -20.565256996],
            [-42.875984, -20.565313995999901],
            [-42.876228, -20.565370996],
            [-42.876448, -20.5655309959999],
            [-42.876595, -20.5657139959999],
            [-42.876742, -20.565943995999898],
            [-42.876913, -20.566218996],
            [-42.87706, -20.566448996],
            [-42.877243, -20.5667469959999],
            [-42.877427, -20.566883995999898],
            [-42.877695, -20.566975995999901],
            [-42.877964, -20.567032996],
            [-42.878232, -20.567077996],
            [-42.878476, -20.567088995999899],
            [-42.878732, -20.567099996],
            [-42.879244, -20.567098996],
            [-42.8795, -20.567120996],
            [-42.879731999999898, -20.5672239959999],
            [-42.879891, -20.567407995999901],
            [-42.880111, -20.567544996],
            [-42.880319, -20.567658996],
            [-42.880526, -20.567796996],
            [-42.880721999999899, -20.567933996],
            [-42.880917, -20.568059995999899],
            [-42.881161, -20.568185996],
            [-42.881369, -20.568300995999898],
            [-42.881600999999897, -20.568425996],
            [-42.882001, -20.568596995999901],
            [-42.882137, -20.568424996],
            [-42.882160999999897, -20.568195995999901],
            [-42.88221, -20.567976996],
            [-42.882246, -20.567735996],
            [-42.882294, -20.567482996],
            [-42.882342, -20.567183996],
            [-42.882427, -20.566931995999902],
            [-42.882523999999897, -20.566678996],
            [-42.882657, -20.566471996],
            [-42.88273, -20.566229995999901],
            [-42.882851, -20.565988995999898],
            [-42.882973, -20.565724996],
            [-42.88307, -20.565425995999899],
            [-42.88313, -20.565183996],
            [-42.883311999999897, -20.564907995999899],
            [-42.883483, -20.564712996],
            [-42.883713999999898, -20.564677995999901],
            [-42.883958, -20.564688996],
            [-42.884189999999897, -20.564756996],
            [-42.884471, -20.564882995999898],
            [-42.884726999999899, -20.5649289959999],
            [-42.885008, -20.565030996],
            [-42.88524, -20.5650879959999],
            [-42.885484, -20.565144996],
            [-42.886057, -20.565143996],
            [-42.886300999999897, -20.565131995999899],
            [-42.886592999999898, -20.565107995999899],
            [-42.886947, -20.565049996],
            [-42.887154, -20.564888996],
            [-42.887422, -20.564761996],
            [-42.887678, -20.5646809959999],
            [-42.887920999999899, -20.564542995999901],
            [-42.888165, -20.5644279959999],
            [-42.888421, -20.564380996],
            [-42.888677, -20.564368995999899],
            [-42.888969, -20.564367995999898],
            [-42.889322999999898, -20.564447996],
            [-42.889555, -20.5645629959999],
            [-42.889750999999897, -20.564699995999899],
            [-42.890056, -20.564779996],
            [-42.890276, -20.564870996],
            [-42.890580999999898, -20.564961996],
            [-42.890861, -20.565076996],
            [-42.891142, -20.565178995999901],
            [-42.891435, -20.565247996],
            [-42.891691, -20.565338996],
            [-42.891934999999897, -20.565372996],
            [-42.89224, -20.565406996],
            [-42.892496, -20.565405996],
            [-42.892812999999897, -20.565359995999899],
            [-42.893045, -20.565335995999899],
            [-42.893349999999899, -20.565266995999899],
            [-42.893569, -20.565139996],
            [-42.893739, -20.564978996],
            [-42.893946, -20.5647949959999],
            [-42.894189, -20.564598996],
            [-42.894372, -20.564322995999898],
            [-42.894517, -20.5639549959999],
            [-42.894626, -20.563758995999901],
            [-42.89476, -20.563517996],
            [-42.894905999999899, -20.563241995999899],
            [-42.895003, -20.563000996],
            [-42.895088, -20.562781996],
            [-42.895136, -20.5625409959999],
            [-42.895147, -20.562299996],
            [-42.895159, -20.562080996],
            [-42.895170999999898, -20.561828996],
            [-42.895255, -20.561517996],
            [-42.895328, -20.561288996],
            [-42.895474, -20.5610589959999],
            [-42.89562, -20.560862996],
            [-42.895741, -20.5606679959999],
            [-42.895887, -20.560471995999901],
            [-42.896093999999898, -20.560298996],
            [-42.896324999999898, -20.560103996],
            [-42.896581, -20.5599079959999],
            [-42.896763, -20.559723995999899],
            [-42.896897, -20.559458995999901],
            [-42.897006, -20.559228995999899],
            [-42.897090999999897, -20.559021996],
            [-42.897102, -20.558792996],
            [-42.897102, -20.558470996],
            [-42.897077, -20.558252996],
            [-42.897101, -20.5580349959999],
            [-42.897124, -20.557804996],
            [-42.89716, -20.557574996],
            [-42.897196, -20.557287996],
            [-42.897318, -20.557022995999901],
            [-42.897512, -20.556563996],
            [-42.897621, -20.556344996],
            [-42.897755, -20.556160996],
            [-42.897998, -20.555941995999898],
            [-42.898254, -20.555826996],
            [-42.898583, -20.555699995999898],
            [-42.898839, -20.555641996],
            [-42.899143, -20.555594996],
            [-42.899668, -20.555593996],
            [-42.89996, -20.555627995999899],
            [-42.900241, -20.555684996],
            [-42.900522, -20.5557189959999],
            [-42.900814, -20.555740995999901],
            [-42.901069999999898, -20.555751996],
            [-42.901302, -20.555727996],
            [-42.901545999999897, -20.555589996],
            [-42.901777, -20.555382995999899],
            [-42.901972, -20.555232996],
            [-42.902166, -20.555048996],
            [-42.902335999999899, -20.554841995999901],
            [-42.90247, -20.554622996],
            [-42.90253, -20.554312996],
            [-42.902615, -20.554105996],
            [-42.902650999999899, -20.5538529959999],
            [-42.902735999999898, -20.553611996],
            [-42.902905, -20.553151995999901],
            [-42.903027, -20.552898996],
            [-42.903112, -20.552668996],
            [-42.903221, -20.552462996],
            [-42.903428, -20.552335996],
            [-42.903647, -20.552185995999899],
            [-42.903891, -20.552047995999899],
            [-42.904073, -20.551874996],
            [-42.904219, -20.5516679959999],
            [-42.904353, -20.551483995999899],
            [-42.904497999999897, -20.5512879959999],
            [-42.904607999999897, -20.551046996],
            [-42.904606999999899, -20.550805996],
            [-42.90457, -20.5505419959999],
            [-42.904471999999899, -20.550300996],
            [-42.90441, -20.550036996],
            [-42.904348, -20.549806996],
            [-42.904299, -20.549553995999901],
            [-42.904237, -20.549312996],
            [-42.904213, -20.549094996],
            [-42.904127, -20.548876995999901],
            [-42.904077, -20.548658996],
            [-42.90404, -20.548417996],
            [-42.903991, -20.5481539959999],
            [-42.903978, -20.547877996],
            [-42.903941, -20.547659995999901],
            [-42.903916, -20.547383996],
            [-42.903879, -20.547165995999901],
            [-42.903756, -20.5468679959999],
            [-42.903754999999897, -20.546362996],
            [-42.903803, -20.546132996],
            [-42.903863, -20.545879995999901],
            [-42.90396, -20.545672996],
            [-42.904118, -20.545419996],
            [-42.904239, -20.545155995999899],
            [-42.904446, -20.544913995999899],
            [-42.904592, -20.544706995999899],
            [-42.904761999999899, -20.544522996],
            [-42.904969, -20.544383996],
            [-42.905212999999897, -20.5441889959999],
            [-42.905493, -20.544003996],
            [-42.9057, -20.543900995999898],
            [-42.905907, -20.543773996],
            [-42.906126, -20.5436469959999],
            [-42.906357, -20.543599996],
            [-42.906613, -20.543599996],
            [-42.906857, -20.543667996],
            [-42.907162, -20.543598996],
            [-42.907441999999897, -20.5435639959999],
            [-42.907723, -20.543562995999899],
            [-42.907979, -20.543550996],
            [-42.908223, -20.543504996],
            [-42.908454, -20.5434009959999],
            [-42.908686, -20.543307996],
            [-42.908904999999898, -20.543157995999898],
            [-42.909112, -20.543031996],
            [-42.909306, -20.542801996],
            [-42.909452, -20.542570995999899],
            [-42.909525, -20.542352996],
            [-42.909658, -20.542145995999899],
            [-42.909865, -20.541995996],
            [-42.910145, -20.5418459959999],
            [-42.910351999999897, -20.541730996],
            [-42.910595999999899, -20.541615996],
            [-42.910815, -20.541522996],
            [-42.911058, -20.541384996],
            [-42.911338999999899, -20.541314996],
            [-42.911643, -20.541256996],
            [-42.911912, -20.541221995999901],
            [-42.912155, -20.541209996],
            [-42.912643, -20.541231996],
            [-42.912924, -20.541242995999902],
            [-42.913168, -20.541264995999899],
            [-42.913399, -20.541287995999902],
            [-42.913704, -20.541355995999901],
            [-42.913947999999898, -20.541389996],
            [-42.91418, -20.541434996],
            [-42.914423999999897, -20.541445995999901],
            [-42.914692, -20.541456996],
            [-42.914924, -20.541433996],
            [-42.915166999999897, -20.5413069959999],
            [-42.915166999999897, -20.541065995999901],
            [-42.91513, -20.540824996],
            [-42.915117, -20.540605996],
            [-42.914994, -20.540410995999899],
            [-42.914895999999899, -20.540146996],
            [-42.914760999999899, -20.539894995999902],
            [-42.914627, -20.539665995999901],
            [-42.914456, -20.539516996],
            [-42.914285, -20.539355995999902],
            [-42.914113, -20.539183996],
            [-42.913905999999898, -20.539000996],
            [-42.913783, -20.538805996],
            [-42.913734, -20.538576996],
            [-42.913709, -20.538346996],
            [-42.913708, -20.5377959959999],
            [-42.913731, -20.5375539959999],
            [-42.913743, -20.537324995999899],
            [-42.913790999999897, -20.537105996],
            [-42.913815, -20.536853995999898],
            [-42.913875, -20.536634996],
            [-42.913887, -20.536416996],
            [-42.913995999999898, -20.536140996],
            [-42.914032, -20.535997495999901],
            [-42.914068, -20.535853996],
            [-42.914141, -20.535588995999898],
            [-42.91425, -20.535381996],
            [-42.914408, -20.535220996],
            [-42.914591, -20.5350029959999],
            [-42.914846, -20.5348299959999],
            [-42.91509, -20.534690995999899],
            [-42.915297, -20.534587996],
            [-42.915503999999899, -20.534448996],
            [-42.915735, -20.534310995999899],
            [-42.915979, -20.534172995999899],
            [-42.916173, -20.533999996],
            [-42.916429, -20.533883995999901],
            [-42.916610999999897, -20.533734996],
            [-42.916806, -20.533595996],
            [-42.917037999999899, -20.533480996],
            [-42.917281, -20.533434996],
            [-42.917549, -20.533376996],
            [-42.917793, -20.533341995999901],
            [-42.918037, -20.533260996],
            [-42.918231999999897, -20.533121996],
            [-42.918323, -20.533052996],
            [-42.918369393, -20.532985281],
            [-42.917839767, -20.531864245999898],
            [-42.917567791, -20.5309170959999],
            [-42.917506683999903, -20.530570614999899],
            [-42.917417106999899, -20.530354444],
            [-42.916035447, -20.529253201],
            [-42.914914841, -20.528221609],
            [-42.914579142, -20.527834173],
            [-42.913921435, -20.52701554],
            [-42.913593428, -20.525968399],
            [-42.913659373, -20.525587668999901],
            [-42.913939875, -20.5251307],
            [-42.914198101, -20.524686416999899],
            [-42.914360034, -20.5242720809999],
            [-42.914354427, -20.524014866],
            [-42.914267418, -20.5237848209999],
            [-42.914034321, -20.523507606],
            [-42.91378752, -20.522938731999901],
            [-42.913257574999903, -20.522067917999902],
            [-42.91255059, -20.521038389],
            [-42.912297205999899, -20.5206694029999],
            [-42.91191451, -20.520352],
            [-42.91114426, -20.519567359],
            [-42.910644342999902, -20.518813078999901],
            [-42.909811001, -20.517945219999898],
            [-42.910329492, -20.5173469339999],
            [-42.911199632, -20.516364360999901],
            [-42.911873676, -20.515674475],
            [-42.912436221, -20.51536134],
            [-42.912771627, -20.515276014999898],
            [-42.913264593, -20.51525964],
            [-42.913541140999897, -20.515073361],
            [-42.913925685, -20.514387079999899],
            [-42.914155646, -20.513782116],
            [-42.9146921829999, -20.512132827999899],
            [-42.915087109, -20.5112890579999],
            [-42.91529287, -20.510786432],
            [-42.915486868, -20.510025890999898],
            [-42.916601804, -20.505869565999902],
            [-42.917115689999903, -20.505086849999898],
            [-42.917991607, -20.504248844],
            [-42.91991358, -20.502848839],
            [-42.919999208, -20.502493713],
            [-42.919976866999903, -20.5020625729999],
            [-42.920177082, -20.501183597],
            [-42.920404429, -20.500586752999901],
            [-42.920552160999897, -20.500396383],
            [-42.92150271, -20.500171813],
            [-42.922659989, -20.500024988],
            [-42.923844007, -20.499604285],
            [-42.924400917999897, -20.499155655],
            [-42.924937416, -20.49852827],
            [-42.925104856, -20.498206517],
            [-42.925360708, -20.497084211],
            [-42.925573809, -20.496486036],
            [-42.926177539, -20.495358481],
            [-42.926708356999903, -20.494037528],
            [-42.926841566999897, -20.492989741],
            [-42.926799108999901, -20.492638676],
            [-42.926782553, -20.492501778],
            [-42.925689647, -20.491569921],
            [-42.923760221999899, -20.490364714],
            [-42.923408009999903, -20.490226849],
            [-42.922412552, -20.489546004],
            [-42.921764412999899, -20.489023232],
            [-42.921041336, -20.488075002999899],
            [-42.919818423, -20.486780805],
            [-42.918992069999902, -20.4860294809999],
            [-42.917818297999901, -20.485379716999901],
            [-42.916721217, -20.485071951],
            [-42.914970888, -20.484489641],
            [-42.914956423, -20.483672550999898],
            [-42.914750736, -20.48295252],
            [-42.914440146999901, -20.482442867],
            [-42.913798239999899, -20.481854468999899],
            [-42.914074110999898, -20.4811854279999],
            [-42.914207689, -20.480583189],
            [-42.914372482, -20.480137816],
            [-42.91539956, -20.478186453],
            [-42.915950607999903, -20.477449835999899],
            [-42.9161993559999, -20.476804804],
            [-42.9164123689999, -20.4756655969999],
            [-42.916430413999898, -20.474472582],
            [-42.916058785, -20.4730863099999],
            [-42.915752037999901, -20.473345600999899],
            [-42.915362758, -20.473725696],
            [-42.914937661, -20.47409076],
            [-42.914118323, -20.474781957999902],
            [-42.913836730999897, -20.475031651999899],
            [-42.912764065, -20.475664007],
            [-42.912168736999902, -20.475991608999902],
            [-42.911687495, -20.476107392],
            [-42.911291069999898, -20.4761093289999],
            [-42.910000844, -20.475593531],
            [-42.909283, -20.475249995999899],
            [-42.90899, -20.475145996],
            [-42.90871, -20.475030995999902],
            [-42.908466, -20.474926996],
            [-42.908234999999898, -20.474811996],
            [-42.907991, -20.474741996],
            [-42.907758999999899, -20.474672996],
            [-42.907516, -20.4745349959999],
            [-42.907345, -20.474373995999901],
            [-42.907152182999901, -20.474298812],
            [-42.906930409, -20.47430578],
            [-42.906783973, -20.474336059999899],
            [-42.906541747999903, -20.474455819],
            [-42.9063605219999, -20.474608068],
            [-42.905934574999897, -20.475005666999898],
            [-42.90531864, -20.475529584],
            [-42.904671589, -20.476265261],
            [-42.904400172, -20.476664376],
            [-42.903292025, -20.477710343]
          ],
          [
            [-42.900029157999903, -20.525251445],
            [-42.900032747, -20.525311245999902],
            [-42.900064513, -20.52534732],
            [-42.90015704, -20.52539408],
            [-42.900187378999902, -20.525398580999902],
            [-42.900288968, -20.525389963],
            [-42.900318292999899, -20.525393645],
            [-42.900357605999901, -20.525414063],
            [-42.900390213, -20.525472396999898],
            [-42.900412018999901, -20.525525636999902],
            [-42.900469459, -20.52559901],
            [-42.900519927999902, -20.525670764],
            [-42.900599068, -20.525803023],
            [-42.900610537, -20.525851719999899],
            [-42.900622816999899, -20.5259038579999],
            [-42.900626947, -20.525974619],
            [-42.900673249, -20.526020071999898],
            [-42.900714325, -20.526012554],
            [-42.900899136, -20.526063178],
            [-42.90094727, -20.526141675],
            [-42.900962822999901, -20.526202172],
            [-42.900934962, -20.5262635949999],
            [-42.900916507, -20.526349352999901],
            [-42.900882384999903, -20.526442184],
            [-42.900879384999897, -20.526530813],
            [-42.900833201999902, -20.526593949999899],
            [-42.900799236, -20.526616195],
            [-42.90078725, -20.5266789739999],
            [-42.9008107289999, -20.526737361999899],
            [-42.900767775, -20.526835306999899],
            [-42.90078017, -20.526898644],
            [-42.900743336999902, -20.526985789],
            [-42.900694604, -20.527035407],
            [-42.900730058, -20.527087127999899],
            [-42.900781918, -20.527149257],
            [-42.9008559379999, -20.527271712999902],
            [-42.900884183, -20.527314879],
            [-42.900884076999901, -20.527418554999901],
            [-42.9008762939999, -20.527509263],
            [-42.900886704999898, -20.527589384999899],
            [-42.9009515179999, -20.527658034999899],
            [-42.901007369, -20.5277515679999],
            [-42.901039725, -20.5277881119999],
            [-42.901046846, -20.527839319],
            [-42.901093322, -20.527948748],
            [-42.901107421999903, -20.528022414],
            [-42.901128037, -20.528130113],
            [-42.901146281, -20.528229315],
            [-42.901145194, -20.5282967549999],
            [-42.90116499, -20.528364193],
            [-42.901204477, -20.528425232],
            [-42.901214534, -20.528456735],
            [-42.901227483, -20.528497296],
            [-42.901237067, -20.528527316999899],
            [-42.901274682999897, -20.528580134],
            [-42.901332356999902, -20.52868337],
            [-42.901392391999899, -20.528772417],
            [-42.901447444, -20.528908079],
            [-42.901432703, -20.529020764999899],
            [-42.901447113, -20.5290847109999],
            [-42.90145143, -20.529136719],
            [-42.90145568, -20.529189378],
            [-42.901521235, -20.529277892],
            [-42.901515204, -20.529304289],
            [-42.901483927, -20.529311198],
            [-42.901511674999902, -20.529352065],
            [-42.901572495, -20.529380871999901],
            [-42.901605178999901, -20.529422559999901],
            [-42.90160872, -20.529508638],
            [-42.901621829, -20.529564664],
            [-42.901641100999903, -20.529603546],
            [-42.9016047329999, -20.529625402],
            [-42.901553890999899, -20.529654825],
            [-42.901501806999903, -20.529708425],
            [-42.901460336, -20.529739462999899],
            [-42.901420217, -20.529738311],
            [-42.901383494999898, -20.529753961],
            [-42.901310872, -20.529793227],
            [-42.901277525999902, -20.529825679999899],
            [-42.901236018, -20.529864695999901],
            [-42.901216979, -20.529904969],
            [-42.901226075, -20.5299351899999],
            [-42.90122407, -20.529971571],
            [-42.901266313999898, -20.530000207999901],
            [-42.901246579, -20.53004886],
            [-42.901250402, -20.530086678],
            [-42.9012907749999, -20.530130673],
            [-42.901356811, -20.530209729],
            [-42.901434589, -20.530234715999899],
            [-42.901522622999899, -20.530296781999901],
            [-42.901614579999901, -20.530359852999901],
            [-42.901668543, -20.53039954],
            [-42.901716666, -20.530438852],
            [-42.901773917999897, -20.530485621],
            [-42.901844066, -20.530528965],
            [-42.90189155, -20.530586374999899],
            [-42.901946742999897, -20.530613794],
            [-42.902158496, -20.530613963],
            [-42.902267185, -20.530649329],
            [-42.902353763, -20.530713166999899],
            [-42.902549472, -20.530827304999899],
            [-42.902596648, -20.530801508],
            [-42.902675197, -20.530810129],
            [-42.902786662, -20.530828082],
            [-42.902834686999903, -20.530859356],
            [-42.902846096, -20.53081395],
            [-42.902880320999898, -20.530783555],
            [-42.902938907, -20.530777448],
            [-42.902994253, -20.530737539],
            [-42.903043086, -20.53071492],
            [-42.90311335, -20.530716035999902],
            [-42.903324331, -20.530776402],
            [-42.903447282999899, -20.530813409],
            [-42.903550961999898, -20.530836264999898],
            [-42.903680591999901, -20.530885957],
            [-42.903830835, -20.530927412],
            [-42.903884278, -20.530942157],
            [-42.903989242, -20.530954601],
            [-42.904064171999899, -20.5309535449999],
            [-42.90416641, -20.530996421999902],
            [-42.904274019, -20.531005449],
            [-42.904421559999903, -20.531002608999898],
            [-42.90451783, -20.530965942],
            [-42.904563289, -20.530985058999899],
            [-42.9046322, -20.530964356],
            [-42.904684244999899, -20.530971334999901],
            [-42.904735683, -20.530978230999899],
            [-42.904855612999903, -20.530939173],
            [-42.904996482999898, -20.530804197999899],
            [-42.905105697, -20.530822346],
            [-42.905221167, -20.530726482],
            [-42.905336255, -20.530694126],
            [-42.905379422, -20.530691015999899],
            [-42.905448489, -20.530610243999899],
            [-42.905563577, -20.530557601999899],
            [-42.905624165999903, -20.530495324999901],
            [-42.905739255, -20.530485214999899],
            [-42.905800757999899, -20.530490916],
            [-42.905832991, -20.530514189],
            [-42.905922256999901, -20.530538464],
            [-42.905991248, -20.5305055329999],
            [-42.906047827999899, -20.530425606999898],
            [-42.906108956, -20.530391931999901],
            [-42.906235503999902, -20.530364581999901],
            [-42.906290492, -20.530315082],
            [-42.906342832999897, -20.530293104999899],
            [-42.906400724, -20.530274678],
            [-42.906471488, -20.530241443],
            [-42.906506716, -20.53020344],
            [-42.906527529, -20.530147247999899],
            [-42.9066565689999, -20.530106135],
            [-42.906733577, -20.530100793],
            [-42.906841959, -20.530098122],
            [-42.907033053, -20.530031347],
            [-42.907138582999899, -20.529996624],
            [-42.90725105, -20.5299744769999],
            [-42.907430041999902, -20.529905875999901],
            [-42.90750019, -20.529818511],
            [-42.907538499999902, -20.529806643],
            [-42.907599551, -20.529842684999899],
            [-42.907692515, -20.529949051],
            [-42.907808837999902, -20.529924505999901],
            [-42.907904578999897, -20.530021711],
            [-42.907928782999903, -20.530090142999899],
            [-42.90811359, -20.530222507],
            [-42.908150943, -20.530290097],
            [-42.908165650999898, -20.5303820459999],
            [-42.90818409, -20.530497318999899],
            [-42.908224574, -20.530610731],
            [-42.908250183, -20.530694486999899],
            [-42.908263873, -20.530742136999901],
            [-42.908317985999901, -20.530857429],
            [-42.908342547, -20.530905627],
            [-42.907995375, -20.530934424],
            [-42.907914713, -20.530951731],
            [-42.907743837999902, -20.530956373],
            [-42.907631707999897, -20.530945400999901],
            [-42.907441683999899, -20.5309170969999],
            [-42.907190977, -20.530903237],
            [-42.906742424999898, -20.530837644],
            [-42.906639362, -20.530833045],
            [-42.906345589999901, -20.5308538049999],
            [-42.906031852999902, -20.530904554],
            [-42.905857872, -20.530949959999901],
            [-42.905757931, -20.5309775499999],
            [-42.905646035, -20.531030508],
            [-42.905351993999901, -20.531166977999899],
            [-42.905014363, -20.531320019],
            [-42.904743781, -20.531472138],
            [-42.904612156, -20.5316001909999],
            [-42.904586369, -20.53163036],
            [-42.90451983, -20.531755044],
            [-42.904522099, -20.531817007],
            [-42.904528957, -20.531879443],
            [-42.904582000999902, -20.532054364],
            [-42.904639898, -20.532204763],
            [-42.904718416, -20.532355004],
            [-42.90477429, -20.532493374],
            [-42.904847742, -20.532632863999901],
            [-42.904880109, -20.533017254],
            [-42.904807558, -20.533187584],
            [-42.904707539, -20.533417434999901],
            [-42.904539797, -20.533575522],
            [-42.904102543, -20.533074036999899],
            [-42.903839331, -20.5327331619999],
            [-42.903483369, -20.532283284],
            [-42.903341305, -20.532166985999901],
            [-42.903217522, -20.5320591559999],
            [-42.903035737, -20.531902004],
            [-42.902827004, -20.531717312],
            [-42.902691596999901, -20.531601515],
            [-42.902532387999898, -20.53146641],
            [-42.902370206999898, -20.531380456],
            [-42.902176614, -20.531256856],
            [-42.901954685, -20.531089091],
            [-42.901734744999899, -20.530947194],
            [-42.901189422999899, -20.530644897999899],
            [-42.901073298, -20.530597707],
            [-42.901003782, -20.530584871],
            [-42.900960989999902, -20.530561232],
            [-42.900911755, -20.530585427999899],
            [-42.900752133, -20.530600229999902],
            [-42.900629747, -20.530629248],
            [-42.900492276, -20.530664143],
            [-42.900329605, -20.530683865999901],
            [-42.9002291749999, -20.530693189],
            [-42.900155588, -20.530699749],
            [-42.899970798, -20.530692877],
            [-42.899868470999898, -20.530677458],
            [-42.899790616, -20.530683414999899],
            [-42.899756201999899, -20.5304759049999],
            [-42.899828628, -20.529945311999899],
            [-42.899877386999897, -20.529710294],
            [-42.899908507, -20.529515223],
            [-42.89992373, -20.529420504],
            [-42.899887958, -20.528769262],
            [-42.8999391259999, -20.528500998],
            [-42.899960948999897, -20.528140409999899],
            [-42.900005958999898, -20.52789602],
            [-42.899913559, -20.527803127],
            [-42.900062028, -20.527645262],
            [-42.900226607, -20.527450953],
            [-42.900240278999902, -20.527307272],
            [-42.900191031, -20.526996391],
            [-42.900183147999897, -20.526831006999899],
            [-42.900128880999901, -20.5266785439999],
            [-42.900053811, -20.526471813999901],
            [-42.899981454999903, -20.5263529449999],
            [-42.8998575049999, -20.526159005],
            [-42.8996415679999, -20.525917799],
            [-42.899334814, -20.526007755],
            [-42.899151930999899, -20.526150311],
            [-42.899085336, -20.526042418],
            [-42.899036477, -20.525952875],
            [-42.899122868999903, -20.525822987],
            [-42.899372442, -20.525523021],
            [-42.8992938539999, -20.525415509],
            [-42.899211982999901, -20.525285088],
            [-42.899159654999899, -20.525132625],
            [-42.899098282, -20.52499276],
            [-42.899036615999897, -20.524888834],
            [-42.898973999, -20.524761261],
            [-42.898800159, -20.524612734],
            [-42.898229865, -20.524034768],
            [-42.898315491999902, -20.524001359],
            [-42.898340138, -20.523982573999898],
            [-42.898370692999897, -20.523930570999902],
            [-42.898297543999902, -20.523591553],
            [-42.8983213879999, -20.523299401],
            [-42.898345574, -20.52314725],
            [-42.897916468999902, -20.5230225349999],
            [-42.897385223, -20.522907688],
            [-42.897299301, -20.522887015],
            [-42.897268601, -20.522883072],
            [-42.897119116999903, -20.522907243],
            [-42.896978869, -20.523095331],
            [-42.896779306, -20.523354328],
            [-42.896487479, -20.523748537],
            [-42.89629653, -20.523482401],
            [-42.896004794, -20.52329973],
            [-42.895404275, -20.522968373],
            [-42.895191525, -20.52285109],
            [-42.895042579999902, -20.522760891],
            [-42.894810462999899, -20.522646606],
            [-42.8947067779999, -20.522605129999899],
            [-42.89468264, -20.522596549],
            [-42.894655239, -20.522573063],
            [-42.894802840999901, -20.522242429],
            [-42.894958965999898, -20.5218921049999],
            [-42.895298012, -20.521119975],
            [-42.895466262999904, -20.520735997],
            [-42.8955890189999, -20.520436428],
            [-42.895903566999898, -20.5196688149999],
            [-42.896249775999898, -20.518870805999899],
            [-42.896341623, -20.5186641499999],
            [-42.896551642, -20.518203358999902],
            [-42.896608909, -20.518072671],
            [-42.896713169999899, -20.51783474],
            [-42.896868041999902, -20.517481308999901],
            [-42.896898795, -20.517386976999902],
            [-42.896924445, -20.5173073509999],
            [-42.896952067, -20.517223568],
            [-42.896997206, -20.517255204],
            [-42.897144343, -20.517264074],
            [-42.897266064999897, -20.5172714129999],
            [-42.897360982, -20.5172757],
            [-42.897619835999897, -20.5172858349999],
            [-42.897794507, -20.517319339],
            [-42.897982253999899, -20.517344308],
            [-42.898071036, -20.517309757],
            [-42.898338537, -20.517339997],
            [-42.898713395, -20.517378499],
            [-42.898781754, -20.517353473999901],
            [-42.898990164999901, -20.517297366],
            [-42.899017605, -20.517318218],
            [-42.8990454, -20.51730881],
            [-42.899035203, -20.517286125999899],
            [-42.899019329999902, -20.517264836],
            [-42.899025779999903, -20.5172172269999],
            [-42.89907799, -20.517211352999901],
            [-42.89911484, -20.517207277999901],
            [-42.899151804, -20.5172206569999],
            [-42.899276949999901, -20.517276076],
            [-42.899373398, -20.517289617],
            [-42.899398416999901, -20.517280012],
            [-42.8994401369999, -20.517211833],
            [-42.899531015, -20.517214144],
            [-42.899546867, -20.517232063999899],
            [-42.899562562, -20.517221398999901],
            [-42.899590678, -20.517193662999901],
            [-42.899601984999897, -20.5171838979999],
            [-42.899655329, -20.5171695249999],
            [-42.899683665999902, -20.517143613],
            [-42.899700364, -20.517184539],
            [-42.899710327, -20.517214527],
            [-42.899708331999904, -20.517259587999899],
            [-42.899718799, -20.517358746],
            [-42.899758925999897, -20.517390571],
            [-42.899789187, -20.5174189529999],
            [-42.899839014, -20.5174964559999],
            [-42.899805124, -20.517651472999901],
            [-42.89985329, -20.517696542],
            [-42.899965957999903, -20.517764198999899],
            [-42.900014360999897, -20.5178021309999],
            [-42.900023158, -20.517831231999899],
            [-42.900001695, -20.517849645],
            [-42.899969992, -20.517869296999901],
            [-42.899888475, -20.51792053],
            [-42.899849731, -20.517945574],
            [-42.899815896999897, -20.5179942779999],
            [-42.899809828999899, -20.518040608],
            [-42.899804835999902, -20.51808557],
            [-42.899771825999899, -20.518111593],
            [-42.899713285, -20.518139279],
            [-42.89963947, -20.51817263],
            [-42.899568750999897, -20.518223508999899],
            [-42.899526914, -20.518243067],
            [-42.899489550999903, -20.5182629409999],
            [-42.899432798, -20.5183107299999],
            [-42.899385241999902, -20.518359581],
            [-42.899383939, -20.518545944],
            [-42.89938677, -20.518681855999901],
            [-42.899393271999898, -20.518769829999901],
            [-42.899396855, -20.518818312999901],
            [-42.899405691, -20.518906473999898],
            [-42.899416683, -20.518988189],
            [-42.899435608, -20.519057021],
            [-42.899444420999899, -20.519089073],
            [-42.899443418, -20.51916218],
            [-42.899417894, -20.519224316],
            [-42.899343121, -20.519260043],
            [-42.899276947, -20.519284969999902],
            [-42.899227587, -20.519301868],
            [-42.899156516999902, -20.5193132429999],
            [-42.899164885999902, -20.519384284999902],
            [-42.899194902, -20.519450051],
            [-42.899201640999898, -20.519524896999901],
            [-42.899263340999902, -20.519574129],
            [-42.899249373, -20.5196675779999],
            [-42.899201634999898, -20.519724779999901],
            [-42.899155153, -20.519780475999902],
            [-42.899093729, -20.519855107999899],
            [-42.899050941, -20.519879064],
            [-42.899094818, -20.519963983],
            [-42.899043146999901, -20.520027042],
            [-42.8990909199999, -20.520137881],
            [-42.899089796999903, -20.520210678999899],
            [-42.899076582, -20.520323673],
            [-42.899096103, -20.520425598],
            [-42.899152414999897, -20.520558214999902],
            [-42.899174318, -20.520616824999902],
            [-42.899205439, -20.520684785999901],
            [-42.89920388, -20.5207357809999],
            [-42.899278514999899, -20.520848568],
            [-42.899287312999903, -20.520950797],
            [-42.899325102, -20.521040512],
            [-42.899382165, -20.521144761],
            [-42.899419069, -20.5212121819999],
            [-42.899489683999903, -20.521268065],
            [-42.899609238, -20.521303748],
            [-42.899710237, -20.521350985],
            [-42.899890324999902, -20.521493749999902],
            [-42.899963753, -20.521537259999899],
            [-42.900079439, -20.521586086],
            [-42.900189059, -20.521666314],
            [-42.900197196, -20.521709268999899],
            [-42.900242638, -20.521757219999898],
            [-42.900260019, -20.521800943],
            [-42.900318055999897, -20.5218435479999],
            [-42.900386693, -20.521924467],
            [-42.900428147, -20.521975934999901],
            [-42.900492185, -20.522013983],
            [-42.900496147999903, -20.522054447999899],
            [-42.900531688999898, -20.522108409999898],
            [-42.900554133999897, -20.522179175],
            [-42.900583898, -20.522257502999899],
            [-42.900637425999903, -20.522386851],
            [-42.900645803, -20.522483432999898],
            [-42.900646094, -20.522538607999898],
            [-42.900645373, -20.522546039],
            [-42.900636903, -20.522633321999901],
            [-42.9006255789999, -20.522672175],
            [-42.900605868, -20.52273981],
            [-42.900595231999901, -20.5228509149999],
            [-42.900534644999901, -20.522913663999901],
            [-42.900569638, -20.522969009999901],
            [-42.900596282, -20.52302581],
            [-42.900594780999903, -20.523073914999902],
            [-42.900571936999903, -20.523093786999901],
            [-42.900478215, -20.523171783999899],
            [-42.900440584, -20.523279339999899],
            [-42.900408620999897, -20.523356615],
            [-42.900393715999897, -20.523401066],
            [-42.900380573999897, -20.523479553],
            [-42.900331114, -20.523553637],
            [-42.900288100999902, -20.523644023],
            [-42.900235224, -20.523736998999901],
            [-42.900235278, -20.523806729999901],
            [-42.900227785, -20.5241147519999],
            [-42.900226467, -20.52416893],
            [-42.900215263999897, -20.524311222999899],
            [-42.900212338, -20.524401091],
            [-42.900210767, -20.524449343999901],
            [-42.900209326, -20.524493622],
            [-42.900194757999898, -20.524625939],
            [-42.900178382, -20.524728857],
            [-42.900156073999902, -20.524829904999901],
            [-42.900157697999902, -20.524897584],
            [-42.900192273, -20.524934293999902],
            [-42.900140416999903, -20.524995127999901],
            [-42.900047457, -20.525064444999899],
            [-42.900054575999903, -20.525133051999902],
            [-42.900029001, -20.525190358],
            [-42.900029157999903, -20.525251445]
          ]
        ]
      }
    }
  ]
}
